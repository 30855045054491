/**
 *
 * @param campaign {{
 *     bounce_drops,
 *     bounces,
 *     clicks,
 *     delivered,
 *     invalid_emails,
 *     opens,
 *     requests,
 *     spam_report_drops,
 *     spam_reports,
 *     unique_clicks,
 *     unique_opens,
 *     unsubscribes
 * }}
 * @constructor
 */

function CampaignObject(campaign) {
  this.bounce_drops = campaign?.bounce_drops || 0;
  this.bounces = campaign?.bounces || 0;
  this.clicks = campaign?.clicks || 0;
  this.delivered = campaign?.delivered || 0;
  this.invalid_emails = campaign?.invalid_emails || 0;
  this.opens = campaign?.opens || 0;
  this.requests = campaign?.requests || 0;
  this.spam_report_drops = campaign?.spam_report_drops || 0;
  this.spam_reports = campaign?.spam_reports || 0;
  this.unique_clicks = campaign?.unique_clicks || 0;
  this.unique_opens = campaign?.unique_opens || 0;
  this.unsubscribes = campaign?.unsubscribes || 0;
}

export default CampaignObject;
