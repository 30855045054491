import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, ApiUrl, createURL } from '../config/apiConfig';
import CategoryObject from '../objects/CategoryObject';

const { GET } = ApiMethods;

export const getCategoryList = async () => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.categorizedServices.list,
  });

  if (response.data) {
    return (response.data.results || []).map((i) => new CategoryObject(i));
  } else {
    return Promise.reject(response);
  }
};

export const getCategory = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.categorizedServices.get, { id }),
  });

  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

export const getPureCategoryList = async () => {
  const response = await callApi({
    method: GET,
    absolute: true,
    url: `${ApiUrl}${ApiRoutes.category.list}`,
  });

  if (response.data) {
    return (response.data.results || []).map((i) => new CategoryObject(i));
  } else {
    return Promise.reject(response);
  }
};

export const getPureCategory = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: createURL(`${ApiUrl}${ApiRoutes.category.list}`, { id }),
  });

  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
