export const Types = {
  BUSINESS_LOCATION: '_loc',
  LATEST_APPOINTMENT: '_l-apt',
  SYSTEM_TYPE: '_system',
  USER_TOKEN: '_token',
  USER_REFRESH: '_refresh',
  STRIPE_PAYMENT: '_payment',
  PAYMENT_BACK_PAGE: '_pbp',
  CITY: '_city',
  COUNTRIES: '_countries',
};

export const BusinessTypes = {
  SET_BUSINESS: 'SET_BUSINESS',
};

export const StorageTypes = {
  CATCH_KEY: '__catch',
};
