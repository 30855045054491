import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../Common/UIKit/Form/Button';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import {
  getBusiness,
  getStripeBillingsLink,
  getStripeConnectAccountLink,
  getStripeDisconnectAccountLink,
  getStripePayoutLink,
} from '../../../api';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';

const PaymentPage = () => {
  const [btnLoadings, setBtnLoadings] = useState({
    payout: false,
    billing: false,
    connect: false,
    disconnect: false,
  });
  const { isMarketplace, isSoftware, setHeader, business, setBusiness, userPermissions: p } = useContext(AppContext);

  useEffect(() => {
    setHeader('Settings / Payment');
  }, [setHeader]);

  const setBtnLoading = (name = '', value = true) => {
    if (btnLoadings[name] !== undefined) {
      const updateLoadings = { ...btnLoadings };
      updateLoadings[name] = value;
      setBtnLoadings(updateLoadings);
    }
  };

  const handleBillingHistory = () => {
    handleApi(
      getStripeBillingsLink,
      {},
      {
        loadingFn: (e) => setBtnLoading('billing', e),
        list: false,
      },
    ).then(({ portal_url }) => {
      window.location.href = portal_url;
    });
  };

  const handlePayout = () => {
    handleApi(
      getStripePayoutLink,
      {},
      {
        loadingFn: (e) => setBtnLoading('payout', e),
        list: false,
      },
    ).then(({ login_url }) => {
      // window.location.href = login_url;
      window.open(login_url);
    });
  };

  const handleConnectAccount = () => {
    handleApiSave(getStripeConnectAccountLink, undefined, {}, undefined, {
      loadingFn: (e) => setBtnLoading('connect', e),
    })
      .then(({ connect_url }) => {
        window.location.href = connect_url;
      })
      .catch(() => {});
  };

  const handleDisconnectAccount = () => {
    setBtnLoading('disconnect');
    handleApi(getStripeDisconnectAccountLink, {}, { list: false })
      .then(() => {
        handleApi(getBusiness, {}, { list: false })
          .then(setBusiness)
          .finally(() => setBtnLoading('disconnect', false));
        // window.location.reload(false);
      })
      .catch(() => setBtnLoading('disconnect', false));
  };

  const { _disconnect, _connect, _billing, _stripeLogin, _business } = useMemo(
    () => ({
      _disconnect:
        p?.business?.disconnect_from_stripe?.modify || p?.business?.disconnect_from_stripe?.view
          ? p?.business?.disconnect_from_stripe
          : false,
      _connect:
        p?.business?.connect_to_stripe?.modify || p?.business?.connect_to_stripe?.view
          ? p?.business?.connect_to_stripe
          : false,
      _billing:
        p?.business?.stripe_billing_portal?.modify || p?.business?.stripe_billing_portal?.view
          ? p?.business?.stripe_billing_portal
          : false,
      _stripeLogin:
        p?.business?.stripe_login_link?.modify || p?.business?.stripe_login_link?.view
          ? p?.business?.stripe_login_link
          : false,
      _business: p?.business?.crud?.modify || p?.business?.crud?.view ? p?.business?.crud : false,
    }),
    [p],
  );

  const { isOwner } = usePermission();

  return (
    <div className="c-panel-content c-settings mt-3">
      <>
        {isSoftware && (
          <>
            {(isOwner || _billing) && (
              <div className="c-settings-item">
                <div className="c-settings-item__detail">
                  <span className="c-settings-item__title">Billing History</span>
                  <p className="c-settings-item__description">You can check previous billing history in stripe</p>
                </div>

                <Button
                  sm
                  id="billingHistory"
                  color="info"
                  loading={btnLoadings.billing}
                  onClick={handleBillingHistory}
                >
                  View Detail
                </Button>
              </div>
            )}
          </>
        )}
        {(isOwner || _connect) && !business?.connected_to_stripe && (
          <div className="c-settings-item">
            <div className="c-settings-item__detail">
              <span className="c-settings-item__title">Link your bank account</span>
              <p className="c-settings-item__description">
                You can connect your marketplace to your strip account by this link
              </p>
            </div>

            {(isOwner || _connect.modify) && (
              <Button sm id="connectStripe" color="info" loading={btnLoadings.connect} onClick={handleConnectAccount}>
                Connect
              </Button>
            )}
          </div>
        )}
        {isMarketplace && (
          <>
            {(isOwner || _business) && (
              <div className="c-settings-item">
                <div className="c-settings-item__detail">
                  <NavLink id="penaltyLink" to="/settings/payment/business" className="c-settings-item__title">
                    Payment Penalty
                  </NavLink>
                  <p className="c-settings-item__description">
                    Easily manage your business’s payment penalties and rules
                  </p>
                </div>
                {(isOwner || _business?.modify) && (
                  <Button sm id="penaltyBtn" link="/settings/payment/business" color="info">
                    Manage
                  </Button>
                )}
              </div>
            )}
            {(isOwner || _stripeLogin) && (
              <div className="c-settings-item">
                <div className="c-settings-item__detail">
                  <span className="c-settings-item__title">Payout History</span>
                  <p className="c-settings-item__description">You can check your previous payout history in stripe</p>
                </div>

                {(isOwner || _stripeLogin?.modify) && (
                  <Button sm id="payoutHistory" color="info" loading={btnLoadings.payout} onClick={handlePayout}>
                    View Payout
                  </Button>
                )}
              </div>
            )}
            <div className="c-settings-item">
              <div className="c-settings-item__detail">
                <NavLink id="balanceLink" to="/settings/payment/balance-history" className="c-settings-item__title">
                  Balance History
                </NavLink>
                <p className="c-settings-item__description">Easily check balance history transfers</p>
              </div>
              <Button sm id="balanceBtn" link="/settings/payment/balance-history" color="info">
                Manage
              </Button>
            </div>
          </>
        )}
      </>
      {(isOwner || _disconnect) && business?.connected_to_stripe && (
        <div className="c-settings-item">
          <div className="c-settings-item__detail">
            <span className="c-settings-item__title">Disconnect Strip Account</span>
            <p className="c-settings-item__description">
              This link allows you to disconnect your Strip Account from your Marketplace. (You can Reconnect it
              anytime).
            </p>
          </div>

          {(isOwner || _disconnect?.modify) && (
            <Button
              sm
              id="disconnectStripe"
              color="info"
              loading={btnLoadings.disconnect}
              onClick={handleDisconnectAccount}
            >
              Disconnect
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
