import React, { useContext, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import useApi from '../../../utilities/useApi';
import { getAnalyticsAppointments } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { getAptStatusName } from '../../Common/UIKit/Status';
import { AppContext } from '../../../context/providers';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import usePermission from '../../../utilities/usePermission';
import { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { NavLink } from 'react-router-dom';

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          userCallback: function (label) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }
          },
          // stepSize: 1,
          // maxTicksLimit: 5,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          // stepSize: 4,
          maxTicksLimit: 15,
        },
      },
    ],
  },
};

// eslint-disable-next-line react/prop-types
const AnalyticsAppointmentsPage = ({ headerEl }) => {
  const [data, loading] = useApi(getAnalyticsAppointments, { key: 'business.appointments_statistics' });
  const { setHeader } = useContext(AppContext);
  const { access: _access } = usePermission('business', 'appointments_statistics', 'view');
  useEffect(() => {
    setHeader('Analytics / Appointments & Services');
  }, [setHeader]);
  const decorateRecentAppointments = (appointments) => {
    const { labels, data } = appointments.reduce(
      (acc, a) => {
        // acc.labels.push(a.date);
        acc.labels.push(a.reserved_date);
        acc.data.push(a.count);
        return acc;
      },
      { labels: [], data: [] },
    );

    return {
      labels,
      datasets: [
        {
          label: 'Appointments',
          data: data,
          // borderColor: 'rgba(255, 99, 132, 1)',
          borderColor: '#fc5622',
          borderWidth: 2,
          lineTension: 0,
          fill: false,
        },
      ],
    };
  };

  const decoratePopularServices = (services) => {
    const { labels, data } = services.reduce(
      (acc, s) => {
        acc.labels.push(s.service);
        acc.data.push(s.count);
        return acc;
      },
      { labels: [], data: [] },
    );
    return {
      labels,
      datasets: [
        {
          data,
          // borderColor: 'rgba(255, 99, 132, 1)',
          borderColor: 'rgba(0,0,0,0.5)',
          backgroundColor: ['#fc5622', '#43A047', '#1E88E5', '#e53935', '#FDD835', '#26C6DA'],
          borderWidth: 2,
          lineTension: 0,
          fill: true,
        },
      ],
    };
  };

  return (
    <>
      <HeadPage leftElement={headerEl} />
      {_access ? (
        <>
          {loading ? (
            <PageLoading />
          ) : (
            <>
              {data?.appointments_count_per_day && (
                <div className="c-dashboard__content my-3">
                  <div className="c-dashboard__header">
                    <div className="c-dashboard__head">
                      <h1 className="c-dashboard__title">Appointments within the last 30 days</h1>
                    </div>
                  </div>
                  <div className="c-dashboard__body">
                    <div className="c-dashboard__chart">
                      <Line
                        data={decorateRecentAppointments(data.appointments_count_per_day)}
                        width={100}
                        height={300}
                        options={chartOptions}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {data?.popular_services && (
                  <div className="col-12 col-sm-12 col-md-4 my-3">
                    <div className="c-dashboard__content">
                      <div className="c-dashboard__header">
                        <div className="c-dashboard__head">
                          <h1 className="c-dashboard__title">Popular Services</h1>
                        </div>
                      </div>
                      <div className="c-dashboard__body c-dashboard__body--services">
                        {data?.popular_services.length > 0 ? (
                          <div className="c-dashboard__chart">
                            <Doughnut data={decoratePopularServices(data.popular_services)} width={100} height={100} />
                          </div>
                        ) : (
                          <span className="c-dashboard__body-empty">There is not any Services</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {data?.recent_appointments && (
                  <div className="col-12 col-sm-12 col-md-8 my-3">
                    <div className="c-dashboard__content">
                      <div className="c-dashboard__header">
                        <div className="c-dashboard__head">
                          <h1 className="c-dashboard__title">Recent Appointments</h1>
                        </div>
                        <NavLink to="/sales/appointments">See More</NavLink>
                      </div>
                      <div className="c-dashboard__body px-3">
                        {data.recent_appointments.length > 0 ? (
                          <div className="c-table c-table--sm">
                            <table>
                              <thead>
                                <tr>
                                  <th>Client Name</th>
                                  <th>Service</th>
                                  <th>Date</th>
                                  <th>Time</th>
                                  <th>Staff</th>
                                  <th>Status</th>
                                  <th>Sales Channel</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.recent_appointments.map((appointment, _) => (
                                  <tr key={_}>
                                    <td>{appointment?.client_name}</td>
                                    <td>{appointment?.service_name}</td>
                                    <td>{appointment?.reserved_date}</td>
                                    <td>{appointment?.reserved_time}</td>
                                    <td>{appointment?.staff_name}</td>
                                    <td>{getAptStatusName(appointment?.status)}</td>
                                    <td>{appointment?.sale_channel}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <span className="c-dashboard__body-empty">There is not any Appointments</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default AnalyticsAppointmentsPage;
