import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ControlSelect } from '../UIKit/Form/Select';
import { ControlInput } from '../UIKit/Form/Input';
import { handleApi } from '../../../utilities/useApi';
import { getCountriesList, getProvincesList } from '../../../api';
import { Types } from '../../../config/actionTypes';

// eslint-disable-next-line no-unused-vars
const LocationCitySelector = ({ control, errors, data, hasCountry = true, onChangeProvince }) => {
  const [loadingProvince, setLoadingProvince] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    if (hasCountry) {
      handleApi(
        getCountriesList,
        {},
        { limit: 50, defaultData: null, infinitePage: true, storage: Types.COUNTRIES },
      ).then((res) => {
        setCountries((res.results || []).map((i) => ({ value: i.code, label: i.name })));
      });
    } else {
      handleSelectCountry('CA');
    }
  }, []);

  const handleSelectCountry = (code) => {
    setLoadingProvince(true);
    handleApi(getProvincesList, { countryCode: code }, { limit: 50, defaultData: null, infinitePage: true })
      .then((res) => {
        setProvinces((res.results || []).map((i) => ({ value: i.id, label: i.name, ...i })));
      })
      .finally(() => setLoadingProvince(false));
  };

  useEffect(() => {
    if (data?.country?.code && countries.length > 0) {
      handleSelectCountry(data.country.code);
    }
  }, [data, countries]);
  return (
    <>
      {hasCountry && (
        <ControlSelect
          isSearchable
          control={control}
          name="country"
          rules={{ required: 'Country is required' }}
          isLoading={countries.length === 0}
          onChangeValue={handleSelectCountry}
          defaultValue={data?.country?.code || ''}
          label="Country"
          options={countries}
          placeholder="Country"
          errorMessage={errors?.country?.message}
        />
      )}
      <ControlSelect
        isSearchable
        isDisabled={provinces.length === 0}
        isLoading={loadingProvince}
        maxMenuHeight={200}
        control={control}
        rules={{ required: 'Province is required' }}
        name="province"
        label="Province"
        options={provinces}
        defaultValue={data?.province || ''}
        onChange={onChangeProvince ? (e) => onChangeProvince(e) : undefined}
        placeholder="Province"
        errorMessage={errors?.province?.message}
      />
      <ControlInput
        control={control}
        name="city"
        defaultValue={data?.city || ''}
        label="City"
        placeholder="City"
        errorMessage={errors?.city?.message}
      />
    </>
  );
};

LocationCitySelector.propTypes = {
  data: PropTypes.any,
  errors: PropTypes.any,
  control: PropTypes.any,
  hasCountry: PropTypes.bool,
  onChangeProvince: PropTypes.func,
};

export default LocationCitySelector;
