import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/libs/lib.react-crop.scss';
import ReactCrop from 'react-image-crop';

const getCroppedImage = (image, crop, fileName, extensionType = 'image/jpeg') => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // return new Promise((resolve) => {
  //     canvas.toBlob((blob) => {
  //         blob.name = fileName;
  //         resolve(blob);
  //     }, fileType ? fileType : 'image/jpeg', 1);
  // });
  return new Promise((resolve) => {
    try {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const name = fileName.split('.')[0];
            const nameExt = fileName.split('.')[1];
            const blobExt = blob.type.split('/')[1];
            blob.name = nameExt === blobExt ? fileName : `${name}.${blobExt}`;
            resolve(blob);
          }
        },
        extensionType,
        1,
      );
    } catch (e) {}
  });
};

const CropImage = ({ src = null, config = null, fileName = null, onChange }) => {
  const [crop, setCrop] = useState({
    // aspect: 16 / 9,
    // aspect: 3 / 4,
    aspect: undefined,
    unit: 'px', // default, can be 'px' or '%'
    x: 0,
    y: 0,
    // width: 90,
    // height: 120,
    // width: 120,
    // height: 120,
    width: undefined,
    height: undefined,
  });
  const imageRef = useRef(null);
  const [imageData, setImageData] = useState({ type: 'image/jpeg', extension: 'jpeg' });

  useEffect(() => {
    if (typeof src !== 'string') {
      const file = src?.file;
      if (file?.type && file?.name) {
        const imgSplit = file.name.split('.');
        const extension = imgSplit[imgSplit.length - 1];
        setImageData({ type: file.type, extension });
      }
    }
  }, [src]);

  useEffect(() => {
    if (config && !crop.width && !crop.height) {
      setCrop((prevCrop) => ({ ...prevCrop, ...(config || {}) }));
    }
  }, [src, config, crop]);

  const makeClientCrop = () => {
    const image = imageRef.current;

    const date = new Date().getTime();
    const fileNameDate = date.toString();
    fileName = `${fileName ? fileName : fileNameDate}.${imageData.extension || 'jpeg'}`;
    const extensionType = `image/${fileName.split('.').reverse()[0]}`;
    if (image) {
      if (!crop.width && !crop.height) {
        const imageCrop = {
          ...crop,
          width: image?.width,
          height: image?.height,
        };
        setCrop(imageCrop);
        getCroppedImage(image, imageCrop, fileName, extensionType).then((e) => {
          onChange(e);
          console.log('getCroppedImage 1', e);
        });
      } else {
        getCroppedImage(image, crop, fileName, extensionType).then((e) => {
          onChange(e);
          console.log('getCroppedImage 2', e);
        });
        // getCroppedImage(src, crop, fileName).then(croppedImageUrl => {
        //     this.setState({ croppedImageUrl, imgUrl: URL.createObjectURL(croppedImageUrl) });
        // })
      }
    }
  };
  const handleImageLoaded = useCallback((image) => {
    imageRef.current = image;
    makeClientCrop();
  }, []);

  if (src === null) return null;
  return (
    <div className="c-crop-image-container">
      <ReactCrop
        // src={typeof src !== 'string' ? src.src : src}
        src={typeof src !== 'string' ? src.src : src}
        renderComponent={typeof src === 'string' ? <img src={src} alt="cropImage" /> : undefined}
        crop={crop}
        onChange={setCrop}
        imageStyle={{ maxHeight: '70vh' }}
        crossorigin="Anonymous"
        onComplete={makeClientCrop}
        onImageLoaded={handleImageLoaded}
      />
    </div>
  );
};

CropImage.propTypes = {
  src: PropTypes.any,
  config: PropTypes.any,
  fileName: PropTypes.any,
  onChange: PropTypes.func,
};

export default CropImage;
