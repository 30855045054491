import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import Dropdown from '../../UIKit/Dropdown';
import { handleApiDeleteById, handleApiSave } from '../../../../utilities/useApi';
import { deleteAppointment } from '../../../../api';
import { updateStatusAppointment } from '../../../../api/appointments';
import toaster from 'toasted-notes';
import AppointmentCancelModal from './AppointmentCancelModal';

const AppointmentOptions = ({ data, loadingFn, onUpdate, onRefund, onCheckout }) => {
  const [cancelModal, setCancelModal] = useState(false);
  const isMarketplace = data?.sale_channel === 'marketplace';

  const options = useMemo(() => {
    // no_show => Undo No Show
    // cancel => Undo Cancel
    // arrived => Delete, Cancel, Undo Arrived, Confirm
    if (data) {
      let results = [];
      const deleteOption = {
        type: 'node',
        node: 'Delete',
        id: 'apptOptDelete',
        textColor: 'danger',
        className: 'dropdown-link',
        onClick() {
          const optionsDelete = {
            title: 'Confirm to delete this appointment',
            message: 'Are you sure to delete this appointment.',
            loadingFn,
          };
          handleApiDeleteById(deleteAppointment, data.id, optionsDelete).then(() => onUpdate(+data.id));
        },
      };
      const undoOption = (title) => ({
        type: 'node',
        node: title,
        id: 'apptOptStatusUndo',
        className: 'dropdown-link',
        onClick() {
          handleApiSave(undefined, updateStatusAppointment, { status: 'set-confirmed' }, data.id, { loadingFn })
            .then((d) => {
              toaster.notify(() => <div className="alert alert-success">Appointment set up to New</div>);
              onUpdate(d);
            })
            .catch(() => {});
        },
      });
      const noShowOption = {
        type: 'node',
        node: 'No Show',
        id: 'apptOptStatusNoShow',
        textColor: '#FF6F00',
        className: 'dropdown-link',
        onClick() {
          handleApiSave(undefined, updateStatusAppointment, { status: 'set-no-show' }, data.id, { loadingFn })
            .then((d) => {
              toaster.notify(() => <div className="alert alert-success">Appointment set up to No Show</div>);
              onUpdate(d);
            })
            .catch(() => {});
        },
      };
      const cancelOption = {
        type: 'node',
        node: 'Cancel',
        id: 'apptOptCancel',
        textColor: 'danger',
        className: 'dropdown-link',
        onClick: () => setCancelModal(true),
      };
      const refundOption = {
        type: 'node',
        node: 'Refund',
        id: 'apptOptRefund',
        textColor: 'danger',
        className: 'dropdown-link',
        onClick: () => onRefund(true),
      };
      const checkoutOption = {
        type: 'node',
        id: 'apptOptCheckout',
        node: 'Checkout',
        className: 'dropdown-link',
        onClick: onCheckout,
      };

      if (data.payment_status === 'part_paid') {
        results = [checkoutOption];
      } else if (data.payment_status === 'part_refunded') {
        switch (data.status) {
          case 'no_show':
            results = [undoOption('Undo No Show'), refundOption];
            break;
          case 'client_cancelled':
            results = [refundOption];
            break;
          case 'business_cancelled':
            results = [undoOption('Undo Cancel'), refundOption];
            break;
          case 'arrived':
            results = [deleteOption, cancelOption, undoOption('Undo Arrived'), refundOption];
            if (isMarketplace) results.shift();
            break;
          default:
            results = [noShowOption, cancelOption, refundOption];
          // results = [noShowOption, cancelOption, arrivedOption];
        }
      } else if (data.payment_status === 'refunded') {
        switch (data.status) {
          case 'no_show':
            results = [undoOption('Undo No Show')];
            break;
          case 'client_cancelled':
            results = [];
            break;
          case 'business_cancelled':
            results = [undoOption('Undo Cancel')];
            break;
          case 'arrived':
            results = [deleteOption, cancelOption, undoOption('Undo Arrived')];
            if (isMarketplace) results.shift();
            break;
          default:
            results = [noShowOption, cancelOption];
          // results = [noShowOption, cancelOption, arrivedOption];
        }
      } else if (data.payment_status === 'paid' || data.payment_status === 'part_refund') {
        switch (data.status) {
          case 'no_show':
            results = [undoOption('Undo No Show'), refundOption];
            break;
          case 'client_cancelled':
            results = [refundOption];
            break;
          case 'business_cancelled':
            results = [undoOption('Undo Cancel'), refundOption];
            break;
          case 'arrived':
            results = [deleteOption, cancelOption, undoOption('Undo Arrived'), refundOption];
            if (isMarketplace) results.shift();
            break;
          default:
            results = [noShowOption, cancelOption, refundOption];
          // results = [noShowOption, cancelOption, arrivedOption];
        }
      } else {
        switch (data.status) {
          case 'no_show':
            results = [undoOption('Undo No Show')];
            break;
          // case 'cancel':
          // case 'cancelled':
          case 'client_cancelled':
            results = [];
            break;
          case 'business_cancelled':
            results = [undoOption('Undo Cancel')];
            break;
          case 'arrived':
            results = [deleteOption, cancelOption, undoOption('Undo Arrived')];
            if (isMarketplace) results.shift();
            break;
          default:
            // results = [deleteOption, noShowOption, cancelOption, arrivedOption, checkoutOption];
            results = [deleteOption, noShowOption, cancelOption, checkoutOption];
            if (isMarketplace) {
              results.shift();
              results.pop();
            }
        }
      }

      return results;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isMarketplace]);

  return (
    <>
      {cancelModal && (
        <AppointmentCancelModal
          data={data}
          loadingFn={loadingFn}
          onClose={() => setCancelModal(false)}
          onSave={onUpdate}
        />
      )}
      <Dropdown block positionMenu="top" options={options}>
        <Button id="appointmentOptions" block color="light">
          Options
        </Button>
      </Dropdown>
    </>
  );
};

AppointmentOptions.propTypes = {
  data: PropTypes.any,
  loadingFn: PropTypes.func,
  onUpdate: PropTypes.func,
  onRefund: PropTypes.func,
  onCheckout: PropTypes.func,
};

export default AppointmentOptions;
