import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../UIKit/Form/Button';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';

const CalendarViewSelector = ({ url = null, dataTour }) => {
  const [viewPreset, setViewPreset] = useState('day');

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const view = getQueryUri('view');
    if (view) {
      setViewPreset(view);
    } else if (!!url && url === location.pathname) {
      handleViewPreset('day');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleViewPreset = (value) => {
    const params = setQueryUri({
      ...getQueryUri(),
      view: value.toLowerCase(),
    });
    history.replace(params);
  };

  return (
    <Button
      data-tour={dataTour}
      node={'select'}
      id="calendarViewSelector"
      className="ml-3"
      onChange={(e) => handleViewPreset(e.target.value)}
      value={viewPreset}
    >
      <option value="day" id="calendarViewDay">
        Day
      </option>
      <option value="week" id="calendarViewWeek">
        Week
      </option>
      <option value="month" id="calendarViewMonth">
        Month
      </option>
    </Button>
  );
};

CalendarViewSelector.propTypes = {
  dataTour: PropTypes.string,
  url: PropTypes.string,
};

export default CalendarViewSelector;
