import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import Modal from '../../UIKit/Modal';
import { Controller, useForm } from 'react-hook-form';
import ImageSelector from '../../UIKit/ImageSelector';
import { IMAGE_DEFAULT_USER } from '../../../../assets/Images';
import Input, { ControlInput } from '../../UIKit/Form/Input';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { changePassword, getProfile, updateProfile } from '../../../../api';
import { AppContext } from '../../../../context/providers';
import Button from '../../UIKit/Form/Button';
import EmailFormModal from '../../Settings/Profile/EmailFormModal';
import MobileFormModal from '../../Settings/Profile/MobileFormModal';
import Tab from '../../UIKit/Tab';

const UserProfileModal = ({ onClose }) => {
  const { control, errors, handleSubmit, setError, reset } = useForm();
  const { user, setUser } = useContext(AppContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [mobileModal, setMobileModal] = useState(false);
  const [tab, setTab] = useState(0);
  useEffect(() => {
    handleApi(getProfile).then((data) => {
      setUser(data);
    });
  }, []);
  const onSubmit = (data) => {
    const body = {
      ...data,
      avatar: !!data.avatar && typeof data.avatar !== 'string' ? data.avatar : null,
    };
    handleApiSave(updateProfile, null, body, null, { loadingFn: setSaveLoading, setError })
      .then((res) => {
        setUser(res);
        onClose();
        toaster.notify(() => <div className="alert alert-success mt-3">Profile Changed Successfully</div>);
      })
      .catch(() => {});
  };
  const onChangePassword = ({ current_password, new_password, confirm_password }) => {
    if (confirm_password.trim() !== new_password.trim()) {
      setError('confirm_password', {
        type: 'manual',
        message: 'confirmation password is not matched',
      });
    } else {
      const body = {
        current_password,
        new_password,
      };
      handleApiSave(changePassword, null, body, null, { loadingFn: setSaveLoading, setError })
        .then(() => {
          toaster.notify(() => <div className="alert alert-success mt-3">Password Changed Successfully</div>);
          reset();
          onClose();
        })
        .catch(() => {});
    }
  };
  const handleSetData = () => {
    handleApi(getProfile, {}, { list: false }).then(setUser);
    setEmailModal(false);
    setMobileModal(false);
  };
  console.log('tab', tab, typeof tab);
  return (
    <>
      <Modal
        show
        lg
        sticky
        title="Profile"
        contentClass="c-user-stylist-profile__modal c-user-stylist-profile__modal--sm"
        bodyClass="c-user-stylist-profile__modal-body"
        onClose={onClose}
        saveLoading={saveLoading}
        onSave={tab === 0 ? handleSubmit(onSubmit) : handleSubmit(onChangePassword)}
      >
        <Tab
          isModal
          isMerely
          dataTourHead="modal-tab"
          onSelect={setTab}
          tabHeadClassName="c-user-stylist-profile__tab"
          tabs={['Profile Info', 'Change Password']}
        >
          {tab === 0 ? (
            <form className="c-user-stylist-profile__container" onSubmit={handleSubmit(onSubmit)}>
              <div className="c-user-stylist-profile__section">
                <Controller
                  control={control}
                  name="avatar"
                  defaultValue={user ? user?.avatar : ''}
                  render={({ onChange, value }) => (
                    <ImageSelector
                      id="image"
                      defaultImage={IMAGE_DEFAULT_USER}
                      className="c-settings__image-uploader"
                      onChange={onChange}
                      onDelete={onChange}
                      value={value}
                    />
                  )}
                />
                <section className="c-user-stylist-profile__content">
                  <ControlInput
                    control={control}
                    name="first_name"
                    defaultValue={!!user?.first_name ? user.first_name : ''}
                    label="First Name"
                    placeholder="First Name"
                    rules={{ required: 'First Name is required' }}
                    errorMessage={errors?.first_name?.message}
                  />
                  <ControlInput
                    control={control}
                    name="last_name"
                    defaultValue={!!user?.last_name ? user.last_name : ''}
                    label="Last Name"
                    placeholder="Last Name"
                    rules={{ required: 'Last Name is required' }}
                    errorMessage={errors?.last_name?.message}
                  />
                  <div className="c-form__group-row align-items-end">
                    <Input id="email" disabled label="Email" value={user ? user?.user?.email : ''} />
                    <div style={{ marginBottom: '10px' }}>
                      <Button type="button" color="info" onClick={() => setEmailModal(true)}>
                        Change
                      </Button>
                    </div>
                  </div>
                  <div className="c-form__group-row align-items-end">
                    <Input id={'mobile'} disabled label={'Mobile'} value={user ? user?.user?.mobile : ''} />
                    <div style={{ marginBottom: '10px' }}>
                      <Button type="button" color="info" onClick={() => setMobileModal(true)}>
                        Change
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            </form>
          ) : (
            <form className="c-user-stylist-profile__container" onSubmit={handleSubmit(onChangePassword)}>
              <div className="c-user-stylist-profile__section">
                <div className="c-user-stylist-profile__content">
                  <ControlInput
                    type="password"
                    control={control}
                    name="current_password"
                    rules={{ required: 'Current Password is required' }}
                    defaultValue=""
                    label={'Current Password'}
                    placeholder={'Current Password'}
                    errorMessage={errors?.current_password?.message}
                  />
                  <ControlInput
                    type="password"
                    control={control}
                    name="new_password"
                    rules={{ required: 'New Password is required' }}
                    defaultValue=""
                    label={'New Password'}
                    placeholder={'New Password'}
                    errorMessage={errors?.new_password?.message}
                  />
                  <ControlInput
                    type="password"
                    control={control}
                    name="confirm_password"
                    rules={{ required: 'Confirm Password is required' }}
                    defaultValue=""
                    label={'Confirm Password'}
                    placeholder={'Confirm Password'}
                    errorMessage={errors?.confirm_password?.message}
                  />
                </div>
              </div>
            </form>
          )}
        </Tab>
      </Modal>
      {emailModal && <EmailFormModal onClose={() => setEmailModal(false)} onSave={handleSetData} />}
      {mobileModal && <MobileFormModal onClose={() => setMobileModal(false)} onSave={handleSetData} />}
    </>
  );
};

UserProfileModal.propTypes = {
  onClose: PropTypes.func,
};

export default UserProfileModal;
