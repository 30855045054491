import { callApi } from '../../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../../config/apiConfig';
import CertificateObject from '../../objects/StylistProfile/CertificateObject';
const { GET, POST, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *    id: String,
 *    issued_date: String,
 *    expiration_date: String,
 *    course: String,
 *    institution: String,
 * }}
 */
export const saveCertificate = async ({ ...params }) => {
  const response = await callApi({
    method: params?.id ? PATCH : POST,
    url: params?.id
      ? createURL(ApiRoutes.stylistCertificates.update, { id: params.id })
      : ApiRoutes.stylistCertificates.create,
    params,
    absolute: true,
  });
  if (response.data) {
    return new CertificateObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const getCertificateList = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistCertificates.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new CertificateObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

export const getCertificate = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistCertificates.get,
  });
  if (response.data) {
    return new CertificateObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const deleteCertificate = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    absolute: true,
    url: createURL(ApiRoutes.stylistCertificates.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
