import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ModalFormSidebarContent, ModalFormSidebarHeader } from '../ModalForm/ModalFormSidebar';
import ModalFormSidebarFooter from '../ModalForm/ModalFormSidebarFooter';
import Button from '../UIKit/Form/Button';
import { getPriceFormat } from '../../../utilities/Price';
import { loadStripeTerminal } from '@stripe/terminal-js';
import { PageLoading } from '../UIKit/Spinner';
import { ICON_POS, ICON_SEARCH_FAILED, ICON_UNPLUGGED } from '../../../assets/Images';
import toaster from 'toasted-notes';
import Alert from '../UIKit/Alert';
import { handleApi } from '../../../utilities/useApi';
import { getStripeCreateToken, getStripePayment, setStripeCapturePayment } from '../../../api';
import { IS_DEV } from '../../../config/appConfig';

const CheckoutStripe = ({ price, invoiceId: _invoiceId, createInvoice, onClose, onSuccess }) => {
  const [terminal, setTerminal] = useState(null);
  const [errUnexpectedDisconnect, setErrUnexpectedDisconnect] = useState(false);
  const [discoveredReaders, setDiscoveredReaders] = useState(null);
  const [deviceReader, setDeviceReader] = useState(null);
  const [invoiceId, setInvoiceId] = useState(_invoiceId);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    connectTerminal();
  }, []);
  useEffect(() => {
    setInvoiceId(_invoiceId);
  }, [_invoiceId]);

  const unexpectedDisconnect = () => {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log('Disconnected from reader');
    setErrUnexpectedDisconnect(true);
  };

  const fetchConnectionToken = () =>
    // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
    handleApi(getStripeCreateToken, {}, { loadingFn: setLoading }).then((data) => data?.connection_token);

  const connectTerminal = async () => {
    setErrUnexpectedDisconnect(false);
    const StripeTerminal = await loadStripeTerminal();
    setTerminal(
      StripeTerminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: unexpectedDisconnect,
      }),
    );
  };

  useEffect(() => {
    if (terminal) {
      discoverReaderHandler();
    }
  }, [terminal]);

  const discoverReaderHandler = () => {
    if (terminal) {
      setDeviceLoading(true);
      const config = { simulated: true };
      terminal
        .discoverReaders(config)
        .then((discoverResult) => {
          console.log('error', discoverResult?.error);
          if (discoverResult?.error) {
            console.log('Failed to discover: ', discoverResult.error);
            setDeviceLoading(false);
          } else if (discoverResult.discoveredReaders.length === 0) {
            console.log('No available readers.');
            setDeviceLoading(false);
          } else {
            if (discoverResult.discoveredReaders.length === 1 && terminal.connectionStatus === 'not_connected') {
              handleConnectReader(discoverResult.discoveredReaders[0]);
            } else {
              setDeviceLoading(false);
            }
            setDiscoveredReaders(discoverResult.discoveredReaders);
          }
        })
        .catch((err) => {
          console.log(err);
          setDeviceLoading(false);
        });
      setDiscoveredReaders(null);
    }
  };
  const handleConnectReader = (selectedReader) => {
    setDeviceLoading(true);
    terminal
      .connectReader(selectedReader)
      .then((connectResult) => {
        if (connectResult.error) {
          console.log('Failed to connect: ', connectResult.error);
          toaster.notify(() => <Alert>{connectResult.error}</Alert>, {
            position: 'bottom',
          });
          setDeviceLoading(false);
        } else {
          if (connectResult.reader) {
            setDeviceLoading(false);
          }
          setDeviceReader(connectResult.reader);
        }
      })
      .catch((err) => {
        console.log(err);
        setDeviceLoading(false);
      });
  };

  const handleCancelPayment = async () => {
    try {
      await terminal.cancelCollectPaymentMethod();
    } finally {
      setPaymentLoading(false);
    }
  };

  const handlePayment = async () => {
    try {
      // const connectionStatus = await terminal.getConnectionStatus(); // connecting, connected, not_connected
      // const paymentStatus = await terminal.getPaymentStatus(); // not_ready, ready, waiting_for_input, processing
      // if (connectionStatus === 'not_connected' || paymentStatus === 'ready') {
      //   return;
      // }
      let id = invoiceId;
      setPaymentLoading(true);
      if (!id) {
        const res = await createInvoice();
        id = res.id;
        setInvoiceId(id);
      }
      const response = await handleApi(getStripePayment, { amount: price, id });
      if (response.client_secret) {
        if (IS_DEV) {
          await terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' });
        }
        try {
          const result = await terminal.collectPaymentMethod(response.client_secret);
          console.log('result', result);
          if (result.error) {
            // Placeholder for handling result.error
            setErrUnexpectedDisconnect(result.error);
          } else {
            console.log('terminal.collectPaymentMethod', result.paymentIntent);
            const res = await terminal.processPayment(result.paymentIntent);
            if (res.error) {
              setErrUnexpectedDisconnect(res.error);
            } else if (res.paymentIntent) {
              console.log('res.paymentIntent', res.paymentIntent);
              const resCapture = await handleApi(setStripeCapturePayment, {
                paymentIntent: res.paymentIntent.id,
                id,
              });
              // console.log('res', res);
              console.log('resCapture', resCapture);
              // console.log('result.paymentIntent', res.paymentIntent);
              // paymentIntentId = result.paymentIntent.id;
              toaster.notify(() => <Alert success>Your payment was successful</Alert>, { position: 'bottom' });
              onSuccess(res.paymentIntent);
            }
          }
        } catch (e) {
          console.log(e);
          setPaymentLoading(false);
        }
      } else {
        setErrUnexpectedDisconnect('There is an error payment in stripe process. Please tell support team!');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setPaymentLoading(false);
    }
  };

  const handleBack = () => {
    if ((discoveredReaders || []).length > 1 && deviceReader) {
      terminal.disconnectReader(deviceReader).then(() => {
        setDeviceReader(null);
      });
    } else {
      if (terminal && terminal.connectionStatus) {
        terminal.disconnectReader(deviceReader).then(() => {
          onClose();
        });
      } else {
        onClose();
      }
    }
  };
  return (
    <ModalFormSidebarContent>
      <ModalFormSidebarHeader title="Stripe Payment" onClose={handleBack} />
      <ModalFormSidebarContent>
        {deviceLoading || loading ? (
          <PageLoading />
        ) : terminal ? (
          <>
            {errUnexpectedDisconnect ? (
              <div className="c-checkout-stripe__error">
                <img className="c-checkout-stripe__error-img" src={ICON_UNPLUGGED} alt="terminal error" />
                <h4 className="c-checkout-stripe__error-title">Terminal Disconnected</h4>
                <p className="c-checkout-stripe__error-text">
                  Unexpected disconnection, Make sure the wires, bluetooth, and the server are working properly.
                </p>
                <Button block onClick={connectTerminal}>
                  Reconnect To Terminal
                </Button>
              </div>
            ) : !discoveredReaders ? (
              <div className="c-checkout-stripe__error">
                <img className="c-checkout-stripe__error-img" src={ICON_SEARCH_FAILED} alt="No device detected" />
                <h4 className="c-checkout-stripe__error-title">No Device Found</h4>
                <p className="c-checkout-stripe__error-text">Please try again, or contact support.</p>
                <Button block onClick={discoverReaderHandler}>
                  Discover Device
                </Button>
              </div>
            ) : deviceReader ? (
              <>
                <div className="c-checkout-stripe__reader">
                  <img className="c-checkout-stripe__reader-img" src={ICON_POS} alt={deviceReader.label} />
                  <span className="c-checkout-stripe__reader-detail">
                    <span className="c-checkout-stripe__reader-label">{deviceReader.label}</span>
                    <span className="c-checkout-stripe__reader-meta">
                      <strong>IP Address: </strong>
                      {deviceReader.ip_address}
                    </span>
                    <span className="c-checkout-stripe__reader-meta">
                      <strong>Serial Number: </strong>
                      {deviceReader.serial_number}
                    </span>
                    <span className="c-checkout-stripe__reader-meta">
                      <strong>Location: </strong>
                      {deviceReader.location}
                    </span>
                  </span>
                  <span
                    className={classNames('c-checkout-stripe__reader-status', {
                      'c-checkout-stripe__reader-status--online': deviceReader.status === 'online',
                      'c-checkout-stripe__reader-status--offline': deviceReader.status === 'offline',
                    })}
                  >
                    {deviceReader.status}
                  </span>
                </div>
              </>
            ) : (
              <ul>
                <li className="c-checkout-stripe__readers-title">Select Device</li>
                {(discoveredReaders || []).map((reader) => (
                  <li
                    key={reader.id}
                    className="c-checkout-stripe__reader c-checkout-stripe__reader--clickable"
                    onClick={() => handleConnectReader(reader)}
                  >
                    <img className="c-checkout-stripe__reader-img" src={ICON_POS} alt={reader.label} />
                    <span className="c-checkout-stripe__reader-detail">
                      <span className="c-checkout-stripe__reader-label">{reader.label}</span>
                      <span className="c-checkout-stripe__reader-meta">
                        <strong>IP Address: </strong>
                        {reader.ip_address}
                      </span>
                      <span className="c-checkout-stripe__reader-meta">
                        <strong>Serial Number: </strong>
                        {reader.serial_number}
                      </span>
                      <span className="c-checkout-stripe__reader-meta">
                        <strong>Location: </strong>
                        {reader.location}
                      </span>
                    </span>
                    <span
                      className={classNames('c-checkout-stripe__reader-status', {
                        'c-checkout-stripe__reader-status--online': reader.status === 'online',
                        'c-checkout-stripe__reader-status--offline': reader.status === 'offline',
                      })}
                    >
                      {reader.status}
                    </span>
                  </li>
                ))}
              </ul>
            )}
            {paymentLoading && (
              <div className="p-3">
                <Button block color="light" onClick={handleCancelPayment}>
                  CANCEL PAYMENT
                </Button>
              </div>
            )}
          </>
        ) : null}
      </ModalFormSidebarContent>
      {deviceReader && (
        <ModalFormSidebarFooter>
          <Button block disabled={!discoveredReaders} loading={paymentLoading} onClick={handlePayment}>
            <span className="mr-3">{getPriceFormat(price)}</span>
            PAY BY STRIPE
          </Button>
        </ModalFormSidebarFooter>
      )}
    </ModalFormSidebarContent>
  );
};

CheckoutStripe.propTypes = {
  invoiceId: PropTypes.any,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  createInvoice: PropTypes.func,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default CheckoutStripe;
