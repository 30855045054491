import React, { useContext, useEffect, useState } from 'react';
import SetupSoftwareProfileForm from './SetupSoftwareProfileForm';
import SetupSoftwareCongratulations from './SetupSoftwareCongratulations';
import SetupSoftwarePlans from './SetupSoftwarePlans';
import SetupSoftwareSubscribe from './SetupSoftwareSubscribe';
import { AppContext } from '../../../../context/providers';
import SetupHeadSteps from '../../Setup/SetupHeadSteps';
import { SoftwareStepList } from '../../../../utilities/constants/setup';
import PreviewSetupSoftware from './PreviewSetupSoftware';
import SetupSide from '../../Setup/SetupSide';

// eslint-disable-next-line react/prop-types
const SetupSoftwareContent = ({ isNew = false, goal = 'manage', reference, onClose, onCloseLayout }) => {
  const [plan, setPlan] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [step, setStep] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const { business, newBusiness } = useContext(AppContext);

  useEffect(() => {
    if (isNew) {
      if (reference && newBusiness && (newBusiness.subscribed || newBusiness.is_trial)) {
        setIsStarted(true);
      }
    } else {
      if (reference && business && (business.subscribed || business.is_trial)) {
        setIsStarted(true);
      }
    }
  }, [business, reference, isNew, newBusiness]);

  useEffect(() => {
    if (reference && isStarted) {
      setStep(3);
    } else if (isStarted) setStep(0);
  }, [isStarted, reference]);

  return (
    <div className="c-setup-system-static">
      <SetupSide list={SoftwareStepList} isPreview={!isStarted} step={step} />
      <div className="c-setup-system-content">
        {isStarted ? (
          <>
            <SetupHeadSteps list={SoftwareStepList} active={step} />
            <div className="c-setup-system__form">
              {step === 0 ? (
                <SetupSoftwareProfileForm isNew={isNew} isSoftware goal={goal} onNext={() => setStep(step + 1)} />
              ) : step === 1 ? (
                <SetupSoftwarePlans
                  isNew={isNew}
                  onPrev={() => setStep(step - 1)}
                  onNext={({ sessionId, item }) => {
                    setSessionId(sessionId);
                    setPlan(item);
                    setStep(step + 1);
                  }}
                />
              ) : step === 2 ? (
                <SetupSoftwareSubscribe
                  isNew={isNew}
                  sessionId={sessionId}
                  planId={plan?.id}
                  onPrev={() => setStep(step - 1)}
                  onNext={() => setStep(step + 1)}
                  onClose={onClose}
                />
              ) : (
                <SetupSoftwareCongratulations
                  isNew={isNew}
                  onSave={() => {
                    onClose();
                    onCloseLayout(true);
                  }}
                  onClose={() => {
                    onClose();
                    onCloseLayout(true);
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <PreviewSetupSoftware onStarted={() => setIsStarted(true)} />
        )}
      </div>
    </div>
  );
};

export default SetupSoftwareContent;
