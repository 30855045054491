import React from 'react';
import { AppointmentStatusChoice } from '../../../utilities/constants/choices';

// Confirmed / Pending / Fulfilled / Cancelled / No Shows / Arrived => statuses

export const getAptStatusName = (status) => {
  switch (status) {
    case 'pending':
      return <span className="badge badge-warning">{AppointmentStatusChoice[status]}</span>;
    case 'confirmed':
      return <span className="badge badge-success">{AppointmentStatusChoice[status]}</span>;
    case 'fulfilled':
      return <span className="badge badge-warning">{AppointmentStatusChoice[status]}</span>;
    case 'cancelled':
    case 'business_cancelled':
    case 'client_cancelled':
      return <span className="badge badge-danger">{AppointmentStatusChoice[status]}</span>;
    case 'no_shows':
      return <span className="badge badge-warning">{AppointmentStatusChoice[status]}</span>;
    case 'arrived':
      return <span className="badge badge-warning">{AppointmentStatusChoice[status]}</span>;
    default:
      return null;
  }
};
