export const getQueryUri = (keyFind = null, urlQuery = null) => {
  try {
    // set default if no url query string passed
    if (!urlQuery) urlQuery = window.location.search;
    // check the typeof value passed
    if (typeof urlQuery !== 'string') return {};
    const params = new URLSearchParams(urlQuery);

    // if pass a string as a key
    if (typeof keyFind === 'string') return params.get(keyFind);

    // if pass a array of string as a key
    if (Array.isArray(keyFind))
      return keyFind.reduce(
        (acc, obj) => ({
          ...acc,
          [obj]: typeof obj === 'string' ? params.get(obj) : undefined,
        }),
        {},
      );

    // if don't pass anything
    const result = {};
    for (const entry of params.entries()) result[entry[0]] = entry[1];
    return result;
  } catch (e) {
    return keyFind ? null : {};
  }
};

export const setQueryUri = (query = {}) => {
  const lastQueriesString = [];
  if (!query) return '';
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (!!value) lastQueriesString.push(`${key}=${encodeURI(value)}`);
  });

  return `?${lastQueriesString.join('&')}`;
};
