/**
 *
 * @param invoice {{
 *     id
 *     appointment
 *     lines,
 *     client
 *     client_name,
 *     client_email,
 *     tips,
 *     balance
 *     tax_amount
 *     tax_rate
 *     total_tips
 *     tips_amount
 *     total_payments
 *     total_refunds
 *     subtotal
 *     status
 *     transactions,
 *     payment_status
 *     created_at
 *     updated_at
 *     stripe_payment_available
 *     stripe_refund_available
 * }}
 * @constructor
 */

function InvoiceObject(invoice) {
  this.id = invoice.id;
  this.appointment = invoice.appointment;
  this.client = invoice.client;
  this.client_name = invoice.client_name;
  this.client_email = invoice.client_email;
  this.transactions = (invoice.transactions || []).map((p) => new InvoicePaymentObject(p));
  this.tips = invoice.tips;
  this.total_tips = invoice.total_tips;
  this.total = invoice.total_payments;
  this.total_payments = invoice.total_payments;
  this.total_refunds = invoice.total_refunds;
  this.tax_rate = invoice.tax_rate;
  this.tax_amount = invoice.tax_amount;
  this.balance = invoice.balance;
  this.subtotal = invoice.subtotal;
  this.is_stripe_payment_available = invoice.stripe_payment_available;
  this.is_stripe_refund_available = invoice.stripe_refund_available;
  this.status = invoice.status || invoice.payment_status;
  // this.created_at = invoice.created_at ? invoice.created_at.replace(' UTC+0000', '') : '';
  this.lines = (invoice.lines || []).map((i) => new InvoiceLineObject(i));
}

/**
 *
 * @param line {{
 *     id
 *     duration,
 *     discount_type
 *     discount_amount
 *     item_id
 *     item_type
 *     item_name
 *     price
 *     reserved_date,
 *     reserved_time,
 *     service
 *     service_name
 *     staff
 *     staff_name
 *     sold_item_id
 *     created_at
 *     updated_at
 * }}
 * @constructor
 */

export function InvoiceLineObject(line) {
  this.id = line.id;
  this.duration = line.duration;
  this.discount_type = line.discount_type;
  this.discount_amount = line.discount_amount;
  this.item_id = line.item_id;
  this.item_name = line.item_name;
  this.item_type = line.item_type;
  this.price = line.price;
  this.reserved_date = line.reserved_date;
  this.reserved_time = line.reserved_time;
  this.staff = line.staff;
  this.staff_name = line.staff_name;
  this.sold_item_id = line.sold_item_id || null;
}

/**
 *
 * @param payment {{
 *     id
 *     type,
 *     amount,
 *     method
 *     stripe_session_id
 *     voucher_code
 *     sold_item_id
 *     created_at
 * }}
 * @constructor
 */

export function InvoicePaymentObject(payment) {
  this.id = payment.id;
  this.type = payment.type;
  this.amount = payment.amount;
  this.method = payment.method;
  this.stripe_session_id = payment.stripe_session_id;
  this.sold_item_id = payment.sold_item_id || null;
  this.voucher_code = payment.voucher_code;
  this.created_at = payment.created_at ? payment.created_at.replace(' UTC+0000', '') : '';
}

export default InvoiceObject;
