import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes } from '../config/apiConfig';
import { getParams } from '../utilities/ParamHelper';

const { POST, PUT, PATCH, GET } = ApiMethods;

// const getParams = (params, imageName = 'avatar') => {
//   if (!!params[imageName]) {
//     const formData = new FormData();
//     Object.keys(params).forEach((key) => {
//       if (!!params[key] && key !== imageName) formData.append(key, params[key]);
//     });
//     formData.append(imageName, params[imageName].file);
//     return formData;
//   }
//   return params;
// };

export const sendVerificationIdentity = async ({ identity_type }) => {
  const response = await callApi({
    method: POST,
    params: { identity_type },
    url: ApiRoutes.user.sendVerification,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const verifyCodeIdentity = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.user.verify,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const changeEmail = async (params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: ApiRoutes.user.changeEmail,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const changeIdentity = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.user.changeIdentity,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const changeIdentityWithHeader = async ({ token, ...params }) => {
  const response = await callApi({
    method: POST,
    params,
    headers: { Authorization: `Bearer ${token}` },
    url: ApiRoutes.user.changeIdentity,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const getProfile = async () => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.user.getProfile,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const updateProfile = async (params) => {
  const response = await callApi({
    method: PUT,
    params: getParams(params),
    headers: !!params.avatar
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
    url: ApiRoutes.user.updateProfile,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const partialUpdateProfile = async (params) => {
  const response = await callApi({
    method: PATCH,
    params: getParams(params),
    headers: !!params.avatar
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
    url: ApiRoutes.user.updateProfile,
    locationBase: false,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};
