import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IMAGE_LOGO } from '../../../assets/Images';

const AuthenticationLayout = ({ className, children }) => (
  <div className="c-auth">
    <div className={classNames('c-auth__container', className)}>
      <div className="c-auth__body">
        <img src={IMAGE_LOGO} className="c-auth__logo" alt="Beautster Business" />
        {children}
      </div>
    </div>
  </div>
);

AuthenticationLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
};

export default AuthenticationLayout;
