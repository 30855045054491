import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// eslint-disable-next-line react/display-name
const InputNum = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ id, value, unit, step, unitPos, controller, onChange, onChangeText, className, ...otherProps }, ref) => {
    const [num, setNum] = useState(value ? value : 0);
    useEffect(() => {
      if (!isNaN(value)) setNum(+value);
    }, [value]);

    const handleIncrease = (e) => {
      e.preventDefault();
      const updateVal = (+num + 1).toFixed(2);
      setNum(updateVal);
      onChange(updateVal);
    };
    const handleDecrease = (e) => {
      e.preventDefault();
      const updateVal = (+num - 1).toFixed(2);
      setNum(updateVal);
      onChange(updateVal);
    };
    const handleOnChange = (e) => {
      onChangeText(e.target.value);
      onChange(e);
    };

    return (
      <div
        className={classnames(
          'c-form__controller',
          {
            'c-form__controller--disabled': otherProps.disabled,
          },
          className,
        )}
      >
        <input type="number" value={num} ref={ref} onChange={handleOnChange} id={id} {...otherProps} />
        {controller && (
          <div className="c-form__controller-controls">
            <button onClick={handleDecrease}>
              <i className="fas fa-minus" />
            </button>
            <button onClick={handleIncrease}>
              <i className="fas fa-plus" />
            </button>
          </div>
        )}
        {unit && <span className="c-form__controller-unit">{unit}</span>}
      </div>
    );
  },
);

InputNum.propTypes = {
  id: PropTypes.string,
  controller: PropTypes.bool,
  unit: PropTypes.string,
  value: PropTypes.any,
  step: PropTypes.number,
  className: PropTypes.any,
  unitPos: PropTypes.oneOf(['before', 'after']),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
};

InputNum.defaultProps = {
  id: null,
  step: 1,
  controller: false,
  unit: '',
  unitPos: 'after',
  onChange: () => {},
  onChangeText: () => {},
};

export default InputNum;
