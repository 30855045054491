import React, { useContext, useState } from 'react';
import { Help, YouTube, CastForEducation } from '@material-ui/icons';
import PropTypes from 'prop-types';
import UserHeader from './UserHeader';
import BurgerMenu from './BurgerMenu';
import SwitchHeader from './SwitchHeader';
import { AppContext } from '../../../../context/providers';
import LocationHeader from './LocationHeader';
import Backdrop from '../../UIKit/Backdrop';
import { TourGuide } from '../../UIKit/TourContent';
import VideoTutorialModal from '../VideoTutorialModal';

const Header = ({ onToggle }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const { header } = useContext(AppContext);

  const handleShowTour = () => {
    setIsTourOpen(true);
    setShowInfo(false);
  };
  const handleShowVideo = () => {
    setIsShowVideo(true);
    setShowInfo(false);
  };
  const handleCloseTour = () => {
    setTourStep(0);
    setIsTourOpen(false);
  };
  return (
    <>
      {showInfo && <Backdrop transparent show onClick={() => setShowInfo(false)} />}
      {header?.guide && (
        <TourGuide
          disableInteraction
          steps={header?.guide}
          startAt={tourStep}
          getCurrentStep={setTourStep}
          isOpen={isTourOpen}
          onRequestClose={handleCloseTour}
        />
      )}
      {isShowVideo && header?.videoId && (
        <VideoTutorialModal videoId={header?.videoId} onClose={() => setIsShowVideo(false)} />
      )}
      <div className="c-panel-header">
        <BurgerMenu onToggle={onToggle} />
        <div className="c-panel-header__items">
          {/*<div className="c-panel-header__search">*/}
          {/*    <i className="fas fa-search" />*/}
          {/*</div>*/}
          {/*<SearchHeader />*/}
          {header?.title && (
            <div className="c-panel-header__head">
              <h1 className="c-panel-header__title">{header?.title}</h1>
              {(header?.videoId || header?.guide || header?.guideAction) && (
                <div className="c-panel-header__info-container">
                  <div className="c-panel-header__info" onClick={() => setShowInfo(true)}>
                    <Help />
                  </div>
                  {showInfo && (
                    <ul className="c-panel-header__info-menu">
                      {header?.videoId && (
                        <li className="c-panel-header__info-item" onClick={handleShowVideo}>
                          <YouTube /> Video Tutorial
                        </li>
                      )}
                      {(header?.guide || header?.guideAction) && (
                        <li className="c-panel-header__info-item" onClick={header?.guideAction || handleShowTour}>
                          <CastForEducation /> Page Guide
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>
          )}
          <SwitchHeader />
          {/*<NotificationHeader />*/}
          <LocationHeader />
          <UserHeader />
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  onToggle: PropTypes.func,
};

export default Header;
