import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { ControlStaffSelect } from '../Selections/StaffSelect';
import Button from '../UIKit/Form/Button';
import { Controller, useForm } from 'react-hook-form';
import { ControlInput } from '../UIKit/Form/Input';
import { TipUnitTypeChoice } from '../../../utilities/constants/choices';

const CheckoutTipForm = ({ lines, tips, onSave }) => {
  const [staff, setStaff] = useState(null);
  const { control, errors, handleSubmit, watch, setValue } = useForm();
  const handleSave = (data) => {
    if (+data.amount > 0 && data.staff && staff) {
      onSave({ ...data, staff_name: staff.name });
      setValue('amount', '');
      setValue('staff', '');
    } else {
      toaster.notify(() => <div className="alert alert-danger mt-4">Please all fields for tip</div>);
    }
  };

  const staffFilters = useMemo(() => {
    const staffLines = lines.map((l) => l.staff);
    const staffTips = tips.map((t) => t.staff);
    return staffLines.filter((s) => staffTips.indexOf(s) === -1);
  }, [lines, tips]);

  return (
    <form
      onSubmit={handleSubmit(handleSave)}
      className={classnames('c-checkout-tip__form', {
        'c-checkout-tip__form--disabled': staffFilters.length === 0,
      })}
    >
      <h3 className="c-checkout-tip__title">Tip</h3>
      <ControlStaffSelect
        name="staff"
        defaultValue={null}
        control={control}
        rules={{ required: 'Staff is required' }}
        label="Staff"
        id="tipInputStaff"
        defaultOptions={[{ value: '', label: 'Select Staff' }]}
        filterItems={staffFilters}
        onChange={setStaff}
        placeholder={'Select Staff'}
        errorMessage={errors?.staff?.message}
      />

      <div className="c-checkout-tip__discount">
        <ControlInput
          id="tipInputAmount"
          control={control}
          name="amount"
          defaultValue=""
          rules={{ required: 'Amount is required' }}
          type="number"
          unit={watch()?.type === 'percent' ? '%' : '$'}
          min={0}
          label={`Tip ${watch()?.staff?.label ? `(${watch().staff.label})` : ''}`}
          placeholder={'e.g. 30 (30% off)'}
          errorMessage={errors?.amount?.message}
          formClassName="w-100"
        />
        <Controller
          control={control}
          name="type"
          defaultValue="currency"
          render={({ onChange, value }) => (
            <div className="c-button-group ml-2" style={{ marginBottom: !!errors.amount ? '30px' : '10px' }}>
              {Object.keys(TipUnitTypeChoice).map((key) => (
                <Button
                  id={`tipType-${key}`}
                  color={value !== key && 'light'}
                  type="button"
                  key={key}
                  onClick={() => onChange(key)}
                >
                  {TipUnitTypeChoice[key]}
                </Button>
              ))}
            </div>
          )}
        />
      </div>

      <Button className="mt-4" block>
        Submit Tip
      </Button>
    </form>
  );
};

CheckoutTipForm.propTypes = {
  lines: PropTypes.array,
  tips: PropTypes.array,
  onSave: PropTypes.func,
};

export default CheckoutTipForm;
