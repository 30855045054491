import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/UIKit/Form/Button';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import PromotionDiscountFormModal from '../../Common/Promotion/PromotionDiscount/PromotionDiscountFormModal';
import { getDiscountList } from '../../../api';
import useApi from '../../../utilities/useApi';
import PromotionDiscountItem from '../../Common/Promotion/PromotionDiscount/PromotionDiscountItem';
import { AppContext } from '../../../context/providers';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import usePermission from '../../../utilities/usePermission';
import CampaignModal from '../../Common/Campaign/CampaignModal';

const URL_PAGE = '/promotions/discount-codes/';

const PromotionDiscountsPage = ({ match, history }) => {
  const [showCampaign, setShowCampaign] = useState(false);
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const { setHeader } = useContext(AppContext);
  let [, loading] = useApi(getDiscountList, { page: true, key: 'discount' }, setData);
  const { access: _access } = usePermission('discount', 'crud');
  const { access: _send_campaign } = usePermission('deal', 'send_campaign', 'modify');
  const { access: _campaign_report } = usePermission('deal', 'campaign_report', 'view');

  useEffect(() => {
    setHeader('Promotion / Discount');
  }, [setHeader]);

  useEffect(() => {
    if (_campaign_report && match.path.includes('campaign')) {
      handleOpenCampaign();
    } else if (_access?.view && _access?.modify && match.params.id) {
      handleOpenForm();
    } else if (_access?.modify && match.path === `${URL_PAGE}add`) {
      handleOpenForm();
    }
  }, [match]);

  const handleOpenCampaign = () => setShowCampaign(true);
  const handleCloseCampaign = (opened = true) => {
    setShowCampaign(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveForm = (item) => {
    if (typeof item === 'number' || typeof item === 'string') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => String(i.id) === String(item));
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    setShowForm(false);
    getDiscountList().then(setData);
  };

  // const handleChangePublish = (id, is_published) => {
  //   if (typeof id === 'number' || typeof id === 'string') {
  //     const updateData = [...data.results];
  //     const ind = data.results.findIndex((i) => String(i.id) === String(id));
  //     updateData[ind].is_published = is_published;
  //     updateData[ind].status = is_published ? 'published' : 'draft';
  //     setData({ ...data, results: updateData });
  //   }
  // };

  const handleChangePublish = (id, is_published, type = 'publish') => {
    if (typeof id === 'number' || typeof id === 'string') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => String(i.id) === String(id));

      if (type === 'campaign') {
        updateData[ind].campaign.status = 'sent';
      } else {
        updateData[ind].is_published = is_published;
        updateData[ind].status = is_published ? 'published' : 'draft';
      }

      setData({ ...data, results: updateData });
    }
  };

  return (
    <>
      <HeadOptions
        rightElement={
          <Button sm link={`${URL_PAGE}add`}>
            New Discount
          </Button>
        }
      />
      {showCampaign && <CampaignModal type="discounts" onClose={handleCloseCampaign} />}
      {showForm && _access?.modify && <PromotionDiscountFormModal onSave={handleSaveForm} onClose={handleCloseForm} />}
      {_access?.view ? (
        <>
          {loading && !data ? (
            <PageLoading />
          ) : !!data && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Services Count</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">Limit Total</th>
                        <th className="text-center">Expire after</th>
                        <th className="text-center">Expiration</th>
                        <th className="text-center">Status</th>
                        {_send_campaign && <th className="text-center">Send Campaign</th>}
                        {_campaign_report && <th className="text-center">Campaign Info</th>}
                        {_access?.modify && <th />}
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <PromotionDiscountItem
                          key={item.id}
                          access={{
                            send_campaign: _send_campaign,
                            campaign_report: _campaign_report,
                          }}
                          modify={_access?.modify}
                          item={item}
                          page_url={URL_PAGE}
                          onChangePublish={handleChangePublish}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Discount Exists"
              description="Use bottom button to add your first discount"
              button={<Button link={`${URL_PAGE}add`}>ADD NEW DISCOUNT</Button>}
              icon="fas fa-percentage"
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

PromotionDiscountsPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default PromotionDiscountsPage;
