import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import toaster from 'toasted-notes';
import { getServiceList } from '../../../api';
import SectionHead from '../UIKit/SectionTitle';
import Checkbox from '../UIKit/Form/Checkbox';
import Button from '../UIKit/Form/Button';
import Input from '../UIKit/Form/Input';
import { ServiceTieredLevelChoice } from '../../../utilities/constants/choices';
import { handleApi } from '../../../utilities/useApi';

export const ServiceMultiSelect = ({ show, value, isEdit, onChange, onData, onCancel }) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    handleApi(getServiceList, {}, { infinitePage: true }).then((res) => {
      setData(res.results);
      if (onData) {
        onData(res.results, isEdit ? value : res.results.map((i) => i.id));
      }
      if (res.results.length === 0)
        toaster.notify(() => (
          <div className="alert alert-danger mt-2">
            No Service Exists, Please add first service from `Services` page
          </div>
        ));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show && data.length > 0) {
      if (value) {
        setSelected(value);
        setSelectedItem(data.filter((i) => value.indexOf(i.id) > -1));
        if (value.length === data.length) handleSelectAll();
      } else handleUnSelectAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, data, value]);

  const handleSelectAll = () => {
    const services = data.map((l) => l.id);
    setSelectAll(true);
    setSelected(services);
    setSelectedItem(data);
  };
  const handleUnSelectAll = () => {
    setSelectAll(false);
    setSelected([]);
    setSelectedItem([]);
  };
  const handleToggleAll = () => {
    if (selectAll) handleUnSelectAll();
    else handleSelectAll();
  };
  const handleOnChange = (select, val) => {
    const updateSelected = [...selected];
    const updateSelectedItem = [...selectedItem];
    if (val) {
      updateSelected.push(select.id);
      updateSelectedItem.push(select);
    } else {
      const ind = updateSelected.indexOf(select.id);
      const indItem = updateSelected.findIndex((i) => i.id === select.id);
      updateSelected.splice(ind, 1);
      updateSelectedItem.splice(indItem, 1);
    }
    if (updateSelected.length < data.length) setSelectAll(false);
    else setSelectAll(true);
    setSelected(updateSelected);
    setSelectedItem(updateSelectedItem);
    return updateSelected;
  };

  const getValue = (id) => {
    const val = selected.indexOf(id);
    return val > -1;
  };

  const services = data.map(({ name, tiered_level, ...item }) => ({
    ...item,
    label: `${name}${!!ServiceTieredLevelChoice[tiered_level] ? ` - ${ServiceTieredLevelChoice[tiered_level]}` : ''}`,
    tiered_level,
  }));

  const handleSave = () => {
    onChange(selected, selectedItem);
  };

  return (
    <>
      {/*<div className="c-multi-selection__header-buttons">*/}
      {/*    <Button sm color="main" type="button" onClick={handleSave}>Select {selected.length} {selected.length > 1 ? 'Services' : 'Service'} </Button>*/}
      {/*    <Button sm color="light" type="button" onClick={onCancel}>Cancel</Button>*/}
      {/*</div>*/}
      <div className="c-promotion__form-card-content">
        <SectionHead subTitle backable onClose={onCancel} title="Services" />

        {services.length > 0 ? (
          <>
            <Checkbox
              formGroup
              id="allServices"
              label="Select All Services"
              checked={selectAll}
              onChange={handleToggleAll}
            />
            <hr />
            {services.map((service, key) => (
              <Checkbox
                key={key}
                formGroup
                id={`service-${service.id}`}
                label={service.label}
                checked={getValue(service.id)}
                onChange={(val) => handleOnChange(service, val)}
              />
            ))}
          </>
        ) : (
          <>
            <p>
              No Service Exists, Please add first service from <NavLink to="/services/add">Services page</NavLink>
            </p>
          </>
        )}
      </div>
      <div className="c-promotion__form-card-footer">
        <Button sm color="main" type="button" onClick={handleSave}>
          Select {selected.length} {selected.length > 1 ? 'Services' : 'Service'}{' '}
        </Button>
        <Button sm color="light" type="button" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

ServiceMultiSelect.propTypes = {
  show: PropTypes.bool,
  value: PropTypes.any,
  isEdit: PropTypes.bool,
  onChange: PropTypes.func,
  onData: PropTypes.func,
  onCancel: PropTypes.func,
};

export const InputServiceMultiSelect = ({ onClick, value, error, services, ...otherProps }) => {
  const [textInput, setTextInput] = useState('');
  useEffect(() => {
    if (!!value) {
      setTextInput(
        !!services && services.length > 0 && services.length === value.length
          ? 'All services'
          : services && services.length === 0
          ? 'No Service'
          : value.length + (value.length > 1 ? ' services' : ' service'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <>
      <div className="c-form__group-row align-items-end">
        <Input
          id={'services'}
          formGroup
          label={'Included Services'}
          value={textInput}
          placeholder={!!textInput ? textInput : 'Select Service'}
          disabled
          onClick={onClick}
          {...otherProps}
        />
        <div style={{ marginBottom: error ? '30px' : '10px' }}>
          <Button type="button" color="info" onClick={onClick}>
            Edit
          </Button>
        </div>
      </div>
    </>
  );
};

InputServiceMultiSelect.propTypes = {
  services: PropTypes.array,
  error: PropTypes.any,
  value: PropTypes.any,
  onClick: PropTypes.func,
};

export default ServiceMultiSelect;
