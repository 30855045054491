import React, { useEffect, useState } from 'react';
import toaster from 'toasted-notes';
import { ErrorOutline } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { ControlInput } from '../../Common/UIKit/Form/Input';
import Button from '../../Common/UIKit/Form/Button';
import { register } from '../../../api';
import Checkbox from '../../Common/UIKit/Form/Checkbox';
import { useForm } from 'react-hook-form';
import { handleApiSave } from '../../../utilities/useApi';
import AuthenticationLayout from '../../Common/Authentication/AuthenticationLayout';
import { MARKETPLACE_URL } from '../../../config/appConfig';
import { Types } from '../../../config/actionTypes';

const RegisterPage = () => {
  const [existenceEmail, setExistenceEmail] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { control, errors, handleSubmit, setError } = useForm();
  const [policy, setPolicy] = useState(false);
  // const [recaptcha, setRecaptcha] = useState(null);

  useEffect(() => {
    localStorage.removeItem(Types.SYSTEM_TYPE);
    localStorage.removeItem(Types.LATEST_APPOINTMENT);
    localStorage.removeItem(Types.BUSINESS_LOCATION);
    localStorage.removeItem(Types.COUNTRIES);
  }, []);

  const handleSave = ({ email, ..._body }) => {
    if (!policy) return toaster.notify('Please Agree Private Policy');
    // if (!recaptcha) return toaster.notify('Please Verify non-Robot Task');
    setExistenceEmail(false);
    const body = { email: (email || '').toLowerCase(), ..._body };
    handleApiSave(register, undefined, body, null, { loadingFn: setSaveLoading, setError })
      .then(() => {
        toaster.notify(() => <div className="alert alert-success mt-4">Your registration was successfully</div>);
        window.location.reload();
      })
      .catch(({ errors, status }) => {
        if (+status === 400 && errors?.email && errors?.email[0] && errors?.email[0].includes('must be unique')) {
          setExistenceEmail(true);
        }
      });
  };
  // const verifyCallBack = response => setRecaptcha(response);

  return (
    <AuthenticationLayout>
      <div className="c-auth__head">
        <h1>Sign up for professionals</h1>
        <p>Create your free partner account</p>
      </div>
      {existenceEmail && (
        <div className="alert alert-danger font-weight-bold">
          <ErrorOutline className="mr-2" />
          This email already taken
        </div>
      )}
      <form className="e-form mb-3" onSubmit={handleSubmit(handleSave)}>
        <div className="c-form__group-row c-form__group-row--2">
          <ControlInput
            rules={{ required: 'First name is required' }}
            control={control}
            label="First Name"
            name="first_name"
            placeholder={'First Name'}
            errorMessage={errors?.first_name?.message}
          />
          <ControlInput
            rules={{ required: 'Last name is required' }}
            control={control}
            label="Last Name"
            name="last_name"
            placeholder={'Last Name'}
            errorMessage={errors?.last_name?.message}
          />
        </div>
        <ControlInput
          rules={{ required: 'Email is required' }}
          control={control}
          label="Email"
          type="email"
          name="email"
          placeholder={'Email'}
          errorMessage={errors?.email?.message}
        />
        <ControlInput
          rules={{ required: 'Mobile is required' }}
          control={control}
          label="Mobile"
          type="phone"
          name="mobile"
          placeholder="Mobile"
          errorMessage={errors?.mobile?.message}
        />
        <ControlInput
          rules={{ required: 'Password is required' }}
          control={control}
          label="Password"
          type="password"
          name="password"
          placeholder={'Password'}
          errorMessage={errors?.password?.message}
        />
        <div className="e-checkbox__container">
          <Checkbox
            id="pure-policy"
            onChange={setPolicy}
            value={policy}
            label={
              <>
                I agree to
                <a target="_blank" href={`${MARKETPLACE_URL}/privacy/`}>
                  {' '}
                  privacy policy{' '}
                </a>
                ,
                <a target="_blank" href={`${MARKETPLACE_URL}/terms/`}>
                  {' '}
                  website terms{' '}
                </a>
                and{' '}
                <a target="_blank" href={`${MARKETPLACE_URL}/partner-terms/`}>
                  {' '}
                  partner terms{' '}
                </a>
              </>
            }
          />
        </div>
        {/*<GoogleRecaptcha sitekey={process.env.REACT_APP_RECAPTCHA_KEY} verifyCallback={verifyCallBack} />*/}

        <NavLink to="/forgot" className="c-auth__link">
          Forgot password?
        </NavLink>
        <Button lg id="submitRegister" disabled={!policy} loading={saveLoading} block>
          Sign up
        </Button>
      </form>
      <div className="c-auth__links">
        <p>Already have a professional account?</p>
        <NavLink to="/login" className="c-auth__link">
          Sign in now
        </NavLink>
      </div>
    </AuthenticationLayout>
  );
};

export default RegisterPage;
