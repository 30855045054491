import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { IMAGE_MARKETPLACE_1 } from '../../../assets/Images';
import { StepInfoList } from '../../../utilities/constants/setup';

const SetupSide = ({ list, step, isPreview }) => {
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.playVideo();
    event.target.pauseVideo();
  };
  const data = useMemo(() => {
    const stepKey = (list || []).find((i) => i.step === step)?.key || null;
    return (StepInfoList || []).find((i) => (i.stepKey === 'preview' && isPreview) || i.stepKey === stepKey);
  }, [list, step, isPreview]);
  return (
    <div className="c-setup-system__information">
      <div className="c-setup-system__information-main">
        {data?.youtubeId ? (
          <YouTube
            videoId={data.youtubeId}
            className="c-setup-system__information-video"
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
              },
            }}
            onReady={onReady}
          />
        ) : (
          <img
            src={data?.image || IMAGE_MARKETPLACE_1}
            className="c-setup-system__information-image"
            alt="marketplace"
          />
        )}
      </div>
    </div>
  );
};

SetupSide.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      key: PropTypes.any,
      step: PropTypes.any,
    }),
  ),
  step: PropTypes.number,
  isPreview: PropTypes.bool,
};

export default SetupSide;
