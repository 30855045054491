import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SetupMarketplaceBookingMethods from '../Marketplace/SetupMarketplaceBookingMethods';
import SetupMarketplaceCongratulations from '../Marketplace/SetupMarketplaceCongratulations';
import SetupMarketplaceStripAccount from '../Marketplace/SetupMarketplaceStripAccount';
import SetupMarketplaceLocation from '../Marketplace/SetupMarketplaceLocation';
import SetupSoftwarePlans from '../Software/SetupSoftwarePlans';
import SetupSoftwareSubscribe from '../Software/SetupSoftwareSubscribe';
import SetupMarketplaceProfileForm from '../Marketplace/SetupMarketplaceProfileForm';
import { Types } from '../../../../config/actionTypes';
import { AppContext } from '../../../../context/providers';
import SetupMarketplaceLocationForm from '../Marketplace/SetupMarketplaceLocationForm';
import SetupHeadSteps from '../../Setup/SetupHeadSteps';
import { BothStepList, GoalsOptions } from '../../../../utilities/constants/setup';
import PreviewSetupBoth from './PreviewSetupBoth';
import SetupSide from '../../Setup/SetupSide';

const SetupBothContent = ({ reference, isNew = false, goal = 'all', onClose }) => {
  const [step, setStep] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [plan, setPlan] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const { business } = useContext(AppContext);

  useEffect(() => {
    if (reference && business && (business.subscribed || business.is_trial)) {
      setIsStarted(true);
    }
  }, [reference, business]);

  useEffect(() => {
    if (reference === 'setup.both.subscribe') {
      setStep(5);
      localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
    } else if (reference === 'setup.both') {
      setStep(6);
      localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
    } else if (isStarted) setStep(0);
  }, [isStarted, reference]);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step + 1);
  };

  return (
    <div className="c-setup-system-static">
      <SetupSide list={BothStepList} isPreview={!isStarted} step={step} />
      <div className="c-setup-system-content">
        {isStarted ? (
          <>
            <SetupHeadSteps list={BothStepList} active={step} />
            <div className="c-setup-system__form">
              {step === 0 ? (
                <SetupMarketplaceProfileForm goal={goal} isNew={isNew} onNext={handleNextStep} />
              ) : step === 1 ? (
                <SetupMarketplaceBookingMethods onPrev={handlePrevStep} isNew={isNew} onNext={handleNextStep} />
              ) : step === 2 ? (
                <SetupMarketplaceLocation onPrev={handlePrevStep} isNew={isNew} onNext={handleNextStep} />
              ) : step === 3 ? (
                <SetupMarketplaceLocationForm onPrev={handlePrevStep} isNew={isNew} onNext={handleNextStep} />
              ) : step === 4 ? (
                <SetupSoftwarePlans
                  onPrev={handlePrevStep}
                  onNext={({ sessionId, item }) => {
                    setSessionId(sessionId);
                    setPlan(item);
                    setStep(step + 1);
                  }}
                />
              ) : step === 5 ? (
                <SetupSoftwareSubscribe
                  sessionId={sessionId}
                  planId={plan?.id}
                  onPrev={handlePrevStep}
                  isNew={isNew}
                  onNext={handleNextStep}
                  reference="both.subscribe"
                />
              ) : step === 6 ? (
                <SetupMarketplaceStripAccount
                  onPrev={handlePrevStep}
                  isNew={isNew}
                  onNext={handleNextStep}
                  reference="both"
                />
              ) : (
                <SetupMarketplaceCongratulations isBoth isNew={isNew} onPrev={handlePrevStep} onSave={onClose} />
              )}
            </div>
          </>
        ) : (
          <PreviewSetupBoth onStarted={() => setIsStarted(true)} />
        )}
      </div>
    </div>
  );
};

SetupBothContent.propTypes = {
  reference: PropTypes.string,
  isNew: PropTypes.bool,
  goal: PropTypes.oneOf(GoalsOptions.map((i) => i.value)),
  onClose: PropTypes.func,
};

export default SetupBothContent;
