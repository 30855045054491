import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { IMAGE_LOGO_WHITE_IMG, IMAGE_LOGO_MARKETPLACE, IMAGE_LOGO_SOFTWARE } from '../../../../assets/Images';
import { AppContext } from '../../../../context/providers';
import { APP_NAME } from '../../../../config/appConfig';

const SidebarLogo = () => {
  const { isMarketplace } = useContext(AppContext);
  return (
    <NavLink to="/" id="sidebarLogo" className="c-panel-sidebar__logo">
      <img
        // src={isMarketplace ? IMAGE_LOGO_MARKETPLACE : IMAGE_LOGO_WHITE}
        src={isMarketplace ? IMAGE_LOGO_MARKETPLACE : IMAGE_LOGO_SOFTWARE}
        alt={APP_NAME}
        className="c-panel-sidebar__logo-img"
      />
      <img src={IMAGE_LOGO_WHITE_IMG} alt={APP_NAME} className="c-panel-sidebar__logo-img logo-min-size" />
    </NavLink>
  );
};

export default SidebarLogo;
