import React, { useContext, useMemo } from 'react';
import { KeyboardArrowDown } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';
import Dropdown from '../../UIKit/Dropdown';
import { AppContext } from '../../../../context/providers';
import { Types } from '../../../../config/actionTypes';

const LocationHeader = () => {
  // const [currentLocation, setCurrentLocation] = useState(null);
  const { locations, currentLocation: current } = useContext(AppContext);
  // useEffect(() => {
  //   if (locations && locations.length > 0 && current) {
  //     const location = locations.find((i) => +i.id === +current);
  //     if (location) {
  //       setCurrentLocation(location);
  //     }
  //   }
  // }, [current, locations]);

  const handleSelectLocation = (item) => {
    confirmAlert({
      title: 'Leave Marketplace',
      message: `Are you sure to change current location to the ${item.title}`,
      buttons: [
        {
          label: 'Yes Change Location',
          className: 'bg-danger',
          onClick: () => {
            localStorage.setItem(Types.BUSINESS_LOCATION, item.id);
            localStorage.removeItem(Types.LATEST_APPOINTMENT);
            window.location.reload();
          },
        },
        {
          label: 'No Keep',
          onClick: () => {},
        },
      ],
    });
  };

  const options = useMemo(
    () => [
      ...(locations || []).map((i) => ({
        type: 'node',
        className: classNames('d-block', {
          'bg-gray font-weight-bold': +i.id === +current,
        }),
        node: (
          <span
            className={+i.id !== +current ? 'cursor-pointer text-dark' : undefined}
            onClick={() => handleSelectLocation(i)}
          >
            {i.title}
          </span>
        ),
      })),
      {
        type: 'divider',
      },
      {
        type: 'link',
        label: 'Location Settings',
        url: '/settings/locations',
      },
    ],
    [locations],
  );
  return locations && locations.length > 1 ? (
    <div className="c-panel-header__user">
      <Dropdown options={options}>
        <div className="c-panel-header__user-info">
          {/*<span className="c-panel-header__user-name">{currentLocation ? currentLocation.title : 'Locations...'}</span>*/}
          <strong className="c-panel-header__user-name">
            <KeyboardArrowDown />
            Locations
          </strong>
        </div>
      </Dropdown>
    </div>
  ) : null;
};

export default LocationHeader;
