import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Modal, { ModalDeniedOver } from '../../UIKit/Modal';
import Button from '../../UIKit/Form/Button';
import {
  createStaff,
  updateStaff,
  deleteStaff,
  getStaff,
  getPlans,
  createServiceCommission,
  getServiceCommission,
  updateServiceCommission,
  getAccessLvlList,
} from '../../../../api';
import { PageLoading } from '../../UIKit/Spinner';
import { ControlInput } from '../../UIKit/Form/Input';
import useApi, { handleApi, handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import ImageSelector from '../../UIKit/ImageSelector';
import { IMAGE_DEFAULT_USER } from '../../../../assets/Images';
import { ControlSelect } from '../../UIKit/Form/Select';
import { getValueLabel, ServiceTieredLevelChoice } from '../../../../utilities/constants/choices';
import Tab from '../../UIKit/Tab';
import StaffServices from './StaffServices';
import { AppContext } from '../../../../context/providers';
import StaffMemberFormCommission from './StaffMemberFormCommission';
import TourContent from '../../UIKit/TourContent';
import usePermission from '../../../../utilities/usePermission';
import Alert from '../../UIKit/Alert';
import { ControlCheckbox } from '../../UIKit/Form/Checkbox';

const steps = [
  {
    key: 'staffModal.form',
    selector: '[data-tour="form"]',
    content: <TourContent title="Staff Member" description="You can manage your staff members in here" />,
  },
  {
    key: 'staffModal.modal-tab',
    selector: '[data-tour="modal-tab"]',
    content: <TourContent title="Add Staff" description="You can add new staff to your system in here" />,
  },
];

const StaffMemberModal = ({ staffCount = 0, onClose, onSave }) => {
  const [delLoading, setDelLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [permissions, setPermissions] = useState(null);
  const [isAllowed, setIsAllowed] = useState(true);
  const [servicesList, setServicesList] = useState(null);
  const { handleSubmit, errors, control, watch, setValue, setError, clearErrors } = useForm();
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);
  const { business } = useContext(AppContext);

  const { id } = useParams();

  const [data, loading] = useApiById(getStaff, id);
  const [commissions, commissionsLoading] = useApi(getServiceCommission, {
    infinitePage: true,
    staff: id,
    key: 'servicecommission',
  });

  const { access: _commission } = usePermission('servicecommission', 'crud');
  const { access: _service } = usePermission('service', 'crud');
  const { access: _level } = usePermission('accesslevel', 'crud');

  // useEffect(() => {
  //   if ((id && !loading) || !id) {
  //     setTimeout(() => {
  //       setIsTourOpen(true);
  //     }, 500);
  //   }
  // }, [loading]);
  useEffect(() => {
    if (!id && business) {
      setPlanLoading(true);
      handleApi(getPlans)
        .then((res) => {
          const plan = (res?.results || []).find((i) => i.id === business.plan);
          if (plan && plan?.limits?.staffs && plan?.limits?.staffs > 0 && staffCount === plan?.limits?.staffs) {
            setIsAllowed(false);
          }
        })
        .finally(() => setPlanLoading(false));
    }
  }, [id, business, staffCount]);
  useEffect(() => {
    if (_level?.view) {
      setLoadingPermissions(true);
      handleApi(getAccessLvlList, {}, { infinitePage: true })
        .then((res) => {
          const results = res?.results || [];
          setPermissions(results);
        })
        .finally(() => setLoadingPermissions(false));
    }
  }, [_level]);

  // useEffect(() => {
  //   if (tabIndex === 2) {
  //     setTourStep(0);
  //     setIsTourOpen(true);
  //   }
  // }, [tabIndex]);

  const permissionsOptions = useMemo(
    () => (permissions || []).map((i) => ({ label: i.name, value: i.id })),
    [permissions],
  );

  // const tourSteps = useMemo(
  //   () =>
  //     tabIndex === 2
  //       ? [
  //           {
  //             key: 'staffModal.add-commission',
  //             selector: '[data-tour="add-commission"]',
  //             content: <TourContent title="Add Commission" description="You can add new commissions in here" />,
  //           },
  //         ]
  //       : steps,
  //   [tabIndex],
  // );

  const onInvalidForm = (e) => {
    const messages = Object.keys(e).reduce((acc, key) => {
      const item = e[key];
      if (Array.isArray(item)) {
        const msgs = item.reduce((_acc, i, ind) => {
          const _res = Object.keys(i).map((_key) => `${key.toUpperCase()} ${ind + 1} - ${i[_key].message}`);
          return [..._acc, ..._res];
        }, []);
        return [...acc, ...msgs];
      }
    }, []);
    toaster.notify(() => (
      <Alert danger className="text-left">
        {(messages || []).map((m, index) => (
          <div key={index}>{m}*</div>
        ))}
      </Alert>
    ));
  };
  const onSubmit = (_data) => {
    const body = {
      ..._data,
      avatar:
        _data.avatar === null ? null : !!_data.avatar && typeof _data.avatar !== 'string' ? _data.avatar : undefined,
      services: _data.is_stylist ? servicesList : [],
    };
    const commissionId = (commissions?.results || []).length > 0 ? commissions.results[0]?.id : undefined;
    const tiers = (body?.commission || []).map((i) => ({
      ...i,
      service_from: +(i.service_from || 0),
      service_to: i.service_to === '∞' ? '9999999999' : +i.service_to,
      id: commissionId && !i.id.includes('fake') ? i.id : undefined,
    }));
    delete body.commission;
    setSaveLoading(true);
    handleApiSave(createStaff, updateStaff, body, id, { setError })
      .then((res) => {
        // HandleCatch.removeItem('staff');
        if (tiers) {
          handleApiSave(
            createServiceCommission,
            commissionId ? updateServiceCommission : undefined,
            { tiers, staff: res.id },
            commissionId,
          )
            .then(() => {
              onSave(res);
            })
            .finally(() => setSaveLoading(false));
        } else {
          onSave(res);
          setSaveLoading(false);
        }
      })
      .catch(() => setSaveLoading(false));
  };

  const onDelete = () => {
    const options = {
      title: 'Confirm to delete this staff',
      message: 'Are you sure to delete this staff.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteStaff, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  const tabs = useMemo(() => {
    const res = ['Profile'];
    if (_service?.view && watch().is_stylist) res.push('Service');
    if (_commission?.view || _commission?.modify) res.push('Commission');
    return res;
  }, [_commission, watch().is_stylist]);

  // cannot delete owner staff
  const isOwner = useMemo(
    () => data && business && (business?.owner || '').toString() === (data?.userId || '').toString(),
    [business, data],
  );
  const canDelete = useMemo(() => id && data?.isActive && !isOwner, [isOwner, data]);

  return (
    <>
      <Modal
        lg
        sticky
        show
        guide={steps}
        disabled={!isAllowed || (id && data ? !data.isActive : false)}
        title={!!id ? `Edit Staff` : 'New Staff'}
        onSave={data?.isActive || !id ? handleSubmit(onSubmit, onInvalidForm) : undefined}
        saveLoading={saveLoading}
        saveBtnDisabled={!isAllowed}
        buttonComponent={
          canDelete && (
            <Button color="danger" id="delete-btn" loading={delLoading} onClick={onDelete}>
              Delete
            </Button>
          )
        }
        saveBtn={data?.isActive || !id}
        onClose={onClose}
      >
        {!isAllowed && (
          <ModalDeniedOver
            title="Denied"
            description="Your current plan has limitation staff. For add more staff upgrade your software plan."
            btnText="Upgrade"
            btnLink="/settings/plans"
            onClose={onClose}
          />
        )}
        <Tab isModal isMerely dataTourHead="modal-tab" tabs={tabs}>
          {/*<Tab isModal isMerely dataTourHead="modal-tab" onSelect={setTabIndex} tabs={tabs}>*/}
          <>
            {!!id && (loading || planLoading) ? (
              <PageLoading type="box" />
            ) : (
              <form
                data-tour="form"
                className="py-3"
                onSubmit={data?.isActive || !id ? handleSubmit(onSubmit) : undefined}
              >
                <Controller
                  control={control}
                  name="avatar"
                  defaultValue={data ? data?.avatar : ''}
                  render={({ onChange, value }) => (
                    <ImageSelector
                      id="image"
                      defaultImage={IMAGE_DEFAULT_USER}
                      description="Staff profile will be show in marketplace"
                      className="c-settings__image-uploader"
                      onChange={onChange}
                      onDelete={onChange}
                      value={value}
                    />
                  )}
                />
                <ControlInput
                  control={control}
                  name="name"
                  defaultValue={!!data?.name ? data.name : ''}
                  label="Full Name"
                  placeholder="Full Name"
                  rules={{ required: 'Full Name is required' }}
                  errorMessage={errors?.name?.message}
                />
                <ControlInput
                  control={control}
                  name="mobile"
                  defaultValue={!!data?.phone_number ? data.phone_number : ''}
                  type="phone"
                  label="Mobile Number"
                  // placeholder="+1 204-234-5678"
                  placeholder="204-234-5678"
                  rules={data?.email ? undefined : { required: 'Mobile Number is required' }}
                  errorMessage={errors?.mobile?.message}
                />
                <ControlInput
                  control={control}
                  name="email"
                  defaultValue={!!data?.email ? data.email : ''}
                  type="email"
                  label="Email"
                  placeholder="mail@example.com"
                  rules={{ required: 'Email is required' }}
                  errorMessage={errors?.email?.message}
                />
                <ControlSelect
                  control={control}
                  name="tiered_level"
                  rules={{ required: 'Tiered Level is required' }}
                  defaultValue={!!data?.tiered_level ? data.tiered_level : null}
                  label="Tiered Level"
                  maxMenuHeight={175}
                  // menuPlacement="top"
                  options={getValueLabel(ServiceTieredLevelChoice)}
                  errorMessage={errors?.tiered_level?.message}
                />
                {_level?.view && (
                  <ControlSelect
                    control={control}
                    name="access_level"
                    // rules={{ required: 'Access Level is required' }}
                    defaultValue={!!data?.access_level ? data.access_level : null}
                    label="Access Level"
                    maxMenuHeight={175}
                    isLoading={loadingPermissions}
                    options={permissionsOptions}
                    errorMessage={errors?.access_level?.message}
                  />
                )}
                <ControlCheckbox
                  control={control}
                  name="is_stylist"
                  defaultValue={!!data ? data.is_stylist : true}
                  label="Is Stylist?"
                />
                <ControlInput
                  control={control}
                  name="note"
                  defaultValue={!!data?.note ? data.note : ''}
                  type="textarea"
                  // type="editor"
                  rows={4}
                  label="Note"
                  // onChangeText={(e) => console.log('onChangeText', e)}
                  // onChange={(e) => console.log('onChange', e)}
                  placeholder="Add private notes viewable in staff settings only (optional)"
                  errorMessage={errors?.note?.message}
                />
              </form>
            )}
          </>
          {_service?.view && (
            <StaffServices data={data} level={watch()?.tiered_level || data?.tiered_level} onChange={setServicesList} />
          )}
          {(_commission?.view || _commission?.modify) && (
            <StaffMemberFormCommission
              isModify={_commission?.modify}
              control={control}
              loading={commissionsLoading}
              commissions={commissions?.results || []}
              errors={errors}
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              setError={setError}
            />
          )}
        </Tab>
      </Modal>
      {/*<TourGuide*/}
      {/*  disableInteraction*/}
      {/*  steps={tourSteps}*/}
      {/*  startAt={tourStep}*/}
      {/*  getCurrentStep={setTourStep}*/}
      {/*  isOpen={isTourOpen}*/}
      {/*  onRequestClose={() => setIsTourOpen(false)}*/}
      {/*/>*/}
    </>
  );
};

StaffMemberModal.propTypes = {
  staffCount: PropTypes.number,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default StaffMemberModal;
