import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import CSVReader from 'react-csv-reader';
import Button from '../../UIKit/Form/Button';
import { uploadClientsFile } from '../../../../api';
import { handleApiSave } from '../../../..//utilities/useApi';
import Alert from '../../../Common/UIKit/Alert';
import { validateEmail } from '../../../../utilities/validate';

// const papaparseOptions = {
//     header: true,
//     dynamicTyping: true,
//     skipEmptyLines: true,
//     transformHeader: header =>
//         header
//             .toLowerCase()
//             .replace(/\W/g, '_')
// }
//
// const ClientsImportUpload = () => (
//   <>
//     <Button color="main" node="label" htmlFor="uploadImport">
//       Upload import file
//     </Button>
//     <CSVReader
//       cssClass="csv-reader-input d-none"
//       // onError={this.handleDarkSideForce}
//       // parserOptions={papaparseOptions}
//       inputId="uploadImport"
//       inputStyle={{ color: 'red' }}
//       // onFileLoaded={(data, fileInfo) => console.dir(data, fileInfo)}
//     />
//   </>
// );

const ClientsImportUpload = ({ source, btnText = 'Upload import file', onDone }) => {
  const [loading, setLoading] = useState(false);
  const handleUploadFile = (data, undefined, originalFile) => {
    setLoading(true);
    let shouldCheckColumn = null;
    let shouldCheckField = null;
    let shouldCheckItem = null;
    let shouldCheckInd = null;

    // if (data[0].length !== 6) {
    //   // shouldCheckColumn = `Please check columns which have to base on <strong>${source}</strong> format`;
    //   if (source === 'beautster') {
    //     let missedCol = null;
    //     let cls = ['full_name', 'email', 'phone_number', 'birthday_month', 'birthday_day', 'note'];
    //     cls.forEach((i) => {
    //       if ((data[0] || []).indexOf(i) === -1) missedCol = i;
    //     });
    //     if (missedCol)
    //       shouldCheckColumn = `Please check columns which have to base on <strong>${source}</strong> format Missed column <strong>${missedCol}</strong>`;
    //   }
    // }

    if (source === 'beautster') {
      if (data[0].length !== 6) {
        let missedCol = null;
        let cls = ['full_name', 'email', 'phone_number', 'birthday_month', 'birthday_day', 'note'];
        cls.forEach((i) => {
          if ((data[0] || []).indexOf(i) === -1) missedCol = i;
        });
        if (missedCol)
          shouldCheckColumn = `Please check columns which have to base on <strong>${source}</strong> format Missed column <strong>${missedCol}</strong>`;
      } else {
        for (let i = 1; i < data.length; i++) {
          const item = data[i];
          const fullNameParam = item[0];
          const emailParam = item[1];
          if (emailParam && !validateEmail(emailParam)) {
            shouldCheckInd = i + 1;
            shouldCheckItem = fullNameParam || null;
            shouldCheckField = 'Email';
            break;
          }
        }
      }
    } else if (source === 'fresha') {
      for (let i = 1; i < data.length; i++) {
        const item = data[i];
        const clientIDParam = item[0];
        const firstNameParam = item[1];
        const lastNameParam = item[2];
        const nameParam = item[3];
        const emailParam = item[13];
        if (emailParam && !validateEmail(emailParam)) {
          shouldCheckInd = i + 1;
          shouldCheckItem =
            clientIDParam || nameParam || (firstNameParam && lastNameParam)
              ? `${firstNameParam} ${lastNameParam}`
              : firstNameParam || lastNameParam || null;
          shouldCheckField = 'Email';
          break;
        }
      }
    }
    // for (let i = 0; i < data.length; i++) {
    //   const item = data[i];
    //   if (item[0] || item[1] || item[2]) {
    //     // if (!item[0]) {
    //     //   shouldCheckInd = i + 1;
    //     //   shouldCheckField = 'Full Name';
    //     //   break;
    //     // }
    //     // else if (!item[1]) {
    //     //   shouldCheckInd = i + 1;
    //     //   shouldCheckItem = item[0];
    //     //   shouldCheckField = 'Email';
    //     //   break;
    //     // }
    //     // } else if (!item[1] || !item[2]) {
    //     //   shouldCheckInd = i + 1;
    //     //   shouldCheckItem = item[0];
    //     //   shouldCheckField = !item[1] ? 'Email' : 'Mobile';
    //     //   break;
    //     // }
    //   }
    // }
    if (shouldCheckItem || shouldCheckInd || shouldCheckColumn) {
      toaster.notify(() => (
        <Alert danger>
          {shouldCheckColumn ? (
            <div dangerouslySetInnerHTML={{ __html: shouldCheckColumn }} />
          ) : (
            <>
              Please fill valid data for {shouldCheckField} field of item number {shouldCheckInd}{' '}
              {shouldCheckItem ? `(${shouldCheckItem})` : ''}
            </>
          )}
        </Alert>
      ));
      document.querySelector('#uploadImport').value = '';
      setLoading(false);
    } else {
      handleApiSave(uploadClientsFile, undefined, { file: originalFile, source })
        .then(() => {
          onDone();
        })
        .catch((err) => {
          console.log(err);
          document.querySelector('#uploadImport').value = '';
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Button
        color="main"
        data-tour="upload-btn"
        id="importUploadBtn"
        loading={loading}
        node="label"
        htmlFor="uploadImport"
      >
        {btnText}
      </Button>
      <CSVReader
        accept=".xlsx, .xls, .csv"
        cssClass="csv-reader-input d-none"
        // onError={this.handleDarkSideForce}
        // parserOptions={papaparseOptions}
        inputId="uploadImport"
        onFileLoaded={handleUploadFile}
      />
      {/*<input*/}
      {/*  type="file"*/}
      {/*  className="d-none"*/}
      {/*  id="uploadImport"*/}
      {/*  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
      {/*  onChange={handleUploadFile}*/}
      {/*/>*/}
    </>
  );
};

ClientsImportUpload.propTypes = {
  source: PropTypes.string,
  btnText: PropTypes.string,
  onDone: PropTypes.func,
};

export default ClientsImportUpload;
