import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_DEFAULT } from '../../../assets/Images';

const Image = ({ src = IMAGE_DEFAULT, errorSrc = IMAGE_DEFAULT, alt = 'Beautster', ...props }) => {
  const [source, setSource] = useState(src);
  return <img src={source} onError={() => setSource(errorSrc)} alt={alt} {...props} />;
};

Image.propTypes = {
  src: PropTypes.any.isRequired,
  errorSrc: PropTypes.any,
  alt: PropTypes.string,
};

export default Image;
