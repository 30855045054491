import React from 'react';
import PropTypes from 'prop-types';
import AppointmentFormModal from '../Appointment/AppointmentFormModal';

const ConvertGoogleCalenderModal = ({ extraData, onClose, onSave }) => (
  <AppointmentFormModal onSave={() => onSave('refresh', extraData)} onClose={onClose} extraData={extraData} />
);

ConvertGoogleCalenderModal.propTypes = {
  extraData: PropTypes.any,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConvertGoogleCalenderModal;
