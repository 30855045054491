import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Add, ViewWeek, SyncAlt } from '@material-ui/icons';
import Button from '../../Common/UIKit/Form/Button';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import Calendar from '../../Common/Calendar/Calendar';
import DateNavigation from '../../Common/UIKit/DateNavigation';
import CalendarViewSelector from '../../Common/Calendar/CalendarViewSelector';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import { getStringFromDate } from '../../../utilities/DateHelpers';
import { getClosedDateList, getStaffClosedDateList, getStaffList } from '../../../api';
import { handleApi } from '../../../utilities/useApi';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { AppContext } from '../../../context/providers';
import StaffSelect from '../../Common/Selections/StaffSelect';
import { getBusinessWorkingHours } from '../../../api/businessWorkingHours';
import Alert, { ModalAlert } from '../../Common/UIKit/Alert';
import Dropdown from '../../Common/UIKit/Dropdown';
import GoogleCalendarModal from '../../Common/Calendar/GoogleCalendarModal';
import { googleCalendarEventsList } from '../../../api';
import toaster from 'toasted-notes';
import { calendarBlockedDateSteps, calendarSteps } from '../../../config/tourSteps';
import { TourGuide } from '../../Common/UIKit/TourContent';
import usePermission from '../../../utilities/usePermission';
import { NavLink } from 'react-router-dom';

const URL_PAGE = '/calendar';

const CalendarPage = ({ match, history, location }) => {
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [closedDatesLoading, setClosedDatesLoading] = useState(false);
  const [staffClosedDates, setStaffClosedDates] = useState(null);
  const [closedDates, setClosedDates] = useState(null);
  const [googleCalendarModal, setGoogleCalendarModal] = useState(false);
  const [googleEvents, setGoogleEvents] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [resources, setResources] = useState(null);
  const [selectBlockedTime, setSelectBlockedTime] = useState(false);
  const [showBlocked, setShowBlocked] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const { search } = location;
  const { isSoftware, isMarketplace, setHeader, softwareAccess, business, user } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [staffList, setStaffList] = useState(true);
  const [workingHours, setWorkingHours] = useState(true);

  const { access: _staff } = usePermission('staff', 'crud');
  const { access: _appointment } = usePermission('appointment', 'crud');
  const { access: _staffclosed, isReady } = usePermission('staffcloseddate', 'crud');
  const { access: _businessclosed } = usePermission('businesscloseddate', 'crud', 'view');
  const { access: _businesshour } = usePermission('businessworkinghour', 'crud', 'view');
  const { access: _google } = usePermission('googlecalendarevent', 'crud');

  useEffect(() => {
    setLoading(true);
    if (isReady) {
      if (_appointment?.view) {
        const businessHoursApi = _businesshour ? handleApi(getBusinessWorkingHours) : Promise.resolve([]);
        const closedDatesApi = _businessclosed
          ? handleApi(
              getClosedDateList,
              {},
              {
                infinitePage: true,
                list: true,
                limit: 20,
                loadingFn: setClosedDatesLoading,
              },
            )
          : Promise.resolve({ results: [] });
        if (_staff?.view) {
          const staffApi = handleApi(getStaffList, { only_stylists: true, infinitePage: true, list: true, limit: 20 });
          Promise.all([staffApi, businessHoursApi, closedDatesApi])
            .then(([_staffRes, workingRes, closedRes]) => {
              const staffRes = { ..._staffRes, results: (_staffRes?.results || []).filter((i) => i.isActive) };
              if (_staffclosed?.view && (staffRes?.results || []).length > 0) {
                getBlockedStaffTime(staffRes.results);
              }
              setStaffList(staffRes);
              setWorkingHours(workingRes);
              setClosedDates(closedRes);
              setLoading(false);
            })
            .catch(() => setLoading(false));
        } else if (business?.current_staff) {
          Promise.all([businessHoursApi, closedDatesApi])
            .then(([workingRes, closedRes]) => {
              if (_staffclosed?.view) {
                getBlockedStaffTime([{ id: business?.current_staff }]);
              }
              setStaffList({ results: [{ ...user, name: user.full_name, id: business?.current_staff }] });
              setWorkingHours(workingRes);
              setClosedDates(closedRes);
              setLoading(false);
            })
            .catch(() => setLoading(false));
        }
      } else {
        setLoading(false);
      }
    }
  }, [isReady, business]);

  useEffect(() => {
    if (business && resources && _google?.view) {
      getGoogleEvents();
    }
  }, [business, resources]);

  const getBlockedStaffTime = async (list = null) => {
    setCalendarLoading(true);
    const _staffList = list || staffList?.results || [];
    const apiList = _staffList.map(({ id }) =>
      handleApi(
        getStaffClosedDateList,
        { staff: id },
        {
          infinitePage: true,
          list: true,
          limit: 20,
        },
      ),
    );
    Promise.all(apiList)
      .then((responses) => {
        const updateClosedDate = responses.reduce((_acc, response) => {
          const list = (response?.results || []).reduce((acc, i) => {
            const updateAcc = [...acc];
            if (updateAcc.findIndex((j) => j.id === i.id) === -1) updateAcc.push(i);
            return updateAcc;
          }, []);
          return [..._acc, ...list];
        }, []);
        setStaffClosedDates(updateClosedDate);
      })
      .finally(() => setCalendarLoading(false));
  };

  useEffect(() => {
    setHeader('Calendar', { guide: [...calendarSteps, ...calendarBlockedDateSteps] });
  }, [setHeader]);

  useEffect(() => {
    const id = match.params.id;
    if (id && isReady) {
      if (match.path.includes('/calendar/blocked/:id/edit')) {
        if (_staffclosed?.modify) {
          sessionStorage.setItem(
            'location.search',
            sessionStorage.getItem('location.search')
              ? sessionStorage.getItem('location.search')
              : `?view=day&date${getStringFromDate(new Date())}`,
          );
          handleOpenBlocked();
        }
      } else {
        sessionStorage.setItem(
          'location.search',
          sessionStorage.getItem('location.search')
            ? sessionStorage.getItem('location.search')
            : `?view=day&date${getStringFromDate(new Date())}`,
        );
        handleOpenForm();
      }
    }
  }, [match, isReady]);

  useEffect(() => {
    if (_appointment?.modify && location.pathname.includes('/appointment/add')) {
      sessionStorage.setItem(
        'location.search',
        sessionStorage.getItem('location.search')
          ? sessionStorage.getItem('location.search')
          : `?view=day&date${getStringFromDate(new Date())}`,
      );
      handleOpenForm();
    } else if (_google?.modify && location.pathname.includes('/google-calendar')) {
      setGoogleCalendarModal(true);
    }
  }, [location.pathname, isReady]);

  const retrieveUrl = (date = null) => {
    const sessionSearch = sessionStorage.getItem('location.search');
    const search = sessionSearch ? sessionSearch : location.search;
    const query = getQueryUri(['view', 'date'], search);
    sessionStorage.removeItem('location.search');
    history.replace(URL_PAGE + setQueryUri(date ? { ...query, date } : query));
  };

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (date) => {
    setShowForm(false);
    retrieveUrl(date);
  };

  const handleOpenBlockedTime = () => {
    setSelectBlockedTime(true);
    setIsTourOpen(true);
    toaster.notify(() => <Alert info>Select a time on the calendar</Alert>, { duration: 5000 });
  };
  const handleCloseBlockedTime = () => setSelectBlockedTime(false);

  const handleOpenBlocked = () => setShowBlocked(true);
  const handleCloseBlocked = () => {
    handleCloseBlockedTime();
    setShowBlocked(false);
    retrieveUrl();
  };

  const handleOpenNewAppointment = () => {
    history.push(`${URL_PAGE}/appointment/add`);
    sessionStorage.setItem(
      'location.search',
      !!location.search ? location.search : `?view=day&date${getStringFromDate(new Date())}`,
    );
  };

  const handleFilterStaff = (staff) => {
    const params = setQueryUri({
      ...getQueryUri(),
      staff: staff === '0' ? null : staff,
    });
    history.replace(params);
  };

  const options = useMemo(() => {
    const res = [];
    if (_google?.modify) {
      res.unshift({
        type: 'node',
        className: 'p-0',
        node: (
          <NavLink to="/calendar/google-calendar" className="c-calendar__opt">
            <SyncAlt className="c-calendar__opt-img" />
            Sync Google Calendar
          </NavLink>
        ),
        id: 'importGoogleCalendar',
      });
    }
    if (_staffclosed?.modify) {
      res.unshift({
        type: 'node',
        className: 'p-0',
        id: 'newBlockedTime',
        onClick: handleOpenBlockedTime,
        node: (
          <span className="c-calendar__opt">
            <ViewWeek className="c-calendar__opt-img" />
            New Blocked Time
          </span>
        ),
      });
    }
    if (_appointment?.modify && isSoftware && softwareAccess) {
      res.unshift({
        type: 'link',
        url: `${URL_PAGE}/appointment/add`,
        className: 'p-0',
        label: (
          <span id="dropdown-newAppointment" className="c-calendar__opt">
            <Add className="c-calendar__opt-img" />
            New Appointment
          </span>
        ),
        onClick: handleOpenNewAppointment,
      });
    }
    return res;
  }, [isSoftware, softwareAccess, _appointment, _staffclosed]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const staff = useMemo(() => {
    const value = getQueryUri('staff');
    return value ? String(value) : null;
  }, [search]);

  useEffect(() => {
    if (staff && staffList?.results) {
      const body = {
        ...staffList,
        results: staffList.results.filter((s) => String(s.id) === String(staff)),
      };
      setResources(body);
    } else {
      setResources(staffList);
    }
  }, [staff, staffList]);

  const rightEl = (
    <>
      <DateNavigation dataTour="navigation" url={URL_PAGE} />
      <CalendarViewSelector dataTour="views" url={URL_PAGE} />
      {options.length ? (
        <Dropdown options={options}>
          <Button id="addNewOpt" data-tour="add-btn" className="ml-3">
            Add New <i className="fas fa-chevron-down" />
          </Button>
        </Dropdown>
      ) : null}
    </>
  );

  const getGoogleEvents = () => {
    const ownerStaffId = (resources?.results || []).find((i) => i.userId === business.owner);
    googleCalendarEventsList(ownerStaffId?.id, resources?.results || []).then((res) => {
      setGoogleEvents(res);
    });
  };

  const handleCloseGoogleCalendar = () => {
    history.push(`${URL_PAGE}?view=day&date${getStringFromDate(new Date())}`);
    setGoogleCalendarModal(false);
  };

  const handleSaveGoogleCalendar = () => {
    handleCloseGoogleCalendar();
    setTimeout(() => {
      getGoogleEvents();
    }, 1000);
  };

  return _appointment?.view ? (
    <>
      {googleCalendarModal && (
        <GoogleCalendarModal onClose={handleCloseGoogleCalendar} onSave={handleSaveGoogleCalendar} />
      )}
      {(isMarketplace || resources?.results?.length) && (
        <HeadPage
          rightElement={rightEl}
          leftElement={
            <div data-tour="filter-staff">
              <StaffSelect
                id="filterStaff"
                value={staff ? staff : '0'}
                defaultOptions={[{ label: 'All Staffs', value: '0' }]}
                className="c-calendar__staff-filter"
                formGroup={false}
                onChangeValue={handleFilterStaff}
              />
            </div>
          }
        />
      )}
      {staffList && staffList?.results?.length === 0 && (
        <ModalAlert
          title="Add Staff"
          description="You do not have any staff. For check and add new appointment, please add your first staff."
          buttonComponent={
            <Button link="/staff/members/add" id="calendarAddStaffBtn" color="warning">
              Add Staff Now
            </Button>
          }
        />
      )}
      {loading || closedDatesLoading ? (
        <PageLoading />
      ) : isMarketplace || resources?.results?.length > 0 ? (
        <Calendar
          googleEvents={googleEvents}
          url={URL_PAGE}
          resources={resources?.results || []}
          loading={calendarLoading}
          workingHours={workingHours?.results}
          closedDates={closedDates?.results}
          staffClosedDates={staffClosedDates}
          selectBlockedTime={selectBlockedTime}
          showBlocked={showBlocked}
          onRefreshBlocked={getBlockedStaffTime}
          onCloseBlocked={handleCloseBlocked}
          onOpenBlocked={handleOpenBlocked}
          onCloseModal={handleCloseForm}
          onOpenModal={handleOpenForm}
          showModal={showForm}
        />
      ) : (
        <NotFoundData title="No Staff Exists" description="Add staff in staff member page" />
      )}
      <TourGuide
        save
        disableInteraction
        steps={calendarBlockedDateSteps}
        startAt={tourStep}
        getCurrentStep={setTourStep}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </>
  ) : (
    <AccessDeniedPage title="Appointment View Access Denied" />
  );
};

CalendarPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
};

export default CalendarPage;
