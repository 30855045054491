import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Modal from '../UIKit/Modal';
import { createClient, updateClient } from '../../../api';
import { ControlInput } from '../UIKit/Form/Input';
import { ControlSelect } from '../UIKit/Form/Select';
import { PageLoading } from '../UIKit/Spinner';
import { DayNumChoice, getValueLabel, MonthNumChoice } from '../../../utilities/constants/choices';
import { handleApiSave } from '../../../utilities/useApi';
// import TourContent, { TourGuide } from '../UIKit/TourContent';

// const steps = [
//   {
//     selector: '[data-tour="client-form"]',
//     position: 'left',
//     content: (
//       <TourContent
//         title="Fill Form Fields"
//         description="To add new client in your system, it is require to fill form field, and then submit button"
//       />
//     ),
//   },
// ];

const ClientFormModal = ({ onClose, data, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  const { control, errors, handleSubmit, setError } = useForm();

  const params = useParams();
  const id = params.clientId;

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTourOpen(!(!!id && !data));
  //   }, 500);
  // }, [id, data]);

  const handleSave = (data) => {
    handleApiSave(createClient, updateClient, data, id, {
      loadingFn: setSaveLoading,
      setError,
    })
      .then(onSave)
      .catch(() => {});
  };

  return (
    <>
      <Modal
        show
        onClose={onClose}
        sticky
        saveLoading={saveLoading}
        onSave={handleSubmit(handleSave)}
        dataTour="client-form"
        title={!!id ? 'Edit Client' : 'Add New Client'}
      >
        {!!id && !data ? (
          <PageLoading />
        ) : (
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="py-3">
              <ControlInput
                control={control}
                name="full_name"
                rules={{ required: 'Full Name is required' }}
                label={'Full Name'}
                defaultValue={!!data?.full_name ? data?.full_name : ''}
                placeholder={'Full Name'}
                errorMessage={errors?.full_name?.message}
              />
              <ControlInput
                control={control}
                name="email"
                rules={{ required: 'Email is required' }}
                type={'email'}
                label={'Email'}
                defaultValue={!!data?.email ? data?.email : ''}
                placeholder={'Email'}
                errorMessage={errors?.email?.message}
              />
              <ControlInput
                control={control}
                name="phone_number"
                // rules={{ required: 'Mobile is required' }}
                type="phone"
                label={'Mobile'}
                defaultValue={!!data?.phone_number ? data?.phone_number : ''}
                placeholder={'Mobile'}
                errorMessage={errors?.phone_number?.message}
              />
              <div className="c-form__group-row">
                <ControlSelect
                  control={control}
                  name="birthday_month"
                  defaultValue={!!data?.birthday_month ? String(data.birthday_month) : undefined}
                  placeholder={'Select Birth Month'}
                  options={getValueLabel(MonthNumChoice)}
                  label={'Birth Month'}
                  maxMenuHeight={200}
                  errorMessage={errors?.birthday_month?.message}
                />
                <ControlSelect
                  control={control}
                  name="birthday_day"
                  defaultValue={!!data?.birthday_day ? String(data.birthday_day) : undefined}
                  placeholder={'Select Birth Day'}
                  options={getValueLabel(DayNumChoice)}
                  label={'Birth Day'}
                  maxMenuHeight={200}
                  errorMessage={errors?.birthday_day?.message}
                />
              </div>
              <ControlInput
                control={control}
                name="note"
                type="textarea"
                label={'Client notes'}
                defaultValue={!!data?.note ? data.note : ''}
                placeholder={'Enter client notes...'}
                errorMessage={errors?.note?.message}
              />
            </div>
          </form>
        )}
      </Modal>
      {/*<TourGuide steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />*/}
    </>
  );
};

ClientFormModal.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default ClientFormModal;
