import React from 'react';

const NotFoundPage = () => (
  <div className="not-found text-center">
    <h1>Page Not Found</h1>
    <p>404</p>
  </div>
);

export default NotFoundPage;
