import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { Error } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/providers';

const CampaignDetailText = ({ isCampaign = false, page_url, access, campaign, id }) => {
  const { isSoftware } = useContext(AppContext);
  const history = useHistory();

  const handleReportCampaign = () => {
    if (campaign) {
      history.push(`${page_url}${id}/campaign`);
    } else {
      toaster.notify(() => <div className="alert alert-warning">This promotion does not have any campaign</div>);
    }
  };
  if ((isSoftware || isCampaign) && access) {
    let result = '';
    if (campaign) {
      const isSent = campaign?.status === 'sent';
      if (isSent) {
        result = (
          <span className="link" id={`openCampaign-${id}`} onClick={handleReportCampaign}>
            Campaign
          </span>
        );
      } else {
        result = <small className="text-info">has not been sent yet</small>;
      }
    } else {
      result = (
        <div className="font-weight-bold text-warning">
          <Error className="font-size-20" />
          <small className="d-block text-center">No Campaign Exists</small>
        </div>
      );
    }
    return <td className="text-center">{result}</td>;
  }
  return null;
};

CampaignDetailText.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCampaign: PropTypes.bool,
  access: PropTypes.bool,
  page_url: PropTypes.string,
  campaign: PropTypes.any,
};

export default CampaignDetailText;
