import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import { AppContext } from '../../../context/providers';
import { handleApiSave } from '../../../utilities/useApi';
import { partialUpdateProfile } from '../../../api';

export const TourGuide = ({ steps: _steps, isOpen, save = false, ...props }) => {
  const [updateProfile, setUpdateProfile] = useState(false);
  const [prevIsOpen, setPrevIsOpen] = useState(null);
  const [current, setCurrent] = useState(null);
  const [done, setDone] = useState([]);
  const [steps, setSteps] = useState([]);
  const { user, setUser, userPermissions: p } = useContext(AppContext);
  useEffect(() => {
    if (!isOpen && prevIsOpen) {
      handleRequestClose(false);
    }
    setPrevIsOpen(isOpen);
  }, [isOpen]);

  const isExistKey = (_key) => {
    const obj = save ? user.metadata?.guided || {} : {};

    const [group, key] = (_key || '').split('.');

    if (obj?.[group]) {
      return (obj[group] || []).indexOf(key) > -1;
    }
    return false;
  };

  const createObj = (list, prev) =>
    list.reduce((acc, i) => {
      const [group, key] = (i || '').split('.');
      return { ...acc, [group]: [...(acc[group] || prev[group] || []), key] };
    }, {});

  useEffect(() => {
    if (p !== null) {
      // setSteps(_steps);
      const exists = _steps.filter((s) => s.key && !isExistKey(s.key));
      const checkedAccessed = exists.filter((i) => {
        if (i.access) {
          const [group, model, type] = (i.access || '').split('.');
          if (!p?.[group]?.[model]?.[type]) {
            return false;
          }
        }
        return true;
      });
      setSteps(checkedAccessed);
    } else {
      setSteps([]);
    }
  }, [_steps, p, save]);

  useEffect(() => {
    if (steps.length && steps[current] && done.indexOf(steps[current].key) === -1) {
      setDone([...done, steps[current].key]);
    }
  }, [current]);

  const handleRequestClose = (fromRequest = true) => {
    const _done = done.length === 0 && steps.length && steps[current] ? [steps[current].key] : done;
    setPrevIsOpen(isOpen);
    if (props.onRequestClose && fromRequest) props.onRequestClose();
    setUpdateProfile(true);
    if (!updateProfile) {
      const guided = user.metadata?.guided || {};
      const obj = createObj(_done, guided);
      const body = {
        metadata: {
          guided: {
            ...guided,
            ...obj,
          },
        },
      };
      handleApiSave(partialUpdateProfile, undefined, body)
        .then(setUser)
        .finally(() => setUpdateProfile(false));
    }
  };

  const handleCurrentStep = (currentStep) => {
    props.getCurrentStep(currentStep);
    setCurrent(currentStep);
  };

  if (steps.length === 0) return null;
  return (
    <Tour
      isOpen={isOpen}
      rounded={8}
      showNavigation={false}
      showNavigationNumber={false}
      showCloseButton={false}
      showNumber={false}
      {...props}
      getCurrentStep={handleCurrentStep}
      onRequestClose={handleRequestClose}
      steps={steps}
      // className="hello"
      // maskClassName="hello-mask"
      // highlightedMaskClassName="hello-highlighted-mask"
    />
  );
};

TourGuide.propTypes = {
  save: PropTypes.bool,
  steps: PropTypes.array,
  isOpen: PropTypes.bool,
  getCurrentStep: PropTypes.func,
  onRequestClose: PropTypes.func,
};
// export const TourGuide = (props) => (IS_DEV ? <TourGuideComponent {...props} /> : null);

TourGuide.propTypes = {
  steps: PropTypes.array,
  isOpen: PropTypes.bool,
  getCurrentStep: PropTypes.func,
  onRequestClose: PropTypes.func,
};

const TourContent = ({ title, description }) => (
  <div className="c-tour">
    {title && <h5 className="c-tour__title">{title}</h5>}
    {description && <p className="c-tour__desc">{description}</p>}
  </div>
);

TourContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default TourContent;
