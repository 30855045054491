import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import Input from '../Form/Input';

const MapSearchBox = (props) => (
  <div className="c-map__search">
    <Autocomplete {...props}>
      <Input formGroup={false} placeholder="Search your business name OR Your location..." />
    </Autocomplete>
  </div>
);

export default MapSearchBox;
