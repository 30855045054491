import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import BusinessObject from '../objects/BusinessObject';
// import NotificationObject from '../objects/NotificationObject';

const { GET } = ApiMethods;

// const _data = [
//   {
//     id: 1,
//     title: 'Calgary Location',
//     province: 'Alberta',
//     address: 'Calgary - Manhathan',
//     setup_marketplace: true,
//     setup_software: true,
//   },
//   {
//     id: 2,
//     title: 'Edmonton Location',
//     province: 'Alberta',
//     address: 'Edmonton - Manhathan',
//     setup_marketplace: true,
//     setup_software: false,
//   },
//   {
//     id: 3,
//     title: 'Voncover Location',
//     province: 'Alberta',
//     address: 'Voncover - Manhathan',
//     setup_marketplace: false,
//     setup_software: true,
//   },
// ];
//
// export const getLocationList = async () =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         count: _data.length,
//         results: _data,
//       });
//     }, 2000);
//   });
/**
 * @param params {{
 *     page,
 *     limit
 * }}
 */
export const getLocationList = async ({ page, limit }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.locations.list,
    locationBase: false,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new BusinessObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     page,
 *     limit
 * }}
 */
export const getCountriesList = async ({ page, limit }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    locationBase: false,
    url: ApiRoutes.locations.countries,
  });

  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     page,
 *     limit,
 *     countryCode,
 * }}
 */
export const getProvincesList = async ({ page, limit, countryCode }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
      country__code: countryCode,
    },
    locationBase: false,
    url: ApiRoutes.locations.provinces,
  });

  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     id,
 * }}
 */
export const getProvince = async ({ id }) => {
  const response = await callApi({
    method: GET,
    locationBase: false,
    url: createURL(ApiRoutes.locations.province, { id }),
  });

  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
