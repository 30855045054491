import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { CheckCircle, Error } from '@material-ui/icons';
import toaster from 'toasted-notes';
import Button from '../../UIKit/Form/Button';
import { handleApiSave } from '../../../../utilities/useApi';
import { publishLmo, scheduleCampaign, unPublishLmo } from '../../../../api';
import { AppContext } from '../../../../context/providers';
import CampaignDetailText from '../CampaignDetailText';

const PromotionLmoItem = ({ page_url, item, access, onChangePublish }) => {
  const [sendLoading, setSendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { business } = useContext(AppContext);
  const { id, serviceName, isBooked, discount, available_date, available_time, is_published, expired, campaign } = item;

  const handleSend = () => {
    handleApiSave(scheduleCampaign, null, { id, type: 'lmos' }, null, { loadingFn: setSendLoading })
      .then(() => {
        toaster.notify(() => <div className="alert alert-success">Campaign has been sent successfully</div>);
        onChangePublish(id, true, 'campaign');
      })
      .catch(() => {});
  };

  const handlePublish = () => {
    if (expired || isBooked) return;
    handleApiSave(is_published ? unPublishLmo : publishLmo, null, id, null, { loadingFn: setLoading })
      .then(({ is_published }) => {
        toaster.notify(() => (
          <div className="alert alert-success">
            {is_published ? `Your Lmo has been published successfully` : `Your Lmo has been unpublished successfully`}
          </div>
        ));
        onChangePublish(id, is_published);
      })
      .catch(() => {});
  };

  const disableBtn = useMemo(
    () =>
      expired ||
      isBooked ||
      !business?.connected_to_stripe ||
      (access ? (is_published ? !access.unpublish : !access.publish) : false),
    [access, item, business],
  );

  return (
    <tr>
      <td>{serviceName}</td>
      <td className="text-center">{discount}%</td>
      <td className="text-center">
        {available_date} {available_time}
      </td>
      <td
        className={classnames('text-center', {
          'text-info': !is_published,
          'text-success': is_published,
          'text-danger': expired,
          'text-primary': isBooked,
        })}
      >
        {isBooked ? 'Has Been Booked' : expired ? 'Expired' : is_published ? 'Published' : 'Draft'}
      </td>
      <td className="text-center">
        <Button
          sm
          disabled={disableBtn}
          loading={loading}
          className="m-auto"
          color={is_published ? 'warning' : 'success'}
          onClick={!disableBtn ? handlePublish : undefined}
          id={`publishPromotion-${id}`}
        >
          {is_published ? 'UnPublish' : 'Publish'}
        </Button>
      </td>
      {access?.send_campaign && (
        <td className="text-center">
          {campaign ? (
            campaign?.status === 'sent' ? (
              <div className="font-weight-bold text-success">
                <CheckCircle className="font-size-20" />
                <small className="d-block text-center">Has Been Sent</small>
              </div>
            ) : (
              <Button
                sm
                disabled={expired || !access?.send_campaign}
                loading={sendLoading}
                className="m-auto"
                onClick={handleSend}
              >
                {campaign?.status === 'sent' ? 'Sent' : 'Send'}
              </Button>
            )
          ) : (
            <span className="font-weight-bold text-warning">
              <Error className="font-size-20" />
              <small className="d-block text-center">No Campaign Exists</small>
            </span>
          )}
        </td>
      )}
      {/*<td className="text-center">*/}
      {/*  <ReleasePromotionButton type="lmo" item={item} access={access} onSubmit={onChangePublish} />*/}
      {/*</td>*/}
      <CampaignDetailText isCampaign page_url={page_url} campaign={campaign} access={access?.campaign_report} id={id} />
      <td className="text-center">
        <NavLink to={`${page_url}${id}`}>Manage</NavLink>
      </td>
      {item?.appointment_id && (
        <td>
          <NavLink to={`/calendar/appointment/${item?.appointment_id}/edit`}>Appointment Detail</NavLink>
        </td>
      )}
    </tr>
  );
};

PromotionLmoItem.propTypes = {
  page_url: PropTypes.string,
  access: PropTypes.shape({
    publish: PropTypes.bool,
    unpublish: PropTypes.bool,
    send_campaign: PropTypes.bool,
    campaign_report: PropTypes.bool,
  }),
  item: PropTypes.any,
  onChangePublish: PropTypes.func,
};

export default PromotionLmoItem;
