import React, { createContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Types } from '../../config/actionTypes';
import { handleApi } from '../../utilities/useApi';
import { getLocationList, getStylistProfile } from '../../api';

const MARKETPLACE = 'marketplace';
const SOFTWARE = 'software';
const NOTHING = 'nothing';

export const AppContext = createContext({
  locations: null,
  businessLoading: true,
  business: null,
  newBusiness: null,
  onNewBusiness: () => {},
  setBusiness: () => {},
  softwareAccess: true,
  system: MARKETPLACE,
  setSystem: () => {},

  user: null,
  setUser: () => {},

  stylist: null,
  setStylist: () => {},

  permissions: null,
  setPermissions: () => {},

  userPermissions: null,
  setUserPermissions: () => {},

  isDebug: false,
});

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [locations, setLocations] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [businessLoading, setBusinessLoading] = useState(true);
  const [business, setBusiness] = useState(null);
  const [newBusiness, setNewBusiness] = useState(null);
  const [softwareAccess, setSoftwareAccess] = useState(true);
  const [user, setUser] = useState(null);
  const [stylist, setStylist] = useState(null);
  const [system, setSystem] = useState(MARKETPLACE);
  const [header, setHeader] = useState(null);

  const onCheckAccess = () => {
    // if (business && !business?.subscribed && !business?.is_trial && system === SOFTWARE) {
    //   setSoftwareAccess(false);
    // } else if (system === MARKETPLACE || (business && business?.is_trial)) {
    //   setSoftwareAccess(true);
    // }
    if (business && !business?.subscribed && user && !user?.trialing && system === SOFTWARE) {
      setSoftwareAccess(false);
    } else if (system === MARKETPLACE || (user && user?.trialing)) {
      setSoftwareAccess(true);
    }
  };
  useEffect(() => {
    const _token = localStorage.getItem(Types.USER_TOKEN);
    if (user && !locations) {
      const _currentLocation = localStorage.getItem(Types.BUSINESS_LOCATION);
      setBusinessLoading(true);
      handleApi(getLocationList, {}, { infinitePage: true })
        .then((res) => {
          if (res.results) {
            setLocations(res.results.filter((i) => i.marketplace_setup || i.software_setup));
            if (res.results.length === 1 && !_currentLocation) {
              const current = res.results[0];
              setCurrentLocation(current.id);
              localStorage.setItem(Types.BUSINESS_LOCATION, current.id);
            }

            if (!_currentLocation && res.results.length === 1) window.location.reload();
          } else {
            setLocations([]);
          }
          setBusinessLoading(false);
        })
        .finally(() => setBusinessLoading(false));
      if (_currentLocation) setCurrentLocation(_currentLocation);
    } else if (!_token) {
      setBusinessLoading(false);
    }
  }, [user]);

  useEffect(() => {
    onCheckAccess();
  }, [system, business, user]);

  useEffect(() => {
    if (user) {
      handleApi(getStylistProfile).then((data) => {
        setStylist(data);
      });
    }
  }, [user]);

  useEffect(() => {
    if (business?.profile) {
      localStorage.setItem(Types.CITY, business.profile.city || '');
    }
  }, [business]);

  const onSwitchClass = (type) => {
    if (type === SOFTWARE) {
      document.body.classList.add('software');
      document.body.classList.remove('marketplace');
    } else {
      document.body.classList.add('marketplace');
      document.body.classList.remove('software');
    }
  };

  const onSwitchSystem = () => {
    const updateSystem = system === MARKETPLACE ? SOFTWARE : MARKETPLACE;
    localStorage.setItem(Types.SYSTEM_TYPE, updateSystem);
    onSwitchClass(updateSystem);
    setSystem(updateSystem);
    // if (business && !(business?.subscribed || business?.is_trial) && updateSystem === SOFTWARE) {
    //     setSoftwareAccess(false);
    // } else if (updateSystem === MARKETPLACE) {
    //     setSoftwareAccess(true);
    // }
  };

  const handleBusiness = (res) => {
    setBusiness(res);
    // if (res && !(res?.subscribed || res?.is_trial) && system === SOFTWARE) {
    //     setSoftwareAccess(false);
    // } else {
    //     setSoftwareAccess(true);
    // }
  };

  const onSystem = (type) => {
    if (typeof type === 'string') {
      let system;
      switch (type) {
        case MARKETPLACE:
          system = MARKETPLACE;
          break;
        case SOFTWARE:
          system = SOFTWARE;
          break;
        default:
          system = NOTHING;
      }

      if (system !== NOTHING) localStorage.setItem(Types.SYSTEM_TYPE, system);
      else localStorage.removeItem(Types.SYSTEM_TYPE);
      onSwitchClass(system);
      setSystem(system);

      // if (business && !(business?.subscribed || business?.is_trial) && system === SOFTWARE) {
      //     setSoftwareAccess(false);
      // } else if (system === MARKETPLACE) {
      //     setSoftwareAccess(true);
      // }
    }
  };

  /**
   *
   * @param {String} title
   * @param {{
   *    videoLink: {String},
   *  }}
   */
  const handleHeader = useCallback(
    (title, options) => {
      setHeader({ title, ...options });
    },
    [setHeader],
  );

  const isDebug = useMemo(() => {
    const email = user?.user?.email || '';
    return process.env.REACT_APP_DEBUG === 1 || email.includes('ali.mortazavi') || email.includes('instagram.review');
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        isDebug,
        businessLoading,
        business,
        softwareAccess,
        setBusiness: handleBusiness,
        user,
        setUser,
        stylist,
        setStylist,
        setHeader: handleHeader,
        header,
        system,
        isMarketplace: system === MARKETPLACE,
        isSoftware: system === SOFTWARE,
        setSystem: onSystem,
        onSwitchSystem,
        onCheckAccess,
        locations,
        currentLocation,
        newBusiness,
        onNewBusiness: setNewBusiness,
        setLocationsList: setLocations,
        setPermissions: setPermissions,
        permissions: permissions,
        setUserPermissions: setUserPermissions,
        userPermissions: userPermissions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
