import React, { useEffect, useMemo, useState } from 'react';
import { Close, AddCircleOutline } from '@material-ui/icons';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ControlInput } from '../../UIKit/Form/Input';
import Button from '../../UIKit/Form/Button';
import { handleChangeDiscount } from '../../../../utilities/HandleErrors';
import { PageLoading } from '../../UIKit/Spinner';

const StaffCommissionItem = ({
  isModify,
  control,
  clearErrors,
  setError,
  watch,
  errors: _errors,
  index,
  length,
  item,
  onChange,
  onRemove,
}) => {
  const [unit, setUnit] = useState('currency');

  const handleUnit = (val) => {
    clearErrors(`commission[${index}].amount`);
    const w = watch();
    const value = w.commission[index].amount;
    if (val === 'percent') handleChangeDiscount(`commission[${index}].amount`, value, setError, clearErrors, '');
    setUnit(val);
  };
  useEffect(() => {
    if (item && item?.amount_type) {
      setUnit(item?.amount_type);
    }
  }, [item]);
  const errors = useMemo(
    () => (_errors?.amount && _errors?.amount[index] ? _errors?.amount && _errors?.amount[index] : null),
    [_errors, index, watch],
  );

  const handleChangeUnit = (unit, onChange) => {
    if (isModify && onChange) {
      handleUnit(unit);
      onChange(unit);
    }
  };

  return (
    <div
      className={classNames('c-staff__commission-item', {
        'c-staff__commission-item--disabled': item.disabled,
      })}
    >
      {isModify && (
        <div className="c-staff__commission-item-remove" onClick={() => onRemove(item.id)}>
          <Close />
        </div>
      )}
      <div className="c-form__group-row c-form__group-row--4 align-items-end">
        {item.id && <Controller control={control} name={`commission[${index}].id`} defaultValue={item.id} />}
        <ControlInput
          control={control}
          disabled={index === 0 || !isModify}
          unit="$"
          name={`commission[${index}].service_from`}
          defaultValue={item.service_from || ''}
          onChangeText={(e) => onChange('service_from', e)}
          label="From"
          type="tel"
          rules={index !== 0 ? { required: 'From is required' } : undefined}
          formStyle={errors && !(errors?.service_from || null) ? { marginBottom: '35px' } : undefined}
          placeholder="0.00"
          errorMessage={errors?.service_from?.message || null}
        />
        <ControlInput
          control={control}
          unit="$"
          disabled={length - 1 === index || !isModify}
          name={`commission[${index}].service_to`}
          defaultValue={
            length - 1 === index && String(item.service_to) === '9999999999' ? '∞' : '' || item?.service_to || ''
          }
          value={length - 1 === index ? '∞' : undefined}
          onChangeText={(e) => onChange('service_to', e)}
          label="To"
          type="tel"
          placeholder="0.00"
          rules={length - 1 !== index ? { required: 'To is required' } : undefined}
          formStyle={errors && !(errors?.service_to || null) ? { marginBottom: '35px' } : undefined}
          errorMessage={errors?.to?.message || null}
        />
        <ControlInput
          control={control}
          unit={unit === 'percent' ? '%' : '$'}
          name={`commission[${index}].amount`}
          defaultValue={item?.amount || ''}
          onChangeText={(e) => {
            if (unit === 'percent' && +e <= 100) clearErrors(`commission[${index}].amount`);
          }}
          disabled={!isModify}
          rules={{
            min: {
              value: 0,
              message: 'Cannot be less than 0',
            },
            max:
              unit === 'percent'
                ? {
                    value: 100,
                    message: 'Cannot be more than 100',
                  }
                : undefined,
            required: 'Commission is required',
          }}
          formStyle={errors && !(errors?.amount || null) ? { marginBottom: '35px' } : undefined}
          errorMessage={errors?.amount?.message || null}
          label="Commission"
          type="tel"
          placeholder="0.00"
        />
        <Controller
          control={control}
          name={`commission[${index}].amount_type`}
          defaultValue={item?.amount_type || 'currency'}
          render={({ onChange }) => (
            <div className="c-button-group" style={{ marginBottom: errors ? '35px' : '10px' }}>
              <Button
                color={unit === 'percent' ? 'main' : 'light'}
                type="button"
                onClick={() => handleChangeUnit('percent', onChange)}
              >
                %
              </Button>
              <Button
                color={unit === 'currency' ? 'main' : 'light'}
                type="button"
                onClick={() => handleChangeUnit('currency', onChange)}
              >
                $
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
};

StaffCommissionItem.propTypes = {
  isModify: PropTypes.bool,
  control: PropTypes.any,
  length: PropTypes.number,
  index: PropTypes.number,
  errors: PropTypes.any,
  watch: PropTypes.any,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  setValue: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
    service_from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    service_to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    amount_type: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};

const StaffFormCommission = ({
  isModify = false,
  loading = false,
  commissions,
  control,
  watch,
  errors,
  setValue,
  clearErrors,
  setError,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (commissions.length > 0) {
      setList(commissions.reduce((acc, i) => [...acc, ...i.tiers], []));
    }
  }, [commissions]);

  useEffect(() => {
    if (commissions.length > 0) {
      setList(commissions.reduce((acc, i) => [...acc, ...i.tiers], []));
    }
  }, [commissions]);

  const handleAddCommission = () => {
    const updateList = [...list];
    const id = `${new Date().getTime()}-fake`;
    setValue(`commission[${updateList.length - 1}].service_to`, '');
    updateList.push({ id, service_from: 0, service_to: '9999999999', amount: 0, amount_type: 'currency' });
    setList(updateList);
  };
  const handleRemoveCommission = (id) => {
    const updateList = [...list];
    const w = watch();
    const index = updateList.findIndex((i) => i.id === id);
    const item = w.commission[index];
    updateList.splice(index, 1);
    clearErrors(`commission[${index}]`);
    setList(updateList);
    setTimeout(() => {
      (w.commission || []).forEach((i, ind) => {
        if (ind !== index) {
          if (ind > index) {
            if (ind - 1 === index) setValue(`commission[${ind - 1}].service_from`, item.service_from);
            else setValue(`commission[${ind - 1}].service_from`, i.service_from);
            setValue(`commission[${ind - 1}].service_to`, i.service_to);
            setValue(`commission[${ind - 1}].amount`, i.amount);
          } else {
            setValue(`commission[${ind}].service_from`, i.service_from);
            setValue(`commission[${ind}].service_to`, i.service_to);
            setValue(`commission[${ind}].amount`, i.amount);
          }
        }
      });
    }, 10);
  };
  const handleChangeCommission = (key, index, value) => {
    if (key === 'service_to') {
      setValue(`commission[${index + 1}].service_from`, (+value + 0.01).toFixed(2));
    } else if (key === 'service_from') {
      setValue(`commission[${index - 1}].service_to`, (+value - 0.01).toFixed(2));
    }
  };
  return (
    <>
      <div data-tour="commissions" className="py-3">
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <h3>Service Commission</h3>
            {list.map((item, index) => (
              <StaffCommissionItem
                isModify={isModify}
                key={item.id}
                control={control}
                index={index}
                length={list.length}
                errors={errors}
                item={item}
                watch={watch}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
                onChange={(key, value) => handleChangeCommission(key, index, value)}
                onRemove={handleRemoveCommission}
              />
            ))}
            {isModify && (
              <div className="c-staff__commission-add" data-tour="add-commission" onClick={handleAddCommission}>
                <AddCircleOutline /> Add Commission
              </div>
            )}
            {!isModify && list.length === 0 && (
              <p className="text-center text-danger font-size-14">There is not any commission for this staff</p>
            )}
          </>
        )}
      </div>
    </>
  );
};

StaffFormCommission.propTypes = {
  isModify: PropTypes.bool,
  loading: PropTypes.bool,
  commissions: PropTypes.array,
  control: PropTypes.any,
  errors: PropTypes.any,
  watch: PropTypes.any,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
};

export default StaffFormCommission;
