import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { withRouter } from 'react-router-dom';
import Dropdown from '../../../Common/UIKit/Dropdown';
import Button from '../../../Common/UIKit/Form/Button';
import ImageName from '../../../Common/UIKit/ImageName';
import { deleteClient, partialUpdateClient } from '../../../../api';
import { handleApiDeleteById, handleApiSave } from '../../../../utilities/useApi';
import { PageLoading } from '../../../Common/UIKit/Spinner';
import { AppContext } from '../../../../context/providers';
import { ClientNotificationSettingChoice } from '../../../../utilities/constants/choices';
import { getDateInfo } from '../../../../utilities/DateHelpers';
import usePermission from '../../../../utilities/usePermission';

const ClientProfile = ({ id, data, setUri, history }) => {
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isSoftware } = useContext(AppContext);
  const { access: _access } = usePermission('client', 'crud', 'modify');
  useEffect(() => {
    setIsActive(data.is_active);
  }, [data]);
  const handleDelete = () => {
    const deleteOptions = {
      title: 'Confirm to delete client',
      message: 'Are you sure to delete this client.',
      loadingFn: setLoading,
    };
    handleApiDeleteById(deleteClient, id, deleteOptions).then(() => {
      if (data) {
        toaster.notify(() => <div className="alert alert-success">Client Deleted Successfully</div>);
        history.replace('/clients');
      }
    });
  };
  const handleChangeActive = () => {
    handleApiSave(null, partialUpdateClient, { is_active: !isActive }, id, { loadingFn: setLoading })
      .then(() => {
        if (isActive) {
          toaster.notify(() => <div className="alert alert-success">Client Inactive Successfully</div>);
        } else {
          toaster.notify(() => <div className="alert alert-success">Client Active Successfully</div>);
        }
        setIsActive(!isActive);
      })
      .catch(() => {});
  };

  const clientOptions = [
    {
      url: `/clients/${id}/edit`,
      id: 'edit-opt',
      label: 'Edit',
      onClick: () => setUri(`/clients/${id}/edit`),
    },
    {
      type: 'node',
      node: (
        <span id="active-opt" className="dropdown-link">
          {isActive ? 'Inactive' : 'Active'}
        </span>
      ),
      onClick: handleChangeActive,
    },
    {
      type: 'node',
      node: (
        <span id="delete-opt" className="link text-danger">
          Delete
        </span>
      ),
      textColor: 'danger',
      onClick: handleDelete,
    },
  ];
  const birthDate = useMemo(() => {
    if (data && data?.birthday_day && data?.birthday_month) {
      const date = new Date();
      date.setMonth(data.birthday_month - 1, data.birthday_day);
      return `${date.getDate()} ${getDateInfo(date).monthName}`;
    }
    return null;
  }, [data]);

  return (
    <>
      {loading && <PageLoading over />}
      <div className="c-panel-content mb-5">
        <div className="c-client__profile">
          <ImageName className="c-client__avatar" imageSrc={data?.avatar} name={data?.name} />
          <h3 className="c-client__name">{data?.name}</h3>
          {isActive ? (
            <span className={`badge badge-primary c-client__status`}>Activate</span>
          ) : (
            <span className={`badge badge-danger c-client__status`}>Inactive</span>
          )}
          {/*{data?.state && (*/}
          {/*    <span className={`badge ${data?.state === 'blocked' ? 'badge-secondary' : 'badge-primary'} c-client__status`}>*/}
          {/*        {data?.state?.toUpperCase()}*/}
          {/*    </span>*/}
          {/*)}*/}
        </div>
        <div className="c-client__options">
          {_access && (
            <Dropdown options={clientOptions}>
              <div id="client-options" className="c-client__options-btn">
                <i className="fas fa-ellipsis-v" />
              </div>
            </Dropdown>
          )}
          {isSoftware && (
            <Button id="clientNewAppt" link={`/clients/${id}/add-appointment`} block>
              New Appointment
            </Button>
          )}
        </div>
        <div className="c-client__info">
          <div className="c-client__info-item">
            <strong>Mobile: </strong>
            <span>{data?.phone}</span>
          </div>
          <div className="c-client__info-item">
            <strong>Email: </strong>
            <span>{data?.email}</span>
          </div>
          {data?.user?.notification_method && (
            <div className="c-client__info-item">
              <strong>Notification Preferences: </strong>
              <span>{ClientNotificationSettingChoice[data.user.notification_method]}</span>
            </div>
          )}

          {birthDate && (
            <div className="c-client__info-desc">
              <strong>Birth Date: </strong>
              <p>{birthDate}</p>
            </div>
          )}
          {data?.note.trim() && (
            <div className="c-client__info-desc">
              <strong>Customer Note: </strong>
              <p>{data?.note}</p>
            </div>
          )}

          {/*<div className="c-client__info-desc">*/}
          {/*    <p>Customer Notification Preferences</p>*/}
          {/*    <p>{data?.notification}</p>*/}
          {/*    /!*<p>Accepts marketing notifications</p>*!/*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

ClientProfile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.any,
  setUri: PropTypes.func,
  history: PropTypes.any,
};

export default withRouter(ClientProfile);
