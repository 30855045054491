import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ControlInput } from '../../UIKit/Form/Input';
import Button from '../../UIKit/Form/Button';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { createClient, getClient, updateClient } from '../../../../api';
import ModalFormSidebarFooter from '../ModalFormSidebarFooter';
import { ModalFormSidebarClose } from '../ModalFormSidebar';
import { PageLoading } from '../../UIKit/Spinner';

const ModalFormCreateClient = ({ onSave, onClose, client }) => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [data, setData] = useState(client);
  const { control, errors, handleSubmit, setError } = useForm();
  useEffect(() => {
    if (client) {
      setLoading(true);
      if (!client?.full_name) {
        handleApi(getClient, client.id, { list: false })
          .then(setData)
          .finally(() => setLoading(false));
      } else {
        setData(client);
        setLoading(false);
      }
    }
  }, [client]);
  const handleSave = (data) => {
    handleApiSave(createClient, updateClient, data, client?.id, {
      loadingFn: setSaveLoading,
      setError,
    })
      .then(onSave)
      .catch(() => {});
  };
  return (
    <>
      <div className="c-modal-form-sidebar__header">
        <ModalFormSidebarClose onClose={onClose} />
        <span className="c-modal-form-sidebar__header-title">{client ? 'Edit Client' : 'Create Client'}</span>
      </div>
      {loading ? (
        <PageLoading />
      ) : (
        <form
          className="c-modal-form-sidebar__content c-modal-form-sidebar__content--header"
          id="createClientForm"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="c-modal-form-sidebar__clients-form">
            <ControlInput
              control={control}
              name="full_name"
              rules={{ required: 'Full Name is required' }}
              label={'Full Name'}
              defaultValue={data?.full_name || ''}
              placeholder={'Full Name'}
              errorMessage={errors?.full_name?.message}
            />
            <ControlInput
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              type={'email'}
              label={'Email'}
              defaultValue={data?.email || ''}
              placeholder={'Email'}
              errorMessage={errors?.email?.message}
            />
            <ControlInput
              control={control}
              name="phone_number"
              // rules={{ required: 'Mobile is required' }}
              type="phone"
              label="Mobile"
              defaultValue={data?.phone_number || ''}
              placeholder="Mobile"
              errorMessage={errors?.phone_number?.message}
            />
            {/*<ControlSelect*/}
            {/*    control={control}*/}
            {/*    name="notification_setting"*/}
            {/*    defaultValue={data?.notification_setting || 'all'}*/}
            {/*    placeholder={'Select Birth Day'}*/}
            {/*    options={getValueLabel(ClientNotificationSettingChoice)}*/}
            {/*    label="Notification Preference"*/}
            {/*    errorMessage={errors?.notification_setting?.message}*/}
            {/*/>*/}
          </div>
          <ModalFormSidebarFooter>
            <Button color="light" type="button" id="clientFormDiscard" onClick={onClose}>
              Discard
            </Button>
            <Button id="clientFormSubmit" loading={saveLoading}>
              Save Client
            </Button>
          </ModalFormSidebarFooter>
        </form>
      )}
    </>
  );
};

ModalFormCreateClient.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  client: PropTypes.any,
};

export default ModalFormCreateClient;
