import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GetApp, Close, InsertDriveFile } from '@material-ui/icons';

const MessageAttachment = ({ downloadable = false, file, fileName, className, onRemove }) => {
  const isImage = useMemo(() => {
    if (file) {
      if (file?.file) {
        return file.file.type.includes('png') || file.file.type.includes('jpg') || file.file.type.includes('jpeg');
      } else if (file.type) {
        return file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg');
      } else if (typeof file === 'string') {
        return file.includes('png') || file.includes('jpg') || file.includes('jpeg');
      }

      return false;
    }
    return false;
  }, [file]);

  const src = useMemo(() => {
    if (file && isImage) {
      if (typeof file === 'string') return file;
      if (file?.src) return file?.src;
      return URL.createObjectURL(file);
    }
    return null;
  }, [isImage, file]);

  const name = useMemo(() => {
    let results = null;
    if (fileName) results = fileName;
    if (file && !isImage) {
      if (file?.name) results = file?.name;
      if (file?.file?.name) results = file.file.name;
    }
    if (results?.length > 20) {
      results = `${results.substr(0, 20)}...${results.substr(-10)}`;
    }
    return results;
  }, [isImage, file]);

  return (
    <div className={classnames('c-messages__attachment', className)}>
      {isImage ? (
        <div className="c-messages__attachment-image">
          <img src={src} alt="attachment-file" />
          {onRemove && (
            <span className="c-messages__attachment-image-remove" onClick={onRemove}>
              <Close />
            </span>
          )}
        </div>
      ) : (
        <div className="c-messages__attachment-file">
          <InsertDriveFile />
          {name}
          {downloadable && typeof file === 'string' && (
            <a href={file} download className="c-messages__attachment-file-download">
              <GetApp />
            </a>
          )}
          {onRemove && (
            <span className="c-messages__attachment-file-remove" onClick={onRemove}>
              <Close />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

MessageAttachment.propTypes = {
  downloadable: PropTypes.bool,
  file: PropTypes.any,
  fileName: PropTypes.any,
  className: PropTypes.any,
  onRemove: PropTypes.func,
};

export default MessageAttachment;
