import React from 'react';
import { Notifications } from '@material-ui/icons';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import useApi from '../../../utilities/useApi';
import { getMessageList, getNotificationList } from '../../../api';

const NotificationPage = () => {
  const [data, loading] = useApi(getNotificationList);
  const [] = useApi(getMessageList);

  return (
    <>
      <HeadPage title="Notifications" />
      {loading && !data ? (
        <PageLoading />
      ) : !!data?.results && data.results.length > 0 ? (
        <>
          <div className="c-panel-content">
            {loading && <PageLoading over />}
            <div className="c-table c-table--clickable c-table--sm">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Appointment booked</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
          {data && <Pagination count={data.count} />}
        </>
      ) : (
        <NotFoundData
          title="No Notification Exists"
          description="You don't have any notification"
          icon={<Notifications />}
        />
      )}
    </>
  );
};

export default NotificationPage;
