import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tab from '../../Common/UIKit/Tab';
import StaffWorkingHours from './StaffWorkingHours';
import StaffClosedDates from './StaffClosedDates';
import StaffMembers from './StaffMembers';
import StaffCommissions from './StaffCommissions';
import usePermission from '../../../utilities/usePermission';

const StaffPage = ({ location, history }) => {
  const [tabIndex, setTabIndex] = useState(null);
  const { access: _staff } = usePermission('staff', 'crud');
  const { access: _hour } = usePermission('staffworkinghour', 'crud');
  const { access: _commission } = usePermission('commission', 'crud');
  const { access: _closed } = usePermission('staffcloseddate', 'crud');

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('/staff/members')) {
      setTabIndex(0);
    } else if (pathname.includes('/staff/hours')) {
      setTabIndex(1);
    } else if (pathname.includes('/staff/commissions')) {
      setTabIndex(2);
    } else if (pathname.includes('/staff/closed-dates')) {
      setTabIndex(3);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  const handleSelectTab = (ind) => {
    setTabIndex(ind);
    if (ind === 1) history.push('/staff/hours');
    else if (ind === 2) history.push('/staff/commissions');
    else if (ind === 3) history.push('/staff/closed-dates');
    else history.push('/staff/members');
  };

  const tabs = [
    {
      hide: !_staff?.view && !_staff?.modify,
      label: 'Members',
    },
    {
      hide: !_staff?.view && !_hour?.modify && !_hour?.view,
      label: 'Availability',
    },
    {
      hide: !_commission?.view && !_commission?.modify,
      label: 'Commissions',
    },
    {
      hide: !_closed?.view && !_closed?.modify,
      label: 'Blocked Dates',
    },
  ];

  const renderRightEl = (
    <Tab
      id="staffTab"
      dataTour="tabs"
      tabs={tabs}
      tabHeadClassName={'p-0'}
      onSelect={handleSelectTab}
      selected={tabIndex}
    />
  );

  const renderPages = () => {
    switch (tabIndex) {
      case 0:
        return <StaffMembers headerEl={renderRightEl} />;
      case 1:
        return <StaffWorkingHours headerEl={renderRightEl} />;
      case 2:
        return <StaffCommissions headerEl={renderRightEl} />;
      case 3:
        return <StaffClosedDates headerEl={renderRightEl} />;
      default:
        return <StaffMembers headerEl={renderRightEl} />;
    }
  };

  return renderPages();
};

StaffPage.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default StaffPage;
