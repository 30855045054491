import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, withGoogleMap, withScriptjs, Marker } from 'react-google-maps';

export const MyGoogleMap = withScriptjs(
  withGoogleMap(({ defaultCenter, marker, options, mapRef, children, ...otherProps }) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={defaultCenter}
      options={{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        ...options,
      }}
      ref={mapRef}
      {...otherProps}
    >
      {/*<InfoBox*/}
      {/*    defaultPosition={new window.google.maps.LatLng(lat, lng)}*/}
      {/*    options={{ closeBoxURL: ``, enableEventPropagation: true }}*/}
      {/*>*/}
      {/*    <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>*/}
      {/*        <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>*/}
      {/*            Hello, Taipei!*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</InfoBox>*/}
      {marker && <Marker position={marker.center} animation />}
      {children}
    </GoogleMap>
  )),
);

// console.log('process.env.REACT_APP_GOOGLE_MAP', process.env.REACT_APP_GOOGLE_MAP);

// eslint-disable-next-line react/display-name
const Map = forwardRef(
  (
    { loadingElement, containerElement, mapElement, defaultCenter, marker, options = {}, children, ...otherProps },
    ref,
  ) => (
    <MyGoogleMap
      // googleMapURL={
      //   googleMapURL
      //     ? googleMapURL
      //     : `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${process.env.REACT_APP_MAP_API_KEY}`
      // }
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${
        process.env.REACT_APP_GOOGLE_MAP_API_KEY || 'AIzaSyBmvvn3UPSO3T3NSXMmngtwNoxfsd8qDZE'
      }`}
      loadingElement={loadingElement ? loadingElement : <div style={{ height: `100%` }} />}
      containerElement={containerElement ? containerElement : <div style={{ height: `100%`, width: `100%` }} />}
      mapElement={mapElement ? mapElement : <div style={{ height: `100%` }} />}
      defaultCenter={defaultCenter ? defaultCenter : { lat: 51.041493, lng: -114.066613 }}
      marker={marker}
      options={options}
      mapRef={ref}
      {...otherProps}
    >
      {children}
    </MyGoogleMap>
  ),
);
// calc(100vh - 210px)
Map.propTypes = {
  googleMapURL: PropTypes.string,
  loadingElement: PropTypes.any,
  containerElement: PropTypes.any,
  mapElement: PropTypes.any,
  defaultCenter: PropTypes.any,
  marker: PropTypes.any,
  options: PropTypes.any,
  children: PropTypes.any,
};

export default Map;
