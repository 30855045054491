import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../UIKit/Modal';
import { ControlInput } from '../../UIKit/Form/Input';
import { Controller, useForm } from 'react-hook-form';
import { PageLoading } from '../../UIKit/Spinner';
import ImageSelector from '../../UIKit/ImageSelector';
import PromotionCard from '../PromotionCard';
import { ControlDatePicker } from '../../UIKit/Form/DatePicker';
import { ControlTimePicker } from '../../UIKit/Form/TimePicker';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import { createLmo, deleteLmo, getLmo, updateLmo } from '../../../../api';
import Button from '../../UIKit/Form/Button';
import { getDateFromString, getStringFromDate, getStringFromTime } from '../../../../utilities/DateHelpers';
import { handleChangeDiscount } from '../../../../utilities/HandleErrors';
import CampaignInput from '../../Campaign/CampaignInput';
import { ControlServiceSelect } from '../../Selections/ServiceSelect';

// eslint-disable-next-line react/prop-types
const PromotionLmoFormModal = ({ onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [service, setService] = useState(null);

  const { control, errors, handleSubmit, setError, clearErrors, getValues, watch } = useForm();

  const { id } = useParams();

  const [data, loading] = useApiById(getLmo);

  const onSaveForm = (data) => {
    setSaveLoading(true);

    if (!data.image || typeof data.image === 'string') delete data.image;
    console.log(data);
    const body = {
      ...data,
      description: data.subject,
      services: [data.services],
      available_date: getStringFromDate(data.available_date),
      available_time: getStringFromTime(data.available_time),
    };
    handleApiSave(createLmo, updateLmo, body, id, { setError, loadingFn: setSaveLoading })
      .then(onSave)
      .catch(() => {});
  };

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete this LMO',
      message: 'Are you sure to delete this LMO.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteLmo, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  const imageUrl = watch().image && watch().image.url ? watch().image.url : data?.image ? data.image : undefined;

  return (
    <Modal
      show
      onClose={onClose}
      auto
      sticky
      disableFooter
      bodyClass="p-0"
      // onSave={handleSubmit(onSaveForm)}
      // saveLoading={saveLoading}
      // buttonComponent={!!id && <Button color="danger" loading={delLoading} onClick={handleDelete}>Delete</Button>}
      title={!!id ? 'Edit Last Minutes Openings' : 'Create Last Minutes Openings'}
    >
      {!!id && loading ? (
        <PageLoading />
      ) : (
        <div className="c-promotion__form-card-container">
          <div className="c-promotion__form-card-sidebar">
            <form onSubmit={handleSubmit(onSaveForm)}>
              <div className="c-promotion__form-card-content">
                <Controller
                  control={control}
                  name="image"
                  defaultValue={data?.image ? data.image : null}
                  render={({ onChange, onBlur, value }) => (
                    <ImageSelector id="dealImage" onChange={onChange} onBlur={onBlur} value={value} />
                  )}
                />

                <h3 className="my-4">Content</h3>

                {/*<ControlInput*/}
                {/*    control={control}*/}
                {/*    name="subject"*/}
                {/*    rules={{ required: 'Subject is required' }}*/}
                {/*    defaultValue={data?.subject ? data.subject : ''}*/}
                {/*    label={'Subject'}*/}
                {/*    placeholder={'Subject'}*/}
                {/*    errorMessage={errors?.subject?.message}*/}
                {/*/>*/}

                <ControlServiceSelect
                  control={control}
                  name="services"
                  rules={{ required: 'Service is required' }}
                  defaultValue={data?.services ? data.services[0] : ''}
                  label="services"
                  placeholder="Select a service"
                  errorMessage={errors?.services?.message}
                  onChange={setService}
                />

                <ControlInput
                  control={control}
                  name="discount"
                  rules={{
                    min: {
                      value: 0,
                      message: 'Discount cannot be less than 0',
                    },
                    max: {
                      value: 100,
                      message: 'Discount cannot be more than 100',
                    },
                    required: 'Discount is required',
                  }}
                  defaultValue={data?.discount ? data.discount : ''}
                  type="tel"
                  unit="%"
                  min={0}
                  max={100}
                  label={'Discount Amount'}
                  onChangeText={(v) => handleChangeDiscount(v, setError, clearErrors)}
                  placeholder={'e.g. 30 (30% off)'}
                  errorMessage={errors?.discount?.message}
                />

                <div className="c-form__group-row">
                  <ControlDatePicker
                    control={control}
                    name="available_date"
                    rules={{ required: 'Date is required' }}
                    defaultValue={data?.available_date ? getDateFromString(data.available_date) : new Date()}
                    label={'Available date'}
                    errorMessage={errors?.available_date?.message}
                  />
                  <ControlTimePicker
                    control={control}
                    name="available_time"
                    rules={{ required: 'Time is required' }}
                    defaultValue={
                      data?.available_time ? getDateFromString(data.available_date, data.available_time) : new Date()
                    }
                    label={'Available time'}
                    errorMessage={errors?.available_time?.message}
                  />
                </div>

                <ControlInput
                  type="textarea"
                  rows={3}
                  maxLength={100}
                  control={control}
                  name="subject"
                  rules={{ required: 'Description is required' }}
                  defaultValue={data?.subject ? data.subject : ''}
                  label={'Descriptions'}
                  placeholder={'Enter description...'}
                  errorMessage={errors?.subject?.message}
                />

                <CampaignInput hasMarketplace data={data} errors={errors} control={control} />
              </div>

              <div className="c-promotion__form-card-footer">
                <Button loading={saveLoading}>Save</Button>
                {!!id && (
                  <Button color="danger" loading={delLoading} onClick={handleDelete}>
                    Delete
                  </Button>
                )}
              </div>
            </form>
          </div>

          <div className="c-promotion__card-wrapper">
            <div className="c-promotion__card-container">
              <PromotionCard
                imageUrl={imageUrl}
                subject={service?.label}
                description={getValues('description')}
                discount={getValues('discount')}
                campaignAudience={getValues('audience')}
                emailSubject={getValues('email_subject')}
                emailDescription={getValues('email_description')}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PromotionLmoFormModal;
