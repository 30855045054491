import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowBack, ArrowRightAlt, PlayArrow } from '@material-ui/icons';
import YouTube from 'react-youtube';
import Button from '../../UIKit/Form/Button';

const SetupGoalContent = ({ content, onBack, onSubmit }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const handleBack = () => {
    setPlayVideo(false);
    onBack();
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
    event.target.playVideo();
  };

  return (
    <div className="c-panel-setup__onboard">
      {playVideo ? (
        <div className="c-panel-setup__onboard-video">
          <div className="c-panel-setup__onboard-video-head">
            <div className="c-panel-setup__onboard-video-prev" onClick={handleBack}>
              <ArrowBack />
            </div>
            <Button sm className="c-panel-setup__onboard-btn" onClick={onSubmit}>
              Continue To Setup
              <ArrowRightAlt />
            </Button>
          </div>
          <YouTube
            videoId={content.youtubeId}
            className="c-panel-setup__onboard-video-player"
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
              },
            }}
            onReady={onReady}
          />
        </div>
      ) : (
        <div className="c-panel-setup__onboard-preview">
          <img src={content.imageCover} alt={content.type} className="c-panel-setup__onboard-img" />
          <div className="c-panel-setup__onboard-prev-text">
            <h1>{content.title}</h1>
            <p>{content.text}</p>
            <div className="c-panel-setup__onboard-prev-buttons">
              <Button lg className="c-panel-setup__onboard-btn" onClick={onSubmit}>
                Continue To Setup
                <ArrowRightAlt />
              </Button>
              <div className="c-panel-setup__onboard-prev-play" onClick={() => setPlayVideo(true)}>
                <PlayArrow />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SetupGoalContent.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    youtubeId: PropTypes.string,
    imageCover: PropTypes.any,
  }),
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SetupGoalContent;
