import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StarRounded } from '@material-ui/icons';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { changeSubscription, getBusiness, getBusinessById, postPlanCheckout } from '../../../api';
import { getPriceFormat } from '../../../utilities/Price';
import Button from '../UIKit/Form/Button';
import { AppContext } from '../../../context/providers';
import toaster from 'toasted-notes';

const SoftwarePlanItem = ({
  title = '',
  // taxRate = null,
  theme = 'light',
  list = [],
  item = null,
  isNew = false,
  onSessionId,
  selected = false,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { business, setBusiness, newBusiness, onNewBusiness } = useContext(AppContext);

  if (item && list.length === 0) {
    list = Object.keys(item.limits)
      .map((key) => {
        if (key === 'staffs') {
          if (+item.limits[key] === 0 || +item.limits[key] === 'none') {
            return { active: true, label: 'Unlimited staff members' };
          } else if (+item.limits[key] === 1) {
            return { active: true, label: 'Only 1 staff member' };
          } else {
            return { active: true, label: `Up to ${+item.limits[key]} staff members` };
          }
        }
        return null;
      })
      .filter((i) => i);
  }

  const handleSubmit = () => {
    const planId = item?.plan || item?.id;
    if (planId) {
      setSubmitLoading(true);
      if (isNew || !business) {
        handleApiSave(
          newBusiness?.plan && newBusiness?.subscribed ? changeSubscription : postPlanCheckout,
          null,
          // { plan: item.stripe_plan },
          { plan: planId, businessId: newBusiness.id },
          // null,
          // { loadingFn: setSubmitLoading },
        )
          .then((data) => {
            if (data?.session_id && onSessionId) {
              onSessionId(data.session_id, planId);
            }
            if (newBusiness?.plan && newBusiness?.subscribed) {
              handleApi(getBusinessById, newBusiness.id, { list: false })
                .then(onNewBusiness)
                .finally(() => setSubmitLoading(false));
            } else {
              setSubmitLoading(false);
            }
          })
          .catch(({ errors }) => {
            if (errors?.plan) {
              toaster.notify(() => <div className="alert alert-danger">{errors?.plan[0]}</div>);
            }
            setSubmitLoading(false);
          });
      } else {
        handleApiSave(
          business?.plan && business?.subscribed ? changeSubscription : postPlanCheckout,
          null,
          // { plan: item.stripe_plan },
          { plan: planId, businessId: business.id },
          // null,
          // { loadingFn: setSubmitLoading },
        )
          .then((data) => {
            if (data?.session_id && onSessionId) {
              onSessionId(data.session_id, planId);
            }
            if (business?.plan && business?.subscribed) {
              handleApi(getBusiness, {}, { list: false })
                .then(setBusiness)
                .finally(() => setSubmitLoading(false));
            } else {
              setSubmitLoading(false);
            }
          })
          .catch(({ errors }) => {
            if (errors?.plan) {
              toaster.notify(() => <div className="alert alert-danger">{errors?.plan[0]}</div>);
            }
            setSubmitLoading(false);
          });
      }
    }
  };

  return (
    <div
      className={classnames(`c-settings-plans__item c-settings-plans__item--${theme}`, {
        'my-3': selected,
        'mx-auto': selected,
      })}
    >
      <span className="c-settings-plans__item-icon">
        <StarRounded />
      </span>
      <span className="c-settings-plans__item-title">{item?.name ? item?.name : title}</span>
      {/*<span className="c-settings-plans__item-range">$14 - $55</span>*/}
      <span className="c-settings-plans__item-range">{item ? getPriceFormat(+item?.price) : '$0'}</span>
      <div className="c-settings-plans__item-content">
        <div className="c-settings-plans__item-body">
          {/*{onSessionId && (*/}
          {/*    <Select label="Select Month Pack"*/}
          {/*            options={options}*/}
          {/*            onChange={setMonth}*/}
          {/*            value={months ? months : 30}*/}
          {/*            defaultValue={months}*/}
          {/*            formClassName="c-settings-plans__item-input"*/}
          {/*    />*/}
          {/*)}*/}

          <ul className="c-settings-plans__benefits">
            {/* eslint-disable-next-line no-unused-vars */}
            {list.map(({ active, value, label }, key) => (
              <li className="c-settings-plans__benefits-item" key={key}>
                {/*<span className="c-settings-plans__benefits-item-value">{active ? value ? value : <CheckRounded /> : <CloseRounded />}</span>*/}
                {label}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {!selected && (
        <Button
          block
          disabled={
            isNew
              ? newBusiness?.plan === item.id && newBusiness?.subscribed
              : business?.plan === item.id && business?.subscribed
          }
          className="mt-4"
          loading={submitLoading}
          onClick={handleSubmit}
        >
          {/*{getPriceFormat(months ? +months?.price : options[0].price)} Subscribe*/}
          {/*{getPriceFormat(item ? +item.price : months ? +months?.price : 0)}*/}
          {getPriceFormat(item ? +item.price : 0, undefined, undefined, 0)}
          {' / '}
          {(
            isNew
              ? newBusiness?.plan === item.id && newBusiness?.subscribed
              : business?.plan === item.id && business?.subscribed
          ) ? (
            <>
              {/*Subscribed &nbsp;*/}
              month &nbsp;
              <small>(current plan)</small>
            </>
          ) : (
            'month'
          )}
          {/*{taxRate ? (*/}
          {/*  <>*/}
          {/*    &nbsp;+ ${((+item.price || 0) * taxRate) / 100} <small>({taxRate}% tax)</small>*/}
          {/*  </>*/}
          {/*) : null}*/}
        </Button>
      )}
    </div>
  );
};

SoftwarePlanItem.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  taxRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  list: PropTypes.array,
  item: PropTypes.any,
  onSessionId: PropTypes.func,
  selected: PropTypes.bool,
  isNew: PropTypes.bool,
};

export default SoftwarePlanItem;
