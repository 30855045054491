import React, { useContext, useEffect, useMemo, useState } from 'react';
import toaster from 'toasted-notes';
import { useForm } from 'react-hook-form';
import SectionHead from '../../Common/UIKit/SectionTitle';
import Button from '../../Common/UIKit/Form/Button';
import { ControlSelect } from '../../Common/UIKit/Form/Select';
import { getBusiness, partialUpdateBusiness } from '../../../api';
import useApi, { handleApiSave } from '../../../utilities/useApi';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { AppContext } from '../../../context/providers';

const PaymentPenaltyPage = () => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [level, setLevel] = useState('easy');
  const { control, handleSubmit } = useForm();
  const { setBusiness, setHeader } = useContext(AppContext);
  useEffect(() => {
    setHeader('Payment / Penalty');
  }, [setHeader]);
  const [data, loading] = useApi(getBusiness, {}, (res) => {
    setLevel(res?.refund_policy ? res.refund_policy : 'easy');
  });

  const item = useMemo(() => items.find((i) => i.key === level), [level]);

  const handleSave = ({ period }) => {
    const body = {
      refund_policy: level,
      cancellation_period: period,
    };

    handleApiSave(partialUpdateBusiness, null, body, null, { loadingFn: setSaveLoading })
      .then((data) => {
        setBusiness(data);
        toaster.notify(() => <div className="alert alert-success mt-3">Payment Penalty Changed Successfully</div>);
      })
      .catch(() => {});
  };

  return loading ? (
    <PageLoading />
  ) : (
    <>
      <div className="c-panel-content mt-3">
        <SectionHead title="Your No-Show / Late Cancellation Policy" />

        <div className="c-button-group c-policy-buttons">
          {items.map((b) => (
            <Button key={b.key} id={b.key} lg color={level !== b.key && 'light'} onClick={() => setLevel(b.key)}>
              {b.label}
            </Button>
          ))}
        </div>

        <ul className="c-policy-content">
          <li className="c-policy-content__item">
            <span className="c-policy-content__item-label">Cancellation Penalty</span>
            <span className="c-policy-content__item-value">{item.rules.cancellation}%</span>
          </li>
          <li className="c-policy-content__item">
            <span className="c-policy-content__item-label">No-Show Penalty</span>
            <span className="c-policy-content__item-value">{item.rules.no_show}%</span>
          </li>
        </ul>

        <ControlSelect
          control={control}
          label="Cancellation Period (hours)"
          name="period"
          menuPlacement="top"
          options={periodOptions}
          defaultValue={data?.cancellation_period ? data.cancellation_period.toString() : '24'}
        />

        <p className="c-policy-description">
          The cancellation period is the number of hours notice you require clients to give before cancelling or
          rescheduling appointments without penalty.
        </p>

        {/*<ControlCheckbox*/}
        {/*    control={control}*/}
        {/*    label="Enable online payment"*/}
        {/*    name="enable"*/}
        {/*    defaultValue={true}*/}
        {/*/>*/}

        <Button id="savePenalty" loading={saveLoading} onClick={handleSubmit(handleSave)}>
          Save Changes
        </Button>
      </div>
    </>
  );
};

const periodOptions = [
  { label: '1 hour', value: '1' },
  { label: '3 hours', value: '3' },
  { label: '6 hours', value: '6' },
  { label: '12 hours', value: '12' },
  { label: '24 hours', value: '24' },
];

const items = [
  {
    label: 'Easy',
    key: 'easy',
    rules: {
      cancellation: 0,
      no_show: 50,
    },
  },
  {
    label: 'Normal',
    key: 'normal',
    rules: {
      cancellation: 25,
      no_show: 50,
    },
  },
  {
    label: 'Tough',
    key: 'tough',
    rules: {
      cancellation: 50,
      no_show: 100,
    },
  },
];

export default PaymentPenaltyPage;
