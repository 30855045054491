import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../UIKit/Form/Input';
import { getPriceFormat } from '../../../../utilities/Price';

const RefundLineGiftCard = ({ line, index }) => (
  <div className="c-checkout-lines__item c-checkout-lines__item--refund">
    <div className="c-list__item">
      <div className="c-list__info px-0">
        <h3 className="c-list__title">{line?.name ? line?.name : line?.item_name}</h3>
        <div className="c-list__metas">
          <span className="c-list__meta">Gift Card</span>
        </div>
      </div>
      <div className="c-list__el-right">
        <span className="c-list__price">{getPriceFormat(+line?.price)}</span>
      </div>
    </div>
    <div className="c-list__item">
      <div className="px-2 w-25">
        <Input id={`quantity[${index}]`} disabled defaultValue={line?.quantity || 1} label="Quantity" />
      </div>
      <div className="px-2 w-25">
        <Input id={`price[${index}]`} unit="$" disabled defaultValue={line?.price} label="Price" />
      </div>
      <div className="px-2 w-25">
        <Input id={`staff[${index}]`} disabled defaultValue={line?.staff_name} label="Staff" />
      </div>
    </div>
  </div>
);

RefundLineGiftCard.propTypes = {
  index: PropTypes.number,
  line: PropTypes.any,
};

export default RefundLineGiftCard;
