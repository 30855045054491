import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import SoldGiftCardObject from '../objects/SoldGiftCardObject';

const { GET, POST, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getSoldGiftCardList = async ({ search, page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.soldGiftCard.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new SoldGiftCardObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getSoldGiftCard = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.soldGiftCard.get, { id }),
  });
  if (response.data) {
    return new SoldGiftCardObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id,
 * @param is_active
 */
export const partialUpdateSoldGiftCard = async (id, is_active) => {
  const response = await callApi({
    method: PATCH,
    params: { is_active },
    url: createURL(ApiRoutes.soldGiftCard.update, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteSoldGiftCard = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.soldGiftCard.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {
 *     code,
 * }
 */
export const checkValidSoldGiftCard = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.soldGiftCard.validate_code,
    // url: ApiRoutes.discounts.validate_code,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
