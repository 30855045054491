import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../../UIKit/Form/Button';
import SoftwarePlanItem from '../SoftwarePlanItem';
import { StripeButton } from '../../Stripe';
import { Types } from '../../../../config/actionTypes';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { getPlans, postPlanTrial, partialUpdateBusiness } from '../../../../api';
import { AppContext } from '../../../../context/providers';
import { PageLoading } from '../../UIKit/Spinner';

const SetupSoftwareSubscribe = ({
  sessionId,
  isNew = false,
  planId = null,
  reference = 'software',
  onPrev,
  onNext,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState(null);
  const [trialLoading, setTrialLoading] = useState(null);
  const [plan, setPlan] = useState(null);
  const { user, business, setSystem, setBusiness, onCheckAccess, newBusiness, onNewBusiness } = useContext(AppContext);
  // const [plans, loading] = useApi(getPlans);
  // const [plans, loading] = useApi(getPlans, newBusiness ? { businessId: newBusiness.id } : undefined);
  const history = useHistory();

  useEffect(() => {
    if (newBusiness || business) {
      handleApi(getPlans, { businessId: newBusiness?.id || business?.id }, { loadingFn: setLoading }).then(setPlans);
    }
  }, [newBusiness, business]);

  useEffect(() => {
    if (plans?.results) {
      let fetchPlan = null;
      if (planId) {
        fetchPlan = plans.results.find((i) => i.id === planId);
      } else {
        fetchPlan = plans.results.find((i) => (isNew ? i.id === newBusiness.plan : i.id === business.plan));
      }
      if (fetchPlan) {
        fetchPlan.theme = fetchPlan.id % 2 ? 'light' : 'dark';
        setPlan(fetchPlan);
      }
    }
  }, [plans, business, planId]);

  const handleSubmit = () => {
    if (isNew) {
      localStorage.setItem(Types.BUSINESS_LOCATION, newBusiness.id);
    }
    localStorage.setItem(Types.PAYMENT_BACK_PAGE, `setup.${reference}`);
  };

  const handleSubmitTrial = () => {
    setTrialLoading(true);
    const businessId = isNew ? newBusiness.id : undefined;
    handleApiSave(postPlanTrial, undefined, { businessId, plan: plan.id })
      .then(() => {
        handleApiSave(partialUpdateBusiness, null, { businessId, software_setup: true }, null)
          .then((data) => {
            if (onClose) {
              setTimeout(() => {
                if (isNew) {
                  onNewBusiness(data);
                  onNext();
                } else {
                  setBusiness(data);
                  setSystem(data?.software_setup ? 'software' : 'marketplace');
                  history.push('/');
                  onClose();
                }
                onCheckAccess();
              }, 50);
            } else {
              onNext();
            }
          })
          .finally(() => setTrialLoading(false));
      })
      .catch(() => setTrialLoading(false));
  };

  return loading ? (
    <PageLoading />
  ) : (
    <div>
      <div className="c-setup-system-plans">
        {/*<p>Choose the plan that’s right for you</p>*/}
        <div className="c-settings-plans c-setup-system-plans__container">
          {plan && <SoftwarePlanItem item={plan} theme={plan.theme} selected />}
        </div>
      </div>
      <div className="d-flex align-center mt-5">
        <Button type="button" color="light" onClick={onPrev} className="mr-auto">
          Previous Step
        </Button>
        {/*<Button*/}
        {/*    type="button"*/}
        {/*    loading={loading}*/}
        {/*    onClick={handleSubmit}*/}
        {/*    className="ml-auto"*/}
        {/*>Subscribe And Start Software</Button>*/}
        {!user?.trial_is_used && (
          <Button
            color="info"
            type="button"
            loading={trialLoading}
            disabled={trialLoading}
            onClick={handleSubmitTrial}
            className="ml-auto mr-3"
          >
            Start Trial
          </Button>
        )}
        {(isNew ? newBusiness?.subscribed : business?.subscribed) ? (
          <Button type="button" onClick={onNext}>
            Next Step
          </Button>
        ) : (
          <div onClick={handleSubmit}>
            {/*<div className="ml-auto" onClick={handleSubmit}>*/}
            <StripeButton type="button" sessionId={sessionId}>
              Subscribe Plan
            </StripeButton>
          </div>
        )}
      </div>
    </div>
  );
};

SetupSoftwareSubscribe.propTypes = {
  sessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNew: PropTypes.bool,
  planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reference: PropTypes.string,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default SetupSoftwareSubscribe;
