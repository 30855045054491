import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import toaster from 'toasted-notes';
import { Doughnut, Line } from 'react-chartjs-2';
import DashboardBox from '../../Common/Dashboard/DashboardBox';
import { getPriceFormat } from '../../../utilities/Price';
import useApi, { handleApi } from '../../../utilities/useApi';
import {
  getAnalyticsAppointments,
  getAnalyticsSales,
  getAppointmentList,
  getClientList,
  getRequestServiceList,
  sendVerificationIdentity,
} from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { getAptStatusName } from '../../Common/UIKit/Status';
import { AppContext } from '../../../context/providers';
import { MARKETPLACE_COLOR, SOFTWARE_COLOR } from '../../../assets/Colors';
import MobileVerifyModal from '../../Common/Dashboard/MobileVerifyModal';
import DashboardNotifications from '../../Common/Dashboard/DashboardNotifications';
import { AppointmentSaleChannelChoice } from '../../../utilities/constants/choices';
import usePermission from '../../../utilities/usePermission';

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          // max: 1,
          // maxTicksLimit: 5,
          beginAtZero: true,
          userCallback: function (label) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }
          },
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          // stepSize: 4,
          maxTicksLimit: 15,
        },
      },
    ],
  },
};

const DashboardPage = () => {
  // const [isOpenBalance, setIsOpenBalance] = useState(false);
  const [sales, salesLoading] = useApi(getAnalyticsSales, { key: 'business.sales_statistics' });
  const [allClients, allClientsLoading] = useApi(getClientList, { limit: 0, key: 'client' });
  const [allAppointments, allAppointmentsLoading] = useApi(getAppointmentList, { limit: 0, key: 'appointment' });
  const [appointmentAnalytics, appointmentAnalyticsLoading] = useApi(getAnalyticsAppointments, {
    key: 'business.appointments_statistics',
  });
  const { isSoftware, business, user, setHeader } = useContext(AppContext);
  const [requestServices, setRequestServices] = useState(null);
  const [verifyMobile, setVerifyMobile] = useState(false);
  const { access: _access, isOwner } = usePermission('business');

  useEffect(() => {
    // setHeader('Dashboard', { videoId: 'LvPGbZtiK50' });
    setHeader('Dashboard');
  }, [setHeader]);
  useEffect(() => {
    if (user && !user.is_claimed) {
      handleApi(getRequestServiceList, { page: 1, limit: 25 }).then(setRequestServices);
    }
  }, [user]);

  const history = useHistory();

  const decoratedRecentAppointments = useMemo(() => {
    const appointments = appointmentAnalytics?.appointments_count_per_day;
    if (!appointments) return { labels: [], datasets: [] };
    const { labels, data } = appointments.reduce(
      (acc, a) => {
        acc.labels.push(a.reserved_date);
        acc.data.push(a.count);
        return acc;
      },
      { labels: [], data: [] },
    );
    return {
      labels,
      datasets: [
        {
          label: 'Appointments',
          data,
          borderColor: isSoftware ? SOFTWARE_COLOR : MARKETPLACE_COLOR,
          borderWidth: 2,
          lineTension: 0,
          fill: false,
        },
      ],
    };
  }, [appointmentAnalytics]);

  const decoratePopularServices = (services) => {
    if (!services) return { labels: [], datasets: [] };
    const { labels, data } = services.reduce(
      (acc, s) => {
        acc.labels.push(s.service);
        acc.data.push(s.count);
        return acc;
      },
      { labels: [], data: [] },
    );
    return {
      labels,
      datasets: [
        {
          data,
          borderColor: 'rgba(0,0,0,0.5)',
          backgroundColor: ['#fc5622', '#43A047', '#1E88E5', '#e53935', '#FDD835', '#26C6DA'],
          borderWidth: 2,
          lineTension: 0,
          fill: true,
        },
      ],
    };
  };

  const handleVerifyIdentity = (identityType) => {
    handleApi(sendVerificationIdentity, { identity_type: identityType }, { list: false }).then(() => {
      toaster.notify(() =>
        identityType === 'email' ? (
          <alert className="alert alert-success">
            A link has been sent to your email. Check your inbox and Spam sections.
          </alert>
        ) : (
          <alert className="alert alert-success">A link has been sent to your mobile. check your inbox</alert>
        ),
      );
    });
  };

  const handleClickBalance = () => {
    // setIsOpenBalance(isOpenBalance ? null : e.currentTarget);
  };
  return (
    <>
      {verifyMobile && <MobileVerifyModal onClose={() => setVerifyMobile(false)} />}
      {/*{verifyEmail && <EmailVerifyModal onClose={() => setVerifyEmail(false)} />}*/}
      <DashboardNotifications
        onVerifyIdentity={(type) => {
          if (type === 'email') handleVerifyIdentity(type);
          else setVerifyMobile(true);
        }}
      />
      <div className="c-dashboard__box-wrapper">
        {!isSoftware && (
          <DashboardBox
            className="cursor-pointer"
            onClick={() => history.push('/settings/payment/balance-history/')}
            title={
              <div className="position-relative" aria-describedby="balance" onClick={handleClickBalance}>
                Balance
              </div>
            }
            description={business ? `$${business.transfers_balance || 0}` : '-'}
            icon="fas fa-wallet"
            grid={4}
          />
        )}
        <DashboardBox
          className="cursor-pointer"
          title="Total Sales"
          onClick={() => history.push('/sales/transactions/')}
          description={salesLoading ? '-' : getPriceFormat(+sales?.total_sale || 0)}
          icon="fa fa-usd"
          grid={isSoftware ? 3 : 4}
        />
        <DashboardBox
          className="cursor-pointer"
          title="Total Clients"
          onClick={() => history.push('/clients/')}
          description={allClientsLoading ? '-' : +allClients?.count || 0}
          icon="fas fa-users"
          grid={isSoftware ? 3 : 4}
        />
        <DashboardBox
          className="cursor-pointer"
          title="Appointments"
          onClick={() => history.push('/sales/appointments')}
          description={allAppointmentsLoading ? '-' : allAppointments?.count || 0}
          icon="far fa-calendar-check"
          grid={isSoftware ? 3 : 4}
        />
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 pb-5">
          <div className="c-dashboard__content my-3">
            <div className="c-dashboard__header">
              <div className="c-dashboard__head">
                <h1 className="c-dashboard__title">Appointments in the last 30 days</h1>
              </div>
            </div>
            {_access?.appointments_statistics?.view || isOwner ? (
              <div className="c-dashboard__body">
                {appointmentAnalyticsLoading ? (
                  <PageLoading />
                ) : (
                  <div className="c-dashboard__chart">
                    <Line data={decoratedRecentAppointments} width={100} height={300} options={chartOptions} />
                  </div>
                )}
              </div>
            ) : (
              <div className="c-dashboard__body">
                <span className="c-dashboard__body-empty">You do not have permission to view this section</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 my-3">
          <div className="c-dashboard__content">
            <div className="c-dashboard__header">
              <div className="c-dashboard__head">
                <h1 className="c-dashboard__title">Popular services</h1>
              </div>
            </div>
            {_access?.appointments_statistics?.view || isOwner ? (
              <>
                {appointmentAnalyticsLoading ? (
                  <PageLoading />
                ) : (
                  <div className="c-dashboard__body c-dashboard__body--services">
                    {appointmentAnalytics?.popular_services?.length > 0 ? (
                      <div className="c-dashboard__chart">
                        <Doughnut
                          data={decoratePopularServices(appointmentAnalytics?.popular_services)}
                          width={100}
                          height={100}
                        />
                      </div>
                    ) : (
                      <span className="c-dashboard__body-empty">There is not any Services</span>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="c-dashboard__body">
                <span className="c-dashboard__body-empty">You do not have permission to view this section</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-8 my-3">
          <div className="c-dashboard__content">
            <div className="c-dashboard__header">
              <div className="c-dashboard__head">
                <h1 className="c-dashboard__title">
                  {user && !user.is_claimed ? 'Service Requests' : 'Recent Appointments'}
                </h1>
              </div>
              {/*<NavLink to="/requests/services/">See More</NavLink>*/}
              <NavLink to="/sales/appointments">See More</NavLink>
            </div>
            {user && !user.is_claimed ? (
              <>
                <div className="c-dashboard__body px-3">
                  {(requestServices?.results || [])?.length > 0 ? (
                    <div className="c-table c-table--sm">
                      <table>
                        <thead>
                          <tr>
                            <th>Client Name</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Phone Number</th>
                            <th className="text-center">Service Name</th>
                            <th className="text-center">Requested At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(requestServices?.results || []).map((item) => (
                            <tr key={item.id}>
                              <td>{item.full_name}</td>
                              <td className="text-center">{item.email}</td>
                              <td className="text-center">{item.phone_number}</td>
                              <td className="text-center">{item.service_name}</td>
                              <td className="text-center">{item.created_at}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <span className="c-dashboard__body-empty">There is not any service request</span>
                  )}
                </div>
              </>
            ) : (
              <>
                {_access?.appointments_statistics?.view || isOwner ? (
                  <>
                    {appointmentAnalyticsLoading ? (
                      <PageLoading />
                    ) : (
                      <div className="c-dashboard__body px-3">
                        {appointmentAnalytics?.recent_appointments?.length > 0 ? (
                          <div className="c-table c-table--sm">
                            <table>
                              <thead>
                                <tr>
                                  <th>Client Name</th>
                                  <th className="text-center">Service</th>
                                  <th className="text-center">Date</th>
                                  <th className="text-center">Time</th>
                                  <th className="text-center">Staff</th>
                                  <th className="text-center">Status</th>
                                  <th className="text-center">Sales Channel</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {(appointmentAnalytics?.recent_appointments || []).map((appointment, _) => (
                                  <tr key={_}>
                                    <td>{appointment?.client_name}</td>
                                    <td className="text-center">{appointment?.service_name}</td>
                                    <td className="text-center">{appointment?.reserved_date}</td>
                                    <td className="text-center">{appointment?.reserved_time}</td>
                                    <td className="text-center">
                                      <small>{appointment?.staff_name}</small>
                                    </td>
                                    <td className="text-center">
                                      <small>{getAptStatusName(appointment?.status)}</small>
                                    </td>
                                    <td className="text-center">
                                      <small>{AppointmentSaleChannelChoice[appointment?.sale_channel]}</small>
                                    </td>
                                    <td className="text-center">
                                      <NavLink
                                        to={`/calendar/appointment/${
                                          appointment?.appointment_id || appointment?.id
                                        }/edit`}
                                      >
                                        Manage
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <span className="c-dashboard__body-empty">There is not any Appointments</span>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="c-dashboard__body">
                    <span className="c-dashboard__body-empty">You do not have permission to view this section</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
