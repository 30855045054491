import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../context/providers';
import { getQueryUri } from '../../../utilities/URI';
import asyncLocalStorage from '../../../utilities/AsyncLocalStorage';
import { Types } from '../../../config/actionTypes';
import { PageLoading } from '../../Common/UIKit/Spinner';

const AdminLoginPage = ({ history }) => {
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      const { access, refresh } = getQueryUri();
      if (access && refresh) {
        setUser(null);
        await asyncLocalStorage.removeItem(Types.BUSINESS_LOCATION);
        await asyncLocalStorage.removeItem(Types.LATEST_APPOINTMENT);
        await asyncLocalStorage.removeItem(Types.SYSTEM_TYPE);
        await asyncLocalStorage.removeItem('_country');
        await asyncLocalStorage.setItem(Types.USER_TOKEN, access);
        await asyncLocalStorage.setItem(Types.USER_REFRESH, refresh);
        window.location.href = '/';
      } else {
        history.replace('/');
      }
    })();
  }, []);

  return <PageLoading />;
};

AdminLoginPage.propTypes = {
  history: PropTypes.any,
};

export default AdminLoginPage;
