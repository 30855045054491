import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../UIKit/Modal';
import { ControlInput } from '../../UIKit/Form/Input';
import { ControlSelect } from '../../UIKit/Form/Select';
import { Controller, useForm } from 'react-hook-form';
import { PageLoading } from '../../UIKit/Spinner';
import PromotionGiftCard from '../PromotionGiftCard';
import ServiceMultiSelect, { InputServiceMultiSelect } from '../../Selections/ServiceMultiSelect';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import { createGiftCard, deleteGiftCard, getGiftCard, updateGiftCard } from '../../../../api';
import { DealExpireAfterChoice, getValueLabel } from '../../../../utilities/constants/choices';
import Button from '../../UIKit/Form/Button';
// import CampaignInput from "../../Campaign/CampaignInput";
import toaster from 'toasted-notes';
import { handleChangeNumber } from '../../../../utilities/HandleErrors';

// eslint-disable-next-line react/prop-types
const PromotionGiftCardFormModal = ({ onCheckout, onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [services, setServices] = useState([]);

  const { control, errors, handleSubmit, setError, getValues, watch, clearErrors } = useForm();

  const { id } = useParams();

  const [data, loading] = useApiById(getGiftCard, null, (res) => {
    if (res) setServices(res.services);
  });

  const handleClose = () => {
    if (showServices) {
      setShowServices(false);
    } else {
      onClose();
    }
  };
  const onSaveForm = (data) => {
    if (data.services.length > 0) {
      handleApiSave(createGiftCard, updateGiftCard, data, id, { setError, loadingFn: setSaveLoading })
        .then(onSave)
        .catch(() => {});
    } else {
      toaster.notify(() => (
        <div className="alert alert-danger">Please add at least one service. services field cannot be empty</div>
      ));
    }
  };

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete this gift card',
      message: 'Are you sure to delete this gift card.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteGiftCard, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  return (
    <Modal
      show
      auto
      disableFooter
      onClose={handleClose}
      bodyClass="p-0"
      // onSave={handleSubmit(onSaveForm)}
      // saveLoading={saveLoading}
      // buttonComponent={!!id && (
      //     <>
      //         <Button color="danger" loading={delLoading} onClick={handleDelete}>Delete</Button>
      //         {(onCheckout && data?.is_saleable) && <Button color="dark" onClick={() => onCheckout(data)}>Sell Gift Card</Button>}
      //     </>
      // )}
      title={!!id ? 'Edit Gift Card' : 'Create Gift Card'}
    >
      {(loading && !!id) || (!!id && !data) ? (
        <PageLoading />
      ) : (
        <div className="c-promotion__form-card-container">
          <div className="c-promotion__form-card-sidebar">
            <form onSubmit={handleSubmit(onSaveForm)}>
              <div className={`c-promotion__form-card-services ${!showServices ? ' d-none' : ''}`}>
                <Controller
                  control={control}
                  name="services"
                  // rules={{ required: 'Services is required' }}
                  defaultValue={data?.services ? data.services : []}
                  render={({ onChange, value }) => (
                    <ServiceMultiSelect
                      value={(value || []).map((i) => i?.id || i)}
                      show={showServices}
                      isEdit={!!id}
                      onChange={(idList) => {
                        setShowServices(false);
                        onChange(idList);
                      }}
                      onData={(data, listId) => {
                        setServices(data);
                        onChange(listId);
                      }}
                      onCancel={() => setShowServices(false)}
                    />
                  )}
                />
              </div>
              <div className={`c-promotion__form-card-content ${showServices ? ' d-none' : ''}`}>
                <ControlInput
                  control={control}
                  name="name"
                  rules={{ required: 'Subject is required' }}
                  defaultValue={data?.name ? data.name : ''}
                  label="Subject"
                  placeholder={'Subject'}
                  errorMessage={errors?.name?.message}
                />
                <InputServiceMultiSelect
                  value={watch()?.services}
                  onClick={() => setShowServices(true)}
                  errorMessage={errors?.services?.message}
                  error={!!errors.services}
                  services={services}
                />
                <ControlInput
                  control={control}
                  name="value"
                  rules={{ required: 'Value amount is required' }}
                  defaultValue={data?.value ? data.value : ''}
                  onChangeText={(text) => handleChangeNumber('value', text, 'Value', setError, clearErrors)}
                  type="tel"
                  unit="$"
                  label={'Value amount'}
                  placeholder={'e.g. 80'}
                  errorMessage={errors?.value?.message}
                />
                <ControlInput
                  control={control}
                  name="retail_price"
                  rules={{ required: 'Original retail price is required' }}
                  defaultValue={data?.retail_price ? data.retail_price : ''}
                  onChangeText={(text) =>
                    handleChangeNumber('retail_price', text, 'Retail Price', setError, clearErrors)
                  }
                  type="tel"
                  unit="$"
                  label={'Retail price'}
                  placeholder={'e.g. 80'}
                  errorMessage={errors?.retail_price?.message}
                />
                <ControlInput
                  control={control}
                  name="usage_limit"
                  rules={{ required: 'Number of sales is required' }}
                  defaultValue={data?.usage_limit ? data.usage_limit : ''}
                  onChangeText={(text) => handleChangeNumber('usage_limit', text, 'Usage Limit', setError, clearErrors)}
                  type="tel"
                  min={0}
                  max={100}
                  label={'Number of sales'}
                  placeholder={'e.g. 5'}
                  errorMessage={errors?.usage_limit?.message}
                />
                <ControlSelect
                  control={control}
                  name="expire_after"
                  rules={{ required: 'Expire after is required' }}
                  defaultValue={data?.expire_after ? String(data.expire_after) : null}
                  options={getValueLabel(DealExpireAfterChoice)}
                  label={'Expire after'}
                  // maxMenuHeight={200}
                  menuPlacement="top"
                  errorMessage={errors?.expire_after?.message}
                />
                {/*<CampaignInput data={data} errors={errors} control={control} />*/}
              </div>

              <div className={`c-promotion__form-card-footer${showServices ? ' d-none' : ''}`}>
                <div className="d-flex align-items-center">
                  <Button className="mr-2" id="saveBtn" loading={saveLoading}>
                    Save
                  </Button>
                  <Button color="danger" id="deleteBtn" type="button" loading={delLoading} onClick={handleDelete}>
                    Delete
                  </Button>
                </div>
                {onCheckout && data?.is_saleable && (
                  <Button color="dark" id="sellBtn" type="button" onClick={() => onCheckout(data)}>
                    Sell Gift Card
                  </Button>
                )}
              </div>
            </form>
          </div>

          <div className="c-promotion__card-wrapper">
            <div className="c-promotion__card-container">
              <PromotionGiftCard
                subject={watch()?.name ? watch().name : data?.name}
                value={watch()?.value ? watch().value : data?.value}
                price={watch()?.retail_price ? watch().retail_price : data?.retail_price}
                limit={watch()?.usage_limit ? watch().usage_limit : data?.usage_limit}
                expireAfter={getValues('expire_after')}
                campaignAudience={getValues('campaign.audience')}
                emailTemplate={false}
                emailSubject={getValues('campaign.email_subject')}
                emailDescription={getValues('campaign.email_description')}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PromotionGiftCardFormModal;
