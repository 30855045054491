import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { DateRange } from '@material-ui/icons';
import ThirdTimeRequestModel from '../../Common/ThirdTimeRequests/ThirdTimeRequestsModal';
import { getAppointmentList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import useApi, { handleApi } from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import classNames from 'classnames';
import { getFormatTime } from '../../../utilities/DateHelpers';
import Filters from '../../Common/UIKit/HeadOptions/Filters';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import { AppointmentStatusChoice } from '../../../utilities/constants/choices';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/requests/three-time-requests/';

const ThirdTimeRequestsPage = ({ match: { params }, location, history }) => {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [, loading] = useApi(
    getAppointmentList,
    { booking_mode: '3_time', status: true, key: 'appointmentsuggestedtime' },
    setData,
  );

  const { setHeader } = useContext(AppContext);

  useEffect(() => {
    setHeader('Three Timeslot Booking Requests');
  }, [setHeader]);

  useEffect(() => {
    if (params.id) handleOpenModal();
  }, [params, location]);

  const getTotalDuration = (lines) => {
    const time = (lines || []).reduce((acc, i) => {
      const duration = i.duration.split(':');
      const hr = duration[0];
      const min = duration[1];
      return acc + +hr * 60 + +min;
    }, 0);
    return time > 0 ? `${Math.floor(time / 60)}:${time % 60}` : 0;
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (opened = true) => {
    setShowModal(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveForm = () => {
    handleCloseModal();
    handleApi(getAppointmentList, { booking_mode: '3_time' }).then(setData);
  };

  const getStaffListName = (lines) =>
    lines.reduce((acc, i) => (acc.indexOf(i.staff_name) > -1 ? acc : [...acc, i.staff_name]), []);

  const { access: _access } = usePermission('appointmentsuggestedtime', 'crud');

  return (
    <>
      {showModal && _access?.view && <ThirdTimeRequestModel onClose={handleCloseModal} onSave={handleSaveForm} />}
      {_access?.view && <HeadOptions rightElement={<Filters status />} />}
      {_access?.view ? (
        <>
          {loading && !data ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div className="c-panel-content my-3">
                {loading && <PageLoading over />}
                <div className="c-table c-table--clickable">
                  <table>
                    <thead>
                      <tr>
                        <th>Client</th>
                        <th className="text-center">Providers</th>
                        <th className="text-center">Duration</th>
                        <th className="text-center">Requested At</th>
                        <th className="text-center">Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <tr key={item.id} onClick={() => history.push(`${URL_PAGE}${item.id}`)}>
                          <td>{item.client_name}</td>
                          <td className="text-center">
                            <small className="font-weight-bold">{getStaffListName(item.lines).toString()}</small>
                          </td>
                          <td className="text-center">{getFormatTime(getTotalDuration(item.lines))}</td>
                          <td className="text-center">{item.created_at}</td>
                          <td
                            className={classNames('text-center font-weight-bold', {
                              'text-success': item.status === 'confirmed',
                              'text-warning': item.status === 'pending',
                              'text-danger': (item?.status || '').includes('cancel'),
                            })}
                          >
                            {AppointmentStatusChoice[item.status]}
                          </td>
                          <td>
                            <NavLink to={`${URL_PAGE}${item.id}`}>Manage</NavLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Suggestion Time Exists"
              description="You don't have any suggestion time request"
              icon={<DateRange />}
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

ThirdTimeRequestsPage.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.any,
};

export default ThirdTimeRequestsPage;
