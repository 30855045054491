/**
 * @param tier {{
 *    id: Number,
 *    service_from: Number,
 *    service_to: Number,
 *    amount: Number,
 *    amount_type: String,
 * }}
 */
function ServiceCommissionTiersObject(tier) {
  this.id = tier.id;
  this.service_from = tier.service_from;
  this.service_to = tier.service_to;
  this.amount = tier.amount;
  this.amount_type = tier.amount_type;
}

/**
 * @param commission {{
 *    id: Number,
 *    staff: Number,
 *    tiers: [{
 *      id: Number,
 *      service_from: Number,
 *      service_to: Number,
 *      amount: Number,
 *      amount_type: String,
 *    }],
 *    is_active: Boolean,
 *    cycle,
 * }}
 */
function ServiceCommissionObject(commission) {
  this.id = commission.id;
  this.staff = commission.staff;
  this.tiers = commission.tiers.map((tier) => new ServiceCommissionTiersObject(tier));
  this.is_active = commission.is_active;
  this.cycle = commission.cycle;
}

/**
 * @param commission {{
 *    id: Number,
 *    amount: Number,
 *    appointment_id: Number,
 *    appointment_line: Number,
 *    service_commission: Number,
 *    staff_name: String,
 *    service_name: String,
 *    tier_amount: Number,
 *    created_at: Boolean,
 * }}
 */
export function CommissionObject(commission) {
  this.id = commission.id;
  this.amount = commission.amount;
  this.appointmentId = commission.appointment;
  this.appointmentLine = commission.appointment_line;
  this.serviceCommission = commission.service_commission;
  this.staffName = commission.staff_name;
  this.serviceName = commission.service_name;
  this.tierAmount = commission.tier_amount;
  this.createdAt = commission.created_at;
}

export default ServiceCommissionObject;
