import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ControlSelect } from '../UIKit/Form/Select';
import { DealCampaignAudienceChoice, getValueLabel } from '../../../utilities/constants/choices';
import { ControlInput } from '../UIKit/Form/Input';
import { AppContext } from '../../../context/providers';
import { ControlCheckbox } from '../UIKit/Form/Checkbox';

const CampaignInput = ({ control, data, errors, hasMarketplace = false }) => {
  const { isMarketplace } = useContext(AppContext);
  return isMarketplace && !hasMarketplace ? null : (
    <>
      <hr />

      <ControlSelect
        control={control}
        name="campaign.audience"
        rules={{ required: 'Campaign Type is required' }}
        defaultValue={data?.campaign && !!data.campaign.audience ? data.campaign.audience : 'all_clients'}
        options={getValueLabel(DealCampaignAudienceChoice)}
        label="Send Email to Clients"
        menuPlacement="top"
        errorMessage={errors?.campaign?.audience?.message}
      />

      <ControlInput
        control={control}
        name="campaign.email_subject"
        rules={{ required: 'Email Subject is required' }}
        // defaultValue={data?.campaign && !!data.campaign.email_subject ? data.email_subject : 'Promotion Subject Line'}
        defaultValue={data?.campaign && !!data.campaign.email_subject ? data.email_subject : ''}
        label={'Email Subject'}
        placeholder={'Enter Email Subject ...'}
        errorMessage={errors?.campaign?.email_subject?.message}
      />
      <ControlInput
        control={control}
        name="campaign.email_description"
        rules={{ required: 'Email Description is required' }}
        // defaultValue={
        //   data?.campaign && !!data.campaign.email_description ? data.email_description : 'Promotion Description Line'
        // }
        defaultValue={data?.campaign && !!data.campaign.email_description ? data.email_description : ''}
        type="textarea"
        label={'Email Description'}
        placeholder={'Enter Email Description...'}
        errorMessage={errors?.campaign?.email_description?.message}
      />
      <ControlCheckbox
        control={control}
        name="send_sms"
        defaultValue={data?.send_sms || false}
        label="Send By Sms"
        errorMessage={errors?.send_sms?.message}
      />
    </>
  );
};

CampaignInput.propTypes = {
  hasMarketplace: PropTypes.bool,
  control: PropTypes.any,
  data: PropTypes.any,
  errors: PropTypes.any,
};

export default CampaignInput;
