import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Switch from '../../UIKit/Form/Switch';
import { AppContext } from '../../../../context/providers';

const SwitchSidebar = ({ className }) => {
  const { isMarketplace, isSoftware, onSwitchSystem, business } = useContext(AppContext);
  const history = useHistory();
  const handleChange = () => {
    history.replace('/');
    onSwitchSystem();
  };

  if (!business?.software_setup || !business?.marketplace_setup) return null;

  return (
    <div className={classnames('c-panel-sidebar__switch', className)}>
      <span
        className={classnames('c-panel-sidebar__switch-label', {
          'c-panel-sidebar__switch-label--software': isSoftware,
        })}
      >
        Software
      </span>
      <Switch checked={isMarketplace} onChange={handleChange} id="switch-panel" />
      <span
        className={classnames('c-panel-sidebar__switch-label', {
          'c-panel-sidebar__switch-label--marketplace': isMarketplace,
        })}
      >
        Marketplace
      </span>
    </div>
  );
};

SwitchSidebar.propTypes = {
  className: PropTypes.any,
};

export default SwitchSidebar;
