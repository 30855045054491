import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../UIKit/Modal';
import { ControlInput } from '../../UIKit/Form/Input';
import { Controller, useForm } from 'react-hook-form';
import { PageLoading } from '../../UIKit/Spinner';
import ImageSelector from '../../UIKit/ImageSelector';
import PromotionCard from '../PromotionCard';
import ServiceMultiSelect, { InputServiceMultiSelect } from '../../Selections/ServiceMultiSelect';
import { createDeal, getDeal, scheduleCampaign, updateDeal } from '../../../../api';
import ExpireSelect from '../../Selections/ExpireSelect';
import Button from '../../UIKit/Form/Button';
import { handleApiSave, useApiById } from '../../../../utilities/useApi';
import { handleChangeDiscount } from '../../../../utilities/HandleErrors';
import CampaignInput from '../../Campaign/CampaignInput';
import Switch from '../../UIKit/Form/Switch';
import toaster from 'toasted-notes';
import { useIsDesktop } from '../../../../utilities/useIsDesktop';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const PromotionDealFormModal = ({ onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  // const [delLoading, setDelLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [services, setServices] = useState([]);
  const [usageLimitation, setUsageLimitation] = useState(true);
  const { system } = useContext(AppContext);
  const isDesktop = useIsDesktop();
  const { id } = useParams();

  const [data, loading] = useApiById(getDeal, id);

  const { control, errors, handleSubmit, setError, clearErrors, getValues, watch } = useForm();

  useEffect(() => {
    if (data) setUsageLimitation(!!data?.usage_limit);
  }, [data]);
  const handleClose = () => {
    if (showServices) {
      setShowServices(false);
    } else {
      onClose();
    }
  };

  const onSaveForm = (data) => {
    if (data.services.length > 0) {
      setSaveLoading(true);

      if (!data.image || typeof data.image === 'string') delete data.image;
      console.log(data);
      const body = {
        ...data,
        usage_limit: usageLimitation ? data.usage_limit : undefined,
        expire_after: data.expire_after !== '0' ? data.expire_after : undefined,
        create_channel: (system || 'marketplace').toLowerCase(),
      };
      handleApiSave(createDeal, updateDeal, body, id, { setError, loadingFn: setSaveLoading })
        .then(onSave)
        .catch(() => {});
    } else {
      toaster.notify(() => (
        <div className="alert alert-danger">Please add at least one service. services field cannot be empty</div>
      ));
    }
  };

  // const handleDelete = () => {
  //   const options = {
  //     title: 'Confirm to delete this deal',
  //     message: 'Are you sure to delete this deal.',
  //     loadingFn: setDelLoading,
  //   };
  //   handleApiDeleteById(deleteDeal, id, options).then((isSuccess) => {
  //     if (isSuccess) onSave(+id);
  //   });
  // };

  const handleSendCampaign = () => {
    setCampaignLoading(true);
    scheduleCampaign(data.id)
      .then(() => {
        // console.log(_data);
      })
      .finally(() => {
        setCampaignLoading(false);
      });
  };

  const handleUsageLimitation = (value) => {
    clearErrors('usage_limit');
    setUsageLimitation(value);
  };

  const imageUrl = watch().image && watch().image.url ? watch().image.url : data?.image ? data.image : undefined;

  return (
    <Modal
      show
      auto
      // sticky
      disableFooter
      onClose={handleClose}
      bodyClass="p-0"
      // onSave={handleSubmit(onSaveForm)}
      // saveLoading={saveLoading}
      // buttonComponent={!!id && (
      //     <>
      //         {(data?.campaign && data?.campaign?.status !== "scheduled") && (
      //             <Button
      //                 color="success"
      //                 loading={campaignLoading}
      //                 onClick={handleSendCampaign}>
      //                 Send Campaign
      //             </Button>
      //         )}
      //         <Button color="danger" loading={delLoading} onClick={handleDelete}>Delete</Button>
      //     </>
      // )}
      title={!!id ? 'Edit Deal' : 'Add New Deal'}
    >
      {loading && id ? (
        <PageLoading />
      ) : (
        <div className="c-promotion__form-card-container">
          <div className="c-promotion__form-card-sidebar">
            <form onSubmit={handleSubmit(onSaveForm)}>
              <div className={`c-promotion__form-card-services ${!showServices ? ' d-none' : ''}`}>
                <Controller
                  control={control}
                  name="services"
                  // rules={{ required: 'Services is required' }}
                  defaultValue={data?.services ? data.services : []}
                  render={({ onChange, value }) => (
                    <ServiceMultiSelect
                      value={value}
                      show={showServices}
                      onChange={(idList) => {
                        setShowServices(false);
                        onChange(idList);
                      }}
                      onData={(data, listId) => {
                        setServices(data);
                        onChange(listId);
                      }}
                      onCancel={() => setShowServices(false)}
                    />
                  )}
                />
              </div>
              <div className={`c-promotion__form-card-content ${showServices ? ' d-none' : ''}`}>
                <Controller
                  control={control}
                  name="image"
                  // rules={{ required: 'Subject is required' }}
                  defaultValue={data?.image ? data?.image : null}
                  render={({ onChange, onBlur, value }) => (
                    <ImageSelector id="dealImage" onChange={onChange} onBlur={onBlur} value={value} />
                  )}
                />

                <h3 className="my-4">Content</h3>

                <ControlInput
                  control={control}
                  name="subject"
                  rules={{ required: 'Subject is required' }}
                  defaultValue={data?.subject ? data.subject : ''}
                  label={'Subject'}
                  placeholder={'Subject'}
                  errorMessage={errors?.subject?.message}
                />
                <ControlInput
                  type="textarea"
                  control={control}
                  name="description"
                  rules={{ required: 'Description is required' }}
                  defaultValue={data?.description ? data.description : ''}
                  label={'Description'}
                  placeholder={'Enter description...'}
                  errorMessage={errors?.description?.message}
                />

                <InputServiceMultiSelect
                  value={watch()?.services}
                  onClick={() => setShowServices(true)}
                  errorMessage={errors?.services?.message}
                  error={!!errors.services}
                  services={services}
                />
                <ControlInput
                  control={control}
                  name="discount"
                  rules={{
                    min: {
                      value: 0,
                      message: 'Discount cannot be less than 0',
                    },
                    max: {
                      value: 100,
                      message: 'Discount cannot be more than 100',
                    },
                    required: 'Discount is required',
                  }}
                  defaultValue={data?.discount ? data.discount : ''}
                  type="tel"
                  unit="%"
                  min={0}
                  max={100}
                  label={'Discount Amount'}
                  placeholder={'e.g. 30 (30% off)'}
                  errorMessage={errors?.discount?.message}
                  onChangeText={(v) => handleChangeDiscount(v, setError, clearErrors)}
                />
                <Switch
                  formGroup
                  defaultChecked={usageLimitation}
                  label="Limit amount of usage"
                  onChange={handleUsageLimitation}
                />
                <ControlInput
                  control={control}
                  name="usage_limit"
                  rules={usageLimitation ? { required: 'Maximum Promotion Bookings is required' } : undefined}
                  defaultValue={data?.usage_limit ? data.usage_limit : ''}
                  disabled={!usageLimitation}
                  type="tel"
                  min={0}
                  label="Set Maximum"
                  placeholder="Set Maximum"
                  errorMessage={errors?.usage_limit?.message}
                />
                <Controller
                  control={control}
                  name="expire_after"
                  // rules={{ required: 'Valid for is required' }}
                  defaultValue={data ? data.expire_after : '0'}
                  render={({ onChange, onBlur, value }, { invalid }) => (
                    <ExpireSelect
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      errorMessage={errors?.expire_after?.message}
                      error={invalid}
                    />
                  )}
                />
                <CampaignInput data={data} errors={errors} control={control} />
              </div>

              <div className={`c-promotion__form-card-footer${showServices ? ' d-none' : ''}`}>
                <Button loading={saveLoading}>Save</Button>
                {data?.campaign && data?.campaign?.status !== 'scheduled' && (
                  <Button type="button" color="success" loading={campaignLoading} onClick={handleSendCampaign}>
                    Send Campaign
                  </Button>
                )}
              </div>
            </form>
          </div>
          {isDesktop && (
            <div className="c-promotion__card-wrapper">
              <div className="c-promotion__card-container">
                <PromotionCard
                  imageUrl={imageUrl}
                  subject={getValues('subject') || 'Promotion Description'}
                  description={getValues('description') || 'Promotion Description'}
                  discount={getValues('discount')}
                  campaignAudience={getValues('campaign.audience')}
                  emailSubject={getValues('campaign.email_subject')}
                  emailDescription={getValues('campaign.email_description')}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default PromotionDealFormModal;
