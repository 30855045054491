import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from '../../Common/UIKit/Form/Checkbox';
import { AppContext } from '../../../context/providers';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getAccessLvlItemsList, partialUpdateAccessLvl } from '../../../api';
import { getAccessLvlList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import Button from '../../Common/UIKit/Form/Button';
import AccessLevelModal from '../../Common/AccessLevel/AccessLevelModal';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';

const URL_PAGE = '/settings/permissions/';
const UserPermissions = () => {
  const [accessModal, setAccessModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [levels, setLevels] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const { setHeader, setPermissions, permissions } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setHeader('Settings / Access Level');
  }, [setHeader]);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('/level/')) {
      setAccessModal(true);
    }
  }, [location]);

  const getAccessLevelData = () => {
    handleApi(getAccessLvlList, {}, { infinitePage: true })
      .then((res) => {
        const results = (res?.results || []).sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
        setLevels(results);
        setSelected((res?.results || []).reduce((acc, i) => ({ ...acc, [i.id]: i.permissions }), {}));
        if (results.length === 0 || !!permissions) setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleCloseModal = () => {
    setAccessModal(false);
    history.replace(URL_PAGE);
  };

  useEffect(() => {
    getAccessLevelData();
  }, []);

  useEffect(() => {
    if (levels && levels.length > 0 && !permissions) {
      handleApi(getAccessLvlItemsList, {}, { infinitePage: true })
        .then((res) => {
          if (res?.results) {
            setPermissions(
              (res.results || []).reduce((acc, i) => ({ ...acc, [i.group]: [...(acc[i.group] || []), i] || [] }), {}),
            );
          }
        })
        .finally(() => setLoading(false));
    }
  }, [levels, permissions]);

  const handleChange = (checked, lvl, id, type) => {
    let updatedSelected = { ...selected };
    const itemInd = (updatedSelected[lvl] || []).findIndex((i) => i.access_level_item === id);
    const view_permission =
      type === 'modify_permission' && checked ? true : updatedSelected[lvl][itemInd]?.view_permission || undefined;
    if (itemInd === -1) {
      updatedSelected[lvl] = [
        ...(selected[lvl] || []),
        { access_level_item: id, view_permission, [type]: checked, change: true },
      ];
    } else {
      updatedSelected[lvl][itemInd] = {
        ...updatedSelected[lvl][itemInd],
        view_permission,
        [type]: checked,
      };
    }
    setSelected(updatedSelected);
  };

  const handleSaveItem = (item) => {
    if (typeof item === 'number') {
      const updateData = [...levels];
      const ind = levels.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setLevels(updateData);
    }
    handleCloseModal();
    getAccessLevelData();
  };

  const handleSavePermissions = () => {
    setSaveLoading(true);
    const apiList = Object.keys(selected).map((id) =>
      handleApiSave(undefined, partialUpdateAccessLvl, { permissions: selected[id] }, id),
    );
    Promise.all(apiList).finally(() => setSaveLoading(false));
  };

  const getValues = (lvl, id, type) => {
    const item = (selected[lvl] || []).find((i) => i.access_level_item === id);
    return item?.[type];
  };

  return loading ? (
    <PageLoading />
  ) : (
    <>
      {accessModal && <AccessLevelModal onClose={handleCloseModal} onSave={handleSaveItem} />}
      {levels.length === 0 ? (
        <NotFoundData
          title="Access Level Does Not Exist"
          description="There is not any access level. Please add access level first"
          button={<Button link="/settings/permissions/level/add">Add Access Level</Button>}
        />
      ) : (
        <>
          <HeadOptions rightElement={<Button link={`${URL_PAGE}level/add`}>New Access Level</Button>} />
          {saveLoading && <PageLoading over fixed />}
          <div className="c-panel-content">
            <Button sm className="ml-auto" onClick={handleSavePermissions}>
              Save Changes
            </Button>
            <p>
              Setup which sections are accessible to each user permission level. All logged in staff can access the
              calendar, and owner accounts have full system access.
            </p>
            <div className="c-permissions">
              {Object.keys(permissions).map((key) => (
                <div key={key} className="c-permissions__container">
                  <div className="c-permissions__head-wrapper">
                    <ul className="c-permissions__head">
                      <li>{key.toUpperCase()}</li>
                      {levels.map((lvl) => (
                        <li key={lvl.id}>
                          <span className="c-permissions__head-title">{lvl.name}</span>
                          <span className="c-permissions__head-labels">
                            <span className="c-permissions__head-label">View</span>
                            <span className="c-permissions__head-label">Modify</span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="c-permissions__body-wrapper">
                    {(permissions[key] || []).map(({ id, name, type }) => (
                      <ul className="c-permissions__body" key={id}>
                        <li>{name.toUpperCase()}</li>
                        {levels.map((lvl) => (
                          <li
                            className="c-permissions__input-box"
                            key={lvl.id}
                            // onClick={() => handleChange(lvl.id, id)}
                          >
                            <Checkbox
                              label="V"
                              disabled={type === 'modify'}
                              checked={type !== 'modify' ? getValues(lvl.id, id, 'view_permission') : false}
                              onChange={
                                type !== 'modify' ? (c) => handleChange(c, lvl.id, id, 'view_permission') : undefined
                              }
                            />
                            <Checkbox
                              label="M"
                              disabled={type === 'view'}
                              checked={type !== 'view' ? getValues(lvl.id, id, 'modify_permission') : false}
                              onChange={
                                type !== 'view' ? (c) => handleChange(c, lvl.id, id, 'modify_permission') : undefined
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              ))}
              <div className="c-permissions__container">
                <div className="c-permissions__head-wrapper">
                  <ul className="c-permissions__head">
                    <li>EDIT ACCESS LEVEL</li>
                    {levels.map((lvl) => (
                      <li key={lvl.id}>
                        <span className="c-permissions__head-title">{lvl.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="c-permissions__body-wrapper">
                  <ul className="c-permissions__body">
                    <li>EDIT</li>
                    {levels.map((lvl) => (
                      <li className="c-permissions__input-box" key={lvl.id}>
                        <Button sm color="warning" link={`${URL_PAGE}level/${lvl.id}`}>
                          EDIT
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <Button onClick={handleSavePermissions}>Save Changes</Button>
          </div>
        </>
      )}
    </>
  );
};

export default UserPermissions;
