import { getDateFromString, getFormatBriefDate } from '../utilities/DateHelpers';

/**
 *
 * @param card {{
 *     id: Number,
 *     services_info: Array,
 *     name: String,
 *     value: Number,
 *     retail_price: Number,
 *     usage_limit: Number,
 *     expire_after: String,
 *     is_saleable: Boolean,
 *     sold_count: Number,
 *     campaign: Object,
 *     email_subject: String,
 *     email_description: String,
 *     send_sms: Boolean,
 *     status: String,
 *     created_at: String,
 * }}
 * @constructor
 */

function GiftCardObject(card) {
  this.id = card.id;
  this.services = card.services_info.map((i) => i.id);
  this.services_info = card.services_info;
  this.name = card.name;
  this.value = card.value;
  this.retail_price = card.retail_price;
  this.usage_limit = card.usage_limit;
  this.is_saleable = card.is_saleable;
  this.sold_count = card.sold_count;
  this.expire_after = card.expire_after;
  this.is_saleable = card.is_saleable;

  const createdAt = getDateFromString(card.created_at);
  createdAt.setDate(createdAt.getDate() + +card.expire_after);
  this.expire_date = getFormatBriefDate(createdAt);

  this.send_sms = card.send_sms;
  this.campaign = card.campaign;
  this.email_subject = card.email_subject;
  this.email_description = card.email_description;
  this.status = card.status;
}

export default GiftCardObject;
