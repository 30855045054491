import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { PageLoading } from '../../../Common/UIKit/Spinner';
import Pagination from '../../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../../Common/UIKit/NotFoundData';
import useApi from '../../../../utilities/useApi';
import { getSoldGiftCardList } from '../../../../api';
import { getQueryUri, setQueryUri } from '../../../../utilities/URI';
import { getPriceFormat } from '../../../../utilities/Price';
import PromotionSoldGiftCardModal from '../../../Common/Promotion/PromotionGiftCard/PromotionSoldGiftCardModal';
import { AppContext } from '../../../../context/providers';
import HeadPage from '../../../Common/Layouts/HeadPage/HeadPage';
import usePermission from '../../../../utilities/usePermission';

const URL_PAGE = '/promotions/gift-cards/sold/';

// eslint-disable-next-line react/prop-types
const PromotionSoldGiftCards = ({ headerEl }) => {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { setHeader } = useContext(AppContext);
  let [, loading] = useApi(getSoldGiftCardList, { page: true, key: 'soldgiftcard' }, setData);
  const { access: _access } = usePermission('soldgiftcard', 'crud');
  const { access: _activate } = usePermission('soldgiftcard', 'activate', 'view');
  const { access: _deactivate } = usePermission('soldgiftcard', 'deactivate', 'view');

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    setHeader('Promotion / Sold Gift Cards');
  }, [setHeader]);

  useEffect(() => {
    if (match.params.id || match.path === `${URL_PAGE}add`) {
      handleOpenModal();
    }
  }, [match]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (opened = true) => {
    setShowModal(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveForm = (id, item = null) => {
    const page = getQueryUri('page');
    if (typeof id === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === id);
      if (updateData.length > 1 || !page || +page === 1) {
        if (item) {
          updateData[ind] = { ...updateData[ind], ...item };
        } else {
          updateData.splice(ind, 1);
        }
        setData({ ...data, results: updateData });
      } else {
        if (+page > 1) {
          history.push(URL_PAGE + setQueryUri({ page: page - 1 }));
        }
      }
    }
    getSoldGiftCardList({ page }).then(setData);
    handleCloseModal();
  };

  return (
    <>
      <HeadPage leftElement={headerEl} />
      {_access?.view ? (
        <>
          {showModal && <PromotionSoldGiftCardModal onClose={handleCloseModal} onSave={handleSaveForm} />}
          {loading && !data ? (
            <PageLoading />
          ) : !!data && data.count > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table c-table--sm">
                  <table>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th className="text-center">Client</th>
                        <th className="text-center">Initial Balance</th>
                        <th className="text-center">Current Balance</th>
                        <th className="text-center">Last Used At</th>
                        <th className="text-center">Expire At</th>
                        <th className="text-center">Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {/*{data.results.map((item, key) => (*/}
                      {/*    <PromotionSoldGiftCardItem key={key} item={item} onSave={handleSaveForm} />*/}
                      {/*))}*/}
                      {data.results.map((item) => (
                        <tr key={item.id}>
                          <td>{item.code}</td>
                          <td className="text-center">{item.client_name}</td>
                          <td className="text-center">{getPriceFormat(+item.initial_balance)}</td>
                          <td className="text-center">{getPriceFormat(+item.current_balance)}</td>
                          <td className="text-center">
                            {item.last_used_at ? item.last_used_at : <small>This gift card has not used yet</small>}
                          </td>
                          <td className="text-center">{item.expiration_date}</td>
                          <td className="text-center">
                            {item?.expired ? (
                              <span className="badge badge-danger">Expired</span>
                            ) : _activate && _deactivate ? (
                              <>
                                {item?.is_active ? (
                                  <span className="badge badge-success">Active</span>
                                ) : (
                                  <span className="badge badge-secondary">Inactive</span>
                                )}
                              </>
                            ) : null}
                          </td>
                          <td>
                            <NavLink id={`link-${item.id}`} to={`${URL_PAGE}${item.id}`}>
                              Manage
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Sold Gift Cards Exists"
              description="There is not any sold gift card"
              icon="fas fa-gift"
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default PromotionSoldGiftCards;
