import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DotsSpinner } from '../Spinner';

const Button = ({ children, sm, lg, block, link, color, loading, className, node, ...otherProps }) => {
  const btnClasses = classnames(
    'c-button',
    {
      'c-button--light': color === 'light',
      'c-button--dark': color === 'dark',
      'c-button--main': color === 'main',
      'c-button--success': color === 'success',
      'c-button--primary': color === 'primary',
      'c-button--danger': color === 'danger',
      'c-button--warning': color === 'warning',
      'c-button--info': color === 'info',
      'c-button--lg': lg,
      'c-button--sm': sm,
      'c-button--block': block,
      'c-button--loading': loading,
    },
    className,
  );

  const CustomTag = `${node}`;

  const btnContent = loading ? <DotsSpinner sm dark={color === 'light'} /> : children;

  return node ? (
    <CustomTag className={btnClasses} {...otherProps}>
      {btnContent}
    </CustomTag>
  ) : link ? (
    link.includes('http://') || link.includes('https://') ? (
      <a href={link} className={btnClasses} {...otherProps}>
        {btnContent}
      </a>
    ) : (
      <NavLink to={link} strict sensitive className={btnClasses} {...otherProps}>
        {btnContent}
      </NavLink>
    )
  ) : (
    <button className={btnClasses} {...otherProps}>
      {btnContent}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  color: PropTypes.oneOf(['light', 'dark', 'main', 'success', 'primary', 'danger', 'warning', 'info']),
  node: PropTypes.string,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.string,
};

Button.defaultProps = {
  color: 'main',
  sm: false,
  block: false,
  loading: false,
  link: null,
  className: '',
};

export default Button;
