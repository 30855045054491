import React, { useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import { handleApi } from '../../../../utilities/useApi';
import { getClientList } from '../../../../api';
import { PageLoading } from '../../UIKit/Spinner';
import Input from '../../UIKit/Form/Input';
import ModalFormCreateClient from './ModalFormCreateClient';
import ModalFormClientWalkInForm from './ModalFormClientWalkInForm';
import ModalFormClientItem from './ModalFormClientItem';
import usePermission from '../../../../utilities/usePermission';
import { AccessDeniedPage } from '../../UIKit/NotFoundData';

// eslint-disable-next-line react/prop-types
const ModalFormClients = ({ onSelect, client, edit, onEdit }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const { access: _clients } = usePermission('client', 'crud');

  useEffect(() => {
    if (!data && _clients?.view) {
      handleApi(getClientList, {}, { infinitePage: true, loadingFn: setLoading, maxPage: 3 }).then(setData);
    }
  }, [_clients]);

  const handleSearch = async (text) => {
    handleApi(getClientList, { search: text }, { infinitePage: true, loadingFn: setLoading, maxPage: 1 }).then(setData);
  };

  const handleSave = (item) => {
    setShowForm(false);
    onSelect(item);
    if (edit) onEdit();
    handleApi(getClientList, {}, { infinitePage: true, loadingFn: setLoading, maxPage: 3 }).then(setData);
  };

  return (
    <>
      {showForm || edit ? (
        <ModalFormCreateClient
          client={client}
          onClose={() => {
            setShowForm(false);
            onEdit();
          }}
          onSave={handleSave}
        />
      ) : (
        <>
          {_clients?.view || _clients?.modify ? (
            <div className="c-modal-form-sidebar__clients-header">
              {_clients?.view && (
                <div data-tour="client-search" className="c-modal-form-sidebar__clients-search">
                  <Input placeholder="Search client" id="search-client" onChangeText={handleSearch} formGroup={false} />
                </div>
              )}
              {_clients?.modify && (
                <div
                  data-tour="client-add"
                  className="c-modal-form-sidebar__clients-create"
                  onClick={() => setShowForm(true)}
                >
                  <Add /> Create new Client
                </div>
              )}
            </div>
          ) : null}

          <div data-tour="client-list" className="c-modal-form-sidebar__clients-list">
            {_clients?.view ? (
              <>
                {loading ? (
                  <PageLoading />
                ) : (
                  (data?.results || []).map((i, index) => (
                    <ModalFormClientItem key={i.id} index={index} data={i} onSelect={() => onSelect(i)} />
                  ))
                )}
              </>
            ) : (
              <AccessDeniedPage title="Clients View Access Denied" />
            )}
          </div>

          <ModalFormClientWalkInForm onSave={onSelect} />
        </>
      )}
    </>
  );
};

export default ModalFormClients;
