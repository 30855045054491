import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Button from '../../../Common/UIKit/Form/Button';
import { PageLoading } from '../../../Common/UIKit/Spinner';
import Pagination from '../../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../../Common/UIKit/NotFoundData';
import useApi from '../../../../utilities/useApi';
import { getGiftCardList } from '../../../../api';
// import PromotionGiftCardFormModal from "../../../Common/Promotion/PromotionGiftCard/PromotionGiftCardFormModal";
import PromotionGiftCardItem from '../../../Common/Promotion/PromotionGiftCard/PromotionGiftCardItem';
import CampaignModal from '../../../Common/Campaign/CampaignModal';
import PromotionGiftCardModal from '../../../Common/Promotion/PromotionGiftCard/PromotionGiftCardModal';
import { AppContext } from '../../../../context/providers';
import HeadPage from '../../../Common/Layouts/HeadPage/HeadPage';
import usePermission from '../../../../utilities/usePermission';
import PromotionGiftCardDetailModal from '../../../Common/Promotion/PromotionGiftCard/PromotionGiftCardDetailModal';

const URL_PAGE = '/promotions/gift-cards/all/';

// eslint-disable-next-line react/prop-types
const PromotionGiftCards = ({ headerEl }) => {
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const { setHeader } = useContext(AppContext);
  let [, loading] = useApi(getGiftCardList, { page: true, key: 'giftcard' }, setData);
  const { access: _access } = usePermission('giftcard', 'crud');

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    setHeader('Promotion / Gift Cards');
  }, [setHeader]);

  useEffect(() => {
    if (match.path.includes('campaign')) {
      handleOpenCampaign();
    } else if (match.params.id) {
      handleOpenDetail();
    } else if (match.path === `${URL_PAGE}add`) {
      handleOpenForm();
    }
  }, [match]);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    setShowDetail(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleOpenDetail = () => setShowDetail(true);
  // const handleCloseDetail = (opened = true) => {
  //   setShowDetail(false);
  //   if (opened) history.replace(URL_PAGE);
  // };

  const handleOpenCampaign = () => setShowCampaign(true);
  const handleCloseCampaign = (opened = true) => {
    setShowCampaign(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveForm = (item) => {
    if (typeof item === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    setShowForm(false);
    setShowDetail(false);
    getGiftCardList().then(setData);
  };

  return (
    <>
      {(showForm || showDetail) && !_access?.modify && _access?.view && (
        <PromotionGiftCardDetailModal onSave={handleSaveForm} onClose={handleCloseForm} />
      )}
      {(showForm || showDetail) && _access?.modify && _access?.view && (
        <PromotionGiftCardModal onSave={handleSaveForm} onClose={handleCloseForm} />
      )}
      {/*{showDetail && <PromotionGiftCardModal onClose={handleCloseDetail} onSave={handleSaveForm} />}*/}
      {showCampaign && <CampaignModal onClose={handleCloseCampaign} />}
      {/*<HeadPage title={'Promotion Gift Cards'} rightElement={<Button sm link={URL_PAGE + 'add'} >New Gift Card</Button>}  />*/}
      <HeadPage
        rightElement={
          _access?.modify ? (
            <Button sm link={`${URL_PAGE}add`}>
              New Gift Card
            </Button>
          ) : null
        }
        leftElement={headerEl}
      />
      {/*<HeadOptions rightElement={<Button sm link={URL_PAGE + 'add'} >New Gift Card</Button>}  />*/}
      {_access?.view ? (
        <>
          {loading && !data ? (
            <PageLoading />
          ) : !!data && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table c-table--clickable">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th className="text-center">Service</th>
                        <th className="text-center">Value</th>
                        <th className="text-center">Retail Price</th>
                        <th className="text-center">Valid for</th>
                        <th className="text-center">Sold Count</th>
                        <th className="text-center">Usage Limit</th>
                        {/*<th>Expiration Date</th>*/}
                        {/*<th>Status</th>*/}
                        {/*<th />*/}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <PromotionGiftCardItem key={item.id} item={item} page_url={URL_PAGE} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Gift Cards Exists"
              description="Use bottom button to add your first gift card"
              button={<Button link={`${URL_PAGE}add`}>ADD NEW GIFT CARD</Button>}
              icon="fas fa-gift"
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default PromotionGiftCards;
