import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';

const { GET, POST, PATCH, DELETE } = ApiMethods;

/**
 * @param {{
 *    page: Number,
 *    limit: Number,
 * }}
 */
export const getAccessLvlItemsList = async ({ page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.accessLevel.items,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param {{
 *    page: Number,
 *    limit: Number,
 * }}
 */
export const getAccessLvlList = async ({ page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.accessLevel.list,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getAccessLvl = async (id) => {
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.accessLevel.get, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name: {String},
 *     description: {String},
 *     business: {Number},
 *     permissions: {Object({
 *       access_level_item: {Number},
 *       view_permission: {Boolean},
 *       modify_permission: {Boolean},
 *     })},
 *     is_active
 * }}
 */
export const partialUpdateAccessLvl = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    params,
    url: createURL(ApiRoutes.accessLevel.update, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     name: {String},
 *     description: {String},
 *     business: {Number},
 *     permissions: {Object({
 *       access_level_item: {Number},
 *       view_permission: {Boolean},
 *       modify_permission: {Boolean},
 *     })},
 *     is_active
 * }}
 */
export const createAccessLvl = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.accessLevel.create,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteAccessLvl = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.accessLevel.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
