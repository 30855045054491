import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import MessageObject from '../objects/MessageObject';

const { GET, POST } = ApiMethods;

const getParams = (params) => {
  if (!!params.attachment) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (!!params[key] && key !== 'attachment') formData.append(key, params[key]);
    });
    formData.append('attachment', params.attachment);
    return formData;
  }
  return params;
};

/**
 * @param params {{
 *     page,
 *     limit
 * }}
 */
export const getMessageList = async ({ page, limit, ...otherParams }) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
      ...otherParams,
    },
    url: ApiRoutes.messages.list,
  });

  if (response.data) {
    return {
      ...response.data,
    };
    // return {
    //     ...response.data,
    //     results: (response.data.results || []).map(i => new MessageObject(i))
    // }
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *   page,
 *   limit,
 *   id,
 * }}
 */
export const getMessage = async ({ page, limit, id }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    url: createURL(ApiRoutes.messages.get, { id }),
  });

  if (response.data) {
    return {
      ...response.data,
      page,
      results: (response.data.results || []).map((i) => new MessageObject(i)).reverse(),
    };
  } else {
    return Promise.reject(response);
  }
};

export const createMessage = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.messages.create,
    params: getParams(params),
    headers: !!params.attachment
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });

  if (response.data) {
    return Array.isArray(response.data)
      ? response.data.map((i) => new MessageObject(i))
      : new MessageObject(response.data);
  } else {
    return Promise.reject(response);
  }
};
