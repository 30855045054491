/**
 *
 * @param staff {{
 *     id,
 *     name,
 *     time_slots,
 *     hours,
 * }}
 * @constructor
 */

// function StaffHoursObject(staff) {
//     this.id = staff.id;
//     this.name = staff.name;
//     this.hours = (staff.time_slots || staff.hours).map(h => new StaffHourObject(h));
// }

/**
 *
 * @param workingHour {{
 *     id,
 *     staff,
 *     end_time,
 *     start_time,
 *     start_time_tz,
 *     end_time_tz,
 *     week_day,
 *     is_active,
 * }}
 * @constructor
 */
function StaffHourObject(workingHour) {
  this.id = workingHour.id;
  this.staff = workingHour.staff;
  this.week_day = workingHour.week_day;
  this.start_time = workingHour.start_time_tz || workingHour.start_time;
  this.end_time = workingHour.end_time_tz || workingHour.end_time;
  // this.start_time = workingHour.start_time.replace(':00 ', ' ');
  // this.end_time = workingHour.end_time.replace(':00 ', ' ');
  // this.start_time = getFormat24H(workingHour.start_time);
  // this.end_time = getFormat24H(workingHour.end_time);
  this.is_active = workingHour.is_active;
}

export default StaffHourObject;
// export default StaffHoursObject;
