/**
 *
 * @param bookablePhoto {{
 *     id,
 *     tags,
 *     price,
 *     is_published,
 *     image,
 *     service_type,
 *     service_type_name,
 *     created_at,
 *     status
 * }}
 * @constructor
 */

function BookablePhotoObject(bookablePhoto) {
  this.id = bookablePhoto.id;
  this.tags = bookablePhoto.tags;
  this.price = bookablePhoto.price;
  this.is_published = bookablePhoto.is_published;
  this.image = bookablePhoto.image;
  this.service = bookablePhoto.service;
  this.service_name = bookablePhoto.service_name;
  this.staff = bookablePhoto.staff;
  this.staff_name = bookablePhoto.staff_name;
  this.service_type = bookablePhoto.service_type;
  this.service_type_name = bookablePhoto.service_type_name;
  this.created_at = bookablePhoto.created_at ? bookablePhoto.created_at.replace(' UTC+0000', '') : '';
  this.is_published = bookablePhoto.is_published;
  this.status = bookablePhoto?.status || (bookablePhoto.is_published ? 'published' : 'draft');
}

export default BookablePhotoObject;
