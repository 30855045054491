import React, { useContext, useEffect } from 'react';
import DashboardBox from '../../Common/Dashboard/DashboardBox';
import { Line } from 'react-chartjs-2';
import useApi from '../../../utilities/useApi';
import { getAnalyticsClients } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';
import { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { useHistory } from 'react-router-dom';

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          userCallback: function (label) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }
          },
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          maxTicksLimit: 15,
        },
      },
    ],
  },
};

// eslint-disable-next-line react/prop-types
const AnalyticsClientsPage = ({ headerEl }) => {
  const [data, loading] = useApi(getAnalyticsClients, { key: 'business.clients_statistics' });
  const { setHeader } = useContext(AppContext);
  const { access: _access } = usePermission('business', 'clients_statistics', 'view');
  const history = useHistory();
  useEffect(() => {
    setHeader('Analytics / Client Overview');
  }, [setHeader]);
  const decorateClients = (clients) => {
    const { labels, data } = clients.reduce(
      (acc, c) => {
        acc.labels.push(c.date);
        acc.data.push(c.count || 0);
        return acc;
      },
      { labels: [], data: [] },
    );
    return {
      labels: labels,
      datasets: [
        {
          label: 'Clients',
          data,
          borderColor: '#fc5622',
          borderWidth: 2,
          lineTension: 0,
          fill: false,
        },
      ],
    };
  };

  return (
    <>
      <HeadPage leftElement={headerEl} />
      {_access ? (
        <>
          {loading ? (
            <PageLoading />
          ) : (
            <>
              <div className="c-dashboard__box-wrapper">
                <DashboardBox
                  title="New Clients"
                  description={String(data?.new_clients_count || 0)}
                  icon="fas fa-user-plus"
                  className="cursor-pointer"
                  onClick={() => history.push('/clients/')}
                  grid={2}
                />
                <DashboardBox
                  title="Returning Clients"
                  description={String(data?.returned_clients_count || 0)}
                  icon="fas fa-users"
                  className="cursor-pointer"
                  onClick={() => history.push('/clients/')}
                  grid={2}
                />
              </div>
              {data?.all_clients_count && (
                <div className="c-dashboard__content">
                  <div className="c-dashboard__header">
                    <div className="c-dashboard__head">
                      <h1 className="c-dashboard__title">Clients for this year</h1>
                    </div>
                  </div>
                  <div className="c-dashboard__body">
                    <div className="c-dashboard__chart">
                      <Line
                        data={decorateClients(data.all_clients_count)}
                        width={100}
                        height={300}
                        options={chartOptions}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default AnalyticsClientsPage;
