import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import StaffObject from '../objects/StaffObject';
import { getParams } from '../utilities/ParamHelper';

const { GET, POST, PUT, DELETE } = ApiMethods;

/**
 * @param params {{
 *     avatar,
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const createStaff = async (params) => {
  const response = await callApi({
    method: POST,
    headers: !!params.avatar
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
    params: getParams(params),
    url: ApiRoutes.staff.create,
  });
  if (response.data) {
    return new StaffObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const updateStaff = async (id, params) => {
  const response = await callApi({
    method: PUT,
    headers: !!params.avatar
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
    params: getParams(params),
    url: createURL(ApiRoutes.staff.update, { id }),
  });
  if (response.data) {
    return new StaffObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param {{
 *    page: Number,
 *    limit: Number,
 *    url: String,
 *    search: String,
 *    only_stylists: Boolean,
 * }}
 */
export const getStaffList = async ({ page, limit, only_stylists = false, ...otherParams } = {}) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
      ...otherParams,
    },
    url: ApiRoutes.staff.list,
  });
  if (response.data) {
    let results = response.data?.results || [];
    if (only_stylists) results = results.filter((i) => i.is_stylist);
    return {
      ...response.data,
      results: results.map((i) => new StaffObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

export const getStaff = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.staff.get, { id }),
  });
  if (response.data) {
    return new StaffObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const deleteStaff = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.staff.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param {{
 *   id: String,
 *   reserve_date: String,
 *   reserve_time: String,
 *   duration: String,
 * }}
 * @description getting staff taken time slots
 */
export const getStaffSlot = async ({ id, reserve_date, reserve_time, duration }) => {
  const response = await callApi({
    method: POST,
    params: { reserve_date, reserve_time, duration },
    url: createURL(ApiRoutes.staff.slot, { id }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
