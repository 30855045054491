import React, { useState } from 'react';
import Modal from '../../UIKit/Modal';
import Input from '../../UIKit/Form/Input';
import { useForm } from 'react-hook-form';

// eslint-disable-next-line react/prop-types
const TokenFormModal = ({ loading, show, onClose, onSave }) => {
  const [token, setToken] = useState('');
  const { errors, setError } = useForm();

  const handleSave = (e) => {
    e.preventDefault();
    if (!!token) {
      onSave(token);
    } else {
      setError('token', { type: 'manual', message: 'Token is required' });
    }
  };

  return (
    <Modal
      show={show}
      title="Change Token"
      saveLoading={loading}
      onSave={handleSave}
      saveBtnText="Submit Token"
      onClose={onClose}
    >
      <div className="alert alert-info my-3">Enter your token correctly</div>
      <Input
        id={'token'}
        type="tel"
        formGroup
        label={'Token'}
        value={token}
        onChangeText={setToken}
        placeholder={'Token'}
        errorMessage={errors?.token?.message}
        error={!!errors.token}
      />
    </Modal>
  );
};

export default TokenFormModal;
