import React, { useState } from 'react';
import Button from '../../UIKit/Form/Button';
import Input from '../../UIKit/Form/Input';

// eslint-disable-next-line react/prop-types
const ModalFormClientWalkInForm = ({ onSave }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const handleSave = (e) => {
    e.preventDefault();
    if (!!name.trim()) {
      onSave(name);
    } else {
      setError('Please fill full name field');
    }
  };
  const handleChangeName = (text) => {
    if (!!error) setError(null);
    setName(text);
  };
  return (
    <form
      id="walkInForm"
      data-tour="client-walk-in"
      onSubmit={handleSave}
      className="c-modal-form-sidebar__clients-walk-in"
    >
      <Input
        placeholder="Full Name"
        onChangeText={handleChangeName}
        label="Walk-In"
        id="walkInInput"
        formClassName="c-modal-form-sidebar__clients-walk-in-form"
        errorMessage={error}
        error={error}
      />
      <Button id="walkInSubmit" type="submit" style={!!error ? { marginBottom: '21px' } : undefined}>
        Submit
      </Button>
    </form>
  );
};

export default ModalFormClientWalkInForm;
