import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import CheckoutLines from './CheckoutLines';
import ModalForm, { ModalFormContent } from '../ModalForm/ModalForm';
import CheckoutSidebar from './CheckoutSidebar';
import { PageLoading } from '../UIKit/Spinner';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { createInvoice, createStripe, getAppointment, getGiftCard, getInvoice, updateInvoice } from '../../../api';
import CheckoutSummary from './CheckoutSummary';
import CheckoutTipForm from './CheckoutTipForm';
import { StripeElement } from '../Stripe';
import toaster from 'toasted-notes';
import { AppContext } from '../../../context/providers';
import CheckoutDiscount from './CheckoutDiscount';

// onClose, onSave
const CheckoutModal = ({ id, type = 'service', invoiceId, onClose, onSave }) => {
  const [stripeLoading, setStripeLoading] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [lines, setLines] = useState(null);
  const [pays, setPays] = useState([]);
  const [tips, setTips] = useState([]);
  const [total, setTotal] = useState({ line: 0, sum: 0, tax: { rate: 0, amount: 0 } });
  const { control, errors, handleSubmit, setValue } = useForm();

  const { business } = useContext(AppContext);

  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, [type, id]);

  const getData = () =>
    handleApi(type === 'service' ? getAppointment : getGiftCard, id, { list: false }).then((res) => {
      setData(res);
      if (type === 'service') {
        const _lines = res.lines.map((l) => {
          let price = l.price;
          let line_price = l.price;
          return {
            ...l,
            price,
            line_price,
            type: 'service',
          };
        });
        setLines(_lines);
        setClient(
          !!res.client
            ? {
                id: res.client,
                name: res.client_name,
              }
            : res.client_name,
        );
      } else {
        setLines([
          {
            id: res.id,
            type: 'gift_card',
            quantity: 1,
            is_saleable: res.is_saleable,
            expire_after: res.expire_after,
            name: res.name,
            line_price: res.retail_price,
            price: res.retail_price,
            services: res.services,
            usage_limit: res.usage_limit,
            value: res.value,
            sold_count: res.sold_count,
          },
        ]);
      }
    });

  const getInvoiceData = () => {
    handleApi(getInvoice, invoiceId, { list: false, loadingFn: setLoading }).then((data) => {
      setInvoiceData(data);
      const tips = data.tips.map((tip) => ({
        id: tip.id,
        type: tip.amount_type,
        amount: +tip.amount,
        staff: tip.staff,
        staff_name: tip.staff_name,
      }));
      setTips(tips);

      data.transactions.forEach(({ amount, method, voucher_code }) => {
        handlePay(method, +amount, { paid: true, code: voucher_code });
      });
      if (lines.length > 0) {
        const updateLines = data.lines.map((line) => {
          const lineItem = lines.find((l) => +l.service === +line.item_id);
          return {
            ...lineItem,
            price: +line.price,
          };
        });

        setLines(updateLines);
      }
    });
  };

  useEffect(() => {
    if (invoiceId && !!lines && !invoiceData) {
      getInvoiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, lines]);

  useEffect(() => {
    if (lines) {
      // Gift cards total prices
      const giftCards = lines.filter((i) => i.type === 'gift_card');
      const giftCardPrices = giftCards.reduce((acc, i) => {
        if (i.price && (i.quantity || +i.quantity === 0) && typeof +i.quantity === 'number')
          return +i.price * +i.quantity + acc;
        if (i.price) return +i.price + acc;
        if (i.retail_price) return +i.retail_price + acc;
        else if (i.full_service) return +i.full_service.price + acc;
        return acc;
      }, 0);

      // Appointments total prices
      const appointments = lines.filter((i) => i.type !== 'gift_card');
      const appointmentsPrices = appointments.reduce((acc, i) => {
        if (i.price && (i.quantity || +i.quantity === 0) && typeof +i.quantity === 'number')
          return +i.price * +i.quantity + acc;
        if (i.price) return +i.price + acc;
        if (i.retail_price) return +i.retail_price + acc;
        else if (i.full_service) return +i.full_service.price + acc;
        return acc;
      }, 0);

      const linePrices = giftCardPrices + appointmentsPrices;

      const tipPrices = tips.reduce(
        (acc, i) =>
          // currency
          i.type !== 'percent' ? +i.amount + acc : (linePrices * +i.amount) / 100 + acc,
        0,
      );
      console.log('appointmentsPrices', appointmentsPrices);

      // Gift cards do not have tax
      // const taxAmount = (linePrices / 100) * +business.tax_rate;
      const taxAmount = (appointmentsPrices / 100) * +business.tax_rate;
      const taxRate = taxAmount > 0 ? +business.tax_rate : 0;
      setTotal({
        tax: {
          rate: taxRate,
          amount: taxAmount,
        },
        line: linePrices,
        sum: +(linePrices + tipPrices + taxAmount).toFixed(3),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines, tips, business]);

  const totalPay = useMemo(() => pays.reduce((acc, i) => +i.price + acc, 0), [pays]);

  const generateInvoiceBody = (payment = true) => {
    const bodyLines = lines
      .reduce((acc, line) => {
        const updatedAcc = [...acc];
        if (!!line.quantity) {
          for (let i = 0; i < +line.quantity; i++) {
            updatedAcc.push(line);
          }
          return updatedAcc;
        }
        updatedAcc.push(line);
        return updatedAcc;
      }, [])
      .map((l) => ({
        item_type: type,
        item_id: l.type === 'service' ? l.service : l.id,
        sold_item_id: l.type === 'service' ? l.id : undefined,
        price: l.price,
        staff: l.staff,
        discount_type: +l.line_price !== +l.price ? 'manual' : l.service_discount_type,
      }));

    const body = {
      client: typeof client === 'string' ? undefined : client.id,
      client_name: typeof client === 'string' ? client : client.name,
      appointment: type === 'service' ? data.id : undefined,
      lines: bodyLines,
      tips:
        type === 'service'
          ? tips.map((t) => ({
              staff: t.staff,
              amount: t.amount,
              amount_type: t.type,
            }))
          : undefined,
      transactions: payment
        ? pays.reduce((acc, p) => {
            if (p.paid) return acc;

            const updateAcc = [...acc];
            const index = updateAcc.findIndex((i) => i.method === p.key);
            if (index > -1) {
              updateAcc[index].amount = updateAcc[index].amount + +p.price;
            } else {
              updateAcc.push({
                amount: +p.price,
                method: p.key,
                type: 'payment',
                voucher_code: p.key === 'gift_card' && p.code ? p.code : undefined,
              });
            }
            return updateAcc;
          }, [])
        : undefined,
    };
    return body;
  };
  const handleSave = ({}, payment = true) => {
    handleApiSave(createInvoice, updateInvoice, generateInvoiceBody(payment), invoiceId, { loadingFn: setSaveLoading })
      .then((res) => {
        if (type === 'gift_card') {
          onSave({ ...res, giftCard: data });
        } else {
          onSave(res);
        }
      })
      .catch(() => {});
  };

  const handleCreateInvoiceAndUpdate = async () => {
    if (!invoiceId) {
      const response = await handleApiSave(createInvoice, undefined, generateInvoiceBody(true));
      getData();
      setInvoiceData(response);
      return response;
    }
    getData();
    return null;
  };

  const handleStripe = (amount) => {
    setStripeLoading(true);
    const body = {
      invoice: data.invoice,
      client: typeof client === 'string' ? undefined : client.id,
      client_name: typeof client === 'string' ? client : client.id,
      appointment: type === 'service' ? data.id : undefined,
      lines: lines.map((l) => ({
        item_type: type,
        // item_id: l.id,
        sold_item_id: l.type === 'service' ? l.id : undefined,
        item_id: type === 'gift_card' ? l.id : l.service,
        price: l.price,
        staff: l.staff,
      })),
      tips:
        type === 'service'
          ? tips.map((t) => ({
              staff: t.staff,
              amount: t.amount,
              amount_type: t.type,
            }))
          : undefined,
    };
    handleApiSave(createStripe, undefined, { params: body, amount })
      .then((data) => {
        if (data?.session_id) setStripe(data);
        else {
          toaster.notify(() => (
            <div className="alert alert-danger">
              There is a problem, maybe you haven&apos;t sat your stripe secret key in business profile
            </div>
          ));
        }
      })
      .catch(() => setStripeLoading(false));
    // }).finally(() => setStripeLoading(false));
  };

  const handleChangeLineDiscountAmount = (line, discountAmount) => {
    const updatedLines = [...lines];
    const item = updatedLines.find((l) => l.id === line.id);
    item.discount_amount = discountAmount;
    setLines(updatedLines);
  };

  const handleChangeLineDiscount = (line, discount) => {
    const updatedLines = [...lines];
    const itemIndex = updatedLines.findIndex((l) => l.id === line.id);
    updatedLines[itemIndex].discount = discount;
    setValue(`discount_amount[${itemIndex}]`, 'null');
    setLines(updatedLines);
  };

  const handleChangeLinePrice = (line, price, withControl = false) => {
    const updatedLines = [...lines];
    const itemIndex = updatedLines.findIndex((l) => l.id === line.id);
    // const item = updatedLines.find(l => l.id === line.id);
    const item = updatedLines[itemIndex];
    item.price = price;
    setLines(updatedLines);
    setTips([]);
    setPays([]);
    if (withControl) {
      setValue(`price[${itemIndex}]`, price);
    }
  };

  const handleChangeLineStaff = (line, staff) => {
    const updatedLines = [...lines];
    const item = updatedLines.find((l) => l.id === line.id);
    item.staff = staff;
    setLines(updatedLines);
  };

  const handleChangeLineQuantity = (line, quantity) => {
    const updatedLines = [...lines];
    const item = updatedLines.find((l) => l.id === line.id);
    quantity = Math.floor(+quantity);
    item.quantity = +quantity;
    setLines(updatedLines);
  };

  const handlePay = (key, price, options) => {
    const paid = typeof options?.paid === 'boolean' ? options.paid : false;

    if ((total.sum - totalPay >= +price && +price > 0) || paid) {
      setPays([...pays, { key, price: +price, paid, ...options }]);
    }
  };

  /**
   * @param type => 'tip' || 'pay'
   * @param key => type 'pay' => 'pay[key]' | type 'tip' => 'tip'
   **/
  const handleRemovePay = (type, key) => {
    if (type === 'pay') {
      const updatedPays = pays.filter((i) => i.key !== key);
      setPays(updatedPays);
    } else if (type === 'tip') {
      const updatedTips = tips.filter((i) => i.id !== key);
      setTips(updatedTips);
      setPays([]);
    }
  };

  /**
   * @param data {{
   *     type,
   *     amount,
   *     staff,
   *     staff_name,
   * }}
   **/
  const handleAddTip = ({ type, amount, staff, staff_name }) => {
    if (+amount > 0 && staff && staff_name) {
      setTips([...tips, { id: tips.length + 1, type, amount, staff, staff_name }]);
    }
  };

  /**
   * @param discount {{
   *     amount,
   *     amount_type,
   *     code,
   *     expiration_date,
   *     expired,
   *     name,
   * }}
   **/
  const handleDiscount = (discount) => {
    if (+discount.amount > 0) {
      setPays([]);
    }
  };

  const handleSuccessStripe = () => {
    getInvoiceData();
  };

  return (
    <>
      <Modal show auto disableFooter title="Checkout" bodyClass="p-0" onClose={onClose}>
        {saveLoading && <PageLoading over />}
        {loading || !lines ? (
          <PageLoading />
        ) : (
          <ModalForm>
            <ModalFormContent>
              <CheckoutLines
                control={control}
                lines={lines}
                errors={errors}
                pays={pays}
                data={data}
                invoiceId={invoiceId}
                onChangeDiscountAmount={handleChangeLineDiscountAmount}
                onChangeDiscount={handleChangeLineDiscount}
                onChangePrice={handleChangeLinePrice}
                onChangeStaff={handleChangeLineStaff}
                onChangeQuantity={handleChangeLineQuantity}
              />
              <div className="c-checkout-footer">
                {!invoiceId && type === 'service' && (
                  <CheckoutTipForm lines={lines} tips={tips} onSave={handleAddTip} />
                )}
                <div className="c-checkout-footer__summary">
                  <CheckoutSummary
                    total={total}
                    pays={pays}
                    tips={tips}
                    invoiceId={invoiceId}
                    onRemove={handleRemovePay}
                  />
                  {false && <CheckoutDiscount onSubmit={handleDiscount} />}
                </div>
              </div>
            </ModalFormContent>
            <CheckoutSidebar
              lines={lines}
              client={client}
              type={type}
              pays={pays}
              onSuccessStripe={handleSuccessStripe}
              invoiceData={invoiceData}
              totalPrice={+(total.sum - totalPay).toFixed(2)}
              setClient={setClient}
              onCreateStripe={handleStripe}
              stripeLoading={stripeLoading}
              onSave={handleSubmit(handleSave)}
              createInvoice={handleCreateInvoiceAndUpdate}
              onPay={handlePay}
            />
          </ModalForm>
        )}
      </Modal>
      {stripe && <StripeElement session_id={stripe?.session_id} onClose={() => setStripe(null)} />}
    </>
  );
};

CheckoutModal.propTypes = {
  id: PropTypes.any,
  type: PropTypes.string,
  invoiceId: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default CheckoutModal;
