import React, { useContext, useEffect, useMemo, useState } from 'react';
import toaster from 'toasted-notes';
import { useForm } from 'react-hook-form';
import useApi, { handleApiSave } from '../../../utilities/useApi';
import { getBusiness, partialUpdateBusiness } from '../../../api';
import { AppContext } from '../../../context/providers';
import { PageLoading } from '../../Common/UIKit/Spinner';
import SectionHead from '../../Common/UIKit/SectionTitle';
import Button from '../../Common/UIKit/Form/Button';
import Map from '../../Common/UIKit/Map';
import BusinessMapSelector from '../../Common/Business/BusinessMapSelectorModal';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import BusinessLocationForm from '../../Common/Business/BusinessLocationForm';

// eslint-disable-next-line react/prop-types
const BusinessLocation = ({ headerEl }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [taxRate, setTaxRate] = useState(null);
  const { setBusiness, business: data, setHeader } = useContext(AppContext);
  const [, loading] = useApi(getBusiness, {}, setBusiness);
  const { handleSubmit, setError, control, watch, errors } = useForm();

  useEffect(() => {
    setHeader('Business / Location');
  }, [setHeader]);

  const handleSave = ({ province, zip_code, ...res }) => {
    let tax_rate = undefined;
    if (data?.province && province !== data?.province && taxRate) {
      tax_rate = taxRate;
    }
    handleApiSave(partialUpdateBusiness, null, { tax_rate, ...res, zip_code: zip_code.replaceAll(' ', '') }, null, {
      loadingFn: setSaveLoading,
      setError,
    })
      .then((data) => {
        toaster.notify(() => <div className="alert alert-success mt-3">Business Info Changed Successfully</div>);
        setBusiness(data);
      })
      .catch(() => {});
  };

  const defaultCenter = useMemo(() => {
    if (data) {
      const lat = +data.latitude;
      const lng = +data.longitude;
      if (lng === 0 || lat === 0) return null;
      return { lat, lng };
    }
    return null;
  }, [data]);

  const handleDone = () => {
    setRefresh(true);
    setMapModal(false);
    setTimeout(() => {
      setRefresh(false);
    }, 1000);
  };

  return (
    <>
      <HeadPage leftElement={headerEl} />
      {mapModal && <BusinessMapSelector onClose={() => setMapModal(false)} onDone={handleDone} />}
      {loading ? (
        <PageLoading />
      ) : (
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-7 col-lg-8">
            <div className="c-panel-content mb-0" style={{ width: '100%', height: 'calc(100vh - 185px)' }}>
              {refresh ? (
                <PageLoading />
              ) : (
                <Map
                  defaultCenter={defaultCenter ? defaultCenter : { lat: 51.041493, lng: -114.066613 }}
                  center={defaultCenter ? defaultCenter : { lat: 51.041493, lng: -114.066613 }}
                  marker={defaultCenter ? { center: defaultCenter } : undefined}
                />
              )}
              <Button
                color="success"
                sm
                style={{
                  position: 'absolute',
                  top: '20px',
                  left: '20px',
                }}
                onClick={() => setMapModal(true)}
              >
                Change Location
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-4 pr-0">
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="c-panel-content">
                <SectionHead title="Business Address Detail" />
                {refresh ? (
                  <PageLoading />
                ) : (
                  <>
                    <BusinessLocationForm
                      data={data}
                      control={control}
                      errors={errors}
                      watch={watch()}
                      onChangeProvince={(e) => setTaxRate(e.tax_rate)}
                    />
                    <Button className="mt-5" loading={saveLoading}>
                      Save Changes
                    </Button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessLocation;
