import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'toasted-notes/src/styles.css';
import 'rc-slider/assets/index.css';
import AppProvider from './context/providers';

function App() {
  return (
    <AppProvider>
      {/*<Provider store={store}>*/}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      {/*</Provider>*/}
    </AppProvider>
  );
}

export default App;
