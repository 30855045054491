import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, BusinessId, createURL } from '../config/apiConfig';

const { POST } = ApiMethods;

/**
 * @description switch business market place
 */
export const switchMarketplace = async () => {
  const response = await callApi({
    method: POST,
    locationBase: false,
    url: createURL(ApiRoutes.businessMarketplace.switch_marketplace, { id: BusinessId }),
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};
