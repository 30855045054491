import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CheckCircle, Block } from '@material-ui/icons';
import classNames from 'classnames';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import Button from '../../Common/UIKit/Form/Button';
import StaffMemberModal from '../../Common/Staff/StaffMember/StaffMemberModal';
import { getStaffList } from '../../../api';
import Pagination from '../../Common/UIKit/Pagination';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import useApi from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import ImageName from '../../Common/UIKit/ImageName';
import { ServiceTieredLevelChoice } from '../../../utilities/constants/choices';
import { TourGuide } from '../../Common/UIKit/TourContent';
import { staffMembersSteps } from '../../../config/tourSteps';
import usePermission from '../../../utilities/usePermission';
import StaffMemberViewModal from '../../Common/Staff/StaffMember/StaffMemberViewModal';

const URL_PAGE = '/staff/members/';

const StaffMembers = ({ headerEl }) => {
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);

  const [, loading] = useApi(getStaffList, { search: true, page: true, key: 'staff' }, setData);
  const { setHeader } = useContext(AppContext);
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const { access: _access } = usePermission('staff', 'crud');

  useEffect(() => {
    setHeader('Staff / Members', { videoId: 'LvPGbZtiK50', guide: staffMembersSteps });
  }, [setHeader]);

  useEffect(() => {
    if (!!data && !(location.pathname.includes('add') || params.id)) setIsTourOpen(true);
  }, [data, location]);

  useEffect(() => {
    if (params.id || location.pathname === `${URL_PAGE}add`) {
      handleOpenForm();
    }
  }, [params, location]);

  const handleOpenForm = () => {
    setShowForm(true);
    setIsTourOpen(false);
    setTourStep(2);
  };
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    if (opened) history.replace(URL_PAGE);

    if (tourStep === 2) {
      setIsTourOpen(true);
    }
  };

  const handleSaveItem = (item) => {
    if (typeof item === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    handleCloseForm();
    getStaffList().then(setData);
  };
  return (
    <>
      {params.id && showForm && !_access?.modify && _access?.view && <StaffMemberViewModal onClose={handleCloseForm} />}
      {showForm && _access?.modify && (
        <StaffMemberModal staffCount={data?.count || 0} onClose={handleCloseForm} onSave={handleSaveItem} />
      )}
      <HeadOptions
        leftElement={headerEl}
        rightElement={
          _access?.modify ? (
            <Button data-tour="add-btn" link={`${URL_PAGE}add`}>
              New Staff
            </Button>
          ) : undefined
        }
      />
      {_access?.view ? (
        <>
          {loading ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div data-tour="list" className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table c-table--clickable c-table--draggable">
                  <table>
                    <thead>
                      <tr>
                        <th>Avatar</th>
                        <th>Name</th>
                        <th className="text-center">Mobile number</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Tiered Level</th>
                        <th className="text-center">Note</th>
                        <th className="text-center">Is Active</th>
                        {/*<th>Tiered Level</th>*/}
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <tr
                          key={item.id}
                          id={`staff-${item.email}`}
                          className={classNames({
                            'c-table__item--disabled': !item.isActive,
                          })}
                          onClick={item.isActive ? () => history.push(`${URL_PAGE + item.id}/edit`) : undefined}
                        >
                          <td>
                            <ImageName
                              className="image"
                              name={item.name}
                              // imageSrc={item.avatar ? item.avatar: IMAGE_DEFAULT_USER}
                              imageSrc={item.avatar}
                            />
                          </td>
                          <td>
                            {item.name}
                            <br />
                            {item.is_stylist && <small>(Stylist)</small>}
                          </td>
                          <td className="text-center">{item.phone_number}</td>
                          <td className="text-center">{item.email}</td>
                          <td className="text-center">{ServiceTieredLevelChoice[item.tiered_level]}</td>
                          <td className="text-center">
                            <small>
                              {item.note.length > 50
                                ? `${item.note.substr(0, 50)}...`
                                : item.note.length !== 0
                                ? item.note
                                : '-'}
                            </small>
                          </td>
                          <td
                            className={classNames('text-center', {
                              'text-success': item.isActive,
                              'text-warning': !item.isActive,
                            })}
                          >
                            {item.isActive ? (
                              <CheckCircle className="font-size-20" />
                            ) : (
                              <Block className="font-size-20" />
                            )}
                          </td>
                          {/*<td>{ServiceTieredLevelChoice[item.tiered_level]}</td>*/}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Staff Exists"
              description="Use bottom button to add your first staff"
              icon="fas fa-user-plus"
              button={<Button link={`${URL_PAGE}add`}>ADD NEW STAFF</Button>}
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
      {false && (
        <TourGuide
          disableInteraction
          // disableInteraction={tourStep === 0}
          steps={staffMembersSteps}
          startAt={tourStep}
          getCurrentStep={setTourStep}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
        />
      )}
    </>
  );
};

StaffMembers.propTypes = {
  headerEl: PropTypes.any,
};

export default StaffMembers;
