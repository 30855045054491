import React from 'react';
import PropTypes from 'prop-types';
import SectionHead from '../UIKit/SectionTitle';
import Checkbox from '../UIKit/Form/Checkbox';

const BusinessBookingMethod = ({ data, onChange }) => (
  <div className="c-panel-content">
    <SectionHead title="Booking Methods" />
    <div className="alert alert-warning">
      1. Instant booking: With this option, Beautster allows your customers to directly select and book the time slot
      that suits them best.
      <br />
      2. 3-time slots request: We ask your customers to provide 3 time slots availability. Afterwards, you&apos;ll have
      to assign this appointment request to the professional you want, according to the requested time slots.
    </div>
    <Checkbox
      name="method"
      type="radio"
      id="instant"
      defaultChecked={data?.booking_mode === 'instant'}
      onChange={() => onChange('instant')}
      label="Instant Booking"
    />
    <Checkbox
      name="method"
      type="radio"
      id="request"
      defaultChecked={data?.booking_mode === '3_time'}
      onChange={() => onChange('3_time')}
      label="Request a Time (Confirmation is Required)"
    />
  </div>
);

BusinessBookingMethod.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func,
};

export default BusinessBookingMethod;
