export const getParamsWithImage = (params, imageName = 'avatar') => {
  console.log('params', params[imageName]);
  if (!!params[imageName]) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (!!params[key] && Array.isArray(params[key])) {
        (params[key] || []).forEach((i) => formData.append(`${key}[]`, i));
      } else if (!!params[key] && key !== imageName) formData.append(key, params[key]);
    });
    formData.append(imageName, params[imageName].file, params[imageName].file.name);
    return formData;
  }
  return params;
};

export const getParamsWithPhone = (params) => {
  const phone_number = params?.phone_number ? params?.phone_number.replace(/(-|\(|\))/g, '') : undefined;
  const mobile = params?.mobile ? params?.mobile.replace(/(-|\(|\))/g, '') : undefined;
  return {
    ...params,
    phone_number: phone_number || undefined,
    mobile: mobile || undefined,
  };
};

export const getParams = (params, imageName = 'avatar') => {
  const params1 = getParamsWithPhone(params);
  return getParamsWithImage(params1, imageName);
};
