import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../Form/Button';
import Modal from '../Modal';
import Select from '../Form/Select';
import { getServiceList, getStaffList } from '../../../../api';
import useApi from '../../../../utilities/useApi';
import { getQueryUri, setQueryUri } from '../../../../utilities/URI';
import DatePicker from '../Form/DatePicker';
import { getDateFromString, getStringFromDate } from '../../../../utilities/DateHelpers';
import { AppointmentStatusChoice, InvoiceStatusChoice } from '../../../../utilities/constants/choices';
import { useIsDesktop } from '../../../../utilities/useIsDesktop';

const DateRange = ({ onChange, value }) => {
  const isDesktop = useIsDesktop();
  const [dateRange, setDateRange] = useState(0);
  if (!value) {
    value = [];
    value[0] = new Date();
    value[1] = new Date();
    // value[1] = new Date().setDate(value[0].getDate()+7);
  }

  const dateRangeOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 days', value: '7days' },
    { label: 'Last 30 days', value: '30days' },
    { label: 'Last 90 days', value: '90days' },
    { label: 'Last month', value: 'last-month' },
    { label: 'Last year', value: 'last-year' },
    // { label: 'Week to date', value: 'week-to-date' },
    // { label: 'Month to date', value: 'month-to-date' },
  ];

  const handleChangeSelect = (val) => {
    const start_date = new Date();
    const end_date = new Date();
    switch (val) {
      case 'yesterday':
        start_date.setDate(start_date.getDate() - 1);
        end_date.setDate(end_date.getDate() - 1);
        break;
      case '7days':
        start_date.setDate(start_date.getDate() - 6);
        break;
      case '30days':
        start_date.setDate(start_date.getDate() - 30);
        break;
      case '90days':
        start_date.setDate(start_date.getDate() - 90);
        break;
      case 'last-month':
        start_date.setDate(1);
        start_date.setMonth(start_date.getMonth() - 1);
        end_date.setDate(1);
        end_date.setDate(end_date.getDate() - 1);
        break;
      case 'last-year':
        start_date.setDate(1);
        start_date.setMonth(0);
        start_date.setFullYear(start_date.getFullYear() - 1);
        end_date.setDate(1);
        end_date.setMonth(0);
        end_date.setDate(end_date.getDate() - 1);
        break;
    }
    onChange([start_date, end_date]);
    setDateRange(val);
  };

  const handleChangeDate = (date) => {
    onChange(date);
  };

  return (
    <>
      <Select
        formGroup
        id="dateRange"
        label="Date range"
        options={dateRangeOptions}
        value={dateRange}
        onChangeValue={handleChangeSelect}
      />
      <DatePicker
        iconLabel={false}
        arrowLabel={false}
        selected={value[0]}
        startDate={value[0]}
        endDate={value[1]}
        onChange={handleChangeDate}
        selectsRange
        inline
        monthsShown={isDesktop ? 2 : 1}
        customHeader={false}
      />
    </>
  );
};

DateRange.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

const Service = ({ onChange, value }) => {
  const [data] = useApi(getServiceList);
  const options = data ? data.results.map((i) => ({ label: i.name, value: i.id })) : [];
  return (
    <Select
      id="filter-service"
      label="Service"
      options={[{ label: 'Select Service', value: null }, ...options]}
      onChangeValue={onChange}
      defaultValue={value}
      value={value}
    />
  );
};
Service.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

const Staff = ({ onChange, value }) => {
  const [data] = useApi(getStaffList);
  const options = data ? data.results.map((i) => ({ label: i.name, value: i.id })) : [];
  return (
    <Select
      id="filter-staff"
      label="Staff"
      options={[{ label: 'Select Staff', value: null }, ...options]}
      onChangeValue={onChange}
      defaultValue={value}
      value={value}
    />
  );
};
Staff.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

const Status = ({ onChange, value, menuPlacement = 'top' }) => {
  const options = Object.keys(AppointmentStatusChoice).map((val) => ({
    label: AppointmentStatusChoice[val],
    value: val,
  }));
  return (
    <Select
      id="filter-status"
      label="Status"
      menuPlacement={menuPlacement}
      maxMenuHeight={200}
      options={[{ label: 'Select Status', value: null }, ...options]}
      onChangeValue={onChange}
      defaultValue={value}
      value={value}
    />
  );
};
Status.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  menuPlacement: PropTypes.string,
};

const InvoiceStatus = ({ onChange, value, menuPlacement = 'top' }) => {
  const options = Object.keys(InvoiceStatusChoice).map((val) => ({ label: InvoiceStatusChoice[val], value: val }));
  return (
    <Select
      id="filter-invoice-status"
      label="Invoice Status"
      menuPlacement={menuPlacement}
      maxMenuHeight={200}
      options={[{ label: 'Select Status', value: null }, ...options]}
      onChangeValue={onChange}
      defaultValue={value}
      value={value}
    />
  );
};
InvoiceStatus.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  menuPlacement: PropTypes.string,
};

const RenderItems = (props) => {
  const onChangeDate = (val) => props.onChange({ date: val });
  const onChangeStaff = (val) => props.onChange({ staff: val });
  const onChangeStatus = (val) => props.onChange({ status: val });
  const onChangeService = (val) => props.onChange({ service: val });

  const getData = (key) => (!!props.data[key] ? props.data[key] : null);

  return (
    <>
      {props.date && <DateRange value={getData('date')} onChange={onChangeDate} />}
      {props.staff && <Staff value={getData('staff')} onChange={onChangeStaff} />}
      {props.invoiceStatus ? (
        <InvoiceStatus
          menuPlacement={props.date || props.staff ? 'top' : 'bottom'}
          value={getData('status')}
          onChange={onChangeStatus}
        />
      ) : (
        props.status && (
          <Status
            menuPlacement={props.date || props.staff ? 'top' : 'bottom'}
            value={getData('status')}
            onChange={onChangeStatus}
          />
        )
      )}
      {props.service && <Service value={getData('service')} onChange={onChangeService} />}
    </>
  );
};
RenderItems.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.any,
  date: PropTypes.any,
  staff: PropTypes.any,
  invoiceStatus: PropTypes.any,
  status: PropTypes.any,
  service: PropTypes.any,
};

const Filters = ({ className, ...props }) => {
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = getQueryUri();
    let date;
    if (params.from_date && params.to_date)
      date = [getDateFromString(params.from_date), getDateFromString(params.to_date)];
    else if (!params.from_date && params.to_date)
      date = [getDateFromString(params.to_date), getDateFromString(params.to_date)];
    else if (!params.to_date && params.from_date)
      date = [getDateFromString(params.from_date), getDateFromString(params.from_date)];
    const initialData = {
      status: params.status,
      date,
      service: params?.service ? +params.service : undefined,
      staff: params?.staff ? +params.staff : undefined,
    };
    setData(initialData);
    setCount(Object.keys(initialData).reduce((t, c) => t + (!!initialData[c] ? 1 : 0), 0));
  }, [location.search]);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleOnSave = () => {
    setCount(Object.keys(data).reduce((t, c) => t + (!!data[c] ? 1 : 0), 0));

    let newQuery = {};
    if (data.date)
      newQuery = { ...newQuery, from_date: getStringFromDate(data.date[0]), to_date: getStringFromDate(data.date[1]) };
    if (data.staff) newQuery = { ...newQuery, staff: data.staff };
    if (data.service) newQuery = { ...newQuery, service: data.service };
    if (data.status) newQuery = { ...newQuery, status: data.status };
    const newPath = location.pathname + setQueryUri(newQuery);
    history.push(newPath);
    handleClose();
    // onSave(data);
  };
  const handleOnChange = (item) => {
    const updateData = { ...data, ...item };

    setData(updateData);
  };
  const handleOnClear = () => {
    setCount(0);
    setData({});
    const newPath = location.pathname + setQueryUri({});
    history.push(newPath);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        sticky
        bodyClass="min-vh-30"
        onClose={handleClose}
        auto={!!props.date}
        saveBtnText="Apply"
        buttonComponent={
          <Button color="light" onClick={handleOnClear}>
            Clear
          </Button>
        }
        onSave={handleOnSave}
        title="Filters"
      >
        {/*<div className="py-5">*/}
        {/*    <RenderItems {...props} data={data} onChange={handleOnChange} />*/}
        {/*</div>*/}
        <RenderItems {...props} data={data} onChange={handleOnChange} />
      </Modal>
      <Button id="filterBtn" color="light" onClick={handleOpen} className={className}>
        {count > 0 && <div className="badge badge-pill badge-info">{count}</div>}
        <span className="mr-3 ml-2">Filters</span>
        <i className="fas fa-sliders-h" />
      </Button>
    </>
  );
};

Filters.propTypes = {
  date: PropTypes.bool,
  staff: PropTypes.bool,
  invoiceStatus: PropTypes.bool,
  status: PropTypes.bool,
  service: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.any,
};

export default Filters;
