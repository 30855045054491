import React from 'react';
import PropTypes from 'prop-types';
import RefundLineService from './RefundLine/RefundLineService';
import RefundLineGiftCard from './RefundLine/RefundLineGiftCard';

const RefundLines = ({ lines }) => (
  <div className="c-checkout-lines">
    {lines.map((line, ind) =>
      line.item_type === 'service' ? (
        <RefundLineService key={ind} line={line} />
      ) : (
        <RefundLineGiftCard key={ind} line={line} index={ind} />
      ),
    )}
  </div>
);

RefundLines.propTypes = {
  lines: PropTypes.array,
};

export default RefundLines;
