import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import YouTube from 'react-youtube';

const VideoTutorialModal = ({ title, videoId, onClose }) => {
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <Modal show auto title={title || 'Video Tutorial'} onClose={onClose} saveBtn={false}>
      <YouTube
        videoId={videoId}
        className="c-panel-header__info-player"
        opts={{
          height: '100%',
          width: '100%',
          playerVars: {
            //https://developers.google.com/youtube/player_parameters
            autoplay: 1,
          },
        }}
        onReady={onReady}
      />
    </Modal>
  );
};

VideoTutorialModal.propTypes = {
  title: PropTypes.string,
  videoId: PropTypes.string,
  onClose: PropTypes.func,
};

export default VideoTutorialModal;
