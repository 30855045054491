import { callApi } from '../../utilities/ApiHandler';
import { ApiMethods, ApiRoutes } from '../../config/apiConfig';
import StylistProfileObject from '../../objects/StylistProfile/StylistProfileObject';
import { getParams } from '../../utilities/ParamHelper';
const { GET, POST, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *    first_name: String,
 *    last_name: String,
 *    avatar: String,
 *    experiences: ExperienceObject[],
 *    educations: EducationObject[],
 *    certificates: CertificateObject[],
 *    city: String,
 *    job_title: String,
 *    headline: String,
 *    professional_summary: String,
 *    province: String,
 *    isEdit: Boolean,
 * }}
 */
export const saveStylistProfile = async ({ isEdit = false, ...params }) => {
  const response = await callApi({
    method: isEdit ? PATCH : POST,
    url: ApiRoutes.stylistProfile,
    params: getParams(params),
    absolute: true,
    headers: !!params.avatar
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new StylistProfileObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const getStylistProfile = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistProfile,
  });
  if (response.data) {
    return new StylistProfileObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const deleteStylistProfile = async () => {
  const response = await callApi({
    method: DELETE,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistProfile,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
