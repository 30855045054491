import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Close } from '@material-ui/icons';
import { getPriceFormat } from '../../../utilities/Price';
import { InvoicePaymentTypeChoice, TipUnitTypeChoice } from '../../../utilities/constants/choices';

const RefundSummary = ({ data, pays, onRemove }) => {
  const payments = data.transactions.filter((i) => i.type === 'payment');
  const refunds = data.transactions.filter((i) => i.type === 'refund');
  return (
    <div className="c-checkout-summary">
      {pays
        .reduce((acc, i) => {
          const existsInd = acc.findIndex((j) => j.key === i.key && i.paid === j.paid);
          if (existsInd > -1) {
            const item = acc[existsInd];
            acc.splice(existsInd, 1);
            return [
              ...acc,
              {
                ...i,
                price: +item.price + +i.price,
              },
            ];
          }
          return [...acc, i];
        }, [])
        .map(({ key, price, paid, code }) => (
          <div
            className={classnames('c-checkout-summary__item c-checkout-summary__item--refund', {
              'c-checkout-summary__item--refunded': paid,
            })}
            key={key}
          >
            <span className="c-checkout-summary__item-key">
              {InvoicePaymentTypeChoice[key]} <small>(Refund)</small>
              {key === 'gift_card' && !!code && <span className="c-checkout-summary__item-code">{code}</span>}
            </span>
            <span className="c-checkout-summary__item-info">
              <span className="c-checkout-summary__item-value">{getPriceFormat(+price)}</span>
              {!paid && (
                <span className="c-checkout-summary__item-remove" onClick={() => onRemove(key)}>
                  <Close />
                </span>
              )}
            </span>
          </div>
        ))}

      {refunds.map(({ method, amount, voucher_code }) => (
        <div className="c-checkout-summary__item c-checkout-summary__item--refunded" key={method}>
          <span className="c-checkout-summary__item-key">
            {InvoicePaymentTypeChoice[method]} <small>(Refunded)</small>
            {!!voucher_code && <span className="c-checkout-summary__item-code">{voucher_code}</span>}
          </span>
          <span className="c-checkout-summary__item-info">
            <span className="c-checkout-summary__item-value">{getPriceFormat(+amount)}</span>
          </span>
        </div>
      ))}
      <div className="c-checkout-summary__item">
        <span className="c-checkout-summary__item-key">SubTotal</span>
        <span className="c-checkout-summary__item-value">{getPriceFormat(data.subtotal)}</span>
      </div>
      {data.tips.map(({ staff_name, amount, type, id }) => (
        <div className="c-checkout-summary__item c-checkout-summary__item--tip" key={id}>
          <span className="c-checkout-summary__item-key">{staff_name}</span>
          <span className="c-checkout-summary__item-info">
            <span className="c-checkout-summary__item-value">{getPriceFormat(+amount, TipUnitTypeChoice[type])}</span>
          </span>
        </div>
      ))}
      <div className="c-checkout-summary__item">
        <span className="c-checkout-summary__item-key">Tax</span>
        <span className="c-checkout-summary__item-value">
          {getPriceFormat(+data.tax_amount)} ({getPriceFormat(+data.tax_rate, '%')})
        </span>
      </div>
      <div className="c-checkout-summary__item">
        <span className="c-checkout-summary__item-key">Total</span>
        <span className="c-checkout-summary__item-value">{getPriceFormat(+data.total + +data.balance)}</span>
      </div>
      <div className="c-checkout-summary__item c-checkout-summary__item--paid">
        <span className="c-checkout-summary__item-key">Paid</span>
        <span className="c-checkout-summary__item-value">{getPriceFormat(+data.total)}</span>
      </div>
      {payments.map(({ method, amount, voucher_code }) => (
        <div className="c-checkout-summary__item c-checkout-summary__item--paid" key={method}>
          <span className="c-checkout-summary__item-key">
            {InvoicePaymentTypeChoice[method]} <small>(Paid)</small>
            {!!voucher_code && <span className="c-checkout-summary__item-code">{voucher_code}</span>}
          </span>
          <span className="c-checkout-summary__item-info">
            <span className="c-checkout-summary__item-value">{getPriceFormat(+amount)}</span>
          </span>
        </div>
      ))}
      <div className="c-checkout-summary__item c-checkout-summary__item--balance">
        <span className="c-checkout-summary__item-key">Balance</span>
        <span className="c-checkout-summary__item-value">{getPriceFormat(+data.balance)}</span>
      </div>
    </div>
  );
};

RefundSummary.propTypes = {
  data: PropTypes.any,
  pays: PropTypes.array,
  onRemove: PropTypes.func,
};

export default RefundSummary;
