import { getDateFromString, getSumDuration } from '../utilities/DateHelpers';

/**
 * @param line {{
 *     id
 *     duration,
 *     discount_amount
 *     original_price
 *     price
 *     reserved_date,
 *     reserved_time,
 *     suggested_times,
 *     service
 *     service_name
 *     deal
 *     lmo
 *     service_discount_type
 *     staff
 *     staff_name
 *     created_at
 *     updated_at
 * }},
 * @param appointment,
 * @constructor
 */

function AppointmentLineObject(line, appointment) {
  if (appointment) {
    this.appointmentId = appointment.id;
    this.appointmentStatus = appointment.status;
    this.invoice = appointment.invoice || null;
    this.payment_status = appointment.payment_status || null;
    this.sale_channel = appointment.sale_channel;
    this.client = appointment.client;
    this.client_name = appointment.client_name;
    this.client_email = appointment.client_email;
    this.reserved_date = appointment.reserved_date;
    this.note = appointment.note;
    this.reserved_date = appointment.reserved_date;
    this.start = getDateFromString(appointment.reserved_date, line.reserved_time);
    // this.end = getDateFromString(appointment.reserved_date, line.reserved_time, line.duration);
    this.end = getDateFromString(
      appointment.reserved_date,
      line.reserved_time,
      getSumDuration(line.duration, line.extra_time, false),
    );
  }

  this.title = line ? line.service_name : null;
  this.resourceId = line ? line.staff : null;

  this.id = line.id;
  this.duration = line.duration;
  this.discount_amount = line.discount_amount;
  this.original_price = line.original_price;
  this.price = line.price;

  this.reserved_time = line.reserved_time;
  this.suggested_times = line.suggested_times;
  this.service = line.service;
  this.service_name = line.service_name;
  this.deal = line.deal;
  this.lmo = line.lmo;
  this.service_discount_type = line.service_discount_type;
  this.staff = line.staff;
  this.staff_name = line.staff_name;
  this.created_at = line.created_at;
}

export default AppointmentLineObject;
