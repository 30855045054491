import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { FileCopy } from '@material-ui/icons';
import { IMAGE_DEFAULT_USER, IMAGE_LOGO_WHITE_IMG } from '../../../assets/Images';
import { DealCampaignAudienceChoice, DealExpireAfterChoice } from '../../../utilities/constants/choices';
import { getPriceFormat } from '../../../utilities/Price';
import { PageLoading } from '../UIKit/Spinner';
import { AppContext } from '../../../context/providers';

const PromotionGiftCard = ({
  loading = false,
  code,
  subject,
  value: _value,
  price: _price,
  limit = null,
  expireAfter,
  expireDate = null,
  emailTemplate = true,
  emailSubject,
  emailDescription,
  campaignAudience,
}) => {
  const { business, isMarketplace } = useContext(AppContext);
  // price = price ? price : 20;
  // value = value ? (+value < +price) ? +value : price : 10;
  const value = useMemo(() => (_value ? +_value : 0), [_value]);
  const price = useMemo(() => (_price ? (+_price < +value ? +_price : value) : 0), [_price]);
  // limit = limit ? limit : 0;
  const handleCopy = () => {
    if (code) {
      navigator.clipboard.writeText(code);
      toaster.notify(() => <div className="alert alert-success">Copied Code</div>);
    }
  };

  // const cardStyleNum = (Math.ceil((value / price) * 10) + Math.ceil((value / price) * 10) % 2) * _10;
  // const cardStyleNum = (Math.ceil((price / value) * 10) + Math.ceil((price / value) * 10) % 2) * 10;
  const cardStyleNum = (Math.ceil((price / value) * 10) + (Math.ceil((price / value) * 10) % 2)) * 10;

  const toEmail = DealCampaignAudienceChoice[campaignAudience];
  const giftCard = (
    <div className={`c-gift-card c-gift-card--${cardStyleNum}`}>
      {loading && <PageLoading over />}
      <div className="c-gift-card__body">
        <h3 className="c-gift-card__title">{subject ? subject : 'Subject Promotion'}</h3>
        {/*<div className="c-gift-card__description">*/}
        {/*    <p>{description ? description : 'Description Promotion'}</p>*/}
        {/*</div>*/}

        {code && (
          <h2 className="c-gift-card__code">
            {code}
            <span className="c-gift-card__code-copy" onClick={handleCopy}>
              <FileCopy />
            </span>
          </h2>
        )}

        <img src={IMAGE_LOGO_WHITE_IMG} alt={subject ? subject : 'Subject Promotion'} />

        <div className="c-gift-card__price-wrapper">
          <span className="c-gift-card__discount">{getPriceFormat(value)}</span>
          {!!price && (
            <span className="c-gift-card__change-price">
              {/*<span className="c-gift-card__old-price">Retail price of this gift card {price}$</span>*/}
              <span className="c-gift-card__price">Retail price of this gift card for {getPriceFormat(price)}</span>
              {/*<span className="c-gift-card__price">{totalPrice}$</span>*/}
            </span>
          )}
        </div>
        {limit !== null && (
          <span className="c-gift-card__meta">Total number of usage with this gift card: {limit}</span>
        )}
        {expireAfter && <span className="c-gift-card__meta">Expire after: {DealExpireAfterChoice[expireAfter]}</span>}
        {expireDate && <span className="c-gift-card__meta">Expire date: {expireDate}</span>}
      </div>
    </div>
  );

  if (!emailTemplate || isMarketplace) return giftCard;

  return (
    <div className="c-promotion__email">
      <div className="c-promotion__email-header">
        <h1 className="c-promotion__email-subject">{emailSubject}</h1>
        <div className="c-promotion__email-info">
          <img src={IMAGE_DEFAULT_USER} alt="" style={{ width: '50px' }} />
          <div>
            <span>{business ? business?.title : 'Your business name'}</span>
            &#60;<span>beautster@beautster.com</span>&#62;
            <div className="c-promotion__email-to">to {toEmail ? toEmail : 'All clients'}</div>
          </div>
        </div>
      </div>
      <div className="c-promotion__email-body">
        <div className="c-promotion__email-description">
          {emailDescription ? emailDescription : 'Description Promotion'}
        </div>
        {giftCard}
      </div>
    </div>
  );
};

PromotionGiftCard.propTypes = {
  imageUrl: PropTypes.any,
  subject: PropTypes.string,
  description: PropTypes.string,
  discount: PropTypes.string,
  emailSubject: PropTypes.string,
  emailDescription: PropTypes.string,
  campaignAudience: PropTypes.string,
  loading: PropTypes.bool,
  code: PropTypes.string,
  value: PropTypes.string,
  price: PropTypes.string,
  limit: PropTypes.number,
  expireAfter: PropTypes.any,
  expireDate: PropTypes.any,
  emailTemplate: PropTypes.bool,
};

export default PromotionGiftCard;
