import React, { useContext, useState } from 'react';
import Modal from '../../UIKit/Modal';
import { ControlInput } from '../../UIKit/Form/Input';
import { useForm } from 'react-hook-form';
import TokenFormModal from './TokenFormModal';
import { handleApiSave } from '../../../../utilities/useApi';
import { changeIdentity } from '../../../../api';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const MobileFormModal = ({ onClose, onSave }) => {
  const [formToken, setFormToken] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const { user } = useContext(AppContext);
  const { control, errors, handleSubmit, setError } = useForm();

  const handleSave = ({ mobile }) => {
    handleApiSave(changeIdentity, null, { new_identity: mobile, identity_type: 'mobile' }, null, {
      loadingFn: setSaveLoading,
      setError,
    })
      .then((res) => onSave(res))
      .catch(() => {});
  };

  const handleClose = () => {
    if (formToken) {
      setFormToken(false);
    } else {
      onClose();
    }
  };

  const handleSaveToken = () => {
    setTokenLoading(true);
    onClose();
    setFormToken(false);
    setTokenLoading(false);
  };

  return (
    <>
      <TokenFormModal
        show={formToken}
        loading={tokenLoading}
        onClose={() => setFormToken(false)}
        onSave={handleSaveToken}
      />
      <Modal
        show={!formToken}
        title="Change Mobile"
        saveLoading={saveLoading}
        onSave={handleSubmit(handleSave)}
        saveBtnText="Submit Mobile"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="alert alert-warning my-3">Please Enter your mobile number correctly</div>
          <ControlInput
            name="mobile"
            control={control}
            rules={{ required: 'Mobile is required' }}
            defaultValue={user ? user?.user.mobile : ''}
            type="phone"
            label={'Mobile'}
            placeholder={'Mobile'}
            errorMessage={errors?.mobile?.message}
          />
        </form>
      </Modal>
    </>
  );
};

export default MobileFormModal;
