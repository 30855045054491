import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckoutModal from '../../Checkout/CheckoutModal';
import FormModal from './AppointmentFormModal';
import RefundModal from '../../Refund/RefundModal';

const AppointmentFormModal = ({ onClose, onSave, extraData = null }) => {
  const [showCheckout, setShowCheckout] = useState(false);
  const [showRefund, setShowRefund] = useState(null);
  const [data, setData] = useState(null);
  const handleSave = (_data, isCheckout) => {
    if (isCheckout) {
      setData(_data);
      setShowCheckout(true);
    } else {
      onSave(_data);
    }
  };

  const handleCloseCheckout = () => {
    setShowCheckout(false);
    onSave(data);
  };

  const handleRefund = (invoiceId) => {
    setShowRefund(invoiceId);
  };

  return (
    <>
      {showCheckout && (
        <CheckoutModal
          id={data?.id}
          invoiceId={data.invoice}
          onClose={handleCloseCheckout}
          onSave={handleCloseCheckout}
        />
      )}
      {!showRefund && !showCheckout && (
        <FormModal
          onClose={onClose}
          onSave={handleSave}
          // onCheckout={() => setShowCheckout(true)}
          onCheckout={handleSave}
          onRefund={handleRefund}
          extraData={extraData}
        />
      )}
      {showRefund && (
        <RefundModal id={showRefund} onClose={() => setShowRefund(null)} onSave={() => onSave('refresh')} />
      )}
    </>
  );
};

AppointmentFormModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  extraData: PropTypes.any,
};

export default AppointmentFormModal;
