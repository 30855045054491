import React, { useContext, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../Common/UIKit/Form/Button';
import { AppContext } from '../../../context/providers';

const links = [
  {
    id: 'business',
    access: 'both',
    title: 'Business Profile',
    description:
      'Manage your business profile including your Business Name, Description, Address Social Accounts, etc.',
    link: '/settings/business-profile',
  },
  {
    id: 'close-date',
    access: 'both',
    title: 'Business Close Date',
    description: 'Manage your business close date',
    link: '/settings/closed-dates',
  },
  // {
  //   id: 'permission',
  //   access: 'both',
  //   title: 'Access Level',
  //   description: 'You can handle and modify user permission for each access level of users in here',
  //   link: '/settings/permissions',
  // },
  {
    id: 'subscription',
    access: 'software',
    title: 'My Subscription',
    description: 'Edit your Subscription Plan, view your Billing History',
    link: '/subscription',
  },
  {
    id: 'payment',
    access: 'both',
    title: 'Payment',
    description: 'View billing statements including detailed transaction history',
    link: '/settings/payment',
  },
  // {
  //     id: 'preferences',
  //     access: 'both',
  //     title: 'Communications Preferences',
  //     description: 'Manage notifications. notified about your own appointments only, or appointments booked with all staff members.',
  //     link: '/settings/communications',
  // },
  // {
  //     id: 'billing',
  //     access: 'software',
  //     title: 'Billing History',
  //     description: 'View your billing statements and detailed transaction history',
  // },
  // {
  //     id: 'penalty',
  //     access: 'marketplace',
  //     title: 'Payment Penalty',
  //     description: 'You can set penalty for no-show or cancelling appointment',
  //     link: '/settings/payment/business',
  // },
  {
    id: 'marketplace',
    access: 'marketplace',
    title: 'Marketplace',
    description: 'You can control your marketplace here',
    link: '/settings/marketplace',
  },
  {
    id: 'location',
    access: 'both',
    title: 'Location',
    description: 'You can control your locations here',
    link: '/settings/locations',
  },
].filter((i) => i);

const SettingsPage = () => {
  const { isMarketplace } = useContext(AppContext);
  const { setHeader } = useContext(AppContext);
  useEffect(() => {
    setHeader('Settings');
  }, [setHeader]);

  const data = useMemo(() => {
    if (isMarketplace) {
      return links.filter((i) => i.access !== 'software');
    } else {
      return links.filter((i) => i.access !== 'marketplace');
    }
  }, [isMarketplace]);
  return (
    <>
      {/*<HeadPage title={'Settings'} />*/}

      <div className="c-panel-content mt-3 c-settings">
        {data.map((item, key) => (
          <div className="c-settings-item" key={key}>
            <div className="c-settings-item__detail">
              {item?.link ? (
                <NavLink id={`link-${item?.id}`} to={item.link} className="c-settings-item__title">
                  {item.title}
                </NavLink>
              ) : (
                <span className="c-settings-item__title">{item.title}</span>
              )}

              <p className="c-settings-item__description">{item.description}</p>
            </div>
            <Button id={`manageBtn-${item?.id}`} link={item?.link ? item.link : undefined} sm color="info">
              Manage
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default SettingsPage;
