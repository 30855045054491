import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { AppContext } from '../../../context/providers';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getBusiness, getServiceList, switchMarketplace } from '../../../api';
import toaster from 'toasted-notes';
import { confirmAlert } from 'react-confirm-alert';
import { PageLoading } from '../UIKit/Spinner';
import { MARKETPLACE_URL } from '../../../config/appConfig';
import Switch from '../UIKit/Form/Switch';

const PublishMarketplaceModal = ({ title, description, onClose }) => {
  const [isPublished, setIsPublished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPublishable, setIsPublishable] = useState(false);

  const { setBusiness, business, userPermissions: p } = useContext(AppContext);

  useEffect(() => {
    handleApi(getServiceList, { limit: 1 }).then((res) => {
      setIsPublishable(res.count > 0);
    });
    setIsPublished(business?.is_published);
  }, [business]);

  const _access = useMemo(
    () =>
      p?.business?.marketplace_switch?.modify || p?.business?.marketplace_switch?.view
        ? p.business.marketplace_switch
        : false,
    [p],
  );

  const onSwitchMarketPlace = () => {
    handleApiSave(switchMarketplace, null, {}, null, { loadingFn: setLoading })
      .then(() => {
        toaster.notify(() => (
          <div className="alert alert-success mt-3">
            Your business has been {isPublished ? 'left from' : 'joined to'} marketplace Successfully
          </div>
        ));
        setIsPublished(!isPublished);
        getBusiness().then(setBusiness);
        onClose();
      })
      .catch(() => {});
  };
  const onChangePublish = () => {
    if (isPublished) {
      confirmAlert({
        title: 'Leave Marketplace',
        message:
          'Are you ABSOLUTELY sure? This action cannot be undone. Your business will be temporarily deleted from Beautster’s marketplace',
        buttons: [
          {
            label: 'Yes Leave',
            className: 'bg-danger',
            onClick: onSwitchMarketPlace,
          },
          {
            label: 'No Keep',
            onClick: onClose,
          },
        ],
      });
    } else {
      if (isPublishable) {
        onSwitchMarketPlace();
      } else {
        confirmAlert({
          title: 'Publish Marketplace',
          message: 'Your business does not have any service, please add your first service.',
          buttons: [
            {
              label: 'Dismiss',
              className: 'bg-danger',
              onClick: onClose,
            },
            {
              label: 'Add Service',
              className: 'bg-success',
              onClick: onClose,
            },
          ],
        });
      }
    }
  };
  return _access ? (
    <Modal show title={title} description={description} onClose={onClose} saveBtn={false}>
      {loading && <PageLoading over />}
      {isPublished ? (
        <div className="c-settings-marketplace">
          <h3 className="text-success">Your Business Is Up</h3>

          <p className="c-settings-marketplace__p">Clients can see your business on marketplace</p>

          <p className="c-settings-marketplace__p">
            Your page link:
            <a href={`${MARKETPLACE_URL}/business/${business?.slug}`} target="_blank" rel="noreferrer" className="ml-2">
              {MARKETPLACE_URL}/business/{business?.slug}
            </a>
          </p>

          <p className="c-settings-marketplace__p">
            If you want to stop sharing your business on marketplace, you can use the switch in the below
          </p>
        </div>
      ) : (
        <div className="c-settings-marketplace">
          <h3 className="text-danger">Your Business Is Down</h3>

          <p className="c-settings-marketplace__p">
            Clients <span className="text-danger">cannot</span> see your business on marketplace
          </p>

          <p className="c-settings-marketplace__p">
            Your page link:
            <a href={`${MARKETPLACE_URL}/business/${business?.slug}`} target="_blank" rel="noreferrer" className="ml-2">
              {MARKETPLACE_URL}/business/{business?.slug}
            </a>
          </p>

          <p className="c-settings-marketplace__p">
            If you want to share your business on marketplace, you can use the switch in the below
          </p>
        </div>
      )}
      {_access?.modify && (
        <Switch checked={isPublished} label="Publish To The Marketplace" formGroup onChange={onChangePublish} />
      )}
    </Modal>
  ) : null;
};

PublishMarketplaceModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
};

PublishMarketplaceModal.defaultProps = {
  title: 'Publish Marketplace',
  description: 'Your Business Is Down',
};

export default PublishMarketplaceModal;
