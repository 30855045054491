/**
 *
 * @param client {{
 *     id,
 *     user,
 *     full_name,
 *     name,
 *     mobile,
 *     phone_number,
 *     phone,
 *     customer_mobile,
 *     customer_phone,
 *     email,
 *     note,
 *     customer_email,
 *     notification_setting,
 *     last_appointment_date,
 *     appointments_count
 *     cancelled_count
 *     cancelled_appointments_count
 *     no_shows_count
 *     no_show_appointments_count
 *     fulfilled_count
 *     fulfilled_appointments_count
 *     total_sales
 *     is_active
 *     birthday_day
 *     birthday_month
 *     register_channel
 * }}
 * @constructor
 */

function ClientObject(client) {
  this.id = client.id;
  this.avatar = client?.profile?.avatar || client?.profile?.default_avatar_url || null;
  this.user = client?.user || client?.profile?.user || null;
  this.profile = client?.profile || null;
  this.name = client.full_name || client.name;
  this.full_name = client.full_name || client.name;
  const mobile = client.phone_number || client.mobile || client?.profile?.phone_number || client?.profile?.user?.mobile;
  this.phone = mobile === '+1' ? '' : mobile;
  this.phone_number = mobile === '+1' ? '' : mobile;
  this.email = client.email || client?.profile?.email;
  this.note = client.note;
  this.appointments_count = client.appointments_count;
  this.last_appointment_date = client.last_appointment_date;
  this.cancelled_count =
    client.cancelled_count || client.cancelled_appointments_count || client.canceled_appointments_count;
  this.no_shows_count = client.no_shows_count || client.no_show_appointments_count;
  this.total_sales = client.total_sales;
  this.fulfilled_count = client.fulfilled_count || client.fulfilled_appointments_count;
  this.is_active = client.is_active;
  this.birthday_day = client.birthday_day;
  this.birthday_month = client.birthday_month;
  this.register_channel = client.register_channel;
  this.notification =
    client.notification_setting === 'all'
      ? 'Email & Sms'
      : client.notification_setting === 'email'
      ? 'Email'
      : client.notification_setting === 'sms'
      ? 'Sms'
      : null;
  this.notification_setting = client.notification_setting;
}

export default ClientObject;
