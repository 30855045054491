import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Close } from '@material-ui/icons';
import { ControlTimePicker } from '../../UIKit/Form/TimePicker';
import { ControlSelect } from '../../UIKit/Form/Select';
import { DurationChoice, getValueLabel } from '../../../../utilities/constants/choices';
import { ControlServiceSelect } from '../../Selections/ServiceSelect';
import { ControlStaffSelect } from '../../Selections/StaffSelect';

// control, line, errors, onAddLine, onRemoveLine
const AppointmentFormLine = ({ index, line, linesLength, control, errors, onRemoveLine, onChange }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (services && line?.full_service && !line?.full_service?.deal) {
      const service = services.find((i) => +i.value === +line?.full_service.id);
      onChange('full_service', { ...line?.full_service, ...service });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, line]);

  const discountTypesOptions = useMemo(() => {
    let result = [];
    if (line?.full_service?.deal || line?.full_service?.lmo) {
      result = [
        {
          value: '',
          label: 'Select Discount Type',
        },
      ];
    }
    if (line?.full_service) {
      // Deal
      if (line?.deal) {
        result.push({
          value: `deal-${line.deal.id}`,
          label: `--Deal: ${line.deal.subject}`,
          data: line.deal,
        });
      }
      if (line.full_service.deal && line.full_service.deal && line.full_service.deal?.is_bookable) {
        result.push({
          value: `deal-${line.full_service.deal.id}`,
          label: `Deal: ${line.full_service.deal.subject}`,
          data: line.full_service.deal,
        });
      }
      // Lmo
      if (line?.lmo) {
        result.push({
          value: `lmo-${line.lmo.id}`,
          label: `--Lmo: ${line.lmo.subject}`,
          data: line.lmo,
        });
      }
      if (line.full_service.lmo && line.full_service.lmo.is_published) {
        result.push({
          value: `lmo-${line.full_service.lmo.id}`,
          label: `LMO: ${line.full_service.lmo.subject}`,
          data: line.full_service.lmo,
        });
      }
    }
    if (result.length === 1) return [];
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line?.full_service]);
  useEffect(() => {
    if (line?.deal && line?.service_discount_type === 'deal' && discountTypesOptions.length) {
      const dealVal = discountTypesOptions.find((i) => i.value === `deal-${line?.deal?.id}`);
      onChange('service_discount_type', dealVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line, discountTypesOptions]);

  // const handleStaff = (data) => {
  //   if (JSON.stringify(staffList) !== JSON.stringify(data)) {
  //     setStaffList(data);
  //   }
  // };
  const handleServices = (data) => {
    if (JSON.stringify(services) !== JSON.stringify(data)) {
      setServices(data);
    }
  };
  return (
    <div data-tour="appointment-line" className="c-appointment-modal__line">
      <div className="c-appointment-modal__line-input">
        <ControlTimePicker
          control={control}
          name={`lines[${index}].reserved_time`}
          id={`line-${index}-ReservedTime`}
          rules={{ required: 'Start Time is required' }}
          label="Start Time"
          onChangeValue={(val) => onChange(`reserved_time`, val)}
          defaultValue={line?.reserved_time ? line?.reserved_time : new Date()}
          placeholder={'Start Time'}
          errorMessage={errors ? errors?.reserved_time?.message : ''}
        />
      </div>
      <div className="c-appointment-modal__line-input">
        <ControlServiceSelect
          control={control}
          name={`lines[${index}].service`}
          id={`line-${index}-service`}
          rules={index === 0 ? { required: 'Service is required' } : undefined}
          label="Service"
          // onChangeValue={val => onChange(`service`, index, val)}
          onServices={handleServices}
          onChange={(val) => onChange('full_service', val)}
          defaultValue={line?.service ? line?.service : null}
          errorMessage={errors ? errors?.service?.message : ''}
        />
      </div>
      {(index === 0 || (index > 0 && line.service)) && (
        <>
          <div className="c-appointment-modal__line-input">
            <ControlSelect
              control={control}
              name={`lines[${index}].duration`}
              id={`line-${index}-duration`}
              rules={{ required: 'Duration is required' }}
              label={'Duration'}
              onChangeValue={(val) => onChange(`duration`, val)}
              options={getValueLabel(DurationChoice)}
              defaultValue={line?.duration ? line?.duration : null}
              errorMessage={errors ? errors?.duration?.message : ''}
            />
          </div>
          <div className="c-appointment-modal__line-input">
            {/*<ControlStaffSelect*/}
            {/*    control={control}*/}
            {/*    name={`lines[${index}].staff`}*/}
            {/*    rules={{required: 'Staff is required'}}*/}
            {/*    label="Staff"*/}
            {/*    onChange={val => onChange('full_staff', val)}*/}
            {/*    filterItems={line?.full_service ? line?.full_service?.staff : []}*/}
            {/*    defaultValue={line?.staff ? line?.staff : null}*/}
            {/*    errorMessage={errors ? errors?.staff?.message : ''}*/}
            {/*    isDisabled={!line?.full_service}*/}
            {/*/>*/}
            <div
              className={classnames('c-form__group-row', {
                'c-form__group-row--2': discountTypesOptions.length > 0,
              })}
            >
              <ControlStaffSelect
                // onFilteredStaff={handleStaff}
                control={control}
                name={`lines[${index}].staff`}
                id={`line-${index}-staff`}
                rules={{ required: 'Staff is required' }}
                label="Staff"
                onChange={(val) => onChange('full_staff', val)}
                filterItems={line?.full_service ? line?.full_service?.staff : []}
                defaultValue={line?.staff ? line?.staff : null}
                errorMessage={errors ? errors?.staff?.message : ''}
                isDisabled={!line?.full_service}
              />
              {/* ====== Deal Input in form is Here Just uncomment it ====== */}
              {/* ====== fixed it when read appointment again ====== */}
              {discountTypesOptions.length > 0 && (
                <ControlSelect
                  control={control}
                  options={discountTypesOptions}
                  name={`lines[${index}].service_discount_type`}
                  id={`line-${index}-discountType`}
                  onChange={(val) => onChange('service_discount_type', val)}
                  label="Discount Type"
                  defaultValue={line?.deal ? `deal-${line?.deal?.id}` : line?.lmo ? `lmo-${line?.lmo?.id}` : null}
                  errorMessage={errors ? errors?.service_discount_type?.message : ''}
                />
              )}
            </div>
          </div>
        </>
      )}
      {linesLength > 1 && line?.service && (
        <div className="c-appointment-modal__line-remove" onClick={() => onRemoveLine(index)}>
          <Close />
        </div>
      )}
    </div>
  );
};

AppointmentFormLine.propTypes = {
  index: PropTypes.number,
  line: PropTypes.any,
  linesLength: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  onRemoveLine: PropTypes.func,
  onChange: PropTypes.func,
};

export default AppointmentFormLine;
