import React, { useEffect, useContext, useState } from 'react';
import classnames from 'classnames';
import Button from '../../UIKit/Form/Button';
import { handleApi } from '../../../../utilities/useApi';
import { getPlans, getProvince } from '../../../../api';
import SoftwarePlanItem from '../SoftwarePlanItem';
import { PageLoading } from '../../UIKit/Spinner';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const SetupSoftwarePlans = ({ isNew, onPrev, onNext }) => {
  const [taxRate, setTaxRate] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { business, newBusiness } = useContext(AppContext);

  // const [data, loading] = useApi(getPlans, newBusiness ? { businessId: newBusiness.id } : undefined);
  useEffect(() => {
    if (newBusiness || business) {
      handleApi(getPlans, { businessId: newBusiness?.id || business?.id }, { loadingFn: setLoading }).then(setData);
    }
  }, [newBusiness, business]);

  useEffect(() => {
    const provinceId = isNew ? newBusiness?.province : business?.province;
    if (provinceId) {
      handleApi(getProvince, { id: provinceId }, { list: false }).then((res) => {
        setTaxRate(+res.tax_rate);
      });
    }
  }, [business, newBusiness, isNew]);

  const handleOpenStrip = (session_id, itemId) => {
    let item = null;
    data?.results.forEach((i, ind) => {
      if (i.id === itemId) {
        item = {
          ...data.results[ind],
          theme: i.id % 2 ? 'light' : 'dark',
        };
      }
    });
    setSessionId(session_id);
    setSelectedItem(item);
  };

  const handleNext = () => {
    onNext({ item: selectedItem, sessionId });
  };

  return (
    <div>
      <div className="c-setup-system-plans">
        <p>Choose the plan that’s right for you</p>
        {loading ? (
          <PageLoading />
        ) : (
          <div className="c-settings-plans c-setup-system-plans__container">
            {data?.results.map((plan) => (
              <div
                key={plan.id}
                className={classnames('c-setup-system-plans__item', {
                  'c-setup-system-plans__item--active': plan.id === selectedItem?.id,
                  'c-setup-system-plans__item--inactive': selectedItem ? plan.id !== selectedItem.id : false,
                })}
              >
                <SoftwarePlanItem
                  isNew={isNew}
                  taxRate={taxRate}
                  key={plan.id}
                  item={plan}
                  theme={plan.id % 2 ? 'light' : 'dark'}
                  onSessionId={handleOpenStrip}
                  selected={
                    isNew ? newBusiness?.plan && newBusiness.subscribed : business?.plan && business?.subscribed
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="d-flex align-center mt-5">
        <Button type="button" color="light" onClick={onPrev} className="mr-auto">
          Previous Step
        </Button>
        <Button
          type="button"
          disabled={(isNew ? !newBusiness?.plan : !business?.plan) && !selectedItem}
          onClick={handleNext}
          className="ml-auto"
        >
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default SetupSoftwarePlans;
