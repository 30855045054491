/**
 *
 * @param certificate {{
 *     id: String,
 *     issued_date: String,
 *     expiration_date: String,
 *     course: String,
 *     institution: String,
 *     description: String,
 * }}
 * @constructor
 */

function CertificateObject(certificate) {
  this.id = certificate.id;
  this.course = certificate.course;
  this.issued_date = certificate.issued_date ? new Date(certificate.issued_date) : null;
  this.expiration_date = certificate.expiration_date ? new Date(certificate.expiration_date) : null;
  this.institution = certificate.institution;
  this.description = certificate.description;
}

export default CertificateObject;
