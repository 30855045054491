import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes } from '../config/apiConfig';
import NotificationObject from '../objects/NotificationObject';

const { GET } = ApiMethods;

/**
 * @param params {{
 *     page,
 *     limit
 * }}
 */
export const getNotificationList = async ({ page, limit }) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.notifications.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new NotificationObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};
