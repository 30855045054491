import React, { useEffect, useState, useMemo } from 'react';
import Modal from '../UIKit/Modal';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DeleteRounded } from '@material-ui/icons';
import useApi, { handleApi, handleApiDeleteById, handleApiSave, useApiById } from '../../../utilities/useApi';
import {
  createBookablePhoto,
  deleteBookablePhoto,
  getBookablePhoto,
  getServiceList,
  getStaffList,
  getTagList,
  partialUpdateBookablePhoto,
} from '../../../api';
import Button from '../UIKit/Form/Button';
import { PageLoading } from '../UIKit/Spinner';
import CropImage from '../UIKit/CropImage';
import { ControlSelect } from '../UIKit/Form/Select';
import { ControlInput } from '../UIKit/Form/Input';
import { ControlSwitch } from '../UIKit/Form/Switch';
import UploadFileInput from '../UIKit/UploadFileBox';
import { getCategoryList } from '../../../api';
import { ServiceTieredLevelChoice } from '../../../utilities/constants/choices';

const BookablePhotoModal = ({ onClose, onSave, image }) => {
  const [services, setServices] = useState(null);
  const [selectedServiceOpt, setSelectedServiceOpt] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [originImage, setOriginImage] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const [delLoading, setDelLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [staffList, setStaffList] = useState(false);

  const { handleSubmit, errors, control, watch, setValue } = useForm();
  const { id } = useParams();

  // const [staffList] = useApi(getStaffList, { only_stylists: true, infinitePage: true });
  const [categories] = useApi(getCategoryList);

  useEffect(() => {
    handleApi(getStaffList, { only_stylists: true }, { infinitePage: true }).then((res) => {
      setStaffList(res);
    });
  }, []);

  useEffect(() => {
    handleApi(getServiceList, {}, { infinitePage: true }).then((res) => {
      setServices(res);
    });
  }, []);

  const [data, loading] = useApiById(getBookablePhoto, id, (_data) => {
    if (_data?.image) setOriginImage(_data.image);
  });

  useEffect(() => {
    if (categories && data?.service && (services?.results || []).length) {
      const service = (services.results || []).find((i) => i.id === data?.service);
      handleChangeService(service, true);
    }
  }, [data, services, categories]);

  useEffect(() => {
    const slug = selectedServiceOpt?.type ? selectedServiceOpt.type.slug : null;
    if (slug) {
      setAllTags({
        general: [],
        size: [],
        style: [],
        color: [],
      });
      Promise.all([
        handleApi(getTagList, { type: 'general', service_types__slug: slug }, { infinitePage: true }),
        handleApi(getTagList, { type: 'size', service_types__slug: slug }, { infinitePage: true }),
        handleApi(getTagList, { type: 'style', service_types__slug: slug }, { infinitePage: true }),
        handleApi(getTagList, { type: 'color', service_types__slug: slug }, { infinitePage: true }),
      ]).then(([generalRes, sizeRes, styleRes, colorRes]) => {
        const response = {
          general: generalRes?.results || [],
          size: sizeRes?.results || [],
          style: styleRes?.results || [],
          color: colorRes?.results || [],
        };
        setAllTags(response);
      });
    }
  }, [selectedServiceOpt]);

  useEffect(() => {
    if (image) setOriginImage(image);
  }, [image]);

  const serviceFilteredOptions = useMemo(
    () =>
      (services?.results || []).map((i) => ({
        ...i,
        label: `${i.name} (${ServiceTieredLevelChoice[i.tiered_level]})`,
        value: i.id,
      })),
    [services],
  );

  const staffOptions = useMemo(() => {
    if (selectedService) {
      return (staffList?.results || [])
        .filter((i) => selectedService.providers.indexOf(i.id) > -1)
        .map((i) => ({ value: i.id, label: i.name }));
    }
    return [];
  }, [selectedService]);

  const onSaveForm = (data) => {
    const body = {
      ...data,
      service_type: selectedServiceOpt?.type?.id || undefined,
      tags: [
        ...(data?.tags?.color || []),
        ...(data?.tags?.general || []),
        ...(data?.tags?.size || []),
        ...(data?.tags?.style || []),
      ],
      image: croppedImage || undefined,
    };

    handleApiSave(createBookablePhoto, partialUpdateBookablePhoto, body, id, {
      loadingFn: setSaveLoading,
    })
      .then((data) => {
        onSave(data);
      })
      .catch(() => {});
  };

  const handleChangeService = (e, isDefault = false) => {
    setSelectedService(e);
    if (!isDefault) setValue('price', e.price);
    setSelectedServiceOpt(
      (categories || []).reduce(
        (acc, i) => {
          const type = i.service_types.find((j) => j.id.toString() === e.service_type.toString());
          if (type) {
            return {
              type: {
                id: type.id,
                slug: type.slug,
                name: type.name,
              },
              category: {
                id: i.id,
                slug: i.slug,
                name: i.name,
              },
            };
          }
          return acc;
        },
        { type: null, category: null },
      ),
    );
  };

  const onDelete = () => {
    const options = {
      title: 'Confirm to delete this photo',
      message: 'Are you sure to delete this photo.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteBookablePhoto, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setOriginImage({ src: reader.result, file });
      };
    }
  };

  const handleRemovePhoto = () => {
    setCroppedImage(null);
    setOriginImage(null);
  };

  const tagsOptions = (type = 'general') => {
    if (allTags && allTags[type]) {
      if (type === 'color') {
        return allTags[type].map(({ id, name, meta }) => ({
          label: (
            <span className="c-promotion__tags-item">
              <span className="c-promotion__tags-item-color" style={{ backgroundColor: meta?.code || '#fff' }} />
              {name}
            </span>
          ),
          value: id,
        }));
      }
      return allTags[type].map(({ id, name }) => ({ label: name, value: id }));
    }
    return [];
  };

  const colorTags = useMemo(() => tagsOptions('color'), [allTags]);
  const generalTags = useMemo(() => tagsOptions('general'), [allTags]);
  const sizeTags = useMemo(() => tagsOptions('size'), [allTags]);
  const styleTags = useMemo(() => tagsOptions('style'), [allTags]);

  const defaultTagsValue = (type = 'general') => {
    switch (type) {
      case 'color':
        return (data?.tags || []).filter(
          (i) =>
            generalTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            sizeTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            styleTags.findIndex((j) => String(j.value) === String(i)) === -1,
        );
        break;
      case 'general':
        return (data?.tags || []).filter(
          (i) =>
            colorTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            sizeTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            styleTags.findIndex((j) => String(j.value) === String(i)) === -1,
        );
        break;
      case 'size':
        return (data?.tags || []).filter(
          (i) =>
            colorTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            generalTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            styleTags.findIndex((j) => String(j.value) === String(i)) === -1,
        );
        break;
      case 'style':
        return (data?.tags || []).filter(
          (i) =>
            colorTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            generalTags.findIndex((j) => String(j.value) === String(i)) === -1 &&
            sizeTags.findIndex((j) => String(j.value) === String(i)) === -1,
        );
        break;
      default:
        return [];
    }
  };
  return (
    <Modal
      title={!!id ? 'Edit Photo' : 'New Photo'}
      show
      auto
      bodyClass="p-0"
      onSave={handleSubmit(onSaveForm)}
      saveBtnText={watch().is_published ? 'Save and Publish' : 'Save'}
      saveLoading={saveLoading}
      buttonComponent={
        id && (
          <Button color="danger" loading={delLoading} onClick={onDelete}>
            Delete
          </Button>
        )
      }
      onClose={onClose}
    >
      {!!id && (loading || !services) ? (
        <PageLoading type="box" />
      ) : (
        <div className="c-promotion__form-card-container">
          <div className="c-promotion__form-card-sidebar">
            <form onSubmit={handleSubmit(onSaveForm)}>
              <div className="c-promotion__form-card-content">
                <ControlSelect
                  control={control}
                  name="service"
                  rules={{ required: 'Service is required' }}
                  defaultValue={data?.service || ''}
                  label="Service"
                  onChange={handleChangeService}
                  options={serviceFilteredOptions}
                  isSearchable
                  errorMessage={errors?.service?.message}
                />
                <ControlSelect
                  control={control}
                  name="staff"
                  rules={{ required: 'Staff is required' }}
                  defaultValue={data?.staff || ''}
                  label="Staff"
                  options={staffOptions}
                  isSearchable
                  errorMessage={errors?.staff?.message}
                />
                {styleTags.length > 0 && (
                  <ControlSelect
                    isMulti
                    isSearchable
                    maxMenuHeight={200}
                    control={control}
                    name="tags.style"
                    closeMenuOnSelect={false}
                    defaultValue={defaultTagsValue('style')}
                    label="Style"
                    options={styleTags}
                    placeholder="Select Style"
                    errorMessage={errors?.tags?.style?.message}
                  />
                )}
                {colorTags.length > 0 && (
                  <ControlSelect
                    isMulti
                    isSearchable
                    maxMenuHeight={200}
                    control={control}
                    name="tags.color"
                    closeMenuOnSelect={false}
                    defaultValue={defaultTagsValue('color')}
                    label="Color"
                    options={colorTags}
                    placeholder="Select Color"
                    errorMessage={errors?.tags?.color?.message}
                  />
                )}
                {sizeTags.length > 0 && (
                  <ControlSelect
                    isMulti
                    isSearchable
                    maxMenuHeight={200}
                    control={control}
                    name="tags.size"
                    closeMenuOnSelect={false}
                    defaultValue={defaultTagsValue('size')}
                    label="Size"
                    options={sizeTags}
                    placeholder="Select Size"
                    errorMessage={errors?.tags?.size?.message}
                  />
                )}
                {generalTags.length > 0 && (
                  <ControlSelect
                    isMulti
                    isSearchable
                    maxMenuHeight={200}
                    control={control}
                    name="tags.general"
                    closeMenuOnSelect={false}
                    defaultValue={defaultTagsValue('general')}
                    label="Tag"
                    options={generalTags}
                    placeholder="Select Tag"
                    errorMessage={errors?.tags?.general?.message || errors?.tags?.message}
                  />
                )}

                <ControlInput
                  control={control}
                  name="price"
                  defaultValue={data?.price ? data.price : ''}
                  type="number"
                  unit="$"
                  min={0}
                  label="Price Amount (From)"
                  placeholder={'e.g. 30 (3$)'}
                  errorMessage={errors?.price?.message}
                />
                <hr />
                <ControlSwitch
                  control={control}
                  name="is_published"
                  defaultValue={data ? data.is_published : true}
                  label="Publish Now"
                  formGroup
                />
              </div>
            </form>
          </div>

          <div className="c-promotion__card-wrapper">
            <div className="c-promotion__card-container">
              {originImage ? (
                <div className="c-upload-file-preview">
                  <CropImage src={originImage} onChange={setCroppedImage} />
                  <span className="c-upload-file-preview__actions">
                    <span className="c-upload-file-preview__actions-item" onClick={handleRemovePhoto}>
                      <DeleteRounded />
                    </span>
                  </span>
                </div>
              ) : (
                <UploadFileInput onChange={handleChangeFile} accept="image/x-png,image/jpg,image/jpeg" />
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

BookablePhotoModal.propTypes = {
  image: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default BookablePhotoModal;
