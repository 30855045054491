import React, { useContext } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import CookieConsent, { Cookies } from 'react-cookie-consent';
import DashboardPage from './components/Pages/DashboardPage/DashboardPage';
import Layout from './components/Common/Layouts/Layout';
import NotFoundPage from './components/Pages/NotFoundPage/NotFoundPage';
import SalesPage from './components/Pages/SalesPage/SalesPage';
import ClientsPage from './components/Pages/ClientsPage/ClientsPage';
import StaffPage from './components/Pages/StaffPage/StaffPage';
import ClientInfoPage from './components/Pages/ClientsPage/ClientInfo/ClientInfoPage';
import CalendarPage from './components/Pages/CalendarPage/CalendarPage';
import LoginPage from './components/Pages/Authentication/LoginPage';
import RegisterPage from './components/Pages/Authentication/RegisterPage';
import ServicesPage from './components/Pages/ServicesPage/ServicesPage';
import { Types } from './config/actionTypes';
import PromotionDealsPage from './components/Pages/PromotionsPage/PromotionDealsPage';
import PromotionLmoPage from './components/Pages/PromotionsPage/PromotionLmoPage';
import PromotionDiscountsPage from './components/Pages/PromotionsPage/PromotionDiscountsPage';
import AnalyticsPage from './components/Pages/AnalyticsPage/AnalyticsPage';
import PromotionGiftCardsPage from './components/Pages/PromotionsPage/PromotionGiftCardsPage';
import SettingsPage from './components/Pages/SettingsPage/SettingsPage';
import SubscriptionPage from './components/Pages/SubscriptionPage/SubscriptionPage';
import SettingPlansPage from './components/Pages/SettingsPage/SettingPlansPage';
import SettingMarketplacePage from './components/Pages/SettingsPage/SettingMarketplacePage';
import NotificationPage from './components/Pages/NotificationPage';
import BookablePhotosPage from './components/Pages/BookablePhotosPage';
import MessagesPage from './components/Pages/MessagesPage';
import PaymentPage from './components/Pages/PaymentsPage';
import PaymentPenaltyPage from './components/Pages/PaymentsPage/PaymentPenaltyPage';
import ThirdTimeRequestsPage from './components/Pages/Requests/ThirdTimeRequestsPage';
import BusinessProfilePage from './components/Pages/BusinessProfilePages';
import ResetPasswordPage from './components/Pages/Authentication/ResetPasswordPage';
import SuccessPaymentPage from './components/Pages/Stripe/SuccessPaymentPage';
import FailurePaymentPage from './components/Pages/Stripe/FailurePaymentPage';
import RefreshAccountStripePage from './components/Pages/Stripe/RefreshAccountStripePage';
import ReturnAccountStripePage from './components/Pages/Stripe/ReturnAccountStripePage';
import VerifyIdentity from './components/Pages/Authentication/VerifyIdentity';
import SubscriptionSettingsPage from './components/Pages/SubscriptionPage/SubscriptionSettingsPage';
import ForgotPage from './components/Pages/Authentication/ForgotPage';
import { AppContext } from './context/providers';
import ClaimProfilePage from './components/Pages/Authentication/ClaimProfilePage';
import AdminLoginPage from './components/Pages/Authentication/AdminLoginPage';
import BalanceHistoryPage from './components/Pages/BalanceHistoryPage';
import LocationPage from './components/Pages/LocationPage';
import { ModalAlert } from './components/Common/UIKit/Alert';
import Button from './components/Common/UIKit/Form/Button';
import UserPermissions from './components/Pages/SettingsPage/UserPermissions';
import BusinessClosedDates from './components/Pages/BusinessProfilePages/BusinessClosedDates';
import RequestServicesPage from './components/Pages/Requests/RequestServicesPage';
import { IS_DEV } from './config/appConfig';

// export const PrivateRoute = ({ component: Component, deny = false, ...rest }) => {
//   const token = localStorage.getItem(Types.USER_TOKEN);
//   const refresh_token = localStorage.getItem(Types.USER_REFRESH);
//   // return <Route {...rest} render={props => <Component {...props} />} />;
//   return (
//     <Route
//       render={(props) =>
//         token || refresh_token ? (
//           deny ? (
//             <Redirect to={{ pathname: '/' }} />
//           ) : (
//             <Component {...props} />
//           )
//         ) : (
//           <Redirect to={{ pathname: '/login', state: { referrer: props.location } }} />
//         )
//       }
//       {...rest}
//     />
//   );
// };

export const PrivateRoute = ({ component: Component, deny = false, ...rest }) => {
  const token = localStorage.getItem(Types.USER_TOKEN);
  const refresh_token = localStorage.getItem(Types.USER_REFRESH);
  // return <Route {...rest} render={props => <Component {...props} />} />;
  const history = useHistory();
  return (
    <Route
      render={(props) =>
        token || refresh_token ? (
          <>
            {deny ? (
              <ModalAlert
                onClose={() => history.goBack()}
                modalTitle="Permission Denied"
                title="Software Permission"
                description="You do not have any plan, so you cannot add new appointment or staff"
                buttonComponent={<Button link="/subscription">Subscribe Now</Button>}
              />
            ) : (
              <Component {...props} />
            )}
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { referrer: props.location } }} />
        )
      }
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
  deny: PropTypes.bool,
};

export const AuthRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem(Types.USER_TOKEN);
  return (
    <Route
      render={(props) =>
        !token ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
      {...rest}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
};

const Routes = () => {
  const { softwareAccess } = useContext(AppContext);
  return (
    <>
      {!IS_DEV && (
        <CookieConsent
          enableDeclineButton
          declineButtonClasses="c-cookie__button c-cookie__button--decline"
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: '#2B373B' }}
          containerClasses="c-cookie__container"
          contentClasses="c-cookie__content"
          buttonWrapperClasses="c-cookie__buttons"
          buttonClasses="c-cookie__button"
          expires={150}
        >
          Our website uses cookies and similar technologies to personalize your experience and advertising, offer
          sign-on options, and to analyze our traffic.
        </CookieConsent>
      )}
      <Switch>
        <AuthRoute key="login" path="/login" exact component={LoginPage} />
        <AuthRoute key="reset_password" path="/reset_password" exact component={ResetPasswordPage} />
        <AuthRoute key="claim_profile" path="/claim_profile" exact component={ClaimProfilePage} />
        <AuthRoute key="register" path="/register" exact component={RegisterPage} />
        <AuthRoute key="forgot" path="/forgot" exact component={ForgotPage} />
        <Route key="adminLogin" path="/admin-login" exact component={AdminLoginPage} />
        <Route key="successPayment" path="/stripe/success" exact component={SuccessPaymentPage} />
        <Route key="cancelPayment" path="/stripe/cancel" exact component={FailurePaymentPage} />
        <Route key="refreshPayment" path="/stripe/refresh" exact component={RefreshAccountStripePage} />
        <Route key="returnPayment" path="/stripe/return" exact component={ReturnAccountStripePage} />
        <PrivateRoute key="verify_email" path="/verify_email" exact component={VerifyIdentity} />
        <Layout>
          <Switch>
            <PrivateRoute key="dashboard" path="/" exact component={DashboardPage} />
            <PrivateRoute key="dashboard1" path="/dashboard" exact component={DashboardPage} />

            <PrivateRoute key="calendar" path="/calendar" exact component={CalendarPage} />
            <PrivateRoute
              key="calendar"
              path="/calendar/appointment/add"
              exact
              component={CalendarPage}
              deny={!softwareAccess}
            />
            <PrivateRoute
              key="calendar"
              path="/calendar/google-calendar"
              exact
              component={CalendarPage}
              deny={!softwareAccess}
            />
            <PrivateRoute
              key="calendar"
              path="/calendar/appointment/:id/edit"
              exact
              component={CalendarPage}
              deny={!softwareAccess}
            />
            <PrivateRoute key="calendar" path="/calendar/blocked/:id/edit" component={CalendarPage} exact />

            {/* Promotions Pages */}
            <PrivateRoute key="promotions" path="/promotions" exact component={PromotionDealsPage} />
            <PrivateRoute key="promotionsDeal" path="/promotions/deals" exact component={PromotionDealsPage} />
            <PrivateRoute key="promotionsDeal" path="/promotions/deals/add" exact component={PromotionDealsPage} />
            <PrivateRoute key="promotionsDeal" path="/promotions/deals/:id" exact component={PromotionDealsPage} />
            <PrivateRoute
              key="promotionsDeal"
              path="/promotions/deals/:id/campaign"
              exact
              component={PromotionDealsPage}
            />

            <PrivateRoute key="promotionsLastMinutes" path="/promotions/lmos" exact component={PromotionLmoPage} />
            <PrivateRoute key="promotionsLastMinutes" path="/promotions/lmos/add" exact component={PromotionLmoPage} />
            <PrivateRoute key="promotionsLastMinutes" path="/promotions/lmos/:id" exact component={PromotionLmoPage} />
            <PrivateRoute
              key="promotionsLmoCampaign"
              path="/promotions/lmos/:id/campaign"
              exact
              component={PromotionLmoPage}
            />

            <PrivateRoute key="giftCards" path="/promotions/gift-cards" exact component={PromotionGiftCardsPage} />
            <PrivateRoute key="giftCards" path="/promotions/gift-cards/all" exact component={PromotionGiftCardsPage} />
            <PrivateRoute
              key="giftCards"
              path="/promotions/gift-cards/all/add"
              exact
              component={PromotionGiftCardsPage}
              deny={!softwareAccess}
            />
            <PrivateRoute
              key="giftCards"
              path="/promotions/gift-cards/all/:id"
              exact
              component={PromotionGiftCardsPage}
            />
            <PrivateRoute
              key="giftCards"
              path="/promotions/gift-cards/all/:id/campaign"
              exact
              component={PromotionGiftCardsPage}
            />
            <PrivateRoute key="giftCards" path="/promotions/gift-cards/sold" exact component={PromotionGiftCardsPage} />
            <PrivateRoute
              key="giftCards"
              path="/promotions/gift-cards/sold/:id"
              exact
              component={PromotionGiftCardsPage}
            />

            <PrivateRoute
              key="promotionDiscounts"
              path="/promotions/discount-codes"
              exact
              component={PromotionDiscountsPage}
            />
            <PrivateRoute
              key="promotionDiscounts"
              path="/promotions/discount-codes/add"
              exact
              component={PromotionDiscountsPage}
            />
            <PrivateRoute
              key="promotionDiscounts"
              path="/promotions/discount-codes/:id"
              exact
              component={PromotionDiscountsPage}
            />
            <PrivateRoute
              key="promotionDiscountsCampaign"
              path="/promotions/discount-codes/:id/campaign"
              exact
              component={PromotionDiscountsPage}
            />

            {/* Sales Pages */}
            <PrivateRoute key="sales" path="/sales" exact component={SalesPage} />
            <PrivateRoute key="sales" path="/sales/appointments" exact component={SalesPage} />
            <PrivateRoute key="sales" path="/sales/appointments/:id" exact component={SalesPage} />
            <PrivateRoute key="sales" path="/sales/transactions" exact component={SalesPage} />
            <PrivateRoute key="sales" path="/sales/transactions/:id" exact component={SalesPage} />

            <PrivateRoute key="clients" path="/clients" exact component={ClientsPage} />
            <PrivateRoute key="client" path="/clients/:clientId/:page?" exact component={ClientInfoPage} />

            <PrivateRoute key="staff" path="/staff" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/members" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/members/add" exact component={StaffPage} deny={!softwareAccess} />
            <PrivateRoute key="staff" path="/staff/members/:id/edit" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/hours" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/commissions" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/closed-dates" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/closed-dates/add" exact component={StaffPage} />
            <PrivateRoute key="staff" path="/staff/closed-dates/:id/edit" exact component={StaffPage} />

            <PrivateRoute key="services" path="/services" exact component={ServicesPage} />
            <PrivateRoute key="services" path="/services/add" exact component={ServicesPage} />
            <PrivateRoute key="services" path="/services/:id/edit" exact component={ServicesPage} />
            <PrivateRoute key="services" path="/services/:serviceId/add-photo" exact component={ServicesPage} />

            <PrivateRoute key="bookablePhotos" path="/bookable_photos" exact component={BookablePhotosPage} />
            <PrivateRoute key="bookablePhotos" path="/bookable_photos/add" exact component={BookablePhotosPage} />
            <PrivateRoute key="bookablePhotos" path="/bookable_photos/upload" exact component={BookablePhotosPage} />
            <PrivateRoute key="bookablePhotos" path="/bookable_photos/instagram" exact component={BookablePhotosPage} />
            <PrivateRoute key="bookablePhotos" path="/bookable_photos/:id/edit" exact component={BookablePhotosPage} />

            <PrivateRoute key="analytics" path="/analytics" exact component={AnalyticsPage} />
            <PrivateRoute key="analytics" path="/analytics/appointments" exact component={AnalyticsPage} />
            <PrivateRoute key="analytics" path="/analytics/clients" exact component={AnalyticsPage} />
            <PrivateRoute key="analytics" path="/analytics/sales" exact component={AnalyticsPage} />

            <PrivateRoute key="messages" path="/messages" exact component={MessagesPage} />

            <PrivateRoute key="settings" path="/settings" exact component={SettingsPage} />
            <PrivateRoute
              key="settingBusiness"
              path="/settings/business-profile"
              exact
              component={BusinessProfilePage}
            />
            {/*<PrivateRoute key="settingCommunication" path="/settings/communications" exact component={SettingCommunicationPage} />*/}
            <PrivateRoute key="settingPlans" path="/settings/plans" exact component={SettingPlansPage} />

            <PrivateRoute key="permissions" path="/settings/permissions" exact component={UserPermissions} />
            <PrivateRoute
              key="accessLevelNew"
              path="/settings/permissions/level/add"
              exact
              component={UserPermissions}
            />
            <PrivateRoute
              key="accessLevelNew"
              path="/settings/permissions/level/:id"
              exact
              component={UserPermissions}
            />

            <PrivateRoute key="settingMarketplace" path="/settings/marketplace" component={SettingMarketplacePage} />

            <PrivateRoute key="subscription" path="/subscription" exact component={SubscriptionPage} />
            <PrivateRoute
              key="subscriptionSettings"
              path="/subscription/settings"
              exact
              component={SubscriptionSettingsPage}
            />

            <PrivateRoute key="notifications" path="/settings/notifications" component={NotificationPage} />

            <PrivateRoute key="closedDates" path="/settings/closed-dates" exact component={BusinessClosedDates} />
            <PrivateRoute
              key="closedDatesNew"
              path="/settings/closed-dates/add"
              exact
              component={BusinessClosedDates}
            />
            <PrivateRoute
              key="closedDatesEdit"
              path="/settings/closed-dates/:id/edit"
              exact
              component={BusinessClosedDates}
            />

            {/*<PrivateRoute key="thirdTime" path="/third-time-requests" exact component={ThirdTimeRequestsPage} />*/}
            {/*<PrivateRoute key="thirdTime" path="/third-time-requests/:id" exact component={ThirdTimeRequestsPage} />*/}
            <PrivateRoute key="thirdTime" path="/requests/" exact component={ThirdTimeRequestsPage} />
            <PrivateRoute
              key="thirdTime"
              path="/requests/three-time-requests"
              exact
              component={ThirdTimeRequestsPage}
            />
            <PrivateRoute
              key="thirdTime"
              path="/requests/three-time-requests/:id"
              exact
              component={ThirdTimeRequestsPage}
            />
            <PrivateRoute key="requestServices" path="/requests/services" exact component={RequestServicesPage} />
            <PrivateRoute key="requestServices" path="/requests/services/:id" exact component={RequestServicesPage} />

            <PrivateRoute key="payment" path="/settings/payment" exact component={PaymentPage} />
            <PrivateRoute key="payment" path="/settings/payment/business" exact component={PaymentPenaltyPage} />
            <PrivateRoute key="payment" path="/settings/locations" exact component={LocationPage} />
            <PrivateRoute
              key="transfers"
              path="/settings/payment/balance-history"
              exact
              component={BalanceHistoryPage}
            />
            <PrivateRoute key="checkout" path="/checkout" exact render={() => <Redirect to={{ pathname: '/' }} />} />
            <PrivateRoute key="reauth" path="/stripe/reauth" exact render={() => <Redirect to={{ pathname: '/' }} />} />

            <PrivateRoute key="notFound" path="*" exact component={NotFoundPage} />
          </Switch>
        </Layout>

        <Route key="notFound" path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default Routes;
