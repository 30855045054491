import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import toaster from 'toasted-notes';
import { NavLink } from 'react-router-dom';
import { getStaffList } from '../../../../api';
import Checkbox from '../../UIKit/Form/Checkbox';
import SectionHead from '../../UIKit/SectionTitle';
import { handleApi } from '../../../../utilities/useApi';
import { PageLoading } from '../../UIKit/Spinner';

const ServiceFormStaff = ({ values, staff, watch, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    handleApi(getStaffList, { only_stylists: true }, { infinitePage: true, loadingFn: setLoading }).then((res) => {
      setData(res.results);
      if (res.results.length === 0)
        toaster.notify(() => (
          <div className="alert alert-danger mt-2">No Staff Exists, Please add first staff from `Staff` page</div>
        ));
    });
    // getStaffList().then((data) => {
    //   setData(data.results);
    //   if (data.results.length === 0)
    //     toaster.notify(() => (
    //       <div className="alert alert-danger mt-2">No Staff Exists, Please add first staff from `Staff` page</div>
    //     ));
    // });
  }, []);

  const tiered_level = useMemo(
    () => (watch()?.price && watch()?.price[0] ? watch()?.price[0].tiered_level : null),
    [watch()],
  );
  useEffect(() => {
    if (tiered_level) {
      const results = (data || []).filter((i) => i.tiered_level === tiered_level);
      onChange(results.map((i) => i.id));
    }
  }, [tiered_level]);

  useEffect(() => {
    if (data.length > 0) {
      if (values && values.staff) {
        onChange(values.staff);
        if (values.staff.length >= data.length) handleToggleAll();
      } else handleToggleAll();
    }
  }, [data, values]);

  const handleToggleAll = () => {
    if (selectAll) {
      setSelectAll(false);
      onChange([]);
    } else {
      const staff = data.map((l) => l.id);
      setSelectAll(true);
      onChange(staff);
    }
  };
  const handleOnChange = (item, val) => {
    staff = [...staff] || [];
    if (val) {
      staff.push(item.id);
    } else {
      const ind = staff.indexOf(item.id);
      staff.splice(ind, 1);
    }
    if (staff.length < data.length) setSelectAll(false);
    else setSelectAll(true);
    onChange(staff);
  };

  const getValue = (id) => {
    const value = staff || [];
    const val = value.indexOf(id);
    return val > -1;
  };

  const filteredData = useMemo(() => {
    if (watch()?.price && watch()?.price[0] && tiered_level) {
      return tiered_level === 'no_level'
        ? data || []
        : (data || []).filter((i) => i.tiered_level === watch()?.price[0].tiered_level);
    }
    return data || [];
  }, [data, watch, tiered_level]);

  return (
    <>
      <div className="c-service-form__head">
        <SectionHead title="Staff" description="Assign staff to the service" />
      </div>
      {loading ? (
        <PageLoading />
      ) : data.length > 0 ? (
        <div data-tour="staff" className="c-service-form__content">
          <Checkbox formGroup id="allStaff" label="Select All Staff" checked={selectAll} onChange={handleToggleAll} />
          <hr />
          {filteredData.map((staff, key) => (
            <Checkbox
              key={key}
              formGroup
              id={`staff-${staff.id}`}
              label={
                <span className="c-form__label-item">
                  {/*<img src={staff.avatar_url} alt={staff.fullName} />*/}
                  <span className="detail">
                    <span>{staff.name}</span>
                  </span>
                </span>
              }
              checked={getValue(staff.id)}
              onChange={(val) => handleOnChange(staff, val)}
            />
          ))}
          <NavLink to="/staff/members" className="c-service-form__staff-link">
            <Add />
            <span>Add New Staff</span>
          </NavLink>
        </div>
      ) : (
        <h5 className="p-4 text-danger">
          No Staff Exists, Please add first staff from <NavLink to="/staff/members">Staff Page</NavLink>
        </h5>
      )}
    </>
  );
};

ServiceFormStaff.propTypes = {
  values: PropTypes.any,
  staff: PropTypes.any,
  watch: PropTypes.any,
  onChange: PropTypes.func,
};

export default ServiceFormStaff;
