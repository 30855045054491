import React from 'react';
import FormGroup from './FormGroup';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export const ControlSwitch = ({ control, name, rules, defaultValue, id, onChangeValue, ...checkoutProps }) =>
  control ? (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value }, { invalid }) => (
        <Switch
          {...checkoutProps}
          id={id ? id : name}
          onChange={(val) => {
            onChange(val);
            if (onChangeValue) onChangeValue(val);
          }}
          onBlur={onBlur}
          checked={!!value}
          error={!!invalid}
        />
      )}
    />
  ) : null;
ControlSwitch.propTypes = {
  id: PropTypes.string,
  control: PropTypes.any.isRequired,
  name: PropTypes.any,
  rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.any,
  formGroup: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorMessage: PropTypes.string,
  onChangeValue: PropTypes.func,
};

const Switch = ({ id, label, formGroup, type, onChange, ...otherProps }) => {
  const handleOnChange = (e) => onChange(e.target.checked);
  type = type === 'radio' || type === 'checkbox' ? type : 'checkbox';
  const renderCheckbox = (
    <label className="c-form__switch">
      <input type={type} id={id} onChange={handleOnChange} {...otherProps} />
      <span className="c-form__switch-slider c-form__switch-slider--round" />
    </label>
  );

  return formGroup ? (
    <FormGroup id={id} className="c-form__group-switch" label={label}>
      {renderCheckbox}
    </FormGroup>
  ) : (
    renderCheckbox
  );
};

Switch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  formGroup: PropTypes.bool,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  id: null,
  label: null,
  formGroup: false,
  type: 'checkbox',
  onChange: () => {},
};

export default Switch;
