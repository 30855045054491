import { DayNames } from '../utilities/DateHelpers';

/**
 *
 * @param staff {{
 *     id,
 *     name,
 *     phone_number,
 *     avatar,
 *     phone,
 *     mobile,
 *     email,
 *     tiered_level,
 *     access_level,
 *     working_hours,
 *     services,
 *     note: String,
 *     is_stylist: Boolean,
 *     is_active: Boolean,
 *     profile,
 * }}
 * @constructor
 */

function StaffObject(staff) {
  this.id = staff.id;
  this.avatar = staff.avatar;
  this.name = staff.name;
  this.phone_number = staff.mobile || staff.phone || staff.phone_number || '';
  this.email = staff.email || '';
  this.access_level = staff.access_level || '';
  this.tiered_level = staff.tiered_level || '';
  this.times = (staff.working_hours || []).map((i) => ({ ...i, dayName: DayNames[(i.week_day + 1) % 7] }));
  this.services = staff.services || [];
  this.note = staff.note || '';
  this.is_stylist = staff.is_stylist;
  this.isActive = staff.is_active;
  this.userId = staff?.profile ? (staff?.profile?.user ? staff?.profile?.user?.id : null) : null;
  // this.userId = null;
}

export default StaffObject;
