import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '@material-ui/icons';

// const responseTime = useMemo(() => {
//   const now = new Date();
//   if (data?.results && data?.results.length > 0) {
//     const latestDate = getDateFromString(
//       data.results[data?.results.length - 1].created_at,
//       undefined,
//       undefined,
//       true,
//     );
//     const diffTime = Math.abs(now - latestDate);
//     const diffMinutes = Math.floor(diffTime / (1000 * 60));
//     const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
//     const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
//     if (diffHours === 0 && diffDays === 0) {
//       if (diffMinutes <= 1) return 'a few moments ago';
//       return `${diffMinutes} minutes ago`;
//     } else if (diffDays === 0) {
//       return diffHours > 1 ? `${diffHours} hours` : `1 hour`;
//     }
//     return diffDays + 1 === 1 ? '1 day' : diffDays + 1 + ' days';
//   }
//   return null;
// }, [data]);

const MessageContentHeader = ({ client, onDeselectClient }) => (
  <div className="c-messages__header">
    {client && (
      <div className="c-messages__header-content">
        <span className="c-messages__header-back" onClick={onDeselectClient}>
          <ArrowBack />
        </span>
        <div>
          <h4 className="c-messages__title">{client?.full_name}</h4>
          <span className="c-messages__meta">{client?.email}</span>
          {/*{responseTime && <span className="c-messages__meta"> Responsible time: {responseTime}</span>}*/}
        </div>
      </div>
    )}
  </div>
);

MessageContentHeader.propTypes = {
  // data: PropTypes.any,
  client: PropTypes.any,
  onDeselectClient: PropTypes.func,
};

export default MessageContentHeader;
