/**
 *
 * @param card {{
 *     id,
 *     code,
 *     client,
 *     client_name,
 *     current_balance,
 *     initial_balance,
 *     expiration_date,
 *     expired,
 *     gift_card,
 *     is_usable,
 *     last_used_at,
 *     is_active,
 * }}
 * @constructor
 */
function SoldGiftCardObject(card) {
  this.id = card.id;
  this.code = card.code;
  this.client = card.client;
  this.client_name = card.client_name;
  this.current_balance = card.current_balance;
  this.initial_balance = card.initial_balance;
  this.expiration_date = card.expiration_date;
  this.gift_card = card.gift_card;
  this.is_usable = card.is_usable;
  this.last_used_at = card.last_used_at ? card.last_used_at.replace('UTC+0000', '') : card.last_used_at;
  this.expired = card.expired;
  this.is_active = card.is_active;

  this.status = card.expired ? 'expired' : card.is_active ? 'active' : 'inactive';
}

export default SoldGiftCardObject;
