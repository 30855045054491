import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { AppContext } from '../../../context/providers';
import Button from '../../Common/UIKit/Form/Button';
import { Types } from '../../../config/actionTypes';
import { confirmAlert } from 'react-confirm-alert';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import SetupLayout from '../../Common/Layouts/SetupLayout';
import Modal from '../../Common/UIKit/Modal';
import LocationModal from '../../Common/Location/LocationModal';
import { handleApi } from '../../../utilities/useApi';
import { getLocationList } from '../../../api';
import { IMAGE_ANALYSIS, IMAGE_CROWN, IMAGE_DEFAULT } from '../../../assets/Images';
import Image from '../../Common/UIKit/Image';

const LocationImage = ({ item }) => {
  const url = useMemo(() => {
    if (!item?.shouldSetup && ((item?.latitude && item?.longitude) || item?.city)) {
      let u = 'https://maps.googleapis.com/maps/api/staticmap?';
      if (item?.latitude && item?.longitude) u += `center=${item?.latitude},${item?.longitude}`;
      else u += `center=${(item?.city || '').toLowerCase()}`;
      u += '&zoom=13&size=300x300';
      u += `&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
      if (item?.latitude && item?.longitude) u += `&markers=${item?.latitude},${item?.longitude}`;
      return u;
    } else {
      return item?.avatar || IMAGE_DEFAULT;
    }
  }, [item]);
  return (
    <Image
      className="c-settings-item__img-location"
      errorSrc={item?.avatar || IMAGE_DEFAULT}
      alt={item.title}
      src={url}
    />
  );
};

LocationImage.propTypes = {
  item: PropTypes.any,
};

const LocationPage = () => {
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [data, setData] = useState(null);
  const [showManage, setShowManage] = useState(null);
  const [showSetupLayout, setShowSetupLayout] = useState(false);
  const [showSetup, setShowSetup] = useState(null);
  const { setHeader, currentLocation, onNewBusiness, setLocationsList } = useContext(AppContext);

  useEffect(() => {
    setHeader('Locations');
    handleApi(getLocationList, {}, { infinitePage: true, loadingFn: setLoading }).then((res) => {
      if (res.results) {
        setData(res.results);
      } else {
        setData([]);
      }
    });
  }, []);

  const handleSelectLocation = (item) => {
    confirmAlert({
      title: 'Leave Marketplace',
      message: `Are you sure to change current location to the ${item.title}`,
      buttons: [
        {
          label: 'Yes Change Location',
          className: 'bg-danger',
          onClick: () => {
            localStorage.setItem(Types.BUSINESS_LOCATION, item.id);
            localStorage.removeItem(Types.LATEST_APPOINTMENT);
            window.location.reload();
          },
        },
        {
          label: 'No Keep',
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = () => {
    handleApi(getLocationList, {}, { infinitePage: true, loadingFn: setLoading }).then((res) => {
      if (res.results) {
        setData(res.results);
        setLocationsList(res.results);
      } else {
        setData([]);
      }
    });
    setShowManage(null);
  };

  const handleCloseLayout = (refresh = false) => {
    setShowSetupLayout(false);
    setSelectedLocation(null);
    onNewBusiness(null);
    if (refresh) handleSave();
  };

  const isShouldSetup = (item) => !item.software_setup && !item.marketplace_setup;

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : data ? (
        showSetupLayout ? (
          <Modal
            show
            auto
            disableFooter
            sticky
            onClose={handleCloseLayout}
            contentClass="min-vh-100"
            title="Setup New Location"
            bodyClass="c-panel-setup--settings-modal"
          >
            <SetupLayout
              settingMode
              isNew={!selectedLocation}
              item={selectedLocation}
              reference={null}
              setupType={showSetup}
              onShowSetup={setShowSetup}
              onCloseSetupLayout={handleCloseLayout}
            />
          </Modal>
        ) : (
          <>
            {showManage && <LocationModal id={showManage} onClose={() => setShowManage(null)} onSave={handleSave} />}
            <HeadOptions
              rightElement={
                <Button
                  sm
                  onClick={() => {
                    onNewBusiness(null);
                    setSelectedLocation(null);
                    setShowSetupLayout(true);
                  }}
                >
                  Add Location
                </Button>
              }
            />
            <div className="c-panel-content mt-3 c-settings">
              {(data || []).map((item, key) => (
                <div
                  key={key}
                  className={classNames('c-settings-item', {
                    'c-settings-item--active': +item.id === +currentLocation,
                  })}
                >
                  <LocationImage item={{ ...item, shouldSetup: isShouldSetup(item) }} />
                  <div className="c-settings-item__detail">
                    <span className="c-settings-item__title">{item.title}</span>
                    <p className="c-settings-item__description">
                      <strong>Address: </strong>
                      {item.city || item.address ? (
                        <>
                          {item.city}
                          {item.city && item.address ? ' - ' : null}
                          {item.address}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                    {/*{item.plan && (*/}
                    {/*  <p className="c-settings-item__description">*/}
                    {/*    <strong>Plan: </strong>*/}
                    {/*    {item.plan}*/}
                    {/*  </p>*/}
                    {/*)}*/}
                    {/*{item.phone_number && (*/}
                    {/*  <p className="c-settings-item__description">*/}
                    {/*    <strong>Phone: </strong>*/}
                    {/*    {item.phone_number}*/}
                    {/*  </p>*/}
                    {/*)}*/}
                    {(item.marketplace_setup || item.software_setup) && (
                      <p className="c-settings-item__description d-flex align-items-center mt-3">
                        {item.marketplace_setup && (
                          <>
                            <img src={IMAGE_CROWN} alt="marketplace" className="c-settings-item__icon" />
                            <strong className="mr-3">Marketplace</strong>
                          </>
                        )}
                        {item.software_setup && (
                          <>
                            <img src={IMAGE_ANALYSIS} alt="software" className="c-settings-item__icon" />
                            <strong>Software</strong>
                          </>
                        )}
                      </p>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    {isShouldSetup(item) ? (
                      <Button
                        sm
                        id={`completeBtn-${item.id}`}
                        className="mr-2"
                        color="warning"
                        onClick={() => {
                          setSelectedLocation(null);
                          onNewBusiness(item);
                          setShowSetupLayout(true);
                        }}
                      >
                        Complete Setup
                      </Button>
                    ) : (
                      <>
                        {+item.id !== +currentLocation ? (
                          <Button
                            sm
                            id={`selectBtn-${item.id}`}
                            className="mr-2"
                            onClick={() => handleSelectLocation(item)}
                          >
                            Select Location
                          </Button>
                        ) : (
                          <Button sm disabled id={`currentBtn-${item.id}`} className="mr-2" color="light">
                            Current Location
                          </Button>
                        )}
                        <Button sm color="info" id={`manageBtn-${item.id}`} onClick={() => setShowManage(item.id)}>
                          Manage
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )
      ) : null}
    </>
  );
};

export default LocationPage;
