import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import InvoiceObject from '../objects/InvoiceObject';
import { handleError500 } from '../utilities/HandleErrors';

const { GET, POST, PUT, PATCH } = ApiMethods;

/**
 * @param search
 * @param page
 * @param limit
 */
export const getInvoiceList = async ({ search, page, limit, ...params }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
      ...params,
    },
    url: ApiRoutes.invoice.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new InvoiceObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getInvoice = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.invoice.get, { id }),
  });
  if (response.data) {
    // return response.data;
    return new InvoiceObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     lines,
 *     client,
 *     client_name,
 *     client_email,
 *     note
 * }}
 */
export const createInvoice = async (params) => {
  try {
    const response = await callApi({
      method: POST,
      params,
      url: ApiRoutes.invoice.create,
      // needStatus: true
    });
    if (response.data) {
      return new InvoiceObject(response.data);
      // return response.data;
    } else {
      return Promise.reject({
        ...response,
        available: true,
      });
    }
  } catch (err) {
    handleError500(err);
  }
};

/**
 * @param id,
 * @param params {{
 *     lines,
 *     client,
 *     client_name,
 *     client_email,
 *     note
 * }}
 */
export const updateInvoice = async (id, params) => {
  try {
    const response = await callApi({
      method: PUT,
      params,
      url: createURL(ApiRoutes.invoice.update, { id }),
    });
    if (response.data) {
      return new InvoiceObject(response.data);
    } else {
      return Promise.reject({
        ...response,
        available: true,
      });
    }
  } catch (err) {
    handleError500(err);
  }
};

/**
 * @param id,
 * @param params
 */
export const partialUpdateInvoice = async (id, params) => {
  try {
    const response = await callApi({
      method: PATCH,
      params,
      url: createURL(ApiRoutes.invoice.update, { id }),
    });
    if (response.data) {
      return new InvoiceObject(response.data);
    } else {
      return Promise.reject({
        ...response,
        available: true,
      });
    }
  } catch (err) {
    handleError500(err);
  }
};

/**
 * @param params {{
 *     lines,
 *     invoice,
 *     client,
 *     client_name,
 *     client_email,
 *     note
 * }}
 * @param amount
 */
export const createStripe = async ({ params, amount = 0 }) => {
  try {
    let invoice_id = params.invoice;
    if (!invoice_id) {
      const invoice = await createInvoice(params);
      invoice_id = invoice.id;
    }
    const response = await callApi({
      method: POST,
      params: { amount },
      url: createURL(ApiRoutes.invoice.stripe, { id: invoice_id }),
    });
    if (response.data) {
      // return new InvoiceObject(response.data);
      return response.data;
    } else {
      return Promise.reject({
        ...response,
        available: true,
      });
    }
  } catch (err) {
    handleError500(err);
  }
};

/**
 * @function sendInvoice
 * @param params {{
 *   id: Number,
 *   file
 * }}
 */
export const sendInvoice = async ({ id, file }) => {
  try {
    const params = new FormData();
    params.set('file', file);
    const response = await callApi({
      method: POST,
      headers: {
        'Content-Type': 'undefined',
      },
      params,
      url: createURL(ApiRoutes.invoice.send, { id }),
    });
    if (response.data) {
      return response;
    } else {
      return Promise.reject({
        ...response,
        available: true,
      });
    }
  } catch (err) {
    handleError500(err);
  }
};
