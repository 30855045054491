import { MonthNames } from '../DateHelpers';

export const TransferReasonChoice = {
  paid_with_credit: 'Paid with credit',
  new_client_commission: 'New client commission',
  refund_fee: 'Refund fee',
  beautster_discount: 'Beautster discount',
};

export const AppointmentSaleChannelChoice = {
  existing: 'Online',
  phone: 'Phone',
  site: 'Site',
  google_reserve: 'Google Reserve',
  walk_in: 'Walk-in',
  software: 'Software',
  marketplace: 'Marketplace',
};

export const AppointmentStatusChoice = {
  pending: 'Pending',
  confirmed: 'Confirmed',
  arrived: 'Arrived',
  fulfilled: 'Fulfilled',
  cancelled: 'Cancelled',
  business_cancelled: 'Business Cancelled',
  client_cancelled: 'Client Cancelled',
  no_show: 'No Show',
};

export const InvoiceLineItemTypeChoice = {
  service: 'Service',
  gift_card: 'Gift Card',
};

export const InvoicePaymentTypeChoice = {
  cash: 'Cash',
  stripe: 'Stripe',
  credit: 'Credit',
  other: 'Other',
  gift_card: 'Gift Card',
};

export const InvoiceStatusChoice = {
  unpaid: 'Unpaid',
  paid: 'Paid',
  part_paid: 'Part Paid',
  part_refunded: 'Part Refunded',
  canceled: 'Canceled',
  refunded: 'Refunded',
};

export const TipUnitTypeChoice = {
  // cad: 'CAD',
  currency: '$',
  percent: '%',
};

export const ServiceTieredLevelChoice = {
  no_level: 'No Tiered Level',
  entry_level: 'Entry Level',
  level_1: 'Level 1',
  level_2: 'Level 2',
  level_3: 'Level 3',
  level_4: 'Level 4',
  new_talent: 'New Talent',
  stylist: 'Stylist',
  senior_stylist: 'Senior Stylist',
  master_stylist: 'Master Stylist',
  aesthetician: 'Aesthetician',
  massage_therapist: 'Massage therapist',
  educator: 'Educator',
};

export const ServicePriceTypeChoice = {
  fixed: 'Fixed',
  from: 'From',
  free: 'Free',
};

export const DealStatusChoice = {
  pending: 'Pending',
  published: 'Published',
  expired: 'Expired',
};

export const DealCampaignAudienceChoice = {
  all_clients: 'All clients',
  booked: 'Booked clients',
  never_booked: 'Never booked clients',
  booked_last_4_weeks: 'Booked in last 4 weeks',
  booked_last_8_weeks: 'Booked in last 8 weeks',
  booked_last_12_weeks: 'Booked in last 12 weeks',
  booked_last_18_weeks: 'Booked in last 18 weeks',
};

export const DealCampaignStatusChoice = {
  draft: 'Draft',
  scheduled: 'Scheduled',
  sent: 'Sent',
  failed: 'Failed',
};

export const ClientNotificationSettingChoice = {
  none: "Don't send notifications",
  email: 'Email',
  sms: 'Sms',
  all: 'Email & Sms',
};

export const DiscountTypeChoice = {
  '': 'None',
  deal: 'Deal',
  lmo: 'Lmo',
};

export const ClientSourceChoice = {
  request: 'Request',
  deal: 'Deal',
  import: 'Imported Appointment',
  referred: 'Referred Customer',
  referrer: 'Referrer',
  org: 'Organization',
};

export const MonthNumChoice = MonthNames.reduce((acc, val, ind) => ({ ...acc, [ind + 1]: val }), {});
export const DayNumChoice = Array.from(Array(31).keys()).reduce((acc, val) => ({ ...acc, [val + 1]: val + 1 }), {});

export const DealExpireAfterChoice = {
  1: '1 Day',
  7: '1 Week',
  15: '15 Day',
  30: '1 Month',
  60: '2 Months',
  90: '3 Months',
  180: '6 Months',
};

export const ExtraTimeChoice = {
  '00:5:00': '5 Min',
  '00:10:00': '10 Min',
  '00:15:00': '15 Min',
  '00:20:00': '20 Min',
  '00:25:00': '25 Min',
  '00:30:00': '30 Min',
  '00:45:00': '45 Min',
  '01:00:00': '60 Min',
  '01:15:00': '75 Min',
  '01:30:00': '90 Min',
  '01:45:00': '105 Min',
  '02:00:00': '2 Hours',
  '02:15:00': '2 Hours 15 Min',
  '02:30:00': '2 Hours 30 Min',
  '02:45:00': '2 Hours 45 Min',
};

export const DurationChoice = {
  '00:15:00': '15 Min',
  '00:30:00': '30 Min',
  '00:45:00': '45 Min',
  '01:00:00': '60 Min',
  '01:15:00': '75 Min',
  '01:30:00': '90 Min',
  '01:45:00': '105 Min',
  '02:00:00': '2 Hours',
  '02:15:00': '2 Hours 15 Min',
  '02:30:00': '2 Hours 30 Min',
  '02:45:00': '2 Hours 45 Min',
  '03:00:00': '3 Hours',
  '03:15:00': '3 Hours 15 Min',
  '03:30:00': '3 Hours 30 Min',
  '03:45:00': '3 Hours 45 Min',
  '04:00:00': '4 Hours',
  '04:15:00': '4 Hours 15 Min',
  '04:30:00': '4 Hours 30 Min',
  '04:45:00': '4 Hours 45 Min',
  '05:00:00': '5 Hours',
  '05:15:00': '5 Hours 15 Min',
  '05:30:00': '5 Hours 30 Min',
  '05:45:00': '5 Hours 45 Min',
  '06:00:00': '6 Hours',
};

export const DurationSmChoice = {
  '00:15:00': '15 min',
  '00:30:00': '30 min',
  '00:45:00': '45 min',
  '01:00:00': '1h',
  '01:15:00': '1h 15min',
  '01:30:00': '1h 30min',
  '01:45:00': '1h 45min',
  '02:00:00': '2h',
  '02:15:00': '2h 15min',
  '02:30:00': '2h 30min',
  '02:45:00': '2h 45min',
  '03:00:00': '3h',
  '03:30:00': '3h 30min',
  '04:00:00': '4h',
  '04:30:00': '4h 30min',
  '05:00:00': '5h',
  '05:30:00': '5h 30min',
  '06:00:00': '6h',
};

export const CanadaProvincesName = {
  ab: 'Alberta',
  bc: 'British Columbia',
  mb: 'Manitoba',
  nw: 'New Brunswick',
  nl: 'Newfoundland & Labrador',
  ns: 'Nova Scotia',
  nt: 'Northwest territories',
  nu: 'Nunavut',
  on: 'Ontario',
  pe: 'Prince Edward Island',
  qc: 'Quebec',
  sk: 'Saskatchewan',
  yt: 'Yukon',
};

// Province Taxes Are Unit Are Percent(%)
export const CanadaProvincesTax = {
  ab: 5,
  bc: 12,
  mb: 5,
  nw: 15,
  nl: 15,
  ns: 15,
  nt: 5,
  nu: 5,
  on: 13,
  pe: 15,
  qc: 14.98,
  sk: 11,
  yt: 5,
};

export const getValueLabel = (obj) => Object.keys(obj).map((value) => ({ value, label: obj[value] }));
