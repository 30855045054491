const LinkListSoftware = [
  {
    icon: 'fas fa-tachometer-alt',
    name: 'Dashboard',
    url: '/dashboard',
  },
  {
    icon: 'far fa-calendar',
    name: 'Calendar',
    url: '/calendar',
    key: ['appointment'],
  },
  {
    icon: 'fas fa-receipt',
    name: 'Sales',
    url: '/sales',
    key: ['appointment', 'invoice'],
    subLinks: [
      {
        icon: 'fas fa-calendar-check',
        name: 'Sales Appointment',
        key: ['appointment'],
        url: '/sales/appointments',
      },
      {
        icon: 'fas fa-receipt',
        name: 'Sales Transactions',
        key: ['invoice'],
        url: '/sales/transactions',
      },
    ],
  },
  {
    icon: 'fas fa-percent',
    name: 'Promotions',
    url: '/promotions',
    key: ['deal', 'giftcard'],
    subLinks: [
      {
        icon: 'fas fa-briefcase',
        name: 'Deals',
        key: ['deal'],
        url: '/promotions/deals',
      },
      {
        icon: 'fas fa-gift',
        name: 'Gift Cards',
        key: ['giftcard'],
        url: '/promotions/gift-cards',
      },
    ],
  },
  {
    icon: 'fa fa-users',
    name: 'Clients',
    key: ['client'],
    url: '/clients',
  },
  {
    icon: 'fa fa-user',
    name: 'Staff Member',
    key: ['staff', 'commission', 'staffcloseddate'],
    url: '/staff',
    subLinks: [
      {
        icon: 'fas fa-user-friends',
        name: 'Staff Members',
        key: ['staff'],
        url: '/staff/members',
      },
      {
        icon: 'fas fa-clock',
        name: 'Staff Availability',
        key: ['staff'],
        url: '/staff/hours',
      },
      {
        icon: 'fas fa-comment-dollar',
        name: 'Commissions',
        key: ['commission'],
        url: '/staff/commissions',
      },
      {
        icon: 'fas fa-calendar-day',
        name: 'Dates Closed',
        key: ['staffcloseddate'],
        url: '/staff/closed-dates',
      },
    ],
  },
  {
    icon: 'fas fa-clipboard-list',
    name: 'Services',
    key: ['service'],
    url: '/services',
  },
  {
    icon: 'fas fa-chart-pie',
    name: 'Analytics',
    key: [
      'business.appointments_statistics.view',
      'business.clients_statistics.view',
      'business.sales_statistics.view',
    ],
    url: '/analytics',
  },
  {
    icon: 'fas fa-cog',
    name: 'Settings',
    url: '/settings',
    subLinks: [
      {
        icon: 'fas fa-briefcase',
        name: 'Business Profile',
        key: ['business'],
        url: '/settings/business-profile',
      },
      {
        icon: 'fas fa-briefcase',
        name: 'Business Close Date',
        key: ['businesscloseddate'],
        url: '/settings/closed-dates',
      },
      {
        icon: 'fas fa-shield-alt',
        name: 'Access Level',
        url: '/settings/permissions',
        // isDev: true,
      },
      {
        icon: 'fas fa-clipboard',
        name: 'My Subscription',
        url: '/subscription',
      },
      {
        icon: 'fas fa-sliders-h',
        name: 'Subscription Setting',
        url: '/subscription/settings',
      },
      {
        icon: 'fas fa-money-check-alt',
        name: 'Payment',
        url: '/settings/payment',
      },
      {
        icon: 'fas fa-map-marked-alt',
        name: 'Location',
        url: '/settings/locations',
      },
    ].filter((i) => i),
  },
].filter((i) => i);

export default LinkListSoftware;
