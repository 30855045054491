import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import PendingTransferObject from '../objects/PendingTransferObject';

const { GET, POST } = ApiMethods;

/**
 * @param params
 * @description payment pending transfer list
 */
export const postPendingTransferPayment = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.pendingTransfer.payment,
    params,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @description get pending transfer data
 */
export const getPendingTransfer = async (id) => {
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.pendingTransfer.get, { id }),
  });
  if (response.data) {
    return Promise.resolve(new PendingTransferObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get pending transfer data list
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getPendingTransfersList = async ({ search, page, limit }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.pendingTransfer.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new PendingTransferObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};
