import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import ClientObject from '../objects/ClientObject';

const { GET, POST, PUT, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const createClient = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.client.create,
  });
  if (response.data) {
    return new ClientObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const updateClient = async (id, params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: createURL(ApiRoutes.client.update, { id }),
  });
  if (response.data) {
    return new ClientObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const partialUpdateClient = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    params,
    url: createURL(ApiRoutes.client.update, { id }),
  });
  if (response.data) {
    return new ClientObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     nextUrl,
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getClientList = async ({ search, has_user = undefined, page, limit, nextUrl = null }) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      has_user,
      offset: (page - 1) * limit,
    },
    absolute: !!nextUrl,
    url: nextUrl || ApiRoutes.client.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new ClientObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getClient = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.client.get, { id }),
  });
  if (response.data) {
    return new ClientObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteClient = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.client.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *   file,
 *   source,
 * }}
 */
export const uploadClientsFile = async ({ file, source }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('source', source);
  const response = await callApi({
    method: POST,
    params: formData,
    url: ApiRoutes.client.import,
  });
  if (response.data && response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
