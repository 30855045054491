import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleApi } from '../../../utilities/useApi';
import { forgot } from '../../../api';
import toaster from 'toasted-notes';
import AuthenticationLayout from '../../Common/Authentication/AuthenticationLayout';
import { ControlInput } from '../../Common/UIKit/Form/Input';
import { NavLink } from 'react-router-dom';
import Button from '../../Common/UIKit/Form/Button';

const ForgotPage = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { control, errors, handleSubmit } = useForm();

  const onSubmit = ({ email }) => {
    const body = { email: (email || '').toLowerCase() };
    handleApi(forgot, body, { loadingFn: setLoading, list: false })
      .then((data) => {
        toaster.notify(() => <div className="alert alert-success mt-2">{data.message}</div>);
        setMessage(data.message);
      })
      .catch(({ errors }) => {
        if (errors?.email) toaster.notify(() => <div className="alert alert-danger mt-2">{errors.email[0]}</div>);
      });
  };

  return (
    <AuthenticationLayout>
      {message ? (
        <>
          <h2 className="text-center text-success my-5">{message}</h2>
          <Button block lg link="/login">
            Back To Login Page
          </Button>
        </>
      ) : (
        <>
          <div className="c-auth__head">
            <h1>Forgot Password</h1>
            <p>For reset your password enter your email</p>
          </div>
          <form className="e-form mb-3" onSubmit={handleSubmit(onSubmit)}>
            <ControlInput
              control={control}
              type="email"
              defaultValue=""
              name="email"
              label="Email address"
              rules={{ required: 'Email is required' }}
              placeholder="example@example.com"
              errorMessage={errors?.email?.message}
            />

            <Button lg block id="submitForgot" loading={loading} className="mt-4">
              Submit
            </Button>
          </form>
          <div className="c-auth__links">
            <p>Don’t want to reset password?</p>
            <NavLink to="/login" className="c-auth__link">
              Go to log in
            </NavLink>
          </div>
        </>
      )}
    </AuthenticationLayout>
  );
};

export default ForgotPage;
