import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Select from '../UIKit/Form/Select';
import { getStaffList } from '../../../api';
// import {StorageTypes} from "../../../config/actionTypes";
import { handleApi } from '../../../utilities/useApi';
import toaster from 'toasted-notes';
import usePermission from '../../../utilities/usePermission';
// import HandleCatch from "../../../utilities/HandleCatch";

export const ControlStaffSelect = ({ control, name, rules, defaultValue, id, onChangeValue, ...selectProps }) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    render={({ onChange, onBlur, value }, { invalid }) => (
      <StaffSelect
        {...selectProps}
        id={id ? id : name}
        onChangeValue={(val) => {
          onChange(val);
          if (onChangeValue) onChangeValue(val);
        }}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        error={!!invalid}
      />
    )}
  />
);

ControlStaffSelect.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  rules: PropTypes.array,
  defaultValue: PropTypes.any,
  id: PropTypes.any,
  onChangeValue: PropTypes.func,
};

const StaffSelect = ({
  filterItems,
  onFilteredStaff,
  onChange,
  onChangeValue,
  value,
  hasDefault,
  defaultOptions = [],
  ...otherProps
}) => {
  const [data, setData] = useState(null);
  const [stateDefault, setStateDefault] = useState(false);

  const { access: _access } = usePermission('staff', 'crud', 'view');

  useEffect(() => {
    if (!otherProps.defaultValue && hasDefault && data && !stateDefault) {
      setStateDefault(true);
      if (data?.results.length > 0) {
        onChangeValue(data?.results[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // const storage = HandleCatch.getItem('staff');
    if (_access && !data) {
      const storage = false;
      if (storage) {
        setData(storage);
      } else {
        handleApi(getStaffList, { only_stylists: true }, { defaultData: null, infinitePage: true }).then((res) => {
          setData(res);
          // HandleCatch.setItem('staff', res);
          if (res.results.length === 0)
            toaster.notify(() => (
              <div className="alert alert-danger mt-2">
                No Service Exists, Please add first service from `Services` page
              </div>
            ));
        });
      }
    }
  }, [_access]);

  useEffect(() => {
    if (onChange) onChange(data ? data?.results.find((i) => String(i.id) === String(value)) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data]);

  // let staffs = data ? data?.results.map(({ id, name }) => ({ label: name, value: id })) : [];
  // staffs = staffs.filter((s) => (filterItems ? filterItems.indexOf(s.value) > -1 : true));
  // const staffs = useMemo(
  //   () =>
  //     (data?.results || [])
  //       .map(({ id, name, isActive }) => ({ label: name, value: id, isActive }))
  //       .filter((s) => (filterItems ? filterItems.indexOf(s.value) > -1 : true))
  //       .filter((s) => s.isActive),
  //   [data],
  // );

  const staffs = (data?.results || [])
    .map(({ id, name, isActive }) => ({ label: name, value: id, isActive }))
    .filter((s) => (filterItems ? filterItems.indexOf(s.value) > -1 : true))
    .filter((s) => s.isActive);

  const currentValue = useMemo(() => staffs.find((s) => s.value === value), [staffs, value]);

  useEffect(() => {
    if (onFilteredStaff) onFilteredStaff(staffs);
  }, [staffs]);
  useEffect(() => {
    if (!currentValue) {
      if (onChange) onChange(staffs[0] ? staffs[0].value : null);
    }
  }, [currentValue]);

  if (!_access) return null;

  return (
    <Select
      id={'input-staff'}
      isSearchable
      label={'Staff'}
      value={value}
      onChangeValue={onChangeValue}
      placeholder={'Select Staff'}
      options={[...defaultOptions, ...staffs]}
      {...otherProps}
    />
  );
};

StaffSelect.propTypes = {
  defaultOptions: PropTypes.array,
  filterItems: PropTypes.array,
  hasDefault: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFilteredStaff: PropTypes.func,
  onChangeValue: PropTypes.func,
};

export default StaffSelect;
