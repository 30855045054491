export const APP_NAME = 'Beautster';

export const alternate_name = (name) => `${name ? name : APP_NAME}`;

export const IS_DEV =
  (window.location.host || '').includes('localhost') || (window.location.host || '').includes('stage');

export const PARTNER_URL = IS_DEV ? 'https://partners.stage.beautster.com/' : 'https://partners.beautster.com/';
export const MARKETPLACE_URL = IS_DEV ? 'https://stage.beautster.com' : 'https://www.beautster.com';

// export const MARKETPLACE_URL = 'https://www.beautster.com';
