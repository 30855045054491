import React from 'react';
import PropTypes from 'prop-types';
import { Close, Edit } from '@material-ui/icons';
import { getTextFormatDate } from '../StylistProfileModal';

const EducationItem = ({ item, onDelete, onEdit }) => (
  <li className="c-user-stylist-profile__feature">
    <span className="c-user-stylist-profile__feature-label">{item.degree}</span>
    <span className="c-user-stylist-profile__feature-sub">{item.school}</span>
    <span className="c-user-stylist-profile__feature-dates">
      <span className="c-user-stylist-profile__feature-date">{getTextFormatDate(item.start_date)}</span>-
      <span className="c-user-stylist-profile__feature-date">{getTextFormatDate(item.end_date)}</span>
    </span>

    <span className="c-user-stylist-profile__feature-actions">
      <span className="c-user-stylist-profile__feature-delete" onClick={onDelete}>
        <Close />
      </span>
      <span className="c-user-stylist-profile__feature-edit" onClick={onEdit}>
        <Edit />
      </span>
    </span>
  </li>
);

EducationItem.propTypes = {
  item: PropTypes.any,
  onDelete: PropTypes.any,
  onEdit: PropTypes.any,
};

export default EducationItem;
