import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import HeadPage from '../../../Common/Layouts/HeadPage/HeadPage';
import ClientAppointments from './ClientAppointments';
import ClientFormModal from '../../../Common/Client/ClientFormModal';
import { getClient } from '../../../../api';
import ClientProfile from './ClientProfile';
import { PageLoading } from '../../../Common/UIKit/Spinner';
import { getPriceFormat } from '../../../../utilities/Price';
import AppointmentFormModal from '../../../Common/Appointment/AppointmentFormModal';
import { AppContext } from '../../../../context/providers';
import usePermission from '../../../../utilities/usePermission';
import { AccessDeniedPage } from '../../../Common/UIKit/NotFoundData';

const ClientInfoPage = ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [uri, setUri] = useState(null);
  const { setHeader } = useContext(AppContext);
  const { access: _access } = usePermission('client', 'crud', 'view');
  const clientId = match.params.clientId;

  useEffect(() => {
    setHeader('Client Profile');
  }, []);

  useEffect(() => {
    if (!data && !loading && _access) {
      setLoading(true);
      getClient(clientId)
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch(({ status }) => {
          if (status.toString() === '404') {
            history.replace('/notfound');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, _access]);
  useEffect(() => {
    if (match.params.page === 'edit') {
      handleOpenForm();
    }
    if (match.params.page === 'add-appointment') {
      handleOpenAppointmentForm();
    }
  }, [match]);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => {
    if (uri) history.goBack();
    else history.push(`/clients/${clientId}`);
    setUri(null);
    setShowForm(false);
  };

  const handleOpenAppointmentForm = () => setShowAppointmentForm(true);
  const handleCloseAppointmentForm = () => {
    if (uri) history.goBack();
    else history.push(`/clients/${clientId}`);
    setUri(null);
    setShowAppointmentForm(false);
  };

  const handleSaveForm = (data) => {
    setData(data);
    setShowForm(false);
    // getClient(clientId).then(res => {
    //     setData(res);
    // });
  };

  const handleSaveAppointment = () => {
    handleCloseAppointmentForm();
    window.location.reload(false);
  };

  return _access ? (
    <>
      {/*<HeadPage title={'Client Profile'} />*/}
      {showAppointmentForm && (
        <AppointmentFormModal
          onSave={handleSaveAppointment}
          onClose={handleCloseAppointmentForm}
          extraData={{
            type: 'client',
            client: +clientId,
            name: data?.name,
            email: data?.email,
          }}
        />
      )}
      {showForm && <ClientFormModal data={data} onSave={handleSaveForm} onClose={handleCloseForm} />}
      {loading ? (
        <PageLoading />
      ) : !!data ? (
        <div className="row my-3">
          <div className="col-12 col-xs-12 col-md-4">
            <ClientProfile setUri={setUri} id={clientId} data={data} />
          </div>
          <div className="col-12 col-xs-12 col-md-8">
            <div className="c-panel-content p-0 mb-5">
              <div className="c-client__payment-money-info">
                <div className="c-client__payment-money">
                  <span className="c-client__payment-no">{getPriceFormat(data?.total_sales)}</span>
                  <span className="c-client__payment-text">Total Sales</span>
                </div>
              </div>
              <div className="c-client__payment-info">
                <div className="c-client__payment-item">
                  <span className="c-client__payment-no">{data?.appointments_count}</span>
                  <span className="c-client__payment-text">All bookings</span>
                </div>
                <div className="c-client__payment-item">
                  <span className="c-client__payment-no">{data?.fulfilled_count}</span>
                  <span className="c-client__payment-text">Completed</span>
                </div>
                <div className="c-client__payment-item">
                  <span className="c-client__payment-no">{data?.cancelled_count}</span>
                  <span className="c-client__payment-text">Cancelled</span>
                </div>
                <div className="c-client__payment-item">
                  <span className="c-client__payment-no">{data?.no_shows_count}</span>
                  <span className="c-client__payment-text">No Shows</span>
                </div>
              </div>
            </div>
            <ClientAppointments data={data} />
          </div>
        </div>
      ) : null}
    </>
  ) : (
    <AccessDeniedPage />
  );
};

ClientInfoPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default ClientInfoPage;
