import React from 'react';
import { NavLink } from 'react-router-dom';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import { PER_PAGE } from '../../../config/apiConfig';

// eslint-disable-next-line react/prop-types
const Pagination = ({ current = null, count = 1, limit = PER_PAGE }) => {
  if (!count) return null;
  const currentPage = current ? current : +getQueryUri('page') || 1;
  const lastPage = Math.ceil(count / limit);
  const nextPage = lastPage !== currentPage ? currentPage + 1 : null;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const handleActive = (page) => currentPage === page;
  const link = (page) =>
    setQueryUri({
      ...getQueryUri(),
      page,
    });
  // eslint-disable-next-line react/prop-types
  const PageNo = ({ page }) => (
    <li className="c-pagination__item">
      <NavLink id={`page-${page}`} isActive={() => handleActive(page)} to={link(page)}>
        {page}
      </NavLink>
    </li>
  );
  if (lastPage === 1) return null;
  return (
    <div className="c-pagination">
      <ul className="c-pagination__list">
        {prevPage && (
          <li className="c-pagination__item">
            <NavLink id="page-prev" isActive={() => false} to={link(prevPage)}>
              <i className="fas fa-chevron-left" />
            </NavLink>
          </li>
        )}
        {currentPage !== 1 && +prevPage !== 1 && <PageNo page={1} />}

        {currentPage !== 1 && +prevPage !== 1 && prevPage - 1 !== 1 && <li className="c-pagination__item">...</li>}

        {prevPage && <PageNo page={prevPage} />}

        <PageNo page={currentPage} />

        {nextPage && lastPage !== nextPage && <PageNo page={nextPage} />}

        {lastPage !== currentPage && lastPage !== nextPage && lastPage !== nextPage + 1 && (
          <li className="c-pagination__item">...</li>
        )}

        {lastPage && currentPage !== lastPage && <PageNo page={lastPage} />}

        {nextPage && (
          <li className="c-pagination__item">
            <NavLink id="page-next" isActive={() => false} to={link(nextPage)}>
              <i className="fas fa-chevron-right" />
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
