import { DayNames } from '../utilities/DateHelpers';

/**
 *
 * @param workingHour {{
 *     id,
 *     week_day,
 *     start_time_tz,
 *     end_time_tz,
 *     start_time,
 *     end_time,
 *     is_active,
 * }}
 * @constructor
 */

function BusinessWorkingHoursObject(workingHour) {
  this.id = workingHour.id;
  this.dayName = DayNames[(workingHour.week_day + 1) % 7];
  this.week_day = workingHour.week_day;
  this.start_time = workingHour.start_time_tz || workingHour.start_time;
  this.end_time = workingHour.end_time_tz || workingHour.end_time;
  this.is_active = workingHour.is_active;
}

export default BusinessWorkingHoursObject;
