import React, { useContext, useEffect, useState } from 'react';
import { CheckCircle, PauseCircleFilled, Payment } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { getPendingTransfersList, postPendingTransferPayment } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import Pagination from '../../Common/UIKit/Pagination';
import useApi, { handleApi } from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import { TransferReasonChoice } from '../../../utilities/constants/choices';
import Checkbox from '../../Common/UIKit/Form/Checkbox';
import Button from '../../Common/UIKit/Form/Button';
import { getPriceFormat } from '../../../utilities/Price';
import { StripeElement } from '../../Common/Stripe';

const BalanceHistoryPage = () => {
  const [stripe, setStripe] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const { setHeader, business } = useContext(AppContext);
  useEffect(() => {
    setHeader('Balance History');
  }, [setHeader]);
  const [data, loading] = useApi(getPendingTransfersList, { page: true });

  useEffect(() => {
    if (data) {
      const updateSelectedList = (data?.results || []).reduce((acc, { id, amount, transferred }) => {
        if (amount < 0 && !transferred) return [...acc, { id, amount }];
        return acc;
      }, []);
      setSelectedList(updateSelectedList);
    }
  }, [data]);

  // const totalPayment = useMemo(
  //   () => (selectedList || []).reduce((acc, { amount }) => acc + amount * -1, 0).toFixed(2),
  //   [selectedList],
  // );

  const handleToggle = ({ id, amount }, checked) => {
    const updateSelectedList = [...selectedList];
    const index = updateSelectedList.findIndex((i) => i.id === id);
    if (index > -1 && !checked) {
      updateSelectedList.splice(index, 1);
    } else if (checked) {
      updateSelectedList.push({ id, amount });
    }
    setSelectedList(updateSelectedList);
  };

  const handlePayment = () => {
    setPaymentLoading(true);
    // handleApi(postPendingTransferPayment, { items: selectedList.map((i) => i.id) }, { list: false })
    handleApi(postPendingTransferPayment, {}, { list: false })
      .then((res) => {
        if (res?.session_id) setStripe(res);
        else setPaymentLoading(false);
      })
      .catch(() => {
        setPaymentLoading(false);
      });
  };
  console.log('Number(business?.transfers_balance || 0) <= 0', Number(business?.transfers_balance || 0) <= 0);
  return (
    <>
      {loading && !data ? (
        <PageLoading />
      ) : !!data?.results && data.results.length > 0 ? (
        <>
          <div className="c-panel-content mt-4">
            {loading && <PageLoading over />}
            <div className="c-table">
              <table>
                <thead>
                  <tr>
                    {false && <th />}
                    <th>Amount</th>
                    <th className="text-center">Reason</th>
                    <th className="text-center">Is Transferred</th>
                    <th className="text-center">Invoice</th>
                    <th className="text-center">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data.results.map((item, key) => (
                    <tr key={key}>
                      {false && (
                        <td>
                          <Checkbox
                            id={`check-${item.id}`}
                            disabled={+item.amount > 0 || item.transferred}
                            checked={selectedList.findIndex((i) => i.id === item.id) > -1}
                            onChange={(e) => handleToggle(item, e)}
                          />
                        </td>
                      )}
                      <td>{getPriceFormat(item.amount)}</td>
                      <td className="text-center">{TransferReasonChoice[item.reason]}</td>
                      <td
                        className={classNames('text-center', {
                          'text-success': item.transferred,
                          'text-warning': !item.transferred,
                        })}
                      >
                        {item.transferred ? (
                          <CheckCircle className="font-size-20" />
                        ) : (
                          <PauseCircleFilled className="font-size-20" />
                        )}
                      </td>
                      <td className="text-center">
                        <NavLink id={`linkInvoice-${item.id}`} to={`/sales/transactions/${item.invoice}`}>
                          Invoice
                        </NavLink>
                      </td>
                      <td className="text-center">{item.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <small className="font-weight-bold">Total</small>
                      <strong className="ml-3">{getPriceFormat(business?.transfers_balance || 0)}</strong>
                    </td>
                    {/*<td>*/}
                    {/*  /!*<strong>{getPriceFormat(totalPayment)}</strong>*!/*/}
                    {/*  <strong>{getPriceFormat(business?.transfers_balance || 0)}</strong>*/}
                    {/*</td>*/}
                    <td>
                      <Button
                        // disabled={+totalPayment <= 0}
                        // disabled={Number(business?.transfers_balance || 0) <= 0}
                        disabled={Number(business?.transfers_balance || 0) >= 0}
                        loading={paymentLoading}
                        id="paymentBtn"
                        onClick={handlePayment}
                      >
                        <Payment className="mr-2" />
                        <strong>Pay Balance</strong>
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {data && <Pagination count={data.count} />}
          {stripe && <StripeElement session_id={stripe?.session_id} onClose={() => setStripe(null)} />}
        </>
      ) : (
        <NotFoundData
          title="No Balance History"
          description="There is not any balance history data"
          icon="fas fa-history"
        />
      )}
    </>
  );
};

export default BalanceHistoryPage;
