import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InvoiceModalInformation } from '../../Invoice/InvoiceInformation';

// eslint-disable-next-line react/display-name
export const InvoiceModalPrint = forwardRef(({ data }, ref) => (
  <div className="c-invoice-detail p-5" ref={ref}>
    <InvoiceModalInformation data={data} />
  </div>
));

InvoiceModalPrint.propTypes = {
  data: PropTypes.any,
};
