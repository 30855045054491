import React, { useEffect, useState } from 'react';
import { Add, Collections } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Modal from '../../UIKit/Modal';
import { PageLoading } from '../../UIKit/Spinner';
import { handleApi } from '../../../../utilities/useApi';
import { getStylistPhotos } from '../../../../api';
import Button from '../../UIKit/Form/Button';
import StylistPhotoFormModal from './StylistPhotoFormModal';
import Image from '../../UIKit/Image';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const StylistPhotosModal = ({ onClose }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  // null, data item, if new it should set null
  const [itemModal, setItemModal] = useState(null);
  useEffect(() => {
    handleApi(getStylistPhotos)
      .then((data) => {
        setData(data);
      })
      .finally(() => setLoading(false));
  }, []);
  const handleSave = (item) => {
    setData((prev) => {
      const results = prev?.results || [];
      const ind = results.findIndex((i) => i.id === item?.id);
      if (ind > -1) {
        results[ind] = item;
      } else {
        results.push(item);
      }
      return {
        ...(prev || {}),
        results,
      };
    });
    setItemModal(null);
    setShowModal(false);
  };
  const handleEditItem = (item) => {
    setItemModal(item);
    setShowModal(true);
  };
  return (
    <>
      <Modal
        show
        lg={(data?.results || []).length === 0}
        auto={(data?.results || []).length > 0}
        sticky
        title="Photos"
        contentClass="c-user-stylist-profile__modal"
        bodyClass="c-user-stylist-profile__modal-body"
        onClose={onClose}
        saveBtn={false}
      >
        {loading ? (
          <PageLoading type="box" />
        ) : (
          <div className="c-user-stylist-profile__container">
            <div className="c-user-stylist-profile__section">
              <section className="c-user-stylist-profile__content">
                {(data?.results || []).length > 0 ? (
                  <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}>
                    <Masonry gutter={16}>
                      <div className="c-user-stylist-photo__add" onClick={() => handleEditItem(null)}>
                        <Add />
                        <span className="c-user-stylist-profile__add-label">Add Photo</span>
                      </div>
                      {data.results.map((i) => (
                        <div key={i.id} className="c-user-stylist-photo__item" onClick={() => handleEditItem(i)}>
                          <Image key={i.id} src={i.image} className="c-user-stylist-photo__img" />
                          <span className="c-user-stylist-photo__item-business">
                            {i.business_title || <em>No Business</em>}
                          </span>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                ) : (
                  <div className="c-user-stylist-profile__empty">
                    <Collections />
                    <h4>No photo added</h4>
                    <Button className="mt-3" onClick={() => setShowModal(true)}>
                      Add Photo
                    </Button>
                  </div>
                )}
              </section>
            </div>
          </div>
        )}
      </Modal>
      {showModal && (
        <StylistPhotoFormModal
          shouldSave={false}
          data={itemModal}
          onClose={() => setShowModal(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
};

StylistPhotosModal.propTypes = {
  data: PropTypes.any,
  shouldSave: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default StylistPhotosModal;
