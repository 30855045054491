import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Add } from '@material-ui/icons';
import MessageForm from '../../Common/Messages/MessageForm';
import MessageClientList from '../../Common/Messages/MessageClientList';
import MessageContent from '../../Common/Messages/MessageContent';
import MessageContentHeader from '../../Common/Messages/MessageContentHeader';
import { handleApi } from '../../../utilities/useApi';
import { getClientList, getProfile } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';
import MessageModal from '../../Common/Messages/MessageModal';

const MessagePage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [clientChange, setChangeClient] = useState(false);
  const [clients, setClients] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [client, setClient] = useState(null);
  const { setUser, setHeader } = useContext(AppContext);
  // use for mobile view
  const [showClients, setShowClients] = useState(true);
  const { access: _clients } = usePermission('client', 'crud', 'view');
  const { access: _messages } = usePermission('messages', 'crud');

  const contentRef = useRef(null);

  useEffect(() => {
    setHeader('Client Messages');
  }, [setHeader]);

  useEffect(() => {
    if (_clients && !clients) {
      handleApi(getClientList, { has_user: 1 }, { infinitePage: true, loadingFn: setLoading, maxPage: 3 }).then(
        (res) => {
          const data = res.results.filter((c) => c.user);
          setClients(data);
        },
      );
    }
  }, [_clients]);

  const handleChangeClient = (_client) => {
    setClient(_client);
    setData(null);
    if (_client) {
      setChangeClient(true);
      setTimeout(() => {
        setChangeClient(false);
      }, 100);
    }
    if (_client?.id !== client?.id) {
      setTimeout(() => {
        handleApi(getProfile).then(setUser);
      }, 1000);
    }
    setShowClients(!_client);
  };

  const handleDraftMessage = (message) => {
    if (data?.results) {
      const updateData = [...data.results];
      updateData.push(message);
      setData({
        ...data,
        results: updateData,
      });
    }
  };
  const handleSaveMessage = (message) => {
    if (data?.results) {
      const updateData = [...data.results];
      const draftIndex = updateData.findIndex((i) => i?.id === message.id);
      if (draftIndex > -1) {
        updateData[draftIndex] = message;
      } else {
        updateData.push(message);
      }
      setData({
        ...data,
        results: updateData,
      });
      contentRef.current.scrollTo(0, contentRef.current.scrollHeight);
    }
  };

  const handleSaveModal = () => {
    setAddModal(false);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  if (!_clients) return <AccessDeniedPage title="Clients List Access Denied" />;
  if (!_messages?.view) return <AccessDeniedPage title="Messages Access Denied" />;

  return loading ? (
    <PageLoading />
  ) : (
    <>
      {addModal && <MessageModal onClose={() => setAddModal(false)} onSave={handleSaveModal} />}
      <div className="c-messages-container">
        <div className={classnames('c-messages', { 'c-messages--inactive': showClients })}>
          <MessageContentHeader data={data} client={client} onDeselectClient={() => handleChangeClient(null)} />
          {!clientChange && (
            <>
              <MessageContent data={data} client={client} onData={setData} contentRef={contentRef} ref={contentRef} />
              {client && (
                <>
                  {_messages?.modify ? (
                    <MessageForm client={client} onDraft={handleDraftMessage} onSave={handleSaveMessage} />
                  ) : (
                    <div className="alert alert-warning">You do not have access to send any message!</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={classnames('c-messages-sidebar', { 'c-messages-sidebar--active': showClients })}>
          <div className="c-messages__header">
            <h4 className="c-messages__title">Messages</h4>
            <div className="c-messages__add-btn" onClick={() => setAddModal(true)}>
              <Add />
            </div>
          </div>
          <MessageClientList clients={clients || []} selected={client} onSelect={handleChangeClient} />
        </div>
      </div>
    </>
  );
};

export default MessagePage;
