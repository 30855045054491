import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ExitToApp } from '@material-ui/icons';
import Dropdown from '../../UIKit/Dropdown';
import { IMAGE_DEFAULT_WOMAN_AVATAR } from '../../../../assets/Images';
import { Types } from '../../../../config/actionTypes';
import { handleApi } from '../../../../utilities/useApi';
import { getProfile } from '../../../../api';
import { AppContext } from '../../../../context/providers';
import UserSidebar from '../UserSidebar';

const UserHeader = ({ only_logout }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { user, setUser } = useContext(AppContext);

  const handleLogout = () => {
    localStorage.removeItem(Types.USER_TOKEN);
    localStorage.removeItem(Types.USER_REFRESH);
    localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
    localStorage.removeItem(Types.STRIPE_PAYMENT);
    localStorage.removeItem(Types.SYSTEM_TYPE);
    localStorage.removeItem(Types.CITY);
    localStorage.removeItem(Types.BUSINESS_LOCATION);
    localStorage.removeItem(Types.LATEST_APPOINTMENT);
    localStorage.removeItem(Types.COUNTRIES);
    window.location.reload();
  };

  const token = localStorage.getItem(Types.USER_TOKEN);

  useEffect(() => {
    if (token) handleApi(getProfile).then(setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const username = user
    ? user.full_name
      ? user.full_name
      : (user?.email || '').substr(0, (user.email || '').lastIndexOf('@'))
    : '';

  const options = useMemo(() => {
    if (!only_logout) {
      return [
        // {
        //     type: 'node',
        //     node: <span className="c-panel-header__user-dropdown">{username}</span>
        // },
        {
          type: 'link',
          label: 'My Profile',
          url: '/settings/profile',
        },
        {
          type: 'link',
          label: 'Settings',
          url: '/settings',
        },
        {
          type: 'divider',
        },
        {
          type: 'node',
          node: (
            <span id="logout" className="link" onClick={handleLogout}>
              Logout
            </span>
          ),
        },
      ];
    }
    return [
      {
        type: 'node',
        node: (
          <span className="link text-nowrap" onClick={handleLogout}>
            <ExitToApp className="mr-3" /> Sign out
          </span>
        ),
      },
    ];
  }, [only_logout]);

  return (
    <div className="c-panel-header__user">
      {false && (
        <Dropdown options={options}>
          <div id="header-user" className="c-panel-header__user-info">
            <img
              src={user?.avatar || user?.default_avatar_url || IMAGE_DEFAULT_WOMAN_AVATAR}
              alt={username}
              className="c-panel-header__user-avatar"
            />
            <span className="c-panel-header__user-name">{username}</span>
          </div>
        </Dropdown>
      )}
      <div id="header-user" className="c-panel-header__user-info" onClick={() => setOpenSidebar(true)}>
        <img
          src={user?.avatar || user?.default_avatar_url || IMAGE_DEFAULT_WOMAN_AVATAR}
          alt={username}
          className="c-panel-header__user-avatar"
        />
        <span className="c-panel-header__user-name">{username}</span>
      </div>
      <UserSidebar isOpen={openSidebar} onClose={() => setOpenSidebar(false)} />
    </div>
  );
};

UserHeader.propTypes = {
  only_logout: PropTypes.any,
};

export default UserHeader;
