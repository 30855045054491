import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MARKETPLACE_URL } from '../../../config/appConfig';
import { IMAGE_DEFAULT, IMAGE_LOGO, IMAGE_LOGO_IMG } from '../../../assets/Images';
import UserHeader from './Header/UserHeader';
import { DesktopWindows, BusinessCenter, Add } from '@material-ui/icons';
import { AppContext } from '../../../context/providers';
import { PageLoading } from '../UIKit/Spinner';
import { Types } from '../../../config/actionTypes';

const LocationImage = ({ item }) => {
  const url = useMemo(() => {
    if ((item?.latitude && item?.longitude) || item?.city) {
      let u = 'https://maps.googleapis.com/maps/api/staticmap?';
      if (item?.latitude && item?.longitude) u += `center=${item?.latitude},${item?.longitude}`;
      else u += `center=${(item?.city || '').toLowerCase()}`;
      u += '&zoom=13&size=300x300';
      u += '&key=AIzaSyBmvvn3UPSO3T3NSXMmngtwNoxfsd8qDZE';
      if (item?.latitude && item?.longitude) u += `&markers=${item?.latitude},${item?.longitude}`;
      return u;
    } else {
      return item?.avatar || IMAGE_DEFAULT;
    }
  }, [item]);
  return <img alt={item.title} className="c-panel-setup__item-location" src={url} />;
};

LocationImage.propTypes = {
  item: PropTypes.any,
};

const LocationsLayout = ({ onShowSetup }) => {
  const { locations } = useContext(AppContext);
  // useEffect(() => {
  //   MapService.getPicture();
  // }, []);
  useEffect(() => {
    if (locations && locations.length === 1) {
      localStorage.setItem(Types.BUSINESS_LOCATION, locations[0].id);
      localStorage.removeItem(Types.LATEST_APPOINTMENT);
      window.location.reload();
    }
  }, [locations]);
  const handleSelectLocation = (item) => {
    localStorage.setItem(Types.BUSINESS_LOCATION, item.id);
    localStorage.removeItem(Types.LATEST_APPOINTMENT);
    window.location.reload();
  };
  return locations && locations.length > 1 ? (
    <>
      <div className="c-panel-setup">
        <div className="c-panel-setup-header">
          <a href={`${MARKETPLACE_URL}/landing-software/`} target="_blank" className="c-panel-setup-header__logo">
            <picture>
              <source media="(min-width:576px)" srcSet={IMAGE_LOGO} />
              <source media="(max-width:576px)" srcSet={IMAGE_LOGO_IMG} />
              <img src={IMAGE_LOGO} alt="Beatuster" className="c-panel-setup-header__logo-image" />
            </picture>
          </a>
          <div className="c-panel-setup-header__user">
            <UserHeader only_logout />
          </div>
        </div>
        <div className="c-panel-setup-content">
          <h1 className="c-panel-setup-title">Select A Business Location</h1>
          <div className="c-panel-setup__locations">
            {(locations || []).map((item) => (
              <div
                key={item.id}
                className="c-panel-setup__item c-panel-setup__item--sm"
                onClick={() => handleSelectLocation(item)}
              >
                <span className="c-panel-setup__item-title">{item.title}</span>
                <span className="c-panel-setup__item-icon">
                  {item.software_setup && <DesktopWindows />}
                  {item.marketplace_setup && <BusinessCenter />}
                </span>
              </div>
            ))}
            <div className="c-panel-setup__item c-panel-setup__item--sm" onClick={onShowSetup}>
              <Add className="c-panel-setup__item-add-svg" />
              <span className="c-panel-setup__item-add-title">Add New Location</span>
            </div>
          </div>
        </div>
        <div className="c-panel-setup-footer">&copy; {new Date().getFullYear()} Beautster.com (SkillPublic Inc.)</div>
      </div>
    </>
  ) : (
    <PageLoading over />
  );
};

LocationsLayout.propTypes = {
  onShowSetup: PropTypes.func,
};

export default LocationsLayout;
