import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useHistory, NavLink } from 'react-router-dom';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import Button from '../../Common/UIKit/Form/Button';
import StaffClosedDateModal from '../../Common/Staff/StaffClosedDateModal';
import useApi from '../../../utilities/useApi';
import { getStaffClosedDateList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { getFormat24H, getFormatDate } from '../../../utilities/DateHelpers';
import Pagination from '../../Common/UIKit/Pagination';
import { AppContext } from '../../../context/providers';
// import { TourGuide } from '../../Common/UIKit/TourContent';
import { staffCloseDateSteps } from '../../../config/tourSteps';
import usePermission from '../../../utilities/usePermission';

const Page_URL = '/staff/closed-dates/';

// eslint-disable-next-line react/prop-types
const StaffClosedDates = ({ headerEl }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  let [, loading] = useApi(getStaffClosedDateList, { page: true, key: 'staffcloseddate' }, setData);
  const { setHeader } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const { access: _access } = usePermission('staffcloseddate', 'crud');

  const guideSteps = useMemo(
    () => (data && !(id || location.pathname === '/staff/closed-dates/add') ? staffCloseDateSteps : null),
    [data, location, id],
  );
  useEffect(() => {
    setHeader('Staff / Dates Closed', { guide: guideSteps });
  }, [setHeader]);

  useEffect(() => {
    if (id || location.pathname === '/staff/closed-dates/add') {
      handleOpenModal();
    }
  }, [location, id]);

  // useEffect(() => {
  //   if (data && !(id || location.pathname === '/staff/closed-dates/add')) {
  //     setIsTourOpen(true);
  //   }
  // }, [data]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    history.replace('/staff/closed-dates');
    setShowModal(false);
  };

  const handleSaveItem = (item) => {
    if (typeof item === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    getStaffClosedDateList().then((data) => setData(data));
    handleCloseModal();
  };

  const getFormat = (val) => {
    const date = getFormatDate(new Date(val));
    const t = val.split(' ');
    const time = `${getFormat24H(`${t[t.length - 2]} ${t[t.length - 1]}`)}:00`;
    return `${date} ${time}`;
  };

  return (
    <>
      {showModal && _access?.modify && <StaffClosedDateModal onSave={handleSaveItem} onClose={handleCloseModal} />}
      <HeadOptions
        leftElement={headerEl}
        rightElement={
          _access?.modify && (
            <Button id="addClosedBtn" data-tour="add-btn" color={'main'} link="/staff/closed-dates/add">
              New Dates Closed
            </Button>
          )
        }
      />
      {_access?.view ? (
        <>
          {loading ? (
            <PageLoading />
          ) : data?.results.length > 0 ? (
            <>
              <div data-tour="list" className="c-panel-content">
                <div className="c-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Staff name</th>
                        <th>Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        {_access?.modify && <th />}
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item, key) => (
                        <tr key={key}>
                          <td>{item.staffName}</td>
                          <td>{item.title}</td>
                          <td>{getFormat(item.start_time)}</td>
                          <td>{getFormat(item.end_time)}</td>
                          {_access?.modify && (
                            <td>
                              <NavLink id={`link-${item.id}`} to={`${Page_URL}${item.id}/edit`}>
                                Manage
                              </NavLink>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination count={data.count} />
            </>
          ) : (
            <NotFoundData title="No Dates Closed Exists" />
          )}
          {/*<TourGuide*/}
          {/*  steps={staffCloseDateSteps}*/}
          {/*  startAt={tourStep}*/}
          {/*  getCurrentStep={setTourStep}*/}
          {/*  isOpen={isTourOpen}*/}
          {/*  onRequestClose={() => setIsTourOpen(false)}*/}
          {/*/>*/}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};
export default StaffClosedDates;
