import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import BusinessWorkingHoursObject from '../objects/BusinessWorkingHoursObject';

const { PUT, PATCH, POST, GET, DELETE } = ApiMethods;

export const getBusinessWorkingHours = async () => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.businessWorkingHours.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new BusinessWorkingHoursObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

export const createBusinessWorkingHours = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.businessWorkingHours.create,
  });
  if (response.data) {
    return Promise.resolve(new BusinessWorkingHoursObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

export const updateBusinessWorkingHours = async (id, params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: createURL(ApiRoutes.businessWorkingHours.update, { id }),
  });
  if (response.data) {
    return Promise.resolve(new BusinessWorkingHoursObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

export const partialUpdateBusinessWorkingHours = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    params,
    url: createURL(ApiRoutes.businessWorkingHours.update, { id }),
  });
  if (response.data) {
    return Promise.resolve(new BusinessWorkingHoursObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

export const deleteBusinessWorkingHours = async (id) => {
  const response = await callApi({
    method: DELETE,
    url: createURL(ApiRoutes.businessWorkingHours.delete, { id }),
  });
  if (response.data) {
    return Promise.resolve(new BusinessWorkingHoursObject(response.data));
  } else {
    return Promise.reject(response);
  }
};
