import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { getPriceFormat } from '../../../../utilities/Price';
import ReleasePromotionButton from '../ReleasePromotionButton';
import CampaignDetailText from '../CampaignDetailText';

const PromotionDiscountItem = ({ access, page_url, item, modify, onChangePublish }) => {
  const {
    id,
    code,
    name,
    services,
    amount,
    unit,
    expire_date,
    usage_limit,
    expire_after,
    expired,
    used_count,
    is_usable,
    campaign,
  } = item;

  const [status, statusStyle] = useMemo(() => {
    const res = expired ? 'Expired' : used_count === usage_limit ? 'Reached Maximum Usage' : is_usable ? 'Usable' : '-';
    const style = expired || used_count === usage_limit ? 'text-danger' : 'text-success';
    return [res, style];
  }, [item]);

  return (
    <tr>
      <td>{code}</td>
      <td className="text-center">{name}</td>
      <td className="text-center">{services.length}</td>
      <td className="text-center">{getPriceFormat(+amount, unit)}</td>
      <td className="text-center">{usage_limit}</td>
      <td className="text-center">{expire_after && `${expire_after} ${+expire_after > 1 ? 'Days' : 'Day'}`}</td>
      <td className="text-center">{expire_date}</td>
      <td className={classNames('text-center', statusStyle)}>{status}</td>
      <td className="text-center">
        <ReleasePromotionButton isCampaign type="discount" item={item} access={access} onSubmit={onChangePublish} />
      </td>
      <td className="text-center">
        <ReleasePromotionButton type="discount" item={item} access={access} onSubmit={onChangePublish} />
      </td>

      <CampaignDetailText isCampaign page_url={page_url} campaign={campaign} access={access?.campaign_report} id={id} />
      {modify && (
        <td className="text-center">
          <NavLink to={`${page_url}${id}`}>Manage</NavLink>
        </td>
      )}
    </tr>
  );
};

PromotionDiscountItem.propTypes = {
  page_url: PropTypes.string,
  item: PropTypes.any,
  modify: PropTypes.bool,
  onChangePublish: PropTypes.func,
  access: PropTypes.shape({
    send_campaign: PropTypes.bool,
    campaign_report: PropTypes.bool,
  }),
};

export default PromotionDiscountItem;
