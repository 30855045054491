import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Instagram, Image } from '@material-ui/icons';
// import InstagramLogin from 'react-instagram-login';
import Modal from '../UIKit/Modal';
import { PARTNER_URL } from '../../../config/appConfig';
import { AppContext } from '../../../context/providers';
// import Button from '../UIKit/Form/Button';

const BookablePhotoSelectorModal = ({ onClose }) => {
  const { business } = useContext(AppContext);
  // const responseInstagram = (response) => {
  // };
  // AQCIm2F8J-6GNqMV9dYe8baI7mvWt61XvKg7NtL0RSr7xVpWGfNv2_iYJtHytSQ9shRHVLkLaFJgfVivPx6j-Xoe9zhBCXWMqmVBfeRXsm6-95JryRuZZ-GqpFfaOboXPQGmcq_Xrj_R6n9E1ESafLN_gffZzxhG8ASn1yVogY0PADpRaCcElB9rp9QPt_xAOEJA8M_7p4F42pkgZdwF29lKlmc4UPoElflDgwuxQ5SqAQ
  // access_token => IGQVJWLWxHVTh2Y2FjNzVkVUljR005SzB6aXN5YlRMT1JSSW9hSmRsVWZAaMnE0SkVSV0hoRTR1Q05jTzdVSEpuZAVJZAR2tsVGtDeGxNR0ZAWUWRqRDhyOVpFbUVrQ3l2dm9oeU8xdXE3Q1o3eWhrX2pEaHVYd3Jwa25TeTNR
  // user_id => 17841450484845381
  // api-version => v12.0
  // https://www.instagram.com/oauth/authorize?client_id=965159147682266&redirect_uri=https://partners.stage.beautster.com/bookable_photos/add/&scope=user_profile,user_media&response_type=code
  const handleAuthInstagram = () => {
    let instaUrl = 'https://www.instagram.com/oauth/authorize?';
    instaUrl += 'client_id=965159147682266';
    instaUrl += `&redirect_uri=${PARTNER_URL}bookable_photos/instagram/`;
    instaUrl += '&scope=user_profile,user_media';
    instaUrl += '&response_type=code';
    window.location.href = instaUrl;
  };
  return (
    <Modal title="Select adding photo method" show auto bodyClass="p-0" saveBtn={false} onClose={onClose}>
      <div className="c-bookable-photo__methods">
        <NavLink id="upload-photo-selector" to="/bookable_photos/upload" className="c-bookable-photo__methods-item">
          <Image className="c-bookable-photo__methods-item-icon" />
          <span className="c-bookable-photo__methods-item-title">Upload photo</span>
        </NavLink>
        {business?.instagramToken && business?.instagramUserId ? (
          <NavLink
            id="instagram-selector"
            to="/bookable_photos/instagram"
            className="c-bookable-photo__methods-item"
            onClick={onClose}
          >
            <Instagram className="c-bookable-photo__methods-item-icon" />
            <span className="c-bookable-photo__methods-item-title">Import From Instagram</span>
          </NavLink>
        ) : (
          <div id="instagram-selector" className="c-bookable-photo__methods-item" onClick={handleAuthInstagram}>
            <Instagram className="c-bookable-photo__methods-item-icon" />
            <span className="c-bookable-photo__methods-item-title">Import From Instagram</span>
          </div>
        )}
      </div>
      {/*<Button link="/bookable_photos/upload" onClick={onClose}>*/}
      {/*  Upload photo*/}
      {/*</Button>*/}
      {/*<InstagramLogin*/}
      {/*  scope="user_profile,user_media"*/}
      {/*  clientId="965159147682266"*/}
      {/*  buttonText="Login"*/}
      {/*  onSuccess={responseInstagram}*/}
      {/*  onFailure={responseInstagram}*/}
      {/*/>*/}
    </Modal>
  );
};
BookablePhotoSelectorModal.propTypes = {
  onClose: PropTypes.func,
};

export default BookablePhotoSelectorModal;
