import React, { useEffect, useState } from 'react';
import SetupMarketplaceProfileForm from './SetupMarketplaceProfileForm';
import SetupMarketplaceBookingMethods from './SetupMarketplaceBookingMethods';
import SetupMarketplaceLocation from './SetupMarketplaceLocation';
import SetupMarketplaceLocationForm from './SetupMarketplaceLocationForm';
import SetupMarketplaceStripAccount from './SetupMarketplaceStripAccount';
import SetupMarketplaceCongratulations from './SetupMarketplaceCongratulations';
import { Types } from '../../../../config/actionTypes';
import SetupHeadSteps from '../../Setup/SetupHeadSteps';
import { MarketplaceStepList } from '../../../../utilities/constants/setup';
import SetupSide from '../../Setup/SetupSide';
import PreviewSetupMarketplace from './PreviewSetupMarketplcae';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceContent = ({ isNew = false, reference, goal, onClose }) => {
  const [step, setStep] = useState(0);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    if (reference) {
      setIsStarted(true);
    }
  }, [reference]);

  useEffect(() => {
    if (reference) {
      setStep(3);
      localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
    } else if (!isStarted) setStep(0);
  }, [isStarted, reference]);

  return (
    <div className="c-setup-system-static">
      <SetupSide list={MarketplaceStepList} isPreview={!isStarted} step={step} />
      <div className="c-setup-system-content">
        {isStarted ? (
          <>
            <SetupHeadSteps list={MarketplaceStepList} active={step} />
            <div className="c-setup-system__form">
              {step === 0 ? (
                <SetupMarketplaceProfileForm isNew={isNew} goal={goal} onNext={() => setStep(step + 1)} />
              ) : step === 1 ? (
                <SetupMarketplaceBookingMethods
                  isNew={isNew}
                  onPrev={() => setStep(step - 1)}
                  onNext={() => setStep(step + 1)}
                />
              ) : step === 2 ? (
                <SetupMarketplaceLocation
                  isNew={isNew}
                  onPrev={() => setStep(step - 1)}
                  onNext={() => setStep(step + 1)}
                />
              ) : step === 3 ? (
                <SetupMarketplaceLocationForm
                  isNew={isNew}
                  onPrev={() => setStep(step - 1)}
                  onNext={() => setStep(step + 1)}
                />
              ) : step === 4 ? (
                <SetupMarketplaceStripAccount
                  isNew={isNew}
                  onPrev={() => setStep(step - 1)}
                  onNext={() => setStep(step + 1)}
                />
              ) : (
                <SetupMarketplaceCongratulations isNew={isNew} onPrev={() => setStep(step - 1)} onSave={onClose} />
              )}
            </div>
          </>
        ) : (
          <PreviewSetupMarketplace onStarted={() => setIsStarted(true)} />
        )}
      </div>
    </div>
  );
};

export default SetupMarketplaceContent;
