import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Modal from '../../UIKit/Modal';
import { createStaffHours, updateStaffHours } from '../../../../api';
import { DayNames } from '../../../../utilities/DateHelpers';
import { handleApiSave } from '../../../../utilities/useApi';
import TimeSelect from '../../Selections/TimeSelect';
import { ControlSwitch } from '../../UIKit/Form/Switch';

const daysOfWeek = [...DayNames];
daysOfWeek.push(daysOfWeek[0]);
// daysOfWeek.shift();

const StaffWorkingHoursModal = ({ selected, onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [active, setIsActive] = useState(false);
  const { handleSubmit, control, errors } = useForm();

  useEffect(() => {
    if (selected?.item) {
      setIsActive(selected.item?.is_active);
    }
  }, [selected]);

  const onSubmit = (data) => {
    const body = {
      ...data,
      week_day: selected.day % 7,
      staff: selected.staff.id,
    };

    handleApiSave(createStaffHours, updateStaffHours, body, selected?.item?.id, { loadingFn: setSaveLoading })
      .then((res) =>
        onSave({
          ...selected,
          data: res,
        }),
      )
      .catch(() => {});
  };

  const getTime = () => {
    if (selected?.item) {
      let startHours = selected.item.start_time.split(' ');
      let endHours = selected.item.end_time.split(' ');

      let [startHour, startMin] = startHours[0].split(':');
      let [endHour, endMin] = endHours[0].split(':');

      startHour = startHours[1].toLowerCase() === 'am' ? startHour : +startHour + 12;
      endHour = endHours[1].toLowerCase() === 'am' ? endHour : +endHour + 12;

      // const { hours: startHour, min: startMin } = getFormat24H(selected.item.start_time, 'object');
      // const { hours: endHour, min: endMin } = getFormat24H(selected.item.end_time, 'object');
      return {
        start_time: `${startHour}:${startMin}:00`,
        end_time: `${endHour}:${endMin}:00`,
      };
    }
    return null;
  };
  return (
    <Modal
      show
      title={`${selected.staff.name}'s Working Hours`}
      description={`Edit ${selected.staff.name}'s Working Hours on ${daysOfWeek[selected?.day % 7]}`}
      onSave={handleSubmit(onSubmit)}
      saveLoading={saveLoading}
      bodyClass="min-vh-40"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="c-staff__hours-container">
          <TimeSelect
            control={control}
            name="start_time"
            rules={{ required: 'Please fill start time' }}
            defaultValue={getTime()?.start_time ? getTime().start_time : null}
            label="Start Time"
            iconLabel={false}
            className="height-time"
            isDisabled={!active}
            errorMessage={errors && errors.start_time ? errors.start_time.message : null}
          />
          <TimeSelect
            control={control}
            name="end_time"
            rules={{ required: 'Please fill end time' }}
            defaultValue={getTime()?.end_time ? getTime().end_time : null}
            label="End Time"
            popperPlacement="top"
            iconLabel={false}
            className="height-time"
            isDisabled={!active}
            errorMessage={errors && errors.end_time ? errors.end_time.message : null}
          />
          <ControlSwitch
            formGroup
            control={control}
            id="isActive"
            label={active ? 'Active Time' : 'Inactive Time'}
            name="is_active"
            onChangeValue={setIsActive}
            defaultValue={selected?.item?.is_active}
          />
        </div>
      </form>
    </Modal>
  );
};

StaffWorkingHoursModal.propTypes = {
  selected: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default StaffWorkingHoursModal;
