import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { getQueryUri } from './URI';
import { PER_PAGE } from '../config/apiConfig';
import { handleFormError } from './HandleErrors';
import { AppContext } from '../context/providers';
import usePermission from './usePermission';

const optionsDelete = {
  title: 'Confirm to delete this item',
  message: 'Are you sure to delete this item.',
  loadingFn: () => {},
};
export const handleApiDeleteById = async (fn, id = null, options = optionsDelete) =>
  new Promise((resolve, reject) => {
    if (!!id || typeof id === 'boolean') {
      confirmAlert({
        title: options.title,
        message: options.message,
        buttons: [
          {
            label: 'Yes',
            className: 'bg-danger',
            onClick: () => {
              options.loadingFn(true);
              fn(id)
                .then(() => resolve(true))
                .catch((e) => {
                  if (+e.status !== 406) {
                    reject(e);
                  } else {
                    resolve(true);
                  }
                })
                .finally(() => {
                  options.loadingFn(false);
                });
            },
          },
          {
            label: 'No',
            onClick: () => {
              reject(false);
            },
          },
        ],
      });
    }
  });

const optionsSave = {
  loadingFn: () => {},
  setError: null,
};
export const handleApiSave = async (fnCreate, fnUpdate, body, id = null, options = optionsSave) => {
  const { loadingFn, setError } = { ...optionsSave, ...options };
  return new Promise((resolve, reject) => {
    try {
      loadingFn(true);
      if (!!id && fnUpdate) {
        fnUpdate(id, body)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            handleFormError(e, setError);
            reject(e);
          })
          .finally(() => loadingFn(false));
      } else {
        fnCreate(body)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            handleFormError(e, setError);
            reject(e);
          })
          .finally(() => loadingFn(false));
      }
    } catch (e) {
      loadingFn(false);
    }
  });
};

const optionsApi = {
  key: '',
  defaultData: null,
  infinitePage: false,
  limit: 20,
  page: 1,
  maxPage: null,
  list: true,
  storage: null,
  loadingFn: () => {},
};

export const handleApi = (fn, params, options = optionsApi) => {
  let { loadingFn, infinitePage, limit, list, defaultData, storage, maxPage } = { ...optionsApi, ...options };
  const perPage = limit ? limit : PER_PAGE;
  list = typeof list === 'boolean' ? list : true;
  if (storage) {
    const storageMemory = localStorage.getItem(storage);
    if (storageMemory) {
      if (JSON.parse(storageMemory)?.results.length > 0) {
        return new Promise((resolve) => {
          resolve(JSON.parse(storageMemory));
        });
      }
    }
  }
  if (!!infinitePage) {
    let page = options?.page ? options.page : 1;
    return new Promise((resolve, reject) => {
      if (loadingFn) loadingFn(true);
      if (!defaultData || defaultData.next) {
        fn(list ? { ...params, page: +page, limit: +perPage } : params)
          .then((res) => {
            const updatedData =
              !!defaultData && defaultData.results
                ? { ...res, results: [...defaultData.results, ...res.results] }
                : res;
            if (!res.next || (maxPage !== null && maxPage === page)) {
              resolve(updatedData);
              if (storage) {
                if (updatedData.results.length > 0) localStorage.setItem(storage, JSON.stringify(updatedData));
              }
            } else if (res.next) {
              const newPage = +page + 1;
              handleApi(fn, params, {
                ...options,
                list,
                page: newPage,
                defaultData: updatedData,
                loadingFn: () => {},
              })
                .then(resolve)
                .catch(reject);
            }
          })
          .catch(reject)
          .finally(() => {
            if (loadingFn) loadingFn(false);
          });
      } else {
        if (loadingFn) loadingFn(false);
      }
    });
  }
  return new Promise((resolve, reject) => {
    if (loadingFn) loadingFn(true);
    fn(list ? { limit: PER_PAGE, ...params } : params)
      .then((res) => {
        resolve(res);
      })
      .catch(reject)
      .finally(() => {
        if (loadingFn) loadingFn(false);
      });
  });
};
handleApi.propTypes = {
  fn: PropTypes.func.isRequired,
  params: PropTypes.any,
  options: PropTypes.any,
};

export const useApiById = (fn, _id = null, cb) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const params = useParams();
  let id = _id;
  if (typeof id === 'boolean') id = false;
  else if (!id) id = params?.id || null;
  useEffect(() => {
    if (!!id) {
      setLoading(true);
      fn(id)
        .then((res) => {
          setData(res);
          if (cb) cb(res);
        })
        .catch((err) => {
          if (cb) cb(null, err);
          if (err.status && +err.status === 404) {
            history.replace('/notfound');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (typeof id === 'boolean' || _id === null || typeof id === 'undefined') {
      if (cb) cb(null);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return [data, loading];
};

const useApi = (fn, params = { key: '' }, cb) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prev, setPrev] = useState(null);

  const { system, userPermissions: p } = useContext(AppContext);
  const { isOwner } = usePermission();

  const history = useHistory();
  let locationParams = useParams();
  const infinitePage = params?.infinitePage;
  const perPage = params?.limit ? params?.limit : PER_PAGE;
  let page;
  let urlParams = {};

  const isDenied = useMemo(() => {
    if (p !== null) {
      if (!isOwner && params?.key && typeof params?.key === 'string') {
        const [_key, index] = params?.key.split('.');
        return !p[_key]?.[index || 'crud']?.view;
      }
      return false;
    }
    return true;
  }, [isOwner, p]);

  if (params?.page && typeof params?.page === 'boolean') page = getQueryUri('page') || 1;
  if (params?.search && typeof params?.search === 'boolean') urlParams.search = getQueryUri('q') || undefined;
  if (params?.status && typeof params?.status === 'boolean') urlParams.status = getQueryUri('status') || undefined;
  if (params?.staff && typeof params?.staff === 'boolean') urlParams.staff = getQueryUri('staff') || undefined;
  if (params?.service && typeof params?.service === 'boolean') urlParams.service = getQueryUri('service') || undefined;
  if (params?.to_date && typeof params?.to_date === 'boolean') urlParams.to_date = getQueryUri('to_date') || undefined;
  if (params?.month && typeof params?.month === 'boolean') urlParams.month = getQueryUri('month') || undefined;
  if (params?.from_date && typeof params?.from_date === 'boolean')
    urlParams.from_date = getQueryUri('from_date') || undefined;

  const paramsBody = { ...params };
  if (typeof params?.page === 'boolean') delete paramsBody.page;
  if (typeof params?.limit === 'boolean') delete paramsBody.limit;
  if (typeof params?.search === 'boolean') delete paramsBody.search;
  if (typeof params?.status === 'boolean') delete paramsBody.status;
  if (typeof params?.staff === 'boolean') delete paramsBody.staff;
  if (typeof params?.service === 'boolean') delete paramsBody.service;
  if (typeof params?.month === 'boolean') delete paramsBody.month;
  delete paramsBody.infinitePage;

  if (Object.keys(urlParams).length === 0 && typeof page === undefined) locationParams = null;

  if (typeof page === undefined) page = 1;

  const getData = (infinite, data = null) => {
    fn({ page: +page, limit: +perPage, ...paramsBody, ...urlParams })
      .then((res) => {
        if (!infinite) window.scrollTo(0, 0);
        const updatedData =
          infinite && !!data && data.results ? { ...res, results: [...data.results, ...res.results] } : res;
        setData(updatedData);
        if (cb) cb(updatedData);
        if (infinite && res.next) {
          ++page;
          getData(infinite, updatedData);
        }
      })
      .catch((err) => {
        if (cb) cb(null, err);
        if (err.status && +err.status === 404) {
          history.replace('/notfound');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isDenied) {
      if (
        !prev ||
        (!!prev &&
          (JSON.stringify(prev.urlParams) !== JSON.stringify(urlParams) ||
            page !== prev.page ||
            prev.system !== system))
      ) {
        setPrev({ urlParams, page, system });
        setLoading(true);
        getData(infinitePage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, system, locationParams, urlParams, isDenied]);

  return [data, loading];
};

useApi.propTypes = {
  fn: PropTypes.func.isRequired,
  params: PropTypes.shape({
    staff: PropTypes.string,
    limit: PropTypes.number,
    page: PropTypes.bool,
    search: PropTypes.bool,
    infinitePage: PropTypes.bool,
  }),
  cb: PropTypes.func,
};

export default useApi;
