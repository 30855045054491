import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Backdrop from '../UIKit/Backdrop';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getAppointmentList, getBusiness, getPermissionList, getProfile, partialUpdateBusiness } from '../../../api';
import SetupMarketplaceModal from '../SystemSetup/Marketplace/SetupMarketplaceModal';
import { Types } from '../../../config/actionTypes';
import { AppContext } from '../../../context/providers';
import { PageLoading } from '../UIKit/Spinner';
import SetupSoftwareModal from '../SystemSetup/Software/SetupSoftwareModal';
import MarketplaceCongressModal from '../SystemSetup/Marketplace/MarketplaceCongressModal';
import SoftwareCongressModal from '../SystemSetup/Software/SoftwareCongressModal';
import toaster from 'toasted-notes';
import Alert from '../UIKit/Alert';
import BothCongressModal from '../SystemSetup/Both/BothCongressModal';
import SetupLayout from './SetupLayout';
import LocationsLayout from './LocationsLayout';
import usePermission from '../../../utilities/usePermission';

const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [showSetupLayout, setShowSetupLayout] = useState(false);
  const [showSetup, setShowSetup] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [reference, setReference] = useState(null);
  const token = localStorage.getItem(Types.USER_TOKEN);
  const history = useHistory();

  const {
    setBusiness,
    setSystem,
    business,
    system,
    currentLocation,
    user,
    setUser,
    setUserPermissions,
    userPermissions,
    businessLoading,
    locations,
  } = useContext(AppContext);

  useEffect(() => {
    const _token = localStorage.getItem(Types.USER_TOKEN);
    if (_token) {
      handleApi(getProfile).then(setUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isOwner } = usePermission();

  useEffect(() => {
    if (user && business && !userPermissions) {
      if (!isOwner) {
        if (!permissionLoading) {
          setPermissionLoading(true);
          const _token = localStorage.getItem(Types.USER_TOKEN);
          const _currentLocation = localStorage.getItem(Types.BUSINESS_LOCATION);
          if (_token) handleApi(getProfile).then(setUser);
          if (_token && _currentLocation) {
            handleApi(getPermissionList, {}, { infinitePage: true }).then((res) => {
              const results = (res.results || []).reduce((acc, i) => {
                acc[i.model] = {
                  ...(acc[i.model] || {}),
                  [i.action || 'crud']: {
                    view: i.view_permission || false,
                    modify: i.modify_permission || false,
                  },
                };
                return acc;
              }, {});
              setUserPermissions(results);
              setTimeout(() => {
                setPermissionLoading(false);
              }, 200);
            });
          }
        }
      } else {
        setUserPermissions({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwner, user, business, permissionLoading]);

  useEffect(() => {
    if (system === 'marketplace' && currentLocation) {
      (async () => {
        const latestAppointment = await handleApi(getAppointmentList, { page: 1, limit: 1 });
        if (latestAppointment?.results && latestAppointment?.results[0]) {
          const appointment = latestAppointment?.results[0];
          const latestAppStorage = localStorage.getItem(Types.LATEST_APPOINTMENT);
          if (!(latestAppStorage && appointment.id.toString() === latestAppStorage.toString())) {
            localStorage.setItem(Types.LATEST_APPOINTMENT, appointment.id);
            let appointmentToaster = null;
            const handleCloseToaster = () => {
              if (appointmentToaster?.id) {
                toaster.close(appointmentToaster.id, appointmentToaster.position);
                if (appointment.booking_mode === '3_time') {
                  history.push(`/three-time-requests/${appointment.id}`);
                } else {
                  history.push(`/calendar/appointment/${appointment.id}/edit`);
                }
              }
            };

            appointmentToaster = toaster.notify(() => (
              <Alert success onClick={handleCloseToaster}>
                <h5>New Booking</h5>

                {appointment.booking_mode === '3_time' ? (
                  <>
                    <p className="mb-0">
                      For <strong>{appointment.client_name}</strong> suggestion times:
                    </p>
                    <ul className="m-0 p-0">
                      {(appointment.suggested_times || []).map((i) => (
                        <li className="c-list__item p-0 border-0" key={i.id}>
                          <small className="font-weight-bold">
                            {i.reserved_date} {i.reserved_time}
                          </small>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <p className="mb-0">
                    For <strong>{appointment.client_name}</strong> at{' '}
                    <strong>
                      {appointment.reserved_date} {appointment.lines[0].reserved_time}
                    </strong>
                  </p>
                )}
              </Alert>
            ));
          }
        }
      })();
    }
  }, [system, currentLocation]);

  useEffect(() => {
    const reference_page = localStorage.getItem(Types.PAYMENT_BACK_PAGE);
    if (reference_page) {
      setReference(reference_page);
      // if (reference_page?.includes('setup')) {
      if (reference_page === 'setup.both.subscribe') {
        const reference_to = reference_page?.substr(6);
        const reference = reference_page.substr(
          6,
          reference_to.indexOf('.') > -1 ? reference_to.indexOf('.') : undefined,
        );
        setShowSetup(reference);
      }
    }
  }, []);

  const showSetupPage = useMemo(() => {
    if (locations && locations.length > 0) return false;
    if (reference === 'setup.both.subscribe') return true;
    const isSet = business?.software_setup || business?.marketplace_setup;
    const systemType = localStorage.getItem(Types.SYSTEM_TYPE);
    if (isSet)
      setSystem(
        systemType === 'software' && business?.software_setup
          ? 'software'
          : systemType === 'marketplace' && business?.marketplace_setup
          ? 'marketplace'
          : business?.software_setup
          ? 'software'
          : 'marketplace',
      );
    return !isSet;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, locations]);

  useEffect(() => {
    if (token && currentLocation) {
      setLoading(true);
      handleApi(getBusiness, { businessId: currentLocation || null })
        .then((business) => {
          if (reference === 'setup.marketplace' && business?.connected_to_stripe && !business?.marketplace_setup) {
            handleApiSave(partialUpdateBusiness, null, { marketplace_setup: true }, null)
              .then((data) => {
                setSystem(data?.marketplace_setup ? 'marketplace' : 'software');
                setBusiness(data);
              })
              .catch(() => {})
              .finally(() => setLoading(false));
          } else if (
            (business.subscribed || business.is_trial) &&
            reference === 'setup.software' &&
            business?.plan &&
            !business?.software_setup
          ) {
            handleApiSave(partialUpdateBusiness, null, { software_setup: true }, null)
              .then((data) => {
                setSystem(data?.software_setup ? 'software' : 'marketplace');
                setBusiness(data);
              })
              .catch(() => {})
              .finally(() => setLoading(false));
          } else if (
            reference === 'setup.both' &&
            (business.subscribed || business.is_trial) &&
            business?.plan &&
            business?.connected_to_stripe
          ) {
            handleApiSave(partialUpdateBusiness, null, { marketplace_setup: true, software_setup: true }, null)
              .then((data) => {
                setSystem('marketplace');
                setBusiness(data);
              })
              .catch(() => {})
              .finally(() => setLoading(false));
          } else {
            setLoading(false);
          }
          const systemType = localStorage.getItem(Types.SYSTEM_TYPE);
          setSystem(systemType ? systemType : business?.software_setup ? 'software' : 'marketplace');
          setBusiness(business);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, currentLocation]);
  const handleOpenSidebar = () => setSidebarOpen(true);

  const handleToggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const locationLayout = useMemo(() => !!(!currentLocation && token), [currentLocation, token]);
  // return (business && showSetupPage) || showSetupLayout ? (
  return !loading && !businessLoading && (showSetupPage || showSetupLayout) ? (
    <SetupLayout
      isNew
      showSetupLayout={showSetupLayout}
      reference={reference}
      setupType={showSetup}
      onShowSetup={setShowSetup}
      onCloseSetupLayout={() => setShowSetupLayout(false)}
    />
  ) : locationLayout ? (
    <LocationsLayout onShowSetup={() => setShowSetupLayout(true)} />
  ) : (
    <>
      {window.innerWidth <= 576 && <Backdrop onClick={handleOpenSidebar} show={!sidebarOpen} />}
      <div className={classnames('c-panel', { 'sidebar-min': !sidebarOpen })}>
        <Sidebar onClose={handleOpenSidebar} onSetup={(type) => setShowSetup(type)} />
        <div className="c-panel-container">
          <Header onToggle={handleToggleSidebar} />
          <div className="c-panel-body">
            {children}
            {showSetup === 'marketplace' && <SetupMarketplaceModal onClose={() => setShowSetup(null)} />}
            {showSetup === 'software' && <SetupSoftwareModal onClose={() => setShowSetup(null)} />}
          </div>
          {reference === 'setup.marketplace' && <MarketplaceCongressModal onClose={() => setReference(null)} />}
          {reference === 'setup.software' && (business?.subscribed || business?.is_trial) && (
            <SoftwareCongressModal onClose={() => setReference(null)} />
          )}
          {reference === 'setup.both' && (business?.subscribed || business?.is_trial) && (
            <BothCongressModal onClose={() => setReference(null)} />
          )}
        </div>
      </div>
      {(loading || userPermissions === null) && <PageLoading over style={{ zIndex: 1000, backgroundColor: '#fff' }} />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
