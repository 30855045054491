import React, { useEffect, useMemo, useState } from 'react';
import Select from '../Form/Select';
import { MonthNames } from '../../../../utilities/DateHelpers';
import { getQueryUri, setQueryUri } from '../../../../utilities/URI';
import { useHistory } from 'react-router-dom';

const MonthsFilter = () => {
  const [value, setValue] = useState(new Date().getMonth() + 1);
  const history = useHistory();
  useEffect(() => {
    const monthVal = getQueryUri('month');
    if (monthVal) setValue(monthVal);
  }, []);
  const options = useMemo(() => MonthNames.map((val, key) => ({ value: key + 1, label: val })), []);
  const handleChange = (val) => {
    setValue(val);
    const otherQuery = getQueryUri();
    const query = setQueryUri({ ...otherQuery, month: val });
    history.push(query);
  };
  return (
    <div style={{ width: 200, marginLeft: 'auto' }}>
      <Select formGroup={false} id="filterMonth" options={options} value={+value} onChangeValue={handleChange} />
    </div>
  );
};

export default MonthsFilter;
