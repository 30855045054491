import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import BusinessObject from '../objects/BusinessObject';
import { Types } from '../config/actionTypes';
import { getParams } from '../utilities/ParamHelper';

const { POST, PUT, PATCH, GET } = ApiMethods;

/**
 * @description get business data
 */
export const getBusiness = async (props = { businessId: null }) => {
  const id = props?.businessId || localStorage.getItem(Types.BUSINESS_LOCATION);
  const response = await callApi({
    method: GET,
    locationBase: false,
    url: createURL(ApiRoutes.business.get, { id }),
    // url: ApiRoutes.business.get,
  });
  if (response.data) {
    return Promise.resolve(new BusinessObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description create new business data
 * @method PATCH
 * @param: {{
 *     title,
 *     bio,
 *     tax_rate,
 *     phone_number,
 *     access_to,
 *     is_independent,
 *     booking_mode,
 *     refund_policy,
 *     cancellation_period,
 *     website,
 *     instagram,
 *     facebook,
 *     twitter,
 *     yelp,
 *     user,
 * }}
 */
export const createBusiness = async (params) => {
  const response = await callApi({
    method: POST,
    params: getParams(params),
    locationBase: false,
    url: ApiRoutes.business.create,
    // url: ApiRoutes.business.update,
  });
  if (response.data) {
    return Promise.resolve(response.data ? new BusinessObject(response.data) : null);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description update business data
 * @method PUT
 * @param: {{
 *     title,
 *     bio,
 *     tax_rate,
 *     phone_number,
 *     access_to,
 *     is_independent,
 *     booking_mode,
 *     refund_policy,
 *     cancellation_period,
 *     website,
 *     instagram,
 *     facebook,
 *     twitter,
 *     yelp,
 *     user,
 * }}
 */
export const updateBusiness = async (params) => {
  const id = localStorage.getItem(Types.BUSINESS_LOCATION);
  const response = await callApi({
    method: PUT,
    params: getParams(params),
    locationBase: false,
    url: createURL(ApiRoutes.business.update, { id }),
    // url: ApiRoutes.business.update,
  });
  if (response.data) {
    return Promise.resolve(response.data ? new BusinessObject(response.data) : null);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description partial update business data
 * @method PATCH
 * @param: {{
 * @param: {{
 *     title,
 *     bio,
 *     phone_number,
 *     tax_rate,
 *     access_to,
 *     is_independent,
 *     booking_mode,
 *     refund_policy,
 *     cancellation_period,
 *     website,
 *     instagram,
 *     facebook,
 *     twitter,
 *     yelp,
 *     user,
 * }}
 */
export const partialUpdateBusiness = async ({ businessId = null, ...params }) => {
  const id = businessId || localStorage.getItem(Types.BUSINESS_LOCATION);
  const response = await callApi({
    method: PATCH,
    params: getParams(params),
    locationBase: false,
    url: createURL(ApiRoutes.business.update, { id }),
    // url: ApiRoutes.business.update,
  });
  if (response.data) {
    return Promise.resolve(response.data ? new BusinessObject(response.data) : null);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param: id
 * @method GET
 * @description get business data by id
 */
export const getBusinessById = async (id) => {
  const response = await callApi({
    method: GET,
    locationBase: false,
    url: createURL(ApiRoutes.business.get, { id }),
    // url: ApiRoutes.business.get,
  });
  if (response.data) {
    return Promise.resolve(response.data ? new BusinessObject(response.data) : null);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param: id
 * @method POST
 * @description update business data by id
 */
export const partialUpdateBusinessById = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    locationBase: false,
    url: createURL(ApiRoutes.business.update, { id }),
    // url: ApiRoutes.business.get,
    params: getParams(params),
  });
  if (response.data) {
    return Promise.resolve(response.data ? new BusinessObject(response.data) : null);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get business preference data
 */
export const getCommunicationBusiness = async () => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.businessPreference.get,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description update business preference data
 * @method PUT
 * @param: {{
 *     notification_state
 *     notification_method,
 *     new_appointment,
 *     marketplace_sale,
 *     reschedule,
 *     cancellation,
 *     no_show,
 *     confirmation,
 *     arrival,
 *     started,
 *     tip,
 * }}
 */
export const updateCommunicationBusiness = async (params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: ApiRoutes.businessPreference.update,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};
