import qs from 'qs';
import { ApiErrorTypes, ApiMethods, ApiUrl, StatusCodes } from '../config/apiConfig';
import { isDevelopment } from '../utilities/Environment';
import axios from 'axios';
import { Types } from '../config/actionTypes';
const JSONstrict = require('json-bigint')({ storeAsString: true });

class APIService {
  constructor() {
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const city = localStorage.getItem(Types.CITY);
    this._defaultHeaders = {
      'Content-Type': 'application/json',
      // 'X-Timezone': timeZone,
      // 'X-CITY': city || '',
      ...(isDevelopment ? {} : {}),
    };
  }

  /**
   *
   * @param headers {Object}
   * @param absolute {Boolean}
   * @returns {Object}
   */
  _getHeaders(headers, absolute = false) {
    Object.keys(headers || {}).forEach((key) => {
      if (headers[key] === undefined || headers[key] === 'undefined') {
        delete headers[key];
      }
    });
    if (absolute) {
      return headers || {};
    }
    return {
      ...this._defaultHeaders,
      ...(headers || {}),
    };
  }

  /**
   *
   * @param url
   * @param method
   * @param params
   * @param headers
   * @param absolute
   * @param locationBase
   * @returns {Promise<T>}
   */
  request(url, method, params, headers = null, absolute = false, locationBase = true) {
    // const locationBaseEnv = +process.env.REACT_APP_LOCATION_BASE === 1;
    // const loc = locationBaseEnv && locationBase;
    const businessId = localStorage.getItem(Types.BUSINESS_LOCATION);
    // const fullUrl = `${absolute ? url : `${ApiUrl}${loc ? `/partners/7861650629271313166` : ''}${url}`}${
    const fullUrl = `${absolute ? url : `${ApiUrl}${locationBase ? `/partners/${businessId}` : ''}${url}`}${
      method === ApiMethods.GET ? this.createQueryString(params) : ''
    }`;
    const isFormData =
      params &&
      (method === ApiMethods.POST ||
        method.toLowerCase() === ApiMethods.PUT ||
        method.toLowerCase() === ApiMethods.PATCH);
    return Promise.race([
      axios(fullUrl, {
        method,
        // transformResponse: method === ApiMethods.GET ? data  => data : undefined,
        transformRequest: (data) => data,
        transformResponse: (data) => data,
        rejectUnauthorized: false,
        headers: this._getHeaders(headers, absolute),
        data: params instanceof FormData ? params : isFormData ? JSON.stringify(params) : undefined,
      })
        .then((res) => {
          const status = +res.status;
          if (status === StatusCodes.Error) {
            return { status, data: 'Server Error' };
            // return { status, data: res.data };
          }
          if (status === StatusCodes.Deleted) {
            return { status, data: true };
          }
          if (status === StatusCodes.NotFound) {
            return { status, data: true };
          }
          // return { status, data: JSONstrict.parse(res.data) };
          // return { status, data: method === ApiMethods.GET ? JSONstrict.parse(res.data) : res.data };
          return { status, data: JSONstrict.parse(res.data) };
          // return { status: status, data: res.data };
        })
        .catch((err) => {
          if (err?.response) {
            const errors =
              err?.response?.data && typeof err.response.data === 'string'
                ? JSON.parse(err?.response?.data)
                : err?.response?.data;
            const status = err?.response?.status || '406';
            return { status: +status, data: errors };
          }
        }),
      new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(ApiErrorTypes.TimeOut);
        }, 30000);
      }),
    ]);
  }

  /**
   *
   * @param obj
   * @returns {String}
   */
  createQueryString(obj) {
    if (!obj) return '';
    let result = '?';
    let hasArray = false;
    Object.keys(obj).forEach((key) => {
      const item = obj[key];
      if (item) {
        if (Array.isArray(item)) {
          hasArray = true;
          // item.forEach((item2, i) => {
          //     result += `${key}[${i}]=${item2}&`;
          // });
          result += `${key}=${item}&`;
        } else {
          result += `${key}=${item}&`;
        }
      }
    });
    result = result.substr(0, result.length - 1);
    return hasArray ? result : `?${qs.stringify(obj)}`;
    // return '?' + qs.stringify(obj);
  }

  /**
   *
   * @param url
   * @param params
   * @param headers
   * @param absolute
   * @param locationBase
   * @returns {Promise<T>}
   */
  post(url, params, headers, absolute, locationBase) {
    return this.request(url, ApiMethods.POST, params, headers, absolute, locationBase);
  }

  /**
   *
   * @param url
   * @param params
   * @param headers
   * @param absolute
   * @param locationBase
   * @returns {Promise<T>}
   */
  put(url, params, headers, absolute, locationBase) {
    return this.request(url, ApiMethods.PUT, params, headers, absolute, locationBase);
  }

  /**
   *
   * @param url
   * @param params
   * @param headers
   * @param absolute
   * @param locationBase
   * @returns {Promise<T>}
   */
  patch(url, params, headers, absolute, locationBase) {
    // console.log('method patch', params)
    // return this.request(url, ApiMethods.DELETE, params, headers, absolute, locationBase);
    return this.request(url, 'PATCH', params, headers, absolute, locationBase);
  }

  /**
   *
   * @param url
   * @param params
   * @param headers
   * @param absolute
   * @param locationBase
   * @returns {Promise<T>}
   */
  delete(url, params, headers, absolute, locationBase) {
    return this.request(url, ApiMethods.DELETE, params, headers, absolute, locationBase);
  }

  /**
   *
   * @param url
   * @param params
   * @param headers
   * @param absolute
   * @param locationBase
   * @returns {Promise<T>}
   */
  get(url, params, headers, absolute, locationBase) {
    return this.request(url, ApiMethods.GET, params, headers, absolute, locationBase);
  }
}

export default new APIService();
