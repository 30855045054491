import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import ModalFormClientItem from '../../ModalForm/ModalFormClient/ModalFormClientItem';
import ModalFormClients from '../../ModalForm/ModalFormClient/ModalFormClients';
import ModalFormSidebarFooter from '../../ModalForm/ModalFormSidebarFooter';
import ModalFormSidebar, { ModalFormSidebarContent, ModalFormSidebarMinimize } from '../../ModalForm/ModalFormSidebar';
import ModalFormTotalPrice from '../../ModalForm/ModalFormTotalPrice';
import AppointmentOptions from './AppointmentOptions';
import { InvoiceStatusChoice } from '../../../../utilities/constants/choices';
import usePermission from '../../../../utilities/usePermission';

const AppointmentModalSidebar = ({
  lines,
  data,
  disabled,
  client,
  loadingFn,
  onSave,
  onUpdate,
  onRefund,
  onCheckout,
  setClient,
  onClose,
}) => {
  const [minimize, setMinimize] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const { access: _access } = usePermission('appointment', 'crud', 'modify');
  const { access: _invoice } = usePermission('invoice', 'crud', 'view');
  const totalPrice = useMemo(
    () =>
      lines.reduce((acc, i) => {
        if (i.price) {
          // if (i?.service_discount_type && i.service_discount_type?.data?.discount) {
          //   const discount = +i.service_discount_type.data.discount;
          //   return +i.price - (+i.price * discount) / 100 + acc;
          // }
          return +i.price + acc;
        } else if (i.full_service) {
          const price = +i.full_service.price;
          // const service_discount = i?.service_discount_type;
          // if (service_discount && service_discount?.data?.discount) {
          //   const discount = +service_discount.data.discount;
          //   return price - (price * discount) / 100 + acc;
          // }
          return price + acc;
        }
        return acc;
      }, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, lines],
  );

  const handleCheckout = () => onSave({ client, isCheckout: true });

  return (
    <ModalFormSidebar minimize={minimize}>
      {!client || editClient ? (
        <ModalFormClients onSelect={setClient} client={client} edit={editClient} onEdit={() => setEditClient(false)} />
      ) : (
        <>
          <ModalFormSidebarMinimize minimize={minimize} onToggle={() => setMinimize(!minimize)} />
          <ModalFormSidebarContent>
            <ModalFormClientItem
              data={client}
              index={-1}
              onRemove={!disabled ? () => setClient(null) : null}
              onEdit={!disabled ? () => setEditClient(true) : null}
            />
            {data && (
              <>
                <span className="c-appointment-modal__payment">
                  {data?.sale_channel === 'marketplace' &&
                    !data.invoice &&
                    !data.status.includes('cancel') &&
                    disabled && (
                      <div className="alert alert-warning">
                        <strong>Client should to pay at the salon</strong>
                      </div>
                    )}
                  <span className="c-appointment-modal__payment-label">Payment Status: </span>
                  <span className="c-appointment-modal__payment-value">{InvoiceStatusChoice[data.payment_status]}</span>
                </span>
                <span className="c-appointment-modal__note">
                  <span className="c-appointment-modal__note-label">Appointment Note: </span>
                  {data?.note ? (
                    <span className="c-appointment-modal__note-value">{data.note}</span>
                  ) : (
                    <span className="c-appointment-modal__note-empty">There is not any note for this appointment</span>
                  )}
                </span>
              </>
            )}
            <ModalFormTotalPrice label="Total" price={totalPrice} />
            {_access &&
              data &&
              data.payment_status !== 'paid' &&
              !data.status.includes('cancel') &&
              !(data.payment_status === 'refunded' || data.payment_status === 'part_refunded') && (
                <div className="p-3">
                  {data?.sale_channel !== 'marketplace' ? (
                    <Button
                      id="completeCheckoutBtn"
                      block
                      color="dark"
                      onClick={onCheckout ? onCheckout : handleCheckout}
                    >
                      Complete Checkout Now
                    </Button>
                  ) : (
                    <Button id="payAtSalonBtn" block color="dark" onClick={onCheckout ? onCheckout : handleCheckout}>
                      Pay At the Salon
                    </Button>
                  )}
                </div>
              )}

            <ModalFormSidebarFooter>
              {_access ? (
                <>
                  {data &&
                    data.status !== 'fulfilled' &&
                    // data.payment_status !== 'paid' &&
                    data.payment_status !== 'refunded' && (
                      // data.payment_status !== 'part_refunded'
                      <AppointmentOptions
                        data={data}
                        loadingFn={loadingFn}
                        onUpdate={onUpdate}
                        onRefund={onRefund}
                        onCheckout={handleCheckout}
                      />
                    )}
                  {!data && (
                    <Button id="expressCheckoutBtn" color="light" onClick={handleCheckout}>
                      Express checkout
                    </Button>
                  )}
                  {(!disabled || !data) && (
                    <Button id="saveBtn" onClick={() => onSave({ client, isCheckout: false })}>
                      Save appointment
                    </Button>
                  )}
                  {data && (
                    <>
                      {data?.sale_channel !== 'marketplace' &&
                        !data.invoice &&
                        !data.status.includes('cancel') &&
                        disabled && (
                          <Button id="checkoutBtn" color="success" onClick={onCheckout ? onCheckout : handleCheckout}>
                            Checkout
                          </Button>
                        )}
                      {data?.sale_channel === 'marketplace' &&
                        !data.invoice &&
                        !data.status.includes('cancel') &&
                        disabled && (
                          <Button id="payAtSalonBtn" color="success" onClick={onCheckout ? onCheckout : handleCheckout}>
                            Pay At the Salon
                          </Button>
                        )}
                      {_invoice && data.invoice && (
                        <Button
                          id="viewInvoiceBtn"
                          color="info"
                          link={`/sales/transactions/${data.invoice}`}
                          onClick={onClose}
                        >
                          View Invoice
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button id="closeBtn" color="light" onClick={onClose}>
                    Close
                  </Button>
                  {_invoice && data.invoice && (
                    <Button
                      id="viewInvoiceBtn"
                      color="info"
                      link={`/sales/transactions/${data.invoice}`}
                      onClick={onClose}
                    >
                      View Invoice
                    </Button>
                  )}
                </>
              )}
            </ModalFormSidebarFooter>
          </ModalFormSidebarContent>
        </>
      )}
    </ModalFormSidebar>
  );
};

AppointmentModalSidebar.propTypes = {
  lines: PropTypes.array,
  data: PropTypes.any,
  disabled: PropTypes.bool,
  client: PropTypes.any,
  loadingFn: PropTypes.func,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
  onRefund: PropTypes.func,
  onCheckout: PropTypes.func,
  setClient: PropTypes.func,
  onClose: PropTypes.func,
};

export default AppointmentModalSidebar;
