import { getDateFromString, getFormatBriefDate } from '../utilities/DateHelpers';

/**
 *
 * @param discount {{
 *     id,
 *     services,
 *     name,
 *     code,
 *     amount,
 *     amount_type,
 *     usage_limit,
 *     expire_after,
 *     campaign,
 *     email_subject,
 *     email_description,
 *     is_published,
 *     expired,
 *     status,
 *     used_count: Number,
 *     is_usable: Boolean,
 *     send_sms: Boolean,
 *     created_at: String,
 * }}
 * @constructor
 */

function DiscountObject(discount) {
  this.id = discount.id;
  this.services = discount.services;
  this.name = discount.name;
  this.code = discount.code;
  this.amount = discount.amount;
  this.unit_type = discount.amount_type;
  this.unit = discount.amount_type === 'percent' ? '%' : '$';
  this.usage_limit = discount.usage_limit;
  this.used_count = discount.used_count;
  this.is_usable = discount.is_usable;
  this.expired = discount.expired;
  this.send_sms = discount.send_sms;
  this.is_published = discount.is_published;

  this.expire_after = discount.expire_after;
  if (discount.expire_after) {
    const createdAt = getDateFromString(discount.created_at);
    createdAt.setDate(createdAt.getDate() + +discount.expire_after);
    this.expire_date = getFormatBriefDate(createdAt);
  } else {
    this.expire_date = null;
  }

  this.campaign = discount.campaign;
  // this.email_subject = discount.email_subject;
  // this.email_description = discount.email_description;
  // this.status = discount.status;
}

export default DiscountObject;
