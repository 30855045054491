import React from 'react';
import PropTypes from 'prop-types';
import ServiceItem from './ServiceItem';
import Dropdown from '../UIKit/Dropdown';

function ServiceGroup(props) {
  const data = props.item;
  const options = [
    {
      id: `addByCat-${data.categoryId}`,
      url: `/services/add?categoryId=${data.categoryId}`,
      label: 'Add New Service',
    },
  ];

  const taskHandler = (items) =>
    items.map((item, index) => <ServiceItem key={index} index={index} isModify={props?.isModify} item={item} />);
  return (
    <div className="c-service-group__item">
      <div className="c-service-group__item-head">
        <div className="c-service-group__item-info">
          <h2 className="c-service-group__item-title">{data.categoryName}</h2>
        </div>
        {props.isModify && (
          <Dropdown options={options}>
            <span className="c-service-group__item-options">
              <i className="fas fa-ellipsis-h" />
            </span>
          </Dropdown>
        )}
      </div>

      <div className="c-service-group__item-services">{taskHandler(data.services)}</div>
    </div>
  );
}

ServiceGroup.propTypes = {
  item: PropTypes.any,
  isModify: PropTypes.bool,
};

export default ServiceGroup;
