import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';

const { POST, GET } = ApiMethods;

/**
 * @description list of all third time requests method
 */
export const suggestionList = async () => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.appointmentSuggestedTimes.list,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @description confirm suggestion item by suggestionId
 */
export const confirmSuggestion = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.appointmentSuggestedTimes.confirm, { id }),
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params
 * @description confirm suggestion item by suggestionId
 */
export const createSuggestion = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.appointmentSuggestedTimes.create,
    params,
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};
