import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, BusinessId, createURL } from '../config/apiConfig';
import { AnalyticsAppointmentsObject, AnalyticsClientsObject, AnalyticsSalesObject } from '../objects/AnalyticsObject';

const { GET } = ApiMethods;

export const getAnalyticsAppointments = async () => {
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.analytics.appointment, { id: BusinessId }),
    locationBase: false,
  });

  if (response.data) {
    return new AnalyticsAppointmentsObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const getAnalyticsClients = async () => {
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.analytics.client, { id: BusinessId }),
    locationBase: false,
  });

  if (response.data) {
    return new AnalyticsClientsObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const getAnalyticsSales = async () => {
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.analytics.sales, { id: BusinessId }),
    locationBase: false,
  });

  if (response.data) {
    return new AnalyticsSalesObject(response.data);
  } else {
    return Promise.reject(response);
  }
};
