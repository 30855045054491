import React from 'react';
import { Done } from '@material-ui/icons';
import { IMAGE_PAYMENT_STRIPE } from '../../../assets/Images';
import Button from '../../Common/UIKit/Form/Button';
import { getQueryUri } from '../../../utilities/URI';
import { getStringFromDate } from '../../../utilities/DateHelpers';

const SuccessPaymentPage = () => {
  const price = getQueryUri('amount');
  const transaction = getQueryUri('transaction');
  const email = getQueryUri('email');
  const date = getQueryUri('date');
  const mobile = getQueryUri('mobile');
  const mode = getQueryUri('mode');
  const invoiceId = getQueryUri('invoice_id');
  const today = new Date();
  return (
    <div className="c-payment-stripe c-payment-stripe--success">
      <img
        className="c-payment-stripe__background c-payment-stripe__background--filter"
        src={IMAGE_PAYMENT_STRIPE}
        alt="Beautster"
      />

      <div className="c-payment-stripe__box c-payment-stripe__box--success">
        <span className="c-payment-stripe__box-icon">
          <Done />
        </span>

        <h1 className="c-payment-stripe__box-title">Payment successful</h1>

        <ul className="c-payment-stripe__list">
          <li className="c-payment-stripe__list-item">
            <span>Date</span>
            <span>{date || getStringFromDate(today)}</span>
          </li>
          {email && (
            <li className="c-payment-stripe__list-item">
              <span>Email</span>
              <span>{email}</span>
            </li>
          )}
          {mobile && (
            <li className="c-payment-stripe__list-item">
              <span>Mobile</span>
              <span>{mobile}</span>
            </li>
          )}
          {price && (
            <li className="c-payment-stripe__list-item">
              <strong>Amount paid</strong>
              <strong>{price}</strong>
            </li>
          )}
          {transaction && (
            <li className="c-payment-stripe__list-item">
              <span>Transaction ID</span>
              <span>{transaction}</span>
            </li>
          )}
        </ul>

        <div className="d-flex align-items-center">
          {mode === 'balance_payment' ? (
            <Button color="success" className="mr-3" link="/settings/payment/balance-history">
              Continue
            </Button>
          ) : mode === 'subscription' ? (
            <Button color="success" className="mr-3" link="/subscription">
              Continue
            </Button>
          ) : (
            <Button color="success" className="mr-3" link={`/sales/transactions/${invoiceId}`}>
              View Invoice
            </Button>
          )}
          <Button link="/">CLOSE</Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPaymentPage;
