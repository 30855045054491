import React, { useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Add } from '@material-ui/icons';
import Modal from '../UIKit/Modal';
import { handleApiSave, useApiById } from '../../../utilities/useApi';
import { confirmSuggestion, createSuggestion, getAppointment } from '../../../api';
import { getDateFromString, getDateInfo, getFormat24H, getFormatTime } from '../../../utilities/DateHelpers';
import { PageLoading } from '../UIKit/Spinner';
import classNames from 'classnames';
import ThirdTimeDateSelector from './ThirdTimeDateSelector';
import usePermission from '../../../utilities/usePermission';

// eslint-disable-next-line react/prop-types
const ThirdTimeRequestModel = ({ onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showSelectTime, setShowSelectTime] = useState(false);
  const [newTime, setNewTime] = useState(null);

  const [isConfirm, setIsConfirmed] = useState(false);

  const { id } = useParams();

  const [data, loading] = useApiById(getAppointment, undefined, (res) => {
    setIsConfirmed(!!res.suggested_times.find((i) => i.status === 'confirmed'));
  });

  const history = useHistory();

  const { access: _access } = usePermission('appointmentsuggestedtime', 'crud');
  const { access: _confirm } = usePermission('appointmentsuggestedtime', 'confirm');

  const handleSave = (e) => {
    e.preventDefault();
    if (!isNaN(selectedTime)) {
      handleApiSave(undefined, confirmSuggestion, {}, selectedTime, {
        loadingFn: setSaveLoading,
      })
        .then(onSave)
        .catch(() => {});
    } else {
      setSaveLoading(true);
      handleApiSave(createSuggestion, null, {
        appointment: data.id,
        reserved_date: selectedTime.split(' ')[0],
        reserved_time: selectedTime.split(' ')[1],
      })
        .then((res) => {
          handleApiSave(undefined, confirmSuggestion, {}, res.id)
            .then(onSave)
            .catch(() => {})
            .finally(() => setSaveLoading(false));
        })
        .catch(() => setSaveLoading(false));
    }
  };

  const handleSetNew = (res) => {
    setNewTime(res);
    if (res?.format) setSelectedTime(res.format);
  };

  const totalDuration = useMemo(() => {
    if (data) {
      const time = (data.lines || []).reduce((acc, i) => {
        const duration = i.duration.split(':');
        const hr = duration[0];
        const min = duration[1];
        return acc + +hr * 60 + +min;
      }, 0);
      return time > 0 ? `${Math.floor(time / 60)}:${time % 60}` : 0;
    }
    return 0;
  }, [data]);

  const suggestedTimes = useMemo(
    () =>
      (data?.suggested_times || []).sort((a, b) => {
        const aDate = getDateFromString(a.reserved_date);
        const bDate = getDateFromString(b.reserved_date);

        if (aDate.getTime() < bDate.getTime()) return -1;
        if (aDate.getTime() > bDate.getTime()) return 1;

        const { hours: aHour, min: aMin } = getFormat24H(a.reserved_time, 'as-object');
        const { hours: bHour, min: bMin } = getFormat24H(b.reserved_time, 'as-object');

        if (aHour < bHour || (aHour <= bHour && aMin <= bMin)) return -1;
        if (aHour > bHour || (aHour >= bHour && aMin >= bMin)) return 1;

        return 0;
      }),
    [data],
  );

  const staffList = useMemo(() => (data ? (data.lines || []).map((i) => i.staff) : []), [data]);

  const handleViewAppointment = () => {
    history.push(`/calendar/appointment/${id}/edit`);
  };

  console.log('suggestedTimes', suggestedTimes);
  return (
    <Modal
      show
      lg
      sticky
      title="Three Timeslots Request"
      onClose={onClose}
      saveBtn={_confirm?.modify}
      saveBtnText={isConfirm ? 'View Appointment' : 'Submit'}
      saveLoading={saveLoading}
      onSave={isConfirm ? handleViewAppointment : handleSave}
    >
      {loading ? (
        <PageLoading />
      ) : (
        <div className="c-third-time">
          {data.lines.map((line) => (
            <ul className="c-third-time__details" key={line.id}>
              <li className="c-third-time__details-item">
                <strong className="c-third-time__details-label">Provider:</strong>
                <span className="c-third-time__details-value">{line.staff_name}</span>
              </li>
              <li className="c-third-time__details-item">
                <strong className="c-third-time__details-label">Service:</strong>
                <span className="c-third-time__details-value">{line.service_name}</span>
              </li>
              <li className="c-third-time__details-item">
                <strong className="c-third-time__details-label">Duration:</strong>
                <span className="c-third-time__details-value">{getFormatTime(line.duration)}</span>
              </li>
              <li className="dropdown-divider" />
            </ul>
          ))}

          <ul className="c-third-time__details">
            <li className="c-third-time__details-item">
              <strong className="c-third-time__details-label">Appointment Duration:</strong>
              <span className="c-third-time__details-value">{getFormatTime(totalDuration)}</span>
            </li>
            <li className="c-third-time__details-item">
              <strong className="c-third-time__details-label">Status:</strong>
              <span
                className={classNames('c-third-time__details-value', {
                  'text-success': isConfirm,
                  'text-warning': !isConfirm,
                })}
              >
                {isConfirm ? 'Confirmed' : 'Pending'}
              </span>
            </li>
            <li className="c-third-time__details-item">
              <strong className="c-third-time__details-label">Requested On:</strong>
              <span className="c-third-time__details-value">{data.created_at}</span>
            </li>
          </ul>

          <div className="c-third-time__suggestions">
            {suggestedTimes.map((item) => (
              <div
                key={item.id}
                className={classnames('c-third-time__item', {
                  'c-third-time__item--active': +item.id === +selectedTime || item.status === 'confirmed',
                  'c-third-time__item--declined': item.status === 'declined',
                  'c-third-time__item--confirmed': isConfirm,
                  'c-third-time__item--denied': !_confirm?.modify,
                })}
                onClick={!isConfirm && _confirm?.modify ? () => setSelectedTime(item.id) : undefined}
              >
                <span className="c-third-time__item-body">
                  <span className="c-third-time__item-date">
                    {getDateInfo(getDateFromString(item.reserved_date)).monthName}{' '}
                    {getDateInfo(getDateFromString(item.reserved_date)).date}
                  </span>
                  <span className="c-third-time__item-day">
                    {getDateInfo(getDateFromString(item.reserved_date)).dayName}
                  </span>
                </span>

                <span className="c-third-time__item-time">{item.reserved_time.replace(':00', '')}</span>
              </div>
            ))}
            {!isConfirm && _access?.modify && (
              <>
                {newTime ? (
                  <div
                    className={classnames('c-third-time__item', {
                      'c-third-time__item--active': isNaN(selectedTime) && typeof selectedTime === 'string',
                    })}
                    onClick={() => setSelectedTime(newTime.format)}
                  >
                    <span className="c-third-time__item-body">
                      <span className="c-third-time__item-date">
                        {newTime?.info?.monthName} {newTime?.info?.date}
                      </span>
                      <span className="c-third-time__item-day">{newTime?.info?.dayName}</span>
                    </span>
                    <span className="c-third-time__item-time">{newTime?.time}</span>
                  </div>
                ) : (
                  <div
                    className="c-third-time__item c-third-time__item--new"
                    onClick={() => setShowSelectTime(!showSelectTime)}
                  >
                    <Add />
                    <span className="c-third-time__item-add">New Time</span>
                  </div>
                )}
              </>
            )}
          </div>

          {showSelectTime && (
            <ThirdTimeDateSelector totalDuration={totalDuration} staffList={staffList} onSelectedTime={handleSetNew} />
          )}
        </div>
      )}
    </Modal>
  );
};

export default ThirdTimeRequestModel;
