import React, { useEffect, useState } from 'react';
import toaster from 'toasted-notes';
import { NavLink, useLocation } from 'react-router-dom';
// import GoogleRecaptcha from 'react-recaptcha';
import Input from '../../Common/UIKit/Form/Input';
import Button from '../../Common/UIKit/Form/Button';
import { login } from '../../../api';
import { Controller, useForm } from 'react-hook-form';
import { handleApiSave } from '../../../utilities/useApi';
import AuthenticationLayout from '../../Common/Authentication/AuthenticationLayout';
import { GoogleIdentityLogin } from '../../Common/Authentication/GoogleIdentity';
import { Types } from '../../../config/actionTypes';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const { control, errors, handleSubmit } = useForm();
  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem(Types.SYSTEM_TYPE);
    localStorage.removeItem(Types.LATEST_APPOINTMENT);
    localStorage.removeItem(Types.BUSINESS_LOCATION);
    localStorage.removeItem(Types.COUNTRIES);
  }, []);

  const onSubmit = ({ email, password }) => {
    // if (!recaptcha) return toaster.notify('Please Verify non-Robot Task');
    if (email && password && email.length > 0 && password.length > 0) {
      setLoading(true);
      // login(email, password, recaptcha)
      const body = { email: (email || '').toLowerCase(), password };
      handleApiSave(login, undefined, body, null, { loadingFn: setLoading })
        .then(() => {
          if (location?.state?.referrer?.pathname)
            window.location.href = location.state.referrer.pathname + location.state.referrer.search;
          else window.location.reload();
          // history.replace('/');
        })
        .catch(() => {});
    } else toaster.notify('Please Enter Email and Password');
  };

  // const verifyCallBack = response => setRecaptcha(response);

  return (
    <AuthenticationLayout>
      <div className="c-auth__head">
        <h1>Log in for professionals</h1>
        <p>Access your partner account to manage your business</p>
      </div>
      <form className="e-form mb-3" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          rules={{ required: 'Email is required' }}
          // defaultValue="thebonbonapp@gmail.com"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              label="Email address"
              type="email"
              id="pure-email"
              formGroup
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              errorMessage={errors?.email?.message}
              error={!!errors.email}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{ required: 'Email is required' }}
          // defaultValue="1234QWer@"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              label="Password"
              type="password"
              id="pure-password"
              formGroup
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              errorMessage={errors?.password?.message}
              error={!!errors.password}
            />
          )}
        />
        {/*<GoogleRecaptcha sitekey={process.env.REACT_APP_RECAPTCHA_KEY} verifyCallback={verifyCallBack} />*/}

        <NavLink to="/forgot" className="c-auth__link">
          Forgot password?
        </NavLink>
        <Button block lg id="submitLogin" loading={loading}>
          Sign in
        </Button>
      </form>
      <GoogleIdentityLogin />
      <div className="c-auth__links">
        <p>Don’t have an account?</p>
        <NavLink to="/register" className="c-auth__link">
          Create an account
        </NavLink>
      </div>
    </AuthenticationLayout>
  );
};

export default LoginPage;
