import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Map from './index';
import { IMAGE_MAP_MARKER } from '../../../../assets/Images';
import MapSearchBox from './MapSearchBox';

// eslint-disable-next-line react/prop-types
const MapSelectPosition = ({ center, onChange, className, ...otherProps }) => {
  const [mapCenter, setMapCenter] = useState(center);
  const [autocomplete, setAutocomplete] = useState(null);
  // const autocomplete = useRef(null);
  const map = useRef(null);
  useEffect(() => {
    if (center === null) {
      onChange({ lat: 51.041493, lng: -114.066613 });
      setMapCenter({ lat: 51.041493, lng: -114.066613 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);
  const onChangeMap = () => {
    onChange({
      lat: map.current.getCenter().lat(),
      lng: map.current.getCenter().lng(),
    });
  };
  // getLatLng({
  //     geometry: {
  //         location: {
  //             lat: () => center.lat,
  //             lng: () => center.lng,
  //         }
  //     }
  // }).then(e => console.log('getLatLng', e))
  // geocodeByAddress('Canada').then(e => console.log('geocodeByAddress', e))
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const data = autocomplete.getPlace();
      if (data?.geometry) {
        const body = {
          lat: data.geometry.location.lat(),
          lng: data.geometry.location.lng(),
          data: {
            ...data,
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng(),
          },
        };
        onChange(body);
        setMapCenter(body);
      }
    } else {
      // console.log('Autocomplete is not loaded yet!');
    }
  };

  return (
    <div className={classnames('c-map__selector', className)} {...otherProps}>
      <Map
        defaultCenter={center}
        options={{
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
        center={mapCenter ? mapCenter : undefined}
        onCenterChanged={onChangeMap}
        ref={map}
      >
        <MapSearchBox onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged} bounds={center} />
      </Map>
      <img
        src={IMAGE_MAP_MARKER}
        alt="location"
        className={classnames('c-map__selector-marker', {
          'c-map__selector-marker--float': false,
        })}
      />
    </div>
  );
};
export default MapSelectPosition;
