/**
 *
 * @param lmo {{
 *     id,
 *     services,
 *     service_name,
 *     subject,
 *     description,
 *     image,
 *     discount,
 *     available_date,
 *     available_time,
 *     expired,
 *     campaign,
 *     is_booked,
 *     is_published,
 *     email_subject,
 *     email_description,
 *     status: String,
 *     send_sms: Boolean,
 * }}
 * @constructor
 */

function LmoObject(lmo) {
  this.id = lmo.id;
  this.serviceName = lmo.service_name;
  this.services = lmo.services;
  this.subject = lmo.subject;
  this.description = lmo.description;
  this.image = lmo.image;
  this.discount = lmo.discount;
  this.available_date = lmo.available_date;
  this.available_time = lmo.available_time;
  this.expired = lmo.expired;
  this.campaign = lmo.campaign;
  this.is_published = lmo.is_published;
  this.isBooked = lmo.is_booked;
  this.email_subject = lmo.email_subject;
  this.email_description = lmo.email_description;
  this.status = lmo.status;
}

export default LmoObject;
