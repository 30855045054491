import React, { useEffect, useState } from 'react';
import { getPriceFormat } from '../../../utilities/Price';
import Input from '../UIKit/Form/Input';

// eslint-disable-next-line react/prop-types
const ModalFormTotalPrice = ({ onChange, label, price }) => {
  const [val, setVal] = useState(price);
  useEffect(() => {
    setVal(price);
  }, [price]);

  const handleChange = (value) => {
    onChange(value);
    setVal(value);
  };
  return (
    <div className="c-modal-form__total-box">
      <span className="c-modal-form__total-label">{label}</span>
      {!!onChange ? (
        <Input
          type="tel"
          formGroup={false}
          onChangeText={handleChange}
          value={val}
          className="c-modal-form__total-input"
          unit="$"
        />
      ) : (
        <span className="c-modal-form__total-price">{getPriceFormat(price)}</span>
      )}
    </div>
  );
};
export default ModalFormTotalPrice;
