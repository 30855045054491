import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IMAGE_DEFAULT_WOMAN_AVATAR } from '../../../assets/Images';
import useApi, { handleApi } from '../../../utilities/useApi';
import { getClientList, getMessageList } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';
// import { getDateFromString, getFormatBriefDate } from '../../../utilities/DateHelpers';
import ImageName from '../UIKit/ImageName';
import Input from '../UIKit/Form/Input';

const MessageClientList = ({ clients: _clients = [], selected = null, onSelect }) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [clients, setClients] = useState(_clients);
  const [messages, messagesLoading] = useApi(getMessageList, { infinitePage: true });

  const [data, loading] = useMemo(() => {
    const loading = messagesLoading;
    const results = (clients || []).map((client) => {
      const clientMessage = (messages?.results || []).find(
        (message) => client?.user?.id === message.id || client?.user?.id === message.id,
      );
      return {
        ...client,
        message: clientMessage,
      };
    });
    return [results, loading];
  }, [clients, messages, messagesLoading]);

  const handleSearch = (searchText) => {
    handleApi(getClientList, { has_user: 1, search: searchText }, { loadingFn: setSearchLoading }).then((res) => {
      const data = res.results.filter((c) => c.user);
      setClients(data);
    });
  };

  return (
    <div className="c-messages__clients">
      <div className="c-messages__client-search">
        <Input formClassName="mb-0" id="search-client_input" onChangeText={handleSearch} placeholder="Search Client" />
      </div>
      {searchLoading || loading ? (
        <PageLoading />
      ) : data.length > 0 ? (
        data.map((client) => (
          <div
            className={classnames('c-messages__clients-item', {
              'c-messages__clients-item--active': selected?.id === client?.id,
            })}
            onClick={() => onSelect(selected?.id === client.id ? null : client)}
            key={client.id}
          >
            <div className="c-messages__clients-item-avatar-container">
              <ImageName
                className="c-messages__clients-item-avatar"
                name={client.full_name}
                imageSrc={client?.message?.avatar || client?.avatar || IMAGE_DEFAULT_WOMAN_AVATAR}
              />
              {client?.message?.unread_count > 0 && (
                <span className="c-messages__clients-item-unread-count">
                  {client?.message?.unread_count > 99 ? '+99' : client?.message?.unread_count}
                </span>
              )}
            </div>
            <div className="c-messages__clients-item-detail">
              <span className="c-messages__clients-item-head">
                <span className="c-messages__clients-item-name">{client.full_name}</span>
                <small className="c-messages__clients-item-date">
                  {/*{client?.message?.created_at*/}
                  {/*  ? getFormatBriefDate(getDateFromString(client?.message?.created_at), true)*/}
                  {/*  : ''}*/}
                  {client?.message?.created_at}
                </small>
              </span>
              <span className="c-messages__clients-item-summary">
                {(client?.message?.last_message === 'system:attachment' ? 'File' : client?.message?.last_message) || ''}
              </span>
            </div>
          </div>
        ))
      ) : (
        <div className="p-3">
          <div className="alert alert-warning text-center">No Client Exists</div>
        </div>
      )}
    </div>
  );
};

MessageClientList.propTypes = {
  clients: PropTypes.array,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default MessageClientList;
