import React, { useContext, useEffect } from 'react';
import Modal from '../../UIKit/Modal';
import { IMAGE_SUCCESS_CHECK } from '../../../../assets/Images';
import Button from '../../UIKit/Form/Button';
import { Types } from '../../../../config/actionTypes';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const BothCongressModal = ({ onClose }) => {
  const { setSystem, onCheckAccess } = useContext(AppContext);
  useEffect(() => {
    localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
  }, []);

  const handleStart = (system) => {
    setSystem(system);
    if (system === 'software') onCheckAccess();
    onClose();
  };
  return (
    <Modal show auto disableFooter disableHeader onClose={handleStart} title="Congress Both">
      <div className="c-setup-system-congratulation">
        <img src={IMAGE_SUCCESS_CHECK} alt="software" />
        <h2>CONGRATULATIONS!</h2>
        <p>Let&apos;s find your next client with the Beautster Marketplace!</p>
      </div>
      <div className="d-flex align-center my-5">
        <Button lg color="info" type="button" onClick={() => handleStart('software')} className="m-auto">
          START SOFTWARE
        </Button>
        <Button lg type="button" onClick={() => handleStart('marketplace')} className="m-auto">
          START MARKETPLACE
        </Button>
      </div>
    </Modal>
  );
};

export default BothCongressModal;
