import React from 'react';
import Select from '../UIKit/Form/Select';

// eslint-disable-next-line react/prop-types
const ExpireSelect = ({ onChange, value }) => (
  <Select
    id={'expireAfter'}
    value={value}
    onChangeValue={onChange}
    options={expireOptions}
    label={'Valid for'}
    menuPlacement="top"
  />
);

export const expireOptions = [
  { label: 'Select', value: '0' },
  { label: '1 days', value: '1' },
  { label: '1 week', value: '7' },
  { label: '15 days', value: '15' },
  { label: '1 month', value: '30' },
  { label: '2 month', value: '60' },
  { label: '3 month', value: '90' },
  { label: '6 month', value: '180' },
];

export default ExpireSelect;
