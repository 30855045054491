import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Send, Attachment } from '@material-ui/icons';
import { ControlInput } from '../UIKit/Form/Input';
import { handleApiSave } from '../../../utilities/useApi';
import { createMessage } from '../../../api';
import { useForm } from 'react-hook-form';
import MessageAttachment from './MessageAttachment';

export const MessageAttachmentForm = ({ file, onChange }) => {
  const fileRef = useRef(null);

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        onChange({ src: reader.result, file });
      };
    } else {
      onChange(null);
    }
  };

  return (
    <div className="c-messages__form-attachment">
      <input
        type="file"
        id="file"
        ref={fileRef}
        className="c-messages__form-attachment-input"
        onChange={handleChangeFile}
      />
      <Attachment />
      {file && (
        <MessageAttachment
          file={file}
          className="c-messages__form-attachment-preview"
          onRemove={() => onChange(null)}
        />
      )}
    </div>
  );
};

MessageAttachmentForm.propTypes = {
  file: PropTypes.any,
  onChange: PropTypes.func,
};

const MessageForm = ({ client, onDraft, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const { control, handleSubmit, setValue, clearErrors } = useForm();

  useEffect(() => {
    setValue('message', '');
    setAttachment(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);
  useEffect(() => {
    if (attachment) {
      clearErrors('message');
    }
  }, [attachment]);

  const handleSubmitForm = ({ message }) => {
    if (saveLoading) return;
    const body = {
      body: message,
      // receiver: client?.id,
      // receiver: client?.user?.id,
      user: client?.user?.id,
    };
    if (attachment) {
      body.attachment = attachment.file;
      body.body = !!message ? message : 'system:attachment';
    }
    onDraft(body);
    setValue('message', '');
    setAttachment(null);
    handleApiSave(createMessage, null, body, null, {
      loadingFn: setSaveLoading,
    })
      .then((data) => onSave({ ...data }))
      .catch(() => {});
  };

  return client ? (
    <form className="c-messages__form" onSubmit={handleSubmit(handleSubmitForm)}>
      <MessageAttachmentForm file={attachment} onChange={setAttachment} />
      <ControlInput
        control={control}
        // type="textarea"
        type="text"
        name="message"
        rows={1}
        rules={!attachment ? { required: 'Message is required' } : undefined}
        defaultValue=""
        placeholder={!attachment ? 'Type a message' : 'Type a Caption'}
        className="c-messages__form-input"
        formClassName="w-100 mb-0"
      />
      <button type="submit" disabled={saveLoading} className="c-messages__form-button">
        <Send />
      </button>
    </form>
  ) : null;
};

MessageForm.propTypes = {
  client: PropTypes.any,
  onDraft: PropTypes.func,
  onSave: PropTypes.func,
};

export default MessageForm;
