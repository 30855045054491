import React from 'react';
import toaster from 'toasted-notes';
import { getStringFromTime } from './DateHelpers';

export const handleError500 = (err) => {
  if (+err.status === 500 && +(process.env.REACT_APP_DEBUG || 0) === 1) {
    // toaster.removeAll();
    toaster.notify(
      () => (
        <div className="pt-1">
          <div className="alert alert-danger">HTTP/1.1 500 Internal Server Error</div>
        </div>
      ),
      { duration: 4000 },
    );
  }
  return err;
};

export const handleErrorUnAvailableAppointment = (data, body = null) => {
  if (+data.status === 400 || data.is_available === false) {
    const durationTime = body?.duration ? body.duration.split(':') : null;
    const startTime = body?.reserved_time ? body.reserved_time : null;
    const staff = body?.staff ? body.staff : null;
    const times = startTime ? startTime.split(':') : null;
    const endDate = new Date();
    if (data?.errors?.non_field_errors) {
      toaster.notify(() => <div className="alert alert-danger">{data.errors.non_field_errors[0]}</div>, {
        duration: 5000,
      });
      return;
    }
    if (body && durationTime) {
      endDate.setSeconds(0);
      endDate.setHours(+times[0] + +durationTime[0]);
      endDate.setMinutes(+times[1] + +durationTime[1]);
    }
    const endTime = getStringFromTime(endDate);
    toaster.notify(
      () => (
        <div className="alert alert-danger">
          {!!body
            ? staff && staff?.name
              ? `${staff?.name} isn’t working ${
                  body.reserved_date && `on ${body.reserved_date} `
                } between ${startTime} and ${endTime}`
              : `Booking ${
                  body.reserved_date && `on ${body.reserved_date} `
                } between ${startTime} and ${endTime}unavailable`
            : data?.errors && data.errors[0]
            ? data.errors[0]
            : null}
        </div>
      ),
      { duration: 5000 },
    );
  }
};

export const handleFormError = ({ status, errors }, setError) => {
  try {
    if (errors) {
      if (status.toString() === '400') {
        Object.keys(errors).forEach((key) => {
          let message =
            typeof errors[key] === 'string' ? errors[key] : Array.isArray(errors[key]) ? errors[key][0] : null;

          if (message === null) {
            Object.keys(errors[key]).forEach((keyName) => {
              const error = errors[key][keyName];
              message = typeof error === 'string' ? error : Array.isArray(error) ? error[0] : null;
              if (keyName === '__all__' || keyName === 'non_field_errors') {
                if (message) toaster.notify(() => <div className="alert alert-danger">{message}</div>);
                return [`${key}.${keyName}`, message];
              } else {
                if (setError) setError(`${key}.${keyName}`, { type: 'manual', message });
                return [`${key}.${keyName}`, message];
              }
            });
          } else {
            if (key === '__all__' || key === 'non_field_errors') {
              if (message) toaster.notify(() => <div className="alert alert-danger">{message}</div>);
              return [key, message];
            } else if (key !== 'hash') {
              if (setError && isNaN(+key)) setError(key, { type: 'manual', message });
              return [key, message];
            }
          }
        });
      } else {
        const errorMessage = errors?.detail ? errors.detail : errors?.message ? errors.message : null;
        if (errorMessage) toaster.notify(() => <div className="alert alert-danger">{errorMessage}</div>);
      }
    } else {
      toaster.notify(() => <div className="alert alert-danger">Error Occur, Check again</div>);
    }
  } catch (e) {
    toaster.notify(() => <div className="alert alert-danger">Error Occur, Check again</div>);
  }
};

export const handleChangeNumber = (name, value, title, setError, clearErrors) => {
  if (isNaN(value) && setError) {
    setError(name, {
      type: 'manual',
      message: `${title} type have to be a decimal`,
    });
  } else {
    if (clearErrors) clearErrors(name);
  }
};

export const handleChangeDiscount = (name, value, setError, clearErrors, label = 'Discount ') => {
  if (isNaN(value)) {
    if (setError) {
      setError(name, {
        type: 'manual',
        message: `${label}type have to be a decimal`,
      });
    }
  } else {
    if (+value < 0) {
      if (setError) {
        setError(name, {
          type: 'min',
          message: `${label}cannot be less than 0`,
        });
      }
    } else if (+value > 100) {
      if (setError) {
        setError(name, {
          type: 'max',
          message: `${label}cannot be more than 100`,
        });
      }
    } else {
      if (clearErrors) clearErrors(name);
    }
  }

  return value;
};
