import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getPriceFormat } from '../../../utilities/Price';
import { getFormatTime } from '../../../utilities/DateHelpers';
import classNames from 'classnames';

function ServiceItem({ item, isModify }) {
  const { id, name, tiered_level, duration, priceType, price } = item;
  const history = useHistory();

  const handleLinked = () => {
    history.push(`/services/${id}/edit`);
  };

  const tieredLevels = {
    no_level: 'No Tiered Level',
    entry_level: 'Entry Level',
    level_1: 'Level 1',
    level_2: 'Level 2',
    level_3: 'Level 3',
    new_talent: 'New Talent',
    stylist: 'Stylist',
    senior_stylist: 'Senior Stylist',
    master_stylist: 'Master Stylist',
    educator: 'Educator',
  };

  return (
    <div
      id={`service-${id}`}
      onClick={isModify ? handleLinked : undefined}
      className={classNames('c-service', {
        'c-service--clickable': isModify,
      })}
    >
      <div className="c-service__detail">
        <div className="c-service__info">
          {/*<i className="fas fa-bars c-service>*/}
          <h3 className="c-service__title">
            {name} {tiered_level && `- ${tieredLevels[tiered_level]}`}
          </h3>
        </div>
        <div className="c-service__duration">
          <span>{getFormatTime(duration, false, { hour: ' hr ', min: ' min' })}</span>
        </div>
      </div>
      <div className="c-service__price">
        <span>
          {priceType.toLowerCase() !== 'fixed' && `${priceType} `}
          {+price > 0 && getPriceFormat(+price)}
        </span>
      </div>
    </div>
  );
}

ServiceItem.propTypes = {
  item: PropTypes.any,
  isModify: PropTypes.bool,
};

export default ServiceItem;
