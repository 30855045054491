import React, { useEffect } from 'react';
import { WifiTethering } from '@material-ui/icons';
import { IMAGE_REFRESH_CONNECT_STRIPE } from '../../../assets/Images';
import { BoxSpinner } from '../../Common/UIKit/Spinner';
import { handleApi } from '../../../utilities/useApi';
import { getStripeConnectAccountLink, getStripeDisconnectAccountLink } from '../../../api';

const RefreshAccountStripePage = () => {
  useEffect(() => {
    handleApi(getStripeDisconnectAccountLink, {}, { list: false })
      .then((_res) => {
        if (!_res.connected_to_stripe) {
          handleApi(getStripeConnectAccountLink, {}, { list: false }).then((res) => {
            if (res?.connect_url) {
              window.location.href = res.connect_url;
            } else {
              // console.log(res);
            }
          });
          // .catch((err) => {
          //   console.log(err);
          // });
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          handleApi(getStripeConnectAccountLink, {}, { list: false }).then((res) => {
            if (res?.connect_url) {
              window.location.href = res.connect_url;
            }
          });
          // .catch((err) => {
          //   console.log(err);
          // });
        }
      });
  }, []);
  return (
    <div className="c-payment-stripe c-payment-stripe--success">
      <img className="c-payment-stripe__background" src={IMAGE_REFRESH_CONNECT_STRIPE} alt="Beautster" />

      <div className="c-payment-stripe__box c-payment-stripe__box--refresh">
        <span className="c-payment-stripe__box-icon">
          <WifiTethering />
        </span>

        <h1 className="c-payment-stripe__box-title mb-0">Try To Connect Again</h1>
        <BoxSpinner />
      </div>
    </div>
  );
};

export default RefreshAccountStripePage;
