import React, { useContext, useEffect, useState } from 'react';
import { Check } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { PageLoading } from '../UIKit/Spinner';
import { handleApi } from '../../../utilities/useApi';
import { createBulkBookablePhoto, getInstagramAccessToken, getInstagramMediaList } from '../../../api';
import classNames from 'classnames';
import { getQueryUri } from '../../../utilities/URI';
import Button from '../UIKit/Form/Button';
import { AppContext } from '../../../context/providers';
import { PARTNER_URL } from '../../../config/appConfig';

const BookablePhotoInstagramModal = ({ onClose, onSave }) => {
  const [selectedList, setSelectedList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [paging, setPaging] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const { business } = useContext(AppContext);
  const handleGetData = (userId, accessToken) => {
    handleApi(
      getInstagramMediaList,
      {
        userId: userId,
        accessToken: accessToken,
      },
      { list: false, loadingFn: setLoading },
    )
      .then((res) => {
        if (res?.data) {
          setData(
            res?.data.filter(
              (i) =>
                (i.media_type || '').toLowerCase() === 'image' ||
                ((i.media_type || '').toUpperCase() === 'CAROUSEL_ALBUM' &&
                  ((i.media_url || '').includes('.jpg') ||
                    (i.media_url || '').includes('.png') ||
                    (i.media_url || '').includes('.jpeg'))),
            ),
          );
          setPaging(res?.paging);
        }
      })
      .catch((err) => {
        const code = getQueryUri('code');
        if (err?.code === '190' || !code) {
          let instaUrl = 'https://www.instagram.com/oauth/authorize?';
          instaUrl += 'client_id=965159147682266';
          // instaUrl += '&redirect_uri=https://partners.beautster.com/bookable_photos/instagram/';
          instaUrl += `&redirect_uri=${PARTNER_URL}bookable_photos/instagram/`;
          instaUrl += '&scope=user_profile,user_media';
          instaUrl += '&response_type=code';
          window.location.href = instaUrl;
        }
        console.log(err);
      });
  };

  useEffect(() => {
    if (!data && business) {
      setLoading(true);
      const code = getQueryUri('code');
      if (!!code) {
        handleApi(getInstagramAccessToken, { code }, { list: false, loadingFn: setLoading })
          .then((res) => {
            if (res) {
              handleGetData(res.user_id, res.access_token);
            } else {
              onClose();
            }
          })
          .catch(onClose);
      } else if (business?.instagramToken && business?.instagramUserId) {
        handleGetData(business.instagramUserId, business.instagramToken);
      } else if (business) {
        onClose();
      }
    }
  }, [business]);

  const isSelected = (id) => selectedList.findIndex((i) => i.id === id) > -1;

  const handleSelectItem = (item) => {
    const updateSelectedList = [...selectedList];
    const selectedIndex = updateSelectedList.findIndex((i) => i.id === item.id);
    if (selectedIndex > -1) {
      updateSelectedList.splice(selectedIndex, 1);
    } else {
      updateSelectedList.push(item);
    }
    setSelectedList(updateSelectedList);
  };

  const toDataURL = (url, caption) => {
    console.log(url, caption);
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            const hashTags = (caption || '').match(/#[a-z]+/gi);
            const captionText =
              (hashTags || []).length > 0 ? (caption || '').substring(0, caption.indexOf(hashTags[0])) : caption || '';
            reader.onloadend = () => resolve({ file: reader.result, hashTags, captionText });
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      )
      .catch((err) => {
        console.log('err', err);
      });
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleSave = () => {
    setSaveLoading(true);
    const promiseList = selectedList.map(({ media_url, caption }) => toDataURL(media_url, caption));
    Promise.all(promiseList)
      .then((dataUrl) => {
        console.log('dataUrl', dataUrl);
        const files = dataUrl.map((d, i) => ({
          file: dataURLtoFile(d.file, `insta_image_${i}.jpg`),
          captionText: d.captionText || '',
          hashTags: d.hashTags || [],
        }));
        console.log('files', files);

        const photoListApi = files.map(({ file, hashTags, captionText }) => {
          const formData = new FormData();
          formData.append('image', file);
          formData.append('metadata', JSON.stringify({ hash_tag: hashTags, caption: captionText }));
          return createBulkBookablePhoto(formData);
        });
        Promise.all(photoListApi)
          .then((res) => {
            if (res) {
              onSave();
            }
          })
          .finally(() => setSaveLoading(false));
        // const formData = new FormData();
        // files.forEach(({ file }) => {
        //   formData.append('images', file);
        // });
        // createBulkBookablePhoto(formData)
        //   .then((res) => {
        //     if (res) {
        //       onSave();
        //     }
        //   })
        //   .finally(() => setSaveLoading(false));
      })
      .catch(() => {
        setSaveLoading(false);
      });
  };

  const handleNextPage = () => {
    handleApi(
      getInstagramMediaList,
      {
        next: paging.next,
      },
      { list: false, loadingFn: setPageLoading },
    ).then((res) => {
      if (res?.data) {
        setData([...data, ...res?.data.filter((i) => (i.media_type || '').toLowerCase() === 'image')]);
        setPaging(res?.paging);
      }
    });
  };

  return (
    <Modal
      show
      auto
      sticky
      // lg={!!data}
      title="Instagram Media"
      description="Select photos which you want to add to your bookable photo"
      onClose={onClose}
      onSave={handleSave}
      saveLoading={saveLoading}
      saveBtnDisabled={selectedList.length === 0}
      saveBtnText="Import Selected Photos"
    >
      {loading ? (
        <PageLoading />
      ) : data ? (
        <div className="c-bookable-photo__instagram-gallery">
          {data.map((i) => (
            <div key={i.id} className="c-bookable-photo__instagram-item" onClick={() => handleSelectItem(i)}>
              <span
                className={classNames('c-bookable-photo__instagram-icon', {
                  'c-bookable-photo__instagram-icon--active': isSelected(i.id),
                })}
              >
                {isSelected(i.id) && <Check />}
              </span>
              <img className="c-bookable-photo__instagram-image" src={i.media_url} alt="Beautster" />
            </div>
          ))}
        </div>
      ) : null}
      {paging?.next && (
        <Button color="light" className="mx-auto my-3" onClick={handleNextPage} loading={pageLoading}>
          Load More
        </Button>
      )}
    </Modal>
  );
};

BookablePhotoInstagramModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default BookablePhotoInstagramModal;
