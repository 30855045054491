import React from 'react';
import { ErrorOutline } from '@material-ui/icons';
import Button from './Form/Button';

export const AlertAccess = () => (
  <div className="c-dashboard__alert c-dashboard__alert--danger">
    <ErrorOutline />
    <div className="c-dashboard__alert-body">
      <h2>Software Permission</h2>
      <p className="c-dashboard__alert-description font-size-14 font-weight-bold">
        {/*You do not have any plan, so you cannot add new appointment or staff*/}
        You do not have any plan
      </p>
      <Button sm color="light" className="mt-3" link="/subscription">
        Subscribe Now
      </Button>
    </div>
  </div>
);
