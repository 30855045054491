import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import GiftCardObject from '../objects/GiftCardObject';

const { GET, POST, PUT, DELETE } = ApiMethods;

/**
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to
 *     campaign_status
 *     expire_after
 * }}
 */
export const createGiftCard = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.giftCard.create,
    params,
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new GiftCardObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to
 *     campaign_status
 *     expire_after
 * }}
 */
export const updateGiftCard = async (id, params) => {
  const response = await callApi({
    method: PUT,
    url: createURL(ApiRoutes.giftCard.update, { id }),
    params,
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new GiftCardObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getGiftCardList = async ({ search, page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.giftCard.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new GiftCardObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getGiftCard = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.giftCard.get, { id }),
  });
  if (response.data) {
    return new GiftCardObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteGiftCard = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.giftCard.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
