import React, { useContext, useState } from 'react';
import { FileCopy } from '@material-ui/icons';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import SectionHead from '../UIKit/SectionTitle';
import Input from '../UIKit/Form/Input';
import { MARKETPLACE_URL } from '../../../config/appConfig';
import Alert from '../UIKit/Alert';
import { AppContext } from '../../../context/providers';
import { IMAGE_CROWN } from '../../../assets/Images';
import SetupMarketplaceModal from '../SystemSetup/Marketplace/SetupMarketplaceModal';

const BusinessShortenLink = ({ data }) => {
  const [showSetup, setShowSetup] = useState(false);
  const { business } = useContext(AppContext);
  const onCopyLink = () => {
    navigator.clipboard.writeText(`${MARKETPLACE_URL}/business/${data.slug}/?utm=site`).then(() => {
      toaster.notify(() => <Alert success>Link copied</Alert>, {
        position: 'bottom',
      });
    });
  };
  return (
    <>
      <div className="c-panel-content">
        <SectionHead title="Shorten Link" />
        {business?.marketplace_setup ? (
          <Input
            disabled
            label="Your business shorten link"
            unit={<FileCopy />}
            onClickUnit={onCopyLink}
            value={`${MARKETPLACE_URL}/business/${data.slug}/?utm=site`}
          />
        ) : (
          <div className="c-settings__setup">
            <span className="c-settings__setup-text">
              For using shorten link, first of all you should setup <strong>marketplace</strong>
            </span>
            <div className="c-settings__setup-btn" onClick={() => setShowSetup(true)}>
              <img src={IMAGE_CROWN} alt="marketplace" className="c-panel-sidebar__setup-img" />
              <span className="c-settings__setup-btn-text">Setup MarketPlace</span>
            </div>
          </div>
        )}
      </div>
      {showSetup && <SetupMarketplaceModal onClose={() => setShowSetup(false)} />}
    </>
  );
};

BusinessShortenLink.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func,
};

export default BusinessShortenLink;
