import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
// import ServiceObject from "../objects/ServiceObject";
import ServiceGroupObject, { ServiceItemObject } from '../objects/ServiceGroupObject';

const { GET, POST, PUT, DELETE } = ApiMethods;

/**
 * @param params {{
 *     name,
 *     description,
 *     duration,
 *     tiered_level,
 *     is_off_peak_sale,
 *     off_peak_off,
 *     hash,
 *     price,
 *     is_and_up,
 *     is_deal,
 *     category,
 *     providers,
 * }}
 */
export const createService = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.service.create,
    // needStatus: true
  });
  if (response.data) {
    return Array.isArray(response.data) ? response.data[0] : response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     description,
 *     duration,
 *     tiered_level,
 *     is_off_peak_sale,
 *     off_peak_off,
 *     hash,
 *     price,
 *     is_and_up,
 *     is_deal,
 *     category,
 *     providers,
 * }}
 */
export const updateService = async (id, params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: createURL(ApiRoutes.service.update, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param page
 * @param limit
 */
export const getServiceList = async ({ page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.service.list,
    // needStatus: true
  });
  // const { data, status } = response;
  // if (status === StatusCodes.Success) return (data.results || []).map(i => new ServiceObject(i));
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new ServiceItemObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

export const getServiceGroupList = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    url: ApiRoutes.categorizedServices.list,
    // needStatus: true
  });

  if (response.data) {
    return (response.data.results || []).map((i) => new ServiceGroupObject(i));
  } else {
    return Promise.reject(response);
  }
};

export const getService = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.service.get, { id }),
  });
  if (response.data) {
    return new ServiceItemObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const deleteService = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.service.delete, { id }),
    // needStatus: true
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
