/**
 *
 * @param message {{
 *     id,
 *     subject,
 *     body,
 *     attachment,
 *     is_read,
 *     sender,
 *     receiver,
 *     direction,
 *     created_at,
 * }}
 * @constructor
 */

function MessageObject(message) {
  this.id = message.id;
  this.subject = message.subject;
  this.body = message.body;
  this.attachment = message.attachment;
  this.is_read = message.is_read;
  this.receiver = message.receiver;
  this.sender = message.sender;
  this.direction = message.direction;
  // this.created_at = message.created_at;
  this.created_at = message.created_at ? message.created_at.replace(' UTC+0000', '') : '';
}

export default MessageObject;
