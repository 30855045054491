import React, { useEffect, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { ErrorOutline } from '@material-ui/icons';
import Button from '../UIKit/Form/Button';
import useApi, { handleApi, handleApiSave } from '../../../utilities/useApi';
import {
  getBookablePhotoList,
  getBusiness,
  getServiceList,
  getStripeConnectAccountLink,
  switchMarketplace,
} from '../../../api';
import { AppContext } from '../../../context/providers';
import { AlertAccess } from '../UIKit/Alerts';
import usePermission from '../../../utilities/usePermission';

export const DashboardNotificationAddPhoto = () => (
  <div className="c-dashboard__alert c-dashboard__alert--warning">
    <ErrorOutline />
    <div className="c-dashboard__alert-body">
      <h2>Add First Bookable Photo</h2>
      <p className="c-dashboard__alert-description">
        You have not added bookable photo yet. Add your first bookable photo now.
      </p>
      <Button sm id="dashNotifyFistPhotoBtn" color="light" className="mt-3" link="/bookable_photos">
        Setup First Photo
      </Button>
    </div>
  </div>
);

export const DashboardNotificationAddPhotoAvatar = () => (
  <div className="c-dashboard__alert c-dashboard__alert--warning">
    <ErrorOutline />
    <div className="c-dashboard__alert-body">
      <h2>Logo Image</h2>
      <p className="c-dashboard__alert-description">You have not added any logo image yet. Add logo now</p>
      <Button sm id="dashNotifyLogoBtn" color="light" className="mt-3" link="/settings/business-profile">
        Add Logo Profile
      </Button>
    </div>
  </div>
);

export const DashboardNotificationConnection = ({ title, description }) => {
  const [connectLoading, setConnectLoading] = useState(false);
  const { business } = useContext(AppContext);
  const handleConnectAccount = () => {
    handleApiSave(getStripeConnectAccountLink, undefined, {}, undefined, {
      loadingFn: setConnectLoading,
    })
      .then(({ connect_url }) => {
        window.location.href = connect_url;
      })
      .catch(() => {});
  };
  if (!(business && !business?.connected_to_stripe)) return null;
  return (
    <div className="c-dashboard__alert c-dashboard__alert--warning">
      <ErrorOutline />
      <div className="c-dashboard__alert-body">
        <h2>{title}</h2>
        <p className="c-dashboard__alert-description">{description}</p>
        <Button
          sm
          id="dashNotifyConnectStripeBtn"
          loading={connectLoading}
          color="light"
          className="mt-3"
          onClick={handleConnectAccount}
        >
          Link your bank account now
        </Button>
      </div>
    </div>
  );
};

DashboardNotificationConnection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

DashboardNotificationConnection.defaultProps = {
  title: 'Connect Stripe Account',
  description: 'Please connect to your stripe account to access pay appointment through stripe system',
};

const DashboardNotificationPublish = () => {
  const [upBusinessLoading, setUpBusinessLoading] = useState(false);
  const { setBusiness } = useContext(AppContext);
  const handleUpBusiness = () => {
    setUpBusinessLoading(true);
    handleApiSave(switchMarketplace)
      .then(() => {
        toaster.notify(() => (
          <div className="alert alert-success mt-3">Your business has been joined to marketplace Successfully</div>
        ));
        getBusiness()
          .then(setBusiness)
          .finally(() => setUpBusinessLoading(true));
      })
      .catch(() => setUpBusinessLoading(true));
  };
  return (
    <div className="c-dashboard__alert c-dashboard__alert--danger">
      <ErrorOutline />
      <div className="c-dashboard__alert-body">
        <h2>Your Business is down</h2>
        <p className="c-dashboard__alert-description">
          Clients cannot see your business on marketplace. If you want to share your business on marketplace, you should
          to switch it up
        </p>
        <Button
          sm
          id="dashNotifyPublishBtn"
          loading={upBusinessLoading}
          color="success"
          className="mt-3"
          onClick={handleUpBusiness}
        >
          Up Business Now
        </Button>
      </div>
    </div>
  );
};

const DashboardNotifications = ({ onVerifyIdentity }) => {
  const [services, loading] = useApi(getServiceList, { limit: 0 });
  const [hasPhoto, setHasPhoto] = useState(false);
  const { isMarketplace, user, business, softwareAccess, system, userPermissions: p } = useContext(AppContext);

  useEffect(() => {
    if (business?.avatar && system === 'marketplace') {
      handleApi(getBookablePhotoList, { limit: 1 }).then((res) => {
        setHasPhoto(res.count > 0);
      });
    }
  }, [business, system]);
  const { isOwner } = usePermission();
  const { _stripeConnect, _publish } = useMemo(
    () => ({
      _stripeConnect: p?.business?.connect_to_stripe?.modify && business && !business?.connected_to_stripe,
      _publish: p?.business?.marketplace_switch?.modify && business && isMarketplace && !business?.is_published,
    }),
    [p, business, isMarketplace],
  );
  return !loading ? (
    <>
      {!softwareAccess ? (
        <AlertAccess />
      ) : services && services?.count === 0 ? (
        <div className="c-dashboard__alert c-dashboard__alert--warning">
          <ErrorOutline />
          <div className="c-dashboard__alert-body">
            <h2>Add First Service</h2>
            <p className="c-dashboard__alert-description">
              You have not added service yet. Add your first service now.
            </p>
            <Button sm id="dashNotifyFistServiceBtn" color="light" className="mt-3" link="/services/add">
              Setup First Service
            </Button>
          </div>
        </div>
      ) : _stripeConnect || isOwner ? (
        <DashboardNotificationConnection />
      ) : isMarketplace ? (
        !business?.avatar ? (
          <DashboardNotificationAddPhotoAvatar />
        ) : !hasPhoto ? (
          <DashboardNotificationAddPhoto />
        ) : null
      ) : _publish || isOwner ? (
        <DashboardNotificationPublish />
      ) : (
        user?.user && (
          <>
            {!user.user.mobile_verified && user.user.mobile ? (
              <div className="c-dashboard__alert c-dashboard__alert--warning">
                <ErrorOutline />
                <div className="c-dashboard__alert-body">
                  <h2>Verify Mobile</h2>
                  <p className="c-dashboard__alert-description">
                    We now need to verify your mobile number. Please click this link and verify your mobile number now.
                  </p>
                  <Button
                    sm
                    id="dashNotifyVerifyMobileBtn"
                    color="light"
                    className="mt-3"
                    onClick={() => onVerifyIdentity('mobile')}
                  >
                    Verify Now
                  </Button>
                </div>
              </div>
            ) : !user.user.email_verified && user.user.email ? (
              <div className="c-dashboard__alert c-dashboard__alert--warning">
                <ErrorOutline />
                <div className="c-dashboard__alert-body">
                  <h2>Verify Email</h2>
                  <p className="c-dashboard__alert-description">
                    {/*We now need to verify your email address. We've sent an email to {user.user.email}*/}
                    {/*to verify your address. Please click the link in that email to continue.*/}
                    We now need to verify your email address. Please click this link and verify your email address now.
                  </p>
                  <Button
                    sm
                    id="dashNotifyVerifyEmailBtn"
                    color="light"
                    className="mt-3"
                    onClick={() => onVerifyIdentity('email')}
                  >
                    Verify Now
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        )
      )}
    </>
  ) : null;
};

DashboardNotifications.propTypes = {
  onVerifyIdentity: PropTypes.func,
};

export default DashboardNotifications;
