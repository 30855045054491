import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const convertToTimeParams = (remaining) => {
  if (remaining <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  let tempRemain = remaining;
  const days = Math.floor(tempRemain / (24 * 3600));
  tempRemain -= days * 24 * 3600;
  const hours = Math.floor(tempRemain / 3600);
  tempRemain -= hours * 3600;
  const minutes = Math.floor(tempRemain / 60);
  tempRemain -= minutes * 60;
  const seconds = tempRemain;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const CountDown = ({ className, remaining }) => {
  const [internalRemaining, setInternalRemaining] = useState(remaining);
  const [data, setData] = useState(convertToTimeParams(internalRemaining));
  useEffect(() => {
    const timer = setTimeout(() => {
      if (internalRemaining >= 1) {
        setData(convertToTimeParams(internalRemaining - 1));
        setInternalRemaining(internalRemaining - 1);
      } else {
        clearTimeout(timer);
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  if (!data) {
    return null;
  }
  const classes = classnames('c-count-down', className);

  return (
    <div className={classes}>
      {data.days >= 0 ? (
        <div className="c-count-down__card">
          {data.days}
          <br />
          DAY
        </div>
      ) : null}
      {data.hours >= 0 ? (
        <div className="c-count-down__card">
          {data.hours}
          <br />
          HR
        </div>
      ) : null}
      {data.minutes >= 0 ? (
        <div className="c-count-down__card">
          {data.minutes}
          <br />
          MIN
        </div>
      ) : null}
      {data.seconds >= 0 ? (
        <div className="c-count-down__card">
          {data.seconds}
          <br />
          SEC
        </div>
      ) : null}
    </div>
  );
};

CountDown.propTypes = {
  className: PropTypes.string,
  remaining: PropTypes.number,
};

export default CountDown;
