import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    backgroundColor: '#FFF',
  },
  invoice: {
    padding: 16,
  },
  client: {
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid',
  },
  clientName: {
    fontSize: 16,
    fontWeight: 500,
    display: 'block',
  },
  clientMeta: {
    fontSize: 12,
    display: 'block',
    color: '#999',
  },
  section: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    paddingVertical: 16,
  },
  sectionBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 0,
    paddingVertical: 5,
  },
  sectionValue: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 0,
    paddingVertical: 5,
  },
  list: {},
  item: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'spaceBetween',
    paddingVertical: 16,
    paddingHorizontal: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    borderBottomStyle: 'solid',
  },
  itemInfo: {
    flex: 1,
  },
  itemLabel: {
    fontSize: 16,
    display: 'block',
  },
  itemValue: {
    fontSize: 16,
    display: 'block',
  },
  itemMeta: {
    fontSize: 14,
    display: 'block',
    color: '#999',
  },
});

export default styles;
