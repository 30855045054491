import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { useForm } from 'react-hook-form';
import { ControlInput } from '../UIKit/Form/Input';
import { AppContext } from '../../../context/providers';
import useApi, { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getProfile, sendVerificationIdentity, verifyCodeIdentity } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';

const MobileVerifyModal = ({ onClose }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const { handleSubmit, control, watch } = useForm();
  const { user, setUser } = useContext(AppContext);
  const [, loading] = useApi(sendVerificationIdentity, { identity_type: 'mobile' });
  const codeText = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const onSubmit = ({ code }) => {
    setSaveLoading(true);
    handleApiSave(verifyCodeIdentity, undefined, { token: code.join('') })
      .then(() => {
        handleApi(getProfile)
          .then((res) => {
            setUser(res);
            onClose();
          })
          .finally(() => setSaveLoading(false));
      })
      .catch(() => setSaveLoading(false));
  };

  const onNextField = (text, ind) => {
    if (ind < 5 && !!text) {
      codeText[ind + 1].current.focus();
    }

    const textCode = watch().code.join('');
    if (textCode.length === 6) {
      handleSubmit(onSubmit);
    }
  };

  return (
    <Modal
      show
      title="Verify Mobile"
      onClose={onClose}
      saveBtnText="Submit"
      onSave={handleSubmit(onSubmit)}
      saveLoading={saveLoading}
    >
      {loading ? (
        <PageLoading />
      ) : (
        <form className="p-4" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-center mb-4">
            A code has been sent to <i>{user.user.mobile}</i>
            <br />
            Enter that code here
          </h3>
          <div className="c-dashboard__verify-form">
            {[1, 2, 3, 4, 5, 6].map((i, ind) => (
              <ControlInput
                key={i}
                type="tel"
                refs={codeText[ind]}
                formGroup={false}
                maxlength="1"
                size="1"
                onChangeText={(text) => onNextField(text, ind)}
                className="c-dashboard__verify-input"
                control={control}
                name={`code[${ind}]`}
                defaultValue=""
                placeholder=""
              />
            ))}
          </div>
        </form>
      )}
    </Modal>
  );
};

MobileVerifyModal.propTypes = {
  onClose: PropTypes.func,
};

export default MobileVerifyModal;
