import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ImageName from '../UIKit/ImageName';
import { getPriceFormat } from '../../../utilities/Price';
import { InvoicePaymentTypeChoice, InvoiceStatusChoice } from '../../../utilities/constants/choices';

export const InvoiceModalInformation = ({ data }) => {
  const { payments, refunds, services, giftCards, last_payment_date, last_refund_date } = useMemo(() => {
    const payments = data?.transactions ? data.transactions.filter((i) => i.type === 'payment') : [];
    const refunds = data?.transactions ? data.transactions.filter((i) => i.type === 'refund') : [];
    const services = data.lines.filter((i) => i.item_type === 'service');
    const giftCards = data.lines.filter((i) => i.item_type === 'gift_card');

    const payments_date = payments
      .sort((a, b) => {
        const a_created_at = new Date(a.created_at);
        const b_created_at = new Date(b.created_at);
        return a_created_at < b_created_at ? -1 : a_created_at > b_created_at ? 1 : 0;
      })
      .reverse();
    const refunds_date = refunds
      .sort((a, b) => {
        const a_created_at = new Date(a.created_at);
        const b_created_at = new Date(b.created_at);
        return a_created_at < b_created_at ? -1 : a_created_at > b_created_at ? 1 : 0;
      })
      .reverse();

    return {
      payments,
      refunds,
      services,
      giftCards,
      last_payment_date: payments_date.length > 0 ? payments_date[0].created_at : null,
      last_refund_date: refunds_date.length > 0 ? refunds_date[0].created_at : null,
    };
  }, [data]);
  return (
    <>
      <div className="c-invoice-detail__client">
        <ImageName name={data.client_name} className="c-invoice-detail__client-image" />
        <div className="c-invoice-detail__client-info">
          <span className="c-invoice-detail__client-name">{data.client_name}</span>
          <span className="c-invoice-detail__client-meta">Client {!data.client && <small>Walk-In</small>}</span>
        </div>
      </div>
      {services.length > 0 && (
        <div className="c-invoice-detail__section">
          <h3 className="c-invoice-detail__section-title">Services</h3>
          <ul className="c-invoice-detail__list">
            {services.map((l) => (
              <li className="c-invoice-detail__list-item" key={l.id}>
                <span className="c-invoice-detail__list-item-info">
                  <span className="c-invoice-detail__list-item-label">{l.item_name}</span>
                  <span className="c-invoice-detail__list-item-meta">with {l.staff_name}</span>
                </span>
                <span className="c-invoice-detail__list-item-value">{getPriceFormat(+l.price)}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {giftCards.length > 0 && (
        <div className="c-invoice-detail__section">
          <h3 className="c-invoice-detail__section-title">Gift Cards</h3>
          <ul className="c-invoice-detail__list">
            {giftCards.map((l) => (
              <li className="c-invoice-detail__list-item" key={l.id}>
                <span className="c-invoice-detail__list-item-info">
                  <span className="c-invoice-detail__list-item-label">{l.item_name}</span>
                  <span className="c-invoice-detail__list-item-meta">with {l.staff_name}</span>
                </span>
                <span className="c-invoice-detail__list-item-value">{getPriceFormat(+l.price)}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {data.tips.length > 0 && (
        <div className="c-invoice-detail__section">
          <h3 className="c-invoice-detail__section-title">Tips</h3>
          <ul className="c-invoice-detail__list">
            {data.tips.map((tip) => (
              <li className="c-invoice-detail__list-item" key={tip.id}>
                <span className="c-invoice-detail__list-item-info">
                  <span className="c-invoice-detail__list-item-label">{tip.staff_name}</span>
                </span>
                <span className="c-invoice-detail__list-item-value">
                  {tip.amount_type === 'percent'
                    ? `${getPriceFormat((+data.subtotal * +tip.amount) / 100, '$', '')} (%${+tip.amount})`
                    : `${getPriceFormat(+tip.amount, '$', '')} (%${(+data.subtotal * +tip.amount) / 100})`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {payments.length > 0 && (
        <div className="c-invoice-detail__section">
          <h3 className="c-invoice-detail__section-title text-success">Payments</h3>
          <ul className="c-invoice-detail__list">
            {payments.map((payment) => (
              <li className="c-invoice-detail__list-item text-success" key={payment.id}>
                <span className="c-invoice-detail__list-item-info">
                  <span className="c-invoice-detail__list-item-label">{InvoicePaymentTypeChoice[payment.method]}</span>
                </span>
                <span className="c-invoice-detail__list-item-value">+{getPriceFormat(+payment.amount)}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {last_payment_date && (
        <div className="c-invoice-detail__section">
          <div className="c-invoice-detail__section-body">
            <span className="c-invoice-detail__section-title">Last Payment Date</span>
            <span className="c-invoice-detail__section-value">{last_payment_date}</span>
          </div>
        </div>
      )}

      {refunds.length > 0 && (
        <div className="c-invoice-detail__section">
          <h3 className="c-invoice-detail__section-title text-danger">Refunds</h3>
          <ul className="c-invoice-detail__list">
            {refunds.map((refund) => (
              <li className="c-invoice-detail__list-item  text-danger" key={refund.id}>
                <span className="c-invoice-detail__list-item-info">
                  <span className="c-invoice-detail__list-item-label">{InvoicePaymentTypeChoice[refund.method]}</span>
                </span>
                <span className="c-invoice-detail__list-item-value">-{getPriceFormat(+refund.amount)}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {last_refund_date && (
        <div className="c-invoice-detail__section">
          <div className="c-invoice-detail__section-body">
            <span className="c-invoice-detail__section-title">Last Refund Date</span>
            <span className="c-invoice-detail__section-value">{last_refund_date}</span>
          </div>
        </div>
      )}

      <div className="c-invoice-detail__section">
        <div className="c-invoice-detail__section-body">
          <span className="c-invoice-detail__section-title">SubTotal</span>
          <span className="c-invoice-detail__section-value">{getPriceFormat(+data.subtotal)}</span>
        </div>
      </div>
      <div className="c-invoice-detail__section">
        <div className="c-invoice-detail__section-body">
          <span className="c-invoice-detail__section-title">Tax</span>
          <span className="c-invoice-detail__section-value">
            {getPriceFormat(+data.tax_amount)} ({getPriceFormat(+data.tax_rate, '%')})
          </span>
        </div>
      </div>
      {+data.total > 0 && (
        <div className="c-invoice-detail__section">
          <div className="c-invoice-detail__section-body">
            <span className="c-invoice-detail__section-title">Total Paid</span>
            <span className="c-invoice-detail__section-value text-success">+{getPriceFormat(+data.total)}</span>
          </div>
        </div>
      )}

      {+data.total_refunds > 0 && (
        <div className="c-invoice-detail__section">
          <div className="c-invoice-detail__section-body">
            <span className="c-invoice-detail__section-title">Total Refunded</span>
            <span className="c-invoice-detail__section-value text-danger">-{getPriceFormat(+data.total_refunds)}</span>
          </div>
        </div>
      )}

      <div className="c-invoice-detail__section">
        <div className="c-invoice-detail__section-body">
          <span className="c-invoice-detail__section-title">Balance</span>
          <span className="c-invoice-detail__section-value">{getPriceFormat(+data.balance)}</span>
        </div>
      </div>

      <div className="c-invoice-detail__section">
        <div className="c-invoice-detail__section-body">
          <span className="c-invoice-detail__section-title">Status</span>
          <span className="c-invoice-detail__section-value">{InvoiceStatusChoice[data.status]}</span>
        </div>
      </div>
    </>
  );
};

InvoiceModalInformation.propTypes = {
  data: PropTypes.any,
};
