import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import DealObject from '../objects/DealObject';

const { GET, POST, PUT, PATCH, DELETE } = ApiMethods;

const getParams = (params) => {
  if (!!params.image) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (key === 'services') {
        params[key].forEach((s) => formData.append('services', s));
        // formData.append('services', JSON.stringify(params[key]))
      } else if (key === 'campaign') {
        Object.keys(params[key]).forEach((s) => formData.append(`campaign.${s}`, params[key][s]));
      } else if (!!params[key] && key !== 'image') formData.append(key, params[key]);
    });
    formData.append('image', params.image.file, params.image.file.name);
    return formData;
  }
  return params;
};

/**
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to
 *     campaign_status
 *     expire_after
 * }}
 */
export const createDeal = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.deal.create,
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });

  if (response.data) {
    return new DealObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to
 *     campaign_status
 *     expire_after
 * }}
 */
export const updateDeal = async (id, params) => {
  const response = await callApi({
    method: PUT,
    url: createURL(ApiRoutes.deal.update, { id }),
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new DealObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to
 *     campaign_status
 *     expire_after
 * }}
 */
export const partialUpdateDeal = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    url: createURL(ApiRoutes.deal.update, { id }),
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new DealObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     search,
 *     page,
 *     limit,
 *     create_channel
 * }}
 */
export const getDealList = async ({ search, page, limit, create_channel = 'marketplace' } = {}) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      create_channel,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.deal.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new DealObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getDeal = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.deal.get, { id }),
  });
  if (response.data) {
    return new DealObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteDeal = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.deal.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 **/
export const publishDeal = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.deal.publish, { id }),
    params: {},
  });
  if (response.data) {
    return new DealObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 **/
export const unPublishDeal = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.deal.unpublish, { id }),
    params: {},
  });
  if (response.data) {
    return new DealObject(response.data);
  } else {
    return Promise.reject(response);
  }
};
