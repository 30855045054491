import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { getFormat24H, getStringFromDate } from '../../../utilities/DateHelpers';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../utilities/useApi';
import { createStaffClosedDate, deleteStaffClosedDate, getStaffClosedDate, updateStaffClosedDate } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';
import { ControlDatePicker } from '../UIKit/Form/DatePicker';
import TimeSelect from '../Selections/TimeSelect';
import { ControlInput } from '../UIKit/Form/Input';
import Button from '../UIKit/Form/Button';
import { ControlStaffSelect } from '../Selections/StaffSelect';

const StaffClosedDateModal = ({ onClose, onSave }) => {
  const [delLoading, setDelLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { handleSubmit, control, errors } = useForm();

  const params = useParams();
  const id = params.id;

  const [data, loading] = useApiById(getStaffClosedDate);

  const handleSave = (res) => {
    const body = {
      title: res.title,
      staff: res.staff,
      start_time: `${getStringFromDate(res.start_date)} ${res.start_time}`,
      end_time: `${getStringFromDate(res.end_date)} ${res.end_time}`,
    };

    handleApiSave(createStaffClosedDate, updateStaffClosedDate, body, id, { loadingFn: setSaveLoading })
      .then(onSave)
      .catch(() => {});
  };

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete this Dates Closed',
      message: 'Are you sure to delete this Dates Closed.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteStaffClosedDate, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  const getValueTime = (time) => {
    if (time) {
      const t = time.split(' ');
      return `${getFormat24H(`${t[t.length - 2]} ${t[t.length - 1]}`)}:00`;
    }
    return null;
  };

  return (
    <Modal
      show
      title={!!id ? 'Edit Block Time' : 'New Block Time'}
      onSave={handleSubmit(handleSave)}
      saveLoading={saveLoading}
      buttonComponent={
        !!id && (
          <Button color="danger" loading={delLoading} onClick={handleDelete}>
            Delete
          </Button>
        )
      }
      onClose={onClose}
    >
      {loading && !!id ? (
        <PageLoading />
      ) : (
        <>
          <div className="alert alert-info mt-4">Online bookings can not be placed during dates Closed.</div>
          <form onSubmit={handleSubmit(handleSave)} className="pt-3 pb-5">
            <ControlStaffSelect
              control={control}
              name="staff"
              rules={{ required: 'Staff is required' }}
              label="Staff"
              defaultValue={data?.staff ? data.staff : null}
              errorMessage={errors?.start_time?.message}
            />
            <ControlDatePicker
              control={control}
              name="start_date"
              rules={{ required: 'Start Date is required' }}
              label="Start Date"
              defaultValue={data?.start_time ? new Date(data.start_time) : new Date()}
              errorMessage={errors?.start_time?.message}
            />
            <TimeSelect
              control={control}
              name="start_time"
              rules={{ required: 'Start Time is required' }}
              label="Start Time"
              defaultValue={data?.start_time ? getValueTime(data.start_time) : '06:00:00'}
              errorMessage={errors?.start_time?.message}
            />

            <ControlDatePicker
              control={control}
              name="end_date"
              rules={{ required: 'End Date is required' }}
              label="End Date"
              defaultValue={data?.end_time ? new Date(data.end_time) : new Date()}
              errorMessage={errors?.end_time?.message}
            />
            <TimeSelect
              control={control}
              name="end_time"
              rules={{ required: 'End Time is required' }}
              label="End Time"
              defaultValue={data?.end_time ? getValueTime(data.end_time) : null}
              errorMessage={errors?.end_time?.message}
            />

            <ControlInput
              control={control}
              name="title"
              rules={{ required: 'Title is required' }}
              defaultValue={data?.title ? data.title : ''}
              label="Title"
              placeholder="e.g public holiday"
              errorMessage={errors?.description?.message}
            />
          </form>
        </>
      )}
    </Modal>
  );
};

StaffClosedDateModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default StaffClosedDateModal;
