import React, { useEffect, useState } from 'react';
import Tab from '../../Common/UIKit/Tab';
import AnalyticsAppointmentsPage from './AnalyticsAppointmentsPage';
import AnalyticsClientsPage from './AnalyticsClientsPage';
import AnalyticsSalesPage from './AnalyticsSalesPage';
// import usePermission from '../../../utilities/usePermission';

const AnalyticsPage = ({ location, history }) => {
  const [tabIndex, setTabIndex] = useState(0);
  // const { access: _access } = usePermission('business');
  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('/analytics/appointments')) {
      setTabIndex(0);
    } else if (pathname.includes('/analytics/clients')) {
      setTabIndex(1);
    } else if (pathname.includes('/analytics/sales')) {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  const handleSelectTab = (ind) => {
    setTabIndex(ind);
    if (ind === 1) history.push('/analytics/clients');
    else if (ind === 2) history.push('/analytics/sales');
    else history.push('/analytics/appointments');
  };
  // const tabs = useMemo(() => {
  //   const res = [];
  //   if (_access?.appointments_statistics?.view) res.push('Appointments');
  //   if (_access?.clients_statistics?.view) res.push('Clients');
  //   if (_access?.sales_statistics?.view) res.push('Sales');
  //   return res;
  // }, [_access]);
  const tabs = ['Appointments', 'Clients', 'Sales'];
  const renderRightEl = (
    <Tab isMerely tabs={tabs} tabHeadClassName={'p-0'} onSelect={handleSelectTab} selected={tabIndex} />
  );

  const renderPages = () => {
    switch (tabIndex) {
      case 0:
        return <AnalyticsAppointmentsPage headerEl={renderRightEl} />;
      case 1:
        return <AnalyticsClientsPage headerEl={renderRightEl} />;
      case 2:
        return <AnalyticsSalesPage headerEl={renderRightEl} />;
      default:
        return <AnalyticsAppointmentsPage headerEl={renderRightEl} />;
    }
  };

  return renderPages();
};

export default AnalyticsPage;
