import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../context/providers';

const usePermission = (group = null, name = null, type = null) => {
  const [user, setUser] = useState(null);
  const { userPermissions: p, user: _user, business } = useContext(AppContext);

  useEffect(() => {
    if (_user && !user) {
      setUser(_user);
    } else if (!_user) {
      setUser(null);
    }
  }, [_user]);

  const access = useMemo(() => {
    if (group && name) {
      if (p?.[group]?.[name] && type) return p[group][name]?.[type];
      return p?.[group]?.[name];
    } else if (group) {
      return p?.[group];
    }
    return false;
  }, [p]);

  const isOwner = useMemo(
    () =>
      user && business
        ? String(user?.id || user?.user?.id) === String(business.owner)
          ? { modify: true, view: true }
          : false
        : false,
    [user, business],
  );

  const isReady = useMemo(() => isOwner || p, [p, isOwner]);

  return { isReady, access: isOwner || access, isOwner: !!isOwner };
};

export default usePermission;
