import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { partialUpdateBusiness } from '../../../api';
import MapSelectPosition from '../UIKit/Map/MapSelectPosition';
import { handleApiSave } from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import toaster from 'toasted-notes';
import Geocode from 'react-geocode';

const BusinessMapSelector = ({ onClose, onDone }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [data, setData] = useState(null);
  const { business, setBusiness } = useContext(AppContext);
  const defaultCenter = useMemo(() => {
    if (business) {
      const lat = +business.latitude;
      const lng = +business.longitude;
      if (lng === 0 || lat === 0) return null;
      return { lat, lng };
    }
    return null;
  }, [business]);

  const handleSave = () => {
    const body = {
      latitude: data?.lat ? data?.lat?.toFixed(6) : data?.data?.lat ? (+data?.data?.lat || 0).toFixed(6) : null,
      longitude: data?.lng ? data?.lng?.toFixed(6) : data?.data?.lng ? (+data?.data?.lng || 0).toFixed(6) : null,
      // profile: {
      //   latitude: +data.lat.toFixed(6),
      //   longitude: +data.lng.toFixed(6),
      // },
    };
    if (data?.data?.address_components) {
      const address_components = data?.data?.address_components;
      const inter_phone_number = data?.data?.international_phone_number;
      const zip_code = address_components.find((i) => i.types.find((j) => j === 'postal_code'));
      const country = address_components.find((i) => i.types.find((j) => j === 'country'));
      const city = address_components.find((i) => i.types.find((j) => j === 'locality'));
      const phone_number = inter_phone_number
        ? inter_phone_number.replaceAll(' ', '').replaceAll('-', '')
        : // .replaceAll('+', '')
          undefined;
      body.address = data.data.formatted_address;

      if (country) body.country = country.long_name;
      if (city) body.city = city.long_name;

      if (data?.data?.business_status) {
        if (zip_code) body.zip_code = zip_code.long_name.replaceAll(' ', '');
        if (data.data.lat && data.data.lng) {
          body.latitude = +data.data.lat.toFixed(6);
          body.longitude = +data.data.lng.toFixed(6);
        }

        body.phone_number = phone_number;
        body.website_address = data.data.website_address;
        body.google_place_id = data.data.place_id;
      }
    }
    Geocode.setApiKey('AIzaSyBmvvn3UPSO3T3NSXMmngtwNoxfsd8qDZE');

    Geocode.setLanguage('en');

    // Geocode.fromLatLng(data.lat, data.lng).then(
    //   (response) => {
    //     const address = response.results[0].formatted_address;
    //   },
    //   (error) => {
    //     console.error(error);
    //   },
    // );
    handleApiSave(partialUpdateBusiness, null, body, null, { loadingFn: setSaveLoading }).then((business) => {
      setBusiness(business);
      toaster.notify(() => <div className="alert alert-success">Your location has been updated successfully</div>);
      onDone();
    });
  };
  return (
    <Modal
      show
      auto
      bodyClass="p-0"
      onSave={handleSave}
      saveLoading={saveLoading}
      title="Select Position"
      onClose={onClose}
    >
      <MapSelectPosition center={defaultCenter} onChange={setData} />
    </Modal>
  );
};

BusinessMapSelector.propTypes = {
  onClose: PropTypes.func,
  onDone: PropTypes.func,
};

export default BusinessMapSelector;
