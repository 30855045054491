import React, { forwardRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Delete } from '@material-ui/icons';
import { IMAGE_DEFAULT } from '../../../assets/Images';
import ImageCropModal from './ImageCropModal';

// eslint-disable-next-line react/display-name
const ImageSelector = forwardRef(
  (
    {
      id,
      buttonText,
      description,
      className,
      withEdit = true,
      editRatio = false,
      onChange,
      onDelete,
      value,
      defaultImage,
      ...otherProps
    },
    ref,
  ) => {
    const [originImage, setOriginImage] = useState(null);
    const [cropModal, setCropModal] = useState(null);
    const [imgSrc, setImageSrc] = useState(null);
    id = id ? id : 'image-selector';

    useEffect(() => {
      if (!!value && typeof value === 'string') {
        setImageSrc(value);
      }
    }, [value]);

    const handleChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        // const url = reader.readAsDataURL(file);
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (withEdit) {
            setOriginImage({ src: reader.result, file });
            setCropModal(true);
          } else {
            setImageSrc(reader.result);
            onChange({ file, url: reader.result });
          }
        };
      }
    };

    const handleChangeCrop = (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        onChange({ url: reader.result, file });
        setCropModal(false);
      };
    };

    const handleDelete = () => {
      setImageSrc(null);
      onChange(null);
      onDelete(null);
    };

    const handleCloseCrop = () => {
      setCropModal(false);
      document.querySelector('[data-el="file-input"]').value = '';
    };

    return (
      <>
        <div className={classnames('c-image-selector', className)}>
          <div className="c-image-selector__controller">
            <label htmlFor={id} id={`label-${id}`} className="c-image-selector__file-container">
              <label htmlFor={id} className="c-button c-button--main c-button--sm c-image-selector__button">
                {buttonText || 'Upload Image'}
              </label>
              <input
                ref={ref}
                id={id}
                data-el="file-input"
                type="file"
                accept="image/x-png,image/jpg,image/jpeg"
                // accept="image/*"
                onChange={handleChange}
                {...otherProps}
              />
            </label>
            {description && <p>{description}</p>}
          </div>

          <div
            className={
              editRatio
                ? 'c-image-selector__image-container'
                : 'c-image-selector__image-container c-image-selector__image-container--circle'
            }
          >
            <img
              className="c-image-selector__image"
              src={imgSrc ? imgSrc : defaultImage ? defaultImage : IMAGE_DEFAULT}
              alt={id}
            />
            {imgSrc && onDelete && (
              <span className="c-image-selector__image-delete" onClick={handleDelete}>
                <Delete />
              </span>
            )}
          </div>
        </div>
        {cropModal && (
          <ImageCropModal
            editRatio={editRatio}
            originImage={originImage}
            setOriginImage={setOriginImage}
            onClose={handleCloseCrop}
            onChange={handleChangeCrop}
          />
        )}
      </>
    );
  },
);

ImageSelector.propTypes = {
  id: PropTypes.string.isRequired,
  editRatio: PropTypes.bool,
  buttonText: PropTypes.string,
  withEdit: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.string,
  description: PropTypes.string,
  defaultImage: PropTypes.any,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ImageSelector;
