import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getBusiness, getServiceList, switchMarketplace } from '../../../api';
import toaster from 'toasted-notes';
import { AppContext } from '../../../context/providers';
import Switch from '../../Common/UIKit/Form/Switch';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { MARKETPLACE_URL } from '../../../config/appConfig';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import usePermission from '../../../utilities/usePermission';

const SettingMarketplacePage = () => {
  const history = useHistory();
  const [isPublished, setIsPublished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPublishable, setIsPublishable] = useState(false);

  const { setBusiness, business, setHeader, userPermissions: p } = useContext(AppContext);

  useEffect(() => {
    setHeader('Settings / Marketplace');
  }, [setHeader]);

  useEffect(() => {
    handleApi(getServiceList, { limit: 1 }).then((res) => {
      setIsPublishable(res.count > 0);
    });
    setIsPublished(business?.is_published);
  }, [business]);

  const _marketplace = useMemo(
    () =>
      p?.business?.marketplace_switch?.modify || p?.business?.marketplace_switch?.view
        ? p?.business?.marketplace_switch
        : false,
    [p],
  );

  const { isOwner } = usePermission();

  const onSwitchMarketPlace = () => {
    handleApiSave(switchMarketplace, null, {}, null, { loadingFn: setLoading })
      .then(() => {
        toaster.notify(() => (
          <div className="alert alert-success mt-3">
            Your business has been {isPublished ? 'left from' : 'joined to'} the marketplace Successfully
          </div>
        ));
        setIsPublished(!isPublished);
        getBusiness({}).then(setBusiness);
        // history.push('/');
      })
      .catch(() => {});
  };
  const onChangePublish = () => {
    if (isPublished) {
      confirmAlert({
        title: 'Leave Marketplace',
        message:
          'Are you ABSOLUTELY sure? This action cannot be undone. Your business will be temporarily deleted from Beautster’s marketplace',
        buttons: [
          {
            label: 'Yes Leave',
            className: 'bg-danger',
            onClick: onSwitchMarketPlace,
          },
          {
            label: 'No Keep',
            onClick: () => {},
          },
        ],
      });
    } else {
      if (isPublishable) {
        onSwitchMarketPlace();
      } else {
        confirmAlert({
          title: 'Publish To The Marketplace',
          message: 'Your business does not have any service, please add your first service.',
          buttons: [
            {
              label: 'Dismiss',
              className: 'bg-danger',
              onClick: () => {},
            },
            {
              label: 'Add Service',
              className: 'bg-success',
              onClick: () => {
                history.push('/services/add');
              },
            },
          ],
        });
      }
    }
  };
  return isOwner || _marketplace ? (
    <div className="c-panel-content mt-3">
      {loading && <PageLoading over />}
      {isPublished ? (
        <div className="c-settings-marketplace">
          <h3 className="text-success">Your Business Is Up</h3>

          <p className="c-settings-marketplace__p">Clients can see your business on the marketplace</p>

          <p className="c-settings-marketplace__p">
            Your page link:
            <a href={`${MARKETPLACE_URL}/business/${business?.slug}`} target="_blank" rel="noreferrer" className="ml-2">
              {MARKETPLACE_URL}/business/{business?.slug}
            </a>
          </p>

          <p className="c-settings-marketplace__p">
            If you want to stop sharing your business on the marketplace, you can use the switch in the below
          </p>
        </div>
      ) : (
        <div className="c-settings-marketplace">
          <h3 className="text-danger">Your Business Is Down</h3>

          <p className="c-settings-marketplace__p">
            Clients <span className="text-danger">cannot</span> see your business on the marketplace
          </p>

          <p className="c-settings-marketplace__p">
            Your page link:
            <a href={`${MARKETPLACE_URL}/business/${business?.slug}`} target="_blank" rel="noreferrer" className="ml-2">
              {MARKETPLACE_URL}/business/{business?.slug}
            </a>
          </p>

          <p className="c-settings-marketplace__p">
            If you want to publish your business on the marketplace, you can use the switch in the below
          </p>
        </div>
      )}
      {(isOwner || _marketplace?.modify) && (
        <Switch checked={isPublished} label="Publish To The Marketplace" formGroup onChange={onChangePublish} />
      )}
    </div>
  ) : (
    <NotFoundData title="Access Denied" />
  );
};

export default SettingMarketplacePage;
