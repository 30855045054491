import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { DoneOutlineRounded } from '@material-ui/icons';

const SetupHeadSteps = ({ list = [], active = 0 }) => {
  const activated = (step) => active > step;
  return (
    <ul className="c-setup-system__steps">
      {list.map(({ id, title, icon }, index) => (
        <li
          key={id}
          className={classnames('c-setup-system__steps-item', {
            'c-setup-system__steps-item--activated': activated(index),
            'c-setup-system__steps-item--active': active === index,
          })}
        >
          <span className="c-setup-system__steps-mark">{activated(index) ? <DoneOutlineRounded /> : icon}</span>
          <span className="c-setup-system__steps-text">{title}</span>
        </li>
      ))}
    </ul>
  );
};

SetupHeadSteps.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      icon: PropTypes.any,
    }),
  ),
  active: PropTypes.number,
};

export default SetupHeadSteps;
