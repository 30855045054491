import React from 'react';
import {
  AccountBalanceWallet,
  AccountTree,
  BusinessCenter,
  EditLocation,
  Flare,
  Map,
  Payment,
  ViewList,
} from '@material-ui/icons';
import {
  IMAGE_SETUP_INFO_ADDRESS,
  IMAGE_SETUP_INFO_MAP,
  IMAGE_SETUP_INFO_PLANS,
  IMAGE_SETUP_INFO_TRIAL,
  IMAGE_SETUP_MARKETPLACE_BANNER,
  IMAGE_SETUP_SOFTWARE_BANNER,
  ICON_USER_STYLIST,
  ICON_USER_BUSINESS,
} from '../../assets/Images';

export const STEPS_TYPES = {
  ONBOARDING: 'onboarding',
  SETUP_STYLIST: 'setup_stylist',
  STYLIST_LAYOUT: 'stylist_layout',
  QUESTIONS: 'business_questions',

  MARKETPLACE: 'marketplace',
  SOFTWARE: 'software',
  BOTH: 'both',

  SETUP_MARKETPLACE: 'setup_marketplace',
  SETUP_SOFTWARE: 'setup_software',
  SETUP_BOTH: 'setup_both',
};

export const QUESTIONS_KEYS = {
  CLIENT: 'new_clients',
  BOOKINGS: 'more_bookings',
  SALON: 'manage_salon',
  ALL: 'all',
};

export const USER_TYPE_KEYS = {
  STYLIST: 'stylist',
  BUSINESS: 'business',
};

export const GoalsOptions = [
  {
    label: QUESTIONS_KEYS.CLIENT,
    value: 'I want to bring more new clients into my salon',
  },
  {
    label: QUESTIONS_KEYS.BOOKINGS,
    value: 'I want to get more bookings from my existing clients',
  },
  {
    label: QUESTIONS_KEYS.SALON,
    value: 'I want to manage and automate my salon daily operations',
  },
  {
    label: QUESTIONS_KEYS.ALL,
    value: 'All of the above + free business coaching service',
  },
];

export const UserTypeOptions = [
  {
    id: USER_TYPE_KEYS.STYLIST,
    title: 'Stylist',
    image: ICON_USER_STYLIST,
    text: 'I am a stylist and I want to create my own profile',
  },
  {
    id: USER_TYPE_KEYS.BUSINESS,
    title: 'Business',
    image: ICON_USER_BUSINESS,
    text: 'I have a business and I want to manage my clients and appointments',
  },
];

export const GoalContent = {
  marketplace: {
    type: 'Marketplace',
    title: "Beautster's online Marketplace",
    text: 'Beautster is a vibrant, rapidly growing community of clients and beauty professionals that connects local customers to local salons. Our online customers are looking for businesses like yours. Show them why they should choose you.',
    youtubeId: 'I71KUWv-Ggk',
    imageCover: IMAGE_SETUP_MARKETPLACE_BANNER,
  },
  software: {
    type: 'Software',
    title: 'Beautster’s Powerful, High-end Salon Software',
    text: 'Beautster Custom Software is built based on almost 1100 interviews with salon and spa owners all over Canada. We automate scheduling, client and staff management, payment processing, and marketing with all-in-one software solutions. It is hosted conveniently on the cloud, so your info is backed up whenever you log in.',
    youtubeId: 'YiIEsTg_DBc',
    imageCover: IMAGE_SETUP_SOFTWARE_BANNER,
  },
};

export const MarketplaceStepList = [
  {
    id: 1,
    step: 0,
    key: 'profile',
    title: 'Business Profile',
    icon: <BusinessCenter />,
  },
  {
    id: 2,
    step: 1,
    key: 'booking_method',
    title: 'Booking Method',
    icon: <ViewList />,
  },
  {
    id: 3,
    step: 2,
    key: 'map_location',
    title: 'Location on map',
    icon: <Map />,
  },
  {
    id: 4,
    step: 3,
    key: 'form_location',
    title: 'Location details',
    icon: <EditLocation />,
  },
  {
    id: 5,
    step: 4,
    key: 'back_account',
    title: 'Bank account details',
    icon: <AccountBalanceWallet />,
  },
];

export const SoftwareStepList = [
  {
    id: 1,
    step: 0,
    key: 'profile',
    title: 'Business Profile',
    icon: <BusinessCenter />,
  },
  {
    id: 2,
    step: 1,
    key: 'plans',
    title: 'Choose Plan',
    icon: <AccountTree />,
  },
  {
    id: 3,
    step: 2,
    key: 'review',
    title: 'Review And Pay',
    icon: <Payment />,
  },
];

export const BothStepList = [
  {
    id: 1,
    step: 0,
    key: 'profile',
    title: 'Business Profile',
    icon: <BusinessCenter />,
  },
  {
    id: 2,
    step: 1,
    key: 'booking_method',
    title: 'Booking Methods',
    icon: <ViewList />,
  },
  {
    id: 3,
    step: 2,
    key: 'map_location',
    title: 'Location on map',
    icon: <Map />,
  },
  {
    id: 4,
    step: 3,
    key: 'form_location',
    title: 'Location details',
    icon: <EditLocation />,
  },
  {
    id: 5,
    step: 4,
    key: 'plans',
    title: 'Plan',
    icon: <AccountTree />,
  },
  {
    id: 6,
    step: 5,
    key: 'review',
    title: 'Review And Pay',
    icon: <Payment />,
  },
  {
    id: 7,
    step: 6,
    key: 'back_account',
    title: 'Bank account details',
    icon: <AccountBalanceWallet />,
  },
  {
    id: 8,
    step: 6,
    key: 'back_account',
    title: 'Congratulation',
    icon: <Flare />,
  },
];

export const StepInfoList = [
  {
    id: 1,
    stepKey: 'preview',
    youtubeId: 'JoMad3uMqIs',
    image: null,
  },
  {
    id: 2,
    stepKey: 'profile',
    youtubeId: 'UyHMCwyK02o',
    image: null,
  },
  {
    id: 3,
    stepKey: 'booking_method',
    youtubeId: 'a0ZY1ebpe1Q',
    image: null,
  },
  {
    id: 4,
    stepKey: 'map_location',
    youtubeId: null,
    image: IMAGE_SETUP_INFO_MAP,
  },
  {
    id: 5,
    stepKey: 'form_location',
    youtubeId: null,
    image: IMAGE_SETUP_INFO_ADDRESS,
  },
  {
    id: 6,
    stepKey: 'plans',
    youtubeId: null,
    image: IMAGE_SETUP_INFO_PLANS,
  },
  {
    id: 7,
    stepKey: 'review',
    youtubeId: null,
    image: IMAGE_SETUP_INFO_TRIAL,
  },
];
