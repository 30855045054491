import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';

const { GET, POST } = ApiMethods;

/**
 * @function googleCalendarAuth
 */
export const googleCalendarAuth = async () => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.googleCalendar.auth,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param param {{
 *   calendarId: Number
 * }}
 * @function googleCalendarSync
 */
export const googleCalendarSync = async ({ calendarId }) => {
  const response = await callApi({
    method: POST,
    params: {
      calendar_ids: calendarId,
      // calendar_id: calendarId,
    },
    url: ApiRoutes.googleCalendar.sync,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @function getGoogleCalendarSync
 */
export const getGoogleCalendarSync = async () => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.googleCalendar.sync,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @function googleCalendarEvents
 */
export const googleCalendarEventsList = async (ownerId = 'unknown', staffList = []) => {
  const response = await callApi({
    method: GET,
    url: ApiRoutes.googleCalendar.eventsList,
    params: {
      v: new Date().getTime(),
    },
  });
  const getResourceId = (i) => {
    const staff = staffList.find(
      (s) => s.email === i?.calendar_id || s.email === i?.calendar_name || s.email === i?.summary,
    );
    return staff?.id || ownerId;
  };
  if (response.data) {
    return (response.data?.results || []).map((i) => ({
      ...i,
      type: 'google-calendar',
      title: i.summary,
      resourceId: getResourceId(i),
      start: new Date(i.start_time),
      end: new Date(i.end_time),
      appointmentLines: [
        {
          id: i.id,
          client_name: 'Unknown',
          service_name: i.summary,
          start: new Date(i.start_time),
          end: new Date(i.end_time),
          resourceId: ownerId,
          staff: ownerId,
          staff_name: 'Google Calendar',
        },
      ],
    }));
    // return {
    //   ...response.data,
    //   results: (response.data?.results || []).map((i) => ({ ...i, resourceId: 'unknown' })),
    // };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @function googleCalendarEvent
 * @param id {String}
 */
export const googleCalendarEvent = async (id) => {
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.googleCalendar.event, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @function googleCalendarList
 * @param accessToken {String}
 */
export const googleCalendarList = async (accessToken) => {
  const response = await callApi({
    method: GET,
    absolute: true,
    authorization: false,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: ApiRoutes.googleCalendar.list,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
