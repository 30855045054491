import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Modal from '../../UIKit/Modal';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { getPureCategoryList, saveStylistPhoto } from '../../../../api';
import { AppContext } from '../../../../context/providers';
import CropImage from '../../UIKit/CropImage';
import { DeleteRounded } from '@material-ui/icons';
import UploadFileInput from '../../UIKit/UploadFileBox';
import { ControlSwitch } from '../../UIKit/Form/Switch';
import { ControlSelect } from '../../UIKit/Form/Select';

const CUSTOM_BUSINESS = 'others';

/**
 * @description if 'shouldSave' was 'false', form just pass the data to the parent and do not save it in server
 */
const StylistPhotoFormModal = ({ data = null, onClose, onSave }) => {
  const { locations } = useContext(AppContext);
  const [croppedImage, setCroppedImage] = useState(null);
  const [originImage, setOriginImage] = useState(data?.image);
  const [saveLoading, setSaveLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(null);
  const [categories, setCategories] = useState(null);
  const [categoryOptions, setCategoryOption] = useState([]);
  const [typeOptions, setTypeOption] = useState([]);
  const [business, setBusiness] = useState(data?.business_id);
  const { control, errors, setError, setValue, handleSubmit } = useForm();

  useEffect(() => {
    handleApi(getPureCategoryList, {}, { loadingFn: setCategoryLoading }).then((res) => {
      setCategories(res);
      const resultCategory = [];
      let resultTypes = [];
      (res || []).forEach(({ name, id, service_types }) => {
        if (service_types.length) {
          resultCategory.push({ value: id, label: name });
          if (resultTypes.length === 0) {
            resultTypes = service_types.map((s) => ({ label: s.name, value: s.id, data: { slug: s.slug } }));
          }
        }
      });
      setCategoryOption(resultCategory);
      setTypeOption(resultTypes);
    });
  }, []);

  useEffect(() => {
    if (categories && data?.service_type) {
      const exists = categories.reduce((acc, cat) => {
        const item = cat.service_types.find((i) => i.id === data.service_type);
        if (item) {
          return {
            service_type_id: item.id,
            category_id: cat.id,
          };
        } else {
          return acc;
        }
      }, null);
      if (exists) {
        setValue('category', exists?.category_id);
        setTimeout(() => {
          handleChangeCategory(exists?.category_id);
          setValue('service_type', exists?.service_type_id);
        }, 10);
      }
    }
  }, [data, categories]);

  const handleSave = ({ is_published, service_type }) => {
    const body = {
      is_published,
      business: business === CUSTOM_BUSINESS ? null : business,
      image: croppedImage || undefined,
      service_type,
    };
    if (data) body.id = data.id;
    handleApiSave(saveStylistPhoto, undefined, body, null, { loadingFn: setSaveLoading, setError }).then((res) => {
      onSave(res);
    });
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setOriginImage({ src: reader.result, file });
      };
    }
  };

  const handleRemovePhoto = () => {
    setCroppedImage(null);
    setOriginImage(null);
  };

  const handleChangeCategory = (categoryId) => {
    const category = categories.find((d) => String(d.id) === String(categoryId));
    const typeOptions = category.service_types.map((s) => ({ label: s.name, value: s.id, slug: s.slug }));
    setTypeOption(typeOptions);
    setTimeout(() => {
      const defVal = typeOptions.find((i) => i.value === data?.service_type);
      setValue('service_type', typeOptions.length > 0 ? defVal?.value || typeOptions[0].value : null);
    }, 10);
    return categoryId;
  };

  const businessesOpt = useMemo(
    () => [
      ...(locations || []).map((i) => ({ value: i.id, label: i.title })),
      { value: CUSTOM_BUSINESS, label: 'Others' },
    ],
    [locations],
  );

  return (
    <Modal
      show
      auto
      bodyClass="p-0"
      title={data ? 'Edit Photo' : 'New Photo'}
      saveLoading={saveLoading}
      onClose={onClose}
      onSave={handleSubmit(handleSave)}
    >
      <div className="c-promotion__form-card-container">
        <div className="c-promotion__form-card-sidebar">
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="c-promotion__form-card-content">
              <ControlSelect
                control={control}
                name="business"
                onChangeValue={setBusiness}
                rules={{ required: 'Service is required' }}
                defaultValue={data?.business || ''}
                label="Select a business"
                options={businessesOpt}
                errorMessage={errors?.business?.message}
                isLoading={categoryLoading}
                isDisabled={categoryLoading}
                isSearchable
              />
              <ControlSelect
                control={control}
                name="category"
                rules={{ required: 'Category is required' }}
                defaultValue={data?.category || ''}
                label="Category"
                options={categoryOptions}
                onChangeValue={handleChangeCategory}
                errorMessage={errors?.category?.message}
                isLoading={categoryLoading}
                isDisabled={categoryLoading}
                isSearchable
              />
              <ControlSelect
                control={control}
                name="service_type"
                rules={{ required: 'Service type is required' }}
                defaultValue={data?.service_type || ''}
                label="Service Type"
                options={typeOptions}
                isSearchable
                errorMessage={errors?.service_type?.message}
              />
            </div>
            <div className="c-promotion__form-card-footer">
              <ControlSwitch
                formGroup
                id="isPublish"
                name="is_published"
                defaultValue={data?.is_published}
                control={control}
                label="Publish the photo"
              />
            </div>
          </form>
        </div>

        <div className="c-promotion__card-wrapper">
          <div className="c-promotion__card-container">
            {originImage ? (
              <div className="c-upload-file-preview">
                <CropImage src={originImage} onChange={setCroppedImage} />
                <span className="c-upload-file-preview__actions">
                  <span className="c-upload-file-preview__actions-item" onClick={handleRemovePhoto}>
                    <DeleteRounded />
                  </span>
                </span>
              </div>
            ) : (
              <UploadFileInput onChange={handleChangeFile} accept="image/x-png,image/jpg,image/jpeg" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

StylistPhotoFormModal.propTypes = {
  data: PropTypes.any,
  shouldSave: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default StylistPhotoFormModal;
