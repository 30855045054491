import React, { memo } from 'react';
import classnames from 'classnames';

// eslint-disable-next-line react/prop-types
const FormGroup = ({ children, id, label, description, error, errorMessage, className, style }) => (
  <div style={style || undefined} className={classnames('c-form__group', { 'c-form__group--error': error }, className)}>
    {label && <label htmlFor={id}>{label}</label>}
    {children}
    {(errorMessage || description) && (
      <small className="c-form__group-description">{error && errorMessage ? errorMessage : description}</small>
    )}
  </div>
);

export default memo(FormGroup);
