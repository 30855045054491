import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Switch from '../../UIKit/Form/Switch';
import { AppContext } from '../../../../context/providers';

const SwitchHeader = ({ className }) => {
  const { isMarketplace, isSoftware, onSwitchSystem, business } = useContext(AppContext);
  const history = useHistory();
  const handleChange = () => {
    history.replace('/');
    onSwitchSystem();
  };

  if (!business?.software_setup || !business?.marketplace_setup) return null;

  return (
    <div className={classnames('c-panel-header__switch', className)}>
      <div className="c-panel-header__switch-labels">
        <span
          className={classnames('c-panel-header__switch-label', {
            'c-panel-header__switch-label--software': isSoftware,
          })}
        >
          Software
        </span>
        <span
          className={classnames('c-panel-header__switch-label', {
            'c-panel-header__switch-label--marketplace': isMarketplace,
          })}
        >
          Marketplace
        </span>
      </div>
      <Switch checked={isMarketplace} onChange={handleChange} id="switch-panel" />
    </div>
  );
};

SwitchHeader.propTypes = {
  className: PropTypes.any,
};

export default SwitchHeader;
