import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../UIKit/Modal';
import { useForm } from 'react-hook-form';
import { ControlInput } from '../../UIKit/Form/Input';
import { ControlDatePicker } from '../../UIKit/Form/DatePicker';
import { getStringMonthFormat } from '../StylistProfileModal';
import { handleApiSave } from '../../../../utilities/useApi';
import { saveCertificate } from '../../../../api';

/**
 * @description if 'shouldSave' was 'false', form just pass the data to the parent and do not save it in server
 */
const CertificateFormModal = ({ data = null, shouldSave = true, onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const { control, errors, setError, handleSubmit } = useForm();
  const handleSave = (body) => {
    if (shouldSave) {
      onSave({ id: `create_${new Date().getTime()}`, ...data, ...body });
    } else {
      const bodyApi = { ...data, ...body };
      if ((bodyApi.id || '').includes('create')) delete bodyApi.id;
      bodyApi.issued_date = getStringMonthFormat(bodyApi.issued_date);
      bodyApi.expiration_date = getStringMonthFormat(bodyApi.expiration_date);
      handleApiSave(saveCertificate, undefined, bodyApi, null, { loadingFn: setSaveLoading, setError }).then((res) => {
        console.log(res);
        onSave(res);
      });
    }
  };
  return (
    <Modal
      show
      title={data ? 'Edit Certificate' : 'New Certificate'}
      saveLoading={saveLoading}
      onClose={onClose}
      onSave={handleSubmit(handleSave)}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <ControlInput
          control={control}
          name="course"
          defaultValue={!!data?.course ? data.course : ''}
          label="Course Title"
          placeholder="Course Title"
          rules={{ required: 'Course Title is required' }}
          errorMessage={errors?.course?.message}
        />
        <ControlInput
          control={control}
          name="institution"
          defaultValue={!!data?.institution ? data.institution : ''}
          label="Institution"
          placeholder="Institution"
          rules={{ required: 'Institution is required' }}
          errorMessage={errors?.institution?.message}
        />
        <ControlDatePicker
          control={control}
          name="issued_date"
          defaultValue={!!data?.issued_date ? data.issued_date : ''}
          label="Issued Date"
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          popperPlacement="top-end"
          placeholder="Issued Date"
          rules={{ required: 'Issued Date is required' }}
          errorMessage={errors?.issued_date?.message}
        />
        <ControlDatePicker
          control={control}
          name="expiration_date"
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          popperPlacement="top-end"
          defaultValue={!!data?.expiration_date ? data.expiration_date : ''}
          label="Expiration Date"
          placeholder="Expiration Date"
          errorMessage={errors?.expiration_date?.message}
        />
        <ControlInput
          type="textarea"
          control={control}
          name="description"
          defaultValue={!!data?.description ? data.description : ''}
          label="Description"
          placeholder="Description"
          errorMessage={errors?.description?.message}
        />
      </form>
    </Modal>
  );
};

CertificateFormModal.propTypes = {
  data: PropTypes.any,
  shouldSave: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default CertificateFormModal;
