import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { IMAGE_SUCCESS_CHECK } from '../../../../assets/Images';
import Button from '../../UIKit/Form/Button';
import { AppContext } from '../../../../context/providers';
import { getBusiness, getBusinessById } from '../../../../api';
import { Types } from '../../../../config/actionTypes';

const SetupSoftwareCongratulations = ({ isNew = false, onSave, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { setBusiness, setSystem, newBusiness, onNewBusiness, currentLocation } = useContext(AppContext);

  const handleStartSoftware = () => {
    setLoading(true);
    if (isNew) {
      getBusinessById(newBusiness.id)
        .then((data) => {
          toaster.notify(() => (
            <div className="alert alert-success mt-3">Your Software Has Been Started Successfully</div>
          ));
          if (!currentLocation) {
            localStorage.setItem(Types.BUSINESS_LOCATION, newBusiness.id);
            window.location.reload();
          }
          setBusiness(data);
          setSystem('software');
          localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
          onNewBusiness(null);
          onSave();
        })
        .finally(() => setLoading(false));
    } else {
      getBusiness()
        .then((data) => {
          toaster.notify(() => (
            <div className="alert alert-success mt-3">Your Software Has Been Started Successfully</div>
          ));
          setBusiness(data);
          setSystem('software');
          localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
          onSave();
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <div>
      <div className="c-setup-system-congratulation">
        <img src={IMAGE_SUCCESS_CHECK} alt="marketplace" />
        <h2>CONGRATULATIONS!</h2>
        <p>Let&apos;s manage your appointment with the Beautster Software!</p>
      </div>
      <div className="d-flex align-center mt-5">
        {isNew && (
          <Button type="button" color="info" onClick={onClose} className="mr-auto">
            Close Setup
          </Button>
        )}
        <Button type="button" loading={loading} onClick={handleStartSoftware} className="ml-auto">
          Start Software
        </Button>
      </div>
    </div>
  );
};

SetupSoftwareCongratulations.propTypes = {
  isNew: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default SetupSoftwareCongratulations;
