import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// eslint-disable-next-line react/display-name
const InputEditor = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ id, value, type, unit, unitPos, onChange, onChangeText, className, ...otherProps }, ref) => {
    const handleOnChange = (event, editor) => {
      const data = editor.getData();
      onChangeText(data);
      onChange({ event, editor, data, target: { value: data } });
    };
    return (
      <div
        className={classnames('c-form__controller', { 'c-form__controller--disabled': otherProps.disabled }, className)}
      >
        <CKEditor
          ref={ref}
          onChange={handleOnChange}
          data={value}
          editor={ClassicEditor}
          config={{
            toolbar: ['bold', 'italic'],
          }}
          {...otherProps}
          // data="<p>Hello from CKEditor 5!</p>"
          // onReady={ editor => {
          //     // You can store the "editor" and use when it is needed.
          //     console.log( 'Editor is ready to use!', editor );
          // } }
          // onChange={ ( event, editor ) => {
          //     const data = editor.getData();
          //     console.log( { event, editor, data } );
          // } }
          // onBlur={ ( event, editor ) => {
          //     console.log( 'Blur.', editor );
          // } }
          // onFocus={ ( event, editor ) => {
          //     console.log( 'Focus.', editor );
          // } }
        />
        {unit && <span className="c-form__controller-unit">{unit}</span>}
      </div>
    );
  },
);

InputEditor.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.any,
  unit: PropTypes.string,
  unitPos: PropTypes.oneOf(['before', 'after']),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
};

InputEditor.defaultProps = {
  id: null,
  type: 'text',
  unit: '',
  unitPos: 'after',
  onChange: () => {},
  onChangeText: () => {},
};

export default InputEditor;
