import React, { useMemo, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import Modal from '../UIKit/Modal';
import { getInvoice, sendInvoice } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';
import { handleApi, useApiById } from '../../../utilities/useApi';
import Button from '../UIKit/Form/Button';
import Dropdown from '../UIKit/Dropdown';
import { InvoiceModalPrint } from '../Exporter/Invoice/InvoicePrint';
import { InvoiceModalInformation } from './InvoiceInformation';
import InvoiceDownload, { InvoiceFile } from '../Exporter/Invoice/InvoiceDownload';
import RefundModal from '../Refund/RefundModal';
import CheckoutModal from '../Checkout/CheckoutModal';
import Alert from '../UIKit/Alert';
import usePermission from '../../../utilities/usePermission';

const InvoiceModal = ({ data: _data, onClose, onSave }) => {
  const [emailFileUrl, setEmailFileUrl] = useState(null);
  const [refund, setRefund] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [data, loading] = useApiById(getInvoice);
  const printRef = useRef(null);
  const { access: _access } = usePermission('invoice', 'crud', 'modify');

  const handleSave = () => {
    setRefund(null);
    setCheckout(null);
    onSave();
  };

  const handleSendEmail = () => {
    if (emailFileUrl) {
      (async () => {
        if (sendLoading) return;
        const { url, fileName } = emailFileUrl;
        let response = await fetch(url);
        let fileRes = await response.blob();
        let metadata = { type: 'application/pdf', name: fileName };
        let file = new File([fileRes], fileName, metadata);

        handleApi(sendInvoice, { id: data.id, file }, { loadingFn: setSendLoading }).then((res) => {
          if (res.status >= 200 && res.status < 300) {
            toaster.notify(() => <Alert success>Email Has Send</Alert>);
          } else {
            toaster.notify(() => <Alert danger>Sending Email is failed. Please try it later!</Alert>);
          }
        });
      })();
    }
  };

  const options = useMemo(() => {
    if (!data) return [];
    const results = [
      {
        type: 'node',
        id: 'printInvoice',
        node: printRef && (
          <>
            {/*<Button sm color="info" onClick={handlePrint}>Print Invoice</Button>*/}
            <ReactToPrint trigger={() => <span>Print</span>} content={() => printRef.current} pageStyle="a4" />
          </>
        ),
        className: 'dropdown-link',
      },
      {
        type: 'node',
        id: 'printDownload',
        node: data ? <InvoiceDownload data={data} /> : null,
      },
    ];

    if (_access) {
      results.push({
        type: 'node',
        onClick: handleSendEmail,
        className: 'dropdown-link',
        id: 'sendEmail',
        node: sendLoading ? 'Sending...' : 'Send Email',
      });

      if (
        data?.status === 'paid' ||
        data?.status === 'part_refunded' ||
        (data?.status === 'canceled' && data?.total_payments > data?.total_refunds)
      ) {
        results.push({
          type: 'node',
          node: 'Refund',
          textColor: 'danger',
          id: 'refund',
          className: `${data?.lines.length > 0 ? 'dropdown-link' : 'd-none'}`,
          onClick() {
            if (data?.lines.length > 0) {
              setRefund({
                id: data.lines[0].item_type === 'gift_card' ? data.lines[0].item_id : null,
                type: data.lines[0].item_type,
              });
            }
          },
        });
      } else if (data?.status === 'part_paid' || data?.status === 'unpaid') {
        results.push({
          type: 'node',
          node: 'Checkout',
          id: 'checkout',
          textColor: 'success',
          className: `${data?.lines.length > 0 ? 'dropdown-link' : 'd-none'}`,
          onClick() {
            if (data?.lines.length > 0) {
              setCheckout({
                id: data.lines[0].item_type === 'gift_card' ? data.lines[0].item_id : data.appointment,
                type: data.lines[0].item_type,
                invoice: data.id,
              });
            }
          },
        });
      }
    }
    return results;
  }, [data, _access]);
  return (
    <>
      {!!checkout && (
        <CheckoutModal
          id={checkout?.id}
          type={checkout.type}
          invoiceId={checkout.invoice}
          onClose={() => setCheckout(null)}
          onSave={handleSave}
        />
      )}
      {!!refund && (
        <RefundModal
          id={refund?.id}
          type={refund?.type}
          data={data}
          onClose={() => setRefund(null)}
          onSave={handleSave}
        />
      )}
      {!emailFileUrl && data && (
        <div className="d-none">
          <InvoiceFile data={data} onFileUrl={setEmailFileUrl} />
        </div>
      )}
      <div className="d-none">{data && <InvoiceModalPrint data={data} ref={printRef} />}</div>
      <Modal
        title={`Invoice #${_data?.id ? _data?.id : data?.id}`}
        // description="Saturday, 17 Oct 2020"
        description={_data?.created_at ? _data?.created_at : data?.created_at}
        show={!refund && !checkout}
        sticky
        saveBtn={false}
        onClose={onClose}
        buttonComponent={
          <Dropdown block options={options} positionMenu="top">
            <Button color="light">Options</Button>
          </Dropdown>
        }
      >
        {loading ? (
          <PageLoading type="box" />
        ) : data ? (
          <div className="c-invoice-detail">
            <InvoiceModalInformation data={data} />
          </div>
        ) : null}
      </Modal>
    </>
  );
};

InvoiceModal.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default InvoiceModal;
