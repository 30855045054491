import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalAlert } from '../UIKit/Alert';
import Button from '../UIKit/Form/Button';
import { AppContext } from '../../../context/providers';
import { handleApiSave } from '../../../utilities/useApi';
import { getStripeConnectAccountLink } from '../../../api';

const ConnectStripAlertModal = ({ onClose }) => {
  const [connectLoading, setConnectLoading] = useState(false);
  const { business, userPermissions: p } = useContext(AppContext);
  const handleConnectAccount = () => {
    handleApiSave(getStripeConnectAccountLink, undefined, {}, undefined, {
      loadingFn: setConnectLoading,
    })
      .then(({ connect_url }) => {
        window.location.href = connect_url;
      })
      .catch(() => {});
  };
  if (!(business && !business?.connected_to_stripe)) return null;
  return (
    <ModalAlert
      title="Connect Stripe Account"
      description="For create LMO discount your business must connected to Stripe"
      onClose={onClose}
      buttonComponent={
        p?.business?.connect_to_stripe?.modify ? (
          <Button sm loading={connectLoading} onClick={handleConnectAccount}>
            Link your bank account now
          </Button>
        ) : null
      }
    />
  );
};

ConnectStripAlertModal.propTypes = {
  onClose: PropTypes.func,
};

export default ConnectStripAlertModal;
