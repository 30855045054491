// import { isNode } from '../utilities/Environment';
// import dotenv from 'dotenv';
//
// if (isNode) console.log({ E: dotenv.config() });

// export const BaseUrl = process.env.REACT_APP_BASE_URL;
// export const BaseImagesUrl = '';
// export const ReactAppBaseAPIUrl = process.env.REACT_APP_BASE_API_URL;
// export const ReactAppBaseNodeApiUrl = isNode && process.env.REACT_APP_BASE_NODE_API_URL;
// export const ApiUrl = isNode ? ReactAppBaseNodeApiUrl : ReactAppBaseAPIUrl;
import { Types } from './actionTypes';

export const ApiUrl = process.env.REACT_APP_BASE_API_URL;
export const PER_PAGE = 10;

export const BusinessId = localStorage.getItem(Types.BUSINESS_LOCATION);

export const ApiRoutes = {
  analytics: {
    appointment: '/partners/businesses/{id}/appointments-statistics/',
    client: '/partners/businesses/{id}/clients-statistics/',
    sales: '/partners/businesses/{id}/sales-statistics/',
  },
  stripe: {
    capture: '/partners/{businessId}/invoices/{id}/stripe-capture-payment/',
    payment: '/partners/{businessId}/invoices/{id}/stripe-checkout/',
    create_token: '/partners/businesses/{id}/create-connection-token/',
    billing: '/partners/businesses/{id}/stripe-billing-portal/',
    payout_link: '/partners/businesses/{id}/stripe-login-link/',
    connect_account: '/partners/businesses/{id}/connect-to-stripe/',
    disconnect_account: '/partners/businesses/{id}/disconnect-from-stripe/',
  },
  business: {
    get: '/partners/businesses/{id}/',
    create: '/partners/businesses/',
    update: '/partners/businesses/{id}/',
    // get: '/businesses/',
    // update: '/businesses/',
  },
  stylistCertificates: {
    get: `${ApiUrl}/partners/stylist-profile/certificates/`,
    create: `${ApiUrl}/partners/stylist-profile/certificates/`,
    update: `${ApiUrl}/partners/stylist-profile/certificates/{id}/`,
    delete: `${ApiUrl}/partners/stylist-profile/certificates/{id}/`,
  },
  stylistEducations: {
    list: `${ApiUrl}/partners/stylist-profile/educations/`,
    create: `${ApiUrl}/partners/stylist-profile/educations/`,
    get: `${ApiUrl}/partners/stylist-profile/educations/{id}/`,
    update: `${ApiUrl}/partners/stylist-profile/educations/{id}/`,
    delete: `${ApiUrl}/partners/stylist-profile/educations/{id}/`,
  },
  stylistExperiences: {
    list: `${ApiUrl}/partners/stylist-profile/experiences/`,
    create: `${ApiUrl}/partners/stylist-profile/experiences/`,
    get: `${ApiUrl}/partners/stylist-profile/experiences/{id}/`,
    update: `${ApiUrl}/partners/stylist-profile/experiences/{id}/`,
    delete: `${ApiUrl}/partners/stylist-profile/experiences/{id}/`,
  },
  stylistProfile: `${ApiUrl}/partners/stylist-profile/`,
  stylistPhoto: {
    get: `${ApiUrl}/partners/stylist-profile/photos/`,
    getById: `${ApiUrl}/partners/stylist-profile/photos/{id}/`,
  },
  pendingTransfer: {
    list: '/pending-transfers/',
    get: '/pending-transfers/{id}/',
    payment: '/pending-transfers/stripe-checkout/',
  },
  businessSubscription: {
    checkout: '/partners/businesses/{id}/subscribe/',
    active_trial: '/partners/businesses/{id}/activate-trial/',
    cancel_subscription: '/partners/businesses/{id}/cancel-subscription/',
    change_subscription: '/partners/businesses/{id}/change-subscription/',
  },
  businessMarketplace: {
    switch_marketplace: '/partners/businesses/{id}/marketplace-switch/',
    third_time_requests: '/third_time_requests/',
  },
  requests: {
    serviceList: '/requested-services/',
    serviceItem: '/requested-services/{id}/',
  },
  businessClosedDates: {
    list: '/business-closed-dates/',
    create: '/business-closed-dates/',
    get: '/business-closed-dates/{id}/',
    update: '/business-closed-dates/{id}/',
    delete: '/business-closed-dates/{id}/',
  },
  businessPreference: {
    get: '/business-preference/',
    update: '/business-preference/',
  },
  businessWorkingHours: {
    list: '/business-working-hours/',
    create: '/business-working-hours/',
    get: '/business-working-hours/{id}/',
    update: '/business-working-hours/{id}/',
    delete: '/business-working-hours/{id}/',
  },
  plan: {
    list: '/plans/',
    listOrigin: '/partners/{id}/plans/',
    get: '/partners/{businessId}/plans/{id}/',
  },
  user: {
    sendVerification: '/user/auth/resend-verification/',
    verify: '/user/auth/verify-identity/',
    changeEmail: '/user/change-email/',
    changeIdentity: '/user/auth/change-identity/',
    getProfile: '/user/profile/',
    updateProfile: '/user/profile/',
  },
  auth: {
    refresh: '/user/auth/login/refresh/',
    register: '/user/auth/register/?business=1',
    login: '/user/auth/login/?business=1',
    google: '/user/auth/validate-google-oauth2/?business=1',
    forgot: '/user/auth/reset-password/',
    forgotToken: '/user/auth/reset-password/validate_token/',
    forgotConfirm: '/user/auth/reset-password/confirm/',
    changePassword: '/user/auth/change-password/',
    claimProfile: '/user/auth/claim-profile/',
  },
  googleCalendar: {
    // /google-calendar-auth-callback/
    auth: '/google-calendar-auth/',
    sync: '/google-calendar-sync/',
    eventsList: '/google-calendar-events/',
    event: '/google-calendar-events/{id}/',
    list: 'https://www.googleapis.com/calendar/v3/users/me/calendarList/',
  },
  campaigns: {
    list: '/campaigns/',
    get: '/campaigns/{id}/',
    // report: '/campaigns/{id}/report/',
    // schedule: '/campaigns/{id}/schedule/',
    report: '/{type}/{id}/campaign-report/',
    schedule: '/{type}/{id}/send-campaign/',
  },
  notifications: {
    list: '/notifications/',
  },
  locations: {
    list: '/partners/businesses/',
    countries: '/countries/',
    provinces: '/provinces/',
    province: '/provinces/{id}/',
  },
  messages: {
    list: '/messages/',
    create: '/messages/',
    get: '/messages/{id}/',
  },
  appointmentSuggestedTimes: {
    list: '/appointment-suggested-times/',
    create: '/appointment-suggested-times/',
    read: '/appointment-suggested-times/{id}/',
    update: '/appointment-suggested-times/{id}/',
    delete: '/appointment-suggested-times/{id}/',
    confirm: '/appointment-suggested-times/{id}/confirm/',
  },
  appointment: {
    create: '/appointments/',
    list: '/appointments/',
    get: '/appointments/{id}/',
    update: '/appointments/{id}/',
    updateStatus: '/appointments/{id}/{status}/',
    delete: '/appointments/{id}/',
  },
  appointmentLine: {
    check_availability: '/appointment-lines/check-availability/',
    create: '/appointment-lines/',
    list: '/appointment-lines/',
    get: '/appointment-lines/{id}/',
    update: '/appointment-lines/{id}/',
    delete: '/appointment-lines/{id}/',
  },
  client: {
    import: '/clients/import/',
    create: '/clients/',
    list: '/clients/',
    get: '/clients/{id}/',
    update: '/clients/{id}/',
    delete: '/clients/{id}/',
  },
  service: {
    create: '/services/',
    list: '/services/',
    get: '/services/{id}/',
    update: '/services/{id}/',
    delete: '/services/{id}/',
  },
  bookablePhoto: {
    create: '/bookable-photos/',
    list: '/bookable-photos/',
    get: '/bookable-photos/{id}/',
    update: '/bookable-photos/{id}/',
    delete: '/bookable-photos/{id}/',
  },
  tags: {
    list: '/tags/',
    get: '/tags/{id}',
  },
  deal: {
    create: '/deals/',
    list: '/deals/',
    get: '/deals/{id}',
    update: '/deals/{id}/',
    delete: '/deals/{id}/',
    publish: '/deals/{id}/publish/',
    unpublish: '/deals/{id}/unpublish/',
  },
  lmo: {
    create: '/lmos/',
    list: '/lmos/',
    get: '/lmos/{id}/',
    update: '/lmos/{id}/',
    delete: '/lmos/{id}/',
    publish: '/lmos/{id}/publish/',
    unpublish: '/lmos/{id}/unpublish/',
  },
  giftCard: {
    create: '/gift-cards/',
    list: '/gift-cards/',
    get: '/gift-cards/{id}/',
    update: '/gift-cards/{id}/',
    delete: '/gift-cards/{id}/',
  },
  soldGiftCard: {
    list: '/sold-gift-cards/',
    get: '/sold-gift-cards/{id}',
    update: '/sold-gift-cards/{id}/',
    delete: '/sold-gift-cards/{id}/',
    validate_code: '/sold-gift-cards/validate-code/',
    // activate: '/sold_gift_cards/{id}/activate/',
    // deactivate: '/sold_gift_cards/{id}/deactivate/',
  },
  discounts: {
    validate_code: '/discounts/validate-code/',
    create: '/discounts/',
    list: '/discounts/',
    get: '/discounts/{id}/',
    update: '/discounts/{id}/',
    delete: '/discounts/{id}/',
    // publish: '/discounts/{id}/publish/',
    // unpublish: '/discounts/{id}/unpublish/',
  },
  categorizedServices: {
    list: '/categorized-services/',
    get: '/categorized-services/{id}/',
  },
  category: {
    list: '/categories/',
    get: '/categories/{id}/',
  },
  staff: {
    create: '/staffs/',
    list: '/staffs/',
    get: '/staffs/{id}/',
    update: '/staffs/{id}/',
    delete: '/staffs/{id}/',
    slot: '/marketplace/staffs/{id}/taken-time-slots/',
  },
  accessLevel: {
    items: '/access-level-items/',
    list: '/access-levels/',
    create: '/access-levels/',
    get: '/access-levels/{id}/',
    update: '/access-levels/{id}/',
    delete: '/access-levels/{id}/',
  },
  permissions: {
    list: '/permissions/',
    get: '/permissions/{id}/',
  },
  staffClosedDates: {
    list: '/staff-closed-dates/',
    create: '/staff-closed-dates/',
    get: '/staff-closed-dates/{id}/',
    update: '/staff-closed-dates/{id}/',
    delete: '/staff-closed-dates/{id}/',
  },
  serviceCommission: {
    create: '/service-commissions/',
    update: '/service-commissions/{id}/',
    list: '/service-commissions/',
    commissions: '/commissions/',
    totalCommission: '/staffs/{id}/total-commission/',
  },
  staffWorkingHours: {
    list: '/staff-working-hours/',
    create: '/staff-working-hours/',
    get: '/staff-working-hours/{id}/',
    update: '/staff-working-hours/{id}/',
    delete: '/staff-working-hours/{id}/',
  },
  invoice: {
    create: '/invoices/',
    update: '/invoices/{id}/',
    list: '/invoices/',
    get: '/invoices/{id}/',
    stripe: '/invoices/{id}/stripe-checkout/',
    send: '/invoices/{id}/send-invoice/',
  },
  instagramAPI: {
    accessToken: '/instagram-oauth/',
    // accessToken: 'https://api.instagram.com/oauth/access_token/',
    mediaList:
      'https://graph.instagram.com/v12.0/{userId}/media?access_token={accessToken}&fields=caption,media_type,media_url',
  },
};

// suggest/customer/?query=A&_=1606974277576

export const createURL = (url, params = {}) => {
  Object.keys(params).forEach((item) => {
    url = url.replace(`{${item}}`, params[item]);
  });
  return url;
};

export const StatusCodes = {
  Success: 200,
  Created: 201,
  Deleted: 204,
  UnAuthorized: 401,
  Error: 500,
  NotFound: 404,
  ExpiredToken: 402,
};

export const ApiErrorTypes = {
  TimeOut: 'Timeout',
  UnAuthorized: 'Not Authorized',
  UnKnown: 'Somehow Failed',
  ExpiredToken: 'Token Expired',
};

export const ApiMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};
