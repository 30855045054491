import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import toaster from 'toasted-notes';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import { deleteGiftCard, getGiftCard, scheduleCampaign } from '../../../../api';
import Modal from '../../UIKit/Modal';
import Button from '../../UIKit/Form/Button';
import { PageLoading } from '../../UIKit/Spinner';
import PromotionGiftCard from '../PromotionGiftCard';
import usePermission from '../../../../utilities/usePermission';

// eslint-disable-next-line react/prop-types
const PromotionGiftCardDetailModal = ({ onClose, onSave, onCheckout }) => {
  const [delLoading, setDelLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const { access: _access } = usePermission('giftcard', 'crud');

  const { id } = useParams();

  const [data, loading] = useApiById(getGiftCard, id);

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete this gift card',
      message: 'Are you sure to delete this gift card.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteGiftCard, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  const handleSendCampaign = () => {
    handleApiSave(scheduleCampaign, null, data.id, null, { loadingFn: setCampaignLoading })
      .then(() => {
        // console.log(_data);
        onSave();
        toaster.notify(() => <div className="alert alert-success">Campaign has been sent successfully</div>);
      })
      .catch(() => {});
  };

  return (
    <Modal
      show
      auto
      sticky
      onClose={onClose}
      saveBtn={false}
      bodyClass="p-0 py-4 bg-secondary"
      buttonComponent={
        !!id && (
          <>
            {data?.campaign && data?.campaign?.status !== 'scheduled' && (
              <Button color="success" loading={campaignLoading} onClick={handleSendCampaign}>
                Send Campaign
              </Button>
            )}
            {onCheckout && data?.is_saleable && (
              <Button color="dark" onClick={() => onCheckout(data)}>
                Sell Gift Card
              </Button>
            )}
            {_access?.modify && (
              <Button color="danger" loading={delLoading} onClick={handleDelete}>
                Delete
              </Button>
            )}
          </>
        )
      }
      title="Gift Card"
    >
      {loading && id ? (
        <PageLoading />
      ) : (
        <PromotionGiftCard
          expireAfter={data?.expire_after}
          imageUrl={data?.image}
          subject={data?.subject}
          description={data?.description}
          value={data?.value}
          price={data?.retail_price}
          limit={data?.usage_limit}
          emailTemplate={false}
          campaignAudience={data?.campaign?.audience}
          emailSubject={data?.campaign?.email_subject}
          emailDescription={data?.campaign?.email_description}
        />
      )}
    </Modal>
  );
};

export default PromotionGiftCardDetailModal;
