import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StaffWorkingHoursModal from '../../Common/Staff/StaffHours/StaffWorkingHoursModal';
import { getStaffList } from '../../../api';
import { DayNames } from '../../../utilities/DateHelpers';
import { PageLoading } from '../../Common/UIKit/Spinner';
import useApi, { handleApi } from '../../../utilities/useApi';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import Button from '../../Common/UIKit/Form/Button';
import { AppContext } from '../../../context/providers';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import { TourGuide } from '../../Common/UIKit/TourContent';
import { staffWorkingHoursSteps } from '../../../config/tourSteps';
import { getQueryUri } from '../../../utilities/URI';
import usePermission from '../../../utilities/usePermission';
import ImageName from '../../Common/UIKit/ImageName';

const daysOfWeek = [...DayNames];
daysOfWeek.push(daysOfWeek[0]);
daysOfWeek.shift();

const StaffWorkingHourItem = ({ index, day, staff, onOpen }) => {
  const currentDay = +index % 7;
  const item = useMemo(() => staff.times.find((i) => +i.week_day === currentDay), [staff]);
  const handleOpen = () => {
    if (!!item) {
      onOpen({ staff, day: currentDay, item });
    } else {
      onOpen({ staff, day: currentDay, hour: null });
    }
  };
  return (
    <td>
      {!!item ? (
        <span
          data-tour="item"
          id={`time-${staff.id}-${day}`}
          className={classnames('c-staff__hours', {
            'c-staff__hours--deactivated': !item?.is_active || !staff.isActive,
          })}
          onClick={handleOpen}
        >
          {item?.is_active ? `${item?.start_time} - ${item?.end_time}` : 'Not Active'}
        </span>
      ) : (
        <span className="c-staff__hours-empty" onClick={handleOpen}>
          +
        </span>
      )}
    </td>
  );
};

StaffWorkingHourItem.propTypes = {
  index: PropTypes.number,
  day: PropTypes.any,
  staff: PropTypes.any,
  onOpen: PropTypes.func,
};

// eslint-disable-next-line react/prop-types
const StaffWorkingHours = ({ headerEl }) => {
  const [overLoading, setOverLoading] = useState(false);
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);

  const [, loading] = useApi(getStaffList, { page: true, key: 'staff' }, setData);
  const { setHeader } = useContext(AppContext);

  const { access: _staff } = usePermission('staff', 'crud');
  const { access: _access } = usePermission('staffworkinghour', 'crud');

  useEffect(() => {
    if (!loading) {
      setIsTourOpen(true);
    }
  }, [loading]);

  useEffect(() => {
    setHeader('Staff / Working Hours', { videoId: 'LvPGbZtiK50', guide: staffWorkingHoursSteps });
  }, [setHeader]);

  // useEffect(() => {
  //   const results = [];
  //   if (staff?.results) {
  //     staff.results.forEach(({ id, name, avatar, isActive }) => {
  //       const item = { id, name, avatar, isActive, data: [] };
  //       if (hours?.results) {
  //         hours?.results.forEach((workingHours) => {
  //           if (+workingHours.staff === +id) item.data.push(workingHours);
  //         });
  //       }
  //       results.push(item);
  //     });
  //   }
  //   setData(results);
  // }, [hours]);

  const handleOpenModal = (item) => {
    setSelected(item);
  };
  const handleCloseModal = () => {
    setSelected(null);
  };

  const handleDeleteItem = (item) => {
    const updatedData = [...data];
    const staffInd = updatedData.findIndex((s) => s.id === item.staff.id);
    const hoursInd = updatedData[staffInd].data.findIndex((h) => h.id === item.item.id);

    updatedData[staffInd].data.splice(hoursInd, 1);
    setData(updatedData);
    setSelected(null);
  };

  const handleSaveItem = (item) => {
    const updatedData = [...(data?.results || [])];
    const staffIndex = updatedData.findIndex((s) => s.id === item.staff.id);
    const hoursIndex = updatedData[staffIndex].times.findIndex((h) => h.id === item.data.id);

    if (hoursIndex > -1) {
      updatedData[staffIndex].times[hoursIndex] = item.data;
    } else {
      updatedData[staffIndex].times = [...updatedData[staffIndex].times, item.data];
    }

    setData({ ...data, results: updatedData });
    setSelected(null);
    const page = getQueryUri('page') || 1;
    handleApi(
      getStaffList,
      {},
      {
        page,
        list: true,
        loadingFn: setOverLoading,
      },
    ).then(setData);
  };

  return (
    <>
      {!!selected && _access?.modify && (
        <StaffWorkingHoursModal
          onClose={handleCloseModal}
          onDelete={handleDeleteItem}
          onSave={handleSaveItem}
          selected={selected}
        />
      )}
      <HeadOptions leftElement={headerEl} />
      {_staff?.view ? (
        <>
          {loading || !data ? (
            <PageLoading />
          ) : data && data?.results.length > 0 ? (
            <>
              {overLoading && <PageLoading over />}
              <div data-tour="hours-list" className="c-panel-content">
                <div className="c-table c-table--sm c-staff__table">
                  <table>
                    <thead>
                      <tr>
                        <th>Avatar</th>
                        <th>Name</th>
                        {daysOfWeek.map((i) => (
                          <th className="text-center" key={i}>
                            {i}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(data?.results || []).map((staff) => (
                        <tr
                          key={staff.id}
                          className={classnames({
                            'c-table__item--disabled': !staff.isActive,
                          })}
                        >
                          <td>
                            <ImageName className="image" name={staff.name} imageSrc={staff.avatar} />
                          </td>
                          <td>{staff.name}</td>
                          {daysOfWeek.map((day, ind) => (
                            <StaffWorkingHourItem
                              key={ind}
                              index={ind}
                              day={day}
                              staff={staff}
                              onOpen={handleOpenModal}
                            />
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {false && (
                <TourGuide
                  disableInteraction
                  steps={staffWorkingHoursSteps}
                  startAt={tourStep}
                  getCurrentStep={setTourStep}
                  isOpen={isTourOpen}
                  onRequestClose={() => setIsTourOpen(false)}
                />
              )}
            </>
          ) : (
            <NotFoundData title="No Staff Exists" button={<Button link="/staff/members/add">New Staff</Button>} />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default StaffWorkingHours;
