import React, { useEffect, useState } from 'react';
import { getQueryUri } from '../../../utilities/URI';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../Common/UIKit/Form/Input';
import Button from '../../Common/UIKit/Form/Button';
import AuthenticationLayout from '../../Common/Authentication/AuthenticationLayout';
import { handleApi } from '../../../utilities/useApi';
import { forgotConfirm } from '../../../api';

const ResetPasswordPage = () => {
  const [isDone, setIsDone] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, errors, handleSubmit, setError } = useForm();

  useEffect(() => {
    const token = getQueryUri('token');
    if (!token) setInvalidToken(true);
  }, []);

  const onSubmit = ({ password, passwordConfirmation }) => {
    const token = getQueryUri('token');
    if (password === passwordConfirmation) {
      handleApi(forgotConfirm, { token, password }, { loadingFn: setLoading, list: false })
        .then((data) => {
          if (data.status.toLowerCase() === 'ok') {
            setIsDone(true);
          } else {
            setInvalidToken(true);
          }
        })
        .catch(({ status }) => {
          if (+status === 404) setInvalidToken(true);
        });
    } else {
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'password confirmation did not match',
      });
    }
  };

  return (
    <AuthenticationLayout>
      {isDone ? (
        <>
          <h2 className="text-center text-success my-5">Password Has Been Changed Successfully</h2>
          <Button block lg link="/login">
            Login Now
          </Button>
        </>
      ) : invalidToken ? (
        <>
          <h2 className="text-center text-danger my-5">
            Token Is Invalid. <br /> Please Check Your Email
          </h2>
          <Button block lg link="/login">
            Login Now
          </Button>
        </>
      ) : (
        <>
          <div className="c-auth__head">
            <h1>Reset Your Password</h1>
          </div>
          <form className="e-form mb-3" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="password"
              rules={{ required: 'Password is required' }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  label="Password"
                  type="password"
                  id="pure-password"
                  formGroup
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={errors?.password?.message}
                  error={!!errors.password}
                />
              )}
            />
            <Controller
              control={control}
              name="passwordConfirmation"
              rules={{ required: 'Password confirmation is required' }}
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  label="Password Confirmation"
                  type="password"
                  id="pure-password-confirmation"
                  formGroup
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={errors?.passwordConfirmation?.message}
                  error={!!errors.passwordConfirmation}
                />
              )}
            />
            <Button block lg className="mt-5" loading={loading}>
              Change Password
            </Button>
          </form>
        </>
      )}
    </AuthenticationLayout>
  );
};

export default ResetPasswordPage;
