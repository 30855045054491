import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import { handleApi } from '../../../../utilities/useApi';
import { getServiceList } from '../../../../api';
import { PageLoading } from '../../UIKit/Spinner';
import SectionHead from '../../UIKit/SectionTitle';
import Checkbox from '../../UIKit/Form/Checkbox';
import { ServiceTieredLevelChoice } from '../../../../utilities/constants/choices';

const StaffServices = ({ data, level, onChange }) => {
  const [loading, setLoading] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const [services, setServices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    handleApi(getServiceList, {}, { infinitePage: true, loadingFn: setLoading }).then((res) => {
      if (res?.results) setServices(res.results);
    });
  }, []);

  useEffect(() => {
    if (onChange) onChange(selectedList);
  }, [selectedList]);

  const filteredData = useMemo(() => {
    if (level) {
      return (services || [])
        .filter((i) => i.tiered_level === 'no_level' || i.tiered_level === level)
        .sort((a) => (a.tiered_level === level ? -1 : 0));
    }
    return services || [];
  }, [services, level]);

  useEffect(() => {
    if (data?.services) {
      setSelectedList(data?.services);
      if (data?.services.length > 0 && data?.services.length === filteredData.length) {
        setSelectAll(true);
      }
    }
  }, [data]);

  // useEffect(() => {
  //   if (services.length > 0) {
  //     if (values && values.staff) {
  //       onChange(values.staff);
  //       if (values.staff.length >= services.length) handleToggleAll();
  //     } else handleToggleAll();
  //   }
  // }, [services, values]);

  const handleToggleAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedList([]);
    } else {
      const servicesList = services.map((l) => l.id);
      setSelectAll(true);
      setSelectedList(servicesList);
    }
  };
  const handleOnChange = (item, val) => {
    const servicesList = [...selectedList] || [];
    if (val) {
      servicesList.push(item.id);
    } else {
      const ind = servicesList.indexOf(item.id);
      servicesList.splice(ind, 1);
    }
    if (selectedList.length < services.length) setSelectAll(false);
    else setSelectAll(true);
    setSelectedList(servicesList);
  };

  const getValue = (id) => {
    const value = selectedList || [];
    const val = value.indexOf(id);
    return val > -1;
  };
  return (
    <div data-tour="services">
      <div className="c-service-form__head">
        <SectionHead title="Services" description="Assign service to the staff" />
      </div>
      {loading ? (
        <PageLoading />
      ) : services.length > 0 ? (
        <>
          <Checkbox
            formGroup
            id="allServices"
            label="Select All Services"
            checked={selectAll}
            onChange={handleToggleAll}
          />
          <hr />
          {filteredData.map((s, key) => (
            <Checkbox
              key={key}
              formGroup
              id={`service-${s.id}`}
              label={
                <span className="c-form__label-item">
                  {/*<img src={staff.avatar_url} alt={staff.fullName} />*/}
                  <span className="detail">
                    <span>
                      {s.name}{' '}
                      {/*{s.tiered_level === 'no_level' && level !== 'no_level' ? <small>(No Tiered Level)</small> : null}*/}
                      <small>({ServiceTieredLevelChoice[s.tiered_level]})</small>
                    </span>
                  </span>
                </span>
              }
              checked={getValue(s.id)}
              onChange={(val) => handleOnChange(s, val)}
            />
          ))}
          <NavLink to="/services/add" className="c-service-form__staff-link">
            <Add />
            <span>Add New Service</span>
          </NavLink>
        </>
      ) : (
        <h5 className="p-4 text-danger">
          No Service Exists, Please add first service from <NavLink to="/staff/members">Services Page</NavLink>
        </h5>
      )}
    </div>
  );
};

StaffServices.propTypes = {
  data: PropTypes.any,
  level: PropTypes.string,
  onChange: PropTypes.func,
};

export default StaffServices;
