import React, { useContext, useState } from 'react';
import Modal from '../../UIKit/Modal';
import { ControlInput } from '../../UIKit/Form/Input';
import { useForm } from 'react-hook-form';
import TokenFormModal from './TokenFormModal';
import { handleApiSave } from '../../../../utilities/useApi';
import { changeIdentity } from '../../../../api';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const EmailFormModal = ({ onClose, onSave }) => {
  const [formToken, setFormToken] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const { user } = useContext(AppContext);
  const { control, errors, handleSubmit, setError } = useForm();

  const handleSave = ({ email }) => {
    handleApiSave(changeIdentity, null, { new_identity: email, identity_type: 'email' }, null, {
      loadingFn: setSaveLoading,
      setError,
    })
      .then((res) => onSave(res))
      .catch(() => {});
  };

  const handleClose = () => {
    if (formToken) {
      setFormToken(false);
    } else {
      onClose();
    }
  };

  const handleSaveToken = () => {
    setTokenLoading(true);
    onClose();
    setFormToken(false);
    setTokenLoading(false);
  };

  return (
    <>
      <TokenFormModal
        show={formToken}
        loading={tokenLoading}
        onClose={() => setFormToken(false)}
        onSave={handleSaveToken}
      />
      <Modal
        show={!formToken}
        title="Change Email"
        saveLoading={saveLoading}
        onSave={handleSubmit(handleSave)}
        saveBtnText="Submit Email"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="alert alert-warning my-3">Please Enter your email address correctly</div>
          <ControlInput
            name="email"
            control={control}
            rules={{ required: 'Email is required' }}
            defaultValue={user ? user?.user.email : ''}
            type="email"
            label="Email"
            placeholder="Enter your email address"
            errorMessage={errors?.email?.message}
          />
        </form>
      </Modal>
    </>
  );
};

export default EmailFormModal;
