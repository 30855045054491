import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const HeadOptions = ({ className, rightElement, leftElement }) => (
  <div className={classnames('c-head-options', className)}>
    {leftElement && <div className="c-head-options__left">{leftElement}</div>}
    {rightElement && <div className="c-head-options__right">{rightElement}</div>}
  </div>
);

HeadOptions.propTypes = {
  className: PropTypes.any,
  rightElement: PropTypes.any,
  leftElement: PropTypes.any,
};

export default HeadOptions;
