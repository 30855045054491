import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({ url, icon, name, subLinks, smallFont = false, unreadCount = 0 }) => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const handleActive = useCallback(
    (i) => {
      if (active !== !!i) {
        setActive(!!i);
        setOpen(!!i);
      }
      return i;
    },
    [active],
  );

  const handleClick = () => {
    if (active) setOpen(!open);
  };
  return (
    <div
      className={classnames('c-panel-sidebar__list-item', {
        'c-panel-sidebar__list-item--active': open,
      })}
    >
      <NavLink
        to={url}
        id={`sidebarLink_${url.replace('/', '').replaceAll('/', '-')}`}
        isActive={(i) => handleActive(i)}
        className={classnames('c-panel-sidebar__list-link', {
          'c-panel-sidebar__list-link--sm': smallFont,
        })}
        onClick={handleClick}
      >
        <i className={icon} />
        <span>{name}</span>
        {url.includes('messages') && unreadCount ? (
          <span className="c-panel-sidebar__sub-list-badge">{unreadCount}</span>
        ) : null}
      </NavLink>
      {subLinks && subLinks.length > 0 && (
        <div className="c-panel-sidebar__sub-list">
          {subLinks.map((item, key) => (
            <NavLink
              key={key}
              to={item.url}
              id={`sidebarSubLink_${item.url.replace('/', '').replaceAll('/', '-')}`}
              className="c-panel-sidebar__sub-list-item"
            >
              <i className={item.icon} />
              <span>{item.name}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

SidebarLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subLinks: PropTypes.array,
  smallFont: PropTypes.any,
  unreadCount: PropTypes.number,
};

export default SidebarLink;
