import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from './Select';
import { FLAG_CANADA, FLAG_AUSTRALIA, FLAG_UK } from '../../../../assets/FlagsImages';

export const countryCodes = ['+1', '+61', '+44'];
const countries = [
  {
    id: 1,
    code: '+1',
    flag: FLAG_CANADA,
    name: 'ca',
  },
  {
    id: 2,
    code: '+61',
    flag: FLAG_AUSTRALIA,
    name: 'au',
  },
  {
    id: 3,
    code: '+44',
    flag: FLAG_UK,
    name: 'uk',
  },
];

// eslint-disable-next-line react/display-name
const InputPhone = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ id, value: _value, unit, unitPos, onChange, onChangeText, onChangeValue, className, ...otherProps }, ref) => {
    const [value, setValue] = useState('');
    const [code, setCode] = useState('+1');
    useEffect(() => {
      setCode(_value?.code ? _value?.code : '+1');
      setValue(_value?.value);
    }, [_value]);

    const handleOnChange = (e) => {
      const val = e.target.value;
      setValue(val);
      onChangeValue({ code, value: val });
    };

    const handleChangeCode = (code) => {
      setCode(code.value);
      onChangeValue({ value, code: code.value });
    };
    const codeOptions = useMemo(
      () =>
        countries.map((country) => ({
          label: (
            <span className="c-input-phone__item">
              <img src={country.flag} alt={country.name} className="c-input-phone__item-image" />
              {country.code}
            </span>
          ),
          value: country.code,
        })),
      [],
    );

    return (
      <div
        className={classnames(
          'c-form__controller overflow-visible',
          { 'c-form__controller--disabled': otherProps.disabled },
          className,
        )}
      >
        <Select
          value={code}
          onChange={handleChangeCode}
          className="c-input-phone__select"
          formGroup={false}
          options={codeOptions}
        />
        <input type="tel" id={id} ref={ref} onChange={handleOnChange} value={value} {...otherProps} />

        {unit && <span className="c-form__controller-unit">{unit}</span>}
      </div>
    );
  },
);

InputPhone.propTypes = {
  id: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.object,
  unitPos: PropTypes.oneOf(['before', 'after']),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  onChangeValue: PropTypes.func,
  className: PropTypes.any,
};

InputPhone.defaultProps = {
  id: null,
  unit: '',
  unitPos: 'after',
  value: { code: '+1', value: '' },
  placeholder: '(444) 333 2222',
  onChange: () => {},
  onChangeText: () => {},
};

export default InputPhone;
