import React, { useContext, useEffect, useState } from 'react';
import { handleApi } from '../../../../utilities/useApi';
import {
  getBusiness,
  getStripeDisconnectAccountLink,
  getStripeConnectAccountLink,
  getBusinessById,
} from '../../../../api';
import SectionHead from '../../UIKit/SectionTitle';
import Button from '../../UIKit/Form/Button';
import { PageLoading } from '../../UIKit/Spinner';
import { Types } from '../../../../config/actionTypes';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceStripAccount = ({ isNew = false, reference = 'marketplace', onNext, onPrev }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(null);
  const { newBusiness, business } = useContext(AppContext);

  useEffect(() => {
    if (isNew) {
      if (newBusiness) {
        handleApi(getBusinessById, newBusiness.id, { list: false }).then((data) => {
          setIsConnected(data?.connected_to_stripe);
        });
      }
    } else {
      handleApi(getBusiness, {}, { list: false }).then((data) => {
        setIsConnected(data?.connected_to_stripe);
      });
    }
  }, [isNew]);
  // useEffect(() => {
  //   handleApi(getBusiness).then((data) => {
  //     setIsConnected(data?.connected_to_stripe);
  //   });
  // }, []);

  const handleDisconnectAccount = () => {
    handleApi(getStripeDisconnectAccountLink, {}, { loadingFn: setSaveLoading }).then(() => {
      setIsConnected(false);
    });
  };

  const handleLinkToStrip = () => {
    setSaveLoading(true);
    handleApi(getStripeConnectAccountLink, isNew ? { businessId: newBusiness?.id || business?.id } : undefined, {
      list: false,
    }).then((data) => {
      if (newBusiness) localStorage.setItem(Types.BUSINESS_LOCATION, newBusiness.id);
      localStorage.setItem(Types.PAYMENT_BACK_PAGE, `setup.${reference}`);
      window.location.href = data?.connect_url;
    });
  };

  return (
    <div className="px-3">
      <SectionHead title="Booking Methods" />
      {isConnected !== null ? (
        <>
          <div>
            In order to join our marketplace and get paid through Beautster, payments are required to run through the
            Stripe app. We use Stripe’s payment processing platform to link your bank account. To link your bank
            account, we use Stripe&apos;s payment processing platform. To ensure the security of your account, federal
            law requires us to verify your identity. This information will only be used for verification. It will not be
            shared with your clients, nor will it be displayed on your profile.
          </div>
          {isConnected ? (
            <>
              <div className="alert alert-success mt-3 mb-5">Your account has been connected successfully</div>
              <Button color="light" loading={saveLoading} onClick={handleDisconnectAccount}>
                Disconnect Strip Account
              </Button>
            </>
          ) : (
            <>
              <Button className="d-inline-block mt-5" loading={saveLoading} onClick={handleLinkToStrip}>
                Link your bank account
              </Button>
            </>
          )}
          <div className="d-flex align-center mt-5">
            <Button type="button" color="light" onClick={onPrev} className="mr-auto">
              Previous Step
            </Button>
            {/*<Button disabled={!isConnected} onClick={onNext} className="ml-auto">*/}
            <Button onClick={onNext} color={!isConnected ? 'info' : undefined} className="ml-auto">
              {!isConnected && 'Skip & '}Next Step
            </Button>
          </div>
        </>
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default SetupMarketplaceStripAccount;
