/**
 *
 * @param category {{
 *     id,
 *     name,
 *     service_types,
 * }}
 * @constructor
 */

function ServiceGroupObject(category) {
  this.categoryId = category.id;
  this.categoryName = category.name;
  this.services = [];
  if (category?.service_types) {
    category.service_types.forEach((sub) => {
      sub.services.forEach((service) => {
        this.services.push(new ServiceItemObject(service));
      });
    });
  }
  // id: 25
  // index: 0service_type
  // name: "Hair Removal"
  // subcategories: [,…]
  // url_slug: "hair-removal"
}

/**
 *
 * @param service {{
 *     id,
 *     name,
 *     description,
 *     extra_time,
 *     duration,
 *     tiered_level,
 *     lmo,
 *     deal,
 *     has_active_deal,
 *     has_active_lmo,
 *     is_and_up,
 *     price_type,
 *     price,
 *     providers,
 *     service_type,
 * }}
 * @constructor
 */
export function ServiceItemObject(service) {
  this.id = service.id;
  this.name = service.name;
  this.duration = service.duration;
  this.extra_time = service.extra_time;
  this.description = service.description;
  this.tiered_level = service.tiered_level;
  this.lmo = service.lmo;
  this.deal = service.deal;
  this.has_active_deal = service.has_active_deal;
  this.has_active_lmo = service.has_active_lmo;
  // this.priceType = service.is_and_up ? 'From' : +service.price === 0 ? 'Free' : 'Fixed';
  // this.is_and_up = +service.price === 0 ? 'free' : service.is_and_up;
  this.priceType = service.price_type === 'from' ? 'From' : +service.price === 0 ? 'Free' : 'Fixed';
  this.price_type = +service.price === 0 ? 'free' : service.price_type;
  this.price = service.price;
  this.providers = service.providers;
  this.service_type = service.service_type;
}

export default ServiceGroupObject;
