import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Error } from '@material-ui/icons';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { getQueryUri } from '../../../utilities/URI';
import { handleApi } from '../../../utilities/useApi';
import { getProfile, verifyCodeIdentity } from '../../../api';
import toaster from 'toasted-notes';
import { AppContext } from '../../../context/providers';
import Button from '../../Common/UIKit/Form/Button';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import AuthenticationLayout from '../../Common/Authentication/AuthenticationLayout';

const VerifyIdentity = ({ history }) => {
  const [error, setError] = useState(null);
  const { setUser } = useContext(AppContext);
  useEffect(() => {
    const token = getQueryUri('token');
    handleApi(verifyCodeIdentity, { token }, { list: false })
      .then(() => {
        toaster.notify(() => <div className="alert alert-success">Your email has verified successfully</div>);
        handleApi(getProfile, {}, { list: false }).then((res) => {
          setUser(res);
          history.push('/');
        });
      })
      .catch(({ errors }) => {
        if (errors?.non_field_errors) {
          setError(errors?.non_field_errors[0]);
        }
      });
  }, []);
  return error ? (
    <AuthenticationLayout>
      <NotFoundData
        icon={<Error />}
        title="Verify Error"
        description={error}
        button={<Button link="/">Back To Home</Button>}
      />
    </AuthenticationLayout>
  ) : (
    <PageLoading />
  );
};

VerifyIdentity.propTypes = {
  history: PropTypes.any,
};

export default VerifyIdentity;
