import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import AppointmentLineObject from '../objects/AppointmentLineObject';

const { GET, POST, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *     staff
 *     reserved_date
 *     reserved_time
 *     duration
 * }}
 */
export const checkAvailabilityAppointmentLine = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.appointmentLine.check_availability,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     staff
 *     reserved_date
 *     reserved_time
 *     duration
 * }}
 */
export const createAppointmentLine = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.appointmentLine.create,
    // needStatus: true
  });
  if (response.data) {
    return new AppointmentLineObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const updateAppointmentLine = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    params,
    url: createURL(ApiRoutes.appointmentLine.update, { id }),
    // needStatus: true
  });
  if (response.data) {
    return new AppointmentLineObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param search
 * @param page
 * @param limit
 */
export const getAppointmentLineList = async (search = '', page = 1, limit = 10) => {
  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.appointmentLine.list,
    // needStatus: true
  });

  // console.log(response);
  // const { data, status } = response;
  // if (status === StatusCodes.Success) return (data.results || []).map(i => new AppointmentLineObject(i));
  if (response.data) {
    return response.data;
    // return {
    //     ...response.data,
    //     results: (response.data.results || []).map(i => new AppointmentLineObject(i))
    // }
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getAppointmentLine = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.appointmentLine.get, { id }),
    // needStatus: true
  });
  if (response.data) {
    return new AppointmentLineObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteAppointmentLine = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.appointmentLine.delete, { id }),
    // needStatus: true
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
