import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tab from '../../Common/UIKit/Tab';
import SalesAppointments from './SalesAppointments';
import SalesTransactions from './SalesTransactions';
import usePermission from '../../../utilities/usePermission';

const SalesPage = ({ history, location }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { access: _appointment } = usePermission('appointment', 'crud');
  const { access: _invoice } = usePermission('invoice', 'crud');

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('/sales/appointments')) {
      setTabIndex(0);
    } else if (pathname.includes('/sales/transactions')) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  const handleSelectTab = (ind) => {
    setTabIndex(ind);
    if (ind === 1) history.push('/sales/transactions');
    else history.push('/sales/appointments');
  };

  const tabs = [
    {
      hide: !_appointment?.view && !_appointment?.modify,
      label: 'Appointments',
    },
    {
      hide: !_invoice?.view && !_invoice?.modify,
      label: 'Transactions',
    },
  ];
  const renderRightEl = <Tab tabs={tabs} tabHeadClassName={'p-0'} onSelect={handleSelectTab} selected={tabIndex} />;

  const renderPages = () => {
    switch (tabIndex) {
      case 0:
        return <SalesAppointments headerEl={renderRightEl} />;
      case 1:
        return <SalesTransactions headerEl={renderRightEl} />;
      default:
        return <SalesAppointments headerEl={renderRightEl} />;
    }
  };

  return renderPages();
};

SalesPage.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
};

export default SalesPage;
