import React from 'react';
import PropTypes from 'prop-types';

const HeadPage = ({ title, rightElement, leftElement }) => (
  <div className="c-head-page">
    {title && <h1>{title}</h1>}
    {leftElement && <div className="c-head-page__element c-head-page__element-left">{leftElement}</div>}
    {rightElement && <div className="c-head-page__element c-head-page__element-right">{rightElement}</div>}
  </div>
);

HeadPage.propTypes = {
  title: PropTypes.string,
  rightElement: PropTypes.any,
  leftElement: PropTypes.any,
};

export default HeadPage;
