import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import { AppointmentCalendarObject, AppointmentListObject } from '../objects/AppointmentObject';

const { GET, POST, PUT, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *     lines,
 *     client,
 *     client_name,
 *     client_email,
 *     note
 * }}
 */
export const createAppointment = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.appointment.create,
    // needStatus: true
  });
  if (response.data) {
    return new AppointmentCalendarObject(response.data);
    // return response.data;
  } else {
    return Promise.reject({
      ...response,
      available: true,
    });
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const updateAppointment = async (id, params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: createURL(ApiRoutes.appointment.update, { id }),
  });
  if (response.data) {
    return new AppointmentCalendarObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     name,
 *     email,
 *     phone,
 *     tiered_level
 * }}
 */
export const partialUpdateAppointment = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    params,
    url: createURL(ApiRoutes.appointment.update, { id }),
  });
  if (response.data) {
    return new AppointmentCalendarObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param status // no_shows, cancelled, arrived
 */
export const updateStatusAppointment = async (id, { status, ...params }) => {
  const response = await callApi({
    method: POST,
    params,
    url: createURL(ApiRoutes.appointment.updateStatus, { id, status }),
  });
  if (response.data) {
    return new AppointmentCalendarObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param page
 * @param limit
 * @param otherParams {{
 *     search,
 *     limit,
 *     to_date,
 *     from_date,
 *     client,
 * }}
 */
export const getAppointmentList = async ({ page, limit, ...otherParams }) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
      ...otherParams,
    },
    url: ApiRoutes.appointment.list,
  });
  if (response.data) {
    const res = {
      ...response.data,
      results: (response.data.results || []).map((i) => new AppointmentListObject(i)),
    };

    return res;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     search,
 *     to_date,
 *     from_date,
 *     page,
 *     limit,
 * }}
 */
export const getAppointmentCalendarList = async (params) => {
  // let { search, to_date, from_date, page, limit, sale_channel } = params;
  let { page, limit } = params;
  limit = +limit || 10;
  page = +page || 1;

  const getParams = { ...params };
  delete getParams.page;
  const response = await callApi({
    method: GET,
    params: {
      // staff: [2, 3, 4],
      offset: (page - 1) * limit,
      ...getParams,
    },
    // params: {
    //     limit,
    //     search,
    //     offset: (page - 1) * limit,
    //     to_date,
    //     from_date,
    //     sale_channel: sale_channel
    // },
    url: ApiRoutes.appointment.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new AppointmentCalendarObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getAppointment = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.appointment.get, { id }),
  });
  if (response.data) {
    return new AppointmentCalendarObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteAppointment = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.appointment.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
