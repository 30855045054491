import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import Modal from '../UIKit/Modal';
import { googleCalendarList, getGoogleCalendarSync, googleCalendarSync, googleCalendarAuth } from '../../../api';
import Checkbox from '../UIKit/Form/Checkbox';
import { PageLoading } from '../UIKit/Spinner';
import Alert from '../UIKit/Alert';
import { useLocation } from 'react-router-dom';
import Button from '../UIKit/Form/Button';

const GoogleCalendarModal = ({ onClose, onSave }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(null);
  // const [calendarId, setCalendarId] = useState(null);
  const [calendarId, setCalendarId] = useState([]);
  const [shouldSignIn, setShouldSignIn] = useState(false);
  const [errorSync, setErrorSync] = useState(null);
  const [calendarList, setCalendarList] = useState(null);
  const location = useLocation();

  useEffect(() => {
    getGoogleCalendarSync().then((res) => {
      setAccessToken(res?.access_token || 'null');
    });
    // return;
    // googleCalendarSync().then((res) => {
    //   console.log('googleCalendarSync', res);
    // });
    // googleCalendarEventsList().then((res) => {
    //   console.log('googleCalendarEvents', res);
    // });
  }, []);
  useEffect(() => {
    if (accessToken) {
      if (location.search === '?message=Calendar%20sync%20failed.') {
        // console.log('location.search', location.search);
        // if (location.search.includes('failed.')) {
        setErrorSync('Google calendar sync failed!');
      } else if (location.search === '?message=Parameters%20is%20not%20valid.') {
        setErrorSync('Google calendar sync failed! - Invalid Parameters');
      } else if (accessToken === 'null') {
        setShouldSignIn(true);
      } else {
        setCalendarList(null);
        setLoading(true);
        googleCalendarList(accessToken)
          .then((res) => {
            setCalendarList(res?.items || null);
            setLoading(false);
          })
          .catch((e) => {
            if (e?.status === 401) {
              setShouldSignIn(true);
              // googleCalendarAuth()
              //   .then((res) => {
              //     if (res?.authorization_url) {
              //       window.location.href = res.authorization_url;
              //     }
              //   })
              //   .catch(() => setLoading(false));
              // const checkedAuth = localStorage.getItem('check-calendar-auth');
              // if (checkedAuth) {
              //   toaster.notify(() => <Alert danger>Google Calendar Error</Alert>);
              //   localStorage.removeItem('check-calendar-auth');
              //   onClose();
              // } else {
              //   localStorage.setItem('check-calendar-auth', '1');
              //   googleCalendarAuth()
              //     .then((res) => {
              //       if (res?.authorization_url) {
              //         window.location.href = res.authorization_url;
              //       }
              //     })
              //     .catch(() => setLoading(false));
              // }
            }
          });
      }
    }
  }, [accessToken]);

  const handleSignIn = () => {
    googleCalendarAuth()
      .then((res) => {
        if (res?.authorization_url) {
          window.location.href = res.authorization_url;
        }
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (calendarId.length > 0) {
      // if (calendarId) {
      setSubmitLoading(true);
      googleCalendarSync({ calendarId })
        .then(() => {
          onSave();
        })
        .finally(() => setSubmitLoading(false));
    } else {
      toaster.notify(() => <Alert danger>Please Select A Google Calendar</Alert>);
    }
  };

  return (
    <Modal
      show
      title={shouldSignIn ? 'Sign In Google' : errorSync ? 'Error' : 'Google Calendar List'}
      saveBtn={!errorSync && !shouldSignIn}
      description={!errorSync && !shouldSignIn && 'Import From Google Calendar'}
      onClose={onClose}
      saveLoading={submitLoading}
      onSave={handleSubmit}
    >
      {errorSync ? (
        <>
          <h2 className="text-center text-danger py-5 px-3">{errorSync}</h2>
        </>
      ) : shouldSignIn ? (
        <div className="c-calendar__google-btn-container">
          <h4>Sign in with your google account</h4>
          <p>Sign in with your google account , which you want to import events from</p>
          {/*<Button color="light" onClick={handleSignIn} className="c-calendar__google-btn">*/}
          {/*  <Image src={IMAGE_GOOGLE_LOGO} alt="Google Calendar" className="c-calendar__google-btn-img" />*/}
          {/*  SIGN IN WITH GOOGLE*/}
          {/*</Button>*/}
          <Button color="light" onClick={handleSignIn} className="c-calendar__google-button" />
        </div>
      ) : (
        <>
          {loading ? (
            <PageLoading />
          ) : (
            (calendarList || []).map((item) => (
              <Checkbox
                // type="radio"
                key={item.id}
                name="calendar-item"
                formGroup
                id={`staff-${item.id}`}
                onChange={(selected) => {
                  // if (selected) setCalendarId(item.id);
                  setCalendarId((prev) => (selected ? [...prev, item.id] : prev.filter((i) => i !== item.id)));
                }}
                label={
                  <span className="c-form__label-item">
                    <span className="detail">
                      <span>{item.summary}</span>
                      {item?.description && (
                        <small className="description font-weight-normal">{item.description}</small>
                      )}
                    </span>
                  </span>
                }
              />
            ))
          )}
        </>
      )}
    </Modal>
  );
};

GoogleCalendarModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default GoogleCalendarModal;
