import React from 'react';
import PropTypes from 'prop-types';
import ServiceGroup from './ServiceGroup';

const Services = ({ data, isModify }) => (
  <div className="c-service-group">
    {data.map(
      (item, key) => item.services.length > 0 && <ServiceGroup key={key} isModify={isModify} index={key} item={item} />,
    )}
  </div>
);

Services.propTypes = {
  data: PropTypes.any,
  isModify: PropTypes.bool,
};

export default Services;
