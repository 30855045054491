import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collections, School, VerifiedUser, Work } from '@material-ui/icons';
import Backdrop from '../../UIKit/Backdrop';
import Image from '../../UIKit/Image';
import { AppContext } from '../../../../context/providers';
import { Types } from '../../../../config/actionTypes';
import StylistProfileModal from '../../UserProfile/StylistProfileModal';
import EducationModal from '../../UserProfile/Education/EducationModal';
import ExperienceModal from '../../UserProfile/Experience/ExperienceModal';
import CertificateModal from '../../UserProfile/Certificate/CertificateModal';
import UserProfileModal from '../../UserProfile/UserProfieModal';
import StylistPhotosModal from '../../UserProfile/StylistPhotosModal/StylistPhotosModal';

const TYPES = {
  STYLIST: 'stylist',
  EXPERIENCE: 'experience',
  EDUCATION: 'education',
  CERTIFICATE: 'certificate',
  PROFILE: 'profile',
  PHOTOS: 'photos',
};

const UserSidebar = ({ isOpen, onClose }) => {
  const { user, stylist } = useContext(AppContext);
  const [showModal, setShowStylist] = useState(null);
  const handleLogout = () => {
    localStorage.removeItem(Types.USER_TOKEN);
    localStorage.removeItem(Types.USER_REFRESH);
    localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
    localStorage.removeItem(Types.STRIPE_PAYMENT);
    localStorage.removeItem(Types.SYSTEM_TYPE);
    localStorage.removeItem(Types.CITY);
    localStorage.removeItem(Types.BUSINESS_LOCATION);
    localStorage.removeItem(Types.LATEST_APPOINTMENT);
    localStorage.removeItem(Types.COUNTRIES);
    window.location.reload();
  };

  const handleOpen = (type = null) => {
    onClose();
    setShowStylist(type);
  };

  return (
    <>
      <Backdrop show={isOpen} onClick={onClose} />
      {showModal === TYPES.STYLIST && <StylistProfileModal onClose={() => setShowStylist(null)} />}
      {showModal === TYPES.EDUCATION && <EducationModal onClose={() => setShowStylist(null)} />}
      {showModal === TYPES.EXPERIENCE && <ExperienceModal onClose={() => setShowStylist(null)} />}
      {showModal === TYPES.CERTIFICATE && <CertificateModal onClose={() => setShowStylist(null)} />}
      {showModal === TYPES.PHOTOS && <StylistPhotosModal onClose={() => setShowStylist(null)} />}
      {showModal === TYPES.PROFILE && <UserProfileModal onClose={() => setShowStylist(null)} />}
      <div
        className={classNames('c-user-sidebar', {
          'c-user-sidebar--open': isOpen,
        })}
      >
        <div className="c-user-sidebar__profile">
          <Image
            src={user?.avatar || user?.default_avatar_url}
            alt={user?.full_name}
            className="c-user-sidebar__avatar"
          />
          <span className="c-user-sidebar__name">{user?.full_name}</span>
        </div>
        <ul className="c-user-sidebar__links">
          <li>
            <span className="c-user-sidebar__link" onClick={() => handleOpen(TYPES.STYLIST)}>
              <span className="c-user-sidebar__link-body">
                <i className="fas fa-id-badge" />
                <span className="c-user-sidebar__link-label">Stylist Profile</span>
              </span>
              {/*<i className="fas fa-arrow-right" />*/}
            </span>
            {stylist && (
              <ul className="c-user-sidebar__links-sub">
                <li>
                  <span className="c-user-sidebar__link" onClick={() => handleOpen(TYPES.EDUCATION)}>
                    <span className="c-user-sidebar__link-body">
                      <School />
                      <span className="c-user-sidebar__link-label">Education</span>
                    </span>
                  </span>
                </li>

                <li>
                  <span className="c-user-sidebar__link" onClick={() => handleOpen(TYPES.EXPERIENCE)}>
                    <span className="c-user-sidebar__link-body">
                      <Work />
                      <span className="c-user-sidebar__link-label">Experience</span>
                    </span>
                  </span>
                </li>

                <li>
                  <span className="c-user-sidebar__link" onClick={() => handleOpen(TYPES.CERTIFICATE)}>
                    <span className="c-user-sidebar__link-body">
                      <VerifiedUser />
                      <span className="c-user-sidebar__link-label">Certificates</span>
                    </span>
                  </span>
                </li>
                <li>
                  <span className="c-user-sidebar__link" onClick={() => handleOpen(TYPES.PHOTOS)}>
                    <span className="c-user-sidebar__link-body">
                      <Collections />
                      <span className="c-user-sidebar__link-label">Photos</span>
                    </span>
                  </span>
                </li>
              </ul>
            )}
          </li>

          <li>
            <span className="c-user-sidebar__link" onClick={() => handleOpen(TYPES.PROFILE)}>
              <span className="c-user-sidebar__link-body">
                <i className="fas fa-user-circle" />
                <span className="c-user-sidebar__link-label">Profile</span>
              </span>
            </span>
          </li>
          <li>
            <span className="c-user-sidebar__link" onClick={handleLogout}>
              <span className="c-user-sidebar__link-body">
                <i className="fas fa-sign-out-alt" />
                <span className="c-user-sidebar__link-label">Logout</span>
              </span>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

UserSidebar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UserSidebar;
