import React, { useContext, useEffect, useState } from 'react';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { createBusiness, getBusiness, getBusinessById, partialUpdateBusiness } from '../../../../api';
import { useForm } from 'react-hook-form';
import toaster from 'toasted-notes';
import SectionHead from '../../UIKit/SectionTitle';
import { ControlInput } from '../../UIKit/Form/Input';
import { ControlCheckbox } from '../../UIKit/Form/Checkbox';
import Button from '../../UIKit/Form/Button';
import { PageLoading } from '../../UIKit/Spinner';
import { AppContext } from '../../../../context/providers';
import BusinessLocationForm from '../../Business/BusinessLocationForm';

// eslint-disable-next-line react/prop-types
const SetupSoftwareProfileForm = ({ onNext, isNew = false, goal: setup_goal, isSoftware = false }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  // const [data, loading] = useApi(getBusiness);

  const { setBusiness, onNewBusiness, newBusiness } = useContext(AppContext);

  const { control, errors, watch, handleSubmit, setError, setValue } = useForm();

  useEffect(() => {
    if (isNew) {
      if (newBusiness) {
        handleApi(getBusinessById, newBusiness.id, { list: false, loadingFn: setLoading }).then(setData);
      } else {
        setLoading(false);
      }
    } else {
      handleApi(getBusiness, {}, { loadingFn: setLoading }).then(setData);
    }
  }, [isNew]);

  const handleSave = (data) => {
    handleApiSave(
      isNew && !newBusiness ? createBusiness : partialUpdateBusiness,
      null,
      { businessId: newBusiness?.id || null, setup_goal, ...data },
      null,
      {
        loadingFn: setSaveLoading,
        setError,
      },
    )
      .then((data) => {
        if (data) {
          toaster.notify(() => (
            <div className="alert alert-success mt-3">Business Info {isNew ? 'Created' : 'Changed'} Successfully</div>
          ));
          if (onNext) onNext();
          if (isNew) onNewBusiness(data);
          else setBusiness(data);
        }
      })
      .catch(() => {});
  };

  const handleChangeProvince = (e) => {
    if (e.id !== data?.province) {
      setValue('tax_rate', e.tax_rate);
    } else {
      setValue('tax_rate', data?.tax_rate);
    }
  };

  return loading ? (
    <PageLoading />
  ) : (
    <form onSubmit={handleSubmit(handleSave)} className="px-3">
      <SectionHead title="Business Info" />
      <ControlInput
        control={control}
        name="title"
        rules={{ required: 'Title is required' }}
        defaultValue={data ? data.title : ''}
        label={'Title'}
        placeholder={'Title'}
        errorMessage={errors?.title?.message}
      />
      <ControlInput
        control={control}
        name="phone_number"
        type="phone"
        // rules={{ required: 'Phone is required' }}
        defaultValue={data?.phone_number || ''}
        label="Phone"
        errorMessage={errors?.phone_number?.message}
      />
      <BusinessLocationForm
        zipCode={false}
        data={data}
        control={control}
        errors={errors}
        watch={watch()}
        onChangeProvince={handleChangeProvince}
      />
      <ControlInput
        control={control}
        name="tax_rate"
        rules={{
          min: {
            value: 0.0000001,
            message: 'Tax should be more than zero',
          },
          required: 'Tax is required',
        }}
        defaultValue={data?.tax_rate || null}
        label="Tax Rate"
        placeholder="eg. 5%"
        unit="%"
        errorMessage={errors?.tax_rate?.message}
      />
      {!isSoftware && (
        <>
          <ControlCheckbox
            control={control}
            name="is_independent"
            defaultValue={data ? data.is_independent : true}
            label={'I am an independent'}
            errorMessage={errors?.is_independent?.message}
          />
        </>
      )}
      <Button color={isSoftware ? 'primary' : undefined} loading={saveLoading} className="ml-auto mt-5">
        Next Step
      </Button>
    </form>
  );
};

export default SetupSoftwareProfileForm;
