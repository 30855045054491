import React, { useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { MessageAttachmentForm } from './MessageForm';
import Input, { ControlInput } from '../UIKit/Form/Input';
import { useForm } from 'react-hook-form';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { createMessage, getClientList } from '../../../api';
import Checkbox from '../UIKit/Form/Checkbox';
import ImageName from '../UIKit/ImageName';
import Button from '../UIKit/Form/Button';
import { CircleSpinner, PageLoading } from '../UIKit/Spinner';

const MessageModal = ({ onClose, onSave }) => {
  const [step, setStep] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientText, setClientText] = useState('');
  const [clientType, setClientType] = useState(2);
  const [clientSelected, setClientSelected] = useState([]);
  const [clientList, setClientList] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const { control, handleSubmit, setValue, clearErrors } = useForm();

  // useEffect(() => {
  //   setValue('message', '');
  //   setAttachment(null);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [client]);
  useEffect(() => {
    setLoading(true);
    getClients('');
  }, []);

  useEffect(() => {
    if (attachment) {
      clearErrors('message');
    }
  }, [attachment]);

  const getClients = (searchText = '', next = false) => {
    handleApi(
      getClientList,
      { search: searchText, has_user: 1, nextUrl: next ? clientList.next : null },
      { loadingFn: next ? setLoadingPage : setLoading },
    ).then((res) => {
      setClientList((prev) => (next ? { ...res, results: [...(prev?.results || []), ...res.results] } : res));
    });
  };

  const handleSubmitForm = ({ message }) => {
    if (saveLoading) return;
    const body = {
      body: message,
      // user: i?.id,
    };
    if (attachment) {
      body.attachment = attachment.file;
      body.body = !!message ? message : 'system:attachment';
    }
    const bulkBody = clientSelected.map((i) => ({ ...body, user: i?.user?.id }));
    handleApiSave(createMessage, null, bulkBody, null, {
      loadingFn: setSaveLoading,
    })
      .then((res) => {
        if (res && Array.isArray(res)) {
          setValue('message', '');
          setAttachment(null);
          onSave();
        }
      })
      // .then((data) => onSave({ ...data }))
      .catch(() => {});
  };

  const handleSelectClients = () => {
    setStep(2);
  };

  const handleSearchClient = (searchText) => {
    getClients(searchText);
    setClientText(searchText);
  };

  const handleSelectClient = (item) => {
    setClientSelected((prev) =>
      prev.find((i) => i.id === item.id) ? prev.filter((i) => i.id !== item.id) : [...prev, item],
    );
  };
  return (
    <Modal
      show
      title="New Message"
      onClose={onClose}
      onSave={step === 1 ? handleSelectClients : handleSubmit(handleSubmitForm)}
      saveLoading={saveLoading}
      saveBtnText={step === 1 ? 'Confirm' : 'Send'}
      saveBtnDisabled={step === 1 && clientSelected.length === 0}
      buttonComponent={
        step === 2 && (
          <Button onClick={() => setStep(1)} color="light">
            Client list
          </Button>
        )
      }
    >
      {step === 1 ? (
        <div>
          {false && (
            <>
              <div className="c-messages__bulk-types">
                <Checkbox
                  id="all"
                  name="type"
                  label="Select All Users"
                  formGroupClass="mr-5 mb-0"
                  value="1"
                  checked={clientType === 1}
                  onChange={() => setClientType(1)}
                />
                <Checkbox
                  name="type"
                  id="custom"
                  label="Select Custom Users"
                  formGroupClass="mb-0"
                  value="2"
                  checked={clientType === 2}
                  onChange={() => setClientType(2)}
                />
              </div>
              <hr />
            </>
          )}
          {clientType === 2 && (
            <>
              {clientSelected.length > 0 && (
                <>
                  <div className="c-messages__bulk-selected-list">
                    {clientSelected.map((i) => (
                      <div key={i.id} className="c-messages__bulk-selected-item badge badge-primary">
                        <span onClick={() => handleSelectClient(i)}>
                          <Close />
                        </span>
                        {i.name}
                      </div>
                    ))}
                  </div>
                  <hr />
                </>
              )}
              <div className="p-3">
                <Input formGroup={false} placeholder="Search client" onChangeText={handleSearchClient} />
              </div>
              <div className="c-messages__bulk-list">
                {loading ? (
                  <PageLoading />
                ) : (
                  <>
                    {(clientList?.results || []).map((client) => (
                      <div
                        key={client.id}
                        className="c-messages__clients-item c-messages__bulk-client"
                        onClick={() => handleSelectClient(client)}
                      >
                        <Checkbox
                          name={client.id}
                          checked={clientSelected.findIndex((c) => c.id === client.id) > -1}
                          formGroup={false}
                        />
                        <div className="c-messages__clients-item-avatar-container c-messages__bulk-client-img">
                          <ImageName
                            name={client.name}
                            src={client.avatar}
                            alt={client.name}
                            className="c-messages__clients-item-avatar"
                          />
                        </div>
                        <span className="c-messages__clients-item-name">{client.name}</span>
                      </div>
                    ))}
                    {loadingPage && <CircleSpinner sm />}
                    {!loadingPage && clientList?.next && (
                      <Button
                        sm
                        color="light"
                        className="c-messages__bulk-more"
                        onClick={() => getClients(clientText, true)}
                      >
                        MORE
                      </Button>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="py-4">
          <form className="c-messages__form c-messages__form--modal" onSubmit={handleSubmit(handleSubmitForm)}>
            <MessageAttachmentForm file={attachment} onChange={setAttachment} />
            <ControlInput
              control={control}
              label="Message Content"
              type="textarea"
              name="message"
              rows={3}
              rules={!attachment ? { required: 'Message is required' } : undefined}
              defaultValue=""
              placeholder={!attachment ? 'Type a message' : 'Type a Caption'}
              className="c-messages__form-input"
              formClassName="w-100 mb-0"
            />
          </form>
        </div>
      )}
    </Modal>
  );
};

MessageModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default MessageModal;
