import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Close, Edit } from '@material-ui/icons';
import ImageName from '../../UIKit/ImageName';

const ModalFormClientItem = ({ data, index = null, onRemove, onEdit, onSelect }) => (
  <div
    className={classnames('c-modal-form-sidebar__client', {
      'c-modal-form-sidebar__client--link': !!onSelect,
    })}
    id={`clientItem-${index !== null ? index : data.id}`}
    onClick={!!onSelect ? onSelect : undefined}
  >
    <ImageName
      name={data?.name ? data.name : data.full_name ? data.full_name : data}
      className="c-modal-form-sidebar__client-image"
    />
    <div className="c-modal-form-sidebar__client-detail">
      <span className="c-modal-form-sidebar__client-name">{data?.name ? data.name : data}</span>
      <span className="c-modal-form-sidebar__client-email">{typeof data === 'string' ? 'Walk-In' : data.email}</span>
    </div>
    {(onEdit || onRemove) && (
      <div className="c-modal-form-sidebar__client-actions">
        {onEdit && typeof data !== 'string' && (
          <div
            className="c-modal-form-sidebar__client-action"
            id={`clientItemEdit-${index !== null ? index : data.id}`}
            onClick={onEdit}
          >
            <Edit />
          </div>
        )}
        {onRemove && (
          <div
            className="c-modal-form-sidebar__client-action"
            id={`clientItemRemove-${index !== null ? index : data.id}`}
            onClick={onRemove}
          >
            <Close />
          </div>
        )}
      </div>
    )}
  </div>
);

ModalFormClientItem.propTypes = {
  data: PropTypes.any,
  index: PropTypes.number,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ModalFormClientItem;
