import React from 'react';
import { ArrowDownwardRounded } from '@material-ui/icons';
import classnames from 'classnames';

// eslint-disable-next-line react/prop-types
const UploadFileInput = ({ label = 'Choose a file or drag it here', className, onChange, ...props }) => (
  <div className={classnames('c-upload-file', className)}>
    <input type="file" id="file-upload__box" className="c-upload-file__input" onChange={onChange} {...props} />
    <div className="c-upload-file__detail">
      <span className="c-upload-file__icon">
        <ArrowDownwardRounded />
      </span>
      {label && <span className="c-upload-file__label">{label}</span>}
    </div>
  </div>
);

export default UploadFileInput;
