import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../utilities/useApi';
import { createAccessLvl, deleteAccessLvl, getAccessLvl, partialUpdateAccessLvl } from '../../../api';
import Button from '../UIKit/Form/Button';
import { ControlInput } from '../UIKit/Form/Input';
import { ControlSwitch } from '../UIKit/Form/Switch';
import { PageLoading } from '../UIKit/Spinner';

const AccessLevelModal = ({ onClose, onSave }) => {
  const [delLoading, setDelLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { id } = useParams();
  const { handleSubmit, errors, control, setError } = useForm();

  const [data, loading] = useApiById(getAccessLvl, id);

  const onSubmit = (res) => {
    const body = id ? res : { ...res, permissions: [] };
    handleApiSave(createAccessLvl, partialUpdateAccessLvl, body, id, { setError, loadingFn: setSaveLoading }).then(
      (response) => {
        onSave(response);
      },
    );
  };
  const onDelete = () => {
    const options = {
      title: 'Confirm to delete this access level',
      message: 'Are you sure to delete this access level.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteAccessLvl, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };
  console.log('loading', loading);
  return (
    <Modal
      show
      title={id ? 'Edit Access Level' : 'New Access Level'}
      onSave={handleSubmit(onSubmit)}
      saveLoading={saveLoading}
      buttonComponent={
        id ? (
          <Button color="danger" loading={delLoading} onClick={onDelete}>
            Delete
          </Button>
        ) : null
      }
      onClose={onClose}
    >
      {loading ? (
        <PageLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlInput
            control={control}
            name="name"
            defaultValue={!!data?.name ? data.name : ''}
            label="Access Name"
            placeholder="Stylist, etc."
            rules={{ required: 'Access Name is required' }}
            errorMessage={errors?.name?.message}
          />
          <ControlInput
            control={control}
            type="textarea"
            name="description"
            defaultValue={!!data?.description ? data.description : ''}
            label="Description (optional)"
            errorMessage={errors?.description?.message}
          />
          <ControlSwitch
            formGroup
            control={control}
            name="is_active"
            defaultValue={!!data ? data.is_active : true}
            label="Is Active"
          />
        </form>
      )}
    </Modal>
  );
};

AccessLevelModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default AccessLevelModal;
