import React from 'react';
import PropTypes from 'prop-types';
import { AddBox, Block } from '@material-ui/icons';

const NotFoundData = ({ imgSrc, icon, title, description, button, fixedWidth }) => {
  const classes = ['c-notfound-data'];
  if (fixedWidth) classes.push('c-notfound-data--width');
  return (
    <div className={classes.join(' ')}>
      <div className="c-notfound-data__img">
        {imgSrc && <img src={imgSrc} alt={title} />}
        {typeof icon === 'string' ? <i className={icon} /> : icon}
      </div>
      <h1 className="c-notfound-data__title">{title}</h1>
      <div className="c-notfound-data__description">{description}</div>
      {button}
    </div>
  );
};

export const AccessDeniedPage = ({ title = 'Access Denied' }) => (
  <div className="c-notfound-data">
    <div className="c-notfound-data__img">
      <Block />
    </div>
    <h1 className="c-notfound-data__title">{title}</h1>
    <div className="c-notfound-data__description">You do not have permission to view this section.</div>
  </div>
);

AccessDeniedPage.propTypes = {
  title: PropTypes.string,
};

NotFoundData.propTypes = {
  fixedWidth: PropTypes.bool,
  imgSrc: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.any,
  button: PropTypes.any,
};

NotFoundData.defaultProps = {
  fixedWidth: false,
  icon: <AddBox />,
};

export default NotFoundData;
