import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/UIKit/Form/Button';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import PromotionDealFormModal from '../../Common/Promotion/PromotionDeals/PromotionDealFormModal';
import { getDealList } from '../../../api';
import useApi from '../../../utilities/useApi';
import PromotionDealItem from '../../Common/Promotion/PromotionDeals/PromotionDealItem';
import CampaignModal from '../../Common/Campaign/CampaignModal';
import PromotionDealDetailModal from '../../Common/Promotion/PromotionDeals/PromotionDealDetailModal';
import { AppContext } from '../../../context/providers';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/promotions/deals/';

const PromotionDealsPage = ({ history, match }) => {
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const { setHeader, isMarketplace, system } = useContext(AppContext);
  const [, loading] = useApi(getDealList, { page: true, create_channel: system, key: 'deal' }, setData);

  const { access: _access } = usePermission('deal', 'crud');
  const { access: _publish } = usePermission('deal', 'publish', 'modify');
  const { access: _unpublish } = usePermission('deal', 'unpublish', 'modify');
  const { access: _send_campaign } = usePermission('deal', 'send_campaign', 'modify');
  const { access: _campaign_report } = usePermission('deal', 'campaign_report', 'view');

  useEffect(() => {
    setHeader('Promotion');
  }, [setHeader]);

  useEffect(() => {
    if (match.path.includes('campaign')) {
      handleOpenCampaign();
    } else if (match.params.id) {
      handleOpenDetail();
    } else if (match.path === `${URL_PAGE}add`) {
      handleOpenForm();
    }
  }, [match]);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleOpenDetail = () => setShowDetail(true);
  const handleCloseDetail = (opened = true) => {
    setShowDetail(false);
    if (opened) history.replace(URL_PAGE);
  };
  const handleOpenCampaign = () => setShowCampaign(true);
  const handleCloseCampaign = (opened = true) => {
    setShowCampaign(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveForm = (item) => {
    if (typeof item === 'number' || typeof item === 'string') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => String(i.id) === String(item));
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    setShowForm(false);
    setShowDetail(false);
    if (item?.status === 'sent') {
      const results = data.results.map((i) =>
        String(i.id) === String(item.id) ? { ...i, campaign: { ...i.campaign, status: item.status } } : i,
      );
      setData({ ...data, results });
    } else {
      getDealList({ create_channel: system }).then(setData);
    }
  };

  const handleChangePublish = (id, is_published, type = 'publish') => {
    if (typeof id === 'number' || typeof id === 'string') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => String(i.id) === String(id));

      if (type === 'campaign') {
        updateData[ind].campaign.status = 'sent';
      } else {
        updateData[ind].is_published = is_published;
        updateData[ind].status = is_published ? 'published' : 'draft';
      }

      setData({ ...data, results: updateData });
    }
  };

  return (
    <>
      {showCampaign && <CampaignModal onClose={handleCloseCampaign} />}
      {showForm && _access?.modify && <PromotionDealFormModal onSave={handleSaveForm} onClose={handleCloseForm} />}
      {showDetail && <PromotionDealDetailModal onSave={handleSaveForm} onClose={handleCloseDetail} />}
      {/*<HeadPage*/}
      {/*    title={'Promotion Deals'}*/}
      {/*    rightElement={<Button sm link={`${URL_PAGE}add`}>New Promotion Deal</Button>}*/}
      {/*/>*/}
      <HeadOptions
        rightElement={
          _access?.modify && (
            <Button sm id="newDealBtn" link={`${URL_PAGE}add`}>
              New Promotion
            </Button>
          )
        }
      />
      {_access?.view ? (
        <>
          {!system || (loading && !data) ? (
            <PageLoading />
          ) : !!data && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th className="text-center">Services Count</th>
                        {/*<th>Amount</th>*/}
                        <th className="text-center">Maximum Usage</th>
                        <th className="text-center">Booked Count</th>
                        <th className="text-center">Discount</th>
                        <th>Expiration</th>
                        {isMarketplace ? (
                          <>
                            <th className="text-center">Status</th>
                            <th className="text-center">Publish</th>
                          </>
                        ) : (
                          <>
                            <th className="text-center">Send Campaign</th>
                            {_campaign_report && <th />}
                          </>
                        )}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <PromotionDealItem
                          access={{
                            publish: _publish,
                            unpublish: _unpublish,
                            send_campaign: _send_campaign,
                            campaign_report: _campaign_report,
                          }}
                          key={item.id}
                          item={item}
                          page_url={URL_PAGE}
                          onChangePublish={handleChangePublish}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Promotion Exists"
              description="Click Below to Create your First Promotion"
              button={
                <Button id="emptyNewDealBtn" link={`${URL_PAGE}add`}>
                  Create New Promotion
                </Button>
              }
              icon="fas fa-briefcase"
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

PromotionDealsPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default PromotionDealsPage;
