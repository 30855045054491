import React from 'react';
import { ChevronLeft, KeyboardArrowDown } from '@material-ui/icons';
import classNames from 'classnames';

// eslint-disable-next-line react/prop-types
const ModalFormSidebar = ({ children, minimize = false }) => (
  <div
    className={classNames('c-modal-form-sidebar', {
      'c-modal-form-sidebar--minimize': minimize,
    })}
  >
    {children}
  </div>
);
// eslint-disable-next-line react/prop-types
export const ModalFormSidebarContent = ({ children }) => (
  <div className="c-modal-form-sidebar__content">{children}</div>
);
// eslint-disable-next-line react/prop-types
export const ModalFormSidebarClose = ({ onClose }) => (
  <div className="c-modal-form-sidebar__close" onClick={onClose && onClose}>
    <ChevronLeft />
  </div>
);
// eslint-disable-next-line react/prop-types
export const ModalFormSidebarHeader = ({ title = '', onClose }) => (
  <div className="c-modal-form-sidebar__header">
    {onClose && <ModalFormSidebarClose onClose={onClose} />}
    <h3 className="c-modal-form-sidebar__header-title">{title}</h3>
  </div>
);
// eslint-disable-next-line react/prop-types
export const ModalFormSidebarMinimize = ({ minimize, onToggle }) => (
  <div
    onClick={onToggle}
    className={classNames('c-modal-form-sidebar__minimizer', {
      'c-modal-form-sidebar__minimizer--minimize': minimize,
    })}
  >
    <KeyboardArrowDown />
  </div>
);

export default ModalFormSidebar;
