import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes } from '../config/apiConfig';
import asyncLocalStorage from '../utilities/AsyncLocalStorage';
import { Types } from '../config/actionTypes';

const { POST } = ApiMethods;

export const authGoogle = async ({ token, isLogin = true }) => {
  const response = await callApi({
    method: POST,
    params: { token },
    url: ApiRoutes.auth.google,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    if (isLogin) {
      await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
      await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
    }
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const login = async ({ email, password }) => {
  const response = await callApi({
    method: POST,
    params: { email, password },
    url: ApiRoutes.auth.login,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
    await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const register = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.auth.register,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    await asyncLocalStorage.setItem(Types.USER_TOKEN, response.data.access);
    await asyncLocalStorage.setItem(Types.USER_REFRESH, response.data.refresh);
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const forgot = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.auth.forgot,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const forgotToken = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.auth.forgotToken,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const forgotConfirm = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.auth.forgotConfirm,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const claimProfilePassword = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.auth.claimProfile,
    authorization: false,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const changePassword = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.auth.changePassword,
    locationBase: false,
  });
  if (response.data && +response.status < 500) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};
