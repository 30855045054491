import React from 'react';
import TourContent from '../components/Common/UIKit/TourContent';

export const clientSteps = [
  {
    key: 'client.add-btn',
    selector: '[data-tour="add-btn"]',
    content: <TourContent title="Add a new client" description="Quickly add a new client to your client list." />,
  },
  {
    key: 'client.options-btn',
    selector: '[data-tour="options-btn"]',
    content: (
      <TourContent
        title="Import Your Client List"
        description="Click here to have the option to import your client list"
      />
    ),
  },
];

export const clientImportSteps = [
  {
    key: 'clientImport.templates',
    selector: '[data-tour="templates"]',
    content: (
      <TourContent
        title="Select a List Template"
        description="You can download pre-made client list templates to enter your client details. For your comfort, we have provided Fresha and Vagaro samples to ease the migration from them. You can download pre-made client list templates to enter your client details. For your comfort, we have provided Fresha and Vagaro samples to ease the migration from them. "
      />
    ),
  },
  {
    key: 'clientImport.sample-btn',
    selector: '[data-tour="sample-btn"]',
    content: (
      <TourContent
        title="Download the Template"
        description="Once you chose the format, download the import file and fill in the client details. "
      />
    ),
  },
  {
    key: 'clientImport.upload-btn',
    selector: '[data-tour="upload-btn"]',
    content: (
      <TourContent
        title="Upload the Client List!"
        description="When finished, upload the client list here to complete the client import process."
      />
    ),
  },
];

export const servicesSteps = [
  {
    key: 'services.list',
    selector: '[data-tour="list"]',
    content: (
      <TourContent
        title="This Is Your Service List!"
        description="You can have a look at all your services and add, remove, or update them."
      />
    ),
  },
  {
    key: 'services.add-btn',
    selector: '[data-tour="add-btn"]',
    content: (
      <TourContent
        title="Add a New Service"
        description="By clicking here, you can add a new service to your service list."
      />
    ),
  },
];

export const serviceFormSteps = [
  {
    key: 'serviceForm.basic',
    selector: '[data-tour="basic"]',
    content: (
      <TourContent
        title="Fill In the Basics"
        description="Select the right category and provide the basic details of the service."
      />
    ),
  },
  {
    key: 'serviceForm.price',
    selector: '[data-tour="price"]',
    content: (
      <TourContent
        title="Tier Level, Pricing, and Duration"
        description="Choose the tier level to assign the right stylists, set the pricing details and choose the suitable duration of the service."
      />
    ),
  },
  {
    key: 'serviceForm.staff',
    selector: '[data-tour="staff"]',
    content: (
      <TourContent
        title="Assign the right staff"
        description="This is a shortlist of the staff matching your tier level of choice. You can uncheck each staff member if you want."
      />
    ),
  },
];

export const staffCloseDateSteps = [
  {
    key: 'staffCloseDate.list',
    selector: '[data-tour="list"]',
    content: (
      <TourContent
        title="Assign Blocked Dates"
        description="For each staff, you can block accepting bookings for certain periods of special holidays, medical leaves, time-offs and etc."
      />
    ),
  },
  {
    key: 'staffCloseDate.add-btn',
    selector: '[data-tour="add-btn"]',
    content: (
      <TourContent title="Add a Block Date" description="To add a new blocked date for a staff member, click here." />
    ),
  },
];

export const staffCommissionsSteps = [
  {
    key: 'staffCommissions.list',
    selector: '[data-tour="list"]',
    content: (
      <TourContent
        title="Set Commission Rates"
        description="From this menu, you can assign unique commission rates for each staff member to ease the payment calculation."
      />
    ),
  },
  {
    key: 'staffCommissions.filters',
    selector: '[data-tour="filters"]',
    content: (
      <TourContent
        title="Commission FIlters"
        description="Use the staff filter to view every commission rate created for each staff in a single month."
      />
    ),
  },
  {
    key: 'staffCommissions.total',
    selector: '[data-tour="total"]',
    content: (
      <TourContent
        title="Total Commissions"
        description="You can view the total amount of commission fee earned by every client here."
      />
    ),
  },
];

export const staffWorkingHoursSteps = [
  {
    key: 'staffWorkingHours.list',
    selector: '[data-tour="hours-list"]',
    content: (
      <TourContent
        title="Set Working Hours"
        description="In this menu, you can set each staff's working hours for every single day of the week seperately."
      />
    ),
  },
  {
    key: 'staffWorkingHours.item',
    selector: '[data-tour="item"]',
    content: (
      <TourContent
        title="Change each day"
        description="By clicking on each day, you can edit the working hours for each client."
      />
    ),
  },
];

export const staffMembersSteps = [
  {
    access: 'staff.crud.view',
    key: 'staffMembers.list',
    selector: '[data-tour="list"]',
    content: (
      <TourContent
        title="Your Staff Are Here"
        description="This is your staff members list. You can manage every detail from every staff right here!"
      />
    ),
  },
  {
    access: 'staff.crud.modify',
    key: 'staffMembers.add-btn',
    selector: '[data-tour="add-btn"]',
    content: (
      <TourContent
        title="Add a New Staff Member"
        description="Click here to add new staff to your staff member list."
      />
    ),
  },
  {
    key: 'staffMembers.tabs',
    selector: '[data-tour="tabs"]',
    content: (
      <TourContent
        title="Staff List Tabs"
        description="You can navigate between staff list tabs to change every staff's personal details, working hours, commission rate and blocked dates."
      />
    ),
  },
];

export const calendarBlockedDateSteps = [
  {
    key: 'calendar.blocked-date',
    content: (
      <TourContent
        title="Add a blocked time"
        description={`You can block a staff member's availability for a period of time. After clicking on "New Blocked TIme", click and drag the mouse to highlight a period of time. Next, fill the form  and finalize the blocked time. `}
      />
    ),
  },
];

export const calendarSteps = [
  {
    key: 'calendar.calendar',
    selector: '[data-tour="calendar"]',
    content: (
      <TourContent
        title="This Is Your Calendar!"
        description="Here you can view and manage all your appointments, with separated sections for each stylist. "
      />
    ),
  },
  {
    key: 'calendar.navigation',
    selector: '[data-tour="navigation"]',
    content: (
      <TourContent
        title="Change The Date"
        description="You can look at any date in your calendar by changing the date here."
      />
    ),
  },
  {
    key: 'calendar.views',
    selector: '[data-tour="views"]',
    content: (
      <TourContent
        title="Daily, Weekly or Monthly View"
        description="Have a look at today's bookings or try looking at all the appointments from this week or month in one view."
      />
    ),
  },
  {
    key: 'calendar.add-btn',
    selector: '[data-tour="add-btn"]',
    content: (
      <TourContent
        title="Add An Appointment"
        description="By clicking here, you can manually add a reservation to your calendar, block a period from accepting bookings and have the option to import your Google Calendar. "
      />
    ),
  },
  {
    key: 'calendar.filter-staff',
    selector: '[data-tour="filter-staff"]',
    content: (
      <TourContent
        title="Staff Filters"
        description="Pick a staff to focus on appointment details from a certain stylist."
      />
    ),
  },
];

export const appointmentFormSteps = [
  {
    key: 'appointmentForm.date',
    selector: '[data-tour="date"]',
    content: <TourContent title="Select a date" description="Set the date of the appointment here." />,
  },
  {
    key: 'appointmentForm.appointment-line',
    selector: '[data-tour="appointment-line"]',
    content: (
      <TourContent title="Set the Details" description="Fill the rest of the important details of the appointment. " />
    ),
  },
  {
    key: 'appointmentForm.client-search',
    selector: '[data-tour="client-search"]',
    content: (
      <TourContent
        title="Assign the Client"
        description="If this is a booking for an existing client, search through your client list and add them to the appointment. "
      />
    ),
  },
  {
    key: 'appointmentForm.client-add',
    selector: '[data-tour="client-add"]',
    content: (
      <TourContent
        title="Add a New Client"
        description="If this is for a new client, simply add them by clicking here!"
      />
    ),
  },
  {
    key: 'appointmentForm.client-list',
    selector: '[data-tour="client-list"]',
    content: (
      <TourContent
        title="Your Client List"
        description="This is a list of every client that has booked you, clients that you created manually, and clients that you imported to Beautster."
      />
    ),
  },
  {
    key: 'appointmentForm.client-walk-in',
    selector: '[data-tour="client-walk-in"]',
    content: (
      <TourContent
        title="Walk-In Clients"
        description="To quickly add a client that just walked in, enter their name here to create the appointment without adding them to your client list. "
      />
    ),
  },
];
