import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { DiscountTypeChoice, InvoiceStatusChoice } from '../../../utilities/constants/choices';
import { getPriceFormat } from '../../../utilities/Price';

const InvoiceItem = ({ page_url, item, index, accessClient, onClick }) => {
  const servicesLen = useMemo(() => item.lines.filter((i) => i.item_type === 'service').length, [item]);
  const giftCardsLen = useMemo(() => item.lines.filter((i) => i.item_type === 'gift_card').length, [item]);
  const [staffs, discountTypes] = useMemo(() => {
    const staffs = item.lines.reduce((acc, p) => {
      const item = acc.find((i) => i.staff === p.staff && p.staff_name);
      if (item) return acc;
      return [...acc, p];
    }, []);
    const discountsItem = item.lines
      .filter((i) => !!i.discount_type)
      .map((i) => i.discount_type)
      .reduce((acc, i) => (acc.indexOf(i) > -1 ? acc : [...acc, i]), []);
    return [staffs, discountsItem || []];
  }, [item]);

  // const paymentsBy = useMemo(() => {
  //     return item.transactions.reduce((acc, p) => {
  //         const item = acc.find(i => i.method === p.method);
  //         if(item || p.type !== 'payment') return acc;
  //         return [...acc, p];
  //     },[]);
  // }, [item]);
  // const refundsBy = useMemo(() => {
  //     return item.transactions.reduce((acc, p) => {
  //         const item = acc.find(i => i.method === p.method);
  //         if(item || p.type !== 'refund') return acc;
  //         return [...acc, p];
  //     },[]);
  // }, [item]);

  const getDate = (transactions) => {
    const date = transactions[transactions.length - 1].created_at;
    const timeArr = date.split(' ');
    const dateArr = date.split(' ');
    dateArr.splice(dateArr.length - 2);
    timeArr.splice(0, timeArr.length - 2);
    return (
      <small>
        {dateArr.join(' ')}
        <br />
        {timeArr.join(' ')}
      </small>
    );
  };

  return (
    <tr>
      <td>
        {!!item.client ? (
          accessClient ? (
            <NavLink to={`/clients/${item.client}`}>{item.client_name}</NavLink>
          ) : (
            item.client_name
          )
        ) : (
          <span>{item.client_name}</span>
        )}
      </td>
      {/*<td>{item.lines.map(l => l.item_type === 'service' && <span key={l.id} className="badge badge-secondary mr-1">{l.item_name}</span>)}</td>*/}
      <td>
        {servicesLen > 0 && (
          <small className="font-weight-bold px-1">
            {servicesLen} Service{servicesLen > 1 ? 's' : ''}
          </small>
        )}
        {giftCardsLen > 0 && (
          <small className="font-weight-bold text-info px-1">
            {giftCardsLen} Gift Card{giftCardsLen > 1 ? 's' : ''}
          </small>
        )}
      </td>
      {/*<td>{getFormatBriefDate(item.created_at, true)}</td>*/}
      <td className="text-center">{item.transactions.length > 0 ? getDate(item.transactions) : '-'}</td>
      <td className="text-center">
        {staffs.map((l) => (
          <>
            <span key={l.id} className="badge badge-secondary mr-1">
              {l.staff_name}
            </span>
            <br />
          </>
        ))}
      </td>
      <td className="text-center">
        <span
          className={classnames('badge badge-primary', {
            'badge-success': item.status === 'paid',
            'badge-dark': item.status === 'unpaid',
            'badge-info': item.status === 'part_paid',
            'badge-warning': item.status === 'part_refunded',
            'badge-danger': item.status === 'refunded' || (item?.status || '').includes('cancel'),
          })}
        >
          {InvoiceStatusChoice[item.status]}
        </span>
      </td>
      <td className="text-center">
        {discountTypes.length === 0
          ? '-'
          : discountTypes.map((i, _) => `${DiscountTypeChoice[i]}${_ + 1 !== discountTypes.length ? ' & ' : ''}`)}
      </td>
      <td
        className={classnames('text-center', {
          'font-weight-bold text-success': +item.total_tips > 0,
        })}
      >
        {+item.total_tips > 0 && '+'}
        {getPriceFormat(+item.total_tips, '$')}
      </td>
      {/*<td className="text-center">*/}
      {/*    {paymentsBy.length > 0 ? paymentsBy.map(p => (*/}
      {/*        <span key={p.id} className="badge badge-success mr-1">*/}
      {/*            {InvoicePaymentTypeChoice[p.method]}*/}
      {/*        </span>*/}
      {/*    )) : '-'}*/}
      {/*</td>*/}
      {/*<td className="text-center">*/}
      {/*    {refundsBy.length > 0 ? refundsBy.map(p => (*/}
      {/*        <span key={p.id} className="badge badge-danger mr-1">*/}
      {/*            {InvoicePaymentTypeChoice[p.method]}*/}
      {/*        </span>*/}
      {/*    )) : '-'}*/}
      {/*</td>*/}
      <td
        className={classnames('text-center', {
          'font-weight-bold text-success': +item.total > 0,
        })}
      >
        {+item.total > 0 && '+'}
        {getPriceFormat(+item.total)}
      </td>
      <td
        className={classnames('text-center', {
          'font-weight-bold text-danger': +item.total_refunds > 0,
        })}
      >
        {+item.total_refunds > 0 && '-'}
        {getPriceFormat(+item.total_refunds)}
      </td>
      <td
        className={classnames('text-center', {
          'font-weight-bold text-success': +item.total - +item.total_refunds > 0,
        })}
      >
        {getPriceFormat(+item.total - +item.total_refunds)}
      </td>
      <td>
        <NavLink id={`invoiceLink-${index}`} onClick={onClick} to={`${page_url}/${item.id}`}>
          Detail
        </NavLink>
      </td>
    </tr>
  );
};

InvoiceItem.propTypes = {
  accessClient: PropTypes.bool,
  page_url: PropTypes.string,
  index: PropTypes.number,
  item: PropTypes.any,
  onClick: PropTypes.func,
};

export default InvoiceItem;
