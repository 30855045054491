import React, { useEffect, useState } from 'react';
import toaster from 'toasted-notes';
import Select from '../UIKit/Form/Select';
import { getServiceList } from '../../../api';
import { ServiceTieredLevelChoice } from '../../../utilities/constants/choices';
import { Controller } from 'react-hook-form';
import { handleApi } from '../../../utilities/useApi';
import usePermission from '../../../utilities/usePermission';

// eslint-disable-next-line react/prop-types
export const ControlServiceSelect = ({ control, name, rules, defaultValue, id, onChangeValue, ...selectProps }) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    render={({ onChange, onBlur, value }, { invalid }) => (
      <ServiceSelect
        {...selectProps}
        id={id ? id : name}
        onChangeValue={(val) => {
          onChange(val);
          if (onChangeValue) onChangeValue(val);
        }}
        onBlur={onBlur}
        value={value}
        error={!!invalid}
      />
    )}
  />
);

// eslint-disable-next-line react/prop-types
const ServiceSelect = ({ onChange, onChangeValue, value, onServices, ...otherProps }) => {
  const [isSet, setIsSet] = useState(false);
  const [data, setData] = useState(null);
  const { access: _service } = usePermission('service', 'crud', 'view');

  const handleChange = (val) => {
    if (onChangeValue) onChangeValue(val.value);
    if (onChange) onChange(val);
  };

  useEffect(() => {
    // const storage = HandleCatch.getItem('services');
    if (_service && !data) {
      const storage = false;
      if (storage) {
        setData(storage);
      } else {
        handleApi(getServiceList, {}, { defaultData: null, infinitePage: true }).then((res) => {
          setData(res);
          // HandleCatch.setItem('services', res);
          if (res.results.length === 0)
            toaster.notify(() => (
              <div className="alert alert-danger mt-2">
                No Service Exists, Please add first service from `Services` page
              </div>
            ));
        });
      }
    }
  }, [_service]);

  const services =
    data && data.results
      ? data.results.map(({ id, name, tiered_level, providers, ...service }) => ({
          label: `${name}${
            !!ServiceTieredLevelChoice[tiered_level] ? ` - ${ServiceTieredLevelChoice[tiered_level]}` : ''
          }`,
          value: id,
          staff: providers,
          tiered_level,
          ...service,
        }))
      : [];

  useEffect(() => {
    if (onServices && services.length > 0 && !isSet) {
      onServices(services);
      setIsSet(true);
    }
  }, [services, isSet]);

  return (
    <Select
      id={'input-service'}
      isSearchable
      label={'Service'}
      value={value}
      onChange={handleChange}
      placeholder={'Select Service'}
      options={services}
      {...otherProps}
    />
  );
  // return null;
};

export default ServiceSelect;
