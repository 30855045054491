import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import LmoObject from '../objects/LmoObject';
const { GET, POST, PUT, DELETE } = ApiMethods;

const getParams = (params) => {
  if (!!params.image) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (key === 'services') {
        params[key].forEach((s) => formData.append('services', s));
      } else if (key === 'campaign') {
        Object.keys(params[key]).forEach((s) => formData.append(`campaign.${s}`, params[key][s]));
      } else if (!!params[key] && key !== 'image') formData.append(key, params[key]);
    });
    formData.append('image', params.image.file, params.image.file.name);
    return formData;
  }
  return params;
};

/**
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to,
 *     campaign_status,
 *     expire_after,
 * }}
 */
export const createLmo = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.lmo.create,
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new LmoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     inform_to,
 *     campaign_status,
 *     expire_after,
 * }}
 */
export const updateLmo = async (id, params) => {
  const response = await callApi({
    method: PUT,
    url: createURL(ApiRoutes.lmo.update, { id }),
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new LmoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getLmoList = async ({ search, page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.lmo.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new LmoObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getLmo = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.lmo.get, { id }),
  });
  if (response.data) {
    return new LmoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteLmo = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.lmo.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 **/
export const publishLmo = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.lmo.publish, { id }),
    params: {},
  });
  if (response.data) {
    return new LmoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 **/
export const unPublishLmo = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.lmo.unpublish, { id }),
    params: {},
  });
  if (response.data) {
    return new LmoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};
