import React, { useContext, useEffect, useMemo } from 'react';
import toaster from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import Button from '../../Common/UIKit/Form/Button';
import { handleApiSave, handleApi } from '../../../utilities/useApi';
import { cancelSubscription, getBusiness } from '../../../api';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';

const SubscriptionSettingsPage = () => {
  const { business, user, setHeader, setBusiness, userPermissions: p } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    setHeader('Settings / Subscription');
  }, [setHeader]);

  const handleCancelSubscription = () => {
    confirmAlert({
      title: 'Cancel your Subscription',
      message:
        'Are you ABSOLUTELY sure? This action cannot be undone. Your subscription will be cancelled permanently.',
      buttons: [
        {
          label: 'Yes Cancel',
          className: 'bg-danger',
          onClick: () => {
            handleApiSave(cancelSubscription, null, {}, null, { loadingFn: () => {} })
              .then(() => {
                handleApi(getBusiness, {}, { list: false }).then(setBusiness);
                toaster.notify(() => (
                  <div className="alert alert-success mt-3">You cancel Subscription Successfully</div>
                ));
                history.replace('/');
              })
              .catch(() => {});
          },
        },
        {
          label: 'No Keep',
          onClick: () => {},
        },
      ],
    });
  };

  const _access = useMemo(
    () => p?.business?.cancel_subscription?.modify || p?.business?.cancel_subscription?.view,
    [p],
  );

  const { isOwner } = usePermission();

  if (!isOwner && !_access) return <NotFoundData title="Access Denied" />;
  return business?.subscription_status === 'canceled' ? (
    <NotFoundData
      title="You have canceled your subscription"
      description={`Current subscription will be end at ${business?.subscription_end_at}`}
    />
  ) : business?.subscribed ? (
    <div className="c-panel-content mt-4">
      <div className="p-4">
        <h3 className="text-danger">Cancel your Subscription</h3>

        <p className="c-settings-marketplace__p">Cancellations are effective immediately.</p>

        <p className="c-settings-marketplace__p">
          Cancelling your subscription means you’ll lose access to all related data.
        </p>

        <p className="c-settings-marketplace__p">
          Click below if you are <span className="text-info">ABSOLUTELY</span> sure you wish to{' '}
          <span className="text-danger">CANCEL</span> your subscription.
        </p>

        {(_access?.modify || isOwner) && (
          <Button color="danger" onClick={handleCancelSubscription}>
            YES, Cancel Subscription
          </Button>
        )}
      </div>
    </div>
  ) : user?.trialing ? (
    <NotFoundData
      icon=""
      title="Subscribe today"
      description={
        <>
          <p className="text-success">
            <strong>You are using trial mode</strong>
          </p>
          <p>
            <strong>Subscribe new plan</strong>
          </p>
        </>
      }
      button={
        <Button lg link="/settings/plans">
          Subscribe Plan
        </Button>
      }
    />
  ) : (
    <NotFoundData
      icon=""
      title="Subscribe today"
      description={
        <>
          <p className="text-danger">
            <strong>Your subscription has been expired</strong>
          </p>
          <p>
            <strong>Subscribe new plan</strong>
          </p>
        </>
      }
      button={
        <Button lg link="/settings/plans">
          Subscribe Plan
        </Button>
      }
    />
  );
};

export default SubscriptionSettingsPage;
