import React, { useContext, useMemo, useState } from 'react';
import MapSelectPosition from '../../UIKit/Map/MapSelectPosition';
import { AppContext } from '../../../../context/providers';
import { handleApiSave } from '../../../../utilities/useApi';
import { partialUpdateBusiness } from '../../../../api';
import toaster from 'toasted-notes';
import Button from '../../UIKit/Form/Button';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceLocation = ({ isNew = false, onNext, onPrev }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [data, setData] = useState({ lat: 51.041493, lng: -114.066613 });
  const { business, setBusiness, newBusiness, onNewBusiness } = useContext(AppContext);
  const defaultCenter = useMemo(() => {
    if (isNew && newBusiness) {
      const lat = +newBusiness.latitude;
      const lng = +newBusiness.longitude;
      if (lng === 0 || lat === 0) return null;
      return { lat, lng };
    } else if (business) {
      const lat = +business.latitude;
      const lng = +business.longitude;
      if (lng === 0 || lat === 0) return null;
      return { lat, lng };
    }
    return null;
  }, [business]);

  const handleSave = () => {
    // const body = {
    //   profile: {
    //     latitude: +data.lat.toFixed(6),
    //     longitude: +data.lng.toFixed(6),
    //     // latitude: 51.041493,
    //     // longitude: -114.066613,
    //   },
    // };
    const body = {
      businessId: isNew ? newBusiness.id : undefined,
      latitude: +data.lat.toFixed(6),
      longitude: +data.lng.toFixed(6),
    };

    if (data?.data?.address_components) {
      const address_components = data?.data?.address_components;
      const inter_phone_number = data?.data?.international_phone_number;
      const zip_code = address_components.find((i) => i.types.find((j) => j === 'postal_code'));
      const country = address_components.find((i) => i.types.find((j) => j === 'country'));
      const city = address_components.find((i) => i.types.find((j) => j === 'locality'));
      const phone_number = inter_phone_number
        ? inter_phone_number.replaceAll(' ', '').replaceAll('-', '')
        : // .replaceAll('+', '')
          undefined;
      body.address = data.data.formatted_address;

      if (country) body.country = country.long_name;
      if (city) body.city = city.long_name;

      if (data?.data?.business_status) {
        if (zip_code) body.zip_code = zip_code.long_name.replaceAll(' ', '');
        if (data.data.lat && data.data.lng) {
          body.latitude = +data.data.lat.toFixed(6);
          body.longitude = +data.data.lng.toFixed(6);
        }

        body.phone_number = phone_number;
        body.website_address = data.data.website_address;
        body.google_place_id = data.data.place_id;
      }
    }
    handleApiSave(partialUpdateBusiness, null, body, null, { loadingFn: setSaveLoading })
      .then((res) => {
        if (isNew) onNewBusiness(res);
        else setBusiness(res);
        toaster.notify(() => <div className="alert alert-success">Your location has been updated successfully</div>);
        onNext();
      })
      .catch(() => {});
  };
  return (
    <>
      {data?.data && data?.data?.business_status && (
        <div className="alert alert-info mb-3">
          Business Name: <strong>{data.data.name}</strong> <br />
          Address: <strong>{data.data.formatted_address}</strong> <br />
          Phone: <strong>{data.data.formatted_phone_number}</strong>
        </div>
      )}
      <div className="alert alert-warning">
        Drag the map so the red pin matches the exact location of your business location or Find your business location
        with search it in search field and press &apos;Next Step&apos; button. It helps client to find your business on
        map.
      </div>
      <MapSelectPosition
        className="c-setup-system__map"
        center={defaultCenter}
        onChange={(e) => setData({ ...data, ...e })}
      />
      <div className="d-flex align-center mt-5">
        <Button type="button" color="light" onClick={onPrev} className="mr-auto">
          Previous Step
        </Button>
        <Button onClick={handleSave} loading={saveLoading} className="ml-auto">
          Next Step
        </Button>
      </div>
    </>
  );
};

export default SetupMarketplaceLocation;
