import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import ClosedDateObject from '../objects/ClosedDateObject';

const { GET, POST, PUT, DELETE } = ApiMethods;

/**
 * @param params {{
 *     title,
 *     start_time,
 *     end_time,
 * }}
 */
export const createClosedDate = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.businessClosedDates.create,
  });
  if (response.data) {
    return new ClosedDateObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     title,
 *     start_time,
 *     end_time,
 * }}
 */
export const updateClosedDate = async (id, params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: createURL(ApiRoutes.businessClosedDates.update, { id }),
  });
  if (response.data) {
    return new ClosedDateObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param page
 * @param limit
 * @param url
 */
export const getClosedDateList = async ({ page, limit, ...otherParams } = {}) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
      ...otherParams,
    },
    url: ApiRoutes.businessClosedDates.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data?.results || []).map((i) => new ClosedDateObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

export const getClosedDate = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.businessClosedDates.get, { id }),
  });
  if (response.data) {
    return new ClosedDateObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const deleteClosedDate = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.businessClosedDates.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
