import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { getPlans, getProvince } from '../../../api';
import useApi, { handleApi } from '../../../utilities/useApi';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { StripeModal } from '../../Common/Stripe';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import SoftwarePlanItem from '../../Common/SystemSetup/SoftwarePlanItem';
import { AppContext } from '../../../context/providers';
// import HeadPage from "../../Common/Layouts/HeadPage/HeadPage";

const SettingPlansPage = () => {
  const [taxRate, setTaxRate] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [data, loading] = useApi(getPlans);
  const { setHeader, business } = useContext(AppContext);
  setHeader('Settings / Plans');

  useEffect(() => {
    const provinceId = business?.profile?.province;
    if (provinceId) {
      handleApi(getProvince, { id: provinceId }, { list: false }).then((res) => {
        setTaxRate(+res.tax_rate);
      });
    }
  }, [business]);

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const query = getQueryUri();

  useEffect(() => {
    // if(query) history.replace(match.url)
    // if(query?.session_id) {
    //     setSessionId(query);
    // }
    // console.log('query', query);
    // console.log('query', query);
    if (query?.session_id) {
      if (query?.status) {
        setSelectedItemId(null);
        setSessionId(null);
      } else {
        if (query?.item) setSelectedItemId(query.item);
        setSessionId(query.session_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleOpenStrip = (session_id, itemId) => {
    history.push(match.url + setQueryUri({ session_id, item: itemId }));
  };

  const handleCloseStrip = () => {
    history.replace(match.url);
    setSessionId(null);
    setSelectedItemId(null);
  };

  const selectedItem = useMemo(() => {
    if (data) {
      const ItemIndex = data?.results.findIndex((i) => +i.id === +selectedItemId);
      if (ItemIndex > -1) {
        const item = data?.results[ItemIndex];
        return {
          ...item,
          theme: ItemIndex % 2 ? 'light' : 'dark',
        };
      }
      return null;
    }
    return null;
  }, [selectedItemId, data]);

  return (
    <>
      {sessionId && (
        <StripeModal
          session_id={sessionId}
          onClose={handleCloseStrip}
          component={selectedItem ? <SoftwarePlanItem item={selectedItem} selected theme={selectedItem.theme} /> : null}
        />
      )}
      {/*<HeadPage title={'Settings / Plans'} />*/}
      {loading ? (
        <PageLoading />
      ) : (
        <div className="c-settings-plans py-5">
          {data?.results.map((plan, ind) => (
            <SoftwarePlanItem
              taxRate={taxRate}
              key={plan.id}
              item={plan}
              theme={ind % 2 ? 'light' : 'dark'}
              onSessionId={handleOpenStrip}
            />
          ))}
        </div>
      )}
      {/*<PlanItem title="Marketer" theme="light" list={plan2} />*/}
      {/*<PlanItem title="Growth" theme="dark" list={plan1} />*/}
    </>
  );
};
//
// const plan1 = [
//     { active: true, label: 'Business Page on Beautster' },
//     { active: true, label: 'List Bookable Work Photo on Marketplace', value: '50 Photos' },
//     { active: true, label: 'Posting Deals on Beautster', value: '3 Deal' },
//     { active: true, label: 'Online Booking' },
//     { active: true, label: 'SMS & Email Reminders' },
//     { active: true, label: 'Client Booking History & Records' },
//     { active: true, label: 'Accept Online Payments' },
//     { active: true, label: 'Auto Repost Business Profile & Service Menu on Kijiji (Daily)\t' },
// ];
//
// const plan2 = [
//     { active: true, label: 'Business Page on Beautster' },
//     { active: true, label: 'List Bookable Work Photo on Marketplace', value: '30 Photos' },
//     { active: true, label: 'Posting Deals on Beautster', value: '1 Deal' },
//     { active: true, label: 'Online Booking' },
//     { active: true, label: 'SMS & Email Reminders' },
//     { active: true, label: 'Client Booking History & Records' },
//     { active: true, label: 'Accept Online Payments' },
// ];

export default SettingPlansPage;
