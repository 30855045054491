/**
 *
 * @param notification {{
 *     id,
 *     services,
 * }}
 * @constructor
 */

function NotificationObject(notification) {
  this.id = notification.id;
  this.services = notification.services;
}

export default NotificationObject;
