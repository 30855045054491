import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../UIKit/Modal';
import { useForm } from 'react-hook-form';
import { ControlInput } from '../../UIKit/Form/Input';
import { ControlDatePicker } from '../../UIKit/Form/DatePicker';
import { getStringMonthFormat } from '../StylistProfileModal';
import { handleApiSave } from '../../../../utilities/useApi';
import { saveExperience } from '../../../../api';

/**
 * @description if 'shouldSave' was 'false', form just pass the data to the parent and do not save it in server
 */
const ExperienceFormModal = ({ data = null, shouldSave = true, onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const { control, errors, setError, handleSubmit } = useForm();
  const handleSave = (body) => {
    if (shouldSave) {
      onSave({ id: `create_${new Date().getTime()}`, ...data, ...body });
    } else {
      const bodyApi = { ...data, ...body };
      if ((bodyApi.id || '').includes('create')) delete bodyApi.id;
      bodyApi.start_date = getStringMonthFormat(bodyApi.start_date);
      bodyApi.end_date = getStringMonthFormat(bodyApi.end_date);
      handleApiSave(saveExperience, undefined, bodyApi, null, { loadingFn: setSaveLoading, setError }).then((res) => {
        console.log(res);
        onSave(res);
      });
    }
  };
  return (
    <Modal
      show
      sticky
      title={data ? 'Edit Experience' : 'New Experience'}
      saveLoading={saveLoading}
      onClose={onClose}
      onSave={handleSubmit(handleSave)}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <ControlInput
          control={control}
          name="job_title"
          defaultValue={!!data?.job_title ? data.job_title : ''}
          label="Job Title"
          placeholder="Job Title"
          rules={{ required: 'Job Title is required' }}
          errorMessage={errors?.job_title?.message}
        />
        <ControlInput
          control={control}
          name="salon"
          defaultValue={!!data?.salon ? data.salon : ''}
          label="Salon Name"
          placeholder="Salon Name"
          rules={{ required: 'Salon Name is required' }}
          errorMessage={errors?.salon?.message}
        />
        <ControlInput
          control={control}
          name="city"
          defaultValue={!!data?.city ? data.city : ''}
          label="City"
          placeholder="City"
          rules={{ required: 'City is required' }}
          errorMessage={errors?.city?.message}
        />
        <div className="d-flex align-row">
          <ControlDatePicker
            control={control}
            formClassName="w-50 mr-2"
            name="start_date"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            popperPlacement="top-end"
            defaultValue={!!data?.start_date ? data.start_date : ''}
            label="Start Date"
            placeholder="Start Date"
            rules={{ required: 'Start Date is required' }}
            errorMessage={errors?.start_date?.message}
          />
          <ControlDatePicker
            control={control}
            formClassName="w-50 ml-2"
            name="end_date"
            defaultValue={!!data?.end_date ? data.end_date : ''}
            label="End Date"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            popperPlacement="top-end"
            placeholder="End Date"
            rules={{ required: 'End Date is required' }}
            errorMessage={errors?.end_date?.message}
          />
        </div>
        <ControlInput
          type="textarea"
          control={control}
          name="description"
          defaultValue={!!data?.description ? data.description : ''}
          label="Description"
          placeholder="Description"
          errorMessage={errors?.description?.message}
        />
      </form>
    </Modal>
  );
};

ExperienceFormModal.propTypes = {
  data: PropTypes.any,
  shouldSave: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default ExperienceFormModal;
