import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../UIKit/Form/Button';
import { STEPS_TYPES, USER_TYPE_KEYS, UserTypeOptions } from '../../../../utilities/constants/setup';
import Image from '../../UIKit/Image';

const UserItem = ({ active, id, title, image, text, onClick }) => (
  <div
    className={classNames('c-panel-setup__user-type', {
      active: active,
    })}
    onClick={() => onClick(id)}
  >
    <Image className="c-panel-setup__user-type__img" src={image} alt={title} />
    <h4>{title}</h4>
    <p>{text}</p>
  </div>
);

UserItem.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

const SetupOnBoarding = ({ onSubmit }) => {
  const [type, setType] = useState(null);

  const handleSubmit = () => {
    const nextStep = type === USER_TYPE_KEYS.STYLIST ? STEPS_TYPES.SETUP_STYLIST : STEPS_TYPES.QUESTIONS;
    onSubmit(nextStep);
  };

  return (
    <>
      <div className="c-panel-setup__question-container">
        <div className="c-panel-setup__question-wrapper">
          <h1>Who are you?</h1>
          <div className="c-panel-setup__user-types">
            {UserTypeOptions.map((i) => (
              <UserItem
                key={i.id}
                active={i.id === type}
                id={i.id}
                title={i.title}
                text={i.text}
                image={i.image}
                onClick={setType}
              />
            ))}
          </div>
          <Button lg disabled={!type} className="mt-5 mx-auto" onClick={handleSubmit}>
            Next Step
          </Button>
        </div>
      </div>
    </>
  );
};

SetupOnBoarding.propTypes = {
  onSubmit: PropTypes.func,
};

export default SetupOnBoarding;
