import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { PhotoLibrary } from '@material-ui/icons';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import useApi from '../../../utilities/useApi';
import { getBookablePhotoList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import Button from '../../Common/UIKit/Form/Button';
import BookablePhotoModal from '../../Common/BookablePhoto/BookablePhotoModal';
import BookablePhotoItem from '../../Common/BookablePhoto/BookablePhotoItem';
import { AppContext } from '../../../context/providers';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import BookablePhotoSelectorModal from '../../Common/BookablePhoto/BookablePhotoSelectorModal';
import BookablePhotoInstagramModal from '../../Common/BookablePhoto/BookablePhotoInstagramModal';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/bookable_photos/';

const LIMIT = 20;

const BookablePhotosPage = ({ match: { params }, history, location }) => {
  const [imageFile, setImageFile] = useState(null);
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [showInstagramMedia, setShowInstagramMedia] = useState(false);
  const inputFile = useRef(null);

  const [, loading] = useApi(getBookablePhotoList, { page: true, limit: LIMIT, key: 'bookablephoto' }, setData);

  const { setHeader } = useContext(AppContext);
  useEffect(() => {
    setHeader('Photo Album');
  }, [setHeader]);

  useEffect(() => {
    if (params.id || (location.pathname || '').includes(`${URL_PAGE}upload`)) {
      setShowSelector(false);
      handleOpenForm();
    } else if ((location.pathname || '').includes(`${URL_PAGE}add`)) {
      handleOpenSelector();
    } else if ((location.pathname || '').includes(`${URL_PAGE}instagram`)) {
      setShowInstagramMedia(true);
    }
  }, [params, location]);

  const handleOpenSelector = () => setShowSelector(true);
  const handleCloseSelector = () => {
    setShowSelector(false);
    history.replace(URL_PAGE);
  };
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    setImageFile(null);
    if (inputFile) {
      inputFile.current.files = null;
      inputFile.current.value = null;
    }
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveItem = (item) => {
    if (typeof item === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    handleCloseForm();
    setShowInstagramMedia(false);
    getBookablePhotoList({ limit: LIMIT }).then(setData);
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageFile({ src: reader.result, file });
        history.push(`${URL_PAGE}upload`);
      };
    } else {
      handleCloseForm();
    }
  };

  const { access: _access } = usePermission('bookablephoto', 'crud');

  return (
    <>
      {showInstagramMedia && (
        <BookablePhotoInstagramModal onSave={handleSaveItem} onClose={() => setShowInstagramMedia(false)} />
      )}
      {showForm && _access?.modify ? (
        <BookablePhotoModal image={imageFile} onSave={handleSaveItem} onClose={handleCloseForm} />
      ) : null}
      {/*{showForm && !_access?.modify && _access?.view ? <BookablePhotoViewModal onClose={handleCloseForm} /> : null}*/}
      {showSelector && <BookablePhotoSelectorModal onClose={handleCloseSelector} />}
      <HeadOptions
        rightElement={
          _access?.modify && (
            <Button sm link="/bookable_photos/add/">
              ADD NEW PHOTO
            </Button>
          )
        }
      />
      {_access?.modify && (
        <input
          type="file"
          id="file"
          accept="image/x-png,image/jpg,image/jpeg"
          ref={inputFile}
          className="d-none"
          onChange={handleChangeFile}
        />
      )}
      {_access?.view ? (
        <>
          {loading && !data ? (
            <PageLoading />
          ) : data?.results && data.results.length > 0 ? (
            <>
              {loading && <PageLoading over />}
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}>
                <Masonry>
                  {data.results.map((item) => (
                    <BookablePhotoItem
                      key={item.id}
                      item={item}
                      isModify={_access?.modify}
                      page_url={URL_PAGE}
                      onSave={handleSaveItem}
                    />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
              {data && <Pagination limit={LIMIT} count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Photo Exists"
              description="You don't have any photo in album"
              icon={<PhotoLibrary />}
              button={_access?.modify ? <Button link="/bookable_photos/add/">ADD NEW PHOTO</Button> : null}
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

BookablePhotosPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
};

export default BookablePhotosPage;
