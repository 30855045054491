/**
 *
 * @param education {{
 *     id: String,
 *     start_date: String,
 *     end_date: String,
 *     school: String,
 *     degree: String,
 *     city: String,
 *     description: String,
 * }}
 * @constructor
 */

function EducationObject(education) {
  this.id = education.id;
  this.school = education.school;
  this.start_date = education.start_date ? new Date(education.start_date) : null;
  this.end_date = education.end_date ? new Date(education.end_date) : null;
  this.degree = education.degree;
  this.city = education.city;
  this.description = education.description;
}

export default EducationObject;
