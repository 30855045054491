import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '../UIKit/Form/Button';
import ModalFormSidebarFooter from '../ModalForm/ModalFormSidebarFooter';
import { ModalFormSidebarContent, ModalFormSidebarHeader } from '../ModalForm/ModalFormSidebar';
import Modal from '../../Common/UIKit/Modal';

const stripeToken = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const stripePromise = loadStripe(stripeToken);

// eslint-disable-next-line react/prop-types
const CheckoutForm = ({ sessionId = null, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let data = null;
    if (sessionId) {
      data = await stripe.redirectToCheckout({
        sessionId,
      });
    } else {
      data = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
    }

    const { paymentMethod } = data;

    if (paymentMethod && onSuccess) onSuccess(paymentMethod);

    setLoading(false);
  };

  return (
    <>
      {!sessionId && (
        <form className="c-stripe-form" onSubmit={handleSubmit}>
          <div className="c-stripe-form__content">
            <CardElement className="c-stripe-form__input" />
          </div>
        </form>
      )}
      <ModalFormSidebarFooter>
        <Button block loading={loading} onClick={handleSubmit}>
          PAY NOW
        </Button>
      </ModalFormSidebarFooter>
    </>
  );
};

// eslint-disable-next-line react/prop-types
const CheckoutRedirect = ({ sessionId = null, children = 'PAY NOW', onClick, ...props }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    await stripe.redirectToCheckout({
      sessionId,
    });

    if (onClick) onClick();

    setLoading(false);
  };

  return (
    <Button {...props} loading={loading} onClick={handleSubmit}>
      {children}
    </Button>
  );
};

const CheckoutRedirectElement = ({ sessionId = null, onClick }) => {
  const stripe = useStripe();
  const handleSubmit = async () => {
    try {
      await stripe.redirectToCheckout({
        sessionId,
      });
      if (onClick) onClick();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (stripe) {
      handleSubmit();
    }
  }, [stripe]);

  return null;
};

CheckoutRedirectElement.propTypes = {
  sessionId: PropTypes.any,
  onClick: PropTypes.any,
};

// eslint-disable-next-line react/prop-types
export const StripeSidebar = ({ onSuccess, onClose, session_id }) => {
  const stripePromise = loadStripe(session_id ? session_id : stripeToken);
  return (
    <ModalFormSidebarContent>
      <ModalFormSidebarHeader title="Online Payment" onClose={onClose} />
      <Elements stripe={stripePromise}>
        <CheckoutForm onSuccess={onSuccess} />
      </Elements>
    </ModalFormSidebarContent>
  );
};

// eslint-disable-next-line react/prop-types
export const StripeModal = ({ component = null, onClose, session_id, title }) => (
  <Modal show disableFooter onClose={onClose} title={title ? title : 'Payment'}>
    {component}
    <ModalFormSidebarFooter>
      <Elements stripe={stripePromise}>
        <CheckoutRedirect sessionId={session_id} />
      </Elements>
    </ModalFormSidebarFooter>
  </Modal>
);

// eslint-disable-next-line react/prop-types
export const StripeElement = ({ session_id }) => (
  <div>
    <Elements stripe={stripePromise}>
      <CheckoutRedirectElement sessionId={session_id} />
    </Elements>
  </div>
);

// eslint-disable-next-line react/prop-types
export const StripeButton = ({ sessionId, children, ...props }) => (
  <Elements stripe={stripePromise}>
    <CheckoutRedirect {...props} sessionId={sessionId}>
      {children}
    </CheckoutRedirect>
  </Elements>
);

export default null;
