import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getPriceFormat } from '../../../../utilities/Price';

const PromotionGiftCardItem = ({ page_url, item }) => {
  const { name, services, value, retail_price, expire_after, sold_count, usage_limit } = item;

  return (
    <tr>
      <td>{name}</td>
      <td className="text-center">
        {services.length} service{services.length > 1 && 's'}
      </td>
      <td className="text-center">{getPriceFormat(+value)}</td>
      <td className="text-center">{getPriceFormat(+retail_price)}</td>
      <td className="text-center">
        {expire_after} {+expire_after > 1 ? 'Days' : 'Day'}
      </td>
      <td className="text-center">{sold_count}</td>
      <td className="text-center">{usage_limit}</td>
      {/*<CampaignDetailText page_url={page_url} campaign={campaign} />*/}
      <td>
        <NavLink id={`link-${item.id}`} to={`${page_url}${item.id}`}>
          Manage
        </NavLink>
      </td>
    </tr>
  );
};

PromotionGiftCardItem.propTypes = {
  page_url: PropTypes.string,
  item: PropTypes.any,
};

export default PromotionGiftCardItem;
