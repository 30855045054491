import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

const SectionHead = ({ title, subTitle, description, className, closeable, backable, rightElement, onClose }) => {
  let titleClasses = 'c-section__head-title';
  if (subTitle) titleClasses = 'c-section__head-subtitle';
  return (
    <div className={`c-section__head-container`}>
      <div className={`c-section__head ${className ? className : ''}`}>
        <div className="c-section__head-content">
          {backable && (
            <div className="c-section__head-back" onClick={onClose}>
              <ArrowBack />
            </div>
          )}

          {title && <h3 className={titleClasses}>{title}</h3>}
        </div>
        <p className="c-section__head-description" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {rightElement}
      {closeable && (
        <div className="c-section__head-close" onClick={onClose}>
          <i className="fas fa-times" />
        </div>
      )}
    </div>
  );
};

SectionHead.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backable: PropTypes.bool,
  closeable: PropTypes.bool,
  rightElement: PropTypes.object,
  onClose: PropTypes.func,
  className: PropTypes.any,
};

SectionHead.defaultProps = {
  title: '',
  description: '',
  closeable: false,
  backable: false,
  rightElement: null,
};

export default SectionHead;
