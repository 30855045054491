import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from '@material-ui/icons';
import ThirdTimeRequestModel from '../../Common/ThirdTimeRequests/ThirdTimeRequestsModal';
import { getRequestServiceList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import useApi from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/requests/services/';

const RequestServicesPage = ({ match: { params }, location, history }) => {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [, loading] = useApi(getRequestServiceList, { page: true }, setData);

  const { setHeader } = useContext(AppContext);

  useEffect(() => {
    setHeader('Service Requests');
  }, [setHeader]);

  useEffect(() => {
    if (params.id) handleOpenModal();
  }, [params, location]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (opened = true) => {
    setShowModal(false);
    if (opened) history.replace(URL_PAGE);
  };

  const { access: _access } = usePermission('appointmentsuggestedtime', 'crud');

  return (
    <>
      {showModal && _access?.view && <ThirdTimeRequestModel onClose={handleCloseModal} />}
      {_access?.view ? (
        <>
          {loading && !data ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div className="c-panel-content my-3">
                {loading && <PageLoading over />}
                <div className="c-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone Number</th>
                        <th className="text-center">Service Name</th>
                        <th className="text-center">Requested At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <tr key={item.id}>
                          <td>{item.full_name}</td>
                          <td className="text-center">{item.email}</td>
                          <td className="text-center">{item.phone_number}</td>
                          <td className="text-center">{item.service_name}</td>
                          <td className="text-center">{item.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Suggestion Time Exists"
              description="You don't have any suggestion time request"
              icon={<DateRange />}
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

RequestServicesPage.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.any,
};

export default RequestServicesPage;
