import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../UIKit/Form/DatePicker';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import classnames from 'classnames';
import { getDateInfo, getFormat24H, getStringFromDate } from '../../../utilities/DateHelpers';
import useApi from '../../../utilities/useApi';
import { getBusinessWorkingHours } from '../../../api/businessWorkingHours';
import { PageLoading } from '../UIKit/Spinner';
import { getStaffHoursList, getStaffSlot } from '../../../api';

const hours = Array.apply(null, new Array(18)).reduce((acc, undefined, index) => {
  const updateAcc = [...acc];
  updateAcc.push(`${6 + index}:00`, `${6 + index}:15`, `${6 + index}:30`, `${6 + index}:45`);
  return updateAcc;
}, []);

const ThirdTimeDateSelector = ({ totalDuration, staffList, onSelectedTime }) => {
  const [newTimePage, setNewTimePage] = useState(1);
  // const [newDate, setNewDate] = useState(new Date());
  const [newDate, setNewDate] = useState(null);
  const [newTime, setNewTime] = useState(null);
  const [staffHours, setStaffHours] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [businessHours, businessHoursLoading] = useApi(getBusinessWorkingHours);

  useEffect(() => {
    if (newDate && newTime) {
      onSelectedTime({
        data: newDate,
        time: newTime,
        format: `${getStringFromDate(newDate)} ${newTime}`,
        info: getDateInfo(newDate),
      });
    }
  }, [newTime, newDate]);

  useEffect(() => {
    if ((staffList || []).length > 0) {
      const apiList = staffList.map((i) => getStaffHoursList({ staff: i }));
      Promise.all(apiList).then((res) => {
        setStaffHours(
          res.reduce((acc, { results: i }) => {
            if (acc.length === 0) return i;
            return acc.map((j) => {
              let start_time = j.start_time;
              let end_time = j.end_time;
              let is_active = j.is_active;
              const { hours: startPH, min: startPM } = getFormat24H(j.start_time, 'as-object');
              const { hours: endPH, min: endPM } = getFormat24H(j.end_time, 'as-object');
              const item = i.find((c) => c.week_day === j.week_day);
              if (item && is_active) {
                const { hours: startH, min: startM } = getFormat24H(item.start_time, 'as-object');
                const { hours: endH, min: endM } = getFormat24H(item.end_time, 'as-object');
                if (startH > startPH || (startH === startPH && startM > startPM)) start_time = item.start_time;
                if (endH < endPH || (endH === endPH && endM < endPM)) end_time = item.end_time;
                return {
                  ...j,
                  is_active: item.is_active,
                  start_time,
                  end_time,
                };
              } else {
                return j;
              }
            });
          }, []),
        );
      });
    }
  }, [staffList]);

  useEffect(() => {
    if ((staffList || []).length > 0 && newDate) {
      const apiList = staffList.map((i) =>
        getStaffSlot({ id: i, reserve_date: getStringFromDate(newDate), duration: totalDuration }),
      );
      Promise.all(apiList).then((res) => {
        setTimeSlots(
          res.reduce(
            (acc, i) => [
              ...acc,
              ...(i?.taken_time_slots || []).map((j) => ({
                start_time: j.start_time.split('T')[1].split('+')[0].replace(':00', ''),
                end_time: j.end_time.split('T')[1].split('+')[0].replace(':00', ''),
              })),
            ],
            [],
          ),
        );
      });
    }
  }, [staffList, newDate]);

  const handleChangeDate = (date) => {
    setNewDate(date);
    setNewTimePage(1);
    setNewTime(null);
  };
  const [filteredHours, allHours] = useMemo(() => {
    let result = hours;
    // Business Working Hours
    if (newDate && businessHours?.results) {
      const item = businessHours?.results.find((i) => i.week_day === getDateInfo(newDate).weekDay);
      if (item) {
        const { hours: startHour, min: startMin } = getFormat24H(item.start_time, 'as-object');
        const { hours: endHour, min: endMin } = getFormat24H(item.end_time, 'as-object');
        const startIndex = result.findIndex((i) => {
          const [hr, min] = i.split(':');
          return +startHour === +hr && +startMin === +min;
        });
        const endIndex = result.findIndex((i) => {
          const [hr, min] = i.split(':');
          return +endHour === +hr && +endMin === +min;
        });
        result = result.slice(startIndex > -1 ? startIndex : 0, endIndex > -1 ? endIndex : undefined);
      }
    }
    // Staff Working Hours
    if (newDate && staffHours) {
      const item = staffHours.find((i) => i.week_day === getDateInfo(newDate).weekDay);
      if (item) {
        if (item.is_active) {
          const { hours: startHour, min: startMin } = getFormat24H(item.start_time, 'as-object');
          const { hours: endHour, min: endMin } = getFormat24H(item.end_time, 'as-object');
          const startIndex = result.findIndex((i) => {
            const [hr, min] = i.split(':');
            return +startHour === +hr && +startMin === +min;
          });
          const endIndex = result.findIndex((i) => {
            const [hr, min] = i.split(':');
            return +endHour === +hr && +endMin === +min;
          });
          result = result.slice(startIndex > -1 ? startIndex : 0, endIndex > -1 ? endIndex : undefined);
        } else {
          result = [];
        }
      }
    }
    // Staff Taken Times Slot
    if (newDate && timeSlots.length > 0) {
      let baseSlotResults = [...result];
      timeSlots.forEach((i) => {
        if (i.start_time && i.end_time) {
          const [startHour, startMin] = i.start_time.split(':');
          const [endHour, endMin] = i.end_time.split(':');
          const startIndex = baseSlotResults.findIndex((i) => {
            const [hr, min] = i.split(':');
            return +startHour === +hr && +startMin === +min;
          });
          const endIndex = baseSlotResults.findIndex((i) => {
            const [hr, min] = i.split(':');
            return +endHour === +hr && +endMin === +min;
          });
          if (startIndex > -1) {
            baseSlotResults.splice(startIndex, endIndex > -1 ? endIndex - startIndex : 0);
          }
        }
      });
      result = baseSlotResults;
    }
    return [result.slice((newTimePage - 1) * 10, newTimePage * 10), result];
  }, [newTimePage, businessHours, newDate, timeSlots, staffHours]);

  const isWeekday = (date) => {
    if (businessHours?.results) {
      const day = date.getDay() > 0 ? date.getDay() - 1 : 6;
      const workingDays = businessHours?.results.filter((i) => i.is_active).map((i) => +i.week_day);
      return workingDays.indexOf(day) > -1;
    }
    return true;
  };

  return businessHoursLoading ? (
    <PageLoading />
  ) : (
    <div className="d-flex align-items-start mt-5">
      <DatePicker
        iconLabel={false}
        arrowLabel={false}
        selected={newDate}
        minDate={new Date()}
        onChange={handleChangeDate}
        inline
        filterDate={isWeekday}
        formGroup={false}
        customHeader={false}
      />
      {newDate && (
        <div className="c-third-time__new-times-container">
          {newTimePage > 1 && (
            <div className="c-third-time__new-times-prev" onClick={() => setNewTimePage(newTimePage - 1)}>
              <ChevronLeft />
            </div>
          )}
          {filteredHours.length > 0 ? (
            <div className="c-third-time__new-times">
              {filteredHours.map((hour) => (
                <div
                  key={hour}
                  className={classnames('c-third-time__new-times-item', {
                    'c-third-time__new-times-item--active': hour === newTime,
                  })}
                  onClick={() => setNewTime(hour)}
                >
                  {hour}
                </div>
              ))}
            </div>
          ) : (
            <div className="alert alert-warning mt-3 p-1 text-center">
              <small className="font-weight-bold">Staffs Are Not Available</small>
            </div>
          )}
          {newTimePage < allHours.length / 10 && (
            <div className="c-third-time__new-times-next" onClick={() => setNewTimePage(newTimePage + 1)}>
              <ChevronRight />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ThirdTimeDateSelector.propTypes = {
  staffList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  totalDuration: PropTypes.number,
  onSelectedTime: PropTypes.func,
};

export default ThirdTimeDateSelector;
