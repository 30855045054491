import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Modal from '../../UIKit/Modal';
import ServiceFormStaff from './ServiceFormStaff';
import ServiceFormPricing from './ServiceFormPricing';
import toaster from 'toasted-notes';
import SectionHead from '../../UIKit/SectionTitle';
import { ControlSelect } from '../../UIKit/Form/Select';
import { ControlInput } from '../../UIKit/Form/Input';
import { getCategoryList } from '../../../../api';
import { createService, deleteService, getService, getServiceList, updateService } from '../../../../api';
import { PageLoading } from '../../UIKit/Spinner';
import Button from '../../UIKit/Form/Button';
// import HandleCatch from "../../../../utilities/HandleCatch";
import { getQueryUri } from '../../../../utilities/URI';
import { handleApiDeleteById, handleApiSave } from '../../../../utilities/useApi';
import { AppContext } from '../../../../context/providers';
// import { TourGuide } from '../../UIKit/TourContent';
import { serviceFormSteps } from '../../../../config/tourSteps';

// eslint-disable-next-line react/prop-types
const ServiceFormModal = ({ onClose, onSave }) => {
  const [isToaster, setIsToaster] = useState(false);
  const [toasterErrors, setToasterErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [categoryOptions, setCategoryOption] = useState([]);
  const [typeOptions, setTypeOption] = useState([]);
  const [data, setData] = useState(null);
  const [staff, setStaff] = useState([]);
  const [isFirstService, setIsFirstService] = useState(false);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  const { handleSubmit, errors, control, watch, setValue, setError } = useForm();

  const { isSoftware } = useContext(AppContext);

  const params = useParams();
  const serviceId = params.id;

  useEffect(() => {
    getServiceList().then(({ count }) => {
      if (count === 0) {
        setIsFirstService(true);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (!loading) {
  //     setIsTourOpen(true);
  //   }
  // }, [serviceId]);

  useEffect(() => {
    if (toasterErrors.length > 0 && !isToaster) {
      setIsToaster(true);
      if (toasterErrors.length > 0) {
        toaster.notify(
          () => (
            <div className="pt-1">
              <div className="alert alert-danger">
                {toasterErrors.map((err) => (
                  <p key={err} className="mb-0 text-left">
                    *{err}
                  </p>
                ))}
              </div>
            </div>
          ),
          { duration: 3000 },
        );
        setTimeout(() => {
          setToasterErrors([]);
        }, 3000);
      }
    }
  }, [toasterErrors]);

  useEffect(() => {
    const categoryId = getQueryUri('categoryId');
    if (categoryId) {
      setValue('category', categoryId);
    }
    getCategoryList()
      .then((data) => {
        setCategories(data);
        const resultCategory = [];
        let resultTypes = [];
        data.forEach(({ name, id, service_types }) => {
          resultCategory.push({ value: id, label: name });
          if (resultTypes.length === 0) {
            resultTypes = service_types.map((s) => ({ label: s.name, value: s.id }));
          }
        });
        if (categoryId) {
          const category = data.find((d) => String(d.id) === categoryId);
          setTypeOption(category.service_types.map((s) => ({ label: s.name, value: s.id })));
        } else {
          setTypeOption(resultTypes);
        }
        setCategoryOption(resultCategory);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (serviceId && !!categories) {
      setLoading(true);
      getService(serviceId)
        .then((data) => {
          const category = categories.find((c) =>
            c.service_types.find((s) => String(s.id) === String(data.service_type)),
          );

          const body = {
            ...data,
            category: category?.id,
            service_type: data.service_type,
            price: [
              {
                duration: data.duration,
                tiered_level: data.tiered_level,
                price: data.price,
                extra_time: data.extra_time,
                // type: data.priceType.toLowerCase(),
                price_type: data.price_type,
                // type: data.price_type ? 'from' : (data.price > 0 ? 'fixed' : 'free'),
                name: '',
                special: '',
              },
            ],
            staff: data.providers,
          };
          setData(body);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [serviceId, categories]);

  const handleChangeCategory = (categoryId) => {
    const category = categories.find((d) => String(d.id) === String(categoryId));
    setValue('service_type', '');
    setTypeOption(category.service_types.map((s) => ({ label: s.name, value: s.id })));
    return categoryId;
  };

  const handleClose = () => {
    onClose();
  };
  const onSubmit = (data) => {
    if (staff.length === 0 && isSoftware) {
      toaster.notify(
        () => (
          <div className="pt-1">
            <div className="alert alert-danger">Please select at least one staff for this service</div>
          </div>
        ),
        { duration: 3000 },
      );
      return;
    }
    setSaveLoading(true);
    const { name, description, price, service_type } = data;
    // price.pop(); // remove last one
    // const date = new Date().getTime();
    // const hash = date.toString().substr(-10);

    let body = null;
    if (!!serviceId) {
      body = {
        name,
        description,
        service_type,
        extra_time: price[0].extra_time, //  [DD] [HH:[MM:]]ss[.uuuuuu]
        duration: price[0].duration, //  [DD] [HH:[MM:]]ss[.uuuuuu]
        price: price[0].price_type === 'free' ? 0 : price[0].price,
        tiered_level: price[0].tiered_level,
        // hash,
        price_type: price[0].price_type === 'free' ? 'fixed' : price[0].price_type,
        providers: staff,
      };
    } else {
      // price.pop();
      body = price.map((p) => ({
        name,
        description,
        service_type,
        duration: p.duration,
        extra_time: p.extra_time,
        price: p.price_type === 'free' ? 0 : p.price,
        tiered_level: p.tiered_level,
        // hash: +hash + ind,
        price_type: p.price_type === 'free' ? 'fixed' : p.price_type,
        providers: staff,
      }));
    }
    handleApiSave(createService, updateService, body, serviceId, { loadingFn: setSaveLoading, setError })
      .then((res) => onSave(res, isFirstService, !serviceId))
      .catch(() => {});
  };

  const handleDelete = () => {
    const deleteOptions = {
      title: 'Confirm to delete service',
      message: 'Are you sure to delete this service.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteService, serviceId, deleteOptions).then(() => {
      onSave(null);
      toaster.notify(() => <div className="alert alert-success">Service Deleted Successfully</div>);
    });
  };

  const onError = (errors) => {
    const notifyErrors = [];
    Object.keys(errors).forEach((key) => {
      const error = errors[key];
      if (Array.isArray(error)) {
        error.forEach((err) => {
          onError(err);
        });
      } else {
        notifyErrors.push(error.message);
      }
    });
    setToasterErrors(notifyErrors);
  };

  const handleChangeServiceType = (e) => {
    if (!watch().name.trim()) {
      setValue('name', e.label);
    }
  };

  return (
    <>
      <Modal
        show
        lg
        sticky
        guide={serviceFormSteps}
        onClose={handleClose}
        onSave={handleSubmit(onSubmit, onError)}
        saveLoading={saveLoading}
        buttonComponent={
          !!serviceId && (
            <Button color="danger" loading={delLoading} onClick={handleDelete}>
              Delete
            </Button>
          )
        }
        title={!!serviceId ? 'Edit Service' : 'Add a New Service'}
      >
        {loading || (!!serviceId && !data) ? (
          <PageLoading type="box" />
        ) : (
          <div className="c-service-form">
            <div className="c-service-form__head">
              <SectionHead title="Basic info" description="Add a service name and choose the category" />
            </div>
            <div data-tour="basic" className="c-service-form__content">
              <div className="c-form__group-row">
                <ControlSelect
                  control={control}
                  name="category"
                  rules={{ required: 'Category is required' }}
                  defaultValue={data?.category || ''}
                  label="Category"
                  options={categoryOptions}
                  onChangeValue={handleChangeCategory}
                  isSearchable
                  errorMessage={errors?.category?.message}
                />
                <ControlSelect
                  control={control}
                  name="service_type"
                  rules={{ required: 'Service type is required' }}
                  defaultValue={data?.service_type || ''}
                  label="Service Type"
                  options={typeOptions}
                  onChange={handleChangeServiceType}
                  isSearchable
                  errorMessage={errors?.service_type?.message}
                />
              </div>
              <ControlInput
                control={control}
                name="name"
                rules={{ required: 'Service name is required' }}
                defaultValue={data?.name ? data.name : ''}
                label="Service name"
                placeholder="Service Name"
                errorMessage={errors?.name?.message}
              />
            </div>
            <ServiceFormPricing
              isEdit={!!serviceId}
              values={data}
              setValue={setValue}
              control={control}
              errors={errors}
              watch={watch}
            />
            <ServiceFormStaff values={data} control={control} staff={staff} watch={watch} onChange={setStaff} />

            <div className="c-service-form__head">
              <SectionHead title="Service Description" />
            </div>
            <div className="c-service-form__content">
              <ControlInput
                type="textarea"
                control={control}
                name="description"
                defaultValue={data?.description ? data.description : ''}
                label="Description"
                placeholder="Add a short description"
                errorMessage={errors?.description?.message}
              />
            </div>
          </div>
        )}
      </Modal>
      {/*<TourGuide*/}
      {/*  disableInteraction*/}
      {/*  steps={serviceFormSteps}*/}
      {/*  startAt={tourStep}*/}
      {/*  getCurrentStep={setTourStep}*/}
      {/*  isOpen={isTourOpen}*/}
      {/*  onRequestClose={() => setIsTourOpen(false)}*/}
      {/*/>*/}
    </>
  );
};

export default ServiceFormModal;
