/**
 *
 * @param analytics {{
 *  appointments_count_per_day,
 *  popular_services,
 *  recent_appointments,
 * }}
 * @constructor
 */

export function AnalyticsAppointmentsObject(analytics) {
  this.appointments_count_per_day = analytics.appointments_count_per_day || null;
  this.popular_services = analytics.popular_services || null;
  this.recent_appointments = analytics.recent_appointments || null;
}

/**
 *
 * @param analytics {{
 *  all_clients_count,
 *  new_clients_count,
 *  returned_clients_count,
 * }}
 * @constructor
 */

export function AnalyticsClientsObject(analytics) {
  this.all_clients_count = analytics.all_clients_count || null;
  this.new_clients_count = analytics.new_clients_count || 0;
  this.returned_clients_count = analytics.returned_clients_count || 0;
}

/**
 *
 * @param analytics {{
 *  sale_per_day,
 *  this_month_sale,
 *  this_week_sale,
 *  total_sale,
 * }}
 * @constructor
 */

export function AnalyticsSalesObject(analytics) {
  this.sale_per_day = analytics.sale_per_day || null;
  this.this_month_sale = analytics.this_month_sale || 0;
  this.this_week_sale = analytics.this_week_sale || 0;
  this.total_sale = analytics.total_sale || 0;
}
