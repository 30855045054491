import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import { AppContext } from '../../../../context/providers';
import { MARKETPLACE_URL } from '../../../../config/appConfig';

const SetupLayoutStylist = ({ onSubmit }) => {
  const { stylist } = useContext(AppContext);
  return (
    <div className="c-panel-setup__question-container">
      <div className="c-panel-setup__question-wrapper">
        <h1>Do you want to create your own business?</h1>
        <p>We&apos;d love to know about your business goals. This will help us to offer you the right product</p>
        <Button lg link={`${MARKETPLACE_URL}/stylists/${stylist.id}/`} className="mt-5 mx-auto" onClick={onSubmit}>
          View Your Profile
        </Button>
        <Button color="light" className="mt-5 mx-auto" onClick={onSubmit}>
          Create Your Own Business
        </Button>
      </div>
    </div>
  );
};

SetupLayoutStylist.propTypes = {
  onSubmit: PropTypes.func,
};

export default SetupLayoutStylist;
