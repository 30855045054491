import React, { useCallback, useContext } from 'react';
import Modal from '../../UIKit/Modal';

import SetupCloseBtn from '../SetupCloseBtn';
import SetupMarketplaceContent from './SetupMarketplaceContent';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceModal = ({ reference, onClose, ...resProps }) => {
  const { setSystem } = useContext(AppContext);
  const handleClose = useCallback(() => {
    if (reference) {
      setSystem('marketplace');
    }
    return onClose();
  }, [reference]);

  return (
    <Modal show auto disableFooter disableHeader onClose={handleClose} bodyClass="p-0" title="Setup Marketplace">
      <SetupCloseBtn onClick={handleClose} />
      <SetupMarketplaceContent reference={reference} {...resProps} />
    </Modal>
  );
};

export default SetupMarketplaceModal;
