export const IMAGE_DEFAULT = require('./images/default-image.jpg').default;
export const IMAGE_DEFAULT_USER = require('./images/default-user.jpg').default;
export const IMAGE_DEFAULT_WOMAN_AVATAR = require('./images/default-woman-avatar.jpg').default;
export const IMAGE_LOGO = require('./images/logo.png').default;
export const IMAGE_LOGO_IMG = require('./images/logo-img.png').default;
export const IMAGE_LOGO_WHITE = require('./images/logo-white.png').default;
export const IMAGE_LOGO_MARKETPLACE = require('./images/logo-marketplace.png').default;
export const IMAGE_LOGO_SOFTWARE = require('./images/logo-software.png').default;
export const IMAGE_LOGO_WHITE_IMG = require('./images/logo-white-img.png').default;
export const IMAGE_EMAIL_INBOX = require('./images/email.png').default;
export const IMAGE_CROWN = require('./images/crown.png').default;
export const IMAGE_ANALYSIS = require('./images/analysis.png').default;
export const IMAGE_PAYMENT_STRIPE = require('./images/paymentImage.jpeg').default;
export const IMAGE_REFRESH_CONNECT_STRIPE = require('./images/refreshConnect.png').default;

export const IMAGE_SUCCESS_CHECK = require('./images/success-check.png').default;

export const IMAGE_MARKETPLACE_1 = require('./images/marketplace-1.png').default;
export const IMAGE_MARKETPLACE_2 = require('./images/marketplace-2.png').default;
export const IMAGE_MARKETPLACE_3 = require('./images/marketplace-3.png').default;

export const IMAGE_GOOGLE_BTN = require('./images/google-button/normal.png').default;
export const IMAGE_GOOGLE_BTN_PRESSED = require('./images/google-button/pressed.png').default;
export const IMAGE_GOOGLE_BTN_FOCUS = require('./images/google-button/focus.png').default;
export const IMAGE_GOOGLE_BTN_DISABLED = require('./images/google-button/disabled.png').default;

export const IMAGE_GOOGLE_LOGO = require('./images/google-logo.png').default;

export const IMAGE_MAP_MARKER = require('./images/map-marker.png').default;

export const IMAGE_SETUP_MARKETPLACE_BANNER = require('./images/setup/marketplace.jpg').default;
export const IMAGE_SETUP_SOFTWARE_BANNER = require('./images/setup/software.jpg').default;
export const IMAGE_SETUP_MARKETPLACE_SIDE_BANNER = require('./images/setup/salon-marketplace.jpg').default;
export const IMAGE_SETUP_SOFTWARE_SIDE_BANNER = require('./images/setup/salon-software.jpg').default;
export const IMAGE_SETUP_INFO_MAP = require('./images/setup/map_location.jpg').default;
export const IMAGE_SETUP_INFO_ADDRESS = require('./images/setup/form_location.png').default;
export const IMAGE_SETUP_INFO_PLANS = require('./images/setup/plans.jpg').default;
export const IMAGE_SETUP_INFO_TRIAL = require('./images/setup/trial_plan.jpg').default;

export const ICON_MONEY = require('./icons/money.png').default;
export const ICON_CREDIT_CARD = require('./icons/credit-card.png').default;
export const ICON_POS = require('./icons/pos.png').default;
export const ICON_UNPLUGGED = require('./icons/unplugged.png').default;
export const ICON_SEARCH_FAILED = require('./icons/search-failed.png').default;

export const ICON_USER_STYLIST = require('./images/setup/hair-cutting.png').default;
export const ICON_USER_BUSINESS = require('./images/setup/barbershop.png').default;
