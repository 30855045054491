import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SetupSoftwareContent from '../../SystemSetup/Software/SetupSoftwareContent';
import SetupMarketplaceContent from '../../SystemSetup/Marketplace/SetupMarketplaceContent';
import SetupBothContent from '../../SystemSetup/Both/SetupBothContent';
import SetupGoalContent from './SetupGoalContent';
import { GoalContent, QUESTIONS_KEYS, STEPS_TYPES } from '../../../../utilities/constants/setup';
import SetupAllGoalContent from './SetupAllGoalContent';
import SetupHeader from './SetupHeader';
import SetupQuestions from './SetupQuestions';
import SetupOnBoarding from './SetupOnBoarding';
import SetupStylistProfile from './SetupStylistProfile';
import SetupLayoutStylist from './SetupLayoutStylist';
import { AppContext } from '../../../../context/providers';
import { PageLoading } from '../../UIKit/Spinner';

const SetupLayout = ({ showSetupLayout, isNew = false, settingMode = false, reference, onCloseSetupLayout }) => {
  const { user, stylist } = useContext(AppContext);
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(STEPS_TYPES.ONBOARDING);

  const year = useMemo(() => new Date().getFullYear(), []);

  useEffect(() => {
    if (user) {
      if (stylist) setStep(STEPS_TYPES.STYLIST_LAYOUT);
      setLoading(false);
    }
  }, [user, stylist]);

  const handleNextStep = (goalValue) => {
    setGoal(goalValue);
    switch (goalValue) {
      case QUESTIONS_KEYS.CLIENT:
      case QUESTIONS_KEYS.BOOKINGS:
        setStep(STEPS_TYPES.MARKETPLACE);
        break;
      case QUESTIONS_KEYS.SALON:
        setStep(STEPS_TYPES.SOFTWARE);
        break;
      default:
        setStep(STEPS_TYPES.BOTH);
    }
  };

  const handleSubmit = () => {
    switch (goal) {
      case QUESTIONS_KEYS.CLIENT:
      case QUESTIONS_KEYS.BOOKINGS:
        setStep(STEPS_TYPES.SETUP_MARKETPLACE);
        break;
      case QUESTIONS_KEYS.SALON:
        setStep(STEPS_TYPES.SETUP_SOFTWARE);
        break;
      default:
        setStep(STEPS_TYPES.BOTH);
    }
  };

  const handleBack = () => {
    setStep(STEPS_TYPES.ONBOARDING);
  };

  return (
    <div
      className={classNames('c-panel-setup', {
        'c-panel-setup--settings': settingMode,
      })}
    >
      {!settingMode && <SetupHeader canBack={showSetupLayout} onClose={onCloseSetupLayout} />}
      {loading ? (
        <PageLoading />
      ) : step === STEPS_TYPES.SETUP_BOTH ? (
        <SetupBothContent
          isStatic
          goal={goal}
          isNew={isNew || showSetupLayout}
          reference={reference}
          onCloseLayout={isNew || showSetupLayout ? onCloseSetupLayout : undefined}
        />
      ) : step === STEPS_TYPES.SETUP_MARKETPLACE ? (
        <SetupMarketplaceContent
          isStatic
          goal={goal}
          isNew={isNew || showSetupLayout}
          reference={reference}
          onCloseLayout={isNew || showSetupLayout ? onCloseSetupLayout : undefined}
        />
      ) : step === STEPS_TYPES.SETUP_SOFTWARE ? (
        <SetupSoftwareContent
          isStatic
          goal={goal}
          isNew={isNew || showSetupLayout}
          reference={reference}
          onCloseLayout={isNew || showSetupLayout ? onCloseSetupLayout : undefined}
        />
      ) : step === STEPS_TYPES.QUESTIONS ? (
        <SetupQuestions onSubmit={handleNextStep} />
      ) : step === STEPS_TYPES.ONBOARDING ? (
        <SetupOnBoarding onSubmit={(type) => setStep(type)} />
      ) : step === STEPS_TYPES.STYLIST_LAYOUT ? (
        <SetupLayoutStylist onSubmit={() => setStep(STEPS_TYPES.QUESTIONS)} />
      ) : step === STEPS_TYPES.SETUP_STYLIST ? (
        <SetupStylistProfile
          onBack={() => setStep(STEPS_TYPES.ONBOARDING)}
          onSave={() => setStep(STEPS_TYPES.STYLIST_LAYOUT)}
        />
      ) : step === STEPS_TYPES.BOTH ? (
        <SetupAllGoalContent onBack={handleBack} onSubmit={handleSubmit} />
      ) : (
        <SetupGoalContent onBack={handleBack} content={GoalContent[step]} onSubmit={handleSubmit} />
      )}
      <div className="c-panel-setup-footer">&copy; {year} Beautster.com (SkillPublic Inc.)</div>
    </div>
  );
};

SetupLayout.propTypes = {
  isNew: PropTypes.bool,
  settingMode: PropTypes.bool,
  showSetupLayout: PropTypes.bool,
  reference: PropTypes.string,
  setupType: PropTypes.string,
  onShowSetup: PropTypes.func,
  onCloseSetupLayout: PropTypes.func,
};

export default SetupLayout;
