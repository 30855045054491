/**
 *
 * @param transfer {{
 *     id,
 *     amount,
 *     invoice,
 *     reason,
 *     transferred,
 *     created_at,
 *     updated_at,
 * }}
 * @constructor
 */

function PendingTransferObject(transfer) {
  this.id = transfer.id;
  this.amount = transfer.amount;
  this.invoice = transfer.invoice;
  this.reason = transfer.reason;
  this.transferred = transfer.transferred;
  this.createdAt = transfer.created_at;
}

export default PendingTransferObject;
