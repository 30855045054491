import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SectionHead from '../UIKit/SectionTitle';
import HoursRange from '../UIKit/HoursRange';
import { DayNames } from '../../../utilities/DateHelpers';
import useApi from '../../../utilities/useApi';
import { getBusinessWorkingHours } from '../../../api/businessWorkingHours';
import { PageLoading } from '../UIKit/Spinner';

const BusinessWorkingHours = ({ saved, onChange, onData }) => {
  const [initialHours, setInitialHours] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [hours, setHours] = useState([]);
  const [data, loading] = useApi(getBusinessWorkingHours, {}, onData);

  useEffect(() => {
    if (saved) {
      let results = [...initialData];
      const itemIndex = results.findIndex((i) => +i.week_day === +saved.week_day);
      if (itemIndex === -1 && !saved?.deleted) {
        results.push(saved);
      } else {
        if (saved.deleted) {
          results[itemIndex] = null;
        } else {
          results[itemIndex] = saved;
        }
      }
      updateList(results.filter((i) => !!i));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  useEffect(() => {
    if (data?.results) {
      updateList(data.results);
    }
  }, [data]);

  const updateList = (data) => {
    const results = weekDays.map((day, index) => {
      // const week_day = +index + 1;
      const week_day = +index;
      const item = data.find((i) => +i?.week_day === week_day);
      if (item) {
        const { start_time, end_time } = getTime(item);
        return {
          id: item.id,
          day,
          week_day,
          start_time,
          end_time,
          closed: !item.is_active,
        };
      }
      return {
        week_day,
        day,
        start_time: '08:00:00',
        end_time: '18:00:00',
        closed: true,
      };
    });
    setHours(results);
    setInitialHours(results);
    setInitialData(data);
  };

  const handleChangeHours = (day, value) => {
    const start_time = value[0];
    const end_time = value[1];
    const closed = value[2];
    const updateHours = [...hours];
    const ind = updateHours.findIndex((i) => i.week_day === day);
    updateHours[ind] = {
      ...updateHours[ind],
      start_time,
      end_time,
      closed,
    };
    setHours(updateHours);

    const changedHours = initialHours
      .map((i) => {
        const item = updateHours.find((j) => j.week_day === i.week_day);
        if (item.start_time !== i.start_time || item.end_time !== i.end_time || item.closed !== i.closed) {
          return item;
        }
        return null;
      })
      .filter((i) => !!i);
    onChange(changedHours);
  };

  const formatTime = (val) => {
    const hour = +val.split(':')[0];
    const unitTime = hour >= 12 ? 'PM' : 'AM';
    const min = +val.split(':')[1];
    return `${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min} ${unitTime}`;
  };

  const getTime = (item) => {
    let startHours = item.start_time.split(' ');
    let endHours = item.end_time.split(' ');

    let [startHour, startMin] = startHours[0].split(':');
    let [endHour, endMin] = endHours[0].split(':');

    startHour = startHour < 12 && startHours[1].toLowerCase() === 'pm' ? +startHour + 12 : startHour;
    endHour = endHour < 12 && endHours[1].toLowerCase() === 'pm' ? +endHour + 12 : endHour;
    return {
      start_time: `${startHour}:${startMin}:00`,
      end_time: `${endHour}:${endMin}:00`,
    };
  };

  const getLabel = (item) => {
    const timeLabel = `<small> ${formatTime(item.start_time)}-${formatTime(item.end_time)}</small>`;
    return `${item.day}: ${timeLabel}`;
  };

  return (
    <div className="c-panel-content">
      <SectionHead title="Business Hours" />
      {loading ? (
        <PageLoading />
      ) : (
        hours.map((item) => (
          <HoursRange
            key={item.week_day}
            title={getLabel(item)}
            defaultValue={[item.start_time, item.end_time, item.closed]}
            value={[item.start_time, item.end_time, item.closed]}
            onChange={(value) => handleChangeHours(item.week_day, value)}
          />
        ))
      )}
    </div>
  );
};

const weekDays = [...DayNames];
weekDays.push(weekDays[0]);
weekDays.shift();

BusinessWorkingHours.propTypes = {
  saved: PropTypes.bool,
  onChange: PropTypes.func,
  onData: PropTypes.func,
};

export default BusinessWorkingHours;
