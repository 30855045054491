import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import InputNum from './InputNum';
import FormGroup from './FormGroup';
import InputText from './InputText';
import { Controller } from 'react-hook-form';
import InputFile from './InputFile';
import InputTextarea from './InputTextarea';
import InputPhone, { countryCodes } from './InputPhone';
import InputEditor from './InputEditor';

export const ControlInput = ({
  control,
  name,
  rules,
  defaultValue,
  id,
  error,
  value: val,
  onChangeText,
  onChangeFile,
  disabled,
  refs,
  ...inputProps
}) =>
  control ? (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value }, { invalid }) => (
        <Input
          {...inputProps}
          ref={refs}
          id={id ? id : name}
          onChangeText={
            inputProps?.type !== 'file' && !disabled
              ? (val) => {
                  onChange(val);
                  if (onChangeText) onChangeText(val);
                }
              : undefined
          }
          onChangeFile={
            inputProps?.type === 'file' && !disabled
              ? (val) => {
                  if (onChangeFile) onChangeFile(val);
                }
              : undefined
          }
          onBlur={onBlur}
          value={!!val ? val : value}
          error={error || !!invalid}
          disabled={disabled}
        />
      )}
    />
  ) : null;
ControlInput.propTypes = {
  id: PropTypes.string,
  control: PropTypes.any.isRequired,
  refs: PropTypes.any,
  name: PropTypes.any,
  rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.any,
  formGroup: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'textarea', 'editor', 'file', 'number', 'tel', 'phone']),
  description: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorMessage: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChangeText: PropTypes.func,
  onChangeFile: PropTypes.func,
};

/**
 *
 * @param id
 * @param type
 * @param label
 * @param formGroup
 * @param value
 * @param description
 *
 */
// eslint-disable-next-line react/display-name
const Input = forwardRef((props, ref) => {
  let {
    id,
    type,
    label,
    formGroup,
    formStyle,
    value,
    description,
    error,
    errorMessage,
    onChange,
    onChangeText,
    onChangeFile,
    ...otherProps
  } = props;

  const handleChangePhoneValue = ({ code: _code, value: _value }) => {
    onChange(String(_code) + String(_value));
    onChangeText(String(_code) + String(_value));
  };
  const handleOnChange = (e) => {
    onChangeText(e.target.value);
    onChange(e);
  };
  const getPhoneValue = (val) => {
    let codeVal = '';
    if (val) {
      countryCodes.forEach((code) => {
        if (val.indexOf(code) > -1) codeVal = code;
      });
      const value = val.substr(codeVal.length);
      return { code: codeVal, value };
    }
    return { code: codeVal, value: '' };
  };
  const renderInput =
    type === 'number' ? (
      <InputNum id={id} ref={ref} onChangeText={onChangeText} onChange={onChange} value={value} {...otherProps} />
    ) : type === 'editor' ? (
      <InputEditor
        id={id}
        ref={ref}
        className={classnames('c-form__input', otherProps?.className)}
        onChange={handleOnChange}
        value={value}
        {...otherProps}
      />
    ) : type === 'textarea' ? (
      <InputTextarea
        id={id}
        ref={ref}
        className={classnames('c-form__input', otherProps?.className)}
        onChange={handleOnChange}
        value={value}
        {...otherProps}
      />
    ) : type === 'file' ? (
      <InputFile
        type={type}
        id={id}
        ref={ref}
        onChangeFile={onChangeFile}
        onChange={onChange}
        value={value}
        {...otherProps}
      />
    ) : type === 'phone' ? (
      <InputPhone
        id={id}
        ref={ref}
        onChangeValue={handleChangePhoneValue}
        // onChangeText={onChangeText}
        // onChange={onChange}
        value={getPhoneValue(value)}
        {...otherProps}
      />
    ) : (
      <InputText
        type={type}
        id={id}
        ref={ref}
        onChangeText={onChangeText}
        onChange={onChange}
        value={value}
        {...otherProps}
      />
    );

  return formGroup ? (
    <FormGroup
      id={id}
      label={label}
      style={formStyle || undefined}
      description={description}
      errorMessage={!!errorMessage ? errorMessage : ''}
      className={otherProps?.formClassName}
      error={error}
    >
      {renderInput}
    </FormGroup>
  ) : (
    renderInput
  );
});

Input.propTypes = {
  id: PropTypes.string,
  formGroup: PropTypes.bool,
  formStyle: PropTypes.any,
  type: PropTypes.oneOf(['text', 'password', 'textarea', 'editor', 'file', 'number', 'tel', 'phone']),
  description: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  formClassName: PropTypes.any,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  onChangeFile: PropTypes.func,
};

Input.defaultProps = {
  id: null,
  formGroup: true,
  type: 'text',
  label: '',
  description: '',
  error: false,
  errorMessage: null,
  maxLength: 500,
  onChange: () => {},
  onChangeText: () => {},
  onChangeFile: () => {},
};

export default Input;
