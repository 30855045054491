import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import PureCalendar from './PureCalendar';
import AppointmentFormModal from '../Appointment/AppointmentFormModal';
import {
  createAppointment,
  deleteAppointmentLine,
  getAppointmentCalendarList,
  partialUpdateAppointment,
  updateAppointmentLine,
  updateStaffClosedDate,
} from '../../../api';
import {
  getDateFromString,
  getDateInfo,
  getFormatDate,
  getStringFromDate,
  getStringFromTime,
} from '../../../utilities/DateHelpers';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import { PageLoading } from '../UIKit/Spinner';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import { AlertAccess } from '../UIKit/Alerts';
import SetupSoftwareModal from '../SystemSetup/Software/SetupSoftwareModal';
import ConvertGoogleCalenderModal from './ConvertGoogleCalenderModal';
import { ModalAlert } from '../UIKit/Alert';
import CalendarBlockedModal from './CalendarBlockedModal';
// import { TourGuide } from '../UIKit/TourContent';
// import { calendarSteps } from '../../../config/tourSteps';
import { range } from '../../../utilities/number';

const Calendar = ({
  url,
  googleEvents: _googleEvents,
  resources,
  workingHours,
  staffClosedDates,
  closedDates,
  showModal,
  onCloseModal,
  onRefreshBlocked,
  loading: _loading = false,
  selectBlockedTime,
  showBlocked,
  onCloseBlocked,
  onOpenBlocked,
}) => {
  const [staffInactiveEvents, setStaffInactiveEvents] = useState([]);
  const [blockedEvents, setBlockedEvents] = useState(staffClosedDates || []);
  const [loading, setLoading] = useState(true);
  const [convertGoogleModal, setConvertGoogleModal] = useState(null);
  const [unknownAlert, setUnknownAlert] = useState(false);
  const [editData, setEditData] = useState(null);
  const [events, setEvents] = useState([]);
  const [showSetupSoftware, setShowSetupSoftware] = useState(false);
  const [draggedEvent, setDraggedEvent] = useState(null);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  const [googleEvents, setGoogleEvents] = useState(_googleEvents);

  const location = useLocation();
  const history = useHistory();

  const { search, pathname } = location;
  const { user, business, setSystem, isMarketplace, softwareAccess } = useContext(AppContext);

  // useEffect(() => {
  //   if (!loading && !_loading) {
  //     setIsTourOpen(true);
  //   }
  // }, [loading]);
  useEffect(() => {
    if (_googleEvents) {
      setGoogleEvents(_googleEvents);
    }
  }, [_googleEvents]);
  useEffect(() => {
    setBlockedEvents(staffClosedDates);
  }, [staffClosedDates]);

  const handleSetEvents = (_events) => {
    const uniqueEvents = _events.reduce(
      (acc, i) => (acc.findIndex((j) => j.id === i.id) === -1 ? [...acc, i] : acc),
      [],
    );
    setEvents(uniqueEvents);
  };
  const getAppointment = () => {
    const date = getQueryUri('date') || getStringFromDate(new Date());
    const view = getQueryUri('view') || 'day';
    const staff = getQueryUri('staff');
    const path = pathname[pathname.length - 1] === '/' ? pathname.substr(0, pathname.length - 1) : pathname;

    if (path === url) {
      setLoading(true);
      let from_date = date;
      let to_date = date;
      if (view === 'week') {
        const start_date = getDateFromString(date);
        start_date.setDate(start_date.getDate() - (start_date.getDay() % 7));
        from_date = getStringFromDate(start_date);

        const end_date = getDateFromString(date);
        end_date.setDate(end_date.getDate() + (6 - end_date.getDay()));
        to_date = getStringFromDate(end_date);
      } else if (view === 'month') {
        const start_date = getDateFromString(date);
        start_date.setDate(1);
        from_date = getStringFromDate(start_date);

        const end_date = new Date(start_date);
        end_date.setMonth(end_date.getMonth() + 1);
        end_date.setDate(end_date.getDate() - 1);
        to_date = getStringFromDate(end_date);
      }

      // ===== api list settings
      // api list general body
      const body = {
        from_date,
        to_date,
        staff,
        sale_channel: isMarketplace ? 'marketplace' : undefined,
      };
      // api list general options
      const options = {
        infinitePage: true,
        limit: 20,
        page: 1,
      };
      // api list general api call function
      const fn = getAppointmentCalendarList;

      Promise.all([
        handleApi(fn, { ...body, status: 'confirmed' }, options),
        handleApi(fn, { ...body, status: 'pending' }, options),
        handleApi(fn, { ...body, status: 'fulfilled' }, options),
        handleApi(fn, { ...body, status: 'no_show' }, options),
      ])
        .then((res) => {
          const allResults = res.reduce((acc, data) => {
            const results = data.results.reduce(
              (acc, i) => acc.concat(i.lines.map((l) => ({ ...l, appointmentLines: i.lines }))),
              [],
            );
            return [...acc, ...results];
          }, []);
          handleSetEvents(allResults);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const staffEventClosed = () => {
    const currentDate = getQueryUri('date') || getStringFromDate(new Date());
    const view = getQueryUri('view') || 'day';
    if (getQueryUri('date')) {
      const rangeEnd = view === 'week' ? 7 : 1;
      const inactiveEvents = range(0, rangeEnd).reduce((acc, i) => {
        const date = new Date(currentDate);
        date.setDate(date.getDate() + i);
        const start = new Date(date);
        const end = new Date(date);
        start.setHours(0, 0, 0);
        end.setHours(23, 59, 0);
        const results = resources.reduce((acc, staff) => {
          const res = (staff.times || []).reduce((_acc, time) => {
            if (time.dayName.toLowerCase() === getDateInfo(date).dayName.toLowerCase() && !time.is_active) {
              const id = `${staff.id}_${start.getTime()}_${time.week_day}`;
              if (_acc.findIndex((i) => i.id === id) === -1) {
                const item = {
                  end,
                  id,
                  end_time: getFormatDate(end, true),
                  start_time: getFormatDate(start, true),
                  resourceId: staff.id,
                  start,
                  title: `${staff.name}`,
                  type: 'staff_inactive',
                };
                return [..._acc, item];
              }
            }
            return _acc;
          }, []);

          return [...acc, ...res];
        }, []);
        return [...acc, ...results];
      }, []);
      setStaffInactiveEvents(inactiveEvents);
    }
  };
  useEffect(() => {
    staffEventClosed();
    getAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname]);

  const handleCloseModal = (date) => {
    onCloseModal(date);
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay: allDay,
    };

    setDraggedEvent(null);
    moveEvent({ event, start, end });
  };

  const moveEvent = ({ event, start, end, resourceId }) => {
    // if(!!event.invoice) {
    //     toaster.notify(() => <div className="alert alert-danger">This Appointment has been paid and cannot change data</div>)
    //     return;
    // }
    if (event?.type === 'blocked') {
      const start_date = getStringFromDate(start);
      const start_time = getStringFromTime(start);
      const end_time = `${getStringFromDate(end)} ${getStringFromTime(end)}`;
      const currentBlocked = [...blockedEvents];
      const nextBlocked = blockedEvents.map((existingEvent) =>
        String(existingEvent.id) === String(event.id)
          ? { ...existingEvent, resourceId, start, end, start_time: `${start_date} ${start_time}`, end_time }
          : existingEvent,
      );
      setBlockedEvents(nextBlocked);

      confirmAlert({
        title: 'Confirm to change blocked time',
        message: `Are you sure to change this blocked time to ${start_date} - ${start_time}.`,
        onClickOutside: () => setBlockedEvents(currentBlocked),
        onKeypressEscape: () => setBlockedEvents(currentBlocked),
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              handleApiSave(
                undefined,
                updateStaffClosedDate,
                {
                  ...event,
                  start_time: `${start_date} ${start_time}`,
                  end_time,
                  staff: resourceId,
                },
                event.id,
              )
                .then(() => {
                  // setBlockedEvents(nextBlocked);
                })
                .catch(() => {
                  setBlockedEvents(currentBlocked);
                });
            },
          },
          {
            label: 'No',
            onClick: () => setBlockedEvents(currentBlocked),
          },
        ],
      });
    } else if (!(event?.type === 'staff_inactive' || event?.type === 'google-calendar')) {
      const reserved_date = getStringFromDate(start);
      const reserved_time = getStringFromTime(start);
      const currentEvents = [...events];
      const nextEvents = events.map((existingEvent) =>
        String(existingEvent.id) === String(event.id) ? { ...existingEvent, resourceId, start, end } : existingEvent,
      );

      handleSetEvents(nextEvents);

      if (event.appointmentLines.length > 1 && reserved_date !== getStringFromDate(event.reserved_date)) {
        confirmAlert({
          title: 'Split Appointment',
          message: `This appointment is part of a group, moving it to another day will split it to become a separate booking. Are you sure?`,
          onClickOutside: () => handleSetEvents(currentEvents),
          onKeypressEscape: () => handleSetEvents(currentEvents),
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                const durationTime = Math.abs(end - start);
                const dHour = Math.floor(durationTime / (1000 * 60 * 60));
                const dMin = Math.ceil((durationTime / (1000 * 60)) % 60);
                const duration = `${dHour < 10 ? `0${dHour}` : dHour}:${dMin < 10 ? `0${dMin}` : dMin}:00`;
                try {
                  await deleteAppointmentLine(event.id);
                  const newEvent = await createAppointment({
                    client: event.client,
                    client_name: event.client_name,
                    note: event.note,
                    reserved_date,
                    lines: [
                      {
                        duration,
                        staff: resourceId,
                        service: event.service,
                        reserved_time,
                      },
                    ],
                  });
                  const newResult = { ...newEvent.lines[0], appointmentLines: newEvent.lines };
                  const nextEvents = events.map((existingEvent) =>
                    String(existingEvent.id) === String(event.id)
                      ? { ...newResult, resourceId, start, end }
                      : existingEvent,
                  );
                  handleSetEvents(nextEvents);
                } catch (e) {}
              },
            },
            {
              label: 'No',
              onClick: () => handleSetEvents(currentEvents),
            },
          ],
        });
      } else {
        confirmAlert({
          title: 'Confirm to change appointment',
          message: `Are you sure to change this appointment to ${reserved_date} - ${reserved_time}.`,
          onClickOutside: () => handleSetEvents(currentEvents),
          onKeypressEscape: () => handleSetEvents(currentEvents),
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                const durationTime = Math.abs(end - start);
                const dHour = Math.floor(durationTime / (1000 * 60 * 60));
                const dMin = Math.ceil((durationTime / (1000 * 60)) % 60);
                const duration = `${dHour < 10 ? `0${dHour}` : dHour}:${dMin < 10 ? `0${dMin}` : dMin}:00`;
                if (reserved_date !== getStringFromDate(event.reserved_date)) {
                  handleApiSave(undefined, partialUpdateAppointment, { reserved_date }, event.appointmentId)
                    .then(() => {
                      updateAppointmentLine(event.id, {
                        reserved_time,
                        duration,
                        staff: resourceId,
                      }).then(() => {});
                    })
                    .catch(() => {
                      handleSetEvents(currentEvents);
                    });
                  // partialUpdateAppointment(event.appointmentId, { reserved_date }).then(() => {
                  //     updateAppointmentLine(event.id, {
                  //         // reserved_date,
                  //         reserved_time,
                  //         duration,
                  //         staff: resourceId
                  //     }).then(data => {
                  //         console.log('updateAppointmentLine', data);
                  //     });
                  // });
                } else {
                  handleApiSave(
                    undefined,
                    updateAppointmentLine,
                    {
                      reserved_time,
                      duration,
                      staff: resourceId,
                    },
                    event.id,
                  )
                    .then(() => {})
                    .catch(() => {
                      handleSetEvents(currentEvents);
                    });
                  // updateAppointmentLine(event.id, {
                  //     // reserved_date,
                  //     reserved_time,
                  //     duration,
                  //     staff: resourceId
                  // }).then(data => {
                  //     console.log('updateAppointmentLine', data);
                  // });
                }
              },
            },
            {
              label: 'No',
              onClick: () => handleSetEvents(currentEvents),
            },
          ],
        });
      }
    }
  };
  const handleNewAppointment = ({ start, end, resourceId }) => {
    if (resourceId === 'unknown') {
      setUnknownAlert(true);
      return;
    }
    const staff = resourceId;
    const durationTime = Math.abs(end - start);
    const dHour = Math.floor(durationTime / (1000 * 60 * 60));
    const dMin = Math.ceil((durationTime / (1000 * 60)) % 60);
    const duration = `${dHour < 10 ? `0${dHour}` : dHour}:${dMin < 10 ? `0${dMin}` : dMin}:00`;
    const params = setQueryUri({
      date: getStringFromDate(start),
      time: getStringFromTime(start),
      staff,
      duration,
    });
    sessionStorage.setItem(
      'location.search',
      !!location.search ? location.search : `?view=day&date${getStringFromDate(new Date())}`,
    );
    history.push(`/calendar/appointment/add${params}`);
  };
  // const handleEditEvent = ({ start, end, resourceId, type, ...otherProps}) => {
  const handleEditEvent = (props) => {
    // eslint-disable-next-line react/prop-types
    if (props?.type === 'google-calendar') {
      if (!isMarketplace) {
        setConvertGoogleModal(props);
      } else {
        shouldChangeModeAlert();
      }
      // eslint-disable-next-line react/prop-types
    } else if (props?.type === 'staff_inactive') {
      history.push('/staff/hours');
      // eslint-disable-next-line react/prop-types
    } else if (props?.type === 'blocked') {
      sessionStorage.setItem(
        'location.search',
        !!location.search ? location.search : `?view=day&date${getStringFromDate(new Date())}`,
      );
      history.push(`/calendar/blocked/${props.id}/edit`);
    } else {
      sessionStorage.setItem(
        'location.search',
        !!location.search ? location.search : `?view=day&date${getStringFromDate(new Date())}`,
      );
      history.push(`/calendar/appointment/${props.appointmentId ? props.appointmentId : props.id}/edit`);
    }
  };

  const handleSaveEvent = (data, googleEvent = null) => {
    if (data === 'refresh') {
      getAppointment();
    } else if (typeof data === 'number') {
      const updateEvents = events.filter((event) => String(event.id) !== String(data));
      handleSetEvents(updateEvents);
    } else {
      // const { id, start, end, name, lines, isEdit, ...otherData} = data;
      // console.log('data', id, start, end, name, lines, isEdit, otherData);
      // const newEvents = [];
      // lines.forEach(line => {
      //     newEvents.push({
      //         id,
      //         title: name,
      //         start: start,
      //         end: end,
      //         resourceId: line.staff,
      //         ...otherData,
      //     })
      // });
      // if(isEdit) {
      //     const updateEvents = events.map(event => {
      //         if(event.id.toString() === id.toString()) {
      //             return { ...event, ...newEvent};
      //         }
      //         return event;
      //     });
      //     handleSetEvents(updateEvents);
      // } else {
      //     handleSetEvents(events.concat(newEvents));
      // }
    }
    if (googleEvent?.eventId) {
      setGoogleEvents(_googleEvents.filter((i) => i.id !== googleEvent.eventId));
      setConvertGoogleModal(null);
    }
    handleCloseModal();
  };

  const eventStyleGetter = (event) => {
    // console.log('event', event)
    // let backgroundColor = event.hexColor ? '#' + event.hexColor : '#3174ad';
    let otherStyles = {};
    let backgroundColor = event.hexColor ? `#${event.hexColor}` : '#0097A7';
    let opacity = 1;
    if (
      (event.payment_status === 'paid' ||
        event.payment_status === 'part_paid' ||
        event.payment_status === 'refunded' ||
        event.payment_status === 'part_refunded') &&
      event.appointmentLines.length > 1
    ) {
      backgroundColor = '#607D8B';
    }
    // else if(event.payment_status === 'refunded' || event.payment_status === 'part_refunded') backgroundColor = '#BF360C';
    else if (event.appointmentStatus) {
      // AppointmentStatusChoice
      switch (event.appointmentStatus) {
        case 'no_show':
          backgroundColor = '#ef5350';
          break;
        case 'cancelled':
        case 'business_cancelled':
          backgroundColor = '#ef5350';
          break;
        case 'arrived':
          backgroundColor = '#4CAF50';
          break;
        case 'fulfilled':
          backgroundColor = '#009688';
          break;
        case 'pending':
          backgroundColor = '#FF9800';
          break;
        default:
          // backgroundColor = '#3174ad';
          backgroundColor = '#0097A7';
      }
      // pending: 'Pending',
      // confirmed: 'Confirmed',
      // arrived: 'Arrived',
      // fulfilled: 'Fulfilled',
      // business_cancelled: 'Business Cancelled',
      // no_shows: 'No Shows',
    } else if (event.type && event.type === 'blocked') {
      backgroundColor = '#455A64';
    } else if (event?.type && event.type === 'staff_inactive') {
      backgroundColor = '#B0BEC5';
      otherStyles = {
        borderRadius: 0,
        border: 0,
        background: `linear-gradient(45deg, #607D8B 12.5%, #B0BEC5 12.5%, #B0BEC5 37.5%, #607D8B 37.5%, #607D8B 62.5%, #B0BEC5 62.5%, #B0BEC5 87.5%, #607D8B 87.5%)`,
        backgroundSize: '10px 10px',
        backgroundPosition: '5px 5px',
        opacity: 0.5,
      };
    }

    // if(event.sale_channel === 'marketplace' && !isMarketplace)
    //     backgroundColor = '#BCAAA4';
    if (event.sale_channel === 'marketplace') {
      backgroundColor = '#ffc02e';
      if (!isMarketplace) opacity = 0.6;
      switch (event.appointmentStatus) {
        case 'no_show':
          backgroundColor = '#ef5350';
          break;
        case 'cancelled':
        case 'business_cancelled':
          backgroundColor = '#ef5350';
          break;
        case 'arrived':
          backgroundColor = '#4CAF50';
          break;
        case 'fulfilled':
          backgroundColor = '#009688';
          break;
        case 'pending':
          backgroundColor = '#FF9800';
          break;
        default:
          // backgroundColor = '#3174ad';
          backgroundColor = '#ffc02e';
      }
    }

    if (event?.type === 'blocked') backgroundColor = '#999';
    if (event?.type === 'google-calendar') backgroundColor = '#7986cb';

    return { style: { opacity, backgroundColor, borderColor: 'rgba(0,0,0,0.2)', borderRadius: 2, ...otherStyles } };
  };

  const shouldChangeModeAlert = () => {
    if (business.software_setup && (user.trialing || business.subscribed)) {
      confirmAlert({
        title: 'Change Mode',
        message: `If you want to create new appointment you should change your mode to software. Would you change mode to software?`,
        buttons: [
          {
            label: 'Switch To Software',
            className: 'bg-success',
            onClick: () => {
              setSystem('software');
            },
          },
          {
            label: 'Cancel',
            onClick: () => {},
          },
        ],
      });
    } else {
      confirmAlert({
        title: 'Setup Software',
        message: `You are not allowed to create appointments on Marketplace's calendar. You can only manage the clients that Beautster send to you on the Marketplace. To create appointments you need to setup Beautster software. `,
        buttons: [
          {
            label: 'Setup Software',
            className: 'bg-success',
            onClick: () => {
              setShowSetupSoftware(true);
            },
          },
          {
            label: 'Cancel',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handleNewBlockedTime = ({ start, end, resourceId }) => {
    const date = start;
    const startTime = start;
    const endTime = end;
    const staff = resourceId;
    const staffInfo = resources.find((i) => i.id === resourceId);
    setEditData({
      date,
      staff,
      staffInfo,
      startTime,
      endTime,
      description: '',
      type: 'blocked',
    });
    onOpenBlocked();
  };

  const handleCloseBlocked = () => {
    setEditData(null);
    onCloseBlocked();
  };
  const handleSaveBlocked = (data = 'refresh') => {
    if (typeof data === 'number') {
      const updateBlockedEvents = blockedEvents.filter((item) => String(item.id) !== String(data));
      setBlockedEvents(updateBlockedEvents);
    } else {
      onRefreshBlocked();
    }
    handleCloseBlocked();
  };

  // const staffUnavailableEvent = useMemo(() => {
  //   console.log('blockedEvents', blockedEvents);
  //   const res = (resources || []).reduce((acc, i) => {
  //
  //   }, []);
  //   if (resources) {
  //     ?.times
  //   }
  //   console.log('resources', resources?.times);
  //   {
  //     end: Wed Dec 29 2021 04:00:00 GMT+0330 (Iran Standard Time) {}
  //     end_time: "Wednesday, Dec 29 2021 04:00 AM"
  //     id: "7916735920438865770"
  //     resourceId: "7893483974394678989"
  //     staff: "7893483974394678989"
  //     staffName: "New Staff"
  //     start: Wed Dec 29 2021 01:00:00 GMT+0330 (Iran Standard Time) {}
  //     start_time: "Wednesday, Dec 29 2021 01:00 AM"
  //     title: "ffffffffff"
  //     type: "blocked"
  //   }
  //   return [];
  // }, [resources]);
  //
  const calendarEvents = useMemo(
    () => [...(events || []), ...(googleEvents || []), ...(blockedEvents || []), ...(staffInactiveEvents || [])],
    [events, googleEvents, blockedEvents, staffInactiveEvents],
  );
  console.log('resources', resources);
  return (
    <>
      {convertGoogleModal && (
        <ConvertGoogleCalenderModal
          extraData={{
            workingHours,
            closedDates,
            note: convertGoogleModal.summary,
            start: convertGoogleModal.start,
            end: convertGoogleModal.end,
            eventId: convertGoogleModal.id,
          }}
          onSave={handleSaveEvent}
          onClose={() => setConvertGoogleModal(null)}
        />
      )}
      {unknownAlert && (
        <ModalAlert
          title="This action is unable for this staff"
          description="You cannot select unknown staff to add new appointment. 'Uknown staff' has been added automatically to support unassigned events to any other staff. Most of them came from google calendar."
          onClose={() => setUnknownAlert(false)}
        />
      )}
      {showSetupSoftware && <SetupSoftwareModal onClose={() => setShowSetupSoftware(false)} />}
      <div data-tour="calendar" className="position-relative">
        {(loading || _loading) && <PageLoading over />}
        {showModal && (
          <AppointmentFormModal
            onSave={handleSaveEvent}
            onClose={handleCloseModal}
            extraData={{ workingHours, closedDates }}
          />
        )}
        {showBlocked && (
          <CalendarBlockedModal editData={editData} onSave={handleSaveBlocked} onClose={handleCloseBlocked} />
        )}
        {!softwareAccess && <AlertAccess />}
        <PureCalendar
          url={url}
          events={calendarEvents}
          workingHours={workingHours}
          closedDates={closedDates}
          resources={resources}
          onEventDrop={moveEvent}
          onSelectEvent={handleEditEvent}
          onDoubleClickEvent={handleEditEvent}
          // selectable={softwareAccess && !isMarketplace}
          selectable={softwareAccess}
          // onSelectSlot={(softwareAccess && !isMarketplace) ? selectBlockedTime ? handleNewBlockedTime : handleNewAppointment : undefined}
          onSelectSlot={
            selectBlockedTime
              ? handleNewBlockedTime
              : softwareAccess && !isMarketplace
              ? handleNewAppointment
              : isMarketplace
              ? shouldChangeModeAlert
              : undefined
          }
          // onDrillDown={console.log}
          resizableAccessor={() => false}
          draggableAccessor={(e) =>
            !(e.invoice && e.appointmentLines.length > 1) && !(e.sale_channel === 'marketplace' && !isMarketplace)
          }
          eventPropGetter={eventStyleGetter}
          onDropFromOutside={onDropFromOutside}
          handleDragStart={setDraggedEvent}
        />
      </div>
      {/*<TourGuide*/}
      {/*  disableInteraction={tourStep === 0}*/}
      {/*  steps={calendarSteps}*/}
      {/*  startAt={tourStep}*/}
      {/*  getCurrentStep={setTourStep}*/}
      {/*  isOpen={isTourOpen}*/}
      {/*  onRequestClose={() => setIsTourOpen(false)}*/}
      {/*/>*/}
    </>
  );
};

Calendar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  appointmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  url: PropTypes.string,
  googleEvents: PropTypes.array,
  resources: PropTypes.array,
  workingHours: PropTypes.array,
  closedDates: PropTypes.array,
  staffClosedDates: PropTypes.array,
  showModal: PropTypes.bool,
  onRefreshBlocked: PropTypes.func,
  onCloseModal: PropTypes.func,
  selectBlockedTime: PropTypes.bool,
  showBlocked: PropTypes.bool,
  onCloseBlocked: PropTypes.func,
  onOpenBlocked: PropTypes.func,
};

export default Calendar;
