import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import DatePicker from './Form/DatePicker';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import { getDateFromString, getStringFromDate } from '../../../utilities/DateHelpers';

// eslint-disable-next-line react/prop-types
const DateNavigation = ({ url = null, dataTour }) => {
  const [date, setDate] = useState(new Date());
  const location = useLocation();
  const history = useHistory();

  const view = getQueryUri('view') || 'day';
  const counterNum = view === 'day' ? 1 : view === 'week' ? 7 : 0;
  useEffect(() => {
    const date = getQueryUri('date');
    if (date) {
      setDate(getDateFromString(date));
    } else if (!!url && url === location.pathname) {
      handleChangeDate(new Date());
    }
  }, [location.search]);

  const handleChangeDate = (currentDate) => {
    const date = getStringFromDate(currentDate);
    const params = setQueryUri({
      ...getQueryUri(),
      date,
    });
    history.replace(params);
  };

  const handleNext = () => {
    if (view === 'month') date.setMonth(date.getMonth() + 1);
    else date.setDate(date.getDate() + counterNum);
    handleChangeDate(new Date(date));
  };

  const handlePrev = () => {
    if (view === 'month') date.setMonth(date.getMonth() - 1);
    else date.setDate(date.getDate() - counterNum);
    handleChangeDate(new Date(date));
  };

  const handleToday = () => {
    const today = new Date();
    handleChangeDate(today);
  };
  const handlePickerChange = (date) => {
    handleChangeDate(date);
  };
  return (
    <div data-tour={dataTour} className="c-date-navigation">
      <button className="c-date-navigation__prev" id="navPrevBtn" onClick={handlePrev}>
        <i className="fas fa-chevron-left" />
      </button>

      <DatePicker
        id="navDateBtn"
        formGroup={false}
        arrowLabel={false}
        iconLabel={false}
        onChange={handlePickerChange}
        selected={date}
      />
      <button className="c-date-navigation__today" id="navTodayBtn" onClick={handleToday}>
        Today
      </button>
      <button className="c-date-navigation__next" id="navNextBtn" onClick={handleNext}>
        <i className="fas fa-chevron-right" />
      </button>
    </div>
  );
};

export default DateNavigation;
