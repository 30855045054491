import React, { useContext, useEffect } from 'react';
import { Close } from '@material-ui/icons';
import { IMAGE_PAYMENT_STRIPE } from '../../../assets/Images';
import Button from '../../Common/UIKit/Form/Button';
import { getQueryUri } from '../../../utilities/URI';
import { getStringFromDate } from '../../../utilities/DateHelpers';
import { AppContext } from '../../../context/providers';
import { handleApiSave } from '../../../utilities/useApi';
import { updateBusiness } from '../../../api';

const FailurePaymentPage = () => {
  const { business, setSystem, setBusiness } = useContext(AppContext);
  const price = getQueryUri('amount');
  const transaction = getQueryUri('transaction');
  const email = getQueryUri('email');
  const date = getQueryUri('date');
  const mobile = getQueryUri('mobile');
  const mode = getQueryUri('mode');
  const today = new Date();

  useEffect(() => {
    if (business) {
      if (!business.subscription_end_at && !business.trial_end_at && mode === 'subscription') {
        handleApiSave(updateBusiness, null, { software_setup: false }, null)
          .then((data) => {
            setBusiness(data);
            if (data?.marketplace_setup) {
              setSystem('marketplace');
            }
          })
          .catch(() => {});
      }
    }
  }, [business, mode]);

  return (
    <div className="c-payment-stripe c-payment-stripe--failure">
      <img
        className="c-payment-stripe__background c-payment-stripe__background--filter"
        src={IMAGE_PAYMENT_STRIPE}
        alt="Beautster"
      />

      <div className="c-payment-stripe__box c-payment-stripe__box--failure">
        <span className="c-payment-stripe__box-icon">
          <Close />
        </span>

        <h1 className="c-payment-stripe__box-title">Payment failure</h1>

        <ul className="c-payment-stripe__list">
          <li className="c-payment-stripe__list-item">
            <span>Date</span>
            <span>{date || getStringFromDate(today)}</span>
          </li>
          {email && (
            <li className="c-payment-stripe__list-item">
              <span>Email</span>
              <span>{email}</span>
            </li>
          )}
          {mobile && (
            <li className="c-payment-stripe__list-item">
              <span>Mobile</span>
              <span>{mobile}</span>
            </li>
          )}
          {price && (
            <li className="c-payment-stripe__list-item">
              <strong>Amount paid</strong>
              <strong>{price}</strong>
            </li>
          )}
          {transaction && (
            <li className="c-payment-stripe__list-item">
              <span>Transaction ID</span>
              <span>{transaction}</span>
            </li>
          )}
        </ul>

        <Button link="/">CLOSE</Button>
      </div>
    </div>
  );
};

export default FailurePaymentPage;
