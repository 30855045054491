import { getDateFromString, getFormatBriefDate } from '../utilities/DateHelpers';

/**
 *
 * @param deal {{
 *     id,
 *     services,
 *     subject,
 *     description,
 *     image,
 *     email_subject,
 *     email_description,
 *     discount,
 *     usage_limit,
 *     campaign,
 *     booked_count,
 *     expire_after,
 *     created_at,
 *     is_published,
 *     status,
 *     send_sms: Boolean,
 * }}
 * @constructor
 */

function DealObject(deal) {
  this.id = deal.id;
  this.services = deal.services;
  this.subject = deal.subject;
  this.description = deal.description;
  this.image = deal.image;
  this.email_subject = deal.email_subject;
  this.email_description = deal.email_description;
  this.discount = deal.discount;
  this.booked_count = deal.booked_count;
  this.usage_limit = deal.usage_limit;
  this.campaign = deal.campaign;
  this.expire_after = deal.expire_after;
  const createdAt = getDateFromString(deal.created_at);
  createdAt.setDate(createdAt.getDate() + +deal.expire_after);
  this.expire_date = !!deal.expire_after ? getFormatBriefDate(createdAt) : null;
  this.send_sms = deal.send_sms;
  this.is_published = deal.is_published;
  this.status = deal?.status || (deal.is_published ? 'published' : 'draft');
}

export default DealObject;
