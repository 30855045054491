import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import DiscountObject from '../objects/DiscountObject';

const { GET, POST, PUT, PATCH, DELETE } = ApiMethods;

/**
 * @param params
 */
export const createDiscount = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.discounts.create,
    params,
  });
  if (response.data) {
    return new DiscountObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params
 */

export const updateDiscount = async (id, params) => {
  const response = await callApi({
    method: PUT,
    url: createURL(ApiRoutes.discounts.update, { id }),
    params,
  });
  if (response.data) {
    return new DiscountObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params
 */

export const updatePartialDiscount = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    url: createURL(ApiRoutes.discounts.update, { id }),
    params,
  });
  if (response.data) {
    return new DiscountObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getDiscountList = async ({ search, page, limit } = {}) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.discounts.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new DiscountObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getDiscount = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.discounts.get, { id }),
  });
  if (response.data) {
    return new DiscountObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteDiscount = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.discounts.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 **/
export const publishDiscount = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.discounts.update, { id }),
    params: { is_published: true },
  });
  if (response.data) {
    return new DiscountObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 **/
export const unPublishDiscount = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.discounts.update, { id }),
    params: { is_published: false },
  });
  if (response.data) {
    return new DiscountObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     code
 * }}
 */
export const checkValidDiscount = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.discounts.validate_code,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
