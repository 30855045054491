import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Help } from '@material-ui/icons';
import Backdrop from './Backdrop';
import Button from './Form/Button';
import { TourGuide } from './TourContent';

export const ModalDeniedOver = ({
  title = 'This Action Is Denied',
  description = 'This Action Is Denied',
  btnText = null,
  btnLink = null,
  onClose,
}) => (
  <div className="c-modal__denied">
    <div className="c-modal__denied-body">
      <h4 className="c-modal__denied-title">{title}</h4>
      <p className="c-modal__denied-description">{description}</p>
      {((btnText && btnLink) || onClose) && (
        <div className="c-modal__denied-buttons">
          {onClose && (
            <Button color="light" onClick={onClose}>
              Close
            </Button>
          )}
          {btnText && btnLink && <Button link={btnLink}>{btnText}</Button>}
        </div>
      )}
    </div>
  </div>
);

ModalDeniedOver.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
  onClose: PropTypes.func,
};

const Modal = (props) => {
  const {
    children,
    guide,
    show,
    lg,
    sticky,
    disabled,
    tabIndex,
    auto,
    title,
    description,
    contentClass,
    bodyClass,
    rightHeader,
    leftHeader,
    buttonComponent,
    bigButtonComponent,
    saveBtnComponent,
    saveBtnText,
    closeBtnText,
    saveBtnDisabled,
    saveLoading,
    backdropCloseable,
    onClose,
    onSave,
    saveBtn,
    closeBtn,
    disableFooter,
    disableHeader,
    dataTour,
  } = props;
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (show) setOpen(true);
      else setOpen(false);
    }, 300);
  }, [show]);
  const classNamesObj = {
    open,
    'c-modal--lg': lg,
    'c-modal--sticky': sticky,
    'c-modal--auto-width': auto,
  };
  if (tabIndex) classNamesObj[`c-modal--tab-${tabIndex}`] = true;
  const classes = classNames('c-modal', classNamesObj);

  return show ? (
    <>
      <Backdrop show={show} tabIndex={tabIndex} onClick={backdropCloseable ? onClose : () => {}} />
      {guide && guide.length > 0 ? (
        <TourGuide
          disableInteraction
          steps={guide}
          startAt={tourStep}
          getCurrentStep={setTourStep}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
        />
      ) : null}
      <div data-tour={dataTour || undefined} className={classes} onClick={backdropCloseable ? onClose : () => {}}>
        <div className={classNames('c-modal-content', contentClass)} onClick={(e) => e.stopPropagation()}>
          {!disableHeader && (
            <div className="c-modal-header">
              {closeBtn && (
                <button className="c-modal-header__close" id="modalHeaderClose" onClick={onClose}>
                  <i className="fas fa-times" />
                </button>
              )}
              {leftHeader}
              {(title || description) && (
                <div className="c-modal-header-head">
                  {title && (
                    <div className="c-modal-header__title-container">
                      <h2 className="c-modal-header__title">{title}</h2>
                      {guide && (
                        <div className="c-modal-header__guide" onClick={() => setIsTourOpen(true)}>
                          <Help />
                        </div>
                      )}
                    </div>
                  )}
                  {description && <p className="c-modal-header__description">{description}</p>}
                </div>
              )}
              {rightHeader}
            </div>
          )}
          <div
            className={classNames('c-modal-body', bodyClass, {
              'c-modal-body--disabled': disabled,
            })}
          >
            {disabled && <div className="c-modal-body__over-disabled" />}
            {children}
          </div>
          {!disableFooter && (
            <div className="c-modal-footer">
              {closeBtn && (
                <Button type="button" color={'light'} id="modalCancelBtn" onClick={onClose}>
                  {closeBtnText}
                </Button>
              )}
              <div className="c-modal-footer__buttons">
                {buttonComponent}
                {saveBtn &&
                  (saveBtnComponent || (
                    <Button id="modalSaveBtn" disabled={saveBtnDisabled} loading={saveLoading} onClick={onSave}>
                      {saveBtnText}
                    </Button>
                  ))}
              </div>
            </div>
          )}

          {bigButtonComponent && <div className="c-modal-footer__big-button">{bigButtonComponent}</div>}
        </div>
      </div>
    </>
  ) : null;
};

Modal.propTypes = {
  children: PropTypes.any,
  guide: PropTypes.any,
  auto: PropTypes.bool,
  lg: PropTypes.bool,
  disabled: PropTypes.bool,
  sticky: PropTypes.bool,
  show: PropTypes.bool,
  title: PropTypes.string,
  dataTour: PropTypes.string,
  description: PropTypes.string,
  contentClass: PropTypes.any,
  bodyClass: PropTypes.any,
  rightHeader: PropTypes.any,
  leftHeader: PropTypes.any,
  buttonComponent: PropTypes.any,
  bigButtonComponent: PropTypes.any,
  saveBtnComponent: PropTypes.any,
  closeBtnText: PropTypes.string,
  saveBtnText: PropTypes.string,
  saveBtnDisabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  saveBtn: PropTypes.bool,
  saveLoading: PropTypes.bool,
  backdropCloseable: PropTypes.bool,
  closeBtn: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disableHeader: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

Modal.defaultProps = {
  lg: false,
  sticky: false,
  disabled: false,
  auto: false,
  show: false,
  saveBtn: true,
  saveBtnDisabled: false,
  saveLoading: false,
  backdropCloseable: false,
  closeBtn: true,
  disableFooter: false,
  disableHeader: false,
  saveBtnText: 'Save',
  closeBtnText: 'Close',
  onClose: () => {},
  onSave: () => {},
};

export default Modal;
