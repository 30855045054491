import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import { MARKETPLACE_URL } from '../../../../config/appConfig';

const PreviewSetupSoftware = ({ onStarted }) => (
  <div className="c-setup-system__preview">
    <h1>Meet your powerful cloud-based assistant, working 24/7</h1>
    <div>
      <p>
        Beautster custom software is built based on almost 1100 interviews with salon and spa owners all over Canada. We
        automate scheduling, client and staff management, payment processing, and marketing with all-in-one software
        solutions. It is hosted conveniently on the cloud, so your info is backed up whenever you log in. It&apos;s the
        best value for the lowest price in Canada, and we invite you to give it a try for free.
      </p>
    </div>

    <div className="d-flex align-center">
      <Button color="primary" onClick={onStarted}>
        Active Software
      </Button>
      <Button link={`${MARKETPLACE_URL}/landing-software`} target="_blank" color="light" className="ml-3">
        Learn More
      </Button>
    </div>
  </div>
);

PreviewSetupSoftware.propTypes = {
  onStarted: PropTypes.func,
};

export default PreviewSetupSoftware;
