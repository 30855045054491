import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import StaffHourObject from '../objects/StaffHourObject';

const { GET, POST, PUT, DELETE } = ApiMethods;

/**
 * @param params {{
 *     staff,
 *     week_day,
 *     start_time,
 *     end_time
 * }}
 */
export const createStaffHours = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.staffWorkingHours.create,
  });
  if (response.data) {
    return Promise.resolve(new StaffHourObject(response.data));
  } else {
    return Promise.reject(response);
  }
};
/**
 * @param id
 * @param params {{
 *     staff,
 *     week_day,
 *     start_time,
 *     end_time
 * }}
 */
export const updateStaffHours = async (id, params) => {
  const response = await callApi({
    method: PUT,
    params,
    url: createURL(ApiRoutes.staffWorkingHours.update, { id }),
  });
  if (response.data) {
    return Promise.resolve(new StaffHourObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param page
 * @param limit
 * @param staff
 */
export const getStaffHoursList = async ({ page, limit, staff }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    params: {
      limit,
      offset: (page - 1) * limit,
      staff,
    },
    url: ApiRoutes.staffWorkingHours.list,
  });
  if (response.data) {
    return Promise.resolve({
      ...response.data,
      results: (response.data.results || []).map((i) => new StaffHourObject(i)),
    });
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getStaffHours = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.staffWorkingHours.get, { id }),
  });
  if (response.data) {
    return Promise.resolve(new StaffHourObject(response.data));
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteStaffHours = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.staffWorkingHours.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
