import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/UIKit/Form/Button';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import PromotionLmoFormModal from '../../Common/Promotion/PromotionLmo/PromotionLmoFormModal';
import { getLmoList } from '../../../api';
import useApi from '../../../utilities/useApi';
import PromotionLmoItem from '../../Common/Promotion/PromotionLmo/PromotionLmoItem';
import PromotionLmoDetailModal from '../../Common/Promotion/PromotionLmo/PromotionLmoDetailModal';
import { AppContext } from '../../../context/providers';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
// import { DashboardNotificationConnection } from '../../Common/Dashboard/DashboardNotifications';
import ConnectStripAlertModal from '../../Common/Stripe/ConnectStripAlertModal';
import usePermission from '../../../utilities/usePermission';
import CampaignModal from '../../Common/Campaign/CampaignModal';

const URL_PAGE = '/promotions/lmos/';

const PromotionLmoPage = ({ history, match }) => {
  const [showCampaign, setShowCampaign] = useState(false);
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [connectStrip, setConnectStrip] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const { business, setHeader } = useContext(AppContext);
  const [, loading] = useApi(getLmoList, { page: true, key: 'lmo' }, setData);

  const { access: _access } = usePermission('lmo', 'crud');
  const { access: _publish } = usePermission('lmo', 'publish', 'modify');
  const { access: _unpublish } = usePermission('lmo', 'unpublish', 'modify');
  const { access: _send_campaign } = usePermission('deal', 'send_campaign', 'modify');
  const { access: _campaign_report } = usePermission('deal', 'campaign_report', 'view');

  useEffect(() => {
    setHeader('Promotion / Last Minutes Openings');
  }, [setHeader]);

  useEffect(() => {
    setConnectStrip(business && !business?.connected_to_stripe);
  }, [business]);

  useEffect(() => {
    if (match.path.includes('campaign')) {
      handleOpenCampaign();
    } else if (match.params.id) {
      handleOpenDetail();
    } else if (match.path === `${URL_PAGE}add`) {
      handleOpenForm();
    }
  }, [match]);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    if (opened) history.replace(URL_PAGE);
  };
  const handleOpenDetail = () => setShowDetail(true);
  const handleCloseDetail = (opened = true) => {
    setShowDetail(false);
    if (opened) history.replace(URL_PAGE);
  };
  const handleOpenCampaign = () => setShowCampaign(true);
  const handleCloseCampaign = (opened = true) => {
    setShowCampaign(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveForm = (item) => {
    if (typeof item === 'number' || typeof item === 'string') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => String(i.id) === String(item));
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    handleCloseForm();
    handleCloseDetail();
    getLmoList().then(setData);
  };

  const handleChangePublish = (id, is_published, type = 'publish') => {
    if (typeof id === 'number' || typeof id === 'string') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => String(i.id) === String(id));

      if (type === 'campaign') {
        updateData[ind].campaign.status = 'sent';
      } else {
        updateData[ind].is_published = is_published;
        updateData[ind].status = is_published ? 'published' : 'draft';
      }
      setData({ ...data, results: updateData });
    }
  };

  return (
    <>
      {showCampaign && <CampaignModal type="lmos" onClose={handleCloseCampaign} />}
      {showForm && _access?.modify && <PromotionLmoFormModal onSave={handleSaveForm} onClose={handleCloseForm} />}
      {showDetail && _access?.view && <PromotionLmoDetailModal onSave={handleSaveForm} onClose={handleCloseDetail} />}
      <HeadOptions
        rightElement={
          _access?.modify &&
          !(business && !business?.connected_to_stripe) && (
            <Button sm onClick={handleOpenForm}>
              New Promotion
            </Button>
          )
        }
      />
      {_access?.view ? (
        <>
          {connectStrip && <ConnectStripAlertModal onClose={() => setConnectStrip(false)} />}
          {loading && !data ? (
            <PageLoading />
          ) : !!data && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table c-table--sm c-table--clickable">
                  <table>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th className="text-center">Discount</th>
                        <th className="text-center">Available</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Publish</th>
                        {_send_campaign && <th className="text-center">Send Campaign</th>}
                        {_campaign_report && <th className="text-center">Campaign Info</th>}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <PromotionLmoItem
                          key={item.id}
                          access={{
                            publish: _publish,
                            unpublish: _unpublish,
                            send_campaign: _send_campaign,
                            campaign_report: _campaign_report,
                          }}
                          item={item}
                          page_url={URL_PAGE}
                          onChangePublish={handleChangePublish}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Promotion LMO Exists"
              description="Use bottom button to add your first promotion LMO"
              button={<Button link={`${URL_PAGE}add`}>ADD NEW Promotion LMO</Button>}
              icon="fas fa-hourglass-half"
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

PromotionLmoPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default PromotionLmoPage;
