import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { EditRounded, PublishRounded, DraftsRounded, Person, Category, GroupWork } from '@material-ui/icons';
import { getPriceFormat } from '../../../utilities/Price';
// import { getDateFromString, getFormatBriefDate } from '../../../utilities/DateHelpers';
import { handleApiSave } from '../../../utilities/useApi';
import { partialUpdateBookablePhoto } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';

const BookablePhotoItem = ({ page_url, item, isModify, onSave }) => {
  const [published, setPublished] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPublished(item?.is_published);
  }, [item]);

  const history = useHistory();

  const onChangePublish = () => {
    handleApiSave(null, partialUpdateBookablePhoto, { is_published: !published }, item.id, { loadingFn: setLoading })
      .then(({ is_published }) => {
        setPublished(is_published);
        onSave();
      })
      .catch(() => {});
  };

  return (
    <div
      className={classnames('c-card-item', {
        'c-card-item--clickable': isModify,
      })}
      onClick={isModify ? () => history.push(`${page_url}${item.id}/edit`) : undefined}
    >
      {loading && <PageLoading over />}
      <div className="c-card-item__image-container">
        {isModify && (
          <div className="c-card-item__actions" onClick={(e) => e.stopPropagation()}>
            {!published ? (
              <span className="c-card-item__actions-item" onClick={onChangePublish}>
                <PublishRounded />
                <span className="c-card-item__actions-item-text">Publish</span>
              </span>
            ) : (
              <span className="c-card-item__actions-item" onClick={onChangePublish}>
                <DraftsRounded />
                <span className="c-card-item__actions-item-text">Draft</span>
              </span>
            )}
            <span className="c-card-item__actions-item" onClick={() => history.push(`${page_url}${item.id}/edit`)}>
              <EditRounded />
              <span className="c-card-item__actions-item-text">Edit</span>
            </span>
          </div>
        )}
        <img src={item.image} alt={`c-card-item__image${item.id}`} className="c-card-item__image" />
      </div>

      <div className="c-card-item__detail">
        {(item.service_type_name || item.staff_name || item.service_name) && (
          <span className="c-card-item__title">
            {!item.service_name && !item.service_name && item.service_type_name && (
              <span className="c-card-item__title">
                <small>Service Type: </small> <br />
                <strong>
                  <GroupWork /> {item.service_type_name}
                </strong>
              </span>
            )}
            {item.service_name && (
              <span className="c-card-item__title">
                <small>Service Name: </small> <br />
                <strong>
                  <Category /> {item.service_name}
                </strong>
              </span>
            )}
            {item.staff_name && (
              <span className="c-card-item__title">
                <small>Staff Name: </small> <br />
                <strong>
                  <Person /> {item.staff_name}
                </strong>
              </span>
            )}
          </span>
        )}
        <span className="c-card-item__price">From {getPriceFormat(item.price)}</span>
      </div>
      <div className="c-card-item__info">
        <span className="c-card-item__date">{item.created_at}</span>
        {/*<span className="c-card-item__date">{getFormatBriefDate(getDateFromString(item.created_at))}</span>*/}
        <span
          className={classnames('c-card-item__status', {
            'c-card-item__status-published': published,
          })}
        >
          {published ? 'Published' : 'Draft'}
        </span>
      </div>
    </div>
  );
};

BookablePhotoItem.propTypes = {
  page_url: PropTypes.string,
  isModify: PropTypes.bool,
  item: PropTypes.any,
  onSave: PropTypes.func,
};

export default BookablePhotoItem;
