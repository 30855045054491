import React, { useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import Modal from '../../UIKit/Modal';
import { ControlInput } from '../../UIKit/Form/Input';
import { ControlSelect } from '../../UIKit/Form/Select';
import { Controller, useForm } from 'react-hook-form';
import { PageLoading } from '../../UIKit/Spinner';
import Button from '../../UIKit/Form/Button';
import ServiceMultiSelect, { InputServiceMultiSelect } from '../../Selections/ServiceMultiSelect';
import { DealExpireAfterChoice, getValueLabel } from '../../../../utilities/constants/choices';
import { handleChangeDiscount } from '../../../../utilities/HandleErrors';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import { createDiscount, deleteDiscount, getDiscount, updateDiscount } from '../../../../api';
import CampaignInput from '../../Campaign/CampaignInput';
import toaster from 'toasted-notes';

// eslint-disable-next-line react/prop-types
const PromotionDiscountFormModal = ({ onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [services, setServices] = useState([]);
  const [unit, setUnit] = useState('%');

  const { control, errors, handleSubmit, setError, clearErrors, watch } = useForm();

  const { id } = useParams();

  const [data, loading] = useApiById(getDiscount, undefined, (res) => {
    if (res) setUnit(res.unit);
  });

  const handleUnit = (val) => {
    clearErrors('amount');

    const value = watch().amount;
    if (val === '%') handleChangeDiscount('amount', value, setError, clearErrors);

    setUnit(val);
  };

  const handleClose = () => {
    if (showServices) {
      setShowServices(false);
    } else {
      onClose();
    }
  };

  const onSaveForm = (data) => {
    const body = {
      ...data,
      amount_type: unit === '%' ? 'percent' : 'currency',
    };
    if (data.services.length > 0) {
      handleApiSave(createDiscount, updateDiscount, body, id, { setError, loadingFn: setSaveLoading })
        .then(onSave)
        .catch(() => {});
    } else {
      toaster.notify(() => (
        <div className="alert alert-danger">Please add at least one service. services field cannot be empty</div>
      ));
    }
  };

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete this discount',
      message: 'Are you sure to delete this discount.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteDiscount, id, options).then((isSuccess) => {
      if (isSuccess) onSave(+id);
    });
  };

  return (
    <Modal
      show
      sticky
      disableFooter
      bodyClass="px-0"
      onClose={handleClose}
      onSave={handleSubmit(onSaveForm)}
      saveLoading={saveLoading}
      buttonComponent={
        !!id && (
          <Button color="danger" loading={delLoading} onClick={handleDelete}>
            Delete
          </Button>
        )
      }
      title={!!id ? 'Edit Discount Type' : 'New Discount Type'}
    >
      {!!id && loading ? (
        <PageLoading />
      ) : (
        <form onSubmit={handleSubmit(onSaveForm)}>
          <div
            className={classnames('c-promotion__form-card-services', {
              'd-none': !showServices,
            })}
          >
            <Controller
              control={control}
              name="services"
              defaultValue={data?.services ? data.services : services}
              render={({ onChange, value }) => (
                <ServiceMultiSelect
                  value={value}
                  show={showServices}
                  onChange={(idList) => {
                    setShowServices(false);
                    onChange(idList);
                  }}
                  onData={(data, listId) => {
                    setServices(data);
                    if (!id) onChange(listId);
                  }}
                  onCancel={() => setShowServices(false)}
                />
              )}
            />
          </div>
          <div
            className={classnames('c-promotion__form-card-content', {
              'd-none': showServices,
            })}
          >
            <ControlInput
              control={control}
              name="name"
              label={'Discount Name'}
              rules={{ required: 'Discount Name is required' }}
              defaultValue={data?.name ? data.name : ''}
              placeholder={'e.g. Senior Citizens'}
              errorMessage={errors?.name?.message}
            />
            <ControlInput
              control={control}
              name="code"
              label={'Code'}
              defaultValue={data?.code ? data.code : ''}
              placeholder={'Enter your code (optional)'}
              errorMessage={errors?.code?.message}
            />
            <InputServiceMultiSelect
              value={watch()?.services}
              onClick={() => setShowServices(true)}
              errorMessage={errors?.services?.message}
              error={!!errors.services}
              services={services}
            />
            <div className="c-form__group-row align-items-end">
              <ControlInput
                control={control}
                name="amount"
                rules={{
                  min: {
                    value: 0,
                    message: 'Discount cannot be less than 0',
                  },
                  max:
                    unit === '%'
                      ? {
                          value: 100,
                          message: 'Discount cannot be more than 100',
                        }
                      : undefined,
                  required: 'Discount is required',
                }}
                defaultValue={data?.amount ? data.amount : ''}
                type="tel"
                unit={unit}
                min={0}
                max={unit === '%' ? 100 : undefined}
                label={'Discount Value'}
                onChangeText={(v) => handleChangeDiscount('amount', v, setError, clearErrors)}
                placeholder={'e.g. 30 (30% off)'}
                errorMessage={errors?.amount?.message}
              />

              <div className="c-button-group" style={{ marginBottom: !!errors.amount ? '30px' : '10px' }}>
                <Button color={unit === '%' ? 'main' : 'light'} type="button" onClick={() => handleUnit('%')}>
                  %
                </Button>
                <Button color={unit === '$' ? 'main' : 'light'} type="button" onClick={() => handleUnit('$')}>
                  $
                </Button>
              </div>
            </div>
            <ControlInput
              control={control}
              name="usage_limit"
              rules={{ required: 'Limit Total Number of Uses is required' }}
              defaultValue={data?.usage_limit ? data.usage_limit : ''}
              type="tel"
              min={0}
              label={'Limit Total Number of Uses'}
              placeholder={'Limit Total Number of Uses'}
              errorMessage={errors?.usage_limit?.message}
            />
            <ControlSelect
              control={control}
              name="expire_after"
              rules={{ required: 'Expire after is required' }}
              defaultValue={data?.expire_after ? data.expire_after.toString() : ''}
              options={getValueLabel(DealExpireAfterChoice)}
              label={'Expire after'}
              // maxMenuHeight={200}
              menuPlacement="top"
              errorMessage={errors?.expire_after?.message}
            />

            <CampaignInput hasMarketplace data={data} errors={errors} control={control} />
          </div>
          <div
            className={classnames('c-promotion__form-card-footer', {
              'd-none': showServices,
            })}
          >
            <Button sm color="light" type="button" onClick={onClose}>
              Cancel
            </Button>
            <div className="d-flex align-items-center">
              {!!id && (
                <Button color="danger" type="button" loading={delLoading} onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <Button type="submit" className="ml-2" loading={saveLoading}>
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default PromotionDiscountFormModal;
