/**
 *
 * @param appointment {{
 *     id
 *     invoice
 *     payment_status
 *     lines,
 *     client
 *     client_name,
 *     client_email,
 *     discount
 *     sale_channel
 *     suggested_times
 *     salon
 *     status
 *     note
 *     created_at
 *     updated_at
 * }}
 * @constructor
 */
import { getDateFromString } from '../utilities/DateHelpers';
import AppointmentLineObject from './AppointmentLineObject';

function AppointmentObject(appointment) {
  this.id = appointment.id;
  this.invoice = appointment.invoice || null;
  this.payment_status = appointment.payment_status || null;
  this.client = appointment.client;
  this.client_name = appointment.client_name;
  this.suggested_times = appointment.suggested_times || [];
  this.client_email = appointment.client_email;
  this.reserved_date = appointment.reserved_date;
  this.sale_channel = appointment.sale_channel;
  this.note = appointment.note;
  this.lines = (appointment.lines || []).map((i) => new AppointmentLineObject(i, this));
}

/**
 *
 * @param appointment {{
 *     id
 *     lines,
 *     payment_status
 *     invoice
 *     client
 *     client_name,
 *     client_email,
 *     reserved_date
 *     discount
 *     suggested_times
 *     sale_channel
 *     salon
 *     status
 *     note
 *     created_at
 *     updated_at
 * }}
 * @constructor
 */
export function AppointmentCalendarObject(appointment) {
  this.id = appointment.id;
  this.invoice = appointment.invoice || null;
  this.payment_status = appointment.payment_status || null;
  this.client = appointment.client;
  this.client_name = appointment.client_name;
  this.client_email = appointment.client_email;
  this.note = appointment.note;
  this.reserved_date = appointment.reserved_date;
  this.status = appointment.status;
  this.sale_channel = appointment.sale_channel;
  this.created_at = appointment.created_at;
  this.suggested_times = appointment.suggested_times || [];
  this.date = getDateFromString(appointment.reserved_date);
  this.lines = (appointment.lines || []).map((i) => new AppointmentLineObject(i, this));
}

/**
 *
 * @param appointment {{
 *     id
 *     invoice
 *     payment_status
 *     lines,
 *     client
 *     client_name,
 *     client_email,
 *     discount
 *     sale_channel
 *     salon
 *     status
 *     note
 *     reserved_date
 *     created_at
 *     updated_at
 * }}
 * @constructor
 */
export function AppointmentListObject(appointment) {
  this.id = appointment.id;
  this.invoice = appointment.invoice || null;
  this.booking_mode = appointment.booking_mode || null;
  this.payment_status = appointment.payment_status || null;
  this.client = appointment.client;
  this.client_name = appointment.client_name;
  this.client_full_name = appointment.client_full_name || appointment.client_name;
  this.client_email = appointment.client_email;
  this.status = appointment.status;
  this.sale_channel = appointment.sale_channel;
  this.note = appointment.note;
  this.reserved_date = appointment.reserved_date;
  this.created_at = appointment.created_at;
  this.suggested_times = appointment.suggested_times || [];

  this.lines = (appointment.lines || []).map((i) => new AppointmentLineObject(i, this));
}

export default AppointmentObject;
