import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PromotionGiftCards from './PromotionGiftCards';
import Tab from '../../../Common/UIKit/Tab';
import PromotionSoldGiftCards from './PromotionSoldGiftCards';

const PromotionGiftCardsPage = ({ history, location }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('/promotions/gift-cards/all')) {
      setTabIndex(0);
    } else if (pathname.includes('/promotions/gift-cards/sold')) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  const handleSelectTab = (ind) => {
    setTabIndex(ind);
    if (ind === 1) history.push('/promotions/gift-cards/sold');
    else history.push('/promotions/gift-cards/all');
  };

  const tabs = ['Gift Cards', 'Sold'];
  const renderRightEl = <Tab tabs={tabs} tabHeadClassName={'p-0'} onSelect={handleSelectTab} selected={tabIndex} />;

  const renderPages = () => {
    switch (tabIndex) {
      case 0:
        return <PromotionGiftCards headerEl={renderRightEl} />;
      case 1:
        return <PromotionSoldGiftCards headerEl={renderRightEl} />;
      default:
        return <PromotionGiftCards headerEl={renderRightEl} />;
    }
  };
  return renderPages();
  // return (
  //     <>
  //         {/*<HeadPage title={`Promotion Gift Cards ${tabIndex === 1 ? ' / Sold' : ''}`} rightElement={renderRightEl} />*/}
  //         {renderPages()}
  //     </>
  // );
};

PromotionGiftCardsPage.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
};

export default PromotionGiftCardsPage;
