import React, { useContext, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import DashboardBox from '../../Common/Dashboard/DashboardBox';
import { getPriceFormat } from '../../../utilities/Price';
import useApi from '../../../utilities/useApi';
import { getAnalyticsSales } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { getDateInfo } from '../../../utilities/DateHelpers';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';
import { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { useHistory } from 'react-router-dom';

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          // beginAtZero: true,
          stepSize: 5,
          maxTicksLimit: 5,
          // Include a dollar sign in the ticks
          callback: function (value) {
            // return 'CA $' + value;
            return `$${value}`;
          },
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          // stepSize: 4,
          maxTicksLimit: 15,
        },
      },
    ],
  },
};

// eslint-disable-next-line react/prop-types
const AnalyticsSalesPage = ({ headerEl }) => {
  const [data, loading] = useApi(getAnalyticsSales, { key: 'business.sales_statistics' });
  const { setHeader } = useContext(AppContext);
  const { access: _access } = usePermission('business', 'sales_statistics', 'view');
  const history = useHistory();
  useEffect(() => {
    setHeader('Analytics / Sales Summary');
  }, [setHeader]);
  const decorateClients = (sales) => {
    const { labels, data } = sales.reduce(
      (acc, s) => {
        acc.labels.push(s.date);
        acc.data.push(s.sale);
        return acc;
      },
      { labels: [], data: [] },
    );
    return {
      labels,
      datasets: [
        {
          label: 'Sales',
          data,
          // borderColor: 'rgba(255, 99, 132, 1)',
          borderColor: '#fc5622',
          borderWidth: 2,
          lineTension: 0,
          fill: false,
        },
      ],
    };
  };
  return (
    <>
      <HeadPage leftElement={headerEl} />
      {_access ? (
        <>
          {loading ? (
            <PageLoading />
          ) : (
            <>
              <div className="c-dashboard__box-wrapper">
                <DashboardBox
                  title="Total Sales"
                  className="cursor-pointer"
                  description={getPriceFormat(+data.total_sale || 0)}
                  onClick={() => history.push('/sales/transactions/')}
                  icon="fa fa-usd"
                  grid={3}
                />
                <DashboardBox
                  title={`Sales in ${getDateInfo(new Date()).monthName}`}
                  className="cursor-pointer"
                  description={getPriceFormat(+data.this_month_sale || 0)}
                  onClick={() => history.push('/sales/transactions/')}
                  icon={+data.this_month_sale > 0 ? 'fas fa-arrow-up' : 'fa fa-usd'}
                  iconStyles={+data.this_month_sale > 0 ? { fontSize: '26px', color: '#43A047' } : undefined}
                  grid={3}
                />
                <DashboardBox
                  title="Sales this week"
                  className="cursor-pointer"
                  description={getPriceFormat(+data.this_week_sale || 0)}
                  onClick={() => history.push('/sales/transactions/')}
                  icon={+data.this_week_sale > 0 ? 'fas fa-arrow-up' : 'fa fa-usd'}
                  iconStyles={+data.this_week_sale > 0 ? { fontSize: '26px', color: '#43A047' } : undefined}
                  grid={3}
                />
              </div>

              <div className="c-dashboard__content">
                <div className="c-dashboard__header">
                  <div className="c-dashboard__head">
                    <h1 className="c-dashboard__title">Sales within the last 30 days</h1>
                  </div>
                </div>
                <div className="c-dashboard__body">
                  {data.sale_per_day ? (
                    <div className="c-dashboard__chart">
                      <Line data={decorateClients(data.sale_per_day)} width={100} height={300} options={chartOptions} />
                    </div>
                  ) : (
                    <span className="c-dashboard__body-empty">There is not any sales record</span>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default AnalyticsSalesPage;
