import React, { useContext, useEffect, useMemo } from 'react';
import NotFoundData from '../../Common/UIKit/NotFoundData';
import Button from '../../Common/UIKit/Form/Button';
import { PageLoading } from '../../Common/UIKit/Spinner';
import { AppContext } from '../../../context/providers';
import useApi from '../../../utilities/useApi';
import { getPlans } from '../../../api';
import { getDateFromString } from '../../../utilities/DateHelpers';
import CountDown from '../../Common/UIKit/CounterDown';

const SubscriptionPage = () => {
  const { business, user, setHeader } = useContext(AppContext);
  const [data] = useApi(getPlans);

  useEffect(() => {
    setHeader('Settings / Subscription');
  }, [setHeader]);

  const plan = useMemo(() => {
    if (data?.results && business) {
      return (data?.results || []).find((i) => String(i.id) === String(business.plan));
    }
    return null;
  }, [data, business]);

  const CurrentPlan = plan ? (
    <p>
      <strong>
        <small>Current Plan: </small>
        {plan.name}
      </strong>
    </p>
  ) : null;

  // eslint-disable-next-line react/prop-types
  const TimeExpire = ({ expireDate = null, isTrial = false }) => {
    const now = new Date();
    const date = getDateFromString(expireDate);
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (isTrial) return <CountDown remaining={(diffTime / 1000).toFixed()} />;

    if (diffDays <= 3) {
      return <CountDown remaining={diffTime} />;
    }

    return null;
  };
  return (
    <div className="c-panel-content mt-3">
      {!business ? (
        <PageLoading />
      ) : (
        <>
          {business?.subscribed ? (
            <NotFoundData
              icon=""
              title="Congratulation! You Have Subscribed"
              description={
                <>
                  {CurrentPlan}
                  <TimeExpire expireDate={business?.subscription_end_at} />
                </>
              }
              button={
                <Button lg link="/settings/plans">
                  Upgrade Plan
                </Button>
              }
            />
          ) : user?.trialing ? (
            <NotFoundData
              icon=""
              title="Trial Plan"
              description={
                <>
                  {CurrentPlan}
                  <p>Your trial ends on:</p>
                  <p>
                    <strong>{user?.trial_end_at}</strong>
                  </p>

                  <TimeExpire expireDate={user?.trial_end_at} isTrial />
                </>
              }
              button={
                <>
                  {business?.subscription_status !== 'subscribed' && (
                    <Button lg link="/settings/plans">
                      Go Premium
                    </Button>
                  )}
                </>
              }
            />
          ) : !user?.trialing && user?.trial_end_at ? (
            <NotFoundData
              icon=""
              title="Subscribe today"
              description={
                <>
                  <p className="text-danger">
                    <strong>Your trial plan has been ended</strong>
                  </p>
                  <p>
                    <strong>Subscribe Now</strong>
                  </p>
                </>
              }
              button={
                <Button lg link="/settings/plans">
                  Subscribe Plan
                </Button>
              }
            />
          ) : (
            <NotFoundData
              icon=""
              title="Subscribe today"
              description={
                <>
                  <p className="text-danger">
                    <strong>Your subscription has been expired</strong>
                  </p>
                  <p>
                    <strong>Subscribe new plan</strong>
                  </p>
                </>
              }
              button={
                <Button lg link="/settings/plans">
                  Subscribe Plan
                </Button>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default SubscriptionPage;
