import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/UIKit/Form/Button';
import ClientFormModal from '../../Common/Client/ClientFormModal';
import ClientsImportModal from '../../Common/Importer/Clients/ClientsImportModal';
import { getClientList } from '../../../api';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import SearchBar from '../../Common/UIKit/HeadOptions/SearchBar';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import Pagination from '../../Common/UIKit/Pagination';
import useApi, { handleApi } from '../../../utilities/useApi';
import { AppContext } from '../../../context/providers';
import { AppointmentSaleChannelChoice } from '../../../utilities/constants/choices';
import ImageName from '../../Common/UIKit/ImageName';
import Dropdown from '../../Common/UIKit/Dropdown';
import { TourGuide } from '../../Common/UIKit/TourContent';
import { clientSteps } from '../../../config/tourSteps';
import usePermission from '../../../utilities/usePermission';

const ClientsPage = ({ history, location }) => {
  const [data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);

  const { setHeader } = useContext(AppContext);
  useEffect(() => {
    setHeader('Clients', { guideAction: data ? setIsTourOpen : null });
  }, [setHeader, data]);

  const [, loading] = useApi(getClientList, { search: true, page: true, key: 'client' }, setData);
  const handleOpenImport = () => setShowImport(true);
  const handleCloseImport = () => setShowImport(false);

  const handleOpenForm = () => {
    setShowForm(true);

    if (isTourOpen) {
      setIsTourOpen(false);
      setTourStep(1);
    }
  };
  const handleCloseForm = () => {
    if (tourStep === 1) {
      setIsTourOpen(true);
    }
    setShowForm(false);
  };

  const handleSaveForm = (_data) => {
    setShowForm(false);
    setData({
      ...data,
      results: [...data.results, _data],
    });
    // const page = getQueryUri(location.search, 'page') || 1;
    // console.log('_data', _data)
    // getClientList(searchText, page).then(res => {
    //     setData(res.results || []);
    //     setRes(res);
    //     setLoading(false);
    // });
  };

  const { access: _access } = usePermission('client', 'crud');
  const { access: _import } = usePermission('client', 'import_file');

  const handleImport = () => {
    setShowImport(false);
    if (location.search === '') {
      handleApi(getClientList, {}, { loadingFn: setIsLoading }).then((res) => {
        setData(res);
      });
    } else {
      history.push('/clients');
    }
  };

  const handleOpenOptions = () => {
    if (isTourOpen) {
      setTourStep(2);
      setIsTourOpen(false);
    }
  };

  // const { access: _access } = usePermission('client', 'crud');

  const options = [
    {
      type: 'node',
      node: (
        <span id="import-client-opt" className="dropdown-link" onClick={handleOpenImport}>
          Import Clients
        </span>
      ),
    },
  ];
  const rightEl = (
    <>
      {_import?.modify && (
        <Dropdown options={options}>
          <Button id="options" data-tour="options-btn" onClick={handleOpenOptions} color={'main'}>
            Options <i id="client-options" className="fas fa-chevron-down" />
          </Button>
        </Dropdown>
      )}
      {_access?.modify && (
        <Button id="newBtn" data-tour="add-btn" className="ml-3" color="main" onClick={handleOpenForm}>
          New Client
        </Button>
      )}
    </>
  );

  return (
    <>
      {showImport && <ClientsImportModal onImport={handleImport} onClose={handleCloseImport} />}
      {showForm && <ClientFormModal onSave={handleSaveForm} onClose={handleCloseForm} />}
      <HeadOptions
        className="my-3"
        leftElement={
          data?.results?.length > 0 ? <SearchBar placeholder="Search by name, e-mail or mobile number" /> : null
        }
        rightElement={rightEl}
      />
      {_access?.view ? (
        <>
          {(loading || isLoading) && !data ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table c-table--clickable">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Appts booked</th>
                        <th className="text-center">Channel</th>
                        <th className="text-center">No Shows</th>
                        <th className="text-center">Cancellations</th>
                        <th className="text-center">Last appt</th>
                        <th className="text-center">State</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item) => (
                        <tr
                          key={item.id}
                          id={`client-${item.email}`}
                          onClick={() => history.push(`/clients/${item.id}`)}
                        >
                          <td className="text-center">
                            <ImageName className="image" imageSrc={item.avatar} name={item.full_name} />
                          </td>
                          <td>{item.full_name}</td>
                          <td>{item.phone}</td>
                          <td>{item.email}</td>
                          <td className="text-center">{item.appointments_count}</td>
                          <td className="text-center">
                            <small className="font-weight-bold">
                              {AppointmentSaleChannelChoice[item.register_channel]}
                            </small>
                          </td>
                          <td className="text-center">{item.no_shows_count}</td>
                          <td className="text-center">{item.cancelled_count}</td>
                          <td className="text-center">{item.last_appointment_date}</td>
                          <td className="text-center">
                            {!item.is_active ? (
                              <span className="text-danger">Inactive</span>
                            ) : (
                              <span className="text-success">Active</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Client Exists"
              description="Use bottom button to add your first client"
              icon="fas fa-user-plus"
              button={
                _access?.modify ? (
                  <Button id="emptyNewBtn" onClick={handleOpenForm}>
                    ADD NEW CLIENT
                  </Button>
                ) : undefined
              }
            />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
      <TourGuide
        steps={clientSteps}
        startAt={tourStep}
        getCurrentStep={setTourStep}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </>
  );
};

ClientsPage.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default ClientsPage;
