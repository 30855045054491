import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import Checkbox from './Form/Checkbox';
import { Edit, Close } from '@material-ui/icons';
import Input from './Form/Input';
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const HoursRange = ({ title, onChange, value, defaultValue = [8, 18, false], editable = false }) => {
  const [isEdit, setIsEdit] = useState(false);
  const timeFormatter = (value, twelve = true) => {
    const hour = twelve ? Math.floor(Math.floor(value) !== 12 ? value % 12 : value) : Math.floor(value);
    const min = value % 1 === 0.5 ? '30' : '00';
    return `${hour < 10 ? `0${hour}` : hour}:${min}`;
  };
  const tipFormatter = (value) => {
    const unitTime = value >= 12 ? 'PM' : 'AM';
    const time = timeFormatter(value);
    return `${time} ${unitTime}`;
  };
  const handleChangeRage = (val) => {
    const start = `${timeFormatter(val[0], false)}:00`;
    const end = `${timeFormatter(val[1], false)}:00`;
    onChange([start, end, false]);
  };
  const handleChangeClosed = (val) => {
    onChange([value[0], value[1], val]);
  };

  const timeToFormat = (val) => {
    const hour = +val.split(':')[0];
    const min = +val.split(':')[1];
    const m = min === 30 ? 0.5 : 0;
    return hour + m;
  };

  const getValue = (val) => [timeToFormat(val[0]), timeToFormat(val[1])];

  const handleChange = (text, key) => {
    const val1 = key === 0 ? text : value[0];
    const val2 = key === 1 ? text : value[1];
    onChange([val1, val2, value[2]]);
  };
  return (
    <div className="c-hours-range">
      {title && (
        <div className="c-hours-range__header">
          <h3 className="c-hours-range__title" dangerouslySetInnerHTML={{ __html: title }} />
          {editable && (
            <>
              {!isEdit ? (
                <div className="c-hours-range__edit" onClick={() => setIsEdit(true)}>
                  <Edit />
                </div>
              ) : (
                <div className="c-hours-range__form">
                  <Input
                    formClassName="c-hours-range__input"
                    value={value[0]}
                    onChangeText={(t) => handleChange(t, 0)}
                    type="time"
                    label="Start"
                  />
                  <Input
                    formClassName="c-hours-range__input"
                    value={value[1]}
                    onChangeText={(t) => handleChange(t, 1)}
                    type="time"
                    label="End"
                  />
                  <div className="c-hours-range__edit" onClick={() => setIsEdit(false)}>
                    <Close />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div className="c-hours-range__body">
        <Range
          min={0}
          max={24}
          disabled={!!value[2]}
          step={0.5}
          defaultValue={getValue(defaultValue)}
          tipFormatter={tipFormatter}
          value={getValue(value)}
          onChange={handleChangeRage}
        />
        {/* eslint-disable-next-line react/prop-types */}
        <Checkbox onChange={handleChangeClosed} label="Closed" id={title.toLowerCase()} checked={!!value[2]} />
      </div>
    </div>
  );
};

HoursRange.propTypes = {
  title: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  editable: PropTypes.bool,
};

export default HoursRange;
