import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import Modal from '../../UIKit/Modal';
import { getStaff } from '../../../../api';
import { PageLoading } from '../../UIKit/Spinner';
import { useApiById } from '../../../../utilities/useApi';
import ImageName from '../../UIKit/ImageName';
import { ServiceTieredLevelChoice } from '../../../../utilities/constants/choices';

const StaffMemberViewModal = ({ onClose }) => {
  const { id } = useParams();

  const [data, loading] = useApiById(getStaff, id);
  // const [commissions, commissionsLoading] = useApi(getServiceCommission, { infinitePage: true, staff: id });
  return (
    <Modal lg sticky show title="Staff Member" saveBtn={false} onClose={onClose}>
      {loading ? (
        <PageLoading type="box" />
      ) : (
        <div className="c-staff-view">
          <div className="c-staff-view__section c-staff-view__section--row">
            <ImageName className="c-staff-view__img" imageSrc={data.avatar} name={data.name} />
            <div>
              <h4 className="c-staff-view__name">{data.name}</h4>
              <p className="c-staff-view__meta">
                <span className="c-staff-view__meta-label">Tiered Level: </span>
                {ServiceTieredLevelChoice[data.tiered_level]}
              </p>
              <p className="c-staff-view__meta">
                <span className="c-staff-view__meta-label">Email: </span>
                {data.email}
              </p>
              <p className="c-staff-view__meta">
                <span className="c-staff-view__meta-label">Phone Number: </span>
                {data.phone_number}
              </p>
            </div>
          </div>
          {data.note && (
            <div className="c-staff-view__section">
              <h5 className="c-staff-view__section-title">Note:</h5>
              <p>{data.note}</p>
            </div>
          )}
          <div className="c-staff-view__section">
            <h5 className="c-staff-view__section-title">Working Hours:</h5>
            <ul>
              {data.times.map((i) => (
                <li
                  className={classNames('c-staff-view__time', { 'c-staff-view__time--close': !i.is_active })}
                  key={i.id}
                >
                  <span className="c-staff-view__time-label">
                    {i.dayName} {!i.is_active && <span className="c-staff-view__time-label--close">(Inactive)</span>}
                  </span>
                  <span className="c-staff-view__time-value">
                    {i.start_time} - {i.end_time}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Modal>
  );
};

StaffMemberViewModal.propTypes = {
  onClose: PropTypes.func,
};

export default StaffMemberViewModal;
