/**
 *
 * @param category {{
 *     id,
 *     name,
 *     slug,
 *     service_types
 * }}
 * @constructor
 */

function CategoryObject(category) {
  this.id = category.id;
  this.name = category.name;
  this.slug = category.slug;
  // this.subcategories = (category.subcategories || []).map(i => new SubCategoryObject(i));
  this.service_types = (category.service_types || []).map((i) => new ServiceTypeObject(i));
}

/**
 *
 * @param serviceType {{
 *     id,
 *     name,
 *     slug,
 *     services,
 * }}
 * @constructor
 */
function ServiceTypeObject(serviceType) {
  this.id = serviceType.id;
  this.name = serviceType.name;
  this.slug = serviceType.slug;
  this.services = serviceType.services;
}

export default CategoryObject;
