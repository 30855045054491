import React, { useContext, useEffect, useMemo, useState } from 'react';
import useApi from '../../../utilities/useApi';
import { getCommissionList, getTotalServiceCommission } from '../../../api';
import { AppContext } from '../../../context/providers';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import StaffMemberModal from '../../Common/Staff/StaffMember/StaffMemberModal';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Pagination from '../../Common/UIKit/Pagination';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import MonthsFilter from '../../Common/UIKit/HeadOptions/MonthsFilter';
import StaffSelect from '../../Common/Selections/StaffSelect';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import { getPriceFormat } from '../../../utilities/Price';
import { getStringFromDate } from '../../../utilities/DateHelpers';
// import { TourGuide } from '../../Common/UIKit/TourContent';
import { staffCommissionsSteps } from '../../../config/tourSteps';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/staff/commissions/';

// eslint-disable-next-line react/prop-types
const StaffCommissions = ({ headerEl }) => {
  const [data, setData] = useState(null);
  const [totalCommission, setTotalCommission] = useState(0);
  const [showForm, setShowForm] = useState(false);
  // const [isTourSet, setIsTourSet] = useState(false);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  const [, loading] = useApi(
    getCommissionList,
    { search: true, month: true, page: true, staff: true, key: 'commission' },
    setData,
  );
  const { setHeader } = useContext(AppContext);
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const { access: _access } = usePermission('commission', 'crud');

  const guideSteps = useMemo(
    () => (!loading && !(params.id || location.pathname === `${URL_PAGE}add`) ? staffCommissionsSteps : null),
    [params, location, loading],
  );
  useEffect(() => {
    setHeader('Staff / Commissions', { guide: guideSteps });
  }, [setHeader, guideSteps]);

  useEffect(() => {
    if (params.id || location.pathname === `${URL_PAGE}add`) {
      handleOpenForm();
    }
  }, [params, location]);

  // useEffect(() => {
  //   if (!isTourSet && !loading && !(params.id || location.pathname === `${URL_PAGE}add`)) {
  //     setIsTourOpen(true);
  //     setIsTourSet(true);
  //   }
  // }, [params, location, loading]);

  const staff = useMemo(() => {
    const value = getQueryUri('staff');
    return value ? String(value) : null;
  }, [location]);

  const filteredDate = useMemo(() => {
    const month = getQueryUri('month');
    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setMonth(month - 1);
    fromDate.setDate(1);
    toDate.setMonth(month);
    toDate.setDate(30);

    const from_date = getStringFromDate(fromDate);
    const to_date = getStringFromDate(toDate);
    return { from_date, to_date };
  }, [location]);

  useEffect(() => {
    if (staff) {
      const { from_date, to_date } = filteredDate;
      getTotalServiceCommission({ staff, from_date, to_date }).then((res) => {
        setTotalCommission(res.total_commission);
      });
    } else {
      setTotalCommission(0);
    }
  }, [staff, filteredDate]);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = (opened = true) => {
    setShowForm(false);
    if (opened) history.replace(URL_PAGE);
  };

  const handleSaveItem = (item) => {
    if (typeof item === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    handleCloseForm();
    getCommissionList().then(setData);
  };

  const handleFilterStaff = (staff) => {
    const params = setQueryUri({
      ...getQueryUri(),
      staff: staff === '0' ? null : staff,
    });
    history.replace(params);
  };
  return (
    <>
      {showForm && <StaffMemberModal staffCount={data?.count || 0} onClose={handleCloseForm} onSave={handleSaveItem} />}
      <HeadOptions
        leftElement={headerEl}
        rightElement={
          _access?.view && (
            <div data-tour="filters" className="d-flex align-items-center">
              <StaffSelect
                value={staff ? staff : '0'}
                defaultOptions={[{ label: 'All Staffs', value: '0' }]}
                className="c-calendar__staff-filter"
                formGroup={false}
                onChangeValue={handleFilterStaff}
              />
              <MonthsFilter />
            </div>
          )
        }
      />
      {_access?.view ? (
        <>
          {loading ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div data-tour="list" className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Staff Name</th>
                        <th className="text-center">Service Name</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">Tier Amount</th>
                        <th className="text-center">Created At</th>
                        <th className="text-center">Appointment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item, key) => (
                        <tr key={key}>
                          <td>{item.staffName}</td>
                          <td className="text-center">{item.serviceName}</td>
                          <td className="text-center">{item.amount}</td>
                          <td className="text-center">{item.tierAmount}</td>
                          <td className="text-center">{item.createdAt}</td>
                          <td className="text-center">
                            <NavLink
                              id={`apptLink-${item.appointmentId}`}
                              to={`/calendar/appointment/${item.appointmentId}/edit/`}
                            >
                              Appointment Detail
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData
              title="No Commission Exists"
              description="There is not any commission report yet"
              icon="fas fa-comments-dollar"
            />
          )}
          {(staff || (!!data?.results && data.results.length > 0)) && (
            <div data-tour="total" className="c-table c-commission__total c-table min-vh-50">
              <table>
                <tbody>
                  <tr>
                    <td className="text-left">Staff</td>
                    <td className="text-right">
                      <StaffSelect
                        value={staff ? staff : '0'}
                        formGroup={false}
                        maxMenuHeight={150}
                        onChangeValue={handleFilterStaff}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Month</td>
                    <td className="text-right">
                      <MonthsFilter />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Total Commission</td>
                    <td className="text-right">{getPriceFormat(totalCommission)}</td>
                  </tr>
                  <tr>
                    <td className="text-left">From Date</td>
                    <td className="text-right">{filteredDate.from_date}</td>
                  </tr>
                  <tr>
                    <td className="text-left">To Date</td>
                    <td className="text-right">{filteredDate.to_date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {/*<TourGuide*/}
          {/*  steps={staffCommissionsSteps}*/}
          {/*  startAt={tourStep}*/}
          {/*  getCurrentStep={setTourStep}*/}
          {/*  isOpen={isTourOpen}*/}
          {/*  onRequestClose={() => setIsTourOpen(false)}*/}
          {/*/>*/}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default StaffCommissions;
