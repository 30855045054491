import React, { useContext, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import toaster from 'toasted-notes';
import Button from '../../Common/UIKit/Form/Button';
import SectionHead from '../../Common/UIKit/SectionTitle';
import { ControlInput } from '../../Common/UIKit/Form/Input';
import { Controller, useForm } from 'react-hook-form';
import { ControlCheckbox } from '../../Common/UIKit/Form/Checkbox';
import useApi, { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getBusiness, getStaffList, partialUpdateBusiness, updateStaffHours } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import BusinessWorkingHours from '../../Common/Business/BusinessWorkingHours';
import { createBusinessWorkingHours, updateBusinessWorkingHours } from '../../../api/businessWorkingHours';
import BusinessBookingMethod from '../../Common/Business/BusinessBookingMethod';
import { AppContext } from '../../../context/providers';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import ImageSelector from '../../Common/UIKit/ImageSelector';
import BusinessProfileStatic from './BusinessProfileStatic';
import usePermission from '../../../utilities/usePermission';
import { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import BusinessShortenLink from '../../Common/Business/BusinessShortenLink';

// eslint-disable-next-line react/prop-types
const BusinessProfile = ({ headerEl }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [saveWorkingHours, setSaveWorkingHours] = useState(null);
  const [bookingMethod, setBookingMethod] = useState(null);
  const [currentWorkingHours, setCurrentWorkingHours] = useState(null);
  const [workingHours, setWorkingHours] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { setBusiness, setHeader, isMarketplace } = useContext(AppContext);
  const [data, loading] = useApi(getBusiness, {}, setBusiness);
  const { control, errors, handleSubmit, setError } = useForm();
  const { access: _access } = usePermission('business', 'crud');
  useEffect(() => {
    setHeader('Business / Profile');
  }, [setHeader]);

  // Just update staff working hours base on business hours when there is just one staff
  const updateOnlyStaffHours = (params, staff) => {
    const working_hour = staff.times.find((i) => +i.week_day === +params.week_day);
    handleApiSave(undefined, updateStaffHours, { ...params, staff: staff.id }, working_hour.id).then(() => {
      // console.log(res);
    });
  };
  const handleSave = (data) => {
    setHasChanged(false);
    const changedClose = workingHours.find((i) =>
      (currentWorkingHours?.results || []).find((j) => +j.week_day === +i.week_day && i.closed === j.is_active),
    );

    if (changedClose) {
      handleApi(getStaffList, {}, { limit: 50, infinitePage: true }).then(
        ({ count: _staffCount = 0, results: _staffList = [] }) => {
          workingHours.forEach((item) => {
            const itemBody = {
              week_day: item.week_day,
              start_time: item.start_time.split(':').length === 2 ? `${item.start_time}:00` : item.start_time,
              end_time: item.end_time.split(':').length === 2 ? `${item.end_time}:00` : item.end_time,
              is_active: !item.closed,
            };
            handleApiSave(createBusinessWorkingHours, updateBusinessWorkingHours, itemBody, item.id ? item.id : null)
              .then(setSaveWorkingHours)
              .then(() => {
                if (_staffCount === 1) updateOnlyStaffHours(itemBody, _staffList[0]);
                else {
                  const staffListApi = _staffList.map((staff) => {
                    const working_hour = staff.times.find((i) => +i.week_day === +itemBody.week_day);
                    return updateStaffHours(working_hour.id, {
                      is_active: itemBody.is_active,
                      week_day: itemBody.week_day,
                      staff: staff.id,
                    });
                  });
                  Promise.all(staffListApi).then();
                }
              })
              .catch(() => {});
            // }
          });
        },
      );
    } else {
      handleApi(getStaffList, { limit: 1 }, { limit: 1 }).then(
        ({ count: _staffCount = 0, results: _staffList = [] }) => {
          workingHours.forEach((item) => {
            const itemBody = {
              week_day: item.week_day,
              start_time: item.start_time.split(':').length === 2 ? `${item.start_time}:00` : item.start_time,
              end_time: item.end_time.split(':').length === 2 ? `${item.end_time}:00` : item.end_time,
              is_active: !item.closed,
            };
            handleApiSave(createBusinessWorkingHours, updateBusinessWorkingHours, itemBody, item.id ? item.id : null)
              .then(setSaveWorkingHours)
              .then(() => {
                if (_staffCount === 1) updateOnlyStaffHours(itemBody, _staffList[0]);
              })
              .catch(() => {});
            // }
          });
        },
      );
    }

    const body = { ...data };
    if (bookingMethod) body['booking_mode'] = bookingMethod;
    if (typeof body['avatar'] === 'undefined' || typeof body['avatar'] === 'string') delete body['avatar'];

    handleApiSave(partialUpdateBusiness, null, body, null, { loadingFn: setSaveLoading, setError })
      .then((data) => {
        toaster.notify(() => <div className="alert alert-success mt-3">Business Info Changed Successfully</div>);
        setBusiness(data);
      })
      .catch(() => {});
  };

  const handleChangeHours = (e) => {
    setWorkingHours(e);
    if (!hasChanged) setHasChanged(true);
  };

  return (
    <>
      <Prompt when={hasChanged} message="Please save changes before leave. Are you sure you want to leave?" />
      {_access?.modify && _access?.view && <HeadPage leftElement={headerEl} />}
      {loading ? (
        <PageLoading />
      ) : _access?.modify && _access?.view ? (
        <form onSubmit={handleSubmit(handleSave)} className="row">
          <div className="col-xs-12 col-md-6">
            <div className="c-panel-content">
              <SectionHead title="Business Info" />
              <Controller
                control={control}
                name="avatar"
                // rules={{ required: 'Avatar is required' }}
                defaultValue={data ? data?.avatar : ''}
                render={({ onChange, value }) => (
                  <ImageSelector
                    editRatio
                    withEdit
                    id="image"
                    buttonText="Upload Logo"
                    description="Your logo will be shown in marketplace"
                    className="c-settings__image-uploader"
                    onChange={onChange}
                    onDelete={onChange}
                    value={value}
                  />
                )}
              />
              <ControlInput
                control={control}
                name="title"
                rules={{ required: 'Title is required' }}
                defaultValue={data ? data.title : ''}
                label={'Title'}
                placeholder={'Title'}
                errorMessage={errors?.title?.message}
                onChangeText={(text) => {
                  if (text !== data.title && !hasChanged) setHasChanged(true);
                }}
              />
              <ControlInput
                control={control}
                name="phone_number"
                type="phone"
                // rules={{ required: 'Phone is required' }}
                defaultValue={data.phone_number || ''}
                label={'Phone'}
                placeholder={'Phone'}
                errorMessage={errors?.phone_number?.message}
                onChangeText={(text) => {
                  if (text !== data?.phone_number && !hasChanged) setHasChanged(true);
                }}
              />
              <ControlInput
                control={control}
                name="bio"
                rows={5}
                // rules={{ required: 'Bio is required' }}
                defaultValue={data.bio || ''}
                type="textarea"
                label={'Bio'}
                placeholder={'Bio'}
                errorMessage={errors?.bio?.message}
              />
              <ControlInput
                control={control}
                name="tax_rate"
                // rules={{ required: 'Bio is required' }}
                rules={{
                  min: {
                    value: 0.0000001,
                    message: 'Tax should be more than zero',
                  },
                  required: 'Tax is required',
                }}
                defaultValue={data?.tax_rate}
                label="Tax Rate"
                placeholder="eg. 5%"
                unit="%"
                errorMessage={errors?.tax_rate?.message}
              />

              <hr />

              {isMarketplace && (
                <>
                  <SectionHead subTitle title="Payment Options" />

                  <ControlCheckbox
                    control={control}
                    name="has_pay_at_salon"
                    defaultValue={data ? data.has_pay_at_salon : true}
                    label="Pay at salon"
                    errorMessage={errors?.has_pay_at_salon?.message}
                  />

                  <ControlCheckbox
                    control={control}
                    name="has_online_pay"
                    defaultValue={data ? data.has_online_pay : true}
                    label="Online Pay"
                    errorMessage={errors?.has_online_pay?.message}
                  />

                  <hr />
                </>
              )}

              <SectionHead subTitle title="Social pages(Optional)" />

              <ControlInput
                control={control}
                name="website_address"
                defaultValue={data ? data.website_address : ''}
                type="url"
                unit={<i className="fas fa-link" />}
                placeholder="Website (https://www.example.com)"
              />

              <ControlInput
                control={control}
                name="instagram_account"
                defaultValue={data ? data.instagram_account : ''}
                type="text"
                unit={<i className="fab fa-instagram" />}
                placeholder="Instagram"
              />
              <ControlInput
                control={control}
                name="facebook_account"
                defaultValue={data ? data.facebook_account : ''}
                type="text"
                unit={<i className="fab fa-facebook-f" />}
                placeholder="Facebook"
              />
              <ControlInput
                control={control}
                name="twitter_account"
                defaultValue={data ? data.twitter_account : ''}
                type="text"
                unit={<i className="fab fa-twitter" />}
                placeholder="Twitter"
              />
              <ControlInput
                control={control}
                name="yelp_account"
                defaultValue={data ? data.yelp_account : ''}
                type="text"
                unit={<i className="fab fa-yelp" />}
                placeholder="Yelp"
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <BusinessWorkingHours
              saved={saveWorkingHours}
              onChange={handleChangeHours}
              onData={setCurrentWorkingHours}
            />
            {isMarketplace ? (
              <>
                <BusinessBookingMethod data={data} onChange={setBookingMethod} />
                <BusinessShortenLink data={data} />
              </>
            ) : (
              <BusinessShortenLink data={data} />
            )}
          </div>
          <div className="col-xs-12 col-md-12 mt-3">
            <Button loading={saveLoading}>Save Changes</Button>
          </div>
        </form>
      ) : _access?.view ? (
        <BusinessProfileStatic data={data} />
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default BusinessProfile;
