import React from 'react';
import PropTypes from 'prop-types';
import { TouchApp, Drafts, LocalShipping, EmojiObjects } from '@material-ui/icons';
import Modal from '../UIKit/Modal';
import useApi from '../../../utilities/useApi';
import { getReportCampaign } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';
import { useParams } from 'react-router-dom';

// const dataRes = {
//   bounce_drops: 0,
//   bounces: 0,
//   clicks: 0, // 1
//   unique_clicks: 0, // 2
//   delivered: 0, // 3
//   invalid_emails: 0,
//   opens: 0, // 4
//   unique_opens: 0, // 5
//   requests: 0, // 6
//   spam_report_drops: 0,
//   spam_reports: 0,
//   unsubscribes: 0,
// };

const CampaignModal = ({ type = 'deals', onClose }) => {
  const { id } = useParams();
  const [data, loading] = useApi(getReportCampaign, { id, type });
  return (
    <Modal show bodyClass="p-0" saveBtn={false} title="Campaign Report" onClose={onClose}>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="c-campaign">
          <div className="c-campaign__item">
            <TouchApp />
            <span className="c-campaign__item-label">Clicks</span>
            <span className="c-campaign__item-count">{data.clicks}</span>
          </div>
          <div className="c-campaign__item">
            <TouchApp />
            <span className="c-campaign__item-label">Unique Clicks</span>
            <span className="c-campaign__item-count">{data.unique_clicks}</span>
          </div>
          <div className="c-campaign__item">
            <LocalShipping />
            <span className="c-campaign__item-label">Delivered</span>
            <span className="c-campaign__item-count">{data.delivered}</span>
          </div>

          <div className="c-campaign__item">
            <Drafts />
            <span className="c-campaign__item-label">Opens</span>
            <span className="c-campaign__item-count">{data.opens}</span>
          </div>
          <div className="c-campaign__item">
            <Drafts />
            <span className="c-campaign__item-label">Unique Opens</span>
            <span className="c-campaign__item-count">{data.unique_opens}</span>
          </div>
          <div className="c-campaign__item">
            <EmojiObjects />
            <span className="c-campaign__item-label">Requests</span>
            <span className="c-campaign__item-count">{data.requests}</span>
          </div>
        </div>
      )}
    </Modal>
  );
};

CampaignModal.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
};

export default CampaignModal;
