import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import { MARKETPLACE_URL } from '../../../../config/appConfig';

const PreviewSetupBoth = ({ onStarted }) => (
  <div className="c-setup-system__preview">
    <h1>Meet your powerful cloud-based assistant, working 24/7</h1>
    <div>
      <p className="mb-0">
        Beautster Custom Software built based on almost 1100 interviews with salon and spa owners all over Canada. We
        automate scheduling, client and staff management, payment processing and marketing all-in-one software
        solutions. It is hosted conveniently on the cloud so your info is backed up wherever you log in. It&apos;s the
        best value for the lowest price in Canada, and we invite you to give it a try, for free.
      </p>
      <p>
        Beautster uses Stripe for all payment processing. Updating your billing information lets Beautster handle all
        your online bookings for a low 3% fee per transaction. No hidden fees.
      </p>
      <p>
        Beautster offer you two options to present your schedule and availability. You can configure it differently
        based on your needs.
      </p>
      <p className="mb-0">
        1. Instant booking: With this option, Beautster allows your customers to directly select and book the time slot
        that suits them best.
      </p>
      <p>
        2. 3-time slots request: We ask your customers to provide 3 time slots availability. Afterwards, you&apos;ll
        have to assign this appointment request to the professional you want, according to the requested time slots.
      </p>
      <p>
        <i>
          Note: A salon or spa’s online bookings calendar must be active and synced before customers can successfully
          book online.
        </i>
      </p>
    </div>

    <div className="d-flex align-center">
      <Button onClick={onStarted}>Setup System</Button>
      <Button link={`${MARKETPLACE_URL}/landing-software`} target="_blank" color="light" className="ml-3">
        Learn More
      </Button>
    </div>
  </div>
);

PreviewSetupBoth.propTypes = {
  onStarted: PropTypes.func,
};

export default PreviewSetupBoth;
