import React, { useContext, useEffect } from 'react';
import { IMAGE_REFRESH_CONNECT_STRIPE } from '../../../assets/Images';
import { Done } from '@material-ui/icons';
import Button from '../../Common/UIKit/Form/Button';
import { AppContext } from '../../../context/providers';
import { Types } from '../../../config/actionTypes';
import { handleApi } from '../../../utilities/useApi';
import { getBusiness } from '../../../api';

const ReturnAccountStripePage = () => {
  const { setBusiness, setSystem } = useContext(AppContext);
  useEffect(() => {
    const token = localStorage.getItem(Types.USER_TOKEN);
    if (token) {
      handleApi(getBusiness).then((business) => {
        const systemType = localStorage.getItem(Types.SYSTEM_TYPE);
        setSystem(systemType ? systemType : business?.software_setup ? 'software' : 'marketplace');
        setBusiness(business);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="c-payment-stripe c-payment-stripe--success">
      <img className="c-payment-stripe__background" src={IMAGE_REFRESH_CONNECT_STRIPE} alt="Beautster" />

      <div className="c-payment-stripe__box c-payment-stripe__box--success">
        <span className="c-payment-stripe__box-icon">
          <Done />
        </span>

        <h1 className="c-payment-stripe__box-title">Your account has been connected successfully</h1>

        <Button link="/">CLOSE</Button>
      </div>
    </div>
  );
};

export default ReturnAccountStripePage;
