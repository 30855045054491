import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Input from '../Form/Input';
import FormGroup from '../Form/FormGroup';
import { getQueryUri, setQueryUri } from '../../../../utilities/URI';

const SearchBar = ({ history, placeholder }) => {
  const [searchText, setSearchText] = useState(getQueryUri('q') || '');
  const handleOnSearch = (text, e) => {
    if (e) e.preventDefault();
    setSearchText(text);
    history.push(
      setQueryUri({
        ...getQueryUri(),
        page: undefined,
        q: text,
      }),
    );
  };
  return (
    <form className="c-form__search-bar" onSubmit={(e) => handleOnSearch(searchText, e)}>
      <FormGroup id="pure-searchBar" label={<i className="fas fa-search" />}>
        <Input
          id="pure-searchBar"
          type="search"
          formGroup={false}
          onChangeText={handleOnSearch}
          value={searchText}
          placeholder={placeholder}
        />
      </FormGroup>
    </form>
  );
};

SearchBar.propTypes = {
  history: PropTypes.any,
  placeholder: PropTypes.string,
};

export default withRouter(SearchBar);
