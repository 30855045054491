import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text, Document, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { getPriceFormat } from '../../../../utilities/Price';
import { InvoicePaymentTypeChoice, InvoiceStatusChoice } from '../../../../utilities/constants/choices';
import styles from './InvoiceDownloadStyles';

// Create Document Component
const InvoicePdfDoc = ({ data }) => {
  const payments = data?.transactions ? data.transactions.filter((i) => i.type === 'payment') : [];
  const refunds = data?.transactions ? data.transactions.filter((i) => i.type === 'refund') : [];
  const services = data.lines.filter((i) => i.item_type === 'service');
  const giftCards = data.lines.filter((i) => i.item_type === 'gift_card');
  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.invoice}>
          <View style={styles.client}>
            <Text style={styles.clientName}>{data.client_name}</Text>
            <Text style={styles.clientMeta}>Client {!data.client && ' Walk-In'}</Text>
          </View>
          {services.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Services</Text>
              <View style={styles.list}>
                {services.map((l, ind) => (
                  <View
                    style={{ ...styles.item, borderBottomWidth: services.length - 1 === ind ? 0 : undefined }}
                    key={l.id}
                  >
                    <View style={styles.itemInfo}>
                      <Text style={styles.itemLabel}>{l.item_name}</Text>
                      <Text style={styles.itemMeta}>with {l.staff_name}</Text>
                    </View>
                    <Text style={styles.itemValue}>{getPriceFormat(+l.price)}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}
          {giftCards.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Gift Cards</Text>
              <View style={styles.list}>
                {giftCards.map((l, ind) => (
                  <View
                    style={{ ...styles.item, borderBottomWidth: giftCards.length - 1 === ind ? 0 : undefined }}
                    key={l.id}
                  >
                    <View style={styles.itemInfo}>
                      <Text style={styles.itemLabel}>{l.item_name}</Text>
                      <Text style={styles.itemMeta}>with {l.staff_name}</Text>
                    </View>
                    <Text style={styles.itemValue}>{getPriceFormat(+l.price)}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}
          {data.tips.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Tips</Text>
              <View style={styles.list}>
                {data.tips.map((tip, ind) => (
                  <View
                    style={{ ...styles.item, borderBottomWidth: data.tips.length - 1 === ind ? 0 : undefined }}
                    key={tip.id}
                  >
                    <View style={styles.itemInfo}>
                      <Text style={styles.itemLabel}>{tip.staff_name}</Text>
                    </View>
                    <Text style={styles.itemValue}>
                      {tip.amount_type === 'percent'
                        ? `${getPriceFormat((+data.subtotal * +tip.amount) / 100, '$', '')} (%${+tip.amount})`
                        : `${getPriceFormat(+tip.amount, '$', '')} (%${(+data.subtotal * +tip.amount) / 100})`}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          )}

          {payments.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Payments</Text>
              <View style={styles.list}>
                {payments.map((payment, ind) => (
                  <View
                    style={{ ...styles.item, borderBottomWidth: payments.length - 1 === ind ? 0 : undefined }}
                    key={payment.id}
                  >
                    <View style={styles.itemInfo}>
                      <Text style={styles.itemLabel}>{InvoicePaymentTypeChoice[payment.method]}</Text>
                    </View>
                    <Text style={styles.itemValue}>{getPriceFormat(+payment.amount)}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}

          {refunds.length > 0 && (
            <View style={styles.section}>
              <Text style={{ ...styles.sectionTitle, color: 'red' }}>Refunds</Text>
              <View style={styles.list}>
                {refunds.map((payment, ind) => (
                  <View
                    style={{ ...styles.item, borderBottomWidth: refunds.length - 1 === ind ? 0 : undefined }}
                    key={payment.id}
                  >
                    <View style={styles.itemInfo}>
                      <Text style={{ ...styles.itemLabel, color: 'red' }}>
                        {InvoicePaymentTypeChoice[payment.method]}
                      </Text>
                    </View>
                    <Text style={{ ...styles.itemValue, color: 'red' }}>{getPriceFormat(+payment.amount)}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}

          <View style={styles.section}>
            <View style={styles.sectionBody}>
              <Text style={styles.sectionTitle}>SubTotal</Text>
              <Text style={styles.sectionValue}>{getPriceFormat(+data.subtotal)}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionBody}>
              <Text style={styles.sectionTitle}>Total</Text>
              <Text style={styles.sectionValue}>{getPriceFormat(+data.total)}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionBody}>
              <Text style={styles.sectionTitle}>Balance</Text>
              <Text style={styles.sectionValue}>{getPriceFormat(+data.balance)}</Text>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionBody}>
              <Text style={styles.sectionTitle}>Tax</Text>
              <Text style={styles.sectionValue}>
                {getPriceFormat(+data.tax_amount)} ({getPriceFormat(+data.tax_rate, '%')})
              </Text>
            </View>
          </View>
          {/*<View style={styles.section}>*/}
          {/*    <View style={styles.sectionBody}>*/}
          {/*        <Text style={styles.sectionTitle}>Tax Rate</Text>*/}
          {/*        <Text style={styles.sectionValue}>{getPriceFormat(+data.tax_rate, '%')}</Text>*/}
          {/*    </View>*/}
          {/*</View>*/}
          <View style={{ ...styles.section, borderBottomWidth: 0 }}>
            <View style={styles.sectionBody}>
              <Text style={styles.sectionTitle}>Status</Text>
              <Text style={styles.sectionValue}>{InvoiceStatusChoice[data.status]}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

InvoicePdfDoc.propTypes = {
  data: PropTypes.any,
};

const InvoiceDownload = ({ data }) => {
  const now = new Date();
  const getMonth = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
  const getDate = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const getHours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const getMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const getSeconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();

  const currentDate = `${now.getFullYear()}-${getMonth}-${getDate}`;
  const currentTime = `${getHours}-${getMinutes}-${getSeconds}`;
  const fileName = `invoice_${data.id}_${currentDate}_${currentTime}.pdf`;

  return (
    <PDFDownloadLink document={<InvoicePdfDoc data={data} />} className="dropdown-link" fileName={fileName}>
      {({ loading }) => (loading ? 'Loading document...' : 'Download')}
    </PDFDownloadLink>
  );
};

InvoiceDownload.propTypes = {
  data: PropTypes.any,
};

export const InvoiceFile = ({ data, onFileUrl }) => {
  const now = new Date();
  const getMonth = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
  const getDate = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const getHours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const getMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const getSeconds = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();

  const currentDate = `${now.getFullYear()}-${getMonth}-${getDate}`;
  const currentTime = `${getHours}-${getMinutes}-${getSeconds}`;
  const fileName = `invoice_${data.id}_${currentDate}_${currentTime}.pdf`;
  return (
    <BlobProvider document={<InvoicePdfDoc data={data} />} fileName={fileName}>
      {({ url }) => {
        onFileUrl({ url, fileName });
        // Do whatever you need with blob here
        return <div>There is something going on on the fly</div>;
      }}
    </BlobProvider>
  );
};

InvoiceFile.propTypes = {
  data: PropTypes.any,
  onFileUrl: PropTypes.func,
};

export default InvoiceDownload;
