import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import ServiceCommissionObject, { CommissionObject } from '../objects/ServiceCommissionObject';
// import {getQueryUri} from "../utilities/URI";
import { getStringFromDate } from '../utilities/DateHelpers';

const { GET, POST, PATCH } = ApiMethods;

/**
 * @param {{
 *    page: Number,
 *    limit: Number,
 *    url: String,
 *    search: String,
 *    month: String,
 * }}
 */
export const getCommissionList = async ({ page, limit, month, ...otherParams } = {}) => {
  limit = limit || 10;
  page = page || 1;
  if (!month) {
    month = new Date().getMonth() + 1;
  }
  const fromDate = new Date();
  const toDate = new Date();
  fromDate.setMonth(month - 1);
  fromDate.setDate(1);
  toDate.setMonth(month);
  toDate.setDate(1);
  const from_date = getStringFromDate(fromDate);
  const to_date = getStringFromDate(toDate);

  const response = await callApi({
    method: GET,
    params: {
      limit,
      month,
      offset: (page - 1) * limit,
      to_date,
      from_date,
      ...otherParams,
    },
    url: ApiRoutes.serviceCommission.commissions,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data?.results || []).map((i) => new CommissionObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *    staff: Number,
 *    tiers: [{
 *      service_from: Number,
 *      service_to: Number,
 *      amount: Number,
 *      amount_type: String,
 *    }],
 *    is_active: Boolean,
 * }}
 */
export const createServiceCommission = async (params) => {
  const response = await callApi({
    method: POST,
    params,
    url: ApiRoutes.serviceCommission.create,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data?.results || []).map((i) => new ServiceCommissionObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id: Number,
 * @param params {{
 *    staff: Number,
 *    tiers: [{
 *      service_from: Number,
 *      service_to: Number,
 *      amount: Number,
 *      amount_type: String,
 *    }],
 *    is_active: Boolean,
 * }}
 */
export const updateServiceCommission = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    params,
    url: createURL(ApiRoutes.serviceCommission.update, { id }),
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data?.results || []).map((i) => new ServiceCommissionObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *    staff: Number,
 *    limit: Number,
 *    page: Number,
 * }}
 */
export const getServiceCommission = async ({ limit, page, staff, ...params }) => {
  limit = limit || 10;
  page = page || 1;

  if (staff) {
    const response = await callApi({
      method: GET,
      params: {
        limit,
        offset: (page - 1) * limit,
        ...params,
      },
      url: ApiRoutes.serviceCommission.list,
    });
    if (response.data) {
      return {
        ...response.data,
        results: (response.data?.results || []).map((i) => new ServiceCommissionObject(i)),
      };
    } else {
      return Promise.reject(response);
    }
  } else {
    return Promise.resolve({
      count: 0,
      next: null,
      previous: null,
      results: [],
    });
  }
};

/**
 * @param params {{
 *    staff: Number,
 *    from_date: String,
 *    to_date: String,
 * }}
 */
export const getTotalServiceCommission = async ({ staff, from_date, to_date }) => {
  const response = await callApi({
    method: POST,
    params: {
      from_date,
      to_date,
    },
    url: createURL(ApiRoutes.serviceCommission.totalCommission, { id: staff }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
