import ExperienceObject from './ExperienceObject';
import EducationObject from './EducationObject';
import CertificateObject from './CertificateObject';

/**
 *
 * @param stylist {{
 *     id: String,
 *     first_name: String,
 *     last_name: String,
 *     avatar: String,
 *     experiences: ExperienceObject[],
 *     educations: EducationObject[],
 *     certificates: CertificateObject[],
 *     city: String,
 *     job_title: String,
 *     headline_business: String,
 *     headline_title: String,
 *     professional_summary: String,
 *     province: String,
 * }}
 * @constructor
 */

function StylistProfileObject(stylist) {
  this.id = stylist.id;
  this.avatar = stylist.avatar;
  this.first_name = stylist.first_name;
  this.last_name = stylist.last_name;
  this.experiences = (stylist.experiences || []).map((i) => new ExperienceObject(i));
  this.educations = (stylist.educations || []).map((i) => new EducationObject(i));
  this.certificates = (stylist.certificates || []).map((i) => new CertificateObject(i));
  this.city = stylist.city;
  this.job_title = stylist.job_title;
  this.headline_business = stylist.headline_business;
  this.headline_title = stylist.headline_title;
  this.professional_summary = stylist.professional_summary;
  this.province = stylist.province;
}

export default StylistProfileObject;
