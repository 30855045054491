import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import CampaignDetailText from '../CampaignDetailText';
import ReleasePromotionButton from '../ReleasePromotionButton';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const PromotionDealItem = ({ page_url, item, access, onChangePublish }) => {
  const { isMarketplace } = useContext(AppContext);
  const { id, subject, services, discount, expire_date, booked_count, usage_limit, is_published, campaign } = item;

  return (
    <tr key={id}>
      <td>{subject}</td>
      <td className="text-center">{services?.length}</td>
      <td className="text-center">{usage_limit}</td>
      <td className="text-center">{booked_count}</td>
      <td className="text-center">{discount}%</td>
      <td>{expire_date ? expire_date : <small>This deal does not have expire date</small>}</td>
      {isMarketplace && (
        <td className="text-center">
          <small
            className={`font-weight-bold ${
              usage_limit === booked_count || !is_published ? 'text-danger' : 'text-success'
            }`}
          >
            {usage_limit === booked_count ? 'Reached Maximum Usage' : is_published ? 'Published' : 'Draft'}
          </small>
        </td>
      )}

      <td className="text-center">
        <ReleasePromotionButton type="deal" item={item} access={access} onSubmit={onChangePublish} />
      </td>

      <CampaignDetailText page_url={page_url} campaign={campaign} access={access?.campaign_report} id={id} />

      <td>
        <NavLink id={`link-${id}`} to={`${page_url}${id}`}>
          Manage
        </NavLink>
      </td>
    </tr>
  );
};

PromotionDealItem.propTypes = {
  page_url: PropTypes.string,
  item: PropTypes.any,
  onChangePublish: PropTypes.func,
  access: PropTypes.shape({
    publish: PropTypes.bool,
    unpublish: PropTypes.bool,
    send_campaign: PropTypes.bool,
    campaign_report: PropTypes.bool,
  }),
};

export default PromotionDealItem;
