import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import DDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import FormGroup from './FormGroup';

export const ControlTimePicker = ({ control, name, rules, defaultValue, id, onChangeValue, ...dateProps }) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    render={({ onChange, onBlur, value }, { invalid }) => (
      <TimePicker
        id={id ? id : name}
        onChange={(val) => {
          onChange(val);
          if (onChangeValue) onChangeValue(val);
        }}
        onBlur={onBlur}
        error={!!invalid}
        selected={value}
        {...dateProps}
      />
    )}
  />
);

ControlTimePicker.propTypes = {
  id: PropTypes.string,
  control: PropTypes.any.isRequired,
  name: PropTypes.any,
  rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultValue: PropTypes.any,
  formGroup: PropTypes.bool,
  description: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorMessage: PropTypes.string,
  onChangeValue: PropTypes.func,
};

const TimePicker = ({
  id,
  label,
  iconLabel,
  arrowLabel,
  formGroup,
  selected,
  placeholder,
  onChange,
  error,
  errorMessage,
  description,
  className,
  ...otherProps
}) => {
  const renderTimePicker = (
    <>
      <div className={classnames('l-date-picker-container', className)}>
        {iconLabel && (
          <label htmlFor={id} className={'l-date-picker__label'}>
            <i className="far fa-clock" />
          </label>
        )}
        <DDatePicker
          id={id}
          placeholderText={placeholder}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          selected={selected}
          onChange={onChange}
          {...otherProps}
        />
        {arrowLabel && (
          <label htmlFor={id} className="l-date-picker__label">
            <i className="fas fa-chevron-down" />
          </label>
        )}
      </div>
    </>
  );
  return formGroup ? (
    <FormGroup id={id} label={label} error={error} errorMessage={errorMessage} description={description}>
      {renderTimePicker}
    </FormGroup>
  ) : (
    renderTimePicker
  );
};

TimePicker.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  formGroup: PropTypes.bool,
  iconLabel: PropTypes.bool,
  arrowLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.any,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

TimePicker.defaultProps = {
  id: null,
  formGroup: true,
  isModal: false,
  iconLabel: true,
  arrowLabel: true,
  label: '',
  selected: null,
  error: false,
  placeholder: 'Select Time',
};

export default TimePicker;
