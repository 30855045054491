import React, { useState } from 'react';
import { DeleteRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Modal from './Modal';
import CropImage from './CropImage';
import UploadFileInput from './UploadFileBox';
import Button from './Form/Button';

const ImageCropModal = ({ originImage, editRatio, setOriginImage, onClose, onChange }) => {
  const [croppedImage, setCroppedImage] = useState(null);
  const handleSubmit = () => {
    onChange(croppedImage);
  };
  const handleRemovePhoto = () => {
    setCroppedImage(null);
    setOriginImage(null);
  };
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setOriginImage({ src: reader.result, file });
      };
    }
  };
  return (
    <Modal
      lg
      show
      onClose={onClose}
      saveBtnComponent={
        <Button type="button" id="modalSaveBtn" onClick={handleSubmit}>
          Save
        </Button>
      }
    >
      {originImage ? (
        <div className="c-upload-file-preview">
          <CropImage
            config={{
              aspect: editRatio ? 16 / 9 : undefined,
              width: 500,
              height: 500 * (9 / 16),
              unit: 'px',
            }}
            src={originImage}
            onChange={setCroppedImage}
          />
          <span className="c-upload-file-preview__actions">
            <span className="c-upload-file-preview__actions-item" onClick={handleRemovePhoto}>
              <DeleteRounded />
            </span>
          </span>
        </div>
      ) : (
        <UploadFileInput onChange={handleChangeFile} accept="image/x-png,image/jpg,image/jpeg" />
      )}
    </Modal>
  );
};

ImageCropModal.propTypes = {
  originImage: PropTypes.any,
  editRatio: PropTypes.bool,
  setOriginImage: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default ImageCropModal;
