import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { getAptStatusName } from '../../Common/UIKit/Status';
import Filters from '../../Common/UIKit/HeadOptions/Filters';
import { getAppointmentList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import AppointmentFormModal from '../../Common/Appointment/AppointmentFormModal';
import Pagination from '../../Common/UIKit/Pagination';
import useApi from '../../../utilities/useApi';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import { AppContext } from '../../../context/providers';
import { AppointmentSaleChannelChoice } from '../../../utilities/constants/choices';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/sales/appointments';

// eslint-disable-next-line react/prop-types
const SalesAppointments = ({ headerEl }) => {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { setHeader, isMarketplace } = useContext(AppContext);
  const history = useHistory();
  const match = useRouteMatch();
  const apiParams = {
    // limit: 5,
    key: 'appointment',
    to_date: true,
    from_date: true,
    staff: true,
    status: true,
    service: true,
    search: true,
    page: true,
    // sale_channel: isMarketplace ? 'marketplace' : 'software',
    sale_channel__in: isMarketplace
      ? ['marketplace', 'site', 'email_reminder', 'google_reserve']
      : ['walk_in', 'software'],
  };
  const { access: _client } = usePermission('client', 'crud', 'view');
  const { access: _access } = usePermission('appointment', 'crud');
  const [, loading] = useApi(getAppointmentList, apiParams, setData);

  useEffect(() => {
    setHeader('Sales / Appointments');
  }, [setHeader]);

  useEffect(() => {
    const id = match.params.id;
    if (id) handleOpenModal(+id);
  }, [match]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (opened = true) => {
    if (opened) history.replace(URL_PAGE);
    setShowModal(false);
  };

  const handleSave = (item) => {
    let updateData = [...data.results];
    if (typeof item === 'number' || typeof item === 'string') {
      updateData = updateData.filter((i) => String(i.id) !== String(item));
    } else {
      const id = item?.id ? item?.id : match.params.id;

      const itemIndex = updateData.findIndex((i) => +i.id === +id);

      const updateItem = Object.keys(item).reduce((acc, key) => {
        const value = item[key];
        if (key === 'lines' && value.length === 0) return acc;
        return value ? { ...acc, [key]: value } : acc;
      }, {});

      updateData[itemIndex] = { ...updateData[itemIndex], ...updateItem };
    }

    setData({
      ...data,
      results: updateData,
    });

    handleCloseModal();
  };

  const linkText = useMemo(() => (_access?.modify ? 'Manage' : 'View'), [_access]);
  return (
    <>
      <HeadPage rightElement={_access?.view ? <Filters status date /> : null} leftElement={headerEl} />
      {showModal && <AppointmentFormModal onClose={handleCloseModal} onSave={handleSave} />}
      {_access?.view ? (
        <>
          {loading && !data ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && <PageLoading over />}
                <div className="c-table c-table--sm">
                  <table>
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th>Services</th>
                        <th>Date</th>
                        <th>Staff</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Sales Channel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((appointment, index) => (
                        <tr key={appointment.id}>
                          <td>
                            {!!appointment.client ? (
                              _client ? (
                                <NavLink to={`/clients/${appointment.client}`}>{appointment.client_name}</NavLink>
                              ) : (
                                appointment.client_name
                              )
                            ) : (
                              appointment.client_name
                            )}
                          </td>
                          <td>
                            {appointment.lines.map((line) => (
                              <span className="badge badge-dark mr-2" key={line.id}>
                                {line?.service_name}
                              </span>
                            ))}
                          </td>
                          <td>{appointment?.reserved_date}</td>
                          <td>
                            {appointment.lines.map((line) => (
                              <span className="badge badge-dark mr-2" key={line.id}>
                                {line?.staff_name}
                              </span>
                            ))}
                          </td>
                          <td className="text-center">{getAptStatusName(appointment?.status)}</td>
                          <td className="text-center">{AppointmentSaleChannelChoice[appointment?.sale_channel]}</td>
                          <td>
                            <NavLink id={`linkAppt-${index}`} to={`${URL_PAGE}/${appointment.id}`}>
                              {linkText}
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination count={data.count} />}
            </>
          ) : (
            <NotFoundData icon="fas fa-calendar-check" title="No Appointment Exists" />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default SalesAppointments;
