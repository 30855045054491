import React from 'react';
import { Close } from '@material-ui/icons';

// eslint-disable-next-line react/prop-types
const SetupCloseBtn = ({ onClick }) => (
  <div className="c-setup-system-modal__close" onClick={onClick}>
    <Close />
  </div>
);

export default SetupCloseBtn;
