import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ControlSelect } from '../UIKit/Form/Select';
import { ControlInput } from '../UIKit/Form/Input';
import timezoneJson from '../../../utilities/timezones.json';
import { getValueLabel } from '../../../utilities/constants/choices';
import LocationCitySelector from '../Location/LocationCitySelector';

// eslint-disable-next-line no-unused-vars
const BusinessLocationForm = ({ data, errors, control, zipCode = true, onChangeProvince }) => {
  const timezoneOptions = useMemo(() => getValueLabel(timezoneJson), []);
  return (
    <>
      <LocationCitySelector control={control} data={data} errors={errors} onChangeProvince={onChangeProvince} />
      <ControlSelect
        isSearchable
        isDisabled={timezoneOptions.length === 0}
        maxMenuHeight={200}
        control={control}
        rules={{ required: 'Timezone is required' }}
        name="timezone"
        label="Timezone"
        options={timezoneOptions}
        defaultValue={data?.timezone || ''}
        placeholder="Timezone"
        errorMessage={errors?.timezone?.message}
      />
      <ControlInput
        control={control}
        name="address"
        defaultValue={data?.address || ''}
        type="textarea"
        label="Address"
        placeholder="Address"
        errorMessage={errors?.address?.message}
      />
      {zipCode && (
        <ControlInput
          control={control}
          name="zip_code"
          defaultValue={data?.zip_code || ''}
          label="Postal Code"
          placeholder="Postal Code"
          errorMessage={errors?.zip_code?.message}
        />
      )}
    </>
  );
  // return (
  //   <>
  //     <ControlInput
  //       control={control}
  //       name="country"
  //       defaultValue={data?.profile ? data.country : ''}
  //       label="Country"
  //       placeholder="Country"
  //       errorMessage={errors?.country?.message}
  //     />
  //     {provinceOptions.length > 0 ? (
  //       <ControlSelect
  //         isSearchable
  //         maxMenuHeight={200}
  //         control={control}
  //         name="province"
  //         defaultValue={provinceDefaultSelected || null}
  //         label="Province"
  //         options={provinceOptions}
  //         onChange={onChangeProvince ? (e) => onChangeProvince(e.label, e.value) : undefined}
  //         placeholder="Province"
  //         errorMessage={errors?.province?.message}
  //       />
  //     ) : (
  //       <ControlInput
  //         control={control}
  //         onChangeText={onChangeProvince ? (e) => onChangeProvince(e) : undefined}
  //         name="province"
  //         defaultValue={data?.profile ? data.province : ''}
  //         label="Province"
  //         placeholder="Province"
  //         errorMessage={errors?.province?.message}
  //       />
  //     )}
  //     <ControlInput
  //       control={control}
  //       name="city"
  //       defaultValue={data?.profile ? data.city : ''}
  //       label="City"
  //       placeholder="City"
  //       errorMessage={errors?.city?.message}
  //     />
  //     <ControlInput
  //       control={control}
  //       name="address"
  //       defaultValue={data?.profile ? data.address : ''}
  //       type="textarea"
  //       label="Address"
  //       placeholder="Address"
  //       errorMessage={errors?.address?.message}
  //     />
  //     {zipCode && (
  //       <ControlInput
  //         control={control}
  //         name="zip_code"
  //         defaultValue={data?.profile ? data.zip_code : ''}
  //         label="Postal Code"
  //         placeholder="Postal Code"
  //         errorMessage={errors?.zip_code?.message}
  //       />
  //     )}
  //   </>
  // );
};

BusinessLocationForm.propTypes = {
  data: PropTypes.any,
  errors: PropTypes.any,
  control: PropTypes.any,
  watch: PropTypes.any,
  zipCode: PropTypes.bool,
  onChangeProvince: PropTypes.func,
};

export default BusinessLocationForm;
