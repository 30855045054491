import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../UIKit/Modal';
import RefundLines from './RefundLines';
import ModalForm, { ModalFormContent } from '../ModalForm/ModalForm';
import RefundSidebar from './RefundSidebar';
import { PageLoading } from '../UIKit/Spinner';
import { handleApi, handleApiSave } from '../../../utilities/useApi';
import { getInvoice, partialUpdateInvoice } from '../../../api';
import RefundSummary from './RefundSummary';

// onClose, onSave
const RefundModal = ({ type = 'service', id, data: _data, onClose, onSave }) => {
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);
  const [client, setClient] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [pays, setPays] = useState([]);

  useEffect(() => {
    if (id && !_data) {
      handleApi(getInvoice, id, { list: false, loadingFn: setInvoiceLoading }).then((res) => {
        const { client, client_name } = res;
        setClient(
          client
            ? {
                id: client,
                name: client_name,
                // email: client_email,
              }
            : client_name,
        );
        setInvoiceData(res);
      });
    }
  }, [id]);
  useEffect(() => {
    if (_data) {
      const { client, client_name } = _data;
      setClient(
        client
          ? {
              id: client,
              name: client_name,
              // email: client_email,
            }
          : client_name,
      );
      setInvoiceData(_data);
      setInvoiceLoading(false);
    }
  }, [_data]);

  const handleSave = () => {
    const body = {
      // client: typeof client === 'string' ? undefined : client.id,
      // client_name: typeof client === 'string' ? client : client.id,
      transactions: pays.reduce((acc, p) => {
        if (p.paid) return acc;

        const updateAcc = [...acc];
        const index = updateAcc.findIndex((i) => i.method === p.key);
        if (index > -1) {
          updateAcc[index].amount = updateAcc[index].amount + +p.price;
        } else {
          updateAcc.push({
            amount: +p.price,
            method: p.key,
            type: 'refund',
            voucher_code: p.key === 'gift_card' && p.code ? p.code : undefined,
          });
        }
        return updateAcc;
      }, []),
    };

    handleApiSave(null, partialUpdateInvoice, body, invoiceData.id, { loadingFn: setSaveLoading })
      .then((data) => {
        onSave(data);
      })
      .catch(() => {});
  };

  const handlePay = (key, price, paid) => {
    const totalPay = pays.reduce((acc, p) => +acc + +p.price, 0);
    const totalRefund = invoiceData?.transactions.reduce((acc, i) => (i.type === 'refund' ? +i.amount + acc : acc), 0);
    if (Math.ceil((+invoiceData.total - totalRefund - totalPay).toFixed(2) >= +price && +price > 0) || paid) {
      setPays([...pays, { key, price: +price, paid }]);
    }
  };

  /**
   * @param key => type 'pay' => 'pay[key]' | type 'tip' => 'tip'
   **/
  const handleRemovePay = (key) => {
    const updatedPays = pays.filter((i) => i.key !== key);
    setPays(updatedPays);
  };

  return (
    <Modal show auto disableFooter title="Refund" bodyClass="p-0" onClose={onClose}>
      {invoiceLoading || !invoiceData ? (
        <PageLoading />
      ) : (
        <>
          {saveLoading && <PageLoading over />}
          <ModalForm>
            <ModalFormContent>
              <RefundLines lines={invoiceData.lines} />
              <div className="c-checkout-footer">
                <RefundSummary data={invoiceData} pays={pays} onRemove={handleRemovePay} />
              </div>
            </ModalFormContent>
            <RefundSidebar
              client={client}
              type={type}
              pays={pays}
              invoiceData={invoiceData}
              setClient={setClient}
              onSave={handleSave}
              onPay={handlePay}
            />
          </ModalForm>
        </>
      )}
    </Modal>
  );
};

RefundModal.propTypes = {
  type: PropTypes.string,
  id: PropTypes.any,
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default RefundModal;
