import { ApiRoutes, StatusCodes } from '../config/apiConfig';
import API from '../services/APIService';
import { Types } from '../config/actionTypes';
import asyncLocalStorage from './AsyncLocalStorage';
import { handleError500 } from './HandleErrors';

export const callApi = ({
  method,
  url,
  params = null,
  headers = null,
  absolute = false,
  authorization = true,
  locationBase = true,
}) => {
  const boot = async () => {
    let token = await asyncLocalStorage.getItem(Types.USER_TOKEN);
    let Authorization = null;
    if (!!token) Authorization = authorization ? `Bearer ${token}` : null;

    let response = await handler(Authorization);
    if (
      (response.status === StatusCodes.UnAuthorized || response?.errors?.code === 'token_not_valid') &&
      authorization
    ) {
      try {
        const refreshToken = await asyncLocalStorage.getItem(Types.USER_REFRESH);
        if (!!refreshToken) {
          const refreshData = await API['post'](
            ApiRoutes.auth.refresh,
            { refresh: refreshToken },
            { 'Content-Type': 'application/json' },
            false,
          );
          if (refreshData.status === StatusCodes.Success && refreshData.data) {
            await asyncLocalStorage.setItem(Types.USER_TOKEN, refreshData.data.access);
            Authorization = `Bearer ${refreshData.data.access}`;
            response = await handler(Authorization);
          } else {
            throw new Error('Access Denied');
          }
        } else {
          throw new Error('Access Denied');
        }
      } catch (e) {
        localStorage.removeItem(Types.USER_TOKEN);
        localStorage.removeItem(Types.USER_REFRESH);
        localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
        localStorage.removeItem(Types.STRIPE_PAYMENT);
        localStorage.removeItem(Types.SYSTEM_TYPE);
        localStorage.removeItem(Types.CITY);
        localStorage.removeItem(Types.BUSINESS_LOCATION);
        localStorage.removeItem(Types.LATEST_APPOINTMENT);
        localStorage.removeItem(Types.COUNTRIES);
        window.location.reload();
      }
    }

    return new Promise((resolve) => {
      handleError500(response);
      resolve(response);
    });
  };

  const handler = async (Authorization = null) => {
    try {
      const apiHeaders = { 'Content-Type': 'application/json', ...headers };
      if (!!Authorization) apiHeaders.Authorization = Authorization;
      const s = await API[method](url, params, apiHeaders, absolute, locationBase);

      const { data, status } = s;
      if (status !== StatusCodes.Success && status !== StatusCodes.Created && status !== StatusCodes.Deleted) {
        return { errors: data, status };
      } else {
        return s;
      }
    } catch (error) {
      return {
        data: {},
        status: StatusCodes.Error,
      };
    }
  };

  return boot();
  // return handler();
};
