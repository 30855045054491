/**
 *
 * @param experience {{
 *     id: String,
 *     start_date: String,
 *     end_date: String,
 *     job_title: String,
 *     salon: String,
 *     city: String,
 *     description: String,
 * }}
 * @constructor
 */

function ExperienceObject(experience) {
  this.id = experience.id;
  this.job_title = experience.job_title;
  this.start_date = experience.start_date ? new Date(experience.start_date) : null;
  this.end_date = experience.end_date ? new Date(experience.end_date) : null;
  this.salon = experience.salon;
  this.city = experience.city;
  this.description = experience.description;
}

export default ExperienceObject;
