import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import { deleteLmo, getLmo, scheduleCampaign } from '../../../../api';
import Modal from '../../UIKit/Modal';
import Button from '../../UIKit/Form/Button';
import { PageLoading } from '../../UIKit/Spinner';
import PromotionCard from '../PromotionCard';
import { AppContext } from '../../../../context/providers';
import toaster from 'toasted-notes';

// eslint-disable-next-line react/prop-types
const PromotionLmoDetailModal = ({ onClose, onSave }) => {
  const [delLoading, setDelLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const { isMarketplace } = useContext(AppContext);
  const { id } = useParams();

  const [data, loading] = useApiById(getLmo, id);

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete this deal',
      message: 'Are you sure to delete this deal.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteLmo, id, options).then((isSuccess) => {
      if (isSuccess) onSave(id);
    });
  };

  const handleSendCampaign = () => {
    handleApiSave(scheduleCampaign, null, data.id, null, { loadingFn: setCampaignLoading })
      .then(() => {
        onSave();
        toaster.notify(() => <div className="alert alert-success">Campaign has been sent successfully</div>);
      })
      .catch(() => {});
  };

  return (
    <Modal
      show
      auto
      onClose={onClose}
      saveBtn={false}
      bodyClass={classnames('p-0', { 'py-4 bg-secondary': isMarketplace })}
      buttonComponent={
        !!id && (
          <>
            {data?.campaign && data?.campaign?.status !== 'sent' && (
              <Button color="success" loading={campaignLoading} onClick={handleSendCampaign}>
                Send Campaign
              </Button>
            )}
            <Button color="danger" loading={delLoading} onClick={handleDelete}>
              Delete
            </Button>
          </>
        )
      }
      title="Lmo"
    >
      {loading && id ? (
        <PageLoading />
      ) : (
        <PromotionCard
          imageUrl={data?.image}
          subject={data?.subject}
          description={data?.description}
          discount={data?.discount}
          campaignAudience={data?.campaign?.audience}
          emailSubject={data?.campaign?.email_subject}
          emailDescription={data?.campaign?.email_description}
        />
      )}
    </Modal>
  );
};

export default PromotionLmoDetailModal;
