import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory, NavLink } from 'react-router-dom';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import Button from '../../Common/UIKit/Form/Button';
import BusinessClosedDateModal from '../../Common/Staff/BusinessClosedDateModal';
import useApi from '../../../utilities/useApi';
import { getClosedDateList } from '../../../api';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { getFormat24H, getFormatDate } from '../../../utilities/DateHelpers';
import Pagination from '../../Common/UIKit/Pagination';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';

const Page_URL = '/settings/closed-dates/';

// eslint-disable-next-line react/prop-types
const BusinessClosedDates = ({ headerEl = null }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  let [, loading] = useApi(getClosedDateList, { page: true, key: 'businesscloseddate' }, setData);
  const { setHeader } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { access: _access } = usePermission('businesscloseddate', 'crud');

  useEffect(() => {
    setHeader('Business / Dates Closed');
  }, [setHeader]);

  useEffect(() => {
    if (_access?.modify) {
      if ((id && _access?.view) || location.pathname === `${Page_URL}add`) {
        handleOpenModal();
      }
    }
  }, [location, id, _access]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    history.replace(`${Page_URL}`);
    setShowModal(false);
  };

  const handleSaveItem = (item) => {
    if (typeof item === 'number') {
      const updateData = [...data.results];
      const ind = data.results.findIndex((i) => i.id === item);
      updateData.splice(ind, 1);
      setData({ ...data, results: updateData });
    }
    getClosedDateList().then((data) => setData(data));
    handleCloseModal();
  };

  const getFormat = (val) => {
    const date = getFormatDate(new Date(val));
    const t = val.split(' ');
    const time = `${getFormat24H(`${t[t.length - 2]} ${t[t.length - 1]}`)}:00`;
    return `${date} ${time}`;
  };

  return (
    <>
      {showModal && _access?.modify && <BusinessClosedDateModal onSave={handleSaveItem} onClose={handleCloseModal} />}
      <HeadOptions
        leftElement={headerEl}
        rightElement={
          _access?.modify && (
            <Button id="addHeaderBtn" link={`${Page_URL}add`}>
              New Dates Closed
            </Button>
          )
        }
      />
      {_access?.view ? (
        <>
          {loading ? (
            <PageLoading />
          ) : data?.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                <div className="c-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item, key) => (
                        <tr key={key}>
                          <td>{item.title}</td>
                          <td>{getFormat(item.start_time)}</td>
                          <td>{getFormat(item.end_time)}</td>
                          <td>
                            <NavLink id={`link-${item.id}`} to={`${Page_URL}${item.id}/edit`}>
                              Manage
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination count={data.count} />
            </>
          ) : (
            <NotFoundData title="No Dates Closed Exists" />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};
export default BusinessClosedDates;
