import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import SectionHead from '../../../Common/UIKit/SectionTitle';
import useApi from '../../../../utilities/useApi';
import { getAppointmentList } from '../../../../api';
import NotFoundData from '../../../Common/UIKit/NotFoundData';
import { PageLoading } from '../../../Common/UIKit/Spinner';
import Pagination from '../../../Common/UIKit/Pagination';
import { getAptStatusName } from '../../../Common/UIKit/Status';
import { getStringFromDate } from '../../../../utilities/DateHelpers';

const ClientAppointmentItem = ({ item }) => {
  const staffs = useMemo(
    () =>
      item.lines.reduce((acc, p) => {
        const item = acc.find((i) => i.staff === p.staff && p.staff_name);
        if (item) return acc;
        return [...acc, p];
      }, []),
    [item],
  );
  const services = useMemo(
    () =>
      item.lines.reduce((acc, p) => {
        const item = acc.find((i) => i.service === p.service && p.service_name);
        if (item) return acc;
        return [...acc, p];
      }, []),
    [item],
  );
  return (
    <tr>
      <td>
        <NavLink
          id={`apptLink-${item.id}`}
          to={
            item?.reserved_date
              ? `/calendar?date=${getStringFromDate(item?.reserved_date)}&view=day`
              : `/three-time-requests/${item.id}`
          }
        >
          {item?.reserved_date ? (
            item?.reserved_date
          ) : (
            <small className="text-danger font-weight-bold">Should confirm a selected time</small>
          )}
        </NavLink>
      </td>
      <td>
        {services.map((line) => (
          <span className="badge badge-dark mr-2" key={line.id}>
            {line?.service_name}
          </span>
        ))}
      </td>
      <td>
        {staffs.map((line) => (
          <span className="badge badge-dark mr-2" key={line.id}>
            {line?.staff_name}
          </span>
        ))}
      </td>
      <td>{getAptStatusName(item?.status)}</td>
    </tr>
  );
};

ClientAppointmentItem.propTypes = {
  item: PropTypes.any,
};

const ClientAppointments = ({ data: client }) => {
  const [data, loading] = useApi(getAppointmentList, { client: client.id, page: true });
  return (
    <div className="c-panel-content mb-5">
      {data?.note && <p className="description">{client.note}</p>}
      <SectionHead title="Appointments" />
      {loading ? (
        <PageLoading />
      ) : !!data?.results && data?.results.length > 0 ? (
        <>
          <div className="c-table c-table--sm">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Services</th>
                  <th>Staff</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((appointment) => (
                  <ClientAppointmentItem key={appointment.id} item={appointment} />
                ))}
              </tbody>
            </table>
          </div>
          <Pagination count={data.count} />
        </>
      ) : (
        <NotFoundData
          title="No Appointment History"
          description="Use the new appointment button to create a first appointment for this client"
          icon="fas fa-calendar"
        />
      )}
    </div>
  );
};

ClientAppointments.propTypes = {
  data: PropTypes.any,
};

export default ClientAppointments;
