import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../UIKit/Form/Button';
import ModalFormClientItem from '../ModalForm/ModalFormClient/ModalFormClientItem';
import ModalFormClients from '../ModalForm/ModalFormClient/ModalFormClients';
import ModalFormSidebarFooter from '../ModalForm/ModalFormSidebarFooter';
import ModalFormSidebar, { ModalFormSidebarContent } from '../ModalForm/ModalFormSidebar';
import ModalFormTotalPrice from '../ModalForm/ModalFormTotalPrice';
import { InvoicePaymentTypeChoice } from '../../../utilities/constants/choices';
import toaster from 'toasted-notes';
import { handleApiSave } from '../../../utilities/useApi';
import { getStripeConnectAccountLink } from '../../../api';
import { AppContext } from '../../../context/providers';

const RefundSidebar = ({ invoiceData, pays, type = null, client, onSave, onPay, setClient }) => {
  const [connectLoading, setConnectLoading] = useState(false);
  const [totalPay, setTotalPay] = useState(0);
  const { business } = useContext(AppContext);

  const initialTotal = useMemo(() => {
    if (invoiceData) {
      const totalRefund = invoiceData?.transactions.reduce(
        (acc, i) => (i.type === 'refund' ? +i.amount + acc : acc),
        0,
      );
      return +invoiceData.total - totalRefund;
    }
    return 0;
  }, [invoiceData]);

  useEffect(() => {
    setTotalPay(+(initialTotal - +willPay).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pays, invoiceData]);

  const handlePay = (key) => {
    if (+totalPay === 0) return;
    onPay(key, +totalPay);
  };

  const handleConnectError = () => {
    toaster.notify(() => (
      <div className="alert alert-danger">For using stripe, you should connect to your stripe account.</div>
    ));
  };

  const handleConnectAccount = () => {
    handleApiSave(getStripeConnectAccountLink, undefined, {}, undefined, {
      loadingFn: setConnectLoading,
    })
      .then(({ connect_url }) => {
        window.location.href = connect_url;
      })
      .catch(() => {});
  };

  const handleChangeTotalPay = (value) => {
    if (+invoiceData.total - +willPay > +value) {
      setTotalPay(+(+value).toFixed(2));
    } else {
      setTotalPay(+(+initialTotal - +willPay).toFixed(2));
    }
  };

  const approaches = useMemo(
    () =>
      Object.keys(InvoicePaymentTypeChoice)
        // if type === 'gift_card', don't include approach with 'gift_card' key
        .filter((i) => i !== 'gift_card')
        // .filter(i => (i === 'stripe' && invoiceData?.is_stripe_refund_available) || i !== 'stripe')
        .filter((i) => !(i === 'stripe' && !invoiceData?.is_stripe_refund_available))
        .map((key) => ({
          key,
          title: InvoicePaymentTypeChoice[key],
        })),
    [type],
  );

  const willPay = useMemo(() => pays.reduce((acc, p) => acc + +p.price, 0), [pays]);

  return (
    <ModalFormSidebar>
      {!client ? (
        <ModalFormClients onSelect={setClient ? setClient : undefined} />
      ) : (
        <ModalFormSidebarContent>
          {business && !business?.connected_to_stripe && (
            <div className="p-3">
              <Button block color="light" loading={connectLoading} onClick={handleConnectAccount}>
                Connect Stripe Now
              </Button>
            </div>
          )}
          <ModalFormClientItem data={client} />
          <ModalFormTotalPrice onChange={handleChangeTotalPay} label="Pay" price={totalPay} />
          <div className="c-checkout-approaches">
            {approaches.map((a) => (
              <div
                key={a.key}
                className={classnames('c-checkout-approaches__item', {
                  'c-checkout-approaches__item--disabled':
                    +totalPay === 0 || (business && !business?.connected_to_stripe && a.key === 'stripe'),
                  'c-checkout-approaches__item--odd': approaches.length > 2 && approaches.length % 2,
                })}
                onClick={
                  business && !business?.connected_to_stripe && a.key === 'stripe'
                    ? handleConnectError
                    : () => handlePay(a.key)
                }
              >
                {a.title}
              </div>
            ))}
          </div>
          <ModalFormSidebarFooter>
            <Button lg block disabled={+willPay === 0} onClick={onSave}>
              Refund
            </Button>
          </ModalFormSidebarFooter>
        </ModalFormSidebarContent>
      )}
    </ModalFormSidebar>
  );
};

RefundSidebar.propTypes = {
  invoiceData: PropTypes.any,
  pays: PropTypes.array,
  type: PropTypes.any,
  client: PropTypes.any,
  onSave: PropTypes.func,
  onPay: PropTypes.func,
  setClient: PropTypes.func,
};

export default RefundSidebar;
