import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ControlInput } from '../../UIKit/Form/Input';
import { getPriceFormat } from '../../../../utilities/Price';
import Button from '../../UIKit/Form/Button';
// import {ControlSelect} from "../../UIKit/Form/Select";

const CheckoutLineService = (props) => {
  const {
    pays,
    errors,
    control,
    line,
    index,
    // services,
    invoiceId,
    onChangePrice,
    // onChangeDiscount,
    // onChangeDiscountAmount
  } = props;
  // const discountAmountOptions = useMemo(() => {
  //     if(line?.discount && services) {
  //         const service = services.find(s => +s.id === +line.service);
  //         const results = [{ value: 'null', label: 'Select Discount' }];
  //         if(line?.discount === 'lmo') {
  //             results.push({ value: String(service.lmo), label: String(service.lmo) });
  //         } else {
  //             results.push({ value: String(service.deal), label: String(service.deal) });
  //         }
  //         return results;
  //         // return (line?.discount === 'lmo') ?
  //         //     services.map(s => ({ value: s.lmo, label:s.lmo }))
  //         //     : services.map(s => ({ value: s.deal, label:s.deal }))
  //     }
  //     return [];
  // }, [services, line?.discount]);
  // const discountType = useMemo(() => {
  //     if(line?.service_discount_type && services) {
  //         const service = services.find(s => +s.id === +line.service);
  //         if (service) {
  //             // const price = +line?.line_price - (+line?.line_price * 0.1).toFixed(2);
  //             // onChangePrice(line, price, true);
  //             return service[line?.service_discount_type];
  //         }
  //     }
  //     return null;
  // }, [services, line?.service_discount_type]);

  const giftCardSupport = useMemo(() => {
    const pay = pays.find((p) => p.key === 'gift_card');

    if (pay) {
      if (pay.services.indexOf(+line.service) > -1) return true;
    }
    return false;
  }, [pays]);

  return (
    <div className="c-checkout-lines__item c-list__item">
      {/*<div className="c-list__item">*/}
      <div className="c-list__info px-0">
        <h3 className="c-list__title">
          {line?.service_name}
          {giftCardSupport && (
            <>
              &nbsp;- <small className="c-list__meta text-success font-weight-bold">Gift Card Support</small>
            </>
          )}
        </h3>
        <p className="c-list__description">with {line?.staff_name}</p>

        <div className="c-list__metas">
          <span className="c-list__meta">Time: {line?.reserved_time}</span>
          <span className="c-list__meta">, Duration: {line?.duration}</span>
        </div>
        {line?.deal ? (
          <div className="c-list__metas mt-0 pt-0 text-success">
            <strong className="c-list__meta">
              Deal: {line.deal?.subject} , %{line.deal?.discount}
            </strong>
          </div>
        ) : (
          line?.lmo && (
            <div className="c-list__metas mt-0 pt-0 text-success">
              <strong className="c-list__meta">
                Lmo: {line.lmo?.subject} , %{line.lmo?.discount}
              </strong>
            </div>
          )
        )}
        {/*{discountType && (*/}
        {/*    <div className="c-list__metas mt-0 pt-0 text-success">*/}
        {/*        {line?.service_discount_type === 'deal' ? (*/}
        {/*            <>*/}
        {/*                <strong className="c-list__meta">*/}
        {/*                    Deal: {discountType?.subject} , %{discountType?.discount}*/}
        {/*                </strong>*/}
        {/*            </>*/}
        {/*        ) : line?.service_discount_type === 'lmo' ? (*/}
        {/*            <>*/}
        {/*                <strong className="c-list__meta">*/}
        {/*                    Lmo: {discountType?.subject} , %{discountType?.discount}*/}
        {/*                </strong>*/}
        {/*            </>*/}
        {/*        ) : null}*/}
        {/*    </div>*/}
        {/*)}*/}
      </div>
      <div className={classnames('c-list__el-right', 'c-list__el-right--auto')}>
        {invoiceId ? (
          // <span className="c-list__price">{getPriceFormat(+line?.line_price)}</span>
          <span className="c-list__price">{getPriceFormat(+line?.price)}</span>
        ) : (
          <div>
            <div className="d-flex align-items-center">
              {+line?.discount_amount > 0 ? (
                <span className="c-list__preview-price">
                  <span className="c-list__origin-price">{getPriceFormat(+line?.original_price)}</span>
                  {getPriceFormat(+line?.line_price)}
                </span>
              ) : (
                <span className="c-list__preview-price">{getPriceFormat(+line?.original_price)}</span>
              )}

              <ControlInput
                control={control}
                rules={{ required: 'Price is required' }}
                name={`price[${index}]`}
                // defaultValue={line?.price_overwrite ? line?.price_overwrite : line?.price}
                type="tel"
                unit="$"
                disabled={!!invoiceId}
                min={0}
                defaultValue={line?.price}
                formGroup={false}
                placeholder={'e.g. 30 ($30)'}
                errorMessage={errors?.price && errors?.price[index]?.message}
                onChangeText={(val) => onChangePrice(line, val)}
              />
            </div>
            {+line?.line_price !== +line?.price && line?.service_discount_type && (
              <div className="d-flex align-items-center mt-2">
                You are using manual price
                {line?.service_discount_type === 'deal' ? (
                  <Button sm onClick={() => onChangePrice(line, +line?.line_price, true)}>
                    Use Deal
                  </Button>
                ) : (
                  <Button sm onClick={() => onChangePrice(line, +line?.line_price, true)}>
                    Use Lmo
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
        {/*<span className="c-list__price">*/}
        {/*    {getPriceFormat(line?.line_price ? line.line_price : line.price)}*/}
        {/*</span>*/}
      </div>
      {/*</div>*/}
      {/*<div className="c-list__item">*/}
      {/*    <div className="px-2 w-25">*/}
      {/*        <ControlInput*/}
      {/*            control={control}*/}
      {/*            name={`price[${index}]`}*/}
      {/*            type="number"*/}
      {/*            unit="$"*/}
      {/*            disabled={!!invoiceId}*/}
      {/*            min={0}*/}
      {/*            defaultValue={line?.price}*/}
      {/*            label="Price"*/}
      {/*            placeholder={'e.g. 30 ($30)'}*/}
      {/*            errorMessage={errors?.price && errors?.price[index]?.message}*/}
      {/*            onChangeText={val => onChangePrice(line, val)}*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*    <div className="px-2 w-25">*/}
      {/*        <ControlSelect*/}
      {/*            control={control}*/}
      {/*            name={`discount[${index}]`}*/}
      {/*            disabled={!!invoiceId}*/}
      {/*            defaultValue={line?.discount ? line.discount : 'manual'}*/}
      {/*            label="Discount Type"*/}
      {/*            options={discountOptions}*/}
      {/*            placeholder={'Select Discount Type'}*/}
      {/*            errorMessage={errors?.discount && errors?.discount[index]?.message}*/}
      {/*            onChangeValue={val => onChangeDiscount(line, val)}*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*    {((line?.discount && line?.discount === "manual") || !line?.discount) && (*/}
      {/*        <ControlInput*/}
      {/*            control={control}*/}
      {/*            name={`discount_amount[${index}]`}*/}
      {/*            type="number"*/}
      {/*            unit="$"*/}
      {/*            disabled={!!invoiceId}*/}
      {/*            min={0}*/}
      {/*            defaultValue={(invoiceId && line?.discount_amount) ? line?.discount_amount : 0}*/}
      {/*            label="Discount Amount"*/}
      {/*            placeholder={'e.g. 30 ($30)'}*/}
      {/*            errorMessage={errors?.discount_amount && errors?.discount_amount[index]?.message}*/}
      {/*            onChangeText={val => {*/}
      {/*                onChangeDiscountAmount(line, val);*/}
      {/*                if(!line?.discount) onChangeDiscount(line, 'manual');*/}
      {/*            }}*/}
      {/*        />*/}
      {/*    )}*/}
      {/*    {(line?.discount && line?.discount !== "manual") && (*/}
      {/*        <div className="px-2 w-25">*/}
      {/*            <ControlSelect*/}
      {/*                control={control}*/}
      {/*                name={`discount_amount[${index}]`}*/}
      {/*                disabled={!!invoiceId}*/}
      {/*                defaultValue={(invoiceId && line?.discount_amount) ? line.discount_amount : 'manual'}*/}
      {/*                label="Discount Amount"*/}
      {/*                options={discountAmountOptions}*/}
      {/*                placeholder={'Select Discount'}*/}
      {/*                errorMessage={errors?.discount_amount && errors?.discount_amount[index]?.message}*/}
      {/*                onChangeValue={val => onChangeDiscountAmount(line, val)}*/}
      {/*            />*/}
      {/*        </div>*/}
      {/*    )}*/}
      {/*</div>*/}
    </div>
  );
};

// const discountOptions = [
//     { label: 'Manual', value: 'manual' },
//     { label: 'LMO', value: 'lmo' },
//     { label: 'Deal', value: 'deal' },
// ];

CheckoutLineService.propTypes = {
  pays: PropTypes.array,
  errors: PropTypes.any,
  control: PropTypes.any,
  line: PropTypes.any,
  index: PropTypes.any,
  invoiceId: PropTypes.any,
  onChangePrice: PropTypes.func,
};

export default CheckoutLineService;
