import React from 'react';
import PropTypes from 'prop-types';

const BurgerMenu = ({ onToggle }) => (
  <div className="c-panel__burger-menu" onClick={onToggle}>
    <i className="fa fa-bars" aria-hidden="true"></i>
    {/*<div className="c-panel__bars">*/}
    {/*<span className="c-panel__bar" />*/}
    {/*<span className="c-panel__bar" />*/}
    {/*<span className="c-panel__bar" />*/}
    {/*<span className="c-panel__bar" />*/}
    {/*<span className="c-panel__bar" />*/}
    {/*</div>*/}
  </div>
);

BurgerMenu.propTypes = {
  onToggle: PropTypes.func,
};

export default BurgerMenu;
