import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';

const { GET, POST } = ApiMethods;

/**
 * @param params {{
 *     code,
 * }}
 */
export const getInstagramAccessToken = async ({ code }) => {
  const response = await callApi({
    method: POST,
    // absolute: true,
    // authorization: false,
    url: ApiRoutes.instagramAPI.accessToken,
    params: {
      // client_id: process.env.REACT_APP_INSTAGRAM_ID,
      // client_secret: process.env.REACT_APP_INSTAGRAM_SECRET,
      // grant_type: 'authorization_code',
      // redirect_uri: process.env.REACT_APP_INSTAGRAM_REDIRECT,
      code,
    },
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
/**
 * @param {{
 *    userId: String,
 *    accessToken: String,
 * }}
 */
export const getInstagramMediaList = async ({ userId, accessToken, next }) => {
  const response = await callApi({
    method: GET,
    absolute: true,
    authorization: false,
    url: next || createURL(ApiRoutes.instagramAPI.mediaList, { userId, accessToken }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
