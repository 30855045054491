import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import CampaignObject from '../objects/CampaignObject';

const { GET, POST } = ApiMethods;

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getCampaignList = async ({ search, page, limit }) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.client.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new CampaignObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getCampaign = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.campaigns.get, { id }),
  });
  if (response.data) {
    return new CampaignObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param type
 * @param params
 */
export const getReportCampaign = async ({ id, type = 'deals' }, params = {}) => {
  const response = await callApi({
    method: GET,
    params,
    url: createURL(ApiRoutes.campaigns.report, { id, type }),
  });
  if (response.data) {
    return new CampaignObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param body {{
 *  id: {Number},
 *  type: {String}
 * }}
 */
export const scheduleCampaign = async (body) => {
  const response = await callApi({
    method: POST,
    params: {},
    url: createURL(ApiRoutes.campaigns.schedule, { id: body.id, type: body.type }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
