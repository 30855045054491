import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Modal, { ModalDeniedOver } from '../../UIKit/Modal';
import { useForm } from 'react-hook-form';
import { ControlDatePicker } from '../../UIKit/Form/DatePicker';
import useApi, { handleApi, handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import {
  checkAvailabilityAppointmentLine,
  createAppointment,
  deleteAppointment,
  deleteAppointmentLine,
  getAppointment,
  getServiceList,
  getStaffList,
  updateAppointment,
} from '../../../../api';
import {
  getFormatBriefDate,
  getDateFromString,
  getStringFromTime,
  getStringFromDate,
} from '../../../../utilities/DateHelpers';
import AppointmentModalSidebar from './AppointmentModalSidebar';
import AppointmentFormLine from './AppointmentFormLine';
import { PageLoading } from '../../UIKit/Spinner';
import { handleErrorUnAvailableAppointment } from '../../../../utilities/HandleErrors';
import { getQueryUri } from '../../../../utilities/URI';
import { range } from '../../../../utilities/number';
import ModalForm, { ModalFormContent } from '../../ModalForm/ModalForm';
import { ControlInput } from '../../UIKit/Form/Input';
import AppointmentLine, { AppointmentResultLines } from './AppointmentLine';
import { appointmentFormSteps } from '../../../../config/tourSteps';
// import { TourGuide } from '../../UIKit/TourContent';
import usePermission from '../../../../utilities/usePermission';

// eslint-disable-next-line react/display-name
const DateInputLabel = forwardRef(({ value, onClick }, ref) => (
  <span className="c-appointment-modal__date-input" onClick={onClick} ref={ref}>
    {getFormatBriefDate(getDateFromString(value))}
  </span>
));

DateInputLabel.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
};

const AppointmentFormModal = ({ onClose, onSave, onCheckout, onRefund, extraData = {} }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [disabledCheckAvailability, setDisabledCheckAvailability] = useState([]);
  const [lines, setLines] = useState([{ reserved_time: extraData?.start || new Date() }]);
  const [deleteLines, setDeleteLines] = useState([]);
  const [client, setClient] = useState(null);
  const { control, errors, handleSubmit, setValue, watch, setError } = useForm();
  // const [isTourOpen, setIsTourOpen] = useState(true);
  // const [tourStep, setTourStep] = useState(0);

  const [services] = useApi(getServiceList, { limit: 0, key: 'service' });

  const { access: _access } = usePermission('appointment', 'crud', 'modify');
  const { access: _service } = usePermission('service', 'crud', 'view');
  const { access: _staff } = usePermission('staff', 'crud', 'view');

  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      if (extraData?.type === 'client') {
        setClient(extraData);
      }
      if (extraData?.note) {
        setValue('note', extraData.note);
      }
      if (extraData?.start) {
        setValue('lines[0].reserved_time', extraData.start);
        setLines([{ reserved_time: extraData.start }]);
      }
    }, 300);
  }, [extraData]);

  useEffect(() => {
    if (!id) {
      const query = getQueryUri();
      const reserved_date = extraData?.start
        ? extraData.start
        : query.date
        ? getDateFromString(query.date)
        : new Date();
      const staff = query.staff ? query.staff : null;
      const reserved_time = extraData?.start
        ? extraData.start
        : query.time
        ? getDateFromString(query.date, query.time)
        : new Date();
      let extraDuration = null;
      if (extraData?.start && extraData?.end) {
        const diffTime = Math.abs(extraData.end - extraData.start);
        const diffMin = Math.floor(diffTime / (1000 * 60));
        const m = diffMin % 60;
        const h = Math.floor(diffMin / 60);
        extraDuration = `${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}:00`;
      }
      const duration = extraDuration ? extraDuration : query.duration ? query.duration : null;
      setLines([{ reserved_time, staff, duration }]);
      setValue(`lines[0].reserved_time`, reserved_time);
      setValue(`lines[0].duration`, duration);
      handleApi(getStaffList, {}, { infinitePage: true }).then((res) => {
        const full_staff = res.results.find((s) => String(s.id) === String(staff));
        if (full_staff) {
          setValue(`lines[0].staff`, staff);
          setLines([
            {
              ...lines[0],
              staff,
              full_staff: {
                ...full_staff,
                value: full_staff.id,
                label: full_staff.name,
              },
            },
          ]);
        }
      });

      setValue(`reserved_date`, reserved_date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [data, loading] = useApiById(getAppointment, id, (res) => {
    if (res) {
      setDisabledCheckAvailability(range(0, res?.lines.length - 1));
      const lines = res.lines.map((l, ind) => {
        const reserved_time = getDateFromString(res.reserved_date, l.reserved_time);
        setClient(
          !!res.client
            ? {
                id: res.client,
                name: res.client_name,
                email: res.client_email,
              }
            : res.client_name,
        );
        setValue(`lines[${ind}].reserved_time`, reserved_time);
        setValue(`lines[${ind}].duration`, l.duration);
        setValue(`lines[${ind}].service`, l.service);
        setValue(`lines[${ind}].staff`, l.staff);

        return {
          id: l.id,
          reserved_time,
          duration: l.duration,
          service: l.service,
          full_service: {
            id: l.service,
            name: l.service_name,
            price: l.price,
          },
          deal: l.deal,
          lmo: l.lmo,
          service_discount_type: l.service_discount_type,
          staff: l.staff,
          full_staff: {
            id: l.staff,
            name: l.staff_name,
          },
          price: l.price,
        };
      });

      setLines(lines);
    }
  });

  const handleSave = async ({ reserved_date, note }, { client, isCheckout }) => {
    if (deleteLines.length > 0) {
      if (data && data?.lines.length === deleteLines.length) {
        const deleteOptions = {
          title: 'Confirm to delete this appointment',
          message:
            "You don't have any services. saving without adding any service means to delete current appointment. Are you sure to delete this item.",
          loadingFn: setSaveLoading,
        };
        handleApiDeleteById(deleteAppointment, data.id, deleteOptions).then(() => onSave(+data.id));
      } else {
        for (const ind in deleteLines) {
          await deleteAppointmentLine(deleteLines[ind]);
        }
      }
    }
    const bodyLines = lines
      .map((l) => ({
        ...l,
        reserved_time: getStringFromTime(l.reserved_time),
        service_discount_type: l?.service_discount_type?.value
          ? l.service_discount_type.value.split('-')[0]
          : undefined,
        full_staff: undefined,
        full_service: undefined,
      }))
      .filter((l) => !!l.service);
    const body = {
      lines: bodyLines,
      client: typeof client === 'string' ? undefined : client?.id,
      client_name: typeof client === 'string' ? client : client.name,
      client_email: typeof client === 'string' ? client.email : '',
      note,
      google_calendar_event: extraData?.eventId || undefined,
      sale_channel: typeof client === 'string' ? 'walk_in' : 'software',
      reserved_date: getStringFromDate(reserved_date),
    };
    handleApiSave(createAppointment, updateAppointment, body, id, {
      loadingFn: setSaveLoading,
      setError,
    })
      .then((data) => onSave({ ...data, isEdit: !!id }, isCheckout))
      .catch((e) => handleErrorUnAvailableAppointment(e));
  };

  const handleAddLine = (body, index) => {
    const { reserved_time, duration } = body;
    const updatedLines = [...lines];
    const new_reserved_time = new Date(reserved_time);
    if (duration) {
      const hour = +duration.split(':')[0];
      const min = +duration.split(':')[1];
      new_reserved_time.setHours(new_reserved_time.getHours() + hour);
      new_reserved_time.setMinutes(new_reserved_time.getMinutes() + min);
    }

    if (updatedLines[index + 1] && updatedLines[index + 1].reserved_time < new_reserved_time) {
      updatedLines[index + 1].reserved_time = new_reserved_time;
      setValue(`lines[${index + 1}].reserved_time`, new_reserved_time);
    } else if ((data && !data.invoice && !updatedLines[index + 1]) || (!data && !updatedLines[index + 1])) {
      updatedLines.push({
        reserved_time: new_reserved_time,
      });
    }
    setLines(updatedLines);
  };

  const handleRemoveLine = (index) => {
    const updatedLines = [...lines];
    if (updatedLines[index]?.id) setDeleteLines([...deleteLines, updatedLines[index].id]);
    updatedLines.splice(index, 1);
    updatedLines.forEach((l, ind) => {
      setValue(`lines[${ind}].reserved_time`, l?.reserved_time);
      setValue(`lines[${ind}].service`, l?.service ? l.service : null);
      setValue(`lines[${ind}].duration`, l?.duration ? l.duration : null);
      setValue(`lines[${ind}].staff`, l?.staff ? l.staff : null);
    });
    setLines(updatedLines);

    if (index === updatedLines.length) {
      const newLines = [...updatedLines];
      const { reserved_time, duration } = newLines[index - 1];
      const new_reserved_time = new Date(reserved_time);
      const hour = +duration.split(':')[0];
      const min = +duration.split(':')[1];
      new_reserved_time.setHours(new_reserved_time.getHours() + hour);
      new_reserved_time.setMinutes(new_reserved_time.getMinutes() + min);
      newLines.push({
        reserved_time: new_reserved_time,
      });
      setLines(newLines);
    }
  };

  const handleChangeItem = (key, value, index) => {
    const updatedLines = [...lines];
    updatedLines[index][key] = value;
    if (key.includes('full_') && !!value) {
      const newKey = key.split('_')[1];
      const newValue = value?.value ? value.value : value?.id;
      updatedLines[index][newKey] = newValue;
      setValue(`lines[${index}].${newKey}`, newValue);
    } else if (key === 'service_discount_type') {
      setValue(`lines[${index}].${key}`, value.value);
    } else {
      setValue(`lines[${index}].${key}`, value);
    }
    // if (key === 'service_discount_type') return;

    if (key === 'full_service' && !!value) {
      updatedLines[index].duration = value.duration;
      setValue(`lines[${index}].duration`, value.duration);
    }
    setLines(updatedLines);

    // if(key === 'full_staff' && !!value) {
    if (
      updatedLines[index]?.duration &&
      updatedLines[index]?.full_service &&
      updatedLines[index]?.full_staff &&
      updatedLines[index]?.reserved_time
    ) {
      handleAddLine(updatedLines[index], index);
    }
    if (updatedLines[index]) {
      const { reserved_time, service, duration, staff, full_staff, id } = updatedLines[index];
      if (reserved_time && service && duration && staff) {
        if (!disabledCheckAvailability.includes(index)) {
          handleCheckAvailability({ reserved_time, service, duration, staff, full_staff, id });
        } else {
          const updateDisabledCheckAvailability = disabledCheckAvailability.filter((i) => +i !== +index);
          setDisabledCheckAvailability(updateDisabledCheckAvailability);
        }
      }
    }
  };
  const handleCheckAvailability = (data = null) => {
    if (data) {
      const { reserved_time, service, duration, staff, full_staff, id } = data;
      const body = {
        reserved_time: getStringFromTime(reserved_time),
        service,
        duration,
        staff,
        id: id || undefined,
        reserved_date: getStringFromDate(watch().reserved_date),
      };
      const checkBody = {
        ...body,
        reserve_time: body.reserved_time,
        reserve_date: body.reserved_date,
      };
      handleApi(checkAvailabilityAppointmentLine, checkBody, { list: false })
        .then((e) => {
          handleErrorUnAvailableAppointment(e, {
            ...checkBody,
            staff: full_staff ? full_staff : null,
          });
        })
        .catch((e) => {
          handleErrorUnAvailableAppointment(e, {
            ...checkBody,
            staff: full_staff ? full_staff : null,
          });
        });
    } else {
      lines.forEach((line) => {
        const { reserved_time, service, duration, staff, full_staff, id } = line;
        const body = {
          reserved_time: getStringFromTime(reserved_time),
          service,
          duration,
          staff,
          id: id || undefined,
          reserved_date: getStringFromDate(watch().reserved_date),
        };
        if (reserved_time && service && duration && staff) {
          const checkBody = {
            ...body,
            reserve_time: body.reserved_time,
            reserve_date: body.reserved_date,
          };
          handleApi(checkAvailabilityAppointmentLine, checkBody)
            .then((e) => {
              handleErrorUnAvailableAppointment(e, {
                ...checkBody,
                staff: full_staff ? full_staff : null,
              });
            })
            .catch((e) => {
              handleErrorUnAvailableAppointment(e, {
                ...checkBody,
                staff: full_staff ? full_staff : null,
              });
            });
        }
      });
    }
  };

  const isWeekday = (date) => {
    if (extraData?.workingHours) {
      const day = date.getDay() > 0 ? date.getDay() - 1 : 6;
      const workingDays = extraData.workingHours.filter((i) => i.is_active).map((i) => +i.week_day);
      return workingDays.indexOf(day) > -1;
    }
    return true;
  };

  const disabled = useMemo(
    () =>
      data &&
      (data?.invoice || data.status === 'arrived' || data.status === 'no_show' || data.status.includes('cancel')),
    [data],
  );

  const disabledModal = useMemo(() => services && services?.count === 0, [services]);

  const title = useMemo(
    () => (id ? (data?.sale_channel === 'marketplace' ? 'View appointment' : 'Edit appointment') : 'New appointment'),
    [id, data],
  );
  const handleClose = () => {
    onClose(data?.date ? getStringFromDate(data.date) : null);
  };
  return (
    <>
      <Modal
        show
        auto
        disabled={disabledModal}
        guide={!(loading && id) ? appointmentFormSteps : undefined}
        disableFooter
        title={title}
        bodyClass="p-0"
        onClose={handleClose}
      >
        {services && services?.count === 0 && (
          <ModalDeniedOver
            title="No Service"
            description="No Service Exists, Please add first service from `Services` page"
            onClose={() => onClose()}
            btnText="Add Service"
            btnLink="/services/add"
          />
        )}
        {!_service ? (
          <ModalDeniedOver
            title="Service List Access Denied"
            description="You do not have permission for service list view"
            onClose={() => onClose()}
          />
        ) : !_staff ? (
          <ModalDeniedOver
            title="Staff List Access Denied"
            description="You do not have permission for staff list view"
            onClose={() => onClose()}
          />
        ) : null}
        {saveLoading && <PageLoading over />}
        {loading && id ? (
          <PageLoading />
        ) : (
          <ModalForm client={client}>
            <ModalFormContent>
              <ControlDatePicker
                id="appointmentDatePicker"
                control={control}
                dataTour="date"
                name="reserved_date"
                formGroup={false}
                rules={{ required: 'Date is required' }}
                defaultValue={data?.start ? data.start : data?.date ? data.date : new Date()}
                errorMessage={errors?.reserved_date?.message}
                iconLabel={false}
                // arrowLabel={!(disabled || isMarketplace)}
                // disabled={disabled || isMarketplace}
                arrowLabel={!(disabled || !_access || data?.sale_channel === 'marketplace')}
                disabled={disabled || !_access || data?.sale_channel === 'marketplace'}
                className="c-appointment-modal__date"
                customInput={<DateInputLabel />}
                filterDate={isWeekday}
                onChangeValue={() => handleCheckAvailability()}
              />
              {disabled || !_access || data?.sale_channel === 'marketplace' ? (
                <>
                  <div className="c-appointment-line-container">
                    {lines.map((line, ind) => (
                      <AppointmentLine key={ind} line={line} />
                    ))}
                    <AppointmentResultLines lines={lines} />
                  </div>
                </>
              ) : (
                <>
                  {lines.map((line, ind) => (
                    <AppointmentFormLine
                      key={ind}
                      errors={errors?.lines && errors?.lines[ind]}
                      control={control}
                      line={line}
                      linesLength={lines.length}
                      index={ind}
                      setValue={setValue}
                      onRemoveLine={handleRemoveLine}
                      onChange={(k, v) => handleChangeItem(k, v, ind)}
                    />
                  ))}
                  <div className="c-appointment-modal__input-note">
                    <ControlInput
                      control={control}
                      name="note"
                      type="textarea"
                      label="Appointment notes"
                      defaultValue={data?.note || ''}
                      placeholder="Add an appointment note"
                      errorMessage={errors?.note?.message}
                    />
                  </div>
                </>
              )}
            </ModalFormContent>
            <AppointmentModalSidebar
              loadingFn={setSaveLoading}
              lines={lines}
              data={data}
              disabled={disabled || data?.sale_channel === 'marketplace'}
              setClient={setClient}
              client={client}
              onSave={handleSubmit(handleSave)}
              onCheckout={() => onCheckout(data, true)}
              onUpdate={onSave}
              onClose={onClose}
              onRefund={() => onRefund(data?.invoice)}
            />
          </ModalForm>
        )}
      </Modal>
      {/*{!(loading && id) && (*/}
      {/*  <TourGuide*/}
      {/*    disableInteraction*/}
      {/*    steps={appointmentFormSteps}*/}
      {/*    startAt={tourStep}*/}
      {/*    getCurrentStep={setTourStep}*/}
      {/*    isOpen={isTourOpen}*/}
      {/*    onRequestClose={() => setIsTourOpen(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

AppointmentFormModal.propTypes = {
  extraData: PropTypes.any,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onCheckout: PropTypes.func,
  onRefund: PropTypes.func,
};

export default AppointmentFormModal;
