import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_DEFAULT, IMAGE_DEFAULT_USER } from '../../../assets/Images';
import { DealCampaignAudienceChoice } from '../../../utilities/constants/choices';
import { AppContext } from '../../../context/providers';

const PromotionCard = ({
  imageUrl,
  subject,
  description,
  discount,
  emailSubject,
  emailDescription,
  campaignAudience,
}) => {
  discount = discount ? (+discount <= 100 ? (+discount > 0 ? discount : 0) : 100) : 10;
  // price = price ? price : 100;
  // const totalPrice =
  //     +discount > 0 ?
  //         +discount <= 100 ?
  //             (price - ((price * discount)/100)).toFixed(2)
  //             : 100
  //         : 0;
  const { isMarketplace } = useContext(AppContext);
  const toEmail = DealCampaignAudienceChoice[campaignAudience];

  const cardComponent = (
    <div className="c-promotion-card">
      <img src={imageUrl ? imageUrl : IMAGE_DEFAULT} alt={subject} />
      <div className="c-promotion-card__body">
        {subject && <h3 className="c-promotion-card__title">{subject}</h3>}
        <div className="c-promotion-card__description">
          <p>{description ? description : 'Description Promotion'}</p>
        </div>

        <div className="c-promotion-card__price-wrapper">
          <span className="c-promotion-card__discount">{discount}% OFF</span>
          {/*<span className="c-promotion-card__change-price">*/}
          {/*    <span className="c-promotion-card__old-price">{price}$</span>*/}
          {/*    <span className="c-promotion-card__price">{totalPrice}$</span>*/}
          {/*</span>*/}
        </div>
      </div>
    </div>
  );
  if (isMarketplace) return cardComponent;
  return (
    <div className="c-promotion__email">
      <div className="c-promotion__email-header">
        <h1 className="c-promotion__email-subject">{emailSubject}</h1>
        <div className="c-promotion__email-info">
          <img src={IMAGE_DEFAULT_USER} alt="" style={{ width: '50px' }} />
          <div>
            <span>Your Salon Name</span>
            &#60;<span>beautster@beautster.com</span>&#62;
            <div className="c-promotion__email-to">to {toEmail ? toEmail : 'All clients'}</div>
          </div>
        </div>
      </div>
      <div className="c-promotion__email-body">
        <div className="c-promotion__email-description">
          {emailDescription ? emailDescription : 'Description Promotion'}
        </div>
        {cardComponent}
      </div>
    </div>
  );
};

PromotionCard.propTypes = {
  imageUrl: PropTypes.any,
  subject: PropTypes.string,
  description: PropTypes.string,
  discount: PropTypes.string,
  emailSubject: PropTypes.string,
  emailDescription: PropTypes.string,
  campaignAudience: PropTypes.string,
};

export default PromotionCard;
