import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { InsertDriveFile, InsertDriveFileOutlined, Flare } from '@material-ui/icons';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Calendar as BigCalendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-big-calendar/lib/sass/styles.scss';
import { getDateFromString, getFormat24H, getStringFromDate } from '../../../utilities/DateHelpers';
import { getQueryUri } from '../../../utilities/URI';
import { AppointmentStatusChoice, InvoiceStatusChoice } from '../../../utilities/constants/choices';
import ImageName from '../UIKit/ImageName';

// https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
// US, Canada
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const DragAndDropCalendar = withDragAndDrop(BigCalendar);
const localizer = momentLocalizer(moment);

const eventItem = (item, viewPreset) => {
  const { id, title, client_name, invoice, payment_status, sale_channel, appointmentStatus } = item;
  if (viewPreset instanceof Date) viewPreset = 'day';
  return (
    <span
      id={`eventAppt-${id}`}
      className={classNames(
        'c-calendar__appointment',
        `c-calendar__appointment--${(viewPreset || 'day').toLowerCase()}`,
      )}
    >
      {title && <span className="c-calendar__appointment-service">{title}</span>}
      {invoice && (
        <span
          className={classNames('c-calendar__appointment-invoice-icon', {
            'c-calendar__appointment-invoice-icon--refund':
              payment_status === 'part_refunded' || payment_status === 'refunded',
          })}
        >
          {payment_status === 'paid' || payment_status === 'part_refunded' || payment_status === 'refunded' ? (
            <InsertDriveFile />
          ) : (
            <InsertDriveFileOutlined />
          )}
        </span>
      )}
      {sale_channel === 'marketplace' && (
        <span className="c-calendar__appointment-invoice-icon">
          <Flare />
        </span>
      )}
      {client_name && (
        <>
          <span className="c-calendar__appointment-client">
            <span className="c-calendar__appointment-client-label">Client:</span>
            {client_name}
          </span>
          <span className="c-calendar__appointment-client--float">
            <span className="c-calendar__appointment-client-label">Client:</span>
            {client_name}
          </span>
        </>
      )}
      {(payment_status === 'part_refunded' || payment_status === 'refunded') && (
        <span className="text-danger d-block font-weight-bold py-1">--- {InvoiceStatusChoice[payment_status]} ---</span>
      )}
      {/*{service && <span className="c-calendar__appointment-service">{service.name}</span> }*/}
      {/*{type === 'blocked' && <span className="c-calendar__appointment-service">BLOCKED TIME</span> }*/}
      {viewPreset === 'DAY' && appointmentStatus !== 'confirmed' && (
        <span className="c-calendar__appointment-status">** {AppointmentStatusChoice[appointmentStatus]} **</span>
      )}
    </span>
  );
};

const PureCalendar = ({ url = null, events, resources, workingHours, closedDates, selectable, ...otherProps }) => {
  const [date, setDate] = useState(new Date());
  const [viewPreset, setViewPreset] = useState(Views.DAY);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === url) {
      const view = getQueryUri('view') || 'day';
      const date = getQueryUri('date') || getStringFromDate(new Date());
      setDate(getDateFromString(date));
      setViewPreset(view.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const calcWorkingHours = (nowDate = date) => {
    const workingItem = (workingHours || []).find(
      (i) => +i.week_day === (nowDate.getDay() > 0 ? nowDate.getDay() - 1 : 6),
    );

    let start = new Date(nowDate);
    let end = new Date(nowDate);
    let disabled = false;
    if (workingItem) {
      const { start_time, end_time, is_active } = workingItem;
      const { hours: startHours, min: startMin } = getFormat24H(start_time, 'object');
      const { hours: endHours, min: endMin } = getFormat24H(end_time, 'object');
      start.setHours(startHours, startMin, 0);
      end.setHours(endHours, endMin, 59);
      disabled = !is_active;
    } else {
      start.setHours(0, 0, 0);
      end.setHours(23, 55, 0);
    }
    const startMin = start.getMinutes();
    // const startT = String(start.getHours() - 1) + (startMin > 0 ? `-5` : '');
    // const startT = String((start.getHours() * 2) - 1) + (startMin > 0 ? `-5` : '');
    const startT = startMin > 0 ? String(start.getHours() * 2) : String(start.getHours() * 2 - 1);
    const endMin = end.getMinutes();
    // const endT = String(24 - end.getHours() - 1) + (endMin > 0 ? `-5` : '');
    // const endT = String(((24 - end.getHours()) * 2) - 1) + (endMin > 0 ? `-5` : '');
    const endT = endMin > 0 ? (24 - end.getHours()) * 2 - 2 : (24 - end.getHours()) * 2 - 1;
    return {
      startDate: start,
      start: startT,
      endDate: end,
      end: endT,
      disabled,
    };
  };

  // const calcClosedDates = (nowDate = date) => {
  //   const closedDate = (closedDates || []).find((i) => {
  //     const nowDateItem = nowDate;
  //     const startDate = new Date(i.start_time);
  //     const endDate = new Date(i.end_time);
  //     nowDateItem.setHours(0, 0, 0, 0);
  //
  //     return nowDateItem >= startDate && nowDateItem <= endDate;
  //   });
  //   return !!closedDate;
  // };

  const calcClosedDates = (nowDate = date) => {
    const closedDate = (closedDates || []).find((i) => {
      const nowDateItem = nowDate;
      const startDate = new Date(i.start_time);
      const endDate = new Date(i.end_time);
      // nowDateItem.setHours(0, 0, 0, 0);

      return nowDateItem >= startDate && nowDateItem <= endDate;
    });
    return !!closedDate;
  };

  const nowTime = useMemo(() => {
    const { startDate } = calcWorkingHours();
    if (startDate.getHours() === 0) {
      const tStartDate = new Date(startDate);
      const time = new Date();
      tStartDate.setHours(time.getHours());
      tStartDate.setMinutes(time.getMinutes());
      return tStartDate;
    }
    return startDate;
  }, [workingHours]);

  const handleSlotPropGetter = (props) => {
    if (calcClosedDates(props)) {
      props.className = 'c-calendar-board--disabled-row';
    }
    props.id = `slot-${new Date(props).getTime()}`;
    return props;
  };

  const handleDayPropGetter = (props) => {
    const currentDate = new Date(props);
    const { start, end, disabled } = calcWorkingHours(currentDate);
    // const isClose = calcClosedDates(currentDate);
    if (start || end) {
      props.className = `
        c-calendar-board--start-${start}
        c-calendar-board--end-${end}
      `;
    }
    // if (disabled || isClose) {
    if (disabled) props.className = 'c-calendar__day-disabled';
    props.id = `day-${new Date(props).getTime()}`;
    return props;
  };

  const [minTime, maxTime] = useMemo(() => {
    const min = new Date();
    min.setHours(0);
    min.setMinutes(0);
    min.setSeconds(0);
    const max = new Date();
    max.setHours(23);
    max.setMinutes(59);
    max.setSeconds(0);
    return [min, max];
  }, []);

  return (
    <DragAndDropCalendar
      selectable={selectable}
      localizer={localizer}
      events={events}
      resizable
      step={15}
      timeslots={2}
      style={{ height: 'calc(100vh - 185px)' }}
      defaultView={Views.DAY}
      view={Views[viewPreset]}
      defaultDate={new Date()}
      date={date}
      scrollToTime={nowTime}
      min={minTime}
      max={maxTime}
      popup={true}
      toolbar={false}
      // resources={isMarketplace ? undefined : resources}
      // resourceIdAccessor={isMarketplace ? undefined : 'id'}
      resources={resources}
      resourceIdAccessor={'id'}
      slotPropGetter={handleSlotPropGetter}
      dayPropGetter={handleDayPropGetter}
      titleAccessor={(item) => eventItem(item, viewPreset)}
      resourceTitleAccessor={({ name, avatar }) => (
        <div className="c-calendar__resource">
          <ImageName name={name} className="c-calendar__resource-img" imageSrc={avatar} />
          <h4 className="c-calendar__resource-name">{name}</h4>
        </div>
      )}
      {...otherProps}
    />
  );
};

PureCalendar.propTypes = {
  url: PropTypes.any,
  events: PropTypes.array,
  resources: PropTypes.array,
  workingHours: PropTypes.array,
  closedDates: PropTypes.array,
  selectable: PropTypes.bool,
};

export default PureCalendar;
