/**
 *
 * @param closedDate {{
 *     id,
 *     title,
 *     start_time,
 *     end_time,
 *     staff, @just for staff closed date,
 * }}
 * @constructor
 */

function ClosedDateObject(closedDate) {
  this.id = closedDate.id;
  this.title = closedDate.title;
  this.start_time = closedDate.start_time;
  this.end_time = closedDate.end_time;
  this.staffName = closedDate.staff_name || undefined;
  this.staff = closedDate.staff || undefined;
  this.resourceId = closedDate.staff || undefined;
  this.start = closedDate.staff ? new Date(closedDate.start_time) : undefined;
  this.end = closedDate.staff ? new Date(closedDate.end_time) : undefined;
  this.type = closedDate.staff ? 'blocked' : undefined;
}

export default ClosedDateObject;
