import { callApi } from '../../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../../config/apiConfig';
import EducationObject from '../../objects/StylistProfile/EducationObject';
const { GET, POST, PATCH, DELETE } = ApiMethods;

/**
 * @param params {{
 *    id: String,
 *    start_date: String,
 *    end_date: String,
 *    school: String,
 *    degree: String,
 *    city: String,
 * }}
 */
export const saveEducation = async ({ ...params }) => {
  const response = await callApi({
    method: params?.id ? PATCH : POST,
    url: params?.id
      ? createURL(ApiRoutes.stylistEducations.update, { id: params.id })
      : ApiRoutes.stylistEducations.create,
    params,
    absolute: true,
  });
  if (response.data) {
    return new EducationObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const getEducationList = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistEducations.list,
  });
  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new EducationObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

export const getEducation = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistEducations.get,
  });
  if (response.data) {
    return new EducationObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

export const deleteEducation = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    absolute: true,
    url: createURL(ApiRoutes.stylistEducations.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
