import { callApi } from '../../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../../config/apiConfig';
const { GET, POST, PATCH, DELETE } = ApiMethods;

const getParams = (params) => {
  if (!!params.image) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (!!params[key] && key !== 'image') formData.append(key, params[key]);
    });
    formData.append('image', params.image, params.image.name);
    return formData;
  }
  return params;
};

/**
 * @param params {{
 *    id: String,
 *    image: String,
 *    business_id: String,
 *    business_title: String,
 *    is_published: Boolean,
 * }}
 */
export const saveStylistPhoto = async ({ id, ...params }) => {
  const response = await callApi({
    method: id ? PATCH : POST,
    url: id ? createURL(ApiRoutes.stylistPhoto.getById, { id }) : ApiRoutes.stylistPhoto.get,
    params: getParams(params, 'image'),
    absolute: true,
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

export const getStylistPhotos = async () => {
  const response = await callApi({
    method: GET,
    params: {},
    absolute: true,
    url: ApiRoutes.stylistPhoto.get,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

export const deleteStylistPhoto = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    absolute: true,
    url: createURL(ApiRoutes.stylistPhoto.getById, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
