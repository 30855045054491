import React from 'react';
import { CSVLink } from 'react-csv';

const headers = [
  { label: 'full_name', key: 'fullName' },
  { label: 'email', key: 'email' },
  { label: 'phone_number', key: 'phoneNumber' },
  { label: 'birthday_month', key: 'birthdayMonth' },
  { label: 'birthday_day', key: 'birthdayDay' },
  { label: 'note', key: 'note' },
];

const data = [
  {
    fullName: 'John Smith',
    email: 'john.smith@doe.com',
    phoneNumber: '+12042345678',
    birthdayMonth: '1',
    birthdayDay: '20',
    note: 'Good Customer',
  },
];

const ClientsImportFile = () => {
  const csvReport = {
    data: data,
    headers: headers,
    filename: `Partners Clients Import.csv`,
  };

  return (
    <CSVLink
      {...csvReport}
      data-tour="sample-btn"
      id="importDownloadSampleBtn"
      className="c-button c-button--light mb-0"
    >
      Download import file
    </CSVLink>
  );
};

export default ClientsImportFile;
