import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Filters from '../../Common/UIKit/HeadOptions/Filters';
import InvoiceModal from '../../Common/Invoice/InvoiceModal';
import { getInvoiceList } from '../../../api/invoices';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import Pagination from '../../Common/UIKit/Pagination';
import useApi, { handleApi } from '../../../utilities/useApi';
import InvoiceItem from '../../Common/Invoice/InvoiceItem';
import { getQueryUri, setQueryUri } from '../../../utilities/URI';
import HeadPage from '../../Common/Layouts/HeadPage/HeadPage';
import { AppContext } from '../../../context/providers';
import usePermission from '../../../utilities/usePermission';

const URL_PAGE = '/sales/transactions';

// eslint-disable-next-line react/prop-types
const SalesTransactions = ({ headerEl }) => {
  const [data, setData] = useState(null);
  const [invoiceModal, setInvoiceModal] = useState(null);
  const [pageQuery, setPageQuery] = useState(null);
  const { setHeader, isMarketplace } = useContext(AppContext);
  const history = useHistory();
  const { id } = useParams();
  const { access: _client } = usePermission('client', 'crud', 'view');
  const { access: _access } = usePermission('invoice', 'crud');

  const apiParams = {
    key: 'invoice',
    to_date: true,
    from_date: true,
    staff: true,
    status: true,
    service: true,
    search: true,
    page: true,
    sale_channel: isMarketplace ? 'marketplace' : 'software',
    // sale_channel__in: encodeURIComponent(
    //   isMarketplace ? 'marketplace,site,email_reminder,google_reserve' : 'walk_in,software',
    // ),
  };

  const [, loading] = useApi(getInvoiceList, apiParams, setData);

  useEffect(() => {
    setHeader('Sales / Transactions');
  }, [setHeader]);

  useEffect(() => {
    if (data) {
      if (id) {
        const item = data.results.find((i) => i.id === +id);
        handleOpenModal(item ? item : { id });
      } else {
        handleCloseModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id]);

  const handleOpenModal = (data) => setInvoiceModal(data);
  const handleCloseModal = (opened = true) => {
    if (opened) {
      history.replace(URL_PAGE + (pageQuery ? setQueryUri(pageQuery) : ''));
    }
    setInvoiceModal(null);
    setPageQuery(null);
  };

  const handleSave = () => {
    handleCloseModal();
    const page = pageQuery?.page || 1;
    handleApi(getInvoiceList, { page }).then(setData);
  };

  return (
    <>
      <HeadPage rightElement={_access?.view ? <Filters invoiceStatus date /> : null} leftElement={headerEl} />
      {_access?.view ? (
        <>
          {!!invoiceModal && <InvoiceModal data={invoiceModal} onSave={handleSave} onClose={handleCloseModal} />}

          {loading && !data ? (
            <PageLoading />
          ) : !!data?.results && data.results.length > 0 ? (
            <>
              <div className="c-panel-content">
                {loading && !id && <PageLoading over />}
                <div className="c-table c-table--sm">
                  <table>
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th>Services/Gift Cards</th>
                        <th className="text-center">Payment Date</th>
                        <th className="text-center">Staff</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Discount Type</th>
                        <th className="text-center">Total Tips</th>
                        {/*<th className="text-center">Payment By</th>*/}
                        {/*<th className="text-center">Refund By</th>*/}
                        <th className="text-center">Total Paid</th>
                        <th className="text-center">Total Refunded</th>
                        <th className="text-center">Gross Total</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.map((item, index) => (
                        <InvoiceItem
                          accessClient={_client}
                          key={item.id}
                          page_url={URL_PAGE}
                          index={index}
                          item={item}
                          onClick={() => setPageQuery(getQueryUri())}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {data && <Pagination current={pageQuery?.page ? +pageQuery.page : null} count={data.count} />}
            </>
          ) : (
            <NotFoundData icon="fas fa-receipt" title="No Sale Transactions Exists" />
          )}
        </>
      ) : (
        <AccessDeniedPage />
      )}
    </>
  );
};

export default SalesTransactions;
