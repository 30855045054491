import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import toaster from 'toasted-notes';
import Modal from '../UIKit/Modal';
import Tab from '../UIKit/Tab';
import { ControlInput } from '../UIKit/Form/Input';
import { ControlCheckbox } from '../UIKit/Form/Checkbox';
import SectionHead from '../UIKit/SectionTitle';
import { handleApiSave, useApiById } from '../../../utilities/useApi';
import { getBusinessById, partialUpdateBusinessById } from '../../../api';
import { PageLoading } from '../UIKit/Spinner';
import BusinessLocationForm from '../Business/BusinessLocationForm';

const LocationProfile = ({ data, errors, control, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <ControlInput
      control={control}
      name="title"
      rules={{ required: 'Title is required' }}
      defaultValue={data ? data.title : ''}
      label={'Title'}
      placeholder={'Title'}
      errorMessage={errors?.title?.message}
    />
    <ControlInput
      control={control}
      name="phone_number"
      type="phone"
      defaultValue={data.phone_number || ''}
      label={'Phone'}
      placeholder={'Phone'}
      errorMessage={errors?.phone_number?.message}
    />
    <ControlInput
      control={control}
      name="bio"
      defaultValue={data.bio || ''}
      type="textarea"
      label={'Bio'}
      placeholder={'Bio'}
      errorMessage={errors?.bio?.message}
    />
    <ControlInput
      control={control}
      name="tax_rate"
      rules={{
        min: {
          value: 0.0000001,
          message: 'Tax should be more than zero',
        },
        required: 'Tax is required',
      }}
      defaultValue={data?.tax_rate}
      label="Tax Rate"
      placeholder="eg. 5%"
      unit="%"
      errorMessage={errors?.tax_rate?.message}
    />
    <ControlCheckbox
      control={control}
      name="is_independent"
      defaultValue={data ? data.is_independent : true}
      label={'I am an independent'}
      errorMessage={errors?.is_independent?.message}
    />

    <hr />

    <SectionHead subTitle title="Social pages(Optional)" />

    <ControlInput
      control={control}
      name="website_address"
      defaultValue={data ? data.website_address : ''}
      type="url"
      unit={<i className="fas fa-link" />}
      placeholder="Website"
    />

    <ControlInput
      control={control}
      name="instagram_account"
      defaultValue={data ? data.instagram_account : ''}
      type="url"
      unit={<i className="fab fa-instagram" />}
      placeholder="Instagram"
    />
    <ControlInput
      control={control}
      name="facebook_account"
      defaultValue={data ? data.facebook_account : ''}
      type="url"
      unit={<i className="fab fa-facebook-f" />}
      placeholder="Facebook"
    />
    <ControlInput
      control={control}
      name="twitter_account"
      defaultValue={data ? data.twitter_account : ''}
      type="url"
      unit={<i className="fab fa-twitter" />}
      placeholder="Twitter"
    />
    <ControlInput
      control={control}
      name="yelp_account"
      defaultValue={data ? data.yelp_account : ''}
      type="url"
      unit={<i className="fab fa-yelp" />}
      placeholder="Yelp"
    />
  </form>
);

LocationProfile.propTypes = {
  data: PropTypes.any,
  errors: PropTypes.any,
  control: PropTypes.any,
  onSubmit: PropTypes.func,
};

const LocationModal = ({ id, onClose, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [data, loading] = useApiById(getBusinessById, id);
  const { control, errors, handleSubmit, setValue } = useForm();
  const handleSave = (data) => {
    handleApiSave(undefined, partialUpdateBusinessById, data, id, { loadingFn: setSaveLoading }).then((res) => {
      if (res) {
        toaster.notify(() => <div className="alert alert-success mt-3">Business Info Changed Successfully</div>);
        onSave();
      }
    });
  };
  const handleChangeProvince = (e) => {
    if (e.id !== data?.province) {
      setValue('tax_rate', e.tax_rate);
    } else {
      setValue('tax_rate', data?.tax_rate);
    }
  };
  return (
    <Modal show lg sticky saveLoading={saveLoading} onClose={onClose} onSave={handleSubmit(handleSave)}>
      {loading ? (
        <PageLoading />
      ) : (
        <Tab isModal tabs={['Profile', 'Location']}>
          <LocationProfile onSubmit={handleSubmit(handleSave)} data={data} control={control} errors={errors} />
          <form onSubmit={handleSubmit(handleSave)}>
            <BusinessLocationForm
              data={data}
              errors={errors}
              control={control}
              onChangeProvince={handleChangeProvince}
            />
          </form>
        </Tab>
      )}
    </Modal>
  );
};

LocationModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default LocationModal;
