import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';

const { POST, GET } = ApiMethods;

/**
 * @description list of all services requests method
 */
export const getRequestServiceList = async ({ page, limit }) => {
  limit = limit || 10;
  page = page || 1;
  const response = await callApi({
    method: GET,
    url: ApiRoutes.requests.serviceList,
    params: {
      limit,
      offset: (page - 1) * limit,
    },
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getRequestServiceItem = async (id) => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.requests.serviceItem, { id }),
  });
  if (response.data) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response);
  }
};
