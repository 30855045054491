import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/UIKit/Form/Button';
import ServiceFormModal from '../../Common/Services/ServiceForm/ServiceFormModal';
import { PageLoading } from '../../Common/UIKit/Spinner';
import NotFoundData, { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import { getServiceGroupList } from '../../../api/services';
import Services from '../../Common/Services/Services';
import { AppContext } from '../../../context/providers';
import HeadOptions from '../../Common/Layouts/HeadOptions/HeadOptions';
import PublishMarketplaceModal from '../../Common/SystemSetup/PublishMarketplaceModal';
import { handleApi } from '../../../utilities/useApi';
// import { TourGuide } from '../../Common/UIKit/TourContent';
import { servicesSteps } from '../../../config/tourSteps';
import usePermission from '../../../utilities/usePermission';
import BookablePhotoModal from '../../Common/BookablePhoto/BookablePhotoModal';
import { confirmAlert } from 'react-confirm-alert';

const ServicesPage = ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showPhoto, setShowPhoto] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showMarketplace, setShowMarketplace] = useState(false);
  const [uri, setUri] = useState(null);
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  const { setHeader, system, business, isMarketplace } = useContext(AppContext);

  const { access: _access } = usePermission('service', 'crud');
  const { access: _categories } = usePermission('servicecategory', 'crud');
  const { access: _photo } = usePermission('bookablephoto', 'crud');

  useEffect(() => {
    setHeader('Services', { guide: servicesSteps });
  }, [setHeader]);
  // useEffect(() => {
  //   if (!data) {
  //     setIsTourOpen(true);
  //   }
  // }, [data]);

  useEffect(() => {
    if (_categories?.view) {
      handleApi(getServiceGroupList, {}, { loadingFn: setLoading, key: 'servicecategory' }).then((res) => {
        setData(res || []);
      });
    }
    // if (!data) {
    //   handleApi(getServiceGroupList, {}, { loadingFn: setLoading }).then((data) => {
    //     setData(data || []);
    //   });
    //   // setLoading(true);
    //   // getServiceGroupList().then((data) => {
    //   //   setData(data || []);
    //   //   setLoading(false);
    //   // });
    // }
  }, [_categories]);

  useEffect(() => {
    if (match.path === '/services/add' || match.path === '/services/:id/edit') {
      handleOpenForm();
    } else if (match.path === '/services/:serviceId/add-photo') {
      handleOpenPhoto();
    }
  }, [match]);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => {
    if (uri) history.goBack();
    else history.replace(`/services`);
    setUri(null);
    setShowForm(false);
  };
  const handleOpenPhoto = () => setShowPhoto(true);
  const handleClosePhoto = () => {
    if (uri) history.goBack();
    else history.replace(`/services`);
    setUri(null);
    setShowPhoto(false);
  };

  const handleSave = (res, isFirstService = false, isNew = false) => {
    // setLoading(true);
    // getServiceGroupList().then((data) => {
    //   setData(data || []);
    //   setLoading(false);
    // });
    handleApi(getServiceGroupList, {}, { loadingFn: setLoading }).then((res) => {
      setData(res || []);
    });
    if (isNew && isMarketplace && res && _photo?.modify) {
      confirmAlert({
        title: 'Add Photo For Service',
        message: 'Do you want to add bookable photo for this service?',
        buttons: [
          {
            label: 'Yes',
            className: 'bg-danger',
            onClick: () => {
              setShowForm(false);
              history.replace(`/services/${res.id}/add-photo`);
            },
          },
          {
            label: 'No',
            onClick: () => {
              handleCloseForm();
            },
          },
        ],
      });
    } else {
      handleCloseForm();
    }

    if (isFirstService && system === 'marketplace' && !business.is_published) {
      setShowMarketplace(true);
    }
  };

  const isEmpty = useMemo(() => (data ? data.filter((i) => i.services.length > 0).length === 0 : false), [data]);

  return (
    <>
      {showForm && _access?.modify && <ServiceFormModal onClose={handleCloseForm} onSave={handleSave} />}
      {showPhoto && isMarketplace && _photo?.modify && (
        <BookablePhotoModal onClose={handleClosePhoto} onSave={handleClosePhoto} />
      )}
      {showMarketplace && <PublishMarketplaceModal onClose={() => setShowMarketplace(false)} />}
      <HeadOptions
        className="my-3"
        rightElement={
          _access?.modify && _categories?.view ? (
            <Button id="addBtn" data-tour="add-btn" link="/services/add">
              Add New Service
            </Button>
          ) : null
        }
      />
      {_categories?.view && _access?.view ? (
        <>
          {loading || !data ? (
            <PageLoading />
          ) : !isEmpty ? (
            <div data-tour="list" className="c-service-group">
              <Services isModify={_access?.modify} data={data} />
            </div>
          ) : (
            <NotFoundData
              fixedWidth
              title="Add New Service"
              description="Setup your Services here. Too easy!"
              button={
                _access?.modify && (
                  <Button block id="emptyAddBtn" link="/services/add">
                    New Service
                  </Button>
                )
              }
              icon="fas fa-clipboard-list"
            />
          )}
          {/*<TourGuide*/}
          {/*  disableInteraction*/}
          {/*  steps={servicesSteps}*/}
          {/*  startAt={tourStep}*/}
          {/*  getCurrentStep={setTourStep}*/}
          {/*  isOpen={isTourOpen}*/}
          {/*  onRequestClose={() => setIsTourOpen(false)}*/}
          {/*/>*/}
        </>
      ) : (
        <AccessDeniedPage title={!_categories?.view ? 'Categories View Access Permission' : undefined} />
      )}
    </>
  );
};

ServicesPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default ServicesPage;
