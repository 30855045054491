import React, { useCallback, useContext } from 'react';
import Modal from '../../UIKit/Modal';
import { Types } from '../../../../config/actionTypes';
import { AppContext } from '../../../../context/providers';
import SetupCloseBtn from '../SetupCloseBtn';
import SetupSoftwareContent from './SetupSoftwareContent';

// eslint-disable-next-line react/prop-types
const SetupSoftwareModal = ({ reference, onClose, ...restProps }) => {
  const { setSystem } = useContext(AppContext);

  const handleClose = useCallback(() => {
    if (reference) {
      localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
      setSystem('software');
      window.location.href = '/';
    }
    return onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  return (
    <Modal show auto disableFooter disableHeader onClose={handleClose} bodyClass="p-0" title="Setup Marketplace">
      <SetupCloseBtn onClick={handleClose} />
      <SetupSoftwareContent {...restProps} />
    </Modal>
  );
};

export default SetupSoftwareModal;
