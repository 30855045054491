/**
 *
 * @param user {{
 *     id,
 *     avatar,
 *     avatar_url,
 *     first_name,
 *     last_name,
 *     full_name,
 *     phone_number,
 *     mobile,
 *     email,
 *     latitude,
 *     longitude,
 *     country,
 *     province,
 *     city,
 *     address,
 *     zip_code,
 *     bio,
 *     unread_count,
 *     trial_is_used,
 *     trialing,
 *     trial_end_at,
 *     meta,
 *     user,
 * }}
 * @constructor
 */

function UserObject(user) {
  this.id = user.id || user?.user?.id;
  this.firstName = user.first_name;
  this.lastName = user.last_name;
  this.fullName = user.full_name || `${user.first_name} ${user.last_name}`;
  this.avatar = user.avatar_url || user.avatar || null;
  this.phone_number = user.phone_number;
  this.email = user.email;
  this.latitude = user.latitude;
  this.longitude = user.longitude;
  this.country = user.country;
  this.province = user.province;
  this.city = user.city;
  this.address = user.address;
  this.zip_code = user.zip_code;
  this.bio = user.bio;
  this.unread_count = user.unread_count;
  this.trial_is_used = user.trial_is_used;
  this.trialing = user.trialing;
  this.is_trial = user.trialing;
  this.trial_end_at = user.trial_end_at;
}

export default UserObject;
