import React, { useContext, useEffect, useState } from 'react';
import { handleApiSave } from '../../../../utilities/useApi';
import { partialUpdateBusiness } from '../../../../api';
import toaster from 'toasted-notes';
import SectionHead from '../../UIKit/SectionTitle';
import Checkbox from '../../UIKit/Form/Checkbox';
import Button from '../../UIKit/Form/Button';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceBookingMethods = ({ isNew = false, onNext, onPrev }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [method, setMethod] = useState('instant');

  const { business, setBusiness, newBusiness, onNewBusiness } = useContext(AppContext);

  useEffect(() => {
    setMethod(newBusiness?.booking_mode || business?.booking_mode);
  }, [business, newBusiness]);

  const handleSave = (e) => {
    e.preventDefault();
    handleApiSave(
      partialUpdateBusiness,
      null,
      { businessId: isNew ? newBusiness.id : undefined, booking_mode: method },
      null,
      { loadingFn: setSaveLoading },
    )
      .then((data) => {
        toaster.notify(() => <div className="alert alert-success mt-3">Booking Method Changed Successfully</div>);
        if (isNew) onNewBusiness(data);
        else setBusiness(data);
        onNext();
      })
      .catch(() => {});
  };

  return (
    <form onSubmit={handleSave} className="px-3">
      <SectionHead title="Booking Methods" />
      <p>
        Beautster offers you two options to present your schedule and availability. You can configure it differently
        based on your needs.
      </p>
      <div className="alert alert-warning">
        **Instant booking: with this option, Beautster allows your customers to directly select and book the time slot
        that suits them best.
      </div>
      <div className="alert alert-warning">
        ** 3-time slots request: We ask your customers to provide three time slots availability. Afterward, you’ll have
        to assign this appointment request to the professional you want, according to the requested time slots.
      </div>
      <Checkbox
        name="method"
        type="radio"
        id="instant"
        checked={method === 'instant'}
        onChange={() => setMethod('instant')}
        label="Instant Booking"
      />
      <Checkbox
        name="method"
        type="radio"
        id="request"
        checked={method === '3_time'}
        onChange={() => setMethod('3_time')}
        label="Request a Time (Confirmation is Required)"
      />
      <div className="d-flex align-center mt-5">
        <Button type="button" color="light" onClick={onPrev} className="mr-auto">
          Previous Step
        </Button>
        <Button loading={saveLoading} className="ml-auto">
          Next Step
        </Button>
      </div>
    </form>
  );
};

export default SetupMarketplaceBookingMethods;
