import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';

const { GET } = ApiMethods;

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getTagList = async ({ page, limit, ...param } = {}) => {
  limit = limit || 10;
  page = page || 1;

  const response = await callApi({
    method: GET,
    locationBase: false,
    params: {
      limit,
      offset: (page - 1) * limit,
      ...param,
    },
    url: ApiRoutes.tags.list,
  });

  if (response.data) {
    return response.data;
    // return {
    //     ...response.data,
    //     results: (response.data.results || []).map(i => new i)
    // }
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getTag = async (id) => {
  const response = await callApi({
    method: GET,
    locationBase: false,
    params: {},
    url: createURL(ApiRoutes.tags.get, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
