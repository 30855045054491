import React, { useContext, useMemo, useState } from 'react';
import Tab from '../../Common/UIKit/Tab';
import BusinessProfile from './BusinessProfile';
import BusinessLocation from './BusinessLocation';
import { AppContext } from '../../../context/providers';
import { AccessDeniedPage } from '../../Common/UIKit/NotFoundData';
import usePermission from '../../../utilities/usePermission';

const BusinessProfilePage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { userPermissions: p } = useContext(AppContext);
  const _business = useMemo(
    () => (p?.business?.crud?.modify || p?.business?.crud?.view ? p?.business?.crud : false),
    [p],
  );
  const { isOwner } = usePermission();
  const tabs = ['Profile', 'Location'];
  const renderRightEl = <Tab tabs={tabs} tabHeadClassName={'p-0'} onSelect={setTabIndex} selected={tabIndex} />;

  const renderPages = () => {
    switch (tabIndex) {
      case 0:
        return <BusinessProfile headerEl={renderRightEl} />;
      case 1:
        return <BusinessLocation headerEl={renderRightEl} />;
      default:
        return <BusinessProfile headerEl={renderRightEl} />;
    }
  };

  return _business || isOwner ? renderPages() : <AccessDeniedPage />;
};

export default BusinessProfilePage;
