import React, { useEffect, useState } from 'react';
import { Add, School } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Modal from '../../UIKit/Modal';
import { PageLoading } from '../../UIKit/Spinner';
import { handleApi, handleApiDeleteById } from '../../../../utilities/useApi';
import { deleteEducation, getEducationList } from '../../../../api';
import Button from '../../UIKit/Form/Button';
import EducationFormModal from './EducationFormModal';
import EducationItem from './EducationItem';

const EducationModal = ({ onClose }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  // null, data item, if new it should set null
  const [itemModal, setItemModal] = useState(null);
  useEffect(() => {
    handleApi(getEducationList)
      .then((data) => {
        setData(data);
      })
      .finally(() => setLoading(false));
  }, []);
  const handleSave = (item) => {
    setData((prev) => {
      const results = prev?.results || [];
      const ind = results.findIndex((i) => i.id === item?.id);
      if (ind > -1) {
        results[ind] = item;
      } else {
        results.push(item);
      }
      return {
        ...(prev || {}),
        results,
      };
    });
    setItemModal(null);
    setShowModal(false);
  };
  const handleEditItem = (item) => {
    setItemModal(item);
    setShowModal(true);
  };
  const handleDeleteItem = (itemId) => {
    const options = {
      title: 'Confirm to delete this item',
      message: 'Are you sure to delete this item.',
      loadingFn: () => {},
    };
    handleApiDeleteById(deleteEducation, itemId, options).then(() => {
      setData((prev) => {
        const results = prev?.results || [];
        const ind = results.findIndex((i) => i.id === itemId);
        if (ind > -1) {
          results.splice(ind, 1);
        }
        return {
          ...(prev || {}),
          results,
        };
      });
    });
  };
  return (
    <>
      <Modal
        show
        lg
        sticky
        title="Educations"
        contentClass="c-user-stylist-profile__modal c-user-stylist-profile__modal--sm"
        bodyClass="c-user-stylist-profile__modal-body"
        onClose={onClose}
        saveBtn={false}
      >
        {loading ? (
          <PageLoading type="box" />
        ) : (
          <div className="c-user-stylist-profile__container">
            <div className="c-user-stylist-profile__section">
              <section className="c-user-stylist-profile__content">
                {(data?.results || []).length > 0 ? (
                  <ul className="c-user-stylist-profile__features">
                    {(data?.results || []).map((edu) => (
                      <EducationItem
                        key={edu.id}
                        item={edu}
                        onDelete={() => handleDeleteItem(edu.id)}
                        onEdit={() => handleEditItem(edu)}
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="c-user-stylist-profile__empty">
                    <School />
                    <h4>No education added</h4>
                    <Button className="mt-3" onClick={() => setShowModal(true)}>
                      Add Education
                    </Button>
                  </div>
                )}
              </section>
              {(data?.results || []).length > 0 && (
                <div className="c-user-stylist-profile__add" onClick={() => setShowModal(true)}>
                  <Add />
                  <span className="c-user-stylist-profile__add-label">Add Education</span>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
      {showModal && (
        <EducationFormModal
          shouldSave={false}
          data={itemModal}
          onClose={() => setShowModal(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
};

EducationModal.propTypes = {
  data: PropTypes.any,
  shouldSave: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default EducationModal;
