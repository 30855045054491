import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';
import { handleApiSave } from '../../../../utilities/useApi';
import { updateStatusAppointment } from '../../../../api/appointments';
import Modal from '../../UIKit/Modal';
import Select from '../../UIKit/Form/Select';

const options = [
  { value: 'no_reason_provided', label: 'No reason provided' },
  { value: 'duplicate', label: 'Duplicate appointment' },
  { value: 'made_by_mistake', label: 'Appointment made by mistake' },
  { value: 'client_not_available', label: 'Client not available' },
];

const AppointmentCancelModal = ({ data, loadingFn, onClose, onSave }) => {
  const [reason, setReason] = useState('0');
  const handleCancel = () => {
    handleApiSave(
      undefined,
      updateStatusAppointment,
      // { status: 'cancel_by_business', cancellation_reason: reason },
      { status: 'set-cancel-by-business', cancellation_reason: reason },
      data.id,
      { loadingFn },
    )
      .then((d) => {
        toaster.notify(() => <div className="alert alert-success">Appointment set up to Cancel</div>);
        onSave(d);
      })
      .catch(() => {});
  };

  return (
    <Modal
      show
      onClose={onClose}
      title="Cancel Appointment"
      saveBtnText="Cancel Appointment"
      onSave={handleCancel}
      bodyClass="min-vh-20"
    >
      <Select
        // defaultMenuIsOpen
        id="reason"
        label="Cancellation reason"
        options={options}
        onChangeValue={setReason}
        formClassName="mb-5 mt-3"
        value={reason}
      />
    </Modal>
  );
};

AppointmentCancelModal.propTypes = {
  data: PropTypes.any,
  loadingFn: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default AppointmentCancelModal;
