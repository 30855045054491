import React from 'react';
import classnames from 'classnames';

// eslint-disable-next-line react/prop-types
export const DotsSpinner = ({ sm, dark }) => (
  <div className="c-spinner c-spinner-dots__container">
    <div
      className={classnames('c-spinner-dots', {
        'c-spinner-dots--sm': sm,
        'c-spinner-dots--dark': dark,
      })}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

// eslint-disable-next-line react/prop-types
export const CircleSpinner = ({ dark, light, sm, lg }) => (
  <div className="c-spinner">
    <div
      className={classnames('c-spinner-circle', {
        'c-spinner-circle--dark': dark,
        'c-spinner-circle--light': light,
        'c-spinner-circle--lg': lg,
        'c-spinner-circle--sm': sm,
      })}
    />
  </div>
);

// eslint-disable-next-line react/prop-types
export const BoxSpinner = ({ dark, light }) => (
  <div className="c-spinner c-spinner-box__container">
    <div
      className={classnames('c-spinner-box', {
        'c-spinner-box--dark': dark,
        'c-spinner-box--light': light,
      })}
    />
  </div>
);

// eslint-disable-next-line react/prop-types
export const PageLoading = ({ over, fixed, type = 'box', style, ...otherProps }) => (
  <div
    className={classnames('c-spinner-page', {
      'c-spinner-page--over': over,
      'c-spinner-page--fixed': fixed,
    })}
    style={style}
  >
    {type === 'dot' ? (
      <DotsSpinner {...otherProps} />
    ) : type === 'box' ? (
      <BoxSpinner {...otherProps} />
    ) : (
      <CircleSpinner {...otherProps} />
    )}
  </div>
);
