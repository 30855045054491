import React, { useState } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { ArrowBack, ArrowRightAlt, PlayArrow } from '@material-ui/icons';
import Button from '../../UIKit/Form/Button';
import { IMAGE_SETUP_MARKETPLACE_SIDE_BANNER, IMAGE_SETUP_SOFTWARE_SIDE_BANNER } from '../../../../assets/Images';

const SetupAllGoalContent = ({ onBack, onSubmit }) => {
  // null, "software", "marketplace" videos
  const [playVideo, setPlayVideo] = useState(null);
  const handleBack = () => {
    setPlayVideo(null);
    onBack();
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
    event.target.playVideo();
  };

  return (
    <div className="c-panel-setup__onboard">
      {playVideo ? (
        <div className="c-panel-setup__onboard-video">
          <div className="c-panel-setup__onboard-video-head">
            <div className="c-panel-setup__onboard-video-prev" onClick={handleBack}>
              <ArrowBack />
            </div>
            <Button sm className="c-panel-setup__onboard-btn" onClick={onSubmit}>
              Continue To Setup
              <ArrowRightAlt />
            </Button>
          </div>
          <YouTube
            videoId={playVideo === 'marketplace' ? 'I71KUWv-Ggk' : 'YiIEsTg_DBc'}
            className="c-panel-setup__onboard-video-player"
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
              },
            }}
            onReady={onReady}
          />
        </div>
      ) : (
        <div className="c-panel-setup__onboard-preview">
          <div className="c-panel-setup__onboard-all">
            <div className="c-panel-setup__onboard-side">
              <img src={IMAGE_SETUP_MARKETPLACE_SIDE_BANNER} alt="Marketplace" className="c-panel-setup__onboard-img" />
              <div className="c-panel-setup__onboard-prev-text">
                <h1>Beautster&apos;s online Marketplace</h1>
                <p>
                  Beautster is a vibrant, rapidly growing community of clients and beauty professionals that connects
                  local customers to local salons. Our online customers are looking for businesses like yours. Show them
                  why they should choose you.
                </p>
                <div className="c-panel-setup__onboard-prev-buttons">
                  <div className="c-panel-setup__onboard-prev-play" onClick={() => setPlayVideo('marketplace')}>
                    <PlayArrow />
                  </div>
                </div>
              </div>
            </div>
            <div className="c-panel-setup__onboard-side">
              <img src={IMAGE_SETUP_SOFTWARE_SIDE_BANNER} alt="Software" className="c-panel-setup__onboard-img" />
              <div className="c-panel-setup__onboard-prev-text">
                <h1>Beautster&apos;s Powerful, High-end Salon Software</h1>
                <p>
                  Beautster Custom Software is built based on almost 1100 interviews with salon and spa owners all over
                  Canada. We automate scheduling, client and staff management, payment processing, and marketing with
                  all-in-one software solutions. It is hosted conveniently on the cloud, so your info is backed up
                  whenever you log in.
                </p>
                <div className="c-panel-setup__onboard-prev-buttons">
                  <div className="c-panel-setup__onboard-prev-play" onClick={() => setPlayVideo('software')}>
                    <PlayArrow />
                  </div>
                </div>
              </div>
            </div>
            <Button lg className="c-panel-setup__onboard-all-btn" onClick={onSubmit}>
              Setup your Business account now
              <ArrowRightAlt />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SetupAllGoalContent.propTypes = {
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SetupAllGoalContent;
