import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ControlInput } from '../../UIKit/Form/Input';
import { ControlSelect } from '../../UIKit/Form/Select';
import SectionHead from '../../UIKit/SectionTitle';
import {
  DurationChoice,
  ExtraTimeChoice,
  getValueLabel,
  ServicePriceTypeChoice,
  ServiceTieredLevelChoice,
} from '../../../../utilities/constants/choices';

const priceLevelOptions = getValueLabel({
  ...ServiceTieredLevelChoice,
  0: 'Choose Tiered  Level',
});

const ServiceFormPricing = ({ setValue, isEdit, values, control, errors, watch }) => {
  // const [formLength, setFormLength] = useState([0, 1]);
  const [formLength, setFormLength] = useState([0]);

  const handleLevelChange = (val, ind) => {
    if (ind + 1 === formLength.length && val !== 0 && val !== '0' && priceLevelOptions.length - 1 > formLength.length) {
      // handleAddForm();
    } else if ((val === '0' || val === 0) && ind + 1 !== formLength.length) {
      handleRemoveForm(ind);
    }
  };

  // const handleAddForm = () => {
  //   const updateLength = [...formLength, formLength.length];
  //   setFormLength(updateLength);
  // };

  const handleRemoveForm = (ind) => {
    const updateLength = formLength.filter((i, key) => key !== ind);
    setFormLength(updateLength);
  };

  const handleIsOptionDisabled = (option) =>
    watch().price.find((p) => p.tiered_level === option.value && option.value !== 0 && option.value !== '0');

  const getLevelLabel = (val) => {
    const item = priceLevelOptions.find((i) => i.value === val);
    if (item) return item.label;
    return priceLevelOptions[0].label;
  };

  const handleClose = (ind) => {
    watch().price.forEach((price, index) => {
      if (index > ind) {
        setValue(`price[${index - 1}]`, watch().price[index]);
      }
    });
    const updateLength = [...formLength];
    updateLength.pop();
    setFormLength(updateLength);
  };

  const renderForm = (ind) => {
    const levelOptions = [...priceLevelOptions];
    if (ind === 0) levelOptions.shift();

    const isFree = watch().price && watch().price[ind] && watch().price[ind].price_type === 'free';

    const selectedLvl =
      watch().price &&
      watch().price[ind] &&
      watch().price[ind].tiered_level !== '0' &&
      watch().price[ind].tiered_level !== 0;

    return (
      <div className={classnames('c-section', { 'd-none': watch()?.price?.remove })}>
        <SectionHead
          closeable={formLength.length > 2}
          subTitle
          title={`Pricing - ${watch()?.price && getLevelLabel(watch()?.price[ind]?.tiered_level)}`}
          onClose={() => handleClose(ind)}
        />
        <div className="c-form__group-row c-form__group-row--2">
          <ControlSelect
            control={control}
            name={`price[${ind}].tiered_level`}
            id={`price-${ind}_tiered_level`}
            rules={{ required: 'Tiered level is required' }}
            label="Tiered Level"
            options={levelOptions}
            isOptionDisabled={handleIsOptionDisabled}
            onChangeValue={(val) => handleLevelChange(val, ind)}
            maxMenuHeight={200}
            menuPlacement={formLength.length > ind + 1 ? 'bottom' : 'top'}
            errorMessage={errors?.price && errors?.price[ind]?.tiered_level?.message}
            defaultValue={values && values.price[ind] ? values.price[ind]?.tiered_level : ind === 0 ? 'no_level' : 0}
          />
        </div>
        {!!selectedLvl && (
          <>
            <div className="c-form__group-row">
              <ControlInput
                control={control}
                name={`price[${ind}].price`}
                rules={!isFree ? { required: 'Price amount is required' } : undefined}
                defaultValue={values && values.price[ind] ? values.price[ind]?.price : ''}
                type="tel"
                unit="$"
                id="price"
                min={0}
                label="Price"
                placeholder="0.00"
                value={isFree ? '0' : undefined}
                disabled={isFree}
                errorMessage={errors?.price && errors?.price[ind]?.price?.message}
              />
              <ControlSelect
                control={control}
                name={`price[${ind}].price_type`}
                id={`price-${ind}_price_type`}
                rules={{ required: 'Price Type is required' }}
                defaultValue={values && values.price[ind] ? values.price[ind]?.price_type : null}
                label="Price Type"
                options={getValueLabel(ServicePriceTypeChoice)}
                errorMessage={errors?.price && errors?.price[ind]?.price_type?.message}
              />
            </div>
            <div className="c-form__group-row">
              <ControlSelect
                control={control}
                name={`price[${ind}].duration`}
                id={`price-${ind}_duration`}
                rules={{ required: 'Price duration is required' }}
                defaultValue={values && values.price[ind] ? values.price[ind]?.duration : null}
                label="Duration"
                options={getValueLabel(DurationChoice)}
                maxMenuHeight={200}
                errorMessage={errors?.price && errors?.price[ind]?.duration?.message}
              />
              <ControlSelect
                control={control}
                name={`price[${ind}].extra_time`}
                id={`price-${ind}_extra_time`}
                rules={{ required: 'Extra time is required' }}
                defaultValue={values && values.price[ind] ? values.price[ind]?.extra_time : null}
                label="Extra time"
                options={getValueLabel(ExtraTimeChoice)}
                maxMenuHeight={200}
                errorMessage={errors?.price && errors?.price[ind]?.extra_time?.message}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="c-service-form__head">
        <SectionHead title="Pricing and Duration" description="Add the pricing options and duration of the service." />
      </div>
      <div data-tour="price" className="c-service-form__content">
        {isEdit ? renderForm(0) : formLength.map((i, key) => renderForm(key))}
      </div>
    </>
  );
};

ServiceFormPricing.propTypes = {
  setValue: PropTypes.func,
  isEdit: PropTypes.bool,
  values: PropTypes.any,
  control: PropTypes.any,
  errors: PropTypes.any,
  watch: PropTypes.any,
};

export default ServiceFormPricing;
