import React, { useMemo } from 'react';
import { ControlSelect } from '../UIKit/Form/Select';
import { range } from '../../../utilities/number';
import { getFormatTime } from '../../../utilities/DateHelpers';

// eslint-disable-next-line react/prop-types
const TimeSelect = ({ control, name, rules, defaultValue, maxMenuHeight = 200, ...otherProps }) => {
  const options = useMemo(
    () =>
      range(0, 23).reduce(
        (a, i) => [
          ...a,
          {
            value: `${i < 10 ? `0${i}` : i}:00:00`,
            label: getFormatTime(`${i}:00:00`, true),
          },
          {
            value: `${i < 10 ? `0${i}` : i}:15:00`,
            label: getFormatTime(`${i}:15:00`, true),
          },
          {
            value: `${i < 10 ? `0${i}` : i}:30:00`,
            label: getFormatTime(`${i}:30:00`, true),
          },
          {
            value: `${i < 10 ? `0${i}` : i}:45:00`,
            label: getFormatTime(`${i}:45:00`, true),
          },
        ],
        [],
      ),
    [],
  );
  return (
    <ControlSelect
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      maxMenuHeight={maxMenuHeight}
      options={options}
      {...otherProps}
    />
  );
};

export default TimeSelect;
