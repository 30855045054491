import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../UIKit/Form/Button';
import Dropdown from '../UIKit/Dropdown';
import ModalFormClientItem from '../ModalForm/ModalFormClient/ModalFormClientItem';
import ModalFormClients from '../ModalForm/ModalFormClient/ModalFormClients';
import ModalFormSidebarFooter from '../ModalForm/ModalFormSidebarFooter';
import ModalFormSidebar, { ModalFormSidebarContent } from '../ModalForm/ModalFormSidebar';
import ModalFormTotalPrice from '../ModalForm/ModalFormTotalPrice';
import { InvoicePaymentTypeChoice } from '../../../utilities/constants/choices';
import CheckoutGiftCard from './CheckoutGiftCard';
import { DotsSpinner } from '../UIKit/Spinner';
import { AppContext } from '../../../context/providers';
import toaster from 'toasted-notes';
import { handleApiSave } from '../../../utilities/useApi';
import { getStripeConnectAccountLink } from '../../../api';
import CheckoutStripe from './CheckoutStripe';
import { IS_DEV } from '../../../config/appConfig';

const CheckoutSidebar = ({
  lines,
  invoiceData,
  totalPrice,
  pays,
  type = null,
  client,
  onSave,
  onPay,
  onSuccessStripe,
  setClient,
  // onCreateStripe,
  stripeLoading,
  createInvoice,
}) => {
  const [connectLoading, setConnectLoading] = useState(false);
  const [totalPay, setTotalPay] = useState(0);
  const [paySidebar, setPaySidebar] = useState(null);
  const { business } = useContext(AppContext);

  useEffect(() => {
    setTotalPay(totalPrice);
  }, [totalPrice]);

  const options = useMemo(() => {
    const results = [];
    if (!invoiceData) {
      results.push({
        type: 'node',
        node: 'Save Unpaid',
        className: 'dropdown-link',
        onClick: () => onSave(false),
      });
    }
    if (!!invoiceData) {
      results.push({
        label: 'Invoice Details',
        url: `/sales/transactions/${invoiceData?.id}`,
      });
    }
    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData, onSave]);

  const handlePay = (key) => {
    // cash: 'Cash',
    // stripe: 'Stripe',
    // other: 'Other',
    // gift_card: 'Gift Card',
    let amount = +totalPay;
    if (+totalPrice < +totalPay) {
      setTotalPay(+totalPrice);
      amount = +totalPrice;
    }
    if (+amount === 0) return;
    switch (key) {
      case 'stripe':
        setPaySidebar(key);
        // onCreateStripe(amount);
        break;
      case 'gift_card':
        setPaySidebar(key);
        break;
      default:
        onPay(key, amount);
    }
  };

  const handlePaySuccess = (key, options) => {
    setPaySidebar(null);
    if (key === 'gift_card') {
      const supportLines = lines.filter((l) => options.services.indexOf(String(l.service)) > -1);
      const totalSupportPrice = supportLines.reduce((acc, i) => acc + +i.price, 0);
      if (+totalPay < +totalSupportPrice) {
        onPay(key, +totalPay, options);
      } else {
        if (+options.current_balance < +totalSupportPrice) {
          onPay(key, +options.current_balance, options);
        } else {
          onPay(key, +totalSupportPrice, options);
        }
      }
    } else if (key === 'stripe') {
      onSuccessStripe();
    } else {
      onPay(key, totalPay, options);
    }
  };

  const handleConnectError = () => {
    toaster.notify(() => (
      <div className="alert alert-danger">For using stripe, you should connect to your stripe account.</div>
    ));
  };

  const handleConnectAccount = () => {
    setConnectLoading(true);
    handleApiSave(getStripeConnectAccountLink, undefined, { businessId: business.id })
      .then(({ connect_url }) => {
        window.location.href = connect_url;
      })
      .catch(() => setConnectLoading(false));
  };

  const approaches = useMemo(
    () =>
      Object.keys(InvoicePaymentTypeChoice)
        // if type === 'gift_card', don't include approach with 'gift_card' key
        .filter((i) => i !== type && i !== 'credit')
        .filter((i) => !(invoiceData && i === 'stripe' && !invoiceData?.is_stripe_payment_available))
        .filter((i) => IS_DEV || i !== 'stripe')
        .map((key) => ({
          key,
          title: InvoicePaymentTypeChoice[key],
        })),
    [type],
  );

  const submit = useMemo(() => {
    const totalPaid = pays.reduce((a, p) => a + +p.price, 0);
    return {
      disabled: totalPaid === 0,
      partPaid: totalPaid > 0 && +totalPrice !== 0,
      completePaid: totalPaid > 0 && +totalPrice === 0,
    };
  }, [pays, totalPrice]);

  return (
    <ModalFormSidebar>
      {paySidebar === 'stripe' ? (
        <CheckoutStripe
          createInvoice={createInvoice}
          invoiceId={invoiceData?.id}
          price={+totalPay}
          onClose={() => setPaySidebar(null)}
          onSuccess={(options) => handlePaySuccess(paySidebar, options)}
        />
      ) : paySidebar === 'gift_card' ? (
        <CheckoutGiftCard
          lines={lines}
          price={+totalPay}
          pays={pays}
          onClose={() => setPaySidebar(null)}
          onSuccess={(options) => handlePaySuccess(paySidebar, options)}
        />
      ) : !client ? (
        <ModalFormClients onSelect={setClient ? setClient : undefined} />
      ) : (
        <ModalFormSidebarContent>
          <ModalFormClientItem
            data={client}
            onRemove={!invoiceData && type === 'gift_card' ? () => setClient(null) : undefined}
          />
          {((invoiceData && invoiceData?.status !== 'paid') || !invoiceData) && (
            <>
              {business && !business?.connected_to_stripe && (
                <div className="p-3">
                  <Button
                    block
                    id="connectStripBtn"
                    color="light"
                    loading={connectLoading}
                    onClick={handleConnectAccount}
                  >
                    Connect Stripe Now
                  </Button>
                </div>
              )}
              <ModalFormTotalPrice onChange={setTotalPay} label="Pay" price={totalPrice} />
              <div className="c-checkout-approaches">
                {approaches.map((a) => (
                  <div
                    key={a.key}
                    id={`checkoutApproach-${a.key}`}
                    className={classnames('c-checkout-approaches__item', {
                      'c-checkout-approaches__item--disabled':
                        +totalPrice === 0 || (business && !business?.connected_to_stripe && a.key === 'stripe'),
                      'c-checkout-approaches__item--odd': approaches.length % 2,
                    })}
                    onClick={
                      business && !business?.connected_to_stripe && a.key === 'stripe'
                        ? handleConnectError
                        : () => handlePay(a.key)
                    }
                  >
                    {stripeLoading && a.key === 'stripe' ? <DotsSpinner /> : a.title}
                  </div>
                ))}
                {/*{+totalPrice === 0 && <Button lg block color="dark" onClick={onSave}>Complete Checkout</Button>}*/}
              </div>
              <div className="c-checkout-submit">
                <Button lg block id="checkoutModalPay" disabled={submit.disabled} onClick={onSave}>
                  {submit.partPaid ? 'Part Paid Checkout' : 'Complete Checkout'}
                </Button>
                {/*<Button lg block disabled={!invoiceData && submit.disabled} onClick={onSave}>*/}
                {/*    {submit.partPaid ? 'Part Paid Checkout' :*/}
                {/*        submit.completePaid ? 'Complete Checkout' :*/}
                {/*            'Submit Checkout'}*/}
                {/*</Button>*/}
              </div>
            </>
          )}
          <ModalFormSidebarFooter>
            <Dropdown block positionMenu="top" options={options}>
              <Button id="checkoutModalOptions" block color="light">
                More Options
              </Button>
            </Dropdown>
          </ModalFormSidebarFooter>
        </ModalFormSidebarContent>
      )}
    </ModalFormSidebar>
  );
};

CheckoutSidebar.propTypes = {
  lines: PropTypes.array,
  invoiceData: PropTypes.any,
  totalPrice: PropTypes.any,
  pays: PropTypes.array,
  type: PropTypes.any,
  client: PropTypes.any,
  onSave: PropTypes.func,
  onSuccessStripe: PropTypes.func,
  onPay: PropTypes.func,
  setClient: PropTypes.func,
  onCreateStripe: PropTypes.func,
  createInvoice: PropTypes.func,
  stripeLoading: PropTypes.bool,
};

export default CheckoutSidebar;
