import React, { useContext, useState } from 'react';
import Button from '../../UIKit/Form/Button';
import { IMAGE_SUCCESS_CHECK } from '../../../../assets/Images';
import { handleApiSave } from '../../../../utilities/useApi';
import { partialUpdateBusiness } from '../../../../api';
import toaster from 'toasted-notes';
import { Types } from '../../../../config/actionTypes';
import { AppContext } from '../../../../context/providers';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceCongratulations = ({ isNew = false, isBoth = false, onPrev, onSave }) => {
  const [loading, setLoading] = useState(false);
  const { newBusiness, business } = useContext(AppContext);

  const handleStartMarketplace = () => {
    setLoading(true);
    const body = {
      businessId: isNew ? newBusiness.id : undefined,
      ...(isBoth ? { marketplace_setup: true, software_setup: true } : { marketplace_setup: true }),
    };
    handleApiSave(partialUpdateBusiness, null, body)
      .then(() => {
        toaster.notify(() => (
          <div className="alert alert-success mt-3">
            Your Marketplace{isBoth && ' & Software'} Has Been Started Successfully
          </div>
        ));
        localStorage.setItem(Types.BUSINESS_LOCATION, isNew ? newBusiness.id : business.id);
        localStorage.setItem(Types.PAYMENT_BACK_PAGE, isBoth ? 'setup.both' : 'setup.marketplace');
        if (onSave) onSave();
        window.location.href = '/';
        window.location.reload();
        // getBusiness()
        //   .then(() => {
        //     toaster.notify(() => (
        //       <div className="alert alert-success mt-3">
        //         Your Marketplace{isBoth && ' & Software'} Has Been Started Successfully
        //       </div>
        //     ));
        //     // setBusiness(data);
        //     // setSystem('marketplace');
        //     localStorage.setItem(Types.PAYMENT_BACK_PAGE, isBoth ? 'setup.both' : 'setup.marketplace');
        //     // onSave();
        //     window.location.reload();
        //   })
        //   .catch(() => setLoading(false));
        // .finally(() => setLoading(false));
      })
      .catch(() => setLoading(false));
  };
  return (
    <div>
      <div className="c-setup-system-congratulation">
        <img src={IMAGE_SUCCESS_CHECK} alt="marketplace" />
        <h2>CONGRATULATIONS!</h2>
        <p>You have been created your marketplace to make bigger your business!</p>
      </div>
      <div className="d-flex align-center mt-5">
        <Button type="button" color="light" onClick={onPrev} className="mr-auto">
          Previous Step
        </Button>
        <Button type="button" loading={loading} onClick={handleStartMarketplace} className="ml-auto">
          Start Marketplace
        </Button>
      </div>
    </div>
  );
};

export default SetupMarketplaceCongratulations;
