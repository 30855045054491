import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ModalFormSidebarContent, ModalFormSidebarHeader } from '../ModalForm/ModalFormSidebar';
import ModalFormSidebarFooter from '../ModalForm/ModalFormSidebarFooter';
import { ControlInput } from '../UIKit/Form/Input';
import Button from '../UIKit/Form/Button';
import { handleApiSave } from '../../../utilities/useApi';
import { checkValidSoldGiftCard } from '../../../api';
import { getPriceFormat } from '../../../utilities/Price';
import { PageLoading } from '../UIKit/Spinner';

const CheckoutGiftCard = ({ pays, price: _price, lines, onSuccess, onClose }) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [isUsed, setIsUsed] = useState(false);
  const [data, setData] = useState(null);
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setPrice(_price);
  }, [_price]);

  const { control, errors, handleSubmit, setError, clearErrors } = useForm();

  const handleSave = ({ code }) => {
    // onSuccess(data);
    setAlertMessage('');
    handleApiSave(checkValidSoldGiftCard, null, { code }, null, { loadingFn: setBtnLoading, setError })
      .then((res) => {
        const services = lines.map((l) => l.service);
        let isExist = false;
        res.services.forEach((s) => {
          if (services.indexOf(String(s)) > -1) isExist = true;
        });

        if (isExist) onSuccess(res);
        else
          setAlertMessage(
            'None of services in this appointment have been supported by this gift card code. Use another one',
          );
      })
      .catch(({ status }) => {
        if (status === 404) {
          setData('This code is invalid');
          setError('code', {
            type: 'manual',
            message: 'This code is invalid',
          });
        }
      });
  };
  const handleChangeText = (code) => {
    setAlertMessage('');
    if (code.length === 10) {
      clearErrors('code');
      const payItem = pays.find((p) => p?.code === code);
      setIsUsed(!!payItem);
      handleApiSave(checkValidSoldGiftCard, null, { code }, null, { loadingFn: setLoading, setError })
        .then((data) => {
          setData(data);
          if (+data?.current_balance < _price) {
            setPrice(+data?.current_balance);
          } else {
            setPrice(_price);
          }
        })
        .catch(({ status }) => {
          if (status === 404) {
            setData('This code is invalid');
            setError('code', {
              type: 'manual',
              message: 'This code is invalid',
            });
          }
        });
    }
  };

  return (
    <ModalFormSidebarContent>
      <ModalFormSidebarHeader title="Gift Card Payment" onClose={onClose} />
      <ModalFormSidebarContent>
        <div className="p-3">
          {alertMessage && <div className="alert alert-danger font-weight-bold">{alertMessage}</div>}
          <ControlInput
            control={control}
            name="code"
            rules={{ required: 'Code is required' }}
            defaultValue={''}
            label="Gift Card Code"
            placeholder="Enter gift card code"
            onChangeText={handleChangeText}
            error={isUsed}
            errorMessage={isUsed ? 'This gift card has been used' : errors?.code?.message}
            description={price < _price && 'Gift card balance is not enough to pay all price'}
          />
          {loading ? (
            <PageLoading />
          ) : (
            data && (
              <div className="position-relative">
                {loading && <PageLoading />}
                {typeof data === 'string' ? (
                  <h3 className="text-center text-danger my-2">{data}</h3>
                ) : (
                  <>
                    <div className="mb-2">
                      <span className="text-secondary font-weight-bold">Code: </span>
                      <span className="font-weight-bold">{data?.code}</span>
                    </div>
                    <div className="mb-2">
                      <span className="text-secondary font-weight-bold">Initial Balance: </span>
                      <span className="font-weight-bold">{getPriceFormat(data?.initial_balance)}</span>
                    </div>
                    <div className="mb-2">
                      <span className="text-secondary font-weight-bold">Current Balance: </span>
                      <span className="font-weight-bold">{getPriceFormat(data?.current_balance)}</span>
                    </div>
                    <div className="mb-2">
                      <span className="text-secondary font-weight-bold">Expiration Date: </span>
                      <span className="font-weight-bold">{data?.expiration_date}</span>
                    </div>
                    {data?.last_used_at && (
                      <div className="mb-2">
                        <span className="text-secondary font-weight-bold">Last used At: </span>
                        <span className="font-weight-bold">{data.last_used_at.replace(' UTC+0000', '')}</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            )
          )}
        </div>
      </ModalFormSidebarContent>
      <ModalFormSidebarFooter>
        <Button
          block
          disabled={!data || typeof data === 'string' || isUsed}
          loading={btnLoading}
          onClick={handleSubmit(handleSave)}
        >
          <span className="mr-3">
            {price < _price && (
              <span className="mr-1">
                <span className="text-decoration-through">{getPriceFormat(_price)}</span>
              </span>
            )}
            {getPriceFormat(price)}
          </span>
          PAY BY GIFT CARD
        </Button>
      </ModalFormSidebarFooter>
    </ModalFormSidebarContent>
  );
};

CheckoutGiftCard.propTypes = {
  pays: PropTypes.array,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lines: PropTypes.array,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default CheckoutGiftCard;
