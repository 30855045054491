import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, BusinessId, createURL } from '../config/apiConfig';

const { POST, GET } = ApiMethods;

/**
 * @description get subscription plan list
 */
export const getPlans = async ({ businessId: _businessId = null }) => {
  const businessId = _businessId || BusinessId;
  const response = await callApi({
    method: GET,
    url: businessId ? createURL(ApiRoutes.plan.listOrigin, { id: businessId }) : ApiRoutes.plan.list,
    locationBase: !businessId,
  });
  if (response.data) {
    const results = (response?.data?.results || [])
      .reduce((acc, i) => {
        let priority = 4;
        if (i?.limits?.staffs === 1) priority = 1;
        if (i?.limits?.staffs > 1) priority = 2;
        if (i?.limits?.staffs === 0) priority = 3;
        return [...acc, { ...i, priority }];
      }, [])
      .sort((a, b) => (a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : 0));
    return Promise.resolve({
      ...response.data,
      results,
    });
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param {{
 *   businessId: String,
 *   planId: String,
 * }}
 * @description get subscription plan
 */
export const getPlan = async ({ businessId: _businessId = null, planId }) => {
  const businessId = _businessId || BusinessId;
  const response = await callApi({
    method: GET,
    url: createURL(ApiRoutes.plan.get, { id: planId, businessId }),
    locationBase: !businessId,
  });
  if (response.data) {
    return response?.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get stripe link for checkout a plan for subscription
 */
export const postPlanTrial = async ({ businessId = null, ...params }) => {
  const _response = await callApi({
    method: POST,
    params,
    locationBase: false,
    url: createURL(ApiRoutes.businessSubscription.active_trial, { id: businessId || BusinessId }),
  });
  if (_response.data) {
    return _response.data;
  } else {
    return Promise.reject(_response);
  }
};

/**
 * @description get stripe link for checkout a plan for subscription
 */
export const postPlanCheckout = async ({ businessId = null, ...params }) => {
  const _response = await callApi({
    method: POST,
    params,
    locationBase: false,
    url: createURL(ApiRoutes.businessSubscription.checkout, { id: businessId || BusinessId }),
  });
  if (_response.data) {
    return _response.data;
  } else {
    return Promise.reject(_response);
  }
};

/**
 * @description change current subscription
 */
export const changeSubscription = async ({ businessId = null, ...params }) => {
  const _response = await callApi({
    method: POST,
    params,
    locationBase: false,
    url: createURL(ApiRoutes.businessSubscription.change_subscription, { id: businessId || BusinessId }),
  });
  if (_response.data) {
    return _response.data;
  } else {
    return Promise.reject(_response);
  }
};

/**
 * @description cancel current subscription
 */
export const cancelSubscription = async () => {
  const _response = await callApi({
    method: POST,
    params: {},
    locationBase: false,
    url: createURL(ApiRoutes.businessSubscription.cancel_subscription, { id: BusinessId }),
  });
  if (_response.data) {
    return _response.data;
  } else {
    return Promise.reject(_response);
  }
};
