import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import toaster from 'toasted-notes';
import { handleApiSave } from '../../../utilities/useApi';
import Button from '../UIKit/Form/Button';
import { authGoogle } from '../../../api';
import { IMAGE_GOOGLE_LOGO } from '../../../assets/Images';

export const GoogleIdentityLogin = () => {
  const [loading, setLoading] = useState(false);

  const onSuccess = ({ tokenId }) => {
    handleApiSave(authGoogle, undefined, { token: tokenId }, null, { loadingFn: setLoading })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {});
  };

  const onFailure = (response) => {
    if (response?.details) {
      toaster.notify(() => <div className="alert alert-danger">{response.details}</div>);
    }
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Login With Google"
      render={({ onClick, disabled }) => (
        <Button color="light" lg block onClick={onClick} disabled={disabled}>
          <span className="c-button-image">
            <img className="c-button-image__img" src={IMAGE_GOOGLE_LOGO} alt="Sign in with google" />
            <span className="c-button-image__name">Sign in with google</span>
          </span>
        </Button>
      )}
      disabled={loading}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      className="d-flex mx-auto"
    />
  );
};
