import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import toaster from 'toasted-notes';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import Modal from '../../UIKit/Modal';
import PromotionGiftCard from '../PromotionGiftCard';
import { handleApiDeleteById, handleApiSave, useApiById } from '../../../../utilities/useApi';
import { deleteSoldGiftCard, getGiftCard, getSoldGiftCard, partialUpdateSoldGiftCard } from '../../../../api';
import { PageLoading } from '../../UIKit/Spinner';
import Button from '../../UIKit/Form/Button';
import Dropdown from '../../UIKit/Dropdown';
import usePermission from '../../../../utilities/usePermission';

const PromotionSoldGiftCardItem = ({ item, giftCard }) => {
  const [data, loading] = useApiById(getSoldGiftCard, item.sold_item_id);
  return (
    <div className="p-2 position-relative">
      <PromotionGiftCard
        loading={loading}
        code={data?.code}
        subject={item.item_name}
        value={data?.initial_balance}
        price={item?.price}
        expireAfter={giftCard?.expire_after}
        expireDate={data?.expire_at}
        emailTemplate={false}
      />
    </div>
  );
};

PromotionSoldGiftCardItem.propTypes = {
  item: PropTypes.any,
  giftCard: PropTypes.any,
};

export const PromotionSoldGiftCardInvoiceModal = ({ data, onClose }) => {
  const cards = data.lines.filter((l) => l.item_type === 'gift_card');
  return (
    <Modal show auto onClose={onClose} saveBtn={false} title="Sold Gift Card">
      <div className="c-gift-card-list">
        {cards.map((card) => (
          <PromotionSoldGiftCardItem key={card.id} item={card} giftCard={data.giftCard} />
        ))}
      </div>
    </Modal>
  );
};

PromotionSoldGiftCardInvoiceModal.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func,
};

const PromotionSoldGiftCardModal = ({ onClose, onSave }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  // const [activeLoading, setActiveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const printRef = useRef(null);
  const { access: _access } = usePermission('soldgiftcard', 'crud', 'modify');
  const { access: _giftcard } = usePermission('giftcard', 'crud', 'view');
  const { access: _activate } = usePermission('soldgiftcard', 'activate', 'modify');
  const { access: _deactivate } = usePermission('soldgiftcard', 'deactivate', 'modify');
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getSoldGiftCard(id).then((_res) => {
        if (_giftcard) {
          getGiftCard(_res.gift_card).then((res) => {
            setData({
              ...res,
              ..._res,
            });
            setLoading(false);
          });
        } else {
          setData(_res);
          setLoading(false);
        }
      });
    }
  }, [id, _giftcard]);

  const handleDelete = () => {
    const options = {
      title: 'Confirm to delete sold gift card',
      message: 'Are you sure to delete this gift which have been sold?',
      loadingFn: setBtnLoading,
    };
    handleApiDeleteById(deleteSoldGiftCard, data.id, options).then(() => {
      onSave(+data.id);
      toaster.notify(() => <div className="alert alert-success">Sold Gift Card Deleted Successfully</div>);
    });
  };

  const handleChangeStatus = () => {
    handleApiSave(null, partialUpdateSoldGiftCard, !data.is_active, data.id, { loadingFn: setBtnLoading })
      .then((data) => {
        onSave(data.id, data);
        toaster.notify(() => (
          <div className="alert alert-success">
            Sold Gift Card {data?.is_active ? 'Active' : 'Inactive'} Successfully
          </div>
        ));
      })
      .catch(() => {});
  };

  const options = useMemo(() => {
    const results = [];
    if (_access) {
      results.unshift({
        type: 'node',
        node: 'Delete',
        id: 'deleteOpt',
        className: 'text-danger dropdown-link',
        onClick: handleDelete,
      });
    }
    if (data?.is_active && _deactivate) {
      results.unshift({
        type: 'node',
        id: 'toggleActiveOpt',
        node: 'Inactive',
        className: `text-warning dropdown-link`,
        onClick: handleChangeStatus,
      });
    }
    if (!data?.is_active && _activate) {
      results.unshift({
        type: 'node',
        id: 'toggleActiveOpt',
        node: 'Active',
        className: `text-success dropdown-link`,
        onClick: handleChangeStatus,
      });
    }
    if (printRef) {
      results.push({
        type: 'node',
        id: 'printOpt',
        node: printRef && (
          <>
            {/*<Button sm color="info" onClick={handlePrint}>Print Invoice</Button>*/}
            <ReactToPrint trigger={() => <span>Print</span>} content={() => printRef.current} pageStyle="a4" />
          </>
        ),
        className: 'dropdown-link',
      });
    }
    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printRef, data, _activate, _deactivate, _access]);
  return (
    <Modal
      show
      auto
      onClose={onClose}
      buttonComponent={
        data && (
          <>
            <Dropdown options={options} block positionMenu="top">
              <Button color="light" loading={btnLoading}>
                Options
              </Button>
            </Dropdown>
            {/*<Button color="danger" loading={delLoading} onClick={handleDelete}>Delete</Button>*/}
            {/*{data?.is_active ? (*/}
            {/*    <Button sm color="warning" loading={activeLoading} onClick={handleChangeStatus}>Inactive</Button>*/}
            {/*): (*/}
            {/*    <Button sm color="success" loading={activeLoading} onClick={handleChangeStatus}>Active</Button>*/}
            {/*)}*/}
          </>
        )
      }
      saveBtn={false}
      title="Sold Gift Card"
    >
      {loading ? (
        <PageLoading />
      ) : data ? (
        <div className="p-2 position-relative" ref={printRef}>
          <PromotionGiftCard
            code={data?.code}
            subject={data?.name}
            value={data?.initial_balance}
            price={data?.retail_price}
            expireAfter={data?.expire_after}
            expireDate={data?.expiration_date}
            emailTemplate={false}
          />
        </div>
      ) : null}
    </Modal>
  );
};

PromotionSoldGiftCardModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default PromotionSoldGiftCardModal;
