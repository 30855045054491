import React, { useState } from 'react';
import classnames from 'classnames';

const TWO_LETTERS = ['ch', 'sh', 'zh'];

// eslint-disable-next-line react/prop-types
const ImageName = ({ imageSrc = null, name = '', className }) => {
  const [errorImage, setErrorImage] = useState(false);
  let imageName = '';

  if (name && typeof name === 'string') {
    const firstTwo = name.substr(0, 2).toLowerCase();
    imageName = TWO_LETTERS.indexOf(firstTwo) > -1 ? firstTwo : name.substr(0, 1);
  }

  return !errorImage && imageSrc ? (
    <img
      src={imageSrc}
      onError={setErrorImage}
      alt={typeof name === 'string' ? name : 'Beautster'}
      className={className}
    />
  ) : (
    <div className={classnames('c-image-name', className)}>{imageName.toUpperCase()}</div>
  );
};

export default ImageName;
