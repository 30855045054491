import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SidebarLink from './SidebarLink';
import SidebarLogo from './SidebarLogo';
import { IMAGE_ANALYSIS, IMAGE_CROWN } from '../../../../assets/Images';
import { AppContext } from '../../../../context/providers';
import LinkListSoftware from './LinkListSoftware';
import LinkListMarketplace from './LinkListMarketplace';
import SwitchSidebar from './SwitchSidebar';
import usePermission from '../../../../utilities/usePermission';
import { IS_DEV } from '../../../../config/appConfig';

const Sidebar = ({ onClose, onSetup }) => {
  const { isMarketplace, isSoftware, business, user, userPermissions: p } = useContext(AppContext);
  const { isOwner } = usePermission();
  const onClickLinks = () => {
    if (window.innerWidth <= 768) onClose();
  };

  const checkKeyAccess = (key) =>
    (key || []).reduce((acc, i) => {
      let item = null;

      const [group, name, type] = (i || '').split('.');
      if (type && name && group) {
        if (type && name && group && p[group][name][type]) item = i;
      } else if (name && group && p[group] && p[group][name] && (p[group][name]?.view || p[group][name]?.modify)) {
        item = i;
      } else if (group && p[group] && p[group]?.crud && (p[group]?.crud?.view || p[group]?.crud?.modify)) {
        item = i;
      }
      return item ? [...acc, i] : acc;
    }, []);

  const checkAccessSubList = (item) =>
    (item.subLinks || []).reduce((_acc2, { key: _key, ..._item }) => {
      if (_key) {
        const res = checkKeyAccess(_key);
        if (res.length === 0) return _acc2;
      }
      return [..._acc2, _item];
    }, []);

  const checkIsNotDev = (list) =>
    (list || []).reduce((acc, i) => {
      const updateAcc = [...acc];
      if (!i.isDev || (i.isDev && IS_DEV)) {
        if ((i.subLinks || []).length > 0) {
          const subLinks = (i.subLinks || []).filter((i) => !i.isDev || (i.isDev && IS_DEV));
          updateAcc.push({ ...i, subLinks });
        } else {
          updateAcc.push(i);
        }
      }
      return updateAcc;
    }, []);
  const checkAccessList = (list) =>
    checkIsNotDev(list || []).reduce((_acc, { key, ...item }) => {
      if (key) {
        const res = checkKeyAccess(key);
        if (res.length > 0) {
          if ((item.subLinks || []).length > 0) {
            item.subLinks = checkAccessSubList(item);
          }
          return [..._acc, item];
        }
        return _acc;
      }
      if ((item.subLinks || []).length > 0) {
        item.subLinks = checkAccessSubList(item);
      }
      return [..._acc, item];
    }, []);

  const [marketplaceLinks, softwareLinks] = useMemo(() => {
    if (isOwner) {
      if (user.is_claimed) {
        return [
          checkIsNotDev(
            LinkListMarketplace.reduce((acc, i) => {
              if (i.claimed) {
                return [...acc, i.claimed];
              }
              return [...acc, i];
            }, []),
          ),
          checkIsNotDev(LinkListSoftware),
        ];
      }
      return [checkIsNotDev(LinkListMarketplace), checkIsNotDev(LinkListSoftware)];
    }
    if (p) {
      let marketplace = checkAccessList(LinkListMarketplace);
      if (user.is_claimed) {
        checkAccessList(
          LinkListMarketplace.reduce((acc, i) => {
            if (i.claimed) {
              return [...acc, i.claimed];
            }
            return [...acc, i];
          }, []),
        );
      }
      const software = checkAccessList(LinkListSoftware);
      return [marketplace, software];
    }
    return [[], []];
  }, [p, isOwner, user]);
  return (
    <div
      className={classnames('c-panel-sidebar', {
        'c-panel-sidebar--marketplace': isMarketplace,
      })}
    >
      <SidebarLogo />
      <SwitchSidebar className="c-panel-sidebar__switch" />
      <div className="c-panel-sidebar__list" onClick={onClickLinks}>
        {isMarketplace ? (
          <>
            <div className="c-panel-sidebar__list-introduce-head my-0">
              <img src={IMAGE_CROWN} alt="marketplace" className="c-panel-sidebar__list-introduce-img" />
              <span className="c-panel-sidebar__list-introduce-text">MarketPlace</span>
            </div>
            <div className="c-panel-sidebar__list-marketplace">
              {marketplaceLinks.map((item, key) => (
                <SidebarLink unreadCount={user?.unread_count} key={key} {...item} />
              ))}
            </div>
            {!business?.software_setup && (
              <div
                className="c-panel-sidebar__setup c-panel-sidebar__setup--software"
                onClick={() => onSetup('software')}
              >
                <img src={IMAGE_ANALYSIS} alt="software" className="c-panel-sidebar__setup-img" />
                <span className="c-panel-sidebar__setup-text">Setup Software</span>
              </div>
            )}
          </>
        ) : isSoftware ? (
          <>
            <div className="c-panel-sidebar__list-introduce-head my-0">
              <img src={IMAGE_ANALYSIS} alt="software" className="c-panel-sidebar__list-introduce-img" />
              <span className="c-panel-sidebar__list-introduce-text c-panel-sidebar__list-introduce-text--software">
                Software
              </span>
            </div>
            <div className="c-panel-sidebar__list-software">
              {softwareLinks.map((item, key) => (
                <SidebarLink key={key} {...item} />
              ))}
            </div>
            {!business?.marketplace_setup && (
              <div
                className="c-panel-sidebar__setup c-panel-sidebar__setup--marketplace"
                onClick={() => onSetup('marketplace')}
              >
                <img src={IMAGE_CROWN} alt="marketplace" className="c-panel-sidebar__setup-img" />
                <span className="c-panel-sidebar__setup-text">Setup MarketPlace</span>
              </div>
            )}
          </>
        ) : null}
        <div className="c-panel-sidebar__description">
          {business && business.marketplace_setup && !business?.is_published ? (
            <small className="text-danger">Your business in marketplace is down</small>
          ) : (
            <span>Beautster System v1.1.0</span>
          )}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  onSetup: PropTypes.func,
};

export default memo(Sidebar);
