import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckoutLineService from './CheckoutLine/CheckoutLineService';
import CheckoutLineGiftCard from './CheckoutLine/CheckoutLineGiftCard';
import { handleApi } from '../../../utilities/useApi';
import { getServiceList } from '../../../api';

const CheckoutLines = (props) => {
  const {
    pays,
    data,
    control,
    lines,
    errors,
    invoiceId,
    onChangePrice,
    onChangeStaff,
    onChangeQuantity,
    onChangeDiscount,
    onChangeDiscountAmount,
  } = props;

  const [services, setServices] = useState(null);
  useEffect(() => {
    if (services === null && lines.length > 0) {
      handleApi(getServiceList, {}, { defaultData: null, infinitePage: true }).then((d) => setServices(d.results));
    }
  }, []);
  return (
    <div className="c-checkout-lines">
      {lines.map((line, ind) =>
        line.type === 'service' ? (
          <CheckoutLineService
            key={ind}
            pays={pays}
            errors={errors?.lines && errors?.lines[ind]}
            control={control}
            line={line}
            index={ind}
            services={services}
            invoiceId={invoiceId}
            onChangePrice={onChangePrice}
            onChangeDiscount={onChangeDiscount}
            onChangeDiscountAmount={onChangeDiscountAmount}
          />
        ) : (
          <CheckoutLineGiftCard
            key={ind}
            // errors={errors?.lines && errors?.lines[ind]}
            errors={errors}
            control={control}
            line={line}
            data={data}
            index={ind}
            invoiceId={invoiceId}
            onChangePrice={onChangePrice}
            onChangeQuantity={onChangeQuantity}
            onChangeStaff={onChangeStaff}
          />
        ),
      )}
    </div>
  );
};

CheckoutLines.propTypes = {
  data: PropTypes.any,
  lines: PropTypes.array,
  pays: PropTypes.array,
  errors: PropTypes.any,
  control: PropTypes.any,
  line: PropTypes.any,
  index: PropTypes.any,
  invoiceId: PropTypes.any,
  onChangePrice: PropTypes.func,
  onChangeStaff: PropTypes.func,
  onChangeQuantity: PropTypes.func,
  onChangeDiscount: PropTypes.func,
  onChangeDiscountAmount: PropTypes.func,
};

export default CheckoutLines;
