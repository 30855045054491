import React, { useContext, useEffect, useState } from 'react';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { getBusiness, getBusinessById, partialUpdateBusiness } from '../../../../api';
import { useForm } from 'react-hook-form';
import toaster from 'toasted-notes';
import SectionHead from '../../UIKit/SectionTitle';
import { ControlInput } from '../../UIKit/Form/Input';
import Button from '../../UIKit/Form/Button';
import { PageLoading } from '../../UIKit/Spinner';
import { AppContext } from '../../../../context/providers';
import BusinessLocationForm from '../../Business/BusinessLocationForm';

// eslint-disable-next-line react/prop-types
const SetupMarketplaceLocationForm = ({ isNew = false, isSoftware = false, onNext, onPrev }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  // const [data, loading] = useApi(getBusiness);

  const { setBusiness, onNewBusiness, newBusiness } = useContext(AppContext);

  const { control, errors, watch, handleSubmit, setError, setValue } = useForm();

  useEffect(() => {
    if (isNew) {
      if (newBusiness) {
        handleApi(getBusinessById, newBusiness.id, { list: false, loadingFn: setLoading }).then(setData);
      } else {
        setLoading(false);
      }
    } else {
      handleApi(getBusiness, {}, { loadingFn: setLoading }).then(setData);
    }
  }, [isNew]);

  const handleSave = ({ zip_code, ...body }) => {
    handleApiSave(
      partialUpdateBusiness,
      null,
      { ...body, zip_code: zip_code.replaceAll(' ', ''), businessId: isNew ? newBusiness.id : undefined },
      null,
      { loadingFn: setSaveLoading, setError },
    )
      .then((res) => {
        toaster.notify(() => (
          <div className="alert alert-success mt-3">Business Location Info Changed Successfully</div>
        ));
        if (isNew) onNewBusiness(res);
        else setBusiness(res);
        if (onNext) onNext();
      })
      .catch(() => {});
  };

  const handleChangeProvince = (e) => {
    if (e.id !== data?.province) {
      setValue('tax_rate', e.tax_rate);
    } else {
      setValue('tax_rate', data?.tax_rate);
    }
  };

  return loading ? (
    <PageLoading />
  ) : (
    <form onSubmit={handleSubmit(handleSave)} className="px-3">
      <SectionHead title="Business Location Info" />
      <BusinessLocationForm
        data={data}
        control={control}
        errors={errors}
        watch={watch()}
        onChangeProvince={handleChangeProvince}
      />
      <ControlInput
        control={control}
        name="tax_rate"
        rules={{
          min: {
            value: 0.0000001,
            message: 'Tax should be more than zero',
          },
          required: 'Tax is required',
        }}
        defaultValue={data?.tax_rate || null}
        label="Tax Rate"
        placeholder="eg. 5%"
        unit="%"
        errorMessage={errors?.tax_rate?.message}
      />
      <div className="d-flex align-center mt-5">
        <Button type="button" color="light" onClick={onPrev} className="mr-auto">
          Previous Step
        </Button>
        <Button color={isSoftware ? 'primary' : undefined} loading={saveLoading} className="ml-auto">
          Next Step
        </Button>
      </div>
    </form>
  );
};

export default SetupMarketplaceLocationForm;
