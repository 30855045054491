import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../UIKit/Modal';
import ClientsImportFile from './ClientsImportFile';
import ClientsImportUpload from './ClientsImportUpload';
import Checkbox from '../../UIKit/Form/Checkbox';
// import { TourGuide } from '../../UIKit/TourContent';
import { clientImportSteps } from '../../../../config/tourSteps';

const sources = [
  {
    id: 'beautster',
    label: 'Beautster (Default)',
  },
  {
    id: 'fresha',
    label: 'Fresha',
  },
  {
    id: 'vagaro',
    label: 'Vagaro',
  },
];

const ClientsImportModal = ({ onClose, onImport }) => {
  const [source, setSource] = useState('beautster');
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTourOpen(true);
  //   }, 500);
  // }, []);

  return (
    <>
      <Modal show title="Import Clients To Beautster" guide={clientImportSteps} saveBtn={false} onClose={onClose}>
        <div className="c-client__import-content">
          <p>Follow the simple instructions below to clients to your Beautster partner account</p>

          <ul className="c-client__import-steps">
            <li data-step={1}>Download Your import spreadsheet using the button below (Download import file)</li>
            <li data-step={2}>Copy and paste your client details into the spreadsheet</li>
            <li data-step={3}>
              Select source of CSV file (Vagaro and Fresha formats are also available - default is Beautster)
            </li>
            <li data-step={4}>Upload spreadsheet using the button below (Upload import file)</li>
          </ul>

          <div data-tour="templates">
            <h5>File template you want to import from:</h5>
            {sources.map((sr) => (
              <Checkbox
                key={sr.id}
                name="source"
                type="radio"
                id={sr.id}
                label={sr.label}
                checked={sr.id === source}
                onChange={(e) => (e ? setSource(sr.id) : null)}
              />
            ))}
          </div>
        </div>
        {/*<ul className="c-client__import-sources">*/}
        {/*  {sources.map((sr) => (*/}
        {/*    <li*/}
        {/*      key={sr.id}*/}
        {/*      className={classNames(`c-client__import-source c-client__import-source--${sr.id}`, {*/}
        {/*        'c-client__import-source--active': sr.id === source,*/}
        {/*      })}*/}
        {/*      onClick={() => setSource(sr.id)}*/}
        {/*    >*/}
        {/*      {sr.label}*/}
        {/*    </li>*/}
        {/*  ))}*/}
        {/*</ul>*/}
        <div className="c-client__import-buttons">
          {source === 'beautster' && <ClientsImportFile />}
          <ClientsImportUpload
            btnText={source !== 'beautster' ? `Upload import ${source} file` : undefined}
            source={source}
            onDone={onImport}
          />
        </div>
      </Modal>
      {/*<TourGuide*/}
      {/*  disableInteraction={tourStep === 0}*/}
      {/*  steps={clientImportSteps}*/}
      {/*  getCurrentStep={setTourStep}*/}
      {/*  isOpen={isTourOpen}*/}
      {/*  onRequestClose={() => setIsTourOpen(false)}*/}
      {/*/>*/}
    </>
  );
};

ClientsImportModal.propTypes = {
  onClose: PropTypes.func,
  onImport: PropTypes.func,
};

export default ClientsImportModal;
