import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '@material-ui/icons';
import StylistProfileForm from '../../UserProfile/StylistProfileForm';

const SetupStylistProfile = ({ onSave, onBack }) => (
  <div className="c-panel-setup__stylist-container">
    <div className="c-panel-setup__head-back">
      <div className="c-panel-setup__onboard-video-prev" onClick={onBack}>
        <ArrowBack />
      </div>
      <h3>Stylist Profile</h3>
    </div>
    <StylistProfileForm onSave={onSave} />
  </div>
);

SetupStylistProfile.propTypes = {
  onSave: PropTypes.func,
  onBack: PropTypes.func,
};

export default SetupStylistProfile;
