import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Add, ArrowForward, School, VerifiedUser, Work } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import Modal from '../UIKit/Modal';
import { handleApi, handleApiDeleteById, handleApiSave } from '../../../utilities/useApi';
import { deleteStylistProfile, getStylistProfile, saveStylistProfile } from '../../../api';
import { IMAGE_DEFAULT_USER } from '../../../assets/Images';
import { PageLoading } from '../UIKit/Spinner';
import Button from '../UIKit/Form/Button';
import ImageSelector from '../UIKit/ImageSelector';
import { ControlInput } from '../UIKit/Form/Input';
import LocationCitySelector from '../Location/LocationCitySelector';
import ExperienceFormModal from './Experience/ExperienceFormModal';
import ExperienceItem from './Experience/ExperienceItem';
import CertificateFormModal from './Certificate/CertificateFormModal';
import EducationModal from './Education/EducationModal';
import EducationItem from './Education/EducationItem';
import CertificateItem from './Certificate/CertificateItem';
import { getDateInfo } from '../../../utilities/DateHelpers';
import EducationFormModal from './Education/EducationFormModal';
import { AppContext } from '../../../context/providers';
import { ControlSelect } from '../UIKit/Form/Select';

export const getTextFormatDate = (date) => `${`0${date.getFullYear()}`.slice(-4)} ${getDateInfo(date).monthBriefName}`;
export const getStringMonthFormat = (date) =>
  date ? `${`0${date.getFullYear()}`.slice(-4)}-${`0${date.getMonth() + 1}`.slice(-2)}` : null;

const CUSTOM_BUSINESS = 'others';

const StylistProfileModal = ({ onClose }) => {
  const { locations, businessLoading } = useContext(AppContext);

  const { setStylist } = useContext(AppContext);
  // null, experience, certificate, education
  const [showModal, setShowModal] = useState(null);

  // null, experience, certificate, education
  const [showFormModal, setShowFormModal] = useState(null);

  // null, data item, if new it should set null
  const [itemModal, setItemModal] = useState(null);

  // Just for create stylist profile { experiences: [], educations: [], certificates: [] }
  const [formData, setFormData] = useState({});

  const [business, setBusiness] = useState(null);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { handleSubmit, errors, control, setError, setValue } = useForm();

  const businessesOpt = useMemo(
    () => [
      ...(locations || []).map((i) => ({ value: i.id, label: i.title })),
      { value: CUSTOM_BUSINESS, label: 'Others' },
    ],
    [locations],
  );

  useEffect(() => {
    if (!businessLoading && locations.length === 0) {
      setBusiness(CUSTOM_BUSINESS);
    }
  }, [locations, businessLoading, business]);
  useEffect(() => {
    if (businessesOpt.length > 0 && data && data.headline_title && !data.headline_business) {
      setTimeout(() => setValue('headline_business', CUSTOM_BUSINESS), 500);
      setBusiness(CUSTOM_BUSINESS);
    }
  }, [data, businessesOpt]);
  useEffect(() => {
    handleApi(getStylistProfile)
      .then((data) => {
        setData(data);

        setFormData({
          educations: data.educations || [],
          experiences: data.experiences || [],
          certificates: data.certificates || [],
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleCloseFormModal = () => {
    setShowFormModal(null);
    setItemModal(null);
  };

  const handleOpenFormModal = (type, item = null) => {
    setShowFormModal(type);
    setItemModal(item);
  };

  const handleSaveModal = (type, body) => {
    setShowFormModal(null);
    setItemModal(null);
    setFormData((prev) => {
      const data = [...(prev[type] || [])];
      const prevIndex = data.findIndex((i) => i.id === body.id);
      if (prevIndex > -1) {
        data[prevIndex] = body;
      } else {
        data.push(body);
      }
      return { ...prev, [type]: data };
    });
  };

  const handleDeleteItem = (type, itemId) => {
    const label = {
      experiences: 'experience',
      educations: 'education',
      certificates: 'certificate',
    };
    confirmAlert({
      title: `Confirm to delete ${label[type]}`,
      message: `Are you sure to delete this ${label[type]}`,
      buttons: [
        {
          label: 'Yes',
          className: 'bg-danger',
          onClick: () => {
            setFormData((prev) => {
              const data = (prev[type] || []).filter((i) => i.id !== itemId);
              return { ...prev, [type]: data };
            });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const onSubmit = (_data) => {
    const avatar =
      _data.avatar === null ? null : !!_data.avatar && typeof _data.avatar !== 'string' ? _data.avatar : undefined;
    const features = Object.keys(formData).reduce((acc, key) => {
      const data = formData[key];
      const res = data.map((i) => {
        const item = { ...i };
        if ((i.id || '').includes('create')) {
          delete item.id;
        }
        if (item.start_date) {
          item.start_date = getStringMonthFormat(new Date(item.start_date));
        }
        if (item.end_date) {
          item.end_date = getStringMonthFormat(new Date(item.end_date));
        }
        if (item.expiration_date) {
          item.expiration_date = getStringMonthFormat(new Date(item.expiration_date));
        }
        if (item.issued_date) {
          item.issued_date = getStringMonthFormat(new Date(item.issued_date));
        }
        return item;
      });
      return { ...acc, [key]: res };
    }, {});
    const body = {
      ..._data,
      avatar,
      headline_title:
        business !== CUSTOM_BUSINESS
          ? (locations || []).find((i) => i.id === business)?.title || null
          : _data.headline_title,
      headline_business: business === CUSTOM_BUSINESS ? null : business,
      isEdit: !!data,
      ...features,
    };
    setSaveLoading(true);
    handleApiSave(saveStylistProfile, undefined, body, undefined, { setError })
      .then((res) => {
        onClose();
        setStylist(res);
        setSaveLoading(false);
      })
      .catch(() => setSaveLoading(false));
  };

  const onDelete = () => {
    const options = {
      title: 'Confirm to delete stylist profile',
      message: 'Are you sure to delete your stylist profile.',
      loadingFn: setDelLoading,
    };
    handleApiDeleteById(deleteStylistProfile, false, options).then((isSuccess) => {
      if (isSuccess) {
        setStylist(null);
        onClose();
      }
    });
  };

  return (
    <>
      <Modal
        show
        auto
        sticky
        title="Stylist Profile"
        contentClass="c-user-stylist-profile__modal"
        bodyClass="c-user-stylist-profile__modal-body"
        buttonComponent={
          data && (
            <Button color="danger" id="delete-btn" loading={delLoading} onClick={onDelete}>
              Delete
            </Button>
          )
        }
        onSave={handleSubmit(onSubmit)}
        saveLoading={saveLoading}
        onClose={() => onClose()}
      >
        {loading ? (
          <PageLoading type="box" />
        ) : (
          <form className="c-user-stylist-profile__container" onSubmit={handleSubmit(onSubmit)}>
            <div className="c-user-stylist-profile__section">
              <Controller
                control={control}
                name="avatar"
                defaultValue={data ? data?.avatar : ''}
                render={({ onChange, value }) => (
                  <ImageSelector
                    id="image"
                    defaultImage={IMAGE_DEFAULT_USER}
                    className="c-settings__image-uploader c-settings__image-uploader--lg"
                    description="Your image profile has an important impact on the clients"
                    onChange={onChange}
                    onDelete={onChange}
                    value={value}
                  />
                )}
              />
              <section className="c-user-stylist-profile__content">
                <ControlInput
                  control={control}
                  name="first_name"
                  defaultValue={!!data?.first_name ? data.first_name : ''}
                  label="First Name"
                  placeholder="First Name"
                  rules={{ required: 'First Name is required' }}
                  errorMessage={errors?.first_name?.message}
                />
                <ControlInput
                  control={control}
                  name="last_name"
                  defaultValue={!!data?.last_name ? data.last_name : ''}
                  label="Last Name"
                  placeholder="Last Name"
                  rules={{ required: 'Last Name is required' }}
                  errorMessage={errors?.last_name?.message}
                />
                <LocationCitySelector hasCountry={false} data={data} errors={errors} control={control} />
                <ControlInput
                  control={control}
                  name="job_title"
                  defaultValue={!!data?.job_title ? data.job_title : ''}
                  label="Job Title"
                  placeholder="Job Title"
                  rules={{ required: 'Job Title is required' }}
                  errorMessage={errors?.job_title?.message}
                />
                {businessesOpt.length > 0 && (
                  <ControlSelect
                    control={control}
                    name="headline_business"
                    options={businessesOpt}
                    onChangeValue={setBusiness}
                    defaultValue={!!data?.headline_business ? data.headline_business : ''}
                    label="Business"
                    placeholder="Business"
                    rules={{ required: 'Business is required' }}
                    errorMessage={errors?.headline_business?.message}
                  />
                )}
                {business === CUSTOM_BUSINESS && (
                  <ControlInput
                    control={control}
                    name="headline_title"
                    defaultValue={!!data?.headline_title ? data.headline_title : ''}
                    label="Business Name"
                    placeholder="Business Name"
                    rules={{ required: 'Business Name is required' }}
                    errorMessage={errors?.headline_title?.message}
                  />
                )}
              </section>

              <ControlInput
                control={control}
                name="professional_summary"
                formClassName="mt-3"
                className="c-user-stylist-profile__description"
                defaultValue={!!data?.professional_summary ? data.professional_summary : ''}
                type="textarea"
                rows={6}
                label="Professional Summary"
                errorMessage={errors?.professional_summary?.message}
              />
            </div>
            <div className="c-user-stylist-profile__section">
              <h3 className="c-user-stylist-profile__content-title">
                <span>Education</span>
              </h3>
              <section className="c-user-stylist-profile__content">
                {(formData?.educations || []).length > 0 ? (
                  <ul className="c-user-stylist-profile__features">
                    {formData?.educations
                      .filter((i, ind) => ind < 2)
                      .map((edu) => (
                        <EducationItem
                          key={edu.id}
                          item={edu}
                          onDelete={() => handleDeleteItem('educations', edu.id)}
                          onEdit={() => handleOpenFormModal('educations', edu)}
                        />
                      ))}
                  </ul>
                ) : (
                  <div className="c-user-stylist-profile__empty">
                    <School />
                    <h4>No education added</h4>
                  </div>
                )}
                {(formData?.educations || []).length > 2 && (
                  <div className="c-user-stylist-profile__more" onClick={() => setShowModal('educations')}>
                    More
                    <ArrowForward />
                  </div>
                )}
              </section>
              <div className="c-user-stylist-profile__add" onClick={() => handleOpenFormModal('educations')}>
                <Add />
                <span className="c-user-stylist-profile__add-label">Add Education</span>
              </div>

              <h3 className="c-user-stylist-profile__content-title">
                <span>Experience</span>
              </h3>
              <section className="c-user-stylist-profile__content">
                {(formData?.experiences || []).length > 0 ? (
                  <ul className="c-user-stylist-profile__features">
                    {formData?.experiences
                      .filter((i, ind) => ind < 2)
                      .map((exp) => (
                        <ExperienceItem
                          key={exp.id}
                          item={exp}
                          onDelete={() => handleDeleteItem('experiences', exp.id)}
                          onEdit={() => handleOpenFormModal('experiences', exp)}
                        />
                      ))}
                  </ul>
                ) : (
                  <div className="c-user-stylist-profile__empty">
                    <Work />
                    <h4>No experience added</h4>
                  </div>
                )}
                {(formData?.experiences || []).length > 2 && (
                  <div className="c-user-stylist-profile__more" onClick={() => setShowModal('experiences')}>
                    More
                    <ArrowForward />
                  </div>
                )}
              </section>
              <div className="c-user-stylist-profile__add" onClick={() => handleOpenFormModal('experiences')}>
                <Add />
                <span className="c-user-stylist-profile__add-label">Add Experience</span>
              </div>

              <h3 className="c-user-stylist-profile__content-title">
                <span>Certificates</span>
              </h3>
              <section className="c-user-stylist-profile__content">
                {(formData?.certificates || []).length > 0 ? (
                  <ul className="c-user-stylist-profile__features">
                    {formData?.certificates
                      .filter((i, ind) => ind < 2)
                      .map((crt) => (
                        <CertificateItem
                          key={crt.id}
                          item={crt}
                          onDelete={() => handleDeleteItem('certificates', crt.id)}
                          onEdit={() => handleOpenFormModal('certificates', crt)}
                        />
                      ))}
                  </ul>
                ) : (
                  <div className="c-user-stylist-profile__empty">
                    <VerifiedUser />
                    <h4>No certificate added</h4>
                  </div>
                )}
                {(formData?.certificates || []).length > 2 && (
                  <div className="c-user-stylist-profile__more" onClick={() => setShowModal('certificates')}>
                    More
                    <ArrowForward />
                  </div>
                )}
              </section>
              <div className="c-user-stylist-profile__add" onClick={() => handleOpenFormModal('certificates')}>
                <Add />
                <span className="c-user-stylist-profile__add-label">Add Certificate</span>
              </div>
            </div>
          </form>
        )}
      </Modal>
      {showFormModal === 'experiences' && (
        <ExperienceFormModal
          shouldSave
          data={itemModal}
          onClose={handleCloseFormModal}
          onSave={(body) => handleSaveModal('experiences', body)}
        />
      )}
      {showFormModal === 'educations' && (
        <EducationFormModal
          shouldSave
          data={itemModal}
          onClose={handleCloseFormModal}
          onSave={(body) => handleSaveModal('educations', body)}
        />
      )}
      {showFormModal === 'certificates' && (
        <CertificateFormModal
          shouldSave
          data={itemModal}
          onClose={handleCloseFormModal}
          onSave={(body) => handleSaveModal('certificates', body)}
        />
      )}
      {showModal === 'educations' && <EducationModal shouldSave onClose={() => setShowModal(null)} />}
    </>
  );
};

StylistProfileModal.propTypes = {
  onClose: PropTypes.func,
};

export default StylistProfileModal;
