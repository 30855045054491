import React from 'react';
import classnames from 'classnames';

// eslint-disable-next-line react/prop-types
export const ModalFormContent = ({ children }) => <div className="c-modal-form-content">{children}</div>;

// eslint-disable-next-line react/prop-types
const ModalForm = ({ children, client }) => (
  <div className={classnames('c-modal-form', { 'c-modal-form--selected-client': !!client })}>{children}</div>
);

export default ModalForm;
