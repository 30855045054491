/**
 *
 * @param business {{
 *     id,
 *     slug,
 *     access_to,
 *     software_setup,
 *     marketplace_setup,
 *     connected_to_stripe,
 *     title,
 *     bio,
 *     profile,
 *     trial_is_used,
 *     booking_mode,
 *     cancellation_period,
 *     is_independent,
 *     is_published,
 *     refund_policy,
 *     plan,
 *     stripe_publishable_key,
 *     stripe_secret_key,
 *     subscription_status,
 *     tax_rate,
 *     trialing,
 *     owner,
 *     has_pay_at_salon,
 *     has_online_pay,
 *     trial_is_used,
 *     trial_end_at,
 *     subscription_status,
 *     subscribed,
 *     subscription_end_at,
 *     instagram_account,
 *     facebook_account,
 *     twitter_account,
 *     yelp_account,
 *     website_address,
 *     transfers_balance,
 *     transfers_total,
 *     instagram_access_token,
 *     instagram_user_id,
 *     current_staff,
 * }}
 * @constructor
 */

import UserObject from './UserObject';

function BusinessObject(business) {
  this.id = business.id;
  this.title = business.title;
  this.slug = business.slug || '';
  this.connected_to_stripe = business.connected_to_stripe;
  // this.access_to = business.access_to;
  this.software_setup = business.software_setup;
  this.marketplace_setup = business.marketplace_setup;
  this.is_published = business.is_published;
  this.booking_mode = business.booking_mode;
  this.bio = business.bio;
  this.transfers_balance = business.transfers_balance;
  this.transfers_total = business.transfers_total;
  this.plan = business.plan;
  this.profile = business.profile ? new UserObject(business.profile) : undefined;
  this.is_independent = business.is_independent;
  this.refund_policy = business.refund_policy;
  this.cancellation_period = business.cancellation_period;
  this.tax_rate = business.tax_rate;
  this.trial_end_at = business?.profile?.trial_end_at || business.trial_end_at;
  this.trial_is_used = business?.profile?.trial_is_used || business.trial_is_used;
  this.is_trial = business?.profile?.trialing || business.trialing;
  this.trialing = business?.profile?.trialing || business.trialing;
  this.subscription_status = business.subscription_status;
  this.subscribed = business.subscribed;
  this.subscription_end_at = business.subscription_end_at;

  this.instagram_account = business.instagram_account;
  this.facebook_account = business.facebook_account;
  this.twitter_account = business.twitter_account;
  this.yelp_account = business.yelp_account;
  this.website_address = business.website_address;

  this.hasPayAtSalon = business.has_pay_at_salon;
  this.has_pay_at_salon = business.has_pay_at_salon;
  this.hasOnlinePay = business.has_online_pay;
  this.has_online_pay = business.has_online_pay;

  this.owner = business.owner;
  this.avatar = business.avatar;
  this.phone_number = business.phone_number;
  this.province = business.province;
  this.country = business.country;
  this.city = business.city;
  this.timezone = business.timezone;
  this.address = business.address;
  this.bio = business.bio;
  this.longitude = business.longitude;
  this.latitude = business.latitude;
  this.zip_code = business.zip_code;
  this.instagramToken = business?.instagram_access_token;
  this.instagramUserId = business?.instagram_user_id;
  this.current_staff = business?.current_staff;
}

export default BusinessObject;
