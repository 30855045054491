import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Close } from '@material-ui/icons';
import { getPriceFormat } from '../../../utilities/Price';
import { InvoicePaymentTypeChoice, TipUnitTypeChoice } from '../../../utilities/constants/choices';

const CheckoutSummary = ({ invoiceId, total, pays, tips, onRemove }) => {
  const paysList = useMemo(
    () =>
      pays.reduce((acc, i) => {
        const existsInd = acc.findIndex((j) => j.key === i.key && i.paid === j.paid);
        if (existsInd > -1) {
          const item = acc[existsInd];
          acc.splice(existsInd, 1);
          return [
            ...acc,
            {
              ...i,
              price: +item.price + +i.price,
            },
          ];
        }
        return [...acc, i];
      }, []),
    [pays],
  );

  return (
    <div className="c-checkout-summary">
      <div className="c-checkout-summary__item">
        <span className="c-checkout-summary__item-key">SubTotal</span>
        <span className="c-checkout-summary__item-value">{getPriceFormat(total.line)}</span>
      </div>
      {tips.map(({ staff_name, amount, type, id }, key) => (
        <div className="c-checkout-summary__item c-checkout-summary__item--tip" key={key}>
          <span className="c-checkout-summary__item-key">{staff_name}</span>
          <span className="c-checkout-summary__item-info">
            <span className="c-checkout-summary__item-value">{getPriceFormat(+amount, TipUnitTypeChoice[type])}</span>
            {!invoiceId && (
              <span className="c-checkout-summary__item-remove" onClick={() => onRemove('tip', id)}>
                <Close />
              </span>
            )}
          </span>
        </div>
      ))}
      {paysList.map(({ key, price, paid, code, current_balance }) => (
        <div className={classnames('c-checkout-summary__item', { 'c-checkout-summary__item--paid': paid })} key={key}>
          <span className="c-checkout-summary__item-key">
            {InvoicePaymentTypeChoice[key]}
            {key === 'gift_card' && !!code && (
              <span className="c-checkout-summary__item-options">
                <span className="c-checkout-summary__item-code">Code: {code}</span>
                {current_balance && (
                  <span className="c-checkout-summary__item-balance">Balance: {getPriceFormat(current_balance)}</span>
                )}
              </span>
            )}
          </span>
          <span className="c-checkout-summary__item-info">
            <span className="c-checkout-summary__item-value">{getPriceFormat(+price)}</span>
            {!paid && (
              <span className="c-checkout-summary__item-remove" onClick={() => onRemove('pay', key)}>
                <Close />
              </span>
            )}
          </span>
        </div>
      ))}
      <div className="c-checkout-summary__item">
        <span className="c-checkout-summary__item-key">Tax</span>
        <span className="c-checkout-summary__item-value">
          {getPriceFormat(total.tax.amount)} ({getPriceFormat(total.tax.rate, '%')})
        </span>
      </div>
      <div className="c-checkout-summary__item font-weight-bold">
        <span className="c-checkout-summary__item-key">Total</span>
        <span className="c-checkout-summary__item-value">{getPriceFormat(total.sum)}</span>
      </div>
      {invoiceId && (
        <div className="c-checkout-summary__item c-checkout-summary__item--balance">
          <span className="c-checkout-summary__item-key">Balance</span>
          <span className="c-checkout-summary__item-value">
            {getPriceFormat(
              pays.reduce((a, i) => +a + +i.price, 0),
              // +
              // tips.reduce((a, i) => +a + +i.amount , 0)
            )}
          </span>
        </div>
      )}
    </div>
  );
};

CheckoutSummary.propTypes = {
  invoiceId: PropTypes.any,
  total: PropTypes.any,
  pays: PropTypes.array,
  tips: PropTypes.array,
  onRemove: PropTypes.func,
};

export default CheckoutSummary;
