import React, { useContext, useState, useMemo } from 'react';
import { CheckCircle, Error } from '@material-ui/icons';
import toaster from 'toasted-notes';
import Button from '../UIKit/Form/Button';
import { handleApiSave } from '../../../utilities/useApi';
import {
  publishDeal,
  unPublishDeal,
  publishLmo,
  unPublishLmo,
  scheduleCampaign,
  updatePartialDiscount,
  unPublishDiscount,
  publishDiscount,
} from '../../../api';
import { AppContext } from '../../../context/providers';
import PropTypes from 'prop-types';

const ReleasePromotionButton = ({ item, access = null, type = 'deal', isCampaign = false, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { isSoftware, business } = useContext(AppContext);
  const { id, campaign, is_published, expired, isBooked, booked_count, usage_limit } = item;

  const handlePublish = () => {
    let fn = publishDeal;
    if (expired || isBooked) return;
    // if (type === 'discount') {
    //     fn = updatePartialDiscount
    // } else
    if (is_published) {
      fn = type === 'discount' ? unPublishDiscount : type === 'lmo' ? unPublishLmo : unPublishDeal;
      // fn = type === 'lmo' ? unPublishLmo : unPublishDeal;
    } else {
      fn = type === 'discount' ? publishDiscount : type === 'lmo' ? publishLmo : publishDeal;
      // fn = type === 'lmo' ? publishLmo : publishDeal;
    }
    if (type === 'discount') {
      handleApiSave(null, updatePartialDiscount, { is_published: !is_published }, id, { loadingFn: setLoading })
        .then(({ is_published }) => {
          toaster.notify(() => (
            <div className="alert alert-success">
              {is_published
                ? `Your Discount has been published successfully`
                : `Your Discount has been unpublished successfully`}
            </div>
          ));
          onSubmit(id, is_published);
        })
        .catch(() => {});
    } else {
      handleApiSave(fn, null, id, null, { loadingFn: setLoading })
        .then(({ is_published }) => {
          const serviceText = type === 'discount' ? 'Discount' : type === 'lmo' ? 'Lmo' : 'Deal';
          toaster.notify(() => (
            <div className="alert alert-success">
              {is_published
                ? `Your ${serviceText} has been published successfully`
                : `Your ${serviceText} has been unpublished successfully`}
            </div>
          ));
          onSubmit(id, is_published);
        })
        .catch(() => {});
    }
  };

  const handleSend = () => {
    handleApiSave(scheduleCampaign, null, { id, type: `${type}s` }, null, { loadingFn: setLoading })
      .then(() => {
        toaster.notify(() => <div className="alert alert-success">Campaign has been sent successfully</div>);
        onSubmit(id, true, 'campaign');
      })
      .catch(() => {});
  };

  const disableBtn = useMemo(
    () =>
      expired ||
      (isBooked && type !== 'discount') ||
      (!business?.connected_to_stripe && type === 'lmo') ||
      (type === 'deal' && booked_count === usage_limit) ||
      (access && typeof access.unpublish !== 'undefined' && typeof access.publish !== 'undefined'
        ? is_published
          ? !access.unpublish
          : !access.publish
        : false),
    [access, type, item, business],
  );

  if (isSoftware || isCampaign) {
    if (campaign) {
      const isSent = campaign?.status === 'sent';
      return isSent ? (
        <div className="font-weight-bold text-success">
          {/*<small className="font-weight-bold text-success">Campaign has been sent</small>*/}
          <CheckCircle className="font-size-20" />
          <small className="d-block text-center">Has Been Sent</small>
        </div>
      ) : (
        <Button
          sm
          disabled={expired || !access?.send_campaign}
          loading={loading}
          className="m-auto"
          onClick={handleSend}
        >
          {isSent ? 'Sent' : 'Send'}
        </Button>
      );
    }
    return (
      <div className="font-weight-bold text-warning">
        <Error className="font-size-20" />
        <small className="d-block text-center">No Campaign Exists</small>
      </div>
    );
  }

  return (
    <Button
      sm
      disabled={disableBtn}
      loading={loading}
      className="m-auto"
      color={is_published ? 'warning' : 'success'}
      onClick={!disableBtn ? handlePublish : undefined}
      id={`publishPromotion-${id}`}
    >
      {is_published ? 'UnPublish' : 'Publish'}
    </Button>
  );
};

ReleasePromotionButton.propTypes = {
  isCampaign: PropTypes.bool,
  type: PropTypes.string,
  access: PropTypes.shape({
    publish: PropTypes.bool,
    unpublish: PropTypes.bool,
    send_campaign: PropTypes.bool,
    campaign_report: PropTypes.bool,
  }),
  item: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default ReleasePromotionButton;
