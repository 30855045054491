import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import Checkbox from '../../UIKit/Form/Checkbox';
import { GoalsOptions } from '../../../../utilities/constants/setup';

const SetupQuestions = ({ onSubmit }) => {
  const [goal, setGoal] = useState(null);
  return (
    <div className="c-panel-setup__question-container">
      <div className="c-panel-setup__question-wrapper">
        <h1>What are you looking for?</h1>
        <p>We&apos;d love to know about your business goals. This will help us to offer you the right product</p>
        <div className="c-panel-setup__question-box">
          {GoalsOptions.map((i) => (
            <Checkbox
              key={i.label}
              type="radio"
              name="goal"
              id={i.label}
              value={i.label}
              label={i.value}
              checked={goal === i.label}
              onChange={setGoal.bind(null, i.label)}
            />
          ))}
          <Button lg disabled={!goal} className="mt-5 mx-auto" onClick={() => onSubmit(goal)}>
            Next Step
          </Button>
        </div>
      </div>
    </div>
  );
};

SetupQuestions.propTypes = {
  onSubmit: PropTypes.func,
};

export default SetupQuestions;
