import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tab = ({
  children,
  initIndex,
  dataTour,
  dataTourHead,
  tabs,
  id,
  isModal,
  tabClassName,
  tabHeadClassName,
  isMerely,
  selected,
  onSelect,
}) => {
  const [index, setIndex] = useState(initIndex);
  useEffect(() => {
    setIndex(selected);
  }, [selected]);

  const handleChangeTab = (ind) => {
    setIndex(ind);
    if (onSelect) onSelect(ind);
  };
  if (tabs.length === 1 && isMerely) return children;
  return (
    <div data-tour={dataTour} className={`c-tab ${isModal ? 'c-tab--modal ' : ''}${tabClassName}`}>
      <ul data-tour={dataTourHead} className={`c-tab__head ${tabHeadClassName}`}>
        {tabs.map((tab, key) => (
          <li
            key={key}
            id={`${id}-${key}`}
            // className={+index === key ? 'active' : ''}
            className={classNames({
              active: +index === key,
              'd-none': tab?.hide,
            })}
            onClick={() => handleChangeTab(key)}
          >
            {(tab?.label || tab).toUpperCase()}
          </li>
        ))}
      </ul>
      {children && (
        <div className="c-tab__body">
          {Array.isArray(children)
            ? children.map((item, key) => (
                <div key={key} className={`c-tab__item ${+index === key ? 'active' : ''}`}>
                  {item}
                </div>
              ))
            : children}
        </div>
      )}
    </div>
  );
};

Tab.propTypes = {
  initIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  isMerely: PropTypes.bool,
  dataTour: PropTypes.string,
  dataTourHead: PropTypes.string,
  tabs: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func,
  children: PropTypes.any,
  isModal: PropTypes.bool,
  tabClassName: PropTypes.any,
  tabHeadClassName: PropTypes.any,
};

Tab.defaultProps = {
  id: 'tab',
  initIndex: 0,
  isMerely: false,
  tabs: [],
  selected: 0,
  tabClassName: '',
  tabHeadClassName: '',
};

export default Tab;
