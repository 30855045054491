import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, createURL } from '../config/apiConfig';
import BookablePhotoObject from '../objects/BookablePhotoObject';

const { GET, POST, PATCH, PUT, DELETE } = ApiMethods;

const getParams = (params) => {
  if (!!params.image) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (key === 'tags') {
        // params[key].forEach(s => formData.append(`tags[]`, s))
        params[key].forEach((s) => formData.append('tags', s));
        // formData.append(`tags`, JSON.stringify(params[key]))
      } else if (!!params[key] && key !== 'image') formData.append(key, params[key]);
    });
    formData.append('image', params.image, params.image.name);
    return formData;
  }
  return params;
};

export const createBulkBookablePhoto = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.bookablePhoto.create,
    params: params,
    headers: { 'Content-Type': 'undefined' },
  });

  if (response.data) {
    return new BookablePhotoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};
/**
 * @param params {{
 *     tags,
 *     price,
 *     is_published,
 *     image,
 *     service,
 * }}
 */

export const createBookablePhoto = async (params) => {
  const response = await callApi({
    method: POST,
    url: ApiRoutes.bookablePhoto.create,
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });

  if (response.data) {
    return new BookablePhotoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     tags,
 *     price,
 *     is_published,
 *     image,
 *     service,
 * }}
 */
export const updateBookablePhoto = async (id, params) => {
  const response = await callApi({
    method: PUT,
    url: createURL(ApiRoutes.bookablePhoto.update, { id }),
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new BookablePhotoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 * @param params {{
 *     tags,
 *     price,
 *     is_published,
 *     image,
 *     service,
 * }}
 */
export const partialUpdateBookablePhoto = async (id, params) => {
  const response = await callApi({
    method: PATCH,
    url: createURL(ApiRoutes.bookablePhoto.update, { id }),
    params: getParams(params),
    headers: !!params.image
      ? {
          'Content-Type': 'undefined',
        }
      : undefined,
  });
  if (response.data) {
    return new BookablePhotoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param params {{
 *     search,
 *     page,
 *     limit
 * }}
 */
export const getBookablePhotoList = async ({ search, page, limit } = {}) => {
  limit = limit || 20;
  page = page || 1;

  const response = await callApi({
    method: GET,
    params: {
      limit,
      search,
      offset: (page - 1) * limit,
    },
    url: ApiRoutes.bookablePhoto.list,
  });

  if (response.data) {
    return {
      ...response.data,
      results: (response.data.results || []).map((i) => new BookablePhotoObject(i)),
    };
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const getBookablePhoto = async (id) => {
  const response = await callApi({
    method: GET,
    params: {},
    url: createURL(ApiRoutes.bookablePhoto.get, { id }),
  });
  if (response.data) {
    return new BookablePhotoObject(response.data);
  } else {
    return Promise.reject(response);
  }
};

/**
 * @param id
 */
export const deleteBookablePhoto = async (id) => {
  const response = await callApi({
    method: DELETE,
    params: {},
    url: createURL(ApiRoutes.bookablePhoto.delete, { id }),
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
