import React, { useContext, useEffect } from 'react';
import Modal from '../../UIKit/Modal';
import { IMAGE_SUCCESS_CHECK } from '../../../../assets/Images';
import Button from '../../UIKit/Form/Button';
import { Types } from '../../../../config/actionTypes';
import { AppContext } from '../../../../context/providers';
import { handleApi, handleApiSave } from '../../../../utilities/useApi';
import { getBusiness, getServiceList, switchMarketplace } from '../../../../api';

// eslint-disable-next-line react/prop-types
const MarketplaceCongressModal = ({ onClose }) => {
  const { setSystem, business, setBusiness } = useContext(AppContext);
  useEffect(() => {
    localStorage.removeItem(Types.PAYMENT_BACK_PAGE);
  }, []);

  const handleStart = () => {
    setSystem('marketplace');
    if (!business?.is_published) {
      handleApi(getServiceList, { limit: 1 }).then((res) => {
        if (res.count > 0) {
          handleApiSave(switchMarketplace).then(() => {
            getBusiness({}).then(setBusiness);
          });
        }
      });
    }
    onClose();
  };
  return (
    <Modal show auto disableFooter disableHeader onClose={handleStart} title="Congress Marketplace">
      <div className="c-setup-system-congratulation">
        <img src={IMAGE_SUCCESS_CHECK} alt="marketplace" />
        <h2>CONGRATULATIONS!</h2>
        <p>Let&apos;s find your next client with the Beautster Marketplace!</p>
      </div>
      <div className="d-flex align-center my-5">
        <Button lg type="button" onClick={handleStart} className="m-auto">
          START MARKETPLACE
        </Button>
      </div>
    </Modal>
  );
};

export default MarketplaceCongressModal;
