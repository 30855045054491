import React from 'react';
import PropTypes from 'prop-types';
import { getPriceFormat } from '../../../../utilities/Price';

const RefundLineService = ({ line }) => (
  <div className="c-checkout-lines__item c-checkout-lines__item--refund c-list__item">
    <div className="c-list__info px-0">
      <h3 className="c-list__title">{line?.service_name ? line.service_name : line?.item_name}</h3>
      <p className="c-list__description">with {line?.staff_name}</p>
    </div>
    <div className="c-list__el-right">
      <span className="c-list__price">{getPriceFormat(+line?.price)}</span>
    </div>
  </div>
);

RefundLineService.propTypes = {
  line: PropTypes.any,
};

export default RefundLineService;
