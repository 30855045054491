import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from './Modal';
import Button from './Form/Button';

const Alert = ({ success, warning, info, danger, className, children, onClick }) => (
  <div
    className={classNames(
      'alert alert-default',
      {
        'alert-success': success,
        'alert-warning': warning,
        'alert-info': info,
        'alert-danger': danger,
      },
      className,
    )}
    onClick={onClick && onClick}
  >
    {children}
  </div>
);

Alert.propTypes = {
  success: PropTypes.bool,
  warning: PropTypes.bool,
  info: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.any,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export const ModalAlert = ({
  modalTitle = 'Warning',
  title = 'Alert Title',
  description = 'Alert Description',
  buttonComponent,
  onClose,
}) => (
  <Modal
    show
    title={<span className="c-alert-modal__modal-title">{modalTitle}</span>}
    disableFooter
    closeBtn={!!onClose}
    onClose={onClose}
  >
    <div className="c-alert-modal">
      <h3 className="c-alert-modal__title">{title}</h3>
      <p className="c-alert-modal__description">{description}</p>
      <div className="c-alert-modal__buttons">
        {onClose && (
          <Button color="light" onClick={onClose}>
            Close
          </Button>
        )}
        {buttonComponent}
      </div>
    </div>
  </Modal>
);

ModalAlert.propTypes = {
  modalTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonComponent: PropTypes.any,
  onClose: PropTypes.func,
};

export default Alert;
