import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DashboardBox = ({
  grid = 1,
  gridSm = 1,
  title = null,
  description = null,
  icon = null,
  iconStyles = null,
  className = null,
  onClick = null,
}) => {
  const classes = classNames(
    'c-dashboard__box',
    `c-dashboard__box--${grid}`,
    `c-dashboard__box--sm-${gridSm}`,
    className,
  );
  return (
    <div className={classes} onClick={onClick || undefined}>
      <div className="c-dashboard__box-icon">
        {icon && typeof icon === 'string' ? <i className={icon} style={iconStyles} /> : icon}
      </div>
      <div className="c-dashboard__box-detail">
        {title && <h3 className="c-dashboard__box-title">{title}</h3>}
        {description != null && <p className="c-dashboard__box-description">{description}</p>}
      </div>
    </div>
  );
};

DashboardBox.propTypes = {
  grid: PropTypes.any,
  gridSm: PropTypes.any,
  title: PropTypes.any,
  description: PropTypes.any,
  icon: PropTypes.any,
  iconStyles: PropTypes.any,
  className: PropTypes.any,
  onClick: PropTypes.func,
};

export default DashboardBox;
