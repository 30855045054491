import { callApi } from '../utilities/ApiHandler';
import { ApiMethods, ApiRoutes, BusinessId, createURL } from '../config/apiConfig';

const { POST } = ApiMethods;

/**
 * @param param {{
 *     id: Number,
 *     amount: Number,
 * }}
 * @description payment by stripe pos machine
 */
export const getStripePayment = async ({ id, amount }) => {
  const response = await callApi({
    method: POST,
    params: {
      amount,
    },
    url: createURL(ApiRoutes.stripe.payment, { businessId: BusinessId, id }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
/**
 * @param param {{
 *     id: Number,
 *     paymentIntent: String,
 * }}
 */
export const setStripeCapturePayment = async ({ id, paymentIntent }) => {
  const response = await callApi({
    method: POST,
    params: {
      payment_intent: paymentIntent,
    },
    url: createURL(ApiRoutes.stripe.capture, { businessId: BusinessId, id }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get stripe link for billing list subscription
 */
export const getStripeCreateToken = async () => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.stripe.create_token, { id: BusinessId }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get stripe link for billing list subscription
 */
export const getStripeBillingsLink = async () => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.stripe.billing, { id: BusinessId }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get stripe link for payout
 */
export const getStripePayoutLink = async () => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.stripe.payout_link, { id: BusinessId }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get stripe link to connect account
 */
// export const getStripeConnectAccountLink = async ({ businessId = null }) => {
export const getStripeConnectAccountLink = async (props = { businessId: null }) => {
  const businessId = props?.businessId || BusinessId;
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.stripe.connect_account, { id: businessId }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};

/**
 * @description get stripe link to disconnect account
 */
export const getStripeDisconnectAccountLink = async () => {
  const response = await callApi({
    method: POST,
    url: createURL(ApiRoutes.stripe.disconnect_account, { id: BusinessId }),
    locationBase: false,
  });
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
};
