import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '@material-ui/icons';
import { MARKETPLACE_URL } from '../../../../config/appConfig';
import { IMAGE_LOGO, IMAGE_LOGO_IMG } from '../../../../assets/Images';
import UserHeader from '../Header/UserHeader';

const SetupHeader = ({ canBack = false, onClose }) => (
  <div className="c-panel-setup-header">
    <div className="d-flex align-items-center">
      <a href={`${MARKETPLACE_URL}/landing-software/`} target="_blank" className="c-panel-setup-header__logo">
        <picture>
          <source media="(min-width:576px)" srcSet={IMAGE_LOGO} />
          <source media="(max-width:576px)" srcSet={IMAGE_LOGO_IMG} />
          <img src={IMAGE_LOGO} alt="Beatuster" className="c-panel-setup-header__logo-image" />
        </picture>
      </a>
      {canBack && (
        <div className="c-panel-setup-header__back" onClick={onClose}>
          <ArrowBack />
          Back To Locations
        </div>
      )}
    </div>
    <div className="c-panel-setup-header__user">
      <UserHeader only_logout />
    </div>
  </div>
);

SetupHeader.propTypes = {
  onClose: PropTypes.func,
  canBack: PropTypes.bool,
};

export default SetupHeader;
