import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Facebook, Instagram, Twitter, Language } from '@material-ui/icons';
import Image from '../../Common/UIKit/Image';
import { IMAGE_DEFAULT } from '../../../assets/Images';
import classNames from 'classnames';
import useApi from '../../../utilities/useApi';
import { getBusinessWorkingHours } from '../../../api/businessWorkingHours';
import { PageLoading } from '../../Common/UIKit/Spinner';
import Map from '../../Common/UIKit/Map';
import usePermission from '../../../utilities/usePermission';

const BusinessProfileStatic = ({ data }) => {
  const [hours, loading] = useApi(getBusinessWorkingHours, { key: 'businessworkinghour' });
  const { access: _hour } = usePermission('businessworkinghour', 'crud', 'view');
  const systemTypes = useMemo(
    () =>
      data.software_setup && data.marketplace_setup
        ? 'Marketplace & Software'
        : data.software_setup
        ? 'Software'
        : data.marketplace_setup
        ? 'Marketplace'
        : null,
    [data],
  );
  const fixedSocialUrl = (url, type) => {
    if (url.includes('http') || url.includes('www')) return url;
    switch (type) {
      case 'facebook':
        return `https://www.facebook.com/${url}/`;
      case 'yelp':
        return `https://www.yelp.com/biz/${url}/`;
      case 'twitter':
        return `https://www.twitter.com/${url}/`;
      case 'instagram':
        return `https://www.instagram.com/${url}/`;
    }
    return url;
  };
  const defaultCenter = useMemo(() => {
    if (data) {
      const lat = +data.latitude;
      const lng = +data.longitude;
      if (lng === 0 || lat === 0) return null;
      return { lat, lng };
    }
    return null;
  }, [data]);
  return (
    <div className="row mt-3">
      <div className="col-xs-12 col-md-6">
        <div className="c-panel-content">
          <div className="c-staff-view__section">
            <h5 className="c-staff-view__section-title mb-4">Business Info</h5>
            <div style={{ minHeight: '285px' }}>
              <div className="c-business-static__profile">
                <Image c src={data?.avatar || IMAGE_DEFAULT} className="c-business-static__image" />
                <div className="c-business-static__profile-detail">
                  <h3>{data.title}</h3>
                  <div className="c-business-static__item">
                    <span className="c-business-static__item-label">System Types:</span>
                    <span className="c-business-static__item-val">{systemTypes}</span>
                  </div>
                  <div className="c-business-static__item">
                    <span className="c-business-static__item-label">Phone:</span>
                    <span className="c-business-static__item-val">{data.phone_number}</span>
                  </div>
                  <div className="mt-4">
                    <ul className="c-business-static__social-media">
                      {data.website_address && (
                        <li>
                          <a
                            className="c-business-static__social-media-link  c-business-static__social-media-link--website"
                            target="_blank"
                            rel="noreferrer"
                            href={data.website_address}
                          >
                            <Language />
                          </a>
                        </li>
                      )}
                      {data.instagram_account && (
                        <li>
                          <a
                            className="c-business-static__social-media-link c-business-static__social-media-link--instagram"
                            target="_blank"
                            rel="noreferrer"
                            href={fixedSocialUrl(data.instagram_account, 'instagram')}
                          >
                            <Instagram />
                          </a>
                        </li>
                      )}
                      {data.twitter_account && (
                        <li>
                          <a
                            className="c-business-static__social-media-link c-business-static__social-media-link--twitter"
                            target="_blank"
                            rel="noreferrer"
                            href={fixedSocialUrl(data.twitter_account, 'twitter')}
                          >
                            <Twitter />
                          </a>
                        </li>
                      )}
                      {data.facebook_account && (
                        <li>
                          <a
                            className="c-business-static__social-media-link c-business-static__social-media-link--facebook"
                            target="_blank"
                            rel="noreferrer"
                            href={fixedSocialUrl(data.facebook_account, 'facebook')}
                          >
                            <Facebook />
                          </a>
                        </li>
                      )}
                      {data.yelp_account && (
                        <li>
                          <a
                            className="c-business-static__social-media-link c-business-static__social-media-link--yelp"
                            target="_blank"
                            rel="noreferrer"
                            href={fixedSocialUrl(data.yelp_account, 'yelp')}
                          >
                            Yelp
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <h5 className="font-weight-bold">Bio</h5>
                <div>{data.bio || '-- NO BIO EXISTS --'}</div>
              </div>
            </div>
          </div>
        </div>
        {_hour && (
          <>
            {loading ? (
              <PageLoading />
            ) : (
              <div className="c-panel-content">
                <div className="c-staff-view__section">
                  <h5 className="c-staff-view__section-title mb-4">Working Hours</h5>
                  <ul>
                    {(hours?.results || []).map((i) => (
                      <li
                        className={classNames('c-staff-view__time', { 'c-staff-view__time--close': !i.is_active })}
                        key={i.id}
                      >
                        <span className="c-staff-view__time-label">
                          {i.dayName}{' '}
                          {!i.is_active && <span className="c-staff-view__time-label--close">(Inactive)</span>}
                        </span>
                        <span className="c-staff-view__time-value">
                          {i.start_time} - {i.end_time}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="col-xs-12 col-md-6">
        <div className="c-panel-content">
          <div className="c-staff-view__section">
            <h5 className="c-staff-view__section-title mb-4">On Map</h5>
            <div style={{ width: '100%', height: '285px' }}>
              <Map
                defaultCenter={defaultCenter ? defaultCenter : { lat: 51.041493, lng: -114.066613 }}
                center={defaultCenter ? defaultCenter : { lat: 51.041493, lng: -114.066613 }}
                marker={defaultCenter ? { center: defaultCenter } : undefined}
              />
            </div>
          </div>
        </div>
        <div className="c-panel-content">
          <div className="c-staff-view__section">
            <h5 className="c-staff-view__section-title mb-4">Detail</h5>
            <ul>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">Country</span>
                <span className="c-staff-view__time-value">{data.country?.name}</span>
              </li>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">Province</span>
                <span className="c-staff-view__time-value">---</span>
              </li>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">City</span>
                <span className="c-staff-view__time-value">{data.city}</span>
              </li>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">Address</span>
                <span className="c-staff-view__time-value">{data.address}</span>
              </li>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">Timezone</span>
                <span className="c-staff-view__time-value">{data.timezone}</span>
              </li>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">Zip Code</span>
                <span className="c-staff-view__time-value">{data.zip_code}</span>
              </li>
              <li className="c-staff-view__time">
                <span className="c-staff-view__time-label">Tax Rate</span>
                <span className="c-staff-view__time-value">{data.tax_rate}%</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

BusinessProfileStatic.propTypes = {
  data: PropTypes.any,
};

export default BusinessProfileStatic;
