import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DoneAll, Security, NotInterested } from '@material-ui/icons';
import { ControlInput } from '../../Common/UIKit/Form/Input';
import Button from '../../Common/UIKit/Form/Button';
import AuthenticationLayout from '../../Common/Authentication/AuthenticationLayout';
import { getQueryUri } from '../../../utilities/URI';
import { handleApiSave } from '../../../utilities/useApi';
import { changeIdentityWithHeader, claimProfilePassword } from '../../../api';
import asyncLocalStorage from '../../../utilities/AsyncLocalStorage';
import { Types } from '../../../config/actionTypes';
import { MARKETPLACE_URL } from '../../../config/appConfig';

const STEPS = {
  START: 0,
  PASSWORD: 1,
  EMAIL: 2,
  ERROR: 3,
  DONE: 4,
};

const ClaimProfilePage = () => {
  const [step, setStep] = useState(STEPS.START);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const { control, errors, handleSubmit, setError, watch } = useForm();
  const slug = useMemo(() => getQueryUri('slug'), []);

  useEffect(() => {
    const token = getQueryUri('token');
    if (!token) setStep(STEPS.ERROR);
  }, []);

  const onSubmit = ({ email, password, passwordConfirmation }) => {
    const token = getQueryUri('token');
    if (step === STEPS.EMAIL && accessToken) {
      setLoading(true);
      handleApiSave(
        changeIdentityWithHeader,
        undefined,
        { token: accessToken.access, new_identity: email, identity_type: 'email' },
        undefined,
        { setError },
      )
        .then(async () => {
          await asyncLocalStorage.setItem(Types.USER_TOKEN, accessToken.access);
          await asyncLocalStorage.setItem(Types.USER_REFRESH, accessToken.refresh);
          window.location.reload();
        })
        .catch(() => setLoading(false));
    } else if (password === passwordConfirmation) {
      setLoading(true);
      handleApiSave(claimProfilePassword, undefined, { token, password }, undefined, { setError })
        .then((data) => {
          setLoading(false);
          if (data?.access && data?.refresh) {
            setAccessToken(data);
            setStep(STEPS.EMAIL);
          } else {
            setStep(STEPS.ERROR);
          }
        })
        .catch(({ status }) => {
          if (+status === 404) setStep(STEPS.ERROR);
          setLoading(false);
        });
    } else {
      setError('passwordConfirmation', {
        type: 'manual',
        message: 'password confirmation did not match',
      });
    }
  };

  return (
    <AuthenticationLayout className={step === STEPS.START ? 'c-auth-claim' : undefined}>
      {step === STEPS.START ? (
        <>
          <div className="c-auth-claim__image">
            <span className="c-auth-claim__image-main">
              <DoneAll />
            </span>
            <i className="c-auth-claim__image-1 far fa-circle" />
            <i className="c-auth-claim__image-2 far fa-lightbulb" />
            <i className="c-auth-claim__image-3 fas fa-circle" />
            <i className="c-auth-claim__image-4 far fa-square" />
            <i className="c-auth-claim__image-5 fas fa-certificate" />
            <i className="c-auth-claim__image-6 fas fa-square" />
            <i className="c-auth-claim__image-7 far fa-lightbulb" />
            <i className="c-auth-claim__image-8 fab fa-canadian-maple-leaf" />
            <i className="c-auth-claim__image-9 fab fa-canadian-maple-leaf" />
          </div>
          <h2 className="c-auth-claim__title">Welcome to Beautster!</h2>
          <p className="c-auth-claim__description">
            Your account is ready and waiting for you!
            <br />
            Just click on this link to set a new password and start your Beautster journey!
          </p>
          <div className="c-auth-claim__buttons">
            {slug && (
              <Button
                block
                lg
                className="mr-2"
                color="light"
                target="_blank"
                link={`${MARKETPLACE_URL}/business/${slug}`}
              >
                View Business Profile
              </Button>
            )}
            <Button block lg onClick={() => setStep(STEPS.PASSWORD)}>
              Claim Now
            </Button>
          </div>
        </>
      ) : step === STEPS.DONE ? (
        <>
          <h2 className="text-center text-success my-5">Password Has Been Changed Successfully</h2>
          <Button block lg link="/login">
            Login Now
          </Button>
        </>
      ) : step === STEPS.ERROR ? (
        <>
          <div className="c-auth-claim__image bg-danger c-auth-claim__image--step">
            <NotInterested />
          </div>
          <h2 className="c-auth-claim__title">Error</h2>
          <h4 className="text-center text-danger my-3">Token Is Invalid. Please Check Your Email Inbox</h4>
          <Button block lg link="/login">
            Login Now
          </Button>
        </>
      ) : (
        <>
          <div className="c-auth-claim__image c-auth-claim__image--step">
            <Security />
          </div>
          <h2 className="c-auth-claim__title c-auth-claim__title--sm">Change Password</h2>
          <form className="e-form my-3" onSubmit={handleSubmit(onSubmit)}>
            {step === STEPS.PASSWORD ? (
              <>
                <ControlInput
                  control={control}
                  label="Password"
                  name="password"
                  type="password"
                  rules={{ required: 'Password is required' }}
                  defaultValue=""
                  errorMessage={errors?.password?.message}
                  error={!!errors.password}
                />
                <ControlInput
                  control={control}
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  type="password"
                  rules={{
                    required: 'Password confirmation is required',
                    validate: (data) => (data !== watch().password ? 'password confirmation is not matched' : true),
                  }}
                  defaultValue=""
                  errorMessage={errors?.passwordConfirmation?.message}
                  error={!!errors.passwordConfirmation}
                />
              </>
            ) : (
              <ControlInput
                control={control}
                label="Email"
                name="email"
                type="email"
                rules={{ required: 'Email is required' }}
                defaultValue=""
                errorMessage={errors?.email?.message}
                error={!!errors.email}
              />
            )}
            <Button block lg className="mt-5" loading={loading}>
              {step === STEPS.PASSWORD ? 'Change Password' : 'Claim Now'}
            </Button>
          </form>
        </>
      )}
    </AuthenticationLayout>
  );
};

export default ClaimProfilePage;
