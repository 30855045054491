import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../UIKit/Form/Button';
import { MARKETPLACE_URL } from '../../../../config/appConfig';

const PreviewSetupMarketplace = ({ onStarted }) => (
  <div className="c-setup-system__preview">
    <h1>Attracting new clients like never before</h1>
    <div>
      <p>
        Beautster is a two-sided online marketplace and community for hair, spa, and beauty professionals that connects
        local customers to local salons and spas.
      </p>
      <p>
        Beautster was created so that busy hair, spa, and beauty professionals could list their services, organize their
        entire business, and attract new customers all in one easy to use marketplace.
      </p>
      <p className="mb-2">
        <strong>With Beautster, you can:</strong>
      </p>
      <ul>
        <li className="mb-2">Attract new clients and get bookings even when you are closed.</li>
        <li className="mb-2">List your Last Minute Openings when a client cancels an appointment.</li>
        <li className="mb-2">Promote your hot deals without paying unfair commissions (20% commission up to $50).</li>
        <li className="mb-2">Free booking for existing clients.</li>
      </ul>
    </div>

    <div className="d-flex align-center mt-5">
      <Button onClick={onStarted}>Setup Marketplace</Button>
      <Button link={`${MARKETPLACE_URL}/landing-marketplace`} target="_blank" color="light" className="ml-3">
        Learn More
      </Button>
    </div>
  </div>
);

PreviewSetupMarketplace.propTypes = {
  onStarted: PropTypes.func,
};

export default PreviewSetupMarketplace;
