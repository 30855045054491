import React, { forwardRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_DEFAULT_WOMAN_AVATAR } from '../../../assets/Images';
import { handleApi } from '../../../utilities/useApi';
import { getMessage } from '../../../api';
import NotFoundData from '../UIKit/NotFoundData';
import { getFormatBriefDate } from '../../../utilities/DateHelpers';
import MessageAttachment from './MessageAttachment';
import { CircleSpinner, PageLoading } from '../UIKit/Spinner';
import { AppContext } from '../../../context/providers';
// import Image from '@/components/Common/UIKit/Image';
import Image from '../../Common/UIKit/Image';

const MessageText = ({ client, item, businessUserName, businessUserAvatar }) => {
  // const name = client.user.id === item.sender ? client?.full_name : businessUserName;
  // const avatar =
  //   client.user.id === item.sender ? (client?.avatar ? client.avatar : IMAGE_DEFAULT_WOMAN_AVATAR) : businessUserAvatar;
  const name = item.direction === 'client' ? client?.full_name : businessUserName;
  const avatar =
    item.direction === 'client' ? (client?.avatar ? client.avatar : IMAGE_DEFAULT_WOMAN_AVATAR) : businessUserAvatar;
  return (
    <div className="c-messages__message">
      <Image className="c-messages__message-avatar" src={avatar} alt={name} />
      <div className="c-messages__message-body">
        <div className="c-messages__message-detail">
          <span className="c-messages__message-name">{name}</span>
          <span className="c-messages__message-date">
            {/*12:45 PM*/}
            {/*{item?.hash ? 'sending...' : item?.created_at && getFormatBriefDate(item.created_at, true, true)}*/}
            {item?.created_at || 'sending...'}
          </span>
        </div>
        <div className="c-messages__message-text">{item?.body}</div>
      </div>
    </div>
  );
};

MessageText.propTypes = {
  client: PropTypes.any,
  item: PropTypes.any,
  businessUserName: PropTypes.any,
  businessUserAvatar: PropTypes.any,
};

const MessageFile = ({ client, item, businessUserName, businessUserAvatar }) => {
  // const name = client.user.id === item.sender ? client?.full_name : businessUserName;
  // const avatar =
  //   client.user.id === item.sender ? (client?.avatar ? client.avatar : IMAGE_DEFAULT_WOMAN_AVATAR) : businessUserAvatar;
  const name = item.direction === 'client' ? client?.full_name : businessUserName;
  const avatar =
    item.direction === 'client' ? (client?.avatar ? client.avatar : IMAGE_DEFAULT_WOMAN_AVATAR) : businessUserAvatar;

  return (
    <div className="c-messages__message">
      <Image className="c-messages__message-avatar" src={avatar} alt={name} />
      <div className="c-messages__message-body">
        <div className="c-messages__message-detail">
          <span className="c-messages__message-name">{name}</span>
          <span className="c-messages__message-date">
            {/*12:45 PM*/}
            {item?.hash ? 'sending...' : item?.created_at && getFormatBriefDate(item.created_at, true)}
          </span>
        </div>
        <div className="c-messages__message-text">
          {item?.body !== 'system:attachment' && item?.body}
          <MessageAttachment downloadable file={item.attachment} fileName={item.attachment} />
        </div>
      </div>
    </div>
  );
};

MessageFile.propTypes = {
  client: PropTypes.any,
  item: PropTypes.any,
  businessUserName: PropTypes.any,
  businessUserAvatar: PropTypes.any,
};

// eslint-disable-next-line react/display-name
const MessageContent = forwardRef(({ client, data, onData, contentRef }, ref) => {
  // const contentRef = useRef(null);
  const [heightPage, setHeightPage] = useState(0);
  const [initialPage, setInitialPage] = useState(false);
  // const [prevData, setPrevData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const { business } = useContext(AppContext);
  // const [, loading] = useApi(getMessageList, { part_2: client?.user }, onData);

  useEffect(() => {
    if (client) {
      handleApi(getMessage, { id: client?.user.id }, { list: false, loadingFn: setLoading }).then((res) => {
        onData(res);
      });
    }
  }, [client]);

  useEffect(
    () => (contentRef) => {
      if (contentRef) {
        contentRef.current.removeEventListener('scroll', handleNextPage);
      }
    },
    [contentRef],
  );

  useEffect(() => {
    if (contentRef && data && client && !loading && !initialPage) {
      contentRef.current.scrollTo(0, contentRef.current.scrollHeight);
      setInitialPage(true);
    }
  }, [data, contentRef, client, loading, initialPage]);

  useEffect(() => {
    contentRef.current.addEventListener('scroll', handleNextPage);
  }, [data, contentRef, loadingPage]);

  useEffect(() => {
    if (contentRef && data && contentRef.current && contentRef.current.scrollHeight > heightPage) {
      setTimeout(() => {
        // contentRef.current.scrollTop = contentRef.current.scrollHeight - heightPage;
        setHeightPage(contentRef.current.scrollHeight);
      }, 1);
    }
  }, [data]);
  const handleNextPage = (e) => {
    let scrollTop = e.srcElement.scrollTop;
    if (!loadingPage && data?.next && scrollTop <= 50) {
      contentRef.current.removeEventListener('scroll', handleNextPage);
      setLoadingPage(true);
      handleApi(getMessage, { id: client?.user.id, page: data.page + 1 }).then((res) => {
        onData({ ...res, results: [...res.results, ...data.results] });
        setLoadingPage(false);
      });
    }
  };
  return (
    <div className="c-messages__content" ref={ref}>
      {loading ? (
        <PageLoading />
      ) : (
        <>
          {loadingPage && <CircleSpinner sm />}
          {client && data?.results ? (
            <>
              {data?.results.map((item, ind) =>
                item?.attachment ? (
                  <MessageFile
                    key={item?.id || ind}
                    client={client}
                    businessUserName={`${business.title} (You)`}
                    businessUserAvatar={business.avatar || IMAGE_DEFAULT_WOMAN_AVATAR}
                    item={item}
                  />
                ) : (
                  <MessageText
                    key={item?.id || ind}
                    client={client}
                    businessUserName={`${business.title} (You)`}
                    businessUserAvatar={business.avatar || IMAGE_DEFAULT_WOMAN_AVATAR}
                    item={item}
                  />
                ),
              )}
            </>
          ) : (
            <NotFoundData icon={null} title="Select client" description="Please select a client from client list" />
          )}
        </>
      )}
    </div>
  );
});

MessageContent.propTypes = {
  client: PropTypes.any,
  data: PropTypes.any,
  contentRef: PropTypes.any,
  onData: PropTypes.func,
};

export default MessageContent;
