import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ControlInput } from '../../UIKit/Form/Input';
import { getPriceFormat } from '../../../../utilities/Price';
import { ControlStaffSelect } from '../../Selections/StaffSelect';

const CheckoutLineGiftCard = ({
  errors,
  control,
  line,
  index,
  invoiceId,
  data,
  onChangePrice,
  onChangeStaff,
  onChangeQuantity,
}) => {
  const maxQuantity = useMemo(() => {
    if (!data) return 0;
    return data.usage_limit - data.sold_count;
  }, [data]);

  return (
    <div className="c-checkout-lines__item">
      <div className="c-list__item">
        <div className="c-list__info px-0">
          <h3 className="c-list__title">{line?.name}</h3>
          <p className="c-list__description">Gift Card (Value: {getPriceFormat(+line.value)})</p>
          <div className="c-list__metas">
            {(line?.services || []).length > 0 && (
              <span className="c-list__meta">
                Services: {line.services.length} service{line.services.length > 1 && 's'}
              </span>
            )}

            <span className="c-list__meta">
              , Expire After: {line.expire_after}
              {line.expire_after !== '1' ? ' days' : ' day'}
            </span>
          </div>
        </div>
        <div className="c-list__el-right">
          <span className="c-list__price">{getPriceFormat(+line?.line_price)}</span>
        </div>
      </div>
      <div className="c-list__item">
        <div className="px-2 w-25">
          <ControlInput
            rules={{
              min: {
                value: 0,
                message: 'Quantity cannot be less than 0',
              },
              max:
                maxQuantity > 0
                  ? {
                      value: maxQuantity,
                      message: `Only ${maxQuantity} card${maxQuantity > 1 ? 's' : ''} exist${
                        maxQuantity > 1 ? 's' : ''
                      }`,
                    }
                  : undefined,
              required: 'Quantity is required',
            }}
            control={control}
            name={`quantity[${index}]`}
            type="number"
            disabled={!!invoiceId}
            max={maxQuantity}
            min={0}
            defaultValue={line?.quantity || 1}
            label="Quantity"
            errorMessage={errors?.quantity && errors?.quantity[index]?.message}
            onChangeText={(val) => onChangeQuantity(line, val)}
          />
        </div>
        <div className="px-2 w-25">
          <ControlInput
            rules={{ required: 'Price is required' }}
            control={control}
            name={`price[${index}]`}
            type="number"
            unit="$"
            disabled={!!invoiceId}
            min={0}
            defaultValue={line?.price}
            label="Price"
            placeholder={'e.g. 30 ($30)'}
            errorMessage={errors?.price && errors?.price[index]?.message}
            onChangeText={(val) => onChangePrice(line, val)}
          />
        </div>
        <div className="px-2 w-25">
          <ControlStaffSelect
            rules={{ required: 'Staff is required' }}
            control={control}
            name={`staff[${index}]`}
            disabled={!!invoiceId}
            defaultValue={line?.staff}
            hasDefault
            label="Staff"
            errorMessage={errors?.staff && errors?.staff[index]?.message}
            onChangeValue={(val) => onChangeStaff(line, val)}
          />
        </div>
      </div>
    </div>
  );
};

CheckoutLineGiftCard.propTypes = {
  errors: PropTypes.any,
  control: PropTypes.any,
  line: PropTypes.any,
  index: PropTypes.any,
  invoiceId: PropTypes.any,
  data: PropTypes.any,
  onChangePrice: PropTypes.func,
  onChangeStaff: PropTypes.func,
  onChangeQuantity: PropTypes.func,
};

export default CheckoutLineGiftCard;
