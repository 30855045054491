import React from 'react';
import PropTypes from 'prop-types';
import { getPriceFormat } from '../../../../utilities/Price';
import { getFormatTime, getStringFromTime, getSumDuration } from '../../../../utilities/DateHelpers';

const AppointmentLine = ({ line: { reserved_time, full_service, duration, full_staff, price } }) => (
  <div className="c-appointment-line">
    <div className="c-appointment-line__duration">{getFormatTime(getStringFromTime(reserved_time), true)}</div>
    <div className="c-appointment-line__detail">
      <span className="c-appointment-line__title">{full_service?.name}</span>
      <span className="c-appointment-line__meta">
        {getFormatTime(duration)} with {full_staff?.name}
      </span>
    </div>
    <span className="c-appointment-line__price">{getPriceFormat(+price)}</span>
  </div>
);

AppointmentLine.propTypes = {
  line: PropTypes.objectOf({
    reserved_time: PropTypes.string,
    full_service: PropTypes.any,
    duration: PropTypes.string,
    full_staff: PropTypes.any,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export const AppointmentResultLines = ({ lines }) => (
  <div className="c-appointment-line">
    <div className="c-appointment-line__duration" />
    <div className="c-appointment-line__detail">
      <span className="c-appointment-line__title">{getSumDuration(lines.map((l) => l.duration))}</span>
    </div>
    <span className="c-appointment-line__price">{getPriceFormat(lines.reduce((a, l) => a + +l.price, 0))}</span>
  </div>
);

AppointmentResultLines.propTypes = {
  lines: PropTypes.array,
};

export default AppointmentLine;
