import React, { useState } from 'react';
// import PromotionGiftCardDetailModal from "./PromotionGiftCardDetailModal";
import PromotionGiftCardFormModal from './PromotionGiftCardFormModal';
import CheckoutModal from '../../Checkout/CheckoutModal';
import { PromotionSoldGiftCardInvoiceModal } from './PromotionSoldGiftCardModal';

// eslint-disable-next-line react/prop-types
const PromotionGiftCardModal = ({ onSave, onClose }) => {
  const [showCheckout, setShowCheckout] = useState(null);
  const [showSold, setShowSold] = useState(null);
  return (
    <>
      {showCheckout ? (
        <CheckoutModal
          id={showCheckout.id}
          type="gift_card"
          onClose={() => {
            setShowCheckout(null);
            onClose();
          }}
          onSave={(data) => {
            setShowCheckout(null);
            setShowSold(data);
          }}
        />
      ) : showSold ? (
        <PromotionSoldGiftCardInvoiceModal
          from_invoice
          data={showSold}
          onClose={() => {
            setShowSold(null);
            onSave();
          }}
        />
      ) : (
        <PromotionGiftCardFormModal onSave={onSave} onClose={onClose} onCheckout={setShowCheckout} />
      )}
    </>
  );
};

export default PromotionGiftCardModal;
