import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// eslint-disable-next-line react/display-name
const InputTextarea = forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ id, value, type, unit, unitPos, onChange, onChangeText, className, ...otherProps }, ref) => {
    const handleOnChange = (e) => {
      onChangeText(e.target.value);
      onChange(e);
    };
    return (
      <div
        className={classnames('c-form__controller', { 'c-form__controller--disabled': otherProps.disabled }, className)}
      >
        <textarea
          id={id}
          ref={ref}
          // className={classnames('c-form__input', otherProps?.className)}
          onChange={handleOnChange}
          value={value}
          {...otherProps}
        />
        {unit && <span className="c-form__controller-unit">{unit}</span>}
      </div>
    );
  },
);

InputTextarea.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.any,
  unitPos: PropTypes.oneOf(['before', 'after']),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
};

InputTextarea.defaultProps = {
  id: null,
  type: 'text',
  unit: '',
  unitPos: 'after',
  onChange: () => {},
  onChangeText: () => {},
};

export default InputTextarea;
